import React from 'react';

import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';

export type AspectRatioProps = {
  ratio?: number;
  children?: React.ReactNode;
  className?: string;
};

const convertAspectRatioToPercentage = (ratio: number) => {
  return `${((1 / ratio) * 100).toFixed(5)}%`;
};

/**
 * `AspectRatio` 컴포넌트는 주어진 종횡비를 유지하는 컨테이너를 생성합니다.
 *
 * 이 컴포넌트는 이미지나 비디오와 같은 미디어 요소의 비율을 유지하면서 레이아웃을 안정적으로 유지하는 데 유용합니다.
 *
 * - 예시: 16:9 종횡비를 유지하는 이미지 컴포넌트 (이미지 로드 이전에도 레이아웃 내에서 요소의 크기를 나타낼 수 있습니다.)
 */
const AspectRatio = ({ ratio = 1, children, className }: AspectRatioProps) => {
  const outerContainerPaddingBottom = convertAspectRatioToPercentage(ratio);

  return (
    <div className={tempCnx('block', className)}>
      <div
        className={tempCnx('relative', 'w-full h-0 overflow-hidden')}
        style={{
          paddingBottom: outerContainerPaddingBottom,
        }}
      >
        <div className={tempCnx('absolute top-0 right-0 bottom-0 left-0', 'w-full h-full')}>{children}</div>
      </div>
    </div>
  );
};

export default AspectRatio;
