import type { AfParameters } from './types';

const DEFAULT_APP_TARGET_VIEW = encodeURIComponent('tvingapp://com.tving.home');

/**
 * 공통으로 전달해야 하는 기본 afParameter 종류 및 파라미터 키 명칭은 다음 스레드를 참조해 주세요. (마케팅 팀으로부터 제공 받아야 함)
 * @see {@link https://tvingcorp.slack.com/archives/C06N7LGUS8M/p1721027153241189?thread_ts=1718003600.923759&cid=C06N7LGUS8M}
 *
 */
export const DEFAULT_AF_PARAMETERS: AfParameters = {
  mediaSource: {
    keys: ['utm_source'],
    defaultValue: 'none', // mediaSource는 defaultValue가 필수 값입니다.
  },
  campaign: {
    keys: ['utm_campaign'],
  },
  channel: {
    keys: ['utm_medium'],
  },
  ad: {
    keys: ['utm_term'],
  },
  adSet: {
    keys: ['utm_content'],
  },
  googleClickIdKey: 'af_sub1',
  deepLinkValue: {
    keys: ['deep_link_value'],
    /**
     * 기본 동작으로 홈 화면으로 이동합니다.
     * deep_link_value를 가이드와 같이 매핑하여 사용할 수도 있으나,
     * App Scheme의 값을 그대로 사용하도록 합니다.
     *
     * 다음 문서에서 확인할 수 있습니다. @see {@link https://tving.atlassian.net/wiki/spaces/COW/pages/20567300}
     */
    defaultValue: DEFAULT_APP_TARGET_VIEW,
  },
  afCustom: [
    // af_dp, af_force_deeplink 파라미터는 유니버셜 링크가 개발이 안된 단계에서 URI Scheme을 사용해 앱 랜딩을 위해 필요합니다.
    {
      paramKey: 'af_dp',
      keys: ['af_dp'],
      defaultValue: DEFAULT_APP_TARGET_VIEW,
    },
    {
      paramKey: 'af_force_deeplink',
      keys: ['af_force_deeplink'],
      defaultValue: 'true',
    },
  ],
};

/**
 * 스마트 스크립트 소스코드 상의 `AF_CUSTOM_EXCLUDE_PARAMS_KEYS`를 참조합니다.
 */
export const RESERVED_PARAMETER_KEYS = [
  'pid',
  'c',
  'af_channel',
  'af_ad',
  'af_adset',
  'deep_link_value',
  'af_sub1',
  'af_sub2',
  'af_sub3',
  'af_sub4',
  'af_sub5',
];
