import useFixedModalBackground from '@tving/utils/src/hooks/common/useFixedModalBackground';

/**
 * Body Scroll Lock을 위한 컴포넌트입니다.
 * - LifeCycle에 따라 Body에 Scroll Lock을 적용하거나 해제합니다.
 */
const BodyScrollLocker = () => {
    // TODO: 모달이 중첩되는 경우 Scroll Lock이 해제되는 문제가 발생할 수 있습니다.
    // 추가 작업이 필요한 경우에 진행합니다.
    useFixedModalBackground();

    return null;
};

export default BodyScrollLocker;
