import { ProvisioningResponse } from '../../apis/types';
import { TvingProvisioning } from '../types';

const proxyProvisioning = (provisioningResponse?: ProvisioningResponse): TvingProvisioning => {
  if (provisioningResponse) {
    const isSeasonLogo = provisioningResponse.logo.seasonYn || false;
    const nowDate = Number(String(Date.now()).substring(0, 10)); // 현재시간 분까지 표기
    const showSeasonLogo = isSeasonLogo && nowDate >= provisioningResponse.logo.seasonStart && nowDate <= provisioningResponse.logo.seasonEnd;

    return {
      region: provisioningResponse.region,
      baseUrl: provisioningResponse.baseUrl,
      isSeasonLogo: showSeasonLogo,
      logoImgUrl: showSeasonLogo ? provisioningResponse.logo.season.imgUrl : provisioningResponse.logo.basic.imgUrl,
      logoImgOnUrlList: showSeasonLogo ? provisioningResponse.logo.season.imgOnUrl : provisioningResponse.logo.basic.imgOnUrl,
      logoImgOffUrlList: showSeasonLogo ? provisioningResponse.logo.season.imgOffUrl : provisioningResponse.logo.basic.imgOffUrl,
      rightTopMenu: provisioningResponse.provisioning.rightTopMenu || [],
      topMenu: provisioningResponse.provisioning.topMenu,
      middleMenu: provisioningResponse.provisioning.middleMenu || [],
      bottomMenu: provisioningResponse.provisioning.bottomMenu || [],
    };
  }
  return {
    region: '',
    baseUrl: '',
    isSeasonLogo: false,
    logoImgUrl: '',
    logoImgOnUrlList: [],
    logoImgOffUrlList: [],
    rightTopMenu: [],
    topMenu: [],
    middleMenu: [],
    bottomMenu: [],
  } as TvingProvisioning;
};

export default proxyProvisioning;
