import { create } from 'zustand';

interface SportsTypeStore {
    sportsType: string | undefined;
    setSportsType: (type: string) => void;
}

const useSportsTypeStore = create<SportsTypeStore>((set) => ({
    sportsType: undefined,
    setSportsType: (type) => set({ sportsType: type }),
}));
export default useSportsTypeStore;
