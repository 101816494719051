export interface LabelProps {
  alt: string;
  labelImage?: string;
  SvgLabelImage?: string;
  tabIndex?: number;
}
const Label = ({ labelImage, SvgLabelImage, tabIndex, alt }: LabelProps) => {
  return (
    <>
      {SvgLabelImage && <SvgLabelImage />}
      {labelImage && <img src={labelImage} alt={alt} tabIndex={tabIndex} />}
    </>
  );
};

export default Label;
