import { OnboardingModalFooterButton } from '@components/common/modal/onboardingModal';
import ReactModal from 'react-modal';
import type { Props as ReactModalProps } from 'react-modal';
import CustomOverlayElement from '@components/common/modal/onboardingModal/CustomOverlayElement';
import BodyScrollLocker from '@components/common/bodyScrollLocker';
import { KboMyTeamSettingModalContextProvider } from '@components/kbo/myteam/setting/KboMyTeamSettingModalContext';
import { Optional } from '@tving/utils/src/types/utility';
import cnx from '@utils/tailwind/cnx';
import { IconPopupClose } from '@components/atom/SVG';
import KboMyTeamSettingHeader from '@components/kbo/myteam/setting/KboMyTeamSettingHeader';
import KboMyteamSettingItems from '@components/kbo/myteam/setting/KboMyteamSettingItems';
import KboMyTeamSettingFooter from '@components/kbo/myteam/setting/KboMyTeamSettingFooter';
import useKboMyTeamSetting from '@hooks/kbo/router/myTeam/useKboMyTeamSetting';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import amplitudeEvent from '@tving/utils/src/utils/amplitude/amplitudeEvent';
import getBasicEventProperties from '@utils/amplitude/eventProperties/getBasicEventProperties';
import { useEffect, useRef } from 'react';

/**
 * 마이팀 설정 화면- PC 화면에서는 모달 형태로 노출한다.
 * @constructor
 */

interface KboMyTeamSettingModalProps {
    isOpen: boolean;
    onClose: () => void;
    bodyContent?: React.ReactNode;
    footerButtons?: OnboardingModalFooterButton[];
}

const KBO_MYTEAM_SETTING_MODAL_CONFIG: Optional<ReactModalProps> = {
    shouldCloseOnOverlayClick: true,
    shouldCloseOnEsc: true,
    preventScroll: true,
    shouldFocusAfterRender: true,
    bodyOpenClassName: null,
    overlayClassName: 'fixed inset-x-0 inset-y-0 bg-[rgba(0,0,0,0.8)] z-[1000]',
    className: cnx(
        'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
        'w-[40rem] h-[50rem]',
        'border border-solid border-gray-25 bg-gray-10',
        'shadow-[0_8px_8px_0px_rgba(0,0,0,0.25)]',
        'overflow-hidden',
    ),
};

const getMyTeamClassNames = () => {
    const titleClass = 'text-white w-full text-[1.5rem] text-[1.5rem] font-bold mb-[0.33rem]';
    const subTitleClass = 'text-gray-45 text-[1rem] h-[1.417rem] w-full';

    const headerClass = 'flex justify-center flex-col px-[2.5rem] py-[1rem] mt-[1.67rem] w-full h-[5.9166rem]';

    const myTeamsClass = 'myteam-setting-contents scrollbar-hidden  grow overflow-scroll pb-[7.5rem]';
    const footerClass = 'myteam-setting-footer w-full h-[10rem] absolute bottom-[-2px] z-10 flex items-center justify-center pointer-events-none';
    const footerButtonClass =
        'bg-[#ff153c] xt-[#ffffff] text-[1.333rem] flex items-center justify-center rounded-[0.25rem] font-bold w-[35rem] h-[5rem] pointer-events-auto';

    return {
        titleClass,
        subTitleClass,
        headerClass,
        myTeamsClass,
        footerClass,
        footerButtonClass,
    };
};

const KboMyTeamSettingModal = ({ isOpen = false, onClose }: KboMyTeamSettingModalProps) => {
    const { pickedTeam, setPickedTeam, handleClickSave, kboMyTeams } = useKboMyTeamSetting({ onClose });
    const { titleClass, subTitleClass, headerClass, myTeamsClass, footerClass, footerButtonClass } = getMyTeamClassNames();
    const hasEventFired = useRef(false);

    const closeComponent = (
        <button type="button" onClick={onClose} className="absolute w-[1.243rem] h-[1.243rem] right-[2.045rem] top-[2.045rem]">
            <IconPopupClose />
        </button>
    );

    useEffect(() => {
        if (!hasEventFired.current) {
            amplitudeEvent({
                event: 'enter_kbo_myteam_select_pc',
                eventProperties: getBasicEventProperties({ screenName: 'myteam_select' }),
            });
            hasEventFired.current = true;
        }
    }, []);

    return (
        <ReactModal {...KBO_MYTEAM_SETTING_MODAL_CONFIG} overlayElement={CustomOverlayElement} isOpen={isOpen}>
            <BodyScrollLocker />
            <KboMyTeamSettingModalContextProvider onClose={() => {}}>
                <div
                    className={tempCnx(
                        'flex flex-col w-[40rem] h-[50rem]',
                        // 'border border-solid border-gray-25 bg-gray-10',
                        // 'shadow-[0_8px_8px_0px_rgba(0,0,0,0.25)]',
                    )}
                >
                    <KboMyTeamSettingHeader titleClass={titleClass} subTitleClass={subTitleClass} headerClass={headerClass} />
                    <KboMyteamSettingItems
                        myTeamsClass={myTeamsClass}
                        pickedTeam={pickedTeam}
                        setPickedTeam={setPickedTeam}
                        kboMyTeams={kboMyTeams}
                    />
                    <div className="w-full h-[10px]" />
                    <KboMyTeamSettingFooter
                        handleClickSave={handleClickSave}
                        footerButtonClass={footerButtonClass}
                        footerClass={footerClass}
                        isMobile={false}
                    />
                    {closeComponent}
                </div>
            </KboMyTeamSettingModalContextProvider>
        </ReactModal>
    );
};
export default KboMyTeamSettingModal;
