import { imgOrigin } from '@utils/apiConfig';
import { getAgeGrade } from '@utils/common/Code';
import { EpisodeContent, ProgramContent, VodContent, VodSchedule } from '@tving/utils/src/apis/types';
import { ProxyLive, ProxyMovie, ProxyProgram } from '@store/apis/types';
import { AxiosRequestConfig } from 'axios';

export const apiProxy = (res, apiUrl: string, fetchData?: AxiosRequestConfig) => {
    let proxyApiResult = res;

    let results = res.body?.result;
    const type: string = res.body?.type;
    // const res.

    if (['ONLY', 'ORIGINAL', 'ORIGINAL & ONLY'].includes(type)) {
        const fixedContents = proxyApiResult.body.fix_contents ? proxyApiResult.body.fix_contents : [];
        const originalContents = proxyApiResult.body.contents ? proxyApiResult.body.contents : [];

        results = [...fixedContents, ...originalContents];
    }
    // curation API 대응..
    if (!results && proxyApiResult.body?.curation_code) {
        results = [proxyApiResult.body];
    } else if (proxyApiResult.body && proxyApiResult.body['recommendation-uuid']) {
        // recommend API 대응

        // ~님이 좋아할만한 영화
        if (proxyApiResult.body?.recommend3) {
            results = proxyApiResult.body?.recommend3?.map((v) => v.meta);
        }
        if (results && Array.isArray(results.vod_list)) {
            results = results.vod_list?.map((v) => ({ ...v, media_type: 'CSMD0100' }));
        }
        // 방송 상세 장르 추천 (다중 띠)
        if (Array.isArray(proxyApiResult.body?.result)) {
            proxyApiResult = recommendApiProxy(proxyApiResult.body?.result as []);
        }
    }

    if (Array.isArray(results) && results[0] && results[0].theme_seq) {
        // 방송 테마
        proxyApiResult = themeApiProxy(results);
    } else if (Array.isArray(results) && results[0] && proxyApiResult.body && proxyApiResult.body.api_uri === '/v2/media/prerelease/contents') {
        proxyApiResult = {
            ...proxyApiResult,
            ...prereleaseListApiProxy(results),
        };
    } else if (!Array.isArray(results) && results?.theme_seq) {
        // 특정 테마 시퀀스로 띠 호출하는 경우 (모바일 상세화면의 "오직 티빙에서만")

        results?.contents?.forEach((d) => {
            const target = d.content || d;
            const mediaType = target.vod_type || '';
            let item: ProxyLive & ProxyProgram & ProxyMovie = {};

            // mediaType 별 구분해서 Proxy
            switch (mediaType) {
                case 'CSMD0100': // Episode
                    item = proxyProgram(item, target);
                    break;
                case 'CSMD0200': // Movie
                    item = proxyMovie(item, target);
                    break;
                default:
                    item = target;
                    break;
            }

            Object.keys(item as object).forEach((key) => {
                if (!item[key]) {
                    delete item[key];
                }
            });
            if (!proxyApiResult.items) {
                proxyApiResult.items = [];
            }
            proxyApiResult.items.push(item);
        });
    } else if (Array.isArray(results) && results[0] && results[0].curation_code) {
        // 영화 테마
        proxyApiResult = curationApiProxy(results);
        /* } else if (Array.isArray(results) && results[0] && results[0].banner_type) {
// 상단 배너, 장르, 단일 배너
//res = curationApiProxy(results);
console.log(results);
debugger; */
    } else if (Array.isArray(results) && results[0] && results[0].episode && results[0].vod_name && !results[0].id) {
        // 회차 보기
        // console.log('회차보기', results[0].vod_name);
        // console.log(results);
        proxyApiResult = episodeListApiProxy(results);
    } else if (Array.isArray(results) && ['ONLY', 'ORIGINAL', 'ORIGINAL & ONLY'].includes(type)) {
        proxyApiResult.items = tvingOriginalApiProxy(results);
    } else if (Array.isArray(results)) {
        results?.forEach((d) => {
            const target: VodContent & VodSchedule = d?.banner_type || d?.notice_seq ? d : d.content || d.CONTENT_RESULT || d;
            // CONTENT_RESULT : 구매내역인 경우

            if (typeof target === 'object') {
                // CONTENT_RESULT 값이 null 인 경우는 구매 콘텐츠 중 서비스 종료된 콘텐츠 (그 외 API 는 undefined)
                if (d.CONTENT_RESULT === null) {
                    return;
                }
                target.lastPlayTime = d.lastPlayTime ? Number(d.lastPlayTime) : 0;
                // 시청 일자는 d.content 아닌 d 하위에 존재
                target.viewDate = d.viewDate || '';
                target.expireDate = d.EXPDATE || '';
                target.purchaseDate = d.CONSUMEDATE || '';
                target.price = d.PRICE || '';
                target.chargeNo = d.CHARGENO || ''; // 구매콘텐츠인 경우 구매 번호 (삭제를 위함)
                target.remainDay = d.REMAINDAY || ''; // 구매콘텐츠인 경우 남은 날짜
                target.masterSeq = d.master_seq || ''; // 시청콘텐츠인 경우 마스터 번호 (삭제를 위함)
            }

            let mediaType =
                target.schedule?.channel?.type ||
                target.vod_type ||
                target.banner_type || // 장르인 경우 배너타입에 코드 존재
                target.contents_code ||
                target.media_type ||
                // || target.movies?.get(0).type
                '';
            if (mediaType === '' && target.channel_name === 'BUNDESLIGA') {
                mediaType = 'CPCS0100';
            }
            let item = {};

            // mediaType 별 구분해서 Proxy
            switch (mediaType) {
                case 'CPCS0100': // Live
                    item = proxyLive(item, target);
                    break;
                case 'CPCS0300': // TVING TV
                    target.isTvingtv = true;
                    item = proxyLive(item, target);
                    break;
                case 'CPCS0400': // TVING LIVE
                    // target['isTvingtv'] = true;
                    item = proxyLive(item, target);
                    break;
                case 'CSMD0100': // Episode
                    item = proxyProgram(item, target);
                    break;
                case 'CSMD0200': // Movie
                    item = proxyMovie(item, target);
                    break;
                case 'AMTP0600': // Genre
                    item = proxyGenre(item, target, apiUrl);
                    break;
                default:
                    item = target;
                    break;
            }

            Object.keys(item as object).forEach((key) => {
                if (!item[key]) {
                    delete item[key];
                }
            });

            //
            if (!proxyApiResult.items) {
                proxyApiResult.items = [];
            }
            proxyApiResult.items.push(item);
        });
    } else {
        proxyApiResult.items = results;
    }

    //
    if (proxyApiResult.body?.has_more) {
        proxyApiResult.body.isMore = proxyApiResult.body?.has_more === 'Y';
    } else if (proxyApiResult.body) {
        if (proxyApiResult.header.status !== 400 && fetchData?.params?.pageSize) {
            proxyApiResult.body.isMore = fetchData?.params?.pageSize === proxyApiResult.body?.result?.length;
        }
    }

    proxyApiResult.apiUrl = apiUrl;

    return proxyApiResult;
};

export const proxyLive = (data: ProxyLive, res: VodContent & VodSchedule): ProxyLive => {
    const proxyLiveResult = data;
    proxyLiveResult.type = 'live';
    proxyLiveResult.broadcastDate = res.schedule?.broadcast_date;
    proxyLiveResult.broadcastEndTime = res.schedule?.broadcast_end_time;
    proxyLiveResult.broadcastStartTime = res.schedule?.broadcast_start_time;

    proxyLiveResult.gradeCode = res.schedule?.grade_code || res.schedule?.program?.grade_code || res.schedule?.movie?.grade_code;
    // eslint-disable-next-line max-len
    proxyLiveResult.ageGrade = getAgeGrade(res.schedule?.episode?.grade_code || res.schedule?.program?.grade_code || res.schedule?.movie?.grade_code); // 시청 연령
    proxyLiveResult.code = res.schedule?.schedule_code;
    proxyLiveResult.contentType = res.schedule?.channel?.type;
    proxyLiveResult.title = res.schedule?.channel?.name?.ko;

    if (res.isTvingtv) {
        proxyLiveResult.subtitle = res.schedule?.episode?.frequency ? `${res.schedule?.episode?.frequency}화` : res.schedule?.movie?.name?.ko;
    } else {
        proxyLiveResult.subtitle =
            (res.schedule?.program?.name?.ko || '') + (res.schedule?.episode?.frequency ? ` ${res.schedule?.episode?.frequency}화` : '');
    }

    proxyLiveResult.programName = res.schedule?.program?.name?.ko || '';
    proxyLiveResult.movieName = res.schedule?.movie?.name?.ko || '';
    proxyLiveResult.frequency = res.schedule?.episode?.frequency || '';
    proxyLiveResult.channelName = res.schedule?.channel?.name?.ko || '';
    proxyLiveResult.channelCode = res.channel_code || '';
    proxyLiveResult.categoryName1 = res.schedule?.program?.category1_name?.ko || '';
    proxyLiveResult.categoryName2 = res.schedule?.program?.category2_name?.ko || '';
    proxyLiveResult.reserveTime = res.reserve_time || '';
    proxyLiveResult.rating = res.live_rating?.realtime_chtp || ''; // 시청률
    proxyLiveResult.isFree = res.schedule?.free_yn === 'Y';
    proxyLiveResult.isAdult = res.schedule?.adult_yn === 'Y';
    proxyLiveResult.mediaCode = res.schedule?.episode?.code || res.schedule?.program?.code || ''; // 실시간채널에서 재생되는 미디어코드 (영화인 경우 일반적으로 P코드)

    // 채널 권리사 요청 블락 여부.
    try {
        proxyLiveResult.isChannelBlock = res?.schedule?.broadcast_url?.[0]?.block_yn === 'Y';
    } catch (e) {
        proxyLiveResult.isChannelBlock = false;
    }

    if (proxyLiveResult.contentType === 'CPCS0400') {
        proxyLiveResult.type = 'tvinglive';
    }

    // TVING LIVE 인 경우 프로그램 이미지 노출
    // case 'CPCS0100': // Live
    // case 'CPCS0300': // TVING TV
    // case 'CPCS0400': // TVING LIVE
    if (proxyLiveResult.contentType === 'CPCS0300' || proxyLiveResult.contentType === 'CPCS0400') {
        if (res.schedule?.program) {
            // 티빙라이브의 경우 이미지 구좌코드 CAIP0500 사용
            const code = proxyLiveResult.contentType === 'CPCS0300' ? 'CAIP0200' : 'CAIP0500';
            proxyLiveResult.thumbnail = imgOrigin + (res.schedule?.program?.image?.filter((d) => d.code === code)[0]?.url || '');
        } else {
            proxyLiveResult.thumbnail = getSelectThumbnail([
                res.schedule?.movie?.image?.filter((d) => d.code === 'CAIM0400')[0]?.url,
                res.schedule?.movie?.image?.filter((d) => d.code === 'CAIM0700')[0]?.url,
            ] as string[]);
        }
    } else {
        // stillshot 이 없는 채널도 있는거 같음. 개발환경이라.. 그런가
        // fallback 이미지 고민. 프로그램 이미지 노출하는 것 같음.
        proxyLiveResult.thumbnail = `//stillshot.tving.com/thumbnail/${proxyLiveResult.code}_1_640x360.jpg`;
    }

    proxyLiveResult.programThumbnail = getSelectThumbnail([
        res.schedule?.program?.image?.filter((d) => d.code === 'CAIP0500')[0]?.url,
        res.schedule?.episode?.image?.filter((d) => d.code === 'CAIE0400')[0]?.url,
        res.schedule?.movie?.image?.filter((d) => d.code === 'CAIM0400')[0]?.url,
        res.schedule?.movie?.image?.filter((d) => d.code === 'CAIM0700')[0]?.url,
        res.schedule?.channel?.image?.filter((d) => d.code === 'CAIC1500')[0]?.url,
    ] as string[]);
    // My 예약 내역에서 보여줄 프로그램 or 에피소드 썸네일

    // 실시간 재생화면에서 표시할 채널 로고 이미지
    proxyLiveResult.channelLogoImage = res.schedule?.channel?.image?.filter((d) => d.code === 'CAIC2300')[0]?.url || '';

    // 분데스리가 예약내역을 위한 소스 (분데스리가 제거 시 삭제 필요)
    // BUNDESLIGA 스케줄 등록이 안될경우 예외처리, 분데스리가 채널명과 이미지만 바꾸면 바뀐이미지로
    if (res.channel_name === 'BUNDESLIGA') {
        proxyLiveResult.programThumbnail = `${imgOrigin}/upload/cms/caip/CAIP0400/P001492216.jpg`;
        proxyLiveResult.channelName = 'BUNDESLIGA';
        proxyLiveResult.code = res.channel_code || '';
        proxyLiveResult.title = res.episode_name || '';
    }

    return proxyLiveResult;
};

const getSelectThumbnail = (arr: (string | undefined)[] = []): string => {
    const targetImage = arr.filter((item) => item)[0];
    return targetImage ? imgOrigin + targetImage : '';
};

export const proxyProgram = (data: ProxyProgram, res: VodContent & VodSchedule) => {
    if (res.program === null) {
        res.program = undefined;
    }

    if (res.episode === null) {
        res.episode = undefined;
    }
    const {
        program: {
            grade_code: gradeCode,
            code: programCode,
            tving_exclusive_yn: exclusiveYn,
            tving_original_yn: originalYn,
            event_yn: eventYn,
            quickup_yn: quickupYn,
            last_frequency_yn: lastFrequencyYn,
            image: programImgArr = [],
            free_yn: programFreeYn,
        } = {} as ProgramContent,
        episode: {
            code: episodeCode,
            grade_code: episodeGradeCode,
            frequency,
            display_title: displayTitle,
            duration,
            broadcast_date: broadcastDate,
            service_open_date: serviceOpenDate,
            broadcast_datetime: broadcastDatetime,
            image: episodeImgArr = [],
            free_yn: episodeFreeYn,
            paramount_yn: paramountYn,
        } = {} as EpisodeContent,
        enm_code: enmCode,
        vod_code: vodCode,
        vod_type: vodType,
        free_yn: freeYn,
        fan_yn: fanYn,
        adult_yn: adultYn,
        lastPlayTime,
        viewDate,
        purchaseDate,
        expireDate,
        price,
        chargeNo,
        remainDay,
        masterSeq,
        vod_name: { ko: vodName } = {},
    } = res || ({} as VodContent);
    const subtitle = (frequency >= 0 && `${frequency}화`) || '';

    const programName = res.program?.name?.ko ?? '';
    const categoryName1 = res.program?.category1_name?.ko ?? '';
    const categoryName2 = res.program?.category2_name?.ko ?? '';

    // 프로그램 포스터 code
    const code = 'CAIP0900';
    // 에피소드 포스터 code
    const watchCode = 'CAIE0400';

    // freeYn이 존재하지 않는 경우, episode 또는 program의 메타 정보로 결정합니다.
    let isFree = false;
    if (freeYn === 'Y') {
        isFree = true;
    } else if (programFreeYn === 'Y') {
        isFree = true;
    } else if (episodeFreeYn === 'Y') {
        isFree = true;
    }

    const proxyProgramResult: ProxyProgram = {
        ...data,
        type: 'episode',
        gradeCode,
        ageGrade: getAgeGrade(gradeCode), // 시청 연령
        episodeGradeCode,
        episodeAgeGrade: getAgeGrade(episodeGradeCode), // QuickVOD 띠에서 에피소드의 19세 연령을 표시하기 위함
        enmCode,
        code: vodCode || episodeCode || '', // TV프로그램 상세 > 비슷한 콘텐츠의 경우 vod_code가 없음
        programCode,
        masterCode: programCode,
        contentType: vodType,
        title: programName,
        subtitle,
        vodName: vodName || '',
        isFree,
        isFan: fanYn === 'Y',
        isAdult: adultYn === 'Y',
        isExclusive: exclusiveYn === 'Y',
        isOriginal: originalYn === 'Y',
        isEvent: eventYn === 'Y', // 이벤트 여부 값 없음!!api 확인 필요
        isQuickVod: quickupYn === 'Y',
        isLast: lastFrequencyYn === 'Y', // 최신 여부
        isParamount: paramountYn === 'Y', // 파라마운트 여부
        categoryName1,
        categoryName2,
        frequency, // 회차
        duration, // 시청중인 콘텐츠 경우 세청 퍼센트 계산을 위한 영상 전체 시간
        lastPlayTime, // 시청중인 콘텐츠 경우 세청 퍼센트 계산을 위한 시청 시간
        insertDate: broadcastDate || serviceOpenDate || broadcastDatetime, // 에피소드 업데이트 시간 (최신표지 배지를 위함)
        viewDate, // 시청일자
        purchaseDate, // 구매일
        expireDate, // 유효 기간
        price, // 결제 금액
        chargeNo, // 구매 고유 넘버 (삭제를 위함)
        remainDay, // 구매 콘텐츠인 경우 남은 날짜
        masterSeq, // 시청콘텐츠인 경우 마스터 번호 (삭제를 위함)\
        thumbnail: imgOrigin + (programImgArr.filter((d) => d.code === code)[0]?.url || ''),
        verticalThumbnail: imgOrigin + (programImgArr.filter((d) => d.code === 'CAIP1500')[0]?.url || ''), // quickVOD 에서 사용할 가로 포스터 이미지

        watchThumbnail: getSelectThumbnail([
            episodeImgArr.filter((d) => d.code === watchCode)[0]?.url || '',
            programImgArr.filter((d) => d.code === 'CAIP0400')[0]?.url || '',
            programImgArr.filter((d) => d.code === code)[0]?.url || '',
        ] as string[]),

        logoImage: programImgArr.filter((d) => d.code === 'CAIP1800')[0]?.url,
        displayTitle,
    };

    // 부가영상 (클립)
    if (res.clip_info) {
        // 부가영상
        proxyProgramResult.code = res.clip_info.code;
        proxyProgramResult.programCode = res.vod_code || '';
        proxyProgramResult.title = res.clip_info.title || '';
        proxyProgramResult.subtitle = res.clip_info.synopsis || '';
        proxyProgramResult.thumbnail = imgOrigin + res.clip_info.image;
    }

    return proxyProgramResult;
};

export const proxyMovie = (data: ProxyMovie, res: VodContent & VodSchedule) => {
    const proxyMovieResult = data;

    proxyMovieResult.type = 'movie';
    proxyMovieResult.gradeCode = res.movie?.grade_code;
    proxyMovieResult.ageGrade = getAgeGrade(res.movie?.grade_code); // 시청 연령
    proxyMovieResult.enmCode = res.enm_code;
    proxyMovieResult.code = res.vod_code;
    proxyMovieResult.masterCode = res.vod_code;
    proxyMovieResult.contentType = res.vod_type;
    proxyMovieResult.title = res.movie?.name?.ko;
    proxyMovieResult.genre = res.movie?.category1_name?.ko;
    proxyMovieResult.categoryName1 = res.movie?.category1_name?.ko;
    proxyMovieResult.categoryName2 = res.movie?.category2_name?.ko;
    proxyMovieResult.summary = res.movie?.story?.ko;
    // console.log('proxyMovie', res);

    let isFree = false;
    if (res?.free_yn === 'Y') {
        isFree = true;
    } else if (res?.movie?.free_yn === 'Y') {
        isFree = true;
    }

    proxyMovieResult.isFree = res.free_yn === 'Y'; // 무료 여부
    proxyMovieResult.isFan = res.fan_yn === 'Y';
    proxyMovieResult.isAdult = res.adult_yn === 'Y'; // 성인인증 필요여부
    proxyMovieResult.isExclusive = res.movie?.tving_exclusive_yn === 'Y'; // 티빙온리 여부
    proxyMovieResult.isEvent = res.movie?.event_yn === 'Y'; // 이벤트 여부
    proxyMovieResult.isOriginal = res.movie?.tving_original_yn === 'Y'; // 오리지널 여부
    proxyMovieResult.isFirstRelease = res.movie?.first_open_yn === 'Y'; // 최초개봉 여부
    proxyMovieResult.isCinemaSame = res.movie?.cine_same_yn === 'Y'; // 극장동시 여부
    proxyMovieResult.isSubtitle = res.movie?.subtitle_ver_yn === 'Y'; // 자막 여부
    proxyMovieResult.isDubbing = res.movie?.dub_ver_yn === 'Y'; // 더빙 여부
    proxyMovieResult.isParamount = res.movie?.paramount_yn === 'Y'; // 더빙 여부
    proxyMovieResult.duration = res.movie?.duration;
    proxyMovieResult.lastPlayTime = res.lastPlayTime; // 시청중인 콘텐츠 경우 세청 퍼센트 계산을 위한 시청 시간
    proxyMovieResult.viewDate = res.viewDate; // 시청일자
    proxyMovieResult.purchaseDate = res.purchaseDate; // 구매일
    proxyMovieResult.expireDate = res.expireDate; // 유효 기간
    proxyMovieResult.price = res.price; // 결제 금액
    proxyMovieResult.chargeNo = res.chargeNo; // 구매 고유 넘버 (삭제를 위함)
    proxyMovieResult.remainDay = res.remainDay; // 구매 콘텐츠인 경우 남은 날짜
    proxyMovieResult.masterSeq = res.masterSeq; // 시청콘텐츠인 경우 마스터 번호 (삭제를 위함)

    proxyMovieResult.billingTag = res.movie?.billing_package_tag;

    // 영화 포스터 code
    const code = 'CAIM2100';
    proxyMovieResult.thumbnail = imgOrigin + (res.movie?.image?.filter((d) => d.code === code)[0]?.url || '');

    // 재생중인, 시청내역 및 구매내역 영화 포스터,스틸샷 code
    let watchThumbnail = '';
    if (res.movie?.image?.find((d) => d.code === 'CAIM0400')) {
        // 가로 스틸샷 대표 이미지
        watchThumbnail = res.movie?.image?.find((d) => d.code === 'CAIM0400')?.url || '';
    } else if (res.movie?.image?.find((d) => d.code === 'CAIM0700')) {
        watchThumbnail = res.movie?.image?.find((d) => d.code === 'CAIM0700')?.url || '';
    } else if (res.movie?.image?.find((d) => d.code === 'CAIM0200')) {
        watchThumbnail = res.movie?.image?.find((d) => d.code === 'CAIM0200')?.url || '';
    }
    proxyMovieResult.watchThumbnail = imgOrigin + watchThumbnail;

    // 부가영상 (클립)
    if (res.clip_info) {
        // 부가영상
        proxyMovieResult.code = res.clip_info.code;
        proxyMovieResult.programCode = res.vod_code || '';
        proxyMovieResult.title = res.clip_info.title || '';
        proxyMovieResult.subtitle = res.clip_info.synopsis || '';
        proxyMovieResult.thumbnail = imgOrigin + res.clip_info.image;
    }

    return proxyMovieResult;
};

export const proxyGenre = (data, res, apiUrl = '') => {
    const { banner_sub_title, banner_title, banner_image_url, banner_link_url, background_color_left, background_color_right } = res.content;
    const thumbnail = String(banner_image_url || '').replace('http:', 'https:');

    const proxyGenreResult = data;

    proxyGenreResult.type = apiUrl.includes('PC_RE_MOVIEHOME_CATEGORY') ? 'movie' : 'program';
    proxyGenreResult.code = banner_sub_title;
    proxyGenreResult.title = banner_title;
    proxyGenreResult.thumbnail = thumbnail.includes('https:') ? thumbnail : imgOrigin + thumbnail;
    proxyGenreResult.href = banner_link_url;
    proxyGenreResult.bgcolor = background_color_left || background_color_right;

    if (proxyGenreResult.type === 'movie' && proxyGenreResult.code === 'RCM') {
        proxyGenreResult.code = proxyGenreResult.code.replace('RCM', 'diversity');
    }

    return proxyGenreResult;
};

export const themeApiProxy = (
    res: {
        theme_seq: string;
        title: string;
        contents: object[];
    }[] & {
        bands?: object[];
    },
) => {
    Array.isArray(res) &&
        res?.forEach((d) => {
            const item = {} as {
                code: string;
                title: string;
                type: string;
                items: (ProxyLive & ProxyProgram & ProxyMovie)[];
            };
            item.code = d.theme_seq;
            item.title = d.title;
            item.type = 'theme';
            item.items = themeProgramProxy(d.contents);

            if (!res.bands) {
                res.bands = [];
            }
            res.bands.push(item);
        });
    return res;
};
// Episode List API 프록시
export const episodeListApiProxy = (items) => {
    const returnData = {} as {
        items: (ProxyLive & ProxyProgram & ProxyMovie)[];
    };
    returnData.items = [];

    Array.isArray(items) &&
        items?.forEach((item, idx) => {
            const data = {} as ProxyLive & ProxyProgram & ProxyMovie;
            data.order = idx + 1;
            data.displayTitle = item.episode?.display_title || '';
            data.frequency = item.episode?.frequency;
            data.title = item.vod_name?.ko || '';
            data.code = item.episode?.code || '';
            data.synopsis = item.episode?.synopsis?.ko || '';
            data.broadcastDate = item.episode?.broadcast_date || '';
            data.duration = item.episode?.duration || 0; // 영상 전체 시간
            data.isFree = item.episode?.free_yn === 'Y'; // 무료여부
            data.programName = item.program?.name?.ko || ''; // 프로그램명
            data.isQuickVod = item.episode?.pip_cliptype === 'C012'; // 에피소드의 QuickVOD 여부
            data.ageGrade = getAgeGrade(item.episode?.grade_code); // 시청 연령
            data.episodeGradeCode = item.episode?.grade_code || ''; // 시청 연령코드
            const code = 'CAIE0400';
            data.thumbnail = getSelectThumbnail([
                item.episode?.image?.filter((d) => d.code === code)[0]?.url,
                item.program?.image?.filter((d) => d.code === 'CAIP0400')[0]?.url,
                item.program?.image?.filter((d) => d.code === 'CAIP0900')[0]?.url,
            ] as string[]);

            if (data.isQuickVod) {
                data.thumbnail = imgOrigin + (item.program?.image?.filter((d) => d.code === 'CAIP1500')[0]?.url || ''); // quickVOD 에서 사용할 가로 포스터 이미지
            }

            returnData.items.push(data);
        });

    return returnData;
};

// Curation API 프록시
export const curationApiProxy = (
    res: {
        curation_code: string;
        curation_name: string;
        movies: object[];
    }[] & {
        bands?: object[];
    },
) => {
    Array.isArray(res) &&
        res?.forEach((d) => {
            const item = {} as {
                code: string;
                title: string;
                type: string;
                items: (ProxyLive & ProxyProgram & ProxyMovie)[];
            };
            item.code = d.curation_code;
            item.title = d.curation_name;
            item.type = 'curation';
            // item.data = {}
            // item.data.items
            item.items = curationMovieProxy(d.movies);

            if (!res.bands) {
                res.bands = [];
            }
            res.bands.push(item);
        });

    return res;
};

// 방송 테마 API 프록시
export const themeProgramProxy = (items) => {
    const returnItems: (ProxyLive & ProxyProgram & ProxyMovie)[] = [];

    Array.isArray(items) &&
        items?.forEach((item) => {
            const data = proxyProgram({}, item.content);
            data.title = item.content.program?.name?.ko;
            data.programCode = item.content.program?.code;
            data.code = item.content.vod_code;
            data.contentType = item.content.vod_type;
            data.insertDate = item.content.insert_date;
            const code = 'CAIP0900';
            data.thumbnail = imgOrigin + (item.content.program?.image?.filter((d) => d.code === code)[0]?.url || '');

            returnItems.push(data);
        });
    return returnItems;
};

// Curation API 프록시
export const curationMovieProxy = (items) => {
    // console.log('curationProgramProxy');
    const returnItems = [] as (ProxyLive & ProxyProgram & ProxyMovie)[];

    Array.isArray(items) &&
        items?.forEach((item) => {
            const data = {} as ProxyLive & ProxyProgram & ProxyMovie;
            data.type = 'movie';
            data.title = item.name?.ko;
            data.genre = item.category1_name?.ko;
            data.categoryName1 = item.category1_name?.ko;
            data.categoryName2 = item.category2_name?.ko;
            data.summary = item.story?.ko;
            data.code = item.code;
            data.contentType = item.type;

            data.isFree = item.free_yn === 'Y'; // 무료 여부
            data.isFan = item.fan_yn === 'Y';
            data.isAdult = item.adult_yn === 'Y'; // 성인인증 필요여부
            data.isExclusive = item.tving_exclusive_yn === 'Y'; // 티빙온리 여부
            data.isEvent = item.event_yn === 'Y'; // 이벤트 여부
            data.isOriginal = item.tving_original_yn === 'Y'; // 오리지널 여부
            data.isFirstRelease = item.first_open_yn === 'Y'; // 최초개봉 여부
            data.isCinemaSame = item.cine_same_yn === 'Y'; // 극장동시 여부
            data.isSubtitle = item.subtitle_ver_yn === 'Y'; // 자막 여부
            data.isParamount = item.paramount_yn === 'Y'; // 파라마운트 여부
            data.isDubbing = item.dub_ver_yn === 'Y'; // 더빙 여부
            data.gradeCode = item.grade_code || ''; // 시청등급 코드
            data.ageGrade = getAgeGrade(item.grade_code); // 시청 연령
            data.duration = item.duration;
            data.billingTag = item.billing_package_tag;

            const code = 'CAIM2100';
            data.thumbnail = imgOrigin + (item.image?.filter((d) => d.code === code)[0]?.url || '');
            returnItems.push(data);
        });
    return returnItems;
};

// 방송 상세 장르 추천
export const recommendApiProxy = (
    res: {
        category_code: string;
        genre_code: string;
        genre_name: string;
        vod_list: object[];
    }[] & {
        bands?: object[];
    },
) => {
    Array.isArray(res) &&
        res?.forEach((d) => {
            const item = {} as {
                genre: string;
                code: string;
                title: string;
                type: string;
                items: (ProxyLive & ProxyProgram & ProxyMovie)[];
            };
            item.genre = d.category_code;
            item.code = d.genre_code;
            item.title = d.genre_name;
            item.type = 'recommend';
            item.items = recommendProgramProxy(d.vod_list);

            if (!res.bands) {
                res.bands = [];
            }
            res.bands.push(item);
        });
    return res;
};

export const recommendProgramProxy = (items) => {
    const returnItems = [] as (ProxyLive & ProxyProgram & ProxyMovie)[];

    Array.isArray(items) &&
        items?.forEach((item) => {
            // const data = {};
            const data = proxyProgram({}, item);
            returnItems.push(data);
        });
    return returnItems;
};

// 모바일 TVING SOON 띠 (공개예정 리스트)
export const prereleaseListApiProxy = (items) => {
    const returnData = {} as {
        items: (ProxyLive & ProxyProgram & ProxyMovie)[];
    };
    returnData.items = [];

    /**
     * [실시간 무료 관련]
     * 무료 여부(`free_yn`)와 파라마운트 여부(`paramount_yn`)이 누락된 API이지만,
     * 차후 SUS 건으로 대응할 예정이므로 수정하지 않습니다. (유지 방침)
     *
     * @see {@link https://tvingcorp.slack.com/archives/C0616J5K7QF/p1698297646893659?thread_ts=1698226253.742639&cid=C0616J5K7QF | 슬랙}
     */
    Array.isArray(items) &&
        items?.forEach((item) => {
            const data = {} as ProxyLive & ProxyProgram & ProxyMovie;
            data.type = item.vod_type === 'CSMD0200' ? 'movie' : 'episode';
            data.code = item.vod_code || '';
            data.programCode = item.vod_code || '';
            data.title = data.title === undefined ? item.vod_name : data.title;
            data.thumbnail = imgOrigin + item.poster_image;
            data.ageGrade = getAgeGrade(item.grade_code); // 시청 연령
            data.isExclusive = item.tving_exclusive_yn === 'Y'; // 티빙 온리
            data.isOriginal = item.tving_original_yn === 'Y'; // 티빙 오리지널

            returnData.items.push(data);
        });

    return returnData;
};

export const tvingOriginalApiProxy = (items) => {
    let returnData = [] as (ProxyLive & ProxyProgram & ProxyMovie)[];

    if (Array.isArray(items)) {
        returnData = items?.map((item) => {
            const data = {} as ProxyLive & ProxyProgram & ProxyMovie;
            data.code = item.vod_code || '';
            data.programCode = item.vod_code || '';
            data.title = item.vod_name || '';
            data.type = item.vod_code?.[0] === 'M' ? 'movie' : 'program';
            data.thumbnail = imgOrigin + item.image;
            data.isExclusive = item.tving_exclusive_yn === 'Y'; // 티빙 온리
            data.isOriginal = item.tving_original_yn === 'Y'; // 티빙 오리지널
            data.ageGrade = getAgeGrade(item.grade_code); // 시청 연령
            data.isAdult = item.adult_yn === 'Y'; // 성인인증 필요여부
            data.isFree = item.free_yn === 'Y'; // 무료 여부
            data.insertDate = item.broadcast_datetime || ''; // 최신표지 배지

            return data;
        });
    }

    return returnData;
};
