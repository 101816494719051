import ResizeObserver from 'resize-observer-polyfill';

export type ResizeListener = (entry: ResizeObserverEntry) => void;

const elementListeners = new Map<Element, Set<ResizeListener>>();

const globalResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    entries.forEach((entry) => {
        const listeners = elementListeners.get(entry.target);
        listeners?.forEach((listener) => listener(entry));
    });
});

/**
 * 다중 엘리먼트를 관찰할 때 단일 ResizeObserver 인스턴스를 사용하여 성능을 향상시키기 위한 클래스입니다.
 *
 * @see {@link https://groups.google.com/a/chromium.org/g/blink-dev/c/z6ienONUb5A/m/F5-VcUZtBAAJ}
 */
export default class GlobalResizeObserver {
    static observe(element: Element, listener: ResizeListener) {
        let listeners = elementListeners.get(element);

        if (!listeners) {
            listeners = new Set<ResizeListener>();
            listeners.add(listener);
            elementListeners.set(element, listeners);
            globalResizeObserver.observe(element);
        }

        listeners.add(listener);
        return () => GlobalResizeObserver.unobserve(element, listener);
    }

    static unobserve(element: Element, listener: ResizeListener) {
        const listeners = elementListeners.get(element);
        if (!listeners) {
            return;
        }

        if (!listeners.has(listener)) {
            return;
        }

        listeners.delete(listener);
        if (listeners.size === 0) {
            elementListeners.delete(element);
            globalResizeObserver.unobserve(element);
        }
    }

    static disconnect() {
        globalResizeObserver.disconnect();
        elementListeners.clear();
    }
}
