import Label from '../../../atom/label/Label';
import { LABEL_IMAGE, LABEL_TYPE } from '../../../constants/labelConstants';

interface DubbingLabelProps {
  type: 'PNG' | 'SVG';
  className?: string;
  tabIndex?: number;
}

const DubbingLabel = ({ type, className, tabIndex }: DubbingLabelProps) => {
  const IMAGE = LABEL_IMAGE[LABEL_TYPE.DUBBING];
  return (
    <div className={`dubbing-label-wrapper ${className}`} tabIndex={tabIndex}>
      <Label
        labelImage={(type === 'PNG' && IMAGE.PNG) || undefined}
        SvgLabelImage={(type === 'SVG' && IMAGE.SVG) || undefined}
        tabIndex={tabIndex}
        alt={IMAGE.ALT}
      />
    </div>
  );
};

export default DubbingLabel;
