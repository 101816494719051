export interface IconProps {
  alt: string;
  iconImage?: string;
  SvgIconImage?: string;
  tabIndex?: number;
  className?: string;
}
const Icon = ({ iconImage, SvgIconImage, tabIndex, alt, className }: IconProps) => {
  return (
    <>
      {SvgIconImage && (
        <div className={className}>
          <SvgIconImage />
        </div>
      )}
      {iconImage && <img src={iconImage} alt={alt} tabIndex={tabIndex} className={className} />}
    </>
  );
};

export default Icon;
