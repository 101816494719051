import { createContext, PropsWithChildren, useRef, useContext } from 'react';

import OneLinkSmartScript from './OneLinkSmartScript';
import { OneLinkSmartScriptConfigs } from './types';

const OneLinkSmartScriptContext = createContext<OneLinkSmartScript | null>(null);

export type OneLinkSmartScriptProviderProps = PropsWithChildren<{
  /**
   * OneLinkSmartScript를 초기화하기 위한 기본 설정값입니다. (최초 렌더링 단계 인스턴스 생성 시에서만 사용됩니다.)
   */
  configs: OneLinkSmartScriptConfigs;
}>;

/**
 * @deprecated OneLinkSmartScriptProvider는 더 이상 사용하지 않습니다.
 * `@tving/utils`의 `deferredDeepLinking`을 사용해 주세요.
 */
export const OneLinkSmartScriptProvider = ({ children, configs }: OneLinkSmartScriptProviderProps) => {
  const instance = useRef<OneLinkSmartScript>(new OneLinkSmartScript(configs));

  return <OneLinkSmartScriptContext.Provider value={instance.current}>{children}</OneLinkSmartScriptContext.Provider>;
};

/**
 * @deprecated useOneLinkSmartScript 더 이상 사용하지 않습니다.
 * `@tving/utils`의 `deferredDeepLinking`을 사용해 주세요.
 */
export const useOneLinkSmartScript = () => {
  const apis = useContext(OneLinkSmartScriptContext);

  if (!apis) {
    throw new Error('useOneLinkSmartScript를 사용하기 위해서는 OneLinkSmartScriptProvider로 감싸져 있어야 합니다.');
  }

  return apis;
};
