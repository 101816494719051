import { AspectRatio, Poster } from '@tving/ui';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import useLazyImage from '@hooks/useLazyImage';
import { IMAGE_RESIZE } from '@constants/image';
import useOptimizeImage from '@hooks/useOptimizeImage';
import useKboTeamQuery from '@hooks/kbo/queries/useKboTeamQuery';
import useClientStore from '@stores/useClientStore';
import { shallow } from 'zustand/shallow';
import { useState } from 'react';

const KboWallPaper = () => {
    const { data: kboTeamResponse } = useKboTeamQuery();
    const teamBackgroundImage = kboTeamResponse?.data?.backgroundImage ?? '';

    const backgroundImage = useOptimizeImage({ imageUrl: teamBackgroundImage, size: IMAGE_RESIZE.large });

    const [imgRef, backgroundImgDataSrc] = useLazyImage({
        src: backgroundImage,
    });

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const handleLoadImage = () => {
        setIsImageLoaded(true);
    };

    const { isMobile, isWebview, isTablet } = useClientStore(
        (state) => ({ isWebview: state.isWebview, isMobile: state.isMobile, isTablet: state.isTablet }),
        shallow,
    );

    const isMobileOrWebview = isMobile || isWebview;

    return (
        <AspectRatio
            key={backgroundImage}
            ratio={isMobileOrWebview && !isTablet ? 780 / 624 : 1615 / 430}
            className={tempCnx('kbo-wallpaper absolute top-0 right-0 left-0 w-screen pointer-events-none', isImageLoaded ? 'visible' : 'invisible')}
        >
            <Poster imgRef={imgRef} dataSrc={backgroundImgDataSrc} className={tempCnx('w-full h-full object-fill')} alt="" onLoad={handleLoadImage} />
            <aside className="absolute bottom-0 left-0 w-full h-[33.333rem] bg-gradient-to-t from-[#000000] lg:from-[1%] to-[33%] to-[#00000000]" />
        </AspectRatio>
    );
};

export default KboWallPaper;
