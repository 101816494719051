import { useState } from 'react';

const useDraftState = <T>(initialState: T): [T, (draft: T) => void] => {
  const [state, setState] = useState<T>();

  const value = state ?? initialState;

  const updateState = (draft: T) => setState(draft);

  return [value, updateState];
};

export default useDraftState;
