import Poster, { PosterProps } from '../../atom/poster/Poster';
import Ranking, { RankingProps } from '../../atom/ranking/Ranking';
import Text, { TextProps } from '../../atom/text/Text';
import Labels, { LabelsProps } from '../labels/Labels';

type BasicRankingItemProps = {
  className?: { wrapper: string; poster: string; ranking: string };
  poster: PosterProps;
  posterText: TextProps<React.ElementType>;
  labels: LabelsProps;
  ranking: RankingProps;
  tabIndex?: number;
};

const BasicRankingItem = ({ className, poster, labels, ranking, posterText, tabIndex }: BasicRankingItemProps) => {
  return (
    <div className={`basicRankingItem ${className?.wrapper}`} tabIndex={tabIndex}>
      <div className={`absolute top-[0px] ${className?.poster}`}>
        {posterText && <Text {...posterText} />}
        <Poster {...poster} />
        <Labels {...labels} />
      </div>
      <Ranking className={className?.ranking} {...ranking} />
    </div>
  );
};

export default BasicRankingItem;
