import { PcClipVerticalItemType } from '@tving/utils/src/types/items';

import { KboBand, KboChannel, KboRoasterBand } from '@typings/kbo/band';
import { Category, PlayerLeaderboardItem } from '@typings/kbo/ranking';

import { KboPlayerRankingTableRowData } from './ranking';

export type KboResponse = {
    code: string; // '0000'
    message: string; // 'Success'
};

export type KboTeamDetailBandsResponse = KboResponse & {
    data: {
        backgroundImage: string;
        teamImageUrl: string;
        bands: KboBand[];
        previewImage: string;
    };
};

export type KboBandsResponse = KboResponse & {
    data: {
        bands: KboBand[];
    };
};

export type KboRosterResponse = KboResponse & {
    data: {
        bands: KboRoasterBand[];
    };
};

export type KboHomeResponse<B = KboBand> = KboResponse & {
    data: {
        title: string;
        titleImageUrl: string; // 'https://image.tving.com/ntgs/operation/specialHall/2023/10/19/1697689585_1.png'
        backgroundColor: string; // '#C30452'
        previewImage: string;
        bands: B[];
    };
};

export type KboLeaderboardItemsResponse<I = PlayerLeaderboardItem> = KboResponse & {
    data: {
        items: I[];
        categories: Category[];
    };
};

export type KboMoreBandItemsResponse<B = KboBand<PcClipVerticalItemType>, I = PcClipVerticalItemType> = KboResponse & {
    data: {
        bands: B[];
        items: I[];
        nextApiUrl?: string;
    };
};

export type KboContentsResponse<B = KboBand, C = KboChannel> = KboResponse & {
    data: {
        code: string; // 'C123456'
        gameCode: string; // '20240123KTHH' OK
        previewImage: string; // 'https://image.tving.com/ntgs/operation/specialHall/2023/10/19/1697689585_1.png' OK
        gameDateTime: number; // 20240123180000 OK
        status: KBO_GAME_STATUS; // OK
        bands: B[];
        channels: C[];
        items: unknown[];
        nextApiUrl?: string;
        gameType: 'ALL_STAR' | 'REGULAR';
    };
};

export type KboHistoryResponse<B = KboBand> = KboResponse & {
    data: {
        titleImageUrl: string; // 'https://image.tving.com/ntgs/operation/specialHall/2023/10/19/1697689585_1.png'
        backgroundColor: string; // '#C30452'
        bands: B[];
    };
};
export type KboPlayerHistoryResponse = KboResponse & {
    data: {
        items: KboPlayerRankingTableRowData[];
    };
};

export type KboScheduleDatePickerResponse = KboResponse & {
    data: {
        calendar: number[]; // [1, 4, 20]
        prevGameDate?: number; // 20240123 없는 경우 undefined
        nextGameDate?: number; // 20240125 없는 경우 undefined
    };
};

export type KboMyTeamsResponse<B = KboBand> = KboResponse & {
    data: {
        bands: B[];
    };
};

export type KboClipResponse<B = KboBand> = KboResponse & {
    data: {
        code: string; // 'SB00001'
        category: string;
        name: string;
        imageUrl: string;
        uploadDate?: number; // YYYYMMDD, 재생목록 없는 경우에만~
        bands: B[];
    };
};

export enum KBO_GAME_STATUS {
    PREV = 'PREV',
    NOW = 'NOW',
    END = 'END',
    CANCEL = 'CANCEL',
    SUSPENDED = 'SUSPENDED',
}

export enum KBO_CONTENTS_TYPE {
    GAME = 'GAME',
    CLIP = 'CLIP',
}
