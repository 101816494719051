/**
 * Paging List
 * @constructor
 */
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';
import Item from '@components/item/Item';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { sendFbaEvent } from '@utils/firebaseAnalytics';
import { useUserInfo } from '@store/state';

const PagingList = ({ displayTitle, bandType, ratio, ld, isEditMode, funnel, searchKeyword, onClickListItem, listIndex, isPayUser }) => {
    const [userInfo] = useUserInfo();
    const items = [];
    const router = useRouter();
    const { query } = router;
    const isSearchPage = router?.asPath?.startsWith('/search') || false;
    ld.data.map((list) => {
        if (list.bands) {
            list.bands.map((bandItem) => {
                bandItem.items && items.push(...bandItem.items);
            });
        } else {
            list.items && items.push(...list.items);
        }
    });

    const createOnClickListItem = (itemIndex) => (event) => {
        onClickListItem?.(
            {
                itemIndex,
                listIndex,
            },
            event,
        );
    };

    const observer = useRef(null);
    const visibleItems = useRef([]); // 화면에 나타난 아이템들을 저장할 배열

    useEffect(() => {
        // 검색 결과 리스트 페이지에서만 사용
        if (isSearchPage && query?.slug) {
            if (!observer.current) {
                observer.current = new IntersectionObserver(
                    (entries) => {
                        let hasNewItems = false;
                        entries.forEach((entry) => {
                            if (entry?.isIntersecting && !entry?.target.dataset.processed) {
                                // 아이템이 처음으로 화면에 나타난 경우
                                entry.target.dataset.processed = 'true'; // 처리됨으로 표시
                                observer.current.unobserve(entry.target); // 더 이상 이 아이템을 관찰하지 않음
                                visibleItems?.current.push({ code: entry.target.getAttribute('code'), idx: entry.target.getAttribute('idx') });
                                hasNewItems = true;
                            }
                        });

                        if (hasNewItems) {
                            const { abtestGroup, bandCode, bandModel, schModel, schRecModel, schCollection } = items[0] || {};
                            const filteredImpressionSchSr = items
                                .filter((item) => visibleItems.current.some((visible) => visible.code === item.code))
                                .map((item) => item.clickSchSr)
                                .join('');

                            // 새로운 아이템들이 화면에 나타난 경우, 이벤트 발송
                            sendFbaEvent({
                                eventName: 'impression',
                                profileNo: userInfo?.profileNo,
                                params: {
                                    type: 'search_detail',
                                    search_id: query?.searchUniqueKey ?? '',
                                    keyword: searchKeyword ?? '',
                                    funnel: funnel ?? '',
                                    band_name: displayTitle ?? '',
                                    is_more: true,
                                    media_codes: visibleItems?.current?.map((item) => item.code) ?? '',
                                    media_ranks: visibleItems?.current?.map((item) => item.idx) ?? '',
                                    abtest_group: abtestGroup,
                                    band_code: bandCode,
                                    band_model: bandModel,
                                    sch_model: schModel,
                                    sch_rec_model: schRecModel,
                                    sch_collection: schCollection,
                                    impression_sch_sr: filteredImpressionSchSr,
                                },
                            });
                            visibleItems.current = []; // array 초기화
                        }
                    },
                    { threshold: 0.5 },
                );
            }

            const itemList = document.querySelectorAll('.item');
            itemList.forEach((item) => {
                if (!item?.dataset.processed) {
                    observer.current.observe(item);
                }
            });

            return () => {
                if (observer.current) {
                    observer.current.disconnect();
                }
            };
        }
        return undefined;
    }, [displayTitle, funnel, items, searchKeyword, userInfo?.profileNo, isSearchPage, query]);

    return (
        <>
            {items.map((item, idx) => {
                const { abtestGroup, bandCode, bandModel, schModel, schRecModel, schCollection, bannerCode } = item || {};
                const fbaParams = {
                    bandCode,
                    bannerCode,
                };
                if (item.abtestGroup) {
                    const clickCode = item.code;
                    const bannerIsManual = !!item?.isManual;
                    fbaParams.clickCode = clickCode;
                    fbaParams.clickSchSr = item.clickSchSr;
                    fbaParams.schRecModel = schRecModel;
                    fbaParams.schCollection = schCollection;
                    fbaParams.schModel = schModel;
                    fbaParams.abtestGroup = abtestGroup;
                    fbaParams.bandModel = bandModel;
                    fbaParams.bannerIsManual = bannerIsManual;
                }
                return (
                    <div key={`item-${idx}`} className="item" onClick={createOnClickListItem(idx)} code={item.code} idx={idx}>
                        <Item
                            bandTitle={displayTitle}
                            bandType={bandType}
                            item={item}
                            idx={idx}
                            ratio={ratio}
                            isEditMode={isEditMode}
                            isSearchPage={isSearchPage}
                            isListPage
                            funnel={funnel}
                            isPayUser={isPayUser}
                            searchKeyword={searchKeyword}
                            fbaParams={fbaParams}
                        />
                    </div>
                );
            })}
        </>
    );
};

const PagingWrap = styled.div``;

const Paging = ({
    displayTitle = '',
    bandType = 'programBasic',
    title = '{title}',
    ratio = '25x36',
    ratioType = 'horizontal',
    listData = {},
    isEditMode = false,
    funnel,
    searchKeyword = '',
    onClickListItem,
    listIndex,
    isPayUser,
}) => {
    const { t } = useTranslation('common');
    return (
        <PagingWrap className={`lists__columns lists__columns-${ratioType}`}>
            <PagingList
                displayTitle={displayTitle}
                bandType={bandType}
                title={title}
                ratio={ratio}
                ratioType={ratioType}
                ld={listData}
                isEditMode={isEditMode}
                funnel={funnel}
                searchKeyword={searchKeyword}
                onClickListItem={onClickListItem}
                listIndex={listIndex}
                isPayUser={isPayUser}
            />
        </PagingWrap>
    );
};

export default Paging;
