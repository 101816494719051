import Poster, { PosterProps } from '../../atom/poster/Poster';
import Text, { TextProps } from '../../atom/text/Text';
import IconClipPlaylist from '../../images/icons/icon_clip_playlist.svg';
import tempCnx from '../../utils/tailwind/tempCnx';
import Labels, { LabelsProps } from '../labels/Labels';

type ClipPlaylistItemProps = {
  tabIndex?: number;
  className?: string;
  poster: PosterProps;
  posterText: TextProps<React.ElementType>;
  labels: LabelsProps;
  text: {
    className: string;
    items: { tag?: React.ElementType<any>; title: string; className?: string; tabindex?: number }[];
  };
  count: number;
};

const ClipPlaylistItem = ({ className, poster, text, posterText, tabIndex, labels, count }: ClipPlaylistItemProps) => {
  return (
    <>
      <div className={`kbo-clip-item-wrapper ${className}`} tabIndex={tabIndex}>
        {posterText && <Text {...posterText} />}
        <Poster {...poster} />
        <Labels {...labels} />

        <div
          className={tempCnx(
            'absolute right-[0.17rem] bottom-[0.17rem] z-[21]',
            'inline-flex items-center justify-center rounded-[0.167rem] px-[0.33rem] py-[0.17rem] bg-black/80',
            'text-[1rem] font-normal leading-[1.167rem] text-gray-85 lg:text-[1.083rem]',
          )}
        >
          <IconClipPlaylist className="mr-[0.17rem] lg:w-[1.333rem] lg:h-[1.333rem]" />
          <span>{`영상 ${count || 0}개`}</span>
        </div>
      </div>

      <div className={text?.className}>
        {text?.items.map((text) => {
          return <Text key={text.title} tag={text.tag} title={text.title} className={text.className} tabIndex={tabIndex} />;
        })}
      </div>
    </>
  );
};

export default ClipPlaylistItem;
