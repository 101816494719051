import Poster, { PosterProps } from '../../atom/poster/Poster';
import ProgressBar from '../../atom/progressBar/ProgressBar';
import Text, { TextProps } from '../../atom/text/Text';
import Labels, { LabelsProps } from '../labels/Labels';

type KboLiveItemProps = {
  className?: string;
  poster: PosterProps;
  posterText: TextProps<React.ElementType>;
  labels: LabelsProps;
  progressBar: {
    percent: number;
  };
  tabIndex?: number;
};

const KboLiveItem = ({ className, poster, posterText, tabIndex, labels, progressBar }: KboLiveItemProps) => {
  return (
    <div className={`kbo-live-item-wrapper ${className}`} tabIndex={tabIndex}>
      {posterText && <Text {...posterText} />}
      <Poster {...poster} />
      <ProgressBar {...progressBar} />
      <Labels {...labels} />
    </div>
  );
};

export default KboLiveItem;
