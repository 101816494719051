import { getContentTypeFromMediaCode } from '@tving/utils/src/utils/proxy-util/getContentTypeFromMediaCode';
import { CONTENT_TYPE } from '@tving/utils/src/constants/commonConstants';
import getAmplitudeEventMediaType from '@tving/utils/src/utils/amplitude/getAmplitudeEventMediaType';

type BaseContentMetaParams = {
    /**
     * 미디어 코드
     * - ex) P000797659
     */
    mediaCode?: string;
    /**
     * 채널 코드
     * - ex) C50241
     */
    channelCode?: string;
    /**
     * 채널 이름
     * - ex) TVING
     */
    channelName?: string;
    /**
     * 프로그램 코드
     * - ex) P001433241
     */
    programCode?: string;
    /**
     * 프로그램 이름
     * - ex) 여고추리반
     */
    programName?: string;
    /**
     * 에피소드 코드(Episode 또는 Program일 때만 적용합니다.)
     * - ex) E003484621
     */
    maybeEpisodeCode?: string;
    /**
     * 이미지 코드
     * - ex) CAIP1000
     */
    imageCode?: string;
    /**
     * 카테고리 이름
     * - ex) 예능
     */
    categoryName?: string;
    /**
     * 카테고리 코드
     * - ex) PCA
     */
    categoryCode?: string;
};

type BandContentMetaParams = BaseContentMetaParams & {
    /**
     * 밴드 이름
     * - ex) 킹더랜드 총 6화
     */
    bandName?: string;
};

type ContinuousPlayParams = {
    /**
     * 연속 재생 여부
     */
    isContinuous?: boolean;
};

type PriceIncreaseLimitContentMetaParams = BaseContentMetaParams & {
    productId: '';
    productName: '';
};

export const getAmplitudeEventParams = {
    base: () => {
        return {
            screen_name: 'contents_detail',
        };
    },

    baseContentMeta: ({
        mediaCode,
        channelCode,
        channelName,
        programCode,
        programName,
        maybeEpisodeCode,
        imageCode,
        categoryName,
        categoryCode,
    }: BaseContentMetaParams) => {
        const contentType = getContentTypeFromMediaCode(mediaCode || '');
        const mediaType = getAmplitudeEventMediaType(mediaCode);

        const isProgramOrEpisode = contentType === CONTENT_TYPE.PROGRAM || contentType === CONTENT_TYPE.EPISODE;

        const maybeProgramCode = (isProgramOrEpisode ? programCode : null) || null;
        const code = (isProgramOrEpisode ? maybeEpisodeCode : mediaCode) || null;

        return {
            ...getAmplitudeEventParams.base(),
            mediatype: mediaType,
            channel_code: channelCode || null,
            channel_name: channelName || null,
            program_code: maybeProgramCode,
            program_name: programName || null,
            code,
            image_code: imageCode || null,
            ctgr_name: categoryName || null,
            ctgr_code: categoryCode || null,
        };
    },

    bandContentMeta: ({ bandName, ...baseContentMetaParams }: BandContentMetaParams) => {
        return {
            ...getAmplitudeEventParams.baseContentMeta(baseContentMetaParams),
            band_name: bandName || null,
        };
    },

    sharedContentMeta: ({ sharedChannel, ...baseContentMetaParams }: SharedContentMetaParams) => {
        return {
            ...getAmplitudeEventParams.baseContentMeta(baseContentMetaParams),
            shared_channel: sharedChannel,
        };
    },

    continuousPlay: ({ isContinuous }: ContinuousPlayParams) => {
        return {
            ...getAmplitudeEventParams.base(),
            // Y: 연속재생 사용
            // N: 연속재생 사용 안함
            continuous_yn: isContinuous ? 'Y' : 'N',
        };
    },

    adultIdentification: () => {
        return {
            screen_name: 'adult_certification',
        };
    },

    priceIncreaseLimitContentMeta: ({ productId, productName, ...baseContentMetaParams }: PriceIncreaseLimitContentMetaParams) => {
        return {
            ...getAmplitudeEventParams.baseContentMeta(baseContentMetaParams),
            type: 'page',
            prodid: productId || null,
            prodname: productName || null,
        };
    },
};
