import React from 'react';
import ChatContainer from '@components/chat/ChatContainer';
import useKboGame from '@stores/kbo/game/useKboGame';
import { shallow } from 'zustand/shallow';
import { useRouter } from 'next/router';
import usePcPlayer from '@store/usePcPlayer';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import IconClose from '@img/chat/icon_close.svg';
import useChatStatusStore from '@tving/utils/src/stores/useChatStatusStore';
import { usePlayerAdInfo, usePlayerInfo } from '@store/state';

type ChatDefaultLayoutProps = {
    children: React.ReactNode;
};

const isLiveStream = (streamData: { type: string }) => {
    // return streamData.type === 'live' || streamData.type === 'tvingtv' || streamData.type === 'tvinglive';
    return ['live', 'tvingtv', 'tvinglive'].includes(streamData.type);
};

const ChatDefaultLayout = ({ children }: ChatDefaultLayoutProps) => {
    const { isChatOpen, setIsChatOpen } = useChatStatusStore(
        (state) => ({
            isChatOpen: state.isChatOpen,
            setIsChatOpen: state.setIsChatOpen,
        }),
        shallow,
    );

    return (
        <div className={tempCnx('flex flex-col justify-between w-full h-full')}>
            <header
                className={tempCnx(
                    'flex grow-0 shrink-0 basis-[4.25rem] justify-between items-center',
                    'border-0 border-solid border-b border-[#262626]',
                )}
            >
                <h2 className={tempCnx('px-[1.333rem]')}>티빙톡</h2>
                <button type="button" aria-label="chat exit" className={tempCnx('h-full px-[1.168rem]')} onClick={() => setIsChatOpen(!isChatOpen)}>
                    <IconClose />
                </button>
            </header>
            <div className={tempCnx('grow overflow-hidden')}>{children}</div>
        </div>
    );
};

const ChatLayoutSwitcher = () => {
    const router = useRouter();
    const { isFullScreen: isFullscreen, setFullScreen } = usePcPlayer(
        (state) => ({
            isFullScreen: state.isFullScreen,
            setFullScreen: state.setFullScreen,
        }),
        shallow,
    );
    const [info] = usePlayerInfo();
    const [adInfo, setAdInfo] = usePlayerAdInfo();
    const { uniqueCode } = useKboGame(
        (state) => ({
            uniqueCode: state.uniqueCode,
            lastPlayChannel: state.lastPlayChannel,
        }),
        shallow,
    );

    const mediaCode = info?.mediaCode;
    const { isAdsPlaying } = adInfo || {};

    const isKbo = router.pathname.includes('/kbo/contents');
    const isLive = true;

    const isShow = isKbo ? isFullscreen : isLive;

    const channelUrl = isKbo ? uniqueCode : `${mediaCode}`;

    if (!channelUrl) {
        return null;
    }

    console.log('#tving-talk - switcher', { isShow, channelUrl });

    return isShow ? (
        <ChatDefaultLayout>
            <ChatContainer channelUrl={channelUrl} />
        </ChatDefaultLayout>
    ) : null;
};

export default ChatLayoutSwitcher;
