import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Link from 'next/link';
import React, { Children } from 'react';

const ActiveLink = ({ children, ...props }) => {
    const { asPath } = useRouter();
    const child = Children.only(children);
    const childClassName = child.props.className || '';
    const className =
        `${asPath}`.startsWith(props.href) || `${asPath}`.startsWith(props.as) ? `${childClassName} ${props.activeClassName}`.trim() : childClassName;

    return (
        <Link scroll {...props}>
            {React.cloneElement(child, {
                className: className || null,
            })}
        </Link>
    );
};

ActiveLink.propTypes = {
    activeClassName: PropTypes.string.isRequired,
};

export default ActiveLink;
