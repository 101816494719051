import styled from '@emotion/styled';
import { changeImageFormat, resizeImage } from '@utils/Thumbnail';
import { useCallback, useEffect, useRef, useState } from 'react';

interface PictureProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    size?: number;
    isWebp?: boolean;
    queryParamsObj?: object;
    'data-testid'?: string;
}

export const Picture = ({ src, size, alt, onLoad, onError, isWebp = true, queryParamsObj = {}, 'data-testid': dataTestId }: PictureProps) => {
    const ref = useRef<HTMLImageElement>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>();
    const [isError, setIsError] = useState<boolean>();

    const imgOnError = useCallback(
        (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            if (onError) {
                onError(e);
            }
            setIsError(true);
        },
        [onError],
    );

    const imgOnLoad = useCallback(
        (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            if (!src) {
                imgOnError(e);
                return;
            }
            if (onLoad) {
                onLoad(e);
            }
            setIsLoaded(true);
        },
        [imgOnError, onLoad, src],
    );

    const className = () => {
        const classArr: string[] = [];
        if (isLoaded) {
            classArr.push('loaded');
        } else if (isError) {
            classArr.push('error');
        }
        return classArr.join(' ');
    };
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        setIsError(false);
        setIsLoaded(false);
        if (!src) {
            imgOnError({ target: ref.current } as unknown as React.SyntheticEvent<HTMLImageElement, Event>);
            return;
        }
        if (ref.current.complete) {
            imgOnLoad({ target: ref.current } as unknown as React.SyntheticEvent<HTMLImageElement, Event>);
        }
    }, [imgOnError, imgOnLoad, ref, src]);

    return (
        <StyledPicture className={className()} data-testid={dataTestId}>
            {isWebp && <StyledSource srcSet={changeImageFormat(src, 'webp', size, queryParamsObj)} type="image/webp" />}
            <StyledImg
                className={className()}
                ref={ref}
                src={src ? resizeImage(src, size) : ''}
                alt={alt}
                onError={imgOnError}
                onLoad={(e) => {
                    imgOnLoad(e);
                }}
            />
        </StyledPicture>
    );
};

const StyledPicture = styled.picture`
    &.error {
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            background: url('/img/tving-empty.svg') no-repeat center center;
            background-size: 7.333rem 2.333rem;
            transform: translate(-50%, -50%);
        }

        img,
        source {
            opacity: 0;
            transition: opacity 0s;
        }
    }

    &.loaded {
        img,
        source {
            opacity: 1;
            transition: opacity 0.1s;
        }
    }

    img {
        // opacity: 0;
        &.loaded {
            opacity: 1;
        }

        &.error {
            opacity: 0;
        }
    }
`;

const StyledSource = styled.source`
    opacity: 1;
    transition: opacity 0.1s;
`;

const StyledImg = styled.img`
    opacity: 1;
    transition: opacity 0.1s;
`;
