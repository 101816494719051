import { CONTENT_TYPE } from '../../constants/commonConstants';

/**
 * MediaCode로부터 Amplitude에서 사용하는 MediaType을 반환하는 함수입니다.
 *
 * @see {@link https://tvingcorp.slack.com/archives/C05139014CD/p1697617739284419?thread_ts=1697106758.796759&cid=C05139014CD Slack}
 */
const getAmplitudeEventMediaType = (mediaCode: string | undefined | null) => {
  if (!mediaCode) {
    return null;
  }

  const firstCharacter = mediaCode.toUpperCase().charAt(0);

  switch (firstCharacter) {
    case 'C': {
      return CONTENT_TYPE.LIVE;
    }
    case 'M': {
      return CONTENT_TYPE.MOVIE;
    }
    case 'P': {
      return CONTENT_TYPE.EPISODE;
    }
    case 'E': {
      return CONTENT_TYPE.EPISODE;
    }
    default: {
      return null;
    }
  }
};

export default getAmplitudeEventMediaType;
