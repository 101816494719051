import { NullableString } from '../../apis/types';
import { CACHE_KEY, DEVICE_TYPE } from '../../constants/commonConstants';

import getDeviceType from './getDeviceType/getDeviceType';

let pageUrl = 'https://www.tving.com';
let apiUrl = 'https://api.tving.com';
let userUrl = 'https://user.tving.com';
let searchUrl = 'https://search.tving.com';
let imageServerUrl = 'https://image.tving.com';
let ttvUrl = 'https://image.tving.com/upload/ttv/status.json';
let bufferUrl = 'https://image.tving.com/upload/buffer/status.json';
let gatewayUrl = 'https://gw.tving.com';
let regionGatewayUrl = 'https://ooo-gw.tving.com';
let sendbirdUrl = `https://gw.tving.com/chat/v1`;
let sendbirdAppId = `1D98E7A2-6E01-480C-A440-BEE494CBA73D`;
let kboUrl = 'https://gw-dev-cf.aws.tving.com/bff/kbo/v2';
let kboBaseUrl = 'https://gw-dev-cf.aws.tving.com/bff/kbo'; // v2, v3 버전 없는 기본 URL
let sportsUrl = 'https://gw.tving.com/bff/sports';
let searchBffUrl = 'https://gw.tving.com/bff/search';

let billUrl = 'https://bill.tving.com';
let amplitudeKey = 'c2b31201837521f7cfb798a47bd43043';
const versionUrl = typeof window !== 'undefined' ? `${window?.location.protocol}//${window?.location.host}/version.json` : '';

export const API_VERSION = {
  V1: 'v1',
  V2: 'v2',
  V3: 'v3',
};

export enum ENVIRONMENT_TYPES {
  PRODUCTION = 'PRODUCTION',
  QC = 'QC',
  QA = 'QA',
  DEV = 'DEV',
}

const getEnvironmentTypes = (environmentType: NullableString) => {
  switch (environmentType) {
    case 'PRODUCTION':
    case 'real':
      return ENVIRONMENT_TYPES.PRODUCTION;
    case 'QC':
    case 'qc':
      return ENVIRONMENT_TYPES.QC;
    case 'QA':
    case 'qa':
      return ENVIRONMENT_TYPES.QA;
    case 'DEV':
    case 'dev':
      return ENVIRONMENT_TYPES.DEV;
    default:
      return ENVIRONMENT_TYPES.PRODUCTION;
  }
};

const environment: string = process.env.REACT_APP_RELEASE ?? 'PRODUCTION';

// Api type A.K.A at
const storedEnvironmentType = typeof window !== 'undefined' ? window.localStorage.getItem(CACHE_KEY.ENVIRONMENT_TYPE) : null;
export const environmentType = storedEnvironmentType ?? getEnvironmentTypes(environment);

switch (environmentType) {
  case ENVIRONMENT_TYPES.PRODUCTION:
    pageUrl = 'https://www.tving.com';
    apiUrl = 'https://api.tving.com';
    userUrl = 'https://user.tving.com';
    searchUrl = 'https://search.tving.com';
    imageServerUrl = 'https://image.tving.com';
    ttvUrl = 'https://image.tving.com/upload/ttv/status.json';
    bufferUrl = 'https://image.tving.com/upload/buffer/status.json';
    gatewayUrl = 'https://gw.tving.com';
    regionGatewayUrl = 'https://ooo-gw.tving.com';
    sendbirdUrl = 'https://gw.tving.com/chat/v1';
    sendbirdAppId = '98BBA916-51D5-4A23-B290-04ECB70230B7';
    kboUrl = 'https://gw.tving.com/bff/kbo/v2';
    kboBaseUrl = 'https://gw.tving.com/bff/kbo';
    sportsUrl = 'https://gw.tving.com/bff/sports';
    searchBffUrl = 'https://gw.tving.com/bff/search';
    billUrl = 'https://bill.tving.com';
    amplitudeKey = 'c2b31201837521f7cfb798a47bd43043';
    break;
  case ENVIRONMENT_TYPES.QC:
    pageUrl = 'https://qc.tving.com';
    apiUrl = 'https://apiqc.tving.com';
    userUrl = 'https://userqc.tving.com';
    searchUrl = 'https://qc-search.tving.com';
    imageServerUrl = 'https://image.tving.com';
    ttvUrl = 'https://image.tving.com/upload/ttv/status.json';
    bufferUrl = 'https://image.tving.com/upload/buffer/status.json';
    gatewayUrl = 'https://gw-qc-cf.aws.tving.com';
    regionGatewayUrl = 'https://ooo-gw-qc.aws.tving.com';
    sendbirdUrl = 'https://gw-qc-cf.aws.tving.com/chat/v1';
    sendbirdAppId = '98BBA916-51D5-4A23-B290-04ECB70230B7';
    kboUrl = 'https://gw-qc-cf.aws.tving.com/bff/kbo/v2';
    kboBaseUrl = 'https://gw-qc-cf.aws.tving.com/bff/kbo';
    sportsUrl = 'https://gw-qc-cf.aws.tving.com/bff/sports';
    searchBffUrl = 'https://gw-qc-cf.aws.tving.com/bff/search';
    billUrl = 'https://billqc.tving.com';
    amplitudeKey = '22ae6386ed7c9bcb4d25c135d61e293f';
    break;
  case ENVIRONMENT_TYPES.QA:
    pageUrl = 'https://qa.tving.com';
    apiUrl = 'https://apiqa.tving.com';
    userUrl = 'https://userqa.tving.com';
    searchUrl = 'https://qa-search.tving.com';
    imageServerUrl = 'https://image-qa.tving.com';
    ttvUrl = 'https://image-qa.tving.com/upload/ttvstg/status.json';
    bufferUrl = 'https://image-qa.tving.com/upload/bufferstg/status.json';
    gatewayUrl = 'https://gw-qa-cf.aws.tving.com';
    regionGatewayUrl = 'https://ooo-gw-qa.aws.tving.com';
    sendbirdUrl = 'https://gw-qa-cf.aws.tving.com/chat/v1';
    sendbirdAppId = '1D98E7A2-6E01-480C-A440-BEE494CBA73D';
    kboUrl = 'https://gw-qa-cf.aws.tving.com/bff/kbo/v2';
    kboBaseUrl = 'https://gw-qa-cf.aws.tving.com/bff/kbo';
    sportsUrl = 'https://gw-qa-cf.aws.tving.com/bff/sports';
    searchBffUrl = 'https://gw-qa-cf.aws.tving.com/bff/search';
    billUrl = 'https://billqa.tving.com';
    amplitudeKey = '22ae6386ed7c9bcb4d25c135d61e293f';
    break;
  case ENVIRONMENT_TYPES.DEV:
    pageUrl = 'https://dev.tving.com';
    apiUrl = 'https://apidev.tving.com';
    userUrl = 'https://userdev.tving.com';
    searchUrl = 'https://dev-search.tving.com';
    imageServerUrl = 'https://imagedev.tving.com';
    ttvUrl = 'https://imagedev.tving.com/upload/ttvdev/status.json';
    bufferUrl = 'https://imagedev.tving.com/upload/bufferdev/status.json';
    gatewayUrl = 'https://gw-dev-cf.aws.tving.com';
    regionGatewayUrl = 'https://ooo-gw-dev.aws.tving.com';
    sendbirdUrl = 'https://gw-dev-cf.aws.tving.com/chat/v1';
    sendbirdAppId = '1D98E7A2-6E01-480C-A440-BEE494CBA73D';
    kboUrl = 'https://gw-dev-cf.aws.tving.com/bff/kbo/v2';
    kboBaseUrl = 'https://gw-dev-cf.aws.tving.com/bff/kbo';
    sportsUrl = 'https://gw-dev-cf.aws.tving.com/bff/sports';
    searchBffUrl = 'https://gw-dev-cf.aws.tving.com/bff/search';
    billUrl = 'https://billdev.tving.com';
    amplitudeKey = 'b6c94c8cc95de692d32ff315f4f9057c';
    break;
  default:
}

const deviceType = getDeviceType();

// eslint-disable-next-line no-nested-ternary
export const POC_CODE = deviceType === DEVICE_TYPE.WEBOS ? 'POCD0800' : deviceType === DEVICE_TYPE.TIZEN ? 'POCD0801' : 'POCD0802';

// eslint-disable-next-line no-nested-ternary
export const SCREEN_CODE = deviceType === DEVICE_TYPE.WEBOS ? 'CSSD1100' : deviceType === DEVICE_TYPE.TIZEN ? 'CSSD1200' : 'CSSD1300';

export const API_KEY =
  // eslint-disable-next-line no-nested-ternary
  deviceType === DEVICE_TYPE.WEBOS
    ? '95a64ebcd8e154aeb96928bf34848826'
    : deviceType === DEVICE_TYPE.TIZEN
    ? 'aeef9047f92b9dc4ebabc71fe4b124bf'
    : '1e7952d0917d6aab1f0293a063697610';

export const PAGE_URL = `${pageUrl}`;
export const API_SERVER_URL = `${apiUrl}`;
export const USER_SERVER_URL = `${userUrl}`;
export const SEARCH_SERVER_URL = `${searchUrl}`;
export const IMAGE_SERVER_URL = `${imageServerUrl}`;
export const TTV_URL = `${ttvUrl}`;
export const BUFFER_URL = `${bufferUrl}`;
export const VERSION_URL = `${versionUrl}`;
export const GATEWAY_URL = `${gatewayUrl}`;
export const KBO_URL = `${kboUrl}`;
export const KBO_BASE_URL = `${kboBaseUrl}`;
export const SPORTS_URL = `${sportsUrl}`;
export const SEARCH_BFF_URL = `${searchBffUrl}`;
export const SENDBIRD_URL = `${sendbirdUrl}`;
export const SENDBIRD_APPID = `${sendbirdAppId}`;
export const BILL_URL = `${billUrl}`;
export const AMPLITUDE_KEY = `${amplitudeKey}`;
export const getKboDefaultApiUrl = ({ version = API_VERSION.V2 }) => `${KBO_BASE_URL}/${version}`;
export const getSportsDefaultApiUrl = ({ version = API_VERSION.V2 }) => `${SPORTS_URL}/${version}`;
