import Poster, { PosterProps } from '../../atom/poster/Poster';
import Text, { TextProps } from '../../atom/text/Text';

export type lineItemProps = {
  className?: string;
  poster: PosterProps;
  posterText: TextProps<React.ElementType>;
  tabIndex?: number;
};
const LineItem = ({ className, poster, posterText, tabIndex }: lineItemProps) => {
  return (
    <div className={`line-item-wrapper ${className}`} tabIndex={tabIndex}>
      {posterText && <Text {...posterText} />}
      <Poster {...poster} />
    </div>
  );
};
export default LineItem;
