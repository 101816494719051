import useWebpStore from '@stores/useWebpStore';
import { convertToWebpResize } from '@tving/utils/src/utils/common/convertToWebpResize';
import { resizeImage } from '@tving/utils/src/utils/common/resizeImage';
import { shallow } from 'zustand/shallow';

type UseOptimizeImageParams = {
    imageUrl: string;
    size: number;
};

const isSvgFormat = (imageUrl: string): boolean => {
    const regex = /https?:\/\/[\w\-./]+\.svg$/;
    return regex.test(imageUrl);
};

const useOptimizeImage = ({ imageUrl, size }: UseOptimizeImageParams) => {
    const { isSupportsWebP, isWebPSupportDetectionDone } = useWebpStore(
        (state) => ({ isSupportsWebP: state.isSupportsWebP, isWebPSupportDetectionDone: state.isWebPSupportDetectionDone }),
        shallow,
    );

    if (!imageUrl || !isWebPSupportDetectionDone) {
        return null;
    }

    if (isSvgFormat(imageUrl)) {
        // svg 포맷은 리사이즈 안되기 때문에, 받은 url 다시 리턴
        return imageUrl;
    }

    if (isSupportsWebP) {
        return convertToWebpResize({ imageUrl, size });
    }
    return resizeImage({ imageUrl, size });
};

export default useOptimizeImage;
