import { useEffect, useRef, useState } from 'react';
import { useFullscreen } from 'react-use';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import throttle from 'lodash.throttle';
import ResizeObserver from 'resize-observer-polyfill';
import { shallow } from 'zustand/shallow';

import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import useChatStatusStore from '@tving/utils/src/stores/useChatStatusStore';
import isClipContentsByUrl from '@tving/utils/src/utils/isClipContentsByUrl/isClipContentsByUrl';

import ChatLayoutSwitcher from '@components/chat/ChatLayoutSwitcher';
import useMultiviewStore from '@components/new-player/mainPlayer/multiview/useMultiviewStore';
import useInsideClick from '@hooks/useInsideClick';
import useOutsideClick from '@hooks/useOutsideClick';
import useTabTrap from '@hooks/useTabTrap';
import useThrottledFn from '@hooks/useThrottledFn';
import useWindowResize from '@hooks/useWindowResize';
import { usePlayerAdInfo, usePlayerInfo, usePlayerPageInfo } from '@store/state';
/** @jsxImportSource @emotion/react */
import usePcPlayer from '@store/usePcPlayer';
import { device } from '@styles/device';

const Player = dynamic(
    () => {
        return import('@components/new-player/mainPlayer');
    },
    { ssr: false },
);

const StyledPlayerWrap = styled.div`
    position: relative;
    /* min-height: 500px; */
    max-height: ${(props) => (props.isResizing ? `${(props.setWidth / 16) * 9}px` : `auto`)};
    height: ${(props) => (props.isResizing ? `calc(${props.setHeight}px - 24em)` : `auto`)};
    margin-top: 0em;
    z-index: 10;

    button {
        outline: 0;
    }

    .player {
        position: ${(props) => (props.isRightSideActive ? 'relative' : 'absolute')};
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
        outline: 0;

        > div.pp {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        > div.test-over {
            position: absolute;
            top: 1em;
            right: 1em;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            z-index: 10;
        }
    }

    > span {
        width: 30%;
        padding: 1em;
        word-break: break-all;
        font-size: 10px;
    }

    p > button {
        /* margin: 0.25em;
        padding: 0.5em;
        font-size: 1.5em;
        background-color: rgba(255, 255, 255, 1);
        color: #000; */
    }

    #test {
        position: absolute;
        width: 100%;
        /* height: 100%; */
        z-index: 0;

        .player-wrap {
            position: absolute;
            /* width: 100%;
            height: 100%; */

            > div:first-of-type > div {
                position: absolute;
                width: 100%;
                height: 100%;

                > div {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: stretch;
                    align-items: stretch;
                }
            }
        }

        .player-wrap + div {
            display: none;

            + div {
                display: none;
            }
        }

        .content {
            display: none;
        }
    }
`;

const anotherStyle = css`
    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 33.33vw;
    /* height: 0; */
    min-height: auto;
    max-height: auto;
    z-index: 99999;
    font-size: 12px;

    @media ${device.mobile} {
        width: 50%;
        min-width: 400px;
    }

    @media ${device.tablet} {
        width: 40%;
    }

    @media ${device.laptop} {
        width: 33.33vw;
    }

    &:before {
        content: '';
        display: block;
        padding-top: 56.25%;
        float: left;
        box-sizing: inherit;
    }

    &:after {
        content: '';
        display: block;
        clear: both;
    }
`;

const activeStyle = css`
    display: flex;
    box-shadow: 0 0 60px 0 #000;
`;

const fullpageStyle = css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    z-index: 210;
`;

// AdCompanion Image Style 을 위한 StyledComponent
const StyledPlayerAdCompanion = styled.div`
    display: ${(props) => (props.isDisplayNone ? `none` : `block`)};

    img {
        width: 100% !important;
        height: auto !important;
    }
`;

const PlayerElement = ({ layoutRef }) => {
    const playerRef = useRef();
    const [isTabIn, setIsTabIn] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [isMiniView, setMiniView] = useState(false);

    const [playerInfo] = usePlayerInfo();
    const [pageInfo] = usePlayerPageInfo();
    const [adInfo, setAdInfo] = usePlayerAdInfo();
    // const { data: streamData } = useStreamInfoApi({ mediaCode: info.mediaCode });
    const router = useRouter();

    const { isPlayingTimeshift } = playerInfo || {};
    const { isAdsPlaying } = adInfo || {};

    const isKboContents = router.pathname.includes('/kbo/contents');
    const isSportsContents = router.pathname.includes('/contents/sports');
    const isClipContents = isClipContentsByUrl(router.asPath);

    // const isAnotherPage = true;
    const isAnotherPage = !router.pathname.includes('/player') && !isKboContents && !isSportsContents && !isClipContents;
    const isLiveOrClipContent = router.query?.slug?.charAt?.(0) === 'C' || isKboContents || isSportsContents || isClipContents;

    const { isFullScreen: isFullscreen, setFullScreen } = usePcPlayer(
        (state) => ({
            isFullScreen: state.isFullScreen,
            setFullScreen: state.setFullScreen,
        }),
        shallow,
    );
    const { isChatOpen, setIsChatOpen } = useChatStatusStore(
        (state) => ({
            isChatOpen: state.isChatOpen,
            setIsChatOpen: state.setIsChatOpen,
        }),
        shallow,
    );
    const { isMultiview } = useMultiviewStore(
        (state) => ({
            isMultiview: state.isMultiview,
        }),
        shallow,
    );

    // const [isFullscreen, toggle] = useToggle(false);
    useFullscreen(layoutRef, isFullscreen, { onClose: () => setFullScreen(false) });

    const appendStyle = () => {
        if (isAnotherPage) {
            if (isMiniView) {
                return css(anotherStyle, activeStyle);
            }
            return css(anotherStyle);
        }
        if (!isLiveOrClipContent || isFullscreen) {
            return css(fullpageStyle);
        }
        return undefined;
    };

    useWindowResize(
        useThrottledFn(() => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }),
    );

    useEffect(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }, []);

    const isResizing = () => {
        return isLiveOrClipContent && !isAnotherPage && !isFullscreen;
    };

    useEffect(() => {
        if (!layoutRef.current) {
            return;
        }
        layoutRef.current.style.overflow = isFullscreen ? 'hidden' : null;
    }, [layoutRef, isFullscreen]);

    useEffect(() => {
        if (!playerRef.current) {
            return;
        }

        playerRef.current.addEventListener('focus', () => {
            setIsFocus(true);
        });
        playerRef.current.addEventListener('blur', () => {
            setIsFocus(false);
            setIsTabIn(false);
        });
    }, [playerRef]);

    useInsideClick(playerRef, () => {
        setIsTabIn(false);
    });

    useOutsideClick(playerRef, () => {
        playerRef.current.blur();
    });

    useTabTrap(playerRef, '.cjp__ui', (!isLiveOrClipContent && !isAnotherPage) || isFullscreen);

    useEffect(() => {
        if (!playerRef.current) {
            return undefined;
        }

        if (!isLiveOrClipContent && !isAnotherPage) {
            document.body.style.overscrollBehavior = 'contain';
            disableBodyScroll(playerRef.current);
        }

        // 미니 플레이어 전환시 포커스.
        setTimeout(() => {
            playerRef.current?.querySelector('.cjp__ui')?.querySelectorAll('button')[0]?.focus();
        }, 0);

        return () => {
            document.body.style.overscrollBehavior = 'auto';
            clearAllBodyScrollLocks();
        };
    }, [isAnotherPage, isLiveOrClipContent, isClipContents]);

    const isPlayingRef = useRef(pageInfo.isPlaying);
    isPlayingRef.current = pageInfo.isPlaying;

    const clearMultiview = useMultiviewStore((state) => state.clearMultiview);

    // 미니모드 전환을 리엑트 라이프사이클이 일어나기 전인 routerChageStart 시점에 변경될 URL을 기준으로 체크 후 변경.
    // 라우트 변경되기전에 플레이어 미니모드로 미리 셋팅하기 위함. 오토배칭으로 인한 로직 변경.
    useEffect(() => {
        const changeStart = (url) => {
            const isPlaying = isPlayingRef.current;
            const isMiniView =
                !url.includes('/player') &&
                !url.includes('/kbo/contents') &&
                !url.includes('/contents/sports') &&
                !isClipContentsByUrl(url) &&
                isPlaying;
            setMiniView(isMiniView);
            if (isMiniView) {
                clearMultiview();
            }
        };
        router.events.on('routeChangeStart', changeStart);
        return () => {
            router.events.off('routeChangeStart', changeStart);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageInfo.isPlaying]);

    // Resize observer to Companion banner
    const companionBannerRef = useRef(null);
    useEffect(() => {
        const root = document.documentElement;
        const companionBanner = companionBannerRef.current;
        const isKboContents = router.pathname.includes('/kbo/contents');
        const isSportsContents = router.pathname.includes('/contents/sports');
        const isClipContents = isClipContentsByUrl(window.location.pathname);

        if (companionBanner) {
            const resizeObserver = new ResizeObserver(
                /**
                 * Resize loop detection 방지를 위해 raq 기반 throttle을 적용합니다.
                 * @see {@link https://github.com/juggle/resize-observer/blob/master/README.md#resize-loop-detection}
                 */
                throttle(
                    (entries) => {
                        const entry = entries[0];
                        const { height } = entry.contentRect;
                        if (isKboContents) {
                            root.style.setProperty('--kbo-player-companion-banner-height', `${height}px`);
                        }
                        if (isSportsContents || isClipContents) {
                            root.style.setProperty('--sports-player-companion-banner-height', `${height}px`);
                        }
                    },
                    undefined, // requestAnimationFrame
                    {
                        leading: true,
                        trailing: true,
                    },
                ),
            );

            resizeObserver.observe(companionBannerRef.current);
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [router.pathname]);

    const isTimemachineMode = !!isPlayingTimeshift;
    const isRightSideActive =
        !isMultiview &&
        !isAdsPlaying &&
        !isTimemachineMode &&
        ((isFullscreen && (isKboContents || isSportsContents || isClipContents) && isChatOpen) ||
            (!isKboContents && !isSportsContents && !isClipContents && isLiveOrClipContent && isChatOpen));

    return (
        <div
            className={tempCnx(
                'player-container',
                (isKboContents || isSportsContents || isClipContents) && !isFullscreen
                    ? 'z-20 !w-[var(--kbo-player-width)] !h-[var(--kbo-player-height)] !w-[var(--sports-player-width)] !h-[var(--sports-player-height)] landscape:!absolute !fixed top-[8.66rem]'
                    : '',
                isClipContents ? 'top-[6rem]' : '',
            )}
        >
            <StyledPlayerWrap
                className={tempCnx(
                    'player-wrap',
                    isKboContents || isSportsContents || isClipContents ? '!w-full !h-full flex' : '',
                    isRightSideActive ? 'flex' : '',
                    isRightSideActive && (isKboContents || isSportsContents || isClipContents) ? '!w-[calc(100vw-31.25rem)]' : '',
                )}
                isRightSideActive={isRightSideActive}
                css={appendStyle}
                setWidth={width}
                setHeight={height}
                isResizing={isResizing()}
            >
                <div
                    className={`player ${isAnotherPage ? 'is-minimode' : ''}`}
                    ref={playerRef}
                    tabIndex={-1}
                    onFocus={() => {
                        setIsFocus(true);
                    }}
                    onBlur={() => {
                        setIsFocus(false);
                    }}
                >
                    {/* <div>div</div>
                <button type="button">button</button> */}
                    {/* <ErrorBoundary fallback={null}> */}
                    <Player
                        isAnotherPage={isAnotherPage}
                        layoutRef={layoutRef}
                        show={isFullscreen}
                        isFocus={isFocus}
                        isTabIn={isTabIn}
                        setIsTabIn={setIsTabIn}
                        isMiniView={isMiniView}
                        setMiniView={setMiniView}
                        isLiveOrClipContent={isLiveOrClipContent}
                    />
                    {/* </ErrorBoundary> */}
                </div>
                {isRightSideActive && !(isKboContents || isSportsContents || isClipContents) ? (
                    <div className={tempCnx('shrink-0 basis-[31.25rem] relative bg-[black] ')}>
                        <ChatLayoutSwitcher />
                    </div>
                ) : null}
            </StyledPlayerWrap>
            {/* AdCompanion Component */}
            <StyledPlayerAdCompanion
                ref={companionBannerRef}
                className={tempCnx(isKboContents || isSportsContents || isClipContents ? 'flex-center w-full bg-black' : '')}
                id="player-ad-companion"
                isDisplayNone={isAnotherPage || !isLiveOrClipContent}
            />
        </div>
    );
};

export default PlayerElement;
