import moment from 'moment';
import useTranslation from 'next-translate/useTranslation';
import { getAgeGrade } from '@utils/common/Code';
import { useAbortSWRInfinite } from './plugins';

/**
 * My > 시청 내역 List
 * @param {number} pageNo - 페이지 번호.
 * @return {Object|boolean} data, source, isLoading, isError Value.
 */
export const useMyLastViewList = ({ contentType = 'episode', startDate = '' }) => {
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달
    const isEpisode = contentType === 'episode';
    // ?${urlString.stringify(keys)}
    // const { data } = useSWR([url, random], fetcher)
    const listData = useAbortSWRInfinite(
        `v2/media/my/lasts?contentType=${contentType}`,
        {
            params: {
                pageSize: 15,
                order: 'new',
                startDate,
            },
            withCredentials: true,
        },
        {},
        (data) => {
            data.items?.map((item) => {
                const { title, vodName, watchThumbnail, viewDate } = item;
                item.title = isEpisode && vodName ? `${vodName}` : title;
                item.thumbnail = watchThumbnail;
                item.subInfo = `${moment(`${viewDate}`, 'YYYYMMDD').format('YYYY.MM.DD')} 시청`;
                item.ageGrade = getAgeGrade(item.gradeCode); // 시청 연령
            });
            return data;
        },
    );
    return { data: listData };
};
/**
 * My > 구매 내역 List
 * @param contentType
 * @returns {Object|boolean}
 */
export const useMyPurchaseList = ({ contentType = 'episode' }) => {
    const isEpisode = contentType === 'episode';
    const { t } = useTranslation('common');
    const listData = useAbortSWRInfinite(
        `v2/media/my/purchase/${contentType}`,
        {
            params: {
                pageSize: 15,
            },
            withCredentials: true,
        },
        {},
        (data) => {
            data.items?.map((item) => {
                item.isSelected = true;
                item.title = isEpisode ? `${item.title} ${item.subtitle}` : item.title;
                item.remainDayStr = isEpisode ? t('my.streaming') : `${t('my.streaming')}/${t('my.download')}`;
                item.remainDayStr =
                    item.remainDay > 0 ? `${item.remainDayStr} ${item.remainDay}일 남음` : `${item.remainDayStr} ${t('my.period_expired')}`;
                item.price = (item.price || 0).toLocaleString();
                item.ageGrade = getAgeGrade(item.gradeCode); // 시청 연령
            });
            return data;
        },
    );
    return { data: listData };
};

/**
 * My > 찜 내역 List
 * @param contentType
 * @returns {Object|boolean}
 */
export const useMyFavoriteList = ({ contentType = 'program' }) => {
    const type = contentType === 'program' ? 'P' : 'M';
    const listData = useAbortSWRInfinite(
        `v2/community/fan/contents?type=${type}`,
        {
            params: {
                pageSize: 15,
            },
            withCredentials: true,
        },
        {},
        (data) => {
            // result 값이 []인 경우 items 필드가 추가되지 않아 화면에서 데이터가 사라지지 않도록 처리
            return { ...data, items: data.items || [] };
        },
    );

    return { data: listData };
};

/**
 * My > 예약 내역 List
 * @returns {Object|boolean}
 */
export const useMyReserveList = () => {
    const { t } = useTranslation('common');

    const listData = useAbortSWRInfinite(
        'v2/community/schedule/reserves',
        {
            params: {
                pageSize: 15,
            },
            withCredentials: true,
        },
        {},
        (data) => {
            data.items?.map((item) => {
                const { programName, frequency, channelName, broadcastStartTime, movieName, title } = item;
                item.title = frequency ? `${programName} (${frequency}화)` : programName || movieName;
                item.subtitle = channelName;
                item.subInfo = broadcastStartTime
                    ? (broadcastStartTime && `${moment(`${broadcastStartTime}`, 'YYYYMMDDHHmmss').format('MM.DD a hh:mm')} 시작`)
                          .replace('am', t('date.time.am'))
                          .replace('pm', t('date.time.pm')) || ''
                    : ''; // 구매일

                // 분데스리가 예약내역을 위한 소스 (분데스리가 제거 시 삭제 필요)
                if (item.channelName === 'BUNDESLIGA') {
                    item.title = title;
                }

                if (!item.title) {
                    item.title = item.program_name || '';
                }
                if (!item.subtitle) {
                    item.subtitle = item.channel_name || '';
                }
                if (!item.code) {
                    item.code = item.channel_code || '';
                }
                if (!item.channelCode) {
                    item.channelCode = item.channel_code || '';
                }
                if (!item.reserveTime) {
                    item.reserveTime = item.reserve_time || '';
                }
                if (item.subInfo === '' && item.reserve_time) {
                    item.subInfo =
                        `${moment(`${item.reserve_time}`, 'YYYYMMDDHHmmss').format('MM.DD a hh:mm')} 시작`
                            .replace('am', t('date.time.am'))
                            .replace('pm', t('date.time.pm')) || '';
                }
            });
            return data;
        },
    );
    return { data: listData };
};
