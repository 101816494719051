import { Vouchers } from '@typings/membership';

/**
 * Partner
 */
export enum Partner {
    LgBundle = 'LG_BUNDLE',
    LgService = 'LG_SERVICE',
    KtBundle = 'KT_BUNDLE',
    KtService = 'KT_SERVICE',
    Naver = 'NAVER',
}

export const PARTNERNAME: Vouchers = {
    LG_BUNDLE: {
        name: 'LG U+',
        type: 'bundle',
        banner: {
            imgSrc: '',
            imgAlt: '',
            bgImage: { webp: '/img/banner_lg@3x.webp', jpg: '/img/banner_lg@3x.jpg' },
            pointColor: '#ea008b',
        },
        multiVoucher: {
            voucherTitle: 'LG U+ 이용권은 LG U+ 홈페이지에서 변경 및 확인 가능합니다.\n 이용권 내역으로 이동하시겠습니까?',
            voucherText: '',
            voucherUrl: '/my/membership',
        },
        subscribe: {
            subscribeTitle: 'LG U+ 이용권을 이용 중이시네요!',
            subscribeText: '해당 이용권은 LG U+ 홈페이지에서 확인하실 수 있습니다.\n LG U+에서 확인하시겠습니까?',
            checkUrl: 'https://www.lguplus.com/mypage/info',
        },
        unSubscribeUrl: 'https://www.lguplus.com/mypage/info',
        player: {
            playerTitle: '1080p 화질은 이용권 업그레이드 후\n 이용 가능합니다. 이용권 내역에서\n 보유하신 이용권을 확인해주세요.',
            playerUrl: '/my/membership',
        },
    },
    LG_SERVICE: {
        name: 'LG U+',
        type: 'service',
        banner: {
            imgSrc: '',
            imgAlt: '',
            bgImage: { webp: '/img/banner_lg@3x.webp', jpg: '/img/banner_lg@3x.jpg' },
            pointColor: '#ea008b',
        },
        multiVoucher: {
            voucherTitle: 'LG U+ 이용권은 LG U+ 홈페이지에서 변경 및 확인 가능합니다.\n 이용권 내역으로 이동하시겠습니까?',
            voucherText: '',
            voucherUrl: '/my/membership',
        },
        subscribe: {
            subscribeTitle: 'LG U+ 이용권을 이용 중이시네요!',
            subscribeText: '해당 이용권은 LG U+ 홈페이지에서 확인하실 수 있습니다.\n LG U+에서 확인하시겠습니까?',
            checkUrl: 'https://www.lguplus.com/mypage/info',
        },
        unSubscribeUrl: 'https://www.lguplus.com/mypage/info',
        player: {
            playerTitle: '1080p 화질은 이용권 업그레이드 후\n 이용 가능합니다. 이용권 내역에서\n 보유하신 이용권을 확인해주세요.',
            playerUrl: '/my/membership',
        },
    },
    KT_BUNDLE: {
        name: 'KT',
        type: 'bundle',
        banner: {
            imgSrc: '',
            imgAlt: '',
            bgImage: { webp: '/img/banner_kt@3x.webp', jpg: '/img/banner_kt@3x.jpg' },
            pointColor: '#ff0000',
        },
        multiVoucher: {
            voucherTitle: 'KT 이용권은 KT 홈페이지에서 변경 및 확인 가능합니다.\n 이용권 내역으로 이동하시겠습니까?',
            voucherText: '',
            voucherUrl: '/my/membership',
        },
        subscribe: {
            subscribeTitle: 'KT 이용권을 이용 중이시네요!',
            subscribeText: '해당 이용권은 KT홈페이지에서 확인하실 수 있습니다. \n KT 홈페이지에서 확인하시겠습니까?',
            checkUrl: 'https://my.kt.com/myinfo/MobileMyInfoDetail.do',
        },
        unSubscribeUrl: 'https://my.kt.com/myinfo/MobileMyInfoDetail.do',
        player: {
            playerTitle: '1080p 화질은 이용권 업그레이드 후\n 이용 가능합니다. 이용권 내역에서\n 보유하신 이용권을 확인해주세요.',
            playerUrl: '/my/membership',
        },
    },
    KT_SERVICE: {
        name: 'KT',
        type: 'service',
        banner: {
            imgSrc: '',
            imgAlt: '',
            bgImage: { webp: '/img/banner_kt@3x.webp', jpg: '/img/banner_kt@3x.jpg' },
            pointColor: '#ff0000',
        },
        multiVoucher: {
            voucherTitle: 'KT 이용권은 KT 홈페이지에서 변경 및 확인 가능합니다.\n 이용권 내역으로 이동하시겠습니까?',
            voucherText: '',
            voucherUrl: '/my/membership',
        },
        subscribe: {
            subscribeTitle: 'KT 이용권을 이용 중이시네요!',
            subscribeText: '해당 이용권은 KT홈페이지에서 확인하실 수 있습니다. \n KT 홈페이지에서 확인하시겠습니까?',
            checkUrl: 'https://my.kt.com/myinfo/MobileMyInfoDetail.do',
        },
        unSubscribeUrl: 'https://my.kt.com/myinfo/MobileMyInfoDetail.do',
        player: {
            playerTitle: '1080p 화질은 이용권 업그레이드 후\n 이용 가능합니다. 이용권 내역에서\n 보유하신 이용권을 확인해주세요.',
            playerUrl: '/my/membership',
        },
    },
    NAVER: {
        name: 'NAVER',
        type: 'membership',
        banner: {
            imgSrc: 'banner_naver',
            imgAlt: '티빙x네이버플러스 멤버쉽 혜택 확인하기',
            bgImage: {},
            pointColor: '',
        },
        multiVoucher: {
            voucherTitle: '네이버플러스 멤버십 이용권 보유 중에는 구독 예약만 가능합니다.\n구독 예약으로 이동할까요?',
            voucherText: '',
            voucherUrl: '/membership/naver',
        },
        subscribe: {
            subscribeTitle: '',
            subscribeText: '',
            checkUrl: '',
        },
        unSubscribeUrl: '/membership/naver',
        player: {
            playerTitle: ``,
            playerUrl: '',
        },
    },
};
