import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';

import { useCookie } from 'react-use';
import dynamic from 'next/dynamic';

import { useRouter } from 'next/router';

import useCrossPlatform from '@utils/crossPlatform/react/useCrossPlatform';
import { getMessaging, isSupported, onMessage } from 'firebase/messaging';
import { getSelectorsByUserAgent, getUA, isDesktop, isMobile, isSafari, isSmartTV } from '@utils/device/device-detect';
import { useLogging, useUserBillProfile, useUserData, useUserInfo, useUserRegionCode } from '@store/state';
import styles from './styles.module.scss';

import { firebaseCloudMessaging } from '@utils/webpush';
import ModalComp from '@components/Modal';
import ModalAgree from '@components/modal/ModalAgree';
import { openCouponRegister } from '@utils/common/BillApi';
import { passwordChangedModal } from '@utils/common/UserApi';

import { pageOrigin, userOrigin } from '@utils/apiConfig';
import { PARTNERNAME } from '@constants/partner';
import { getCookie } from '@utils/common/cookie';

import seGroupAmplitude from '@tving/utils/src/utils/amplitude/seGroupAmplitude';
import setUserIdAmplitude from '@tving/utils/src/utils/amplitude/setUserIdAmplitude';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';

import KboHeader from '@components/kbo/home/header/KboHeader';
import KboHomeTab from '@components/kbo/home/homeTab/KboHomeTab';
import KboNavigation from '@components/kbo/common/KboNavigation/KboNavigation';
import KboNavigationProvider from '@contexts/kbo/navigation/KboNavigationProvider';

import { shallow } from 'zustand/shallow';

import KboHeaderLayout from '@layouts/kbo/KboHeaderLayout/KboHeaderLayout';
import getKboContentsExceptionalStyles from '@utils/kbo/getKboContentsExceptionalStyles/getKboContentsExceptionalStyles';
import { CrossPlatformType } from '@utils/crossPlatform';

import TopWallpaper from './TopWallpaper';

import KboMyTeamSettingModal from '@components/kbo/myteam/setting/KboMyTeamSettingModal';
import ErrorBoundaryWrapper from '@components/kbo/common/ErrorBoundaryWrapper';
import useKboMyTeamModalStore from '@stores/kbo/useKboMyTeamModalStore';
import AthleteMobileWallpaper from '@components/kbo/athlete/AthleteMobileWallpaper';
import KboWallPaper from '@components/kbo/wallPaper/KboWallPaper';

const BrazeInitPage = dynamic(
    () => {
        return import('@components/common/BrazeInit');
    },
    { ssr: false },
);

// const KboDebugModal = dynamic(() => import('@layouts/kbo/defaultLayout/KboDebugModal'), {
//     ssr: false,
// });

const FooterComponent = dynamic(() => import('@components/Footer'), {
    // suspense: true,
    ssr: false,
});

const KboDefaultLayout = (props) => {
    const router = useRouter();
    const [lastPwUpdateCookie] = useCookie('TLPUDB35Qhn7');
    const [token] = useCookie('_tving_token');
    const { children, ssrToken, userInfoData, userInfoProfileData, userInfoValidTicketData, logoImgUrl, regionCode, device } = props;
    const kboLayoutRef = useRef(null);
    const [isTopOfScroll, setIsTopOfScroll] = useState(true);
    const [kboHomeBgOpacity, setKboHomeBgOpacity] = useState(0);
    const { isOnMyTeamModal, setIsOnMyTeamModal } = useKboMyTeamModalStore(
        (state) => ({ isOnMyTeamModal: state.isOnMyTeamModal, setIsOnMyTeamModal: state.setIsOnMyTeamModal }),
        shallow,
    );

    const { isMobileWeb, isMobileWebview: isWebview } = device || {};
    const { push, pathname, query } = router;
    const [, setUserRegionCode] = useUserRegionCode('');
    const [{ setBrazeWebLoginStatus }] = useLogging();

    // ssr 이 있을때와 없을때 분기 필요.
    let { userAgent } = props;
    userAgent = userAgent || getSelectorsByUserAgent(getUA);

    useEffect(() => {
        void setUserRegionCode(regionCode); // CSR에서 regionCode 사용을 위한 상태관리
    }, []);

    useEffect(() => {
        Modal.setAppElement('#modal-root');

        // 쿠폰 열기
        const { action } = query || {};
        if (action === 'coupon') {
            void push(pathname, undefined, { shallow: true });

            ModalComp.dialog({
                title: '지금 쿠폰등록 하시겠습니까?',
                text: "나중에 등록 시 '프로필 > 쿠폰등록' 메뉴에서 등록 가능합니다.",
            }).then(({ isConfirmed }) => {
                if (isConfirmed) {
                    openCouponRegister(`?returnUrl=${encodeURIComponent(`${window.location.href}?action=coupon`)}`);
                }
            });
        }

        if (isMobile) {
            navigator.serviceWorker?.getRegistrations()?.then((registrations) => {
                // eslint-disable-next-line no-restricted-syntax
                for (const registration of registrations) {
                    if (registration.scope?.indexOf('tving.com') !== -1) {
                        registration.unregister();
                    }
                }
            });
        }

        // eslint-disable-next-line
        require('clientjs');
    }, [pathname, push, query, userAgent.getUA]);

    const [userInfo, setUserInfo] = useUserInfo({
        initialData: {
            isLogin: !!ssrToken,
        },
    });

    useEffect(() => {
        setUserInfo((prev) => ({
            ...prev,
            isLogin: !!ssrToken,
        }));
    }, [ssrToken]);

    const userData = useUserData({
        initialData: userInfoData,
        initialProfileData: userInfoProfileData,
        initialValidTicketData: userInfoValidTicketData,
    });
    useEffect(() => {
        if (token && !userData?.token && !userData?.userNo) {
            logout();
        }
    }, [token, userData?.token, userData?.userNo]);

    const logout = () => {
        const returnUrl = `${window.location.origin}/onboarding`;
        const logoutUrl = `${userOrigin}/pc/user/doLogout.tving`;
        setBrazeWebLoginStatus({ loginWebYn: 'N' });

        window.location.href = `${logoutUrl}?returnUrl=${encodeURIComponent(returnUrl)}`;
    };

    const { isLogin } = userInfo;
    const { data } = useUserBillProfile();
    const { purchaseCode } = data || {};
    const isPartner = Object.keys(PARTNERNAME).includes(purchaseCode);
    const { namesubscribe: { subscribeTitle, subscribeText, checkUrl } = {} } = PARTNERNAME[purchaseCode] || {};

    useEffect(() => {
        // setUserInfo
        setUserInfo({
            ...userInfo,
            linkPay: () => {
                if (isPartner) {
                    if (purchaseCode === PARTNERNAME.NAVER.name) {
                        push('/membership/naver');
                    } else {
                        ModalComp.dialog({
                            title: subscribeTitle,
                            text: subscribeText,
                        }).then(({ isConfirmed }) => {
                            if (isConfirmed) {
                                window.open(checkUrl);
                            }
                        });
                    }
                } else {
                    push('/membership/tving');
                }
            },
        });
    }, [purchaseCode]);

    useEffect(() => {
        setUserInfo((prev) => ({
            ...prev,
            ...userData,
        }));
    }, [userData.isLogin, userData.profileNo]);

    useEffect(() => {
        if (
            !isWebview &&
            lastPwUpdateCookie &&
            lastPwUpdateCookie !== '' &&
            userData.lastPasswordUpdateDate &&
            userData.lastPasswordUpdateDate !== '' &&
            lastPwUpdateCookie !== userData.lastPasswordUpdateDate
        ) {
            passwordChangedModal();
        }
    }, [userData.lastPasswordUpdateDate]);

    // Firebase.
    useEffect(() => {
        if (!isLogin || isSafari || isMobile) {
            return;
        }

        const initialFirebase = () => {
            const getMessage = () => {
                const messaging = getMessaging();
                onMessage(messaging, (payload) => {
                    const { title, sub_title, contents, app_url_scheme } = JSON.parse(payload?.data?.body || '{}');
                    const notificationTitle = `${title} - ${sub_title}`;
                    const notificationOptions = {
                        body: contents,
                        // icon: image,
                        icon: `${pageOrigin}/img/tving-icon@3x.png`,
                    };

                    const url = new URL(app_url_scheme);
                    const params = new URLSearchParams(url.search);
                    const mediaCode = params.get('cd');
                    const linkUrl = `${pageOrigin}/player/${mediaCode}`;

                    const notification = new Notification(notificationTitle, notificationOptions);
                    notification.onclick = (event) => {
                        event.preventDefault();
                        window.open(linkUrl, '_blank');
                        notification.close();
                    };
                });
            };

            const setToken = async () => {
                try {
                    const token = await firebaseCloudMessaging.init();
                    if (token) {
                        getMessage();
                    }
                } catch (error) {
                    console.log(error);
                }
            };

            setToken();
        };

        // Firebase isSupported 체크 추가
        isSupported().then((isFirebaseSupported) => {
            if (isFirebaseSupported) {
                initialFirebase();
            }
        });
    }, [isLogin]);

    const [mainClassName, setMainclassName] = useState([]);
    useEffect(() => {
        if (isDesktop) {
            setMainclassName(['is-desktop']);
        }
    }, []);

    useEffect(() => {
        setIsMobileDevice(isMobile);
    }, []);

    const isMounted = useRef(false);

    useEffect(() => {
        if (token && isLogin) {
            // user쪽에서 set해주는 값을 사용하는게 아닌, tving.com 이동 시 직접 setUserId() 함수를 통해 선택된 프로필 토큰 값을 추가
            setUserIdAmplitude(token);
        }
    }, [token, isLogin]);

    useEffect(() => {
        if (isLogin && !isMounted.current) {
            // _tutB3583: master profile 토큰 값
            seGroupAmplitude(getCookie('_tutB3583'));

            isMounted.current = true;
        }
    }, [isLogin]);

    useEffect(() => {
        const onScroll = () => {
            const scrollY = window.pageYOffset;
            const innerWidth = window?.innerWidth;
            const ratioHeight = innerWidth * (9 / 16);

            setIsTopOfScroll(scrollY <= 0); // overscroll bouncing에서는 음수 값이 나올 수 있습니다.
            setKboHomeBgOpacity(scrollY > ratioHeight ? 100 : (scrollY / ratioHeight).toFixed(2));
        };

        document.addEventListener('scroll', onScroll);

        return () => document.removeEventListener('scroll', onScroll);
    }, []);

    const crossPlatformController = useCrossPlatform();
    useEffect(() => {
        const handleChangeContentInset = (event) => {
            document.body.style.setProperty('--content-inset-top', `${event.detail.top}px`);
            document.body.style.setProperty('--content-inset-bottom', `${event.detail.bottom}px`);
            document.body.style.setProperty('--content-inset-right', `${event.detail.right}px`);
            document.body.style.setProperty('--content-inset-left', `${event.detail.left}px`);
        };
        window.addEventListener('onChangeContentInset', handleChangeContentInset);
        return () => {
            window.removeEventListener('onChangeContentInset', handleChangeContentInset);
        };
    }, []);
    useEffect(() => {
        // ONLY Android
        if (crossPlatformController.getCrossPlatformType() !== CrossPlatformType.ANDROID) {
            return () => null;
        }

        const handleSafeAreaEvent = (event) => {
            document.body.style.setProperty('--safe-area-inset-top', `${event.detail.top}px`);
            document.body.style.setProperty('--safe-area-inset-bottom', `${event.detail.bottom}px`);
            document.body.style.setProperty('--safe-area-inset-right', `${event.detail.right}px`);
            document.body.style.setProperty('--safe-area-inset-left', `${event.detail.left}px`);
        };
        const handleChangeOrientation = () => {
            crossPlatformController.getSafeArea();
        };

        crossPlatformController.getSafeArea();

        window.addEventListener('onSafeArea', handleSafeAreaEvent);
        window.addEventListener('onChangeOrientation', handleChangeOrientation);
        return () => {
            window.removeEventListener('onSafeArea', handleSafeAreaEvent);
            window.removeEventListener('onChangeOrientation', handleChangeOrientation);
        };
    }, []);

    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const isKboHome = ['/kbo/home', '/kbo/home/main'].includes(pathname);
    const isKboMorePage = pathname.startsWith('/kbo/more');
    const isKboMyTeamSetting = pathname.startsWith('/kbo/myteam/setting');
    const isKboPlaylistMorePage = pathname.startsWith('/kbo/more/schedule/playlist');
    const isKboCurationMorePage = pathname.startsWith('/kbo/more/curation');
    const isKboContents = pathname.startsWith('/kbo/contents/');
    const isKboAthlete = pathname.startsWith('/kbo/athlete');
    const isKboRoaster = pathname.startsWith('/kbo/roaster');
    const isKboTeamDetail = pathname.startsWith('/kbo/team');

    // 상단 여백 값을 상수로 정의합니다.
    const MOBILE_WEB_MARGIN = 'mt-[4rem]';
    const MOBILE_WEB_PLAYLIST_MORE_MARGIN = 'mt-[3.666rem]';
    const MOBILE_WEB_MORE_MARGIN = 'mt-[3.666rem]';
    const DEFAULT_MARGIN = 'mt-[calc(8rem_+_var(--safe-area-inset-top))]';
    const ATHLETE_MARGIN = 'mt-[calc(3.666rem_+_var(--safe-area-inset-top))]';
    const ROASTER_MARGIN = 'mt-[calc(3.666rem_+_var(--safe-area-inset-top))]';
    const TEAM_DETAIL_MARGIN = 'mt-[calc(3.666rem_+_var(--safe-area-inset-top))]';

    const DESKTOP_ATHLETE_MARGIN = 'mt-[8.333rem]';
    const DESKTOP_ROASTER_MARGIN = 'mt-[8.333rem]';
    const DESKTOP_TEAM_DETAIL_MARGIN = 'mt-[8.333rem]';

    const DESKTOP_MARGIN = 'mt-[9.916rem]';

    const getKboMoreSectionMargin = () => {
        if (isMobileWeb && isWebview) {
            if (isKboPlaylistMorePage) {
                return MOBILE_WEB_PLAYLIST_MORE_MARGIN;
            }
            if (isKboMorePage || isKboCurationMorePage) {
                return MOBILE_WEB_MORE_MARGIN;
            }
            if (isKboAthlete) {
                return ATHLETE_MARGIN;
            }
            if (isKboRoaster) {
                return ROASTER_MARGIN;
            }
            if (isKboTeamDetail) {
                return TEAM_DETAIL_MARGIN;
            }

            return DEFAULT_MARGIN;
        }
        if (isMobileWeb) {
            if (isKboAthlete || isKboRoaster || isKboTeamDetail) {
                return '';
            }

            return MOBILE_WEB_MARGIN;
        }

        if (isKboAthlete) {
            return DESKTOP_ATHLETE_MARGIN;
        }

        if (isKboRoaster) {
            return DESKTOP_ROASTER_MARGIN;
        }

        if (isKboTeamDetail) {
            return DESKTOP_TEAM_DETAIL_MARGIN;
        }

        return DESKTOP_MARGIN;
    };

    const getKboSectionClassName = () => {
        return `kbo-main-section ${getKboMoreSectionMargin()}`;
    };

    const screen = {
        isTopOfScroll,
        kboHomeBgOpacity,
    };

    return (
        <>
            <div
                ref={kboLayoutRef}
                className={`${styles['Layout__safe-area']} grow flex flex-col justify-stretch ${getKboContentsExceptionalStyles({
                    isKboContents,
                    isMobile: isMobileWeb,
                    isWebview,
                })} `}
            >
                {isKboHome ? <TopWallpaper device={device} /> : null}

                {isKboAthlete && device.isMobileWeb ? <AthleteMobileWallpaper device={device} /> : null}
                {isKboTeamDetail && device.isMobileWeb ? <KboWallPaper /> : null}
                <div id="modal-root" />
                <KboNavigationProvider device={device} screen={screen}>
                    <KboHeaderLayout device={device}>
                        {(isKboMorePage ||
                            isKboMyTeamSetting ||
                            (isKboAthlete && isWebview) ||
                            (isKboRoaster && isWebview) ||
                            (isKboTeamDetail && isWebview)) &&
                        isMobileWeb ? null : (
                            <KboNavigation isLogin={isLogin} userAgent={userAgent} logoImgUrl={logoImgUrl} />
                        )}
                        {(isKboMorePage ||
                            isKboMyTeamSetting ||
                            (isKboAthlete && isWebview) ||
                            (isKboRoaster && isWebview) ||
                            (isKboTeamDetail && isWebview)) &&
                        isMobileWeb ? null : (
                            <KboHeader setIsOnMyTeamModal={setIsOnMyTeamModal} isLogin={isLogin} />
                        )}
                        {(isKboMorePage || isKboMyTeamSetting || isKboAthlete || isKboRoaster || isKboTeamDetail) && isMobileWeb ? null : (
                            <KboHomeTab />
                        )}
                        {!isWebview && !isMobileWeb && isOnMyTeamModal ? (
                            <ErrorBoundaryWrapper>
                                <KboMyTeamSettingModal
                                    isOpen={isOnMyTeamModal}
                                    onClose={() => {
                                        setIsOnMyTeamModal(false);
                                    }}
                                />
                            </ErrorBoundaryWrapper>
                        ) : null}
                    </KboHeaderLayout>

                    {isLogin && !isMobileDevice && <ModalAgree />}

                    <main className={tempCnx('kbo-main', 'grow', mainClassName.join(' '))}>
                        <section className={getKboSectionClassName()}>{children}</section>
                    </main>

                    {!isWebview && !isSmartTV && !isKboContents ? <FooterComponent isLogin={isLogin} {...userAgent} /> : null}
                    {/* <KboDebugModal /> */}
                </KboNavigationProvider>
            </div>
            <BrazeInitPage />
        </>
    );
};

export default KboDefaultLayout;
