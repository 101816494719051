import { ThumbnailImage } from '@components/item/ItemComponent';

const SingleBannerItem = ({
    href,
    idx,
    type, // 타입 - movie / episode
    bandType,
    title, // 타이틀 (배너명)
    thumbnail, // 썸네일 이미지
    ratio,
    onClick,
}) => {
    const ratioClassName = ratio ? `item__thumb-${ratio}` : '';

    /**
     * 아이템 클릭 메소드
     */
    const moveLink = e => {
        // route.push(href);

        onClick?.(e);
        window.location.href = href;
    };
    return (
        /* <Link scroll href={href} passHref> */
        <a onClick={e => moveLink(e)}>
            <div className={`item__thumb ${ratioClassName}`}>
                {/* 컨텐츠(포스터) */}
                <ThumbnailImage thumbnail={thumbnail} size={-1} />
            </div>
        </a>
        /* </Link> */
    );
};

export default SingleBannerItem;
