import axiosInstance from '@tving/utils/src/utils/http';
import { GATEWAY_URL } from '@tving/utils/src/utils/common/apiConfig';
import { moveLogout, moveProfileSelect, moveProfileSelectSsr } from '@utils/common/UserApi';
import { getCookie, setCookie } from '@utils/common/cookie';
import fetchAccessToken from '@tving/utils/src/apis/accessToken/fetchAccessToken';
import isRequiredAccessTokenApi from '@tving/utils/src/utils/common/isRequiredAccessTokenApi/isRequiredAccessTokenApi';
import isRequiredLoginPath from '@tving/utils/src/utils/common/isRequiredLoginPath/isRequiredLoginPath';
import * as Sentry from '@sentry/nextjs';
import https from 'https';
import axiosRetry from 'axios-retry';

// let tvingToken = '';
// let authToken = '';
// if (typeof window !== 'undefined') {
//     authToken = getCookie('authToken');
//     tvingToken = getCookie('_tving_token');
// }

// 뉴티빙 - tving-utils API 공통
const httpsAgent = new https.Agent({ keepAlive: true });
axiosInstance.defaults.httpsAgent = httpsAgent;

axiosInstance.defaults.timeout = 5000;
axiosInstance.defaults.params = {
    screenCode: 'CSSD0100',
    osCode: 'CSOD0900',
};

// RT를 이용해 AT 재발급 받는 API 호출
export const createAccessTokenApi = async ({ refreshToken, serverRes, origin }) => {
    const newAccessToken = await fetchAccessToken({ refreshToken }, { serverRes, origin })
        .then((response) => {
            const { accessToken } = response?.data || {};

            if (accessToken) {
                // Access Token 쿠키 생성 - 세션기준
                if (serverRes) {
                    serverRes.setHeader('set-Cookie', `accessToken=${accessToken}; Path=/; Domain=.tving.com;`);
                } else {
                    setCookie('accessToken', accessToken, { path: '/', domain: '.tving.com' });
                }
            }
            return accessToken;
        })
        .catch((e) => {
            // console.log('fetchAccessToken e', e);
        });

    return newAccessToken;
};

axiosRetry(axiosInstance, {
    retries: 3,
    retryCondition: () => true,
    shouldResetTimeout: true, // timeout reset 여부
    retryDelay: (retryCount, error) => {
        return retryCount * 250; // 횟수마다 retryDelay 증가
    },
});

// tving-utils API 인터셉터
axiosInstance.interceptors.request.use(
    async (config) => {
        const convertedRequestConfig = config;

        // Cookie 값 참조 대상 분기
        const currentCoookie = (key: string) => {
            if (config.serverCookies) {
                return config.serverCookies[key];
            }
            return getCookie(key);
        };

        // SSR 예외를 빼서 SSR/CSR 모두 동시에 동작하게..
        // if (typeof window !== 'undefined') {
        let accessToken = currentCoookie('accessToken') || '';
        const refreshToken = currentCoookie('refreshToken') || '';
        const tvingToken = currentCoookie('_tving_token') || '';
        const authToken = currentCoookie('authToken') || '';
        const urlPath = config?.url?.split(GATEWAY_URL)[1] || '';
        const pathname = String(config?.pathname || ''); // API 호출한 pathname. ex)/more/curation/[key]

        // console.log('fetch', { accessToken, refreshToken }, config.serverCookies ? 'server' : 'csr', config?.url);

        // 로그인이 필요한 path 인 경우
        // if (pathname !== '' && isRequiredLoginPath(pathname)) {
        // Acccess Token & Refresh Token 둘 다 없는 경우 로그아웃 처리
        // if (accessToken === '' && refreshToken === '') {
        //     moveLogout({ serverRes: config.serverRes || null, origin: config?.origin });
        //     return Promise.reject();
        // }

        // AT 없는경우 발급 후 쿠키 생성
        if (accessToken === '' && refreshToken && isRequiredAccessTokenApi(urlPath)) {
            // Access Token 발급 API 호출 및 AT 쿠기 굽기
            accessToken = await createAccessTokenApi({ refreshToken, serverRes: config.serverRes || null, origin: config?.origin });
        }
        // }

        // 인증처리가 없어야 하는 API를 제외하되
        if (isRequiredAccessTokenApi(urlPath)) {
            // 로그인만 접근 가능한 path거나 비로그인이 접근 가능한 경우엔 로그인된 경우에만 헤더에 Access-Token 추가
            if (isRequiredLoginPath(pathname) || (!isRequiredLoginPath(pathname) && tvingToken !== undefined)) {
                convertedRequestConfig.headers = {
                    ...convertedRequestConfig.headers,
                    'Auth-Token': authToken || '',
                    'Access-Token': accessToken || '',
                    Authorization: `Bearer ${tvingToken || ''}`,
                };
            }
        }

        try {
            if (config?.serverReq) {
                if (config?.serverReq?.headers['x-forwarded-for']) {
                    convertedRequestConfig.headers = {
                        ...convertedRequestConfig.headers,
                        'Tving-X-Forwarded-For': config?.serverReq?.headers['x-forwarded-for'] || '',
                    };
                }
            }
        } catch (e) {
            // sentry 추가 필요
        }
        // 'Tving-X-Forwarded-For': ctx?.req?.headers['x-forwarded-for'] || '',

        if (convertedRequestConfig.params?.accessToken) {
            convertedRequestConfig.params.accessToken = accessToken;
        }

        // console.log('fetch convertedRequestConfig', convertedRequestConfig?.headers);

        return convertedRequestConfig;
    },
    (error) => {
        Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        // API 요청 응답 결과 code 401인 경우 유효하지 않은 프로필로 판단
        if (response?.data?.header?.status === 401) {
            Sentry.captureMessage('[Custom Error] API Send Response - 401', { level: 'log' });
            // SSR로 호출하는 경우 window undefined 오류로 예외처리. ex) authToken 없는 상태로 에로컨텐츠 상세화면 접근 시 user/info에서 401 발생
            if (typeof window !== 'undefined') {
                moveProfileSelect();
            }
        }

        return response;
    },
    async (error) => {
        // console.log('#here response error', error?.config?.serverRes);
        // console.log('#here response error', error);
        console.log('#here response error :', error?.response?.data?.code);

        //  여기서 다시 시도 후 ...?
        // Cookie 값 참조 대상 분기
        const currentCoookie = (key: string) => {
            if (error?.config.serverCookies) {
                return error?.config.serverCookies[key];
            }
            return getCookie(key);
        };

        // 4000 : AT 재발급
        if (error?.response?.data?.code === '4000') {
            const refreshToken = currentCoookie('refreshToken') || '';
            if (refreshToken === '') {
                moveLogout({ serverRes: error?.config?.serverRes || null, origin: error?.config?.origin });
                return Promise.reject(error);
            }
            const accessToken = await createAccessTokenApi({
                refreshToken,
                serverRes: error?.config.serverRes || null,
                origin: error?.config?.origin,
            });

            // serverCookies 가 존재하면 업데이트 필요.
            if (error.config.serverCookies) {
                error.config.serverCookies = {
                    ...error.config.serverCookies,
                    accessToken,
                };
            }
            if (!error.config.isRetryBlock) {
                error.config.isRetryBlock = true;
                return axiosInstance.request(error.config);
            }
            // return move500Page({ serverRes: error?.config?.serverRes || null });
        }
        if (error?.response?.data?.code === '401' || error?.response?.data?.code === '4004') {
            // 401 : 로그아웃
            // 4004 : 로그아웃 처리 (AT 생성 시 발생가능)
            moveLogout({ serverRes: error?.config?.serverRes || null, origin: error?.config?.origin });
        }
        if (error?.response?.data?.code === '403') {
            // 403 : 프로필 선택 페이지로 이동
            moveProfileSelectSsr({ serverRes: error?.config?.serverRes });
        }

        return Promise.reject(error);
    },
);

export default axiosInstance;
