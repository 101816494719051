import { pageOrigin } from '@utils/apiConfig';

// SSR 페이지 리스트
export const URL_TVING = pageOrigin;
export const URL_ONBOARDING = '/onboarding';
export const URL_SCHEDULE = '/schedule';
export const URL_ONBOARDING_SMARTTV = '/onboarding/tv';
export const URL_FAQ = '/help/faq';
export const URL_KCON = '/event/kcon';
export const URL_CONTENTS_DETAIL = '/contents';
export const URL_PLAYER = '/player';

export const URL_NAVER_PLUS_MEMBERSHIP = 'https://nid.naver.com/membership/my?m=viewDigital';
export const URL_NAVER_GOODBYE_MEMBERSHIP = 'https://mkt.tving.com/goodbyeevent/index.html';

const URL_KBO = '/kbo';
const URL_KBO_HOME = '/kbo/home';
const URL_KBO_CONTENTS = '/kbo/contents';
const URL_KBO_MYTEAM = '/kbo/myteam';
const URL_KBO_ATHLETE = '/kbo/athlete';
const URL_SPORTS = '/sports';
export const URL_KBO_ROASTER = '/kbo/roaster';
export const URL_KBO_TEAM = '/kbo/team';

export const URL_SLUG = '[...slug]';
export const URL_POLICY_PRIVACY = '/policy/privacy';
export const URL_SETTINGS = '/settings';
export const URL_SETTINGS_NOTIFICATION = '/settings/notification';

export const SSR_PAGE_LIST = [
    URL_CONTENTS_DETAIL,
    `${URL_CONTENTS_DETAIL}/[mediaCode]`,
    URL_PLAYER,
    `${URL_PLAYER}/[slug]`,
    URL_ONBOARDING,
    '/more/special/[key]',
    '/more/band/[key]',
    '/more/genre/[key]',
    '/more/curation/[key]',
];

// 비로그인 허용 주소 리스트
export const PC_NO_LOGIN_USER_PASS_URL_LIST = [
    '/lg/ma/shortcutloginT',
    '/billing/billCommand',
    '/bridge/ios',
    '/bridge/ios/[...slug]',
    '/bridge/aos',
    '/bridge/aos/[...slug]',
    '/bridge/contents',
    '/bridge/contents/[...slug]',
    '/contents',
    '/contents/[mediaCode]',
    '/player/RV',
    '/player/RM',
    '/policy/[...slug]',
    '/policy/terms',
    '/policy/pay-terms',
    '/policy/email',
    '/policy/legal',
    '/policy/privacy',
    '/policy/youth',
    '/help/notice',
    '/help/faq',
    '/help/service-policy',
    '/help/early-termination',
    '/help/withdrawal-subscription',
    '/help/proposal',
    '/credit',
    '/event/kcon',
    '/6001',
    '/more/special/[key]',
    '/more/band/[key]',
    '/more/genre/[key]',
    '/more/curation/[key]',
    URL_ONBOARDING_SMARTTV,
    URL_ONBOARDING,
    `${URL_KBO_HOME}`,
    `${URL_KBO_HOME}/history/[tab]`,
    `${URL_KBO_HOME}/schedule`,
    `${URL_KBO_HOME}/video`,
    `${URL_KBO}/more/curation/[key]`,
    `${URL_KBO}/more/video/[...path]`,
    `${URL_KBO}/more/schedule/playlist`,
    `${URL_KBO_CONTENTS}/[gameCode]`,
    `${URL_KBO_CONTENTS}/[gameCode]/[tab]`,
    `${URL_KBO}/sendbird`,
    `${URL_KBO_TEAM}/[teamCode]`,
    `${URL_KBO_ATHLETE}/[athleteCode]`,
    `${URL_KBO_ROASTER}/[teamCode]`,
    `${URL_SETTINGS_NOTIFICATION}`,
    `${URL_SPORTS}`,
    `${URL_SPORTS}/[sportsType]/more/curation/[key]`,
    `${URL_SPORTS}/[sportsType]/more/video/[...path]`,
    `${URL_SPORTS}/[sportsType]/more/shorts/[...path]`,
    `${URL_SPORTS}/[sportsType]/more/schedule/playlist`,
    `${URL_SPORTS}/[sportsType]`,
    `${URL_SPORTS}/[sportsType]/search`,
    `${URL_SPORTS}/[sportsType]/search/results`,
    `${URL_SPORTS}/[sportsType]/[[...sportsTab]]`,
    '/contents/sports/[gameCode]',
    '/contents/sports/[gameCode]/[contentsTab]',
    '/membership/double',
];

// 모바일 허용 주소 리스트
export const MOBILE_PASS_URL_LIST = [
    '/billing/billCommand',
    '/bridge/ios',
    '/bridge/ios/[...slug]',
    '/bridge/aos',
    '/bridge/aos/[...slug]',
    '/bridge/contents',
    '/bridge/contents/[...slug]',
    '/contents',
    '/contents/[mediaCode]',
    '/player/RV',
    '/player/RM',
    '/policy/[...slug]',
    '/policy/terms',
    '/policy/pay-terms',
    '/policy/email',
    '/policy/legal',
    '/policy/privacy',
    '/policy/youth',
    '/event/kcon',
    '/6001',
    '/more/special/[key]',
    '/more/band/[key]',
    '/more/genre/[key]',
    '/more/curation/[key]',
    URL_ONBOARDING_SMARTTV,
    '/credit',
    URL_ONBOARDING,
    `${URL_KBO_HOME}`,
    `${URL_KBO_HOME}/history/[tab]`,
    `${URL_KBO_HOME}/schedule`,
    `${URL_KBO_HOME}/video`,
    `${URL_KBO}/more/curation/[key]`,
    `${URL_KBO}/more/video/[...path]`,
    `${URL_KBO}/more/schedule/playlist`,
    `${URL_KBO_CONTENTS}/[gameCode]`,
    `${URL_KBO_CONTENTS}/[gameCode]/[tab]`,
    `${URL_KBO}/sendbird`,
    `${URL_KBO_MYTEAM}`,
    `${URL_KBO_MYTEAM}/setting`,
    `${URL_KBO_TEAM}/[teamCode]`,
    `${URL_KBO_ATHLETE}/[athleteCode]`,
    `${URL_KBO_ROASTER}/[teamCode]`,
    `${URL_SETTINGS_NOTIFICATION}`,
    `${URL_SPORTS}/[sportsType]/more/curation/[key]`,
    `${URL_SPORTS}/[sportsType]/more/video/[...path]`,
    `${URL_SPORTS}/[sportsType]/more/shorts/[...path]`,
    `${URL_SPORTS}/[sportsType]/more/schedule/playlist`,
    `${URL_SPORTS}/[sportsType]`,
    `${URL_SPORTS}/[sportsType]/[[...sportsTab]]`,
    `${URL_SPORTS}/[sportsType]/search`,
    `${URL_SPORTS}/[sportsType]/search/results`,
    '/contents/sports/[gameCode]',
    '/contents/sports/[gameCode]/[contentsTab]',
    '/contents/sports/not-found',
    '/membership/tving',
    '/membership/double',
];

// 버퍼모드 진입 예외가 필요한 URL
export const NO_BUFFER_CHECK_START_URL = ['/stream', '/lg/ma', '/sm/fh/', '/6001'];

export const API_URLS = {
    STREAM_INFO: '/v2/media/stream/info',
    USER_INFO: '/v2/user/info',
    CONTENT_INFO: '/v2/media/content/info',
    PRERELEASE_CLIP_INFO: '/v2/media/prerelease/clips',
};

const APP_LINK_URLS = {
    DYNAMIC_LINK_URL: 'https://tving.page.link',
    ANDROID_APP_PACKAGE: 'apn=net.cj.cjhv.gs.tving', // 안드로이드 앱 오픈을 위한 패키지 이름
    IOS_APP_STORE_ID: 'isi=400101401', // 앱 미설치 시 앱스토어로 보내기 위한 ID
    IOS_APP_BUNDLE_ID: 'ibi=com.tving.iphone001', // 동적 링크를 통해 iOS 앱을 열기 위한 ID
    IOS_APP_PREVIEW: 'efr=1',
};

export const SUB_BOARD_SHARE_POSSIBLE_URLS = ['/more/special', '/more/genre', '/more/curation'];

// 서브보드 앱링크 URL
export const SUB_BOARD_APP_LINK_URL = ({ key = '', boardType = '' }) => {
    return (
        `${APP_LINK_URLS.DYNAMIC_LINK_URL}` +
        `/?link=${`${URL_TVING}/more/${boardType}/${key}`}` +
        `&${APP_LINK_URLS.ANDROID_APP_PACKAGE}` +
        `&${APP_LINK_URLS.IOS_APP_STORE_ID}` +
        `&${APP_LINK_URLS.IOS_APP_BUNDLE_ID}` +
        `&${APP_LINK_URLS.IOS_APP_PREVIEW}`
    );
};

export const GET_DYNAMIC_LINK_URL = ({ pathname }) => {
    return (
        `${APP_LINK_URLS.DYNAMIC_LINK_URL}` +
        `/?link=${`${URL_TVING}${pathname}`}` +
        `&${APP_LINK_URLS.ANDROID_APP_PACKAGE}` +
        `&${APP_LINK_URLS.IOS_APP_STORE_ID}` +
        `&${APP_LINK_URLS.IOS_APP_BUNDLE_ID}`
    );
};

export const GET_APP_LINK_URL = ({ pathname }) => {
    return `${URL_TVING}${pathname}`;
};

// SPORTS HOME dynamic 앱링크 URL
export const SPORTS_HOME_APP_LINK_URL = ({ sportsType }) => {
    return `${URL_TVING}/sports/${sportsType}`;
};

export const SPORTS_HOME_DYNAMIC_LINK_URL = ({ sportsType }) => {
    return (
        `${APP_LINK_URLS.DYNAMIC_LINK_URL}` +
        `/?link=${`${URL_TVING}/sports/${sportsType}`}` +
        `&${APP_LINK_URLS.ANDROID_APP_PACKAGE}` +
        `&${APP_LINK_URLS.IOS_APP_STORE_ID}` +
        `&${APP_LINK_URLS.IOS_APP_BUNDLE_ID}`
    );
};

// KBO HOME dynamic 앱링크 URL
export const KBO_HOME_APP_LINK_URL = () => {
    return (
        `${APP_LINK_URLS.DYNAMIC_LINK_URL}` +
        `/?link=${`${URL_TVING}/kbo/home`}` +
        `&${APP_LINK_URLS.ANDROID_APP_PACKAGE}` +
        `&${APP_LINK_URLS.IOS_APP_STORE_ID}` +
        `&${APP_LINK_URLS.IOS_APP_BUNDLE_ID}`
    );
};

// CLIP CONTENTS 상세화면 앱링크 URL
export const CLIP_CONTENT_DETAIL_APP_LINK_URL = ({ code = '', subPath = '' }) => {
    return (
        `${APP_LINK_URLS.DYNAMIC_LINK_URL}` +
        `/?link=${URL_TVING}/contents/${code}${subPath ? `/${subPath}` : ''}` +
        `&${APP_LINK_URLS.ANDROID_APP_PACKAGE}` +
        `&${APP_LINK_URLS.IOS_APP_STORE_ID}` +
        `&${APP_LINK_URLS.IOS_APP_BUNDLE_ID}`
    );
};

// SPORTS CONTENTS 상세화면 앱링크 URL
export const SPORTS_CONTENT_DETAIL_APP_LINK_URL = ({ code = '', subPath = '' }) => {
    return (
        `${APP_LINK_URLS.DYNAMIC_LINK_URL}` +
        `/?link=${URL_TVING}/contents/sports/${code}${subPath ? `/${subPath}` : ''}` +
        `&${APP_LINK_URLS.ANDROID_APP_PACKAGE}` +
        `&${APP_LINK_URLS.IOS_APP_STORE_ID}` +
        `&${APP_LINK_URLS.IOS_APP_BUNDLE_ID}`
    );
};

// KBO CONTENTS 상세화면 dynamic 앱링크 URL
export const KBO_CONTENT_DETAIL_APP_LINK_URL = ({ code = '', subPath = '' }) => {
    return (
        `${APP_LINK_URLS.DYNAMIC_LINK_URL}` +
        `/?link=${`${URL_TVING}/kbo/contents/${code}${subPath ? `/${subPath}` : ''}`}` +
        `&${APP_LINK_URLS.ANDROID_APP_PACKAGE}` +
        `&${APP_LINK_URLS.IOS_APP_STORE_ID}` +
        `&${APP_LINK_URLS.IOS_APP_BUNDLE_ID}`
    );
};

// 모바일 Legacy 웹뷰 인 경우 _tving_token 만 쿠키에 저장되어 요청되는 케이스에 오류 방지를 위한 방어 케이스 추가
export const NOT_GIVEN_TOKEN_PAGE_LIST = [
    '/policy/[...slug]',
    '/policy/terms',
    '/policy/pay-terms',
    '/policy/email',
    '/policy/legal',
    '/policy/privacy',
    '/policy/youth',
];
