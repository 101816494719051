import { useEffect, useState } from 'react';

import Button from '../../../atom/button/Button';
import IconCircleCheck from '../../../images/icons/icon_circle_check.svg';
import IconCircleCheckFill from '../../../images/icons/icon_circle_check_fill.svg';

type EditOverlayProps<T> = {
  item: T;
  checkItems: T[];
  isItemChecked: (item: T, checkItems: T[]) => boolean;
};

const EditOverlay = <T,>({ item, checkItems, isItemChecked }: EditOverlayProps<T>) => {
  const [isChecked, setIsChecked] = useState(isItemChecked(item, checkItems));

  useEffect(() => {
    setIsChecked(isItemChecked(item, checkItems));
  }, [checkItems, item, isItemChecked]);

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 z-20 h-full w-full bg-black/50 text-[1.25rem] z-30">
      <Button tag="div" className="absolute top-[0.83rem] right-[0.83rem] w-[2.667rem] h-[2.667rem]">
        {isChecked ? <IconCircleCheckFill width="100%" height="100%" /> : <IconCircleCheck width="2.667rem" height="2.667rem" />}
      </Button>
    </div>
  );
};

export default EditOverlay;
