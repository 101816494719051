import React from 'react';

/**
 * @deprecated
 * OnboardingModal에서 더 이상 사용하지 않습니다!
 * TODO: 다른 곳에서 사용하는 모달을 HeadlessModal로 변경 후 CustomOverlayElement를 제거해 주세요.
 */
const CustomOverlayElement = ({ onClick, onMouseDown, ...rootProps }: React.ComponentPropsWithRef<'div'>, children: React.ReactNode) => {
    return (
        <div {...rootProps}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
                /**
                 * Modal Content에서 드래깅을 시작한 후,
                 * Modal Overlay 영역에서 마우스를 떼면 Overlay 요소의 클릭 이벤트가 발생하여 Modal이 닫히게 됩니다.
                 * (특정 브라우저(ex. Chrome)에서만 발생하는 이슈입니다.)
                 *
                 * 이와 같은 동작이 발생하지 않도록,
                 * Overlay 영역의 클릭 + 마우스 이벤트를 루트 노드의 새로운 자손 요소가 처리하도록 변경합니다.
                 *
                 * @see {@link https://github.com/reactjs/react-modal/issues/383 Github Issue}
                 * @see {@link https://github.com/reactjs/react-modal/blob/v3.14.4/src/components/ModalPortal.js#L399C41-L399C41 React Modal Source}
                 */
                className="absolute inset-x-0 inset-y-0"
                onClick={onClick}
                onMouseDown={onMouseDown}
            />

            {children}
        </div>
    );
};

export default CustomOverlayElement;
