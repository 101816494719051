import { AspectRatio, Poster } from '@tving/ui';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import { useState } from 'react';
import useLazyImage from '@hooks/useLazyImage';
import useKboAthleteQuery from '@hooks/kbo/queries/useKboAthleteQuery';
import useClientStore from '@stores/useClientStore';
import { shallow } from 'zustand/shallow';

const AthleteMobileWallpaper = () => {
    const { data: kboAthleteResponse } = useKboAthleteQuery();
    const backgroundImage = kboAthleteResponse?.data?.backgroundImage;

    const [imgRef, dataSrc] = useLazyImage({
        src: backgroundImage,
    });

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleLoadImage = () => {
        setIsImageLoaded(true);
    };

    const { isTablet } = useClientStore((state) => ({ isTablet: state.isTablet }), shallow);

    return (
        <AspectRatio ratio={isTablet ? 16 / 9 : 750 / 670} className={tempCnx('absolute top-0 right-0 left-0  w-screen pointer-events-none')}>
            <Poster
                imgRef={imgRef}
                dataSrc={dataSrc}
                className={tempCnx('w-full h-full object-cover', isImageLoaded ? 'visible' : 'invisible')}
                onLoad={handleLoadImage}
                alt=""
            />
            <aside className="absolute bottom-0 left-0 w-full h-[16.666rem] bg-gradient-to-b from-transparent to-[#000000]" />
        </AspectRatio>
    );
};

export default AthleteMobileWallpaper;
