import type { ClassValue } from 'clsx';
import clsx from 'clsx';
// @ts-ignore
import { twMerge } from 'tailwind-merge/es5';

/**
 * tving-ui 에서 cnx 를 활용하기 위해 적용했습니다.
 * TODO: 추후 pc app 내 cnx 제거 후 해당 함수를 사용하도록 변경해야합니다.
 *
 * @param inputs
 */
const tempCnx = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export default tempCnx;
