import * as Sentry from '@sentry/react';
import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';

import { API_URLS } from '../../constants/apiConstants';
import { GATEWAY_URL } from '../../utils/common/apiConfig';
import httpGet from '../../utils/http/httpGet';
import { NewTvingResponse, TtvBufferModeResponse } from '../types';

export interface TtvBufferParameter {
  poc: 'web' | 'tv'; // path parameter
}

/**
 * TTV, BUFFER 정보 요청
 * @param requestParameter: 요청 파라미터
 * @param requestConfig : axios 요청 설정
 * @returns
 */
const fetchTtvBuffer = (requestParameter: TtvBufferParameter, requestConfig: AxiosRequestConfig = {}): Promise<TtvBufferModeResponse | null> => {
  const { poc } = requestParameter;
  const params = { v: dayjs(Date.now(), 'Asia/Seoul').format('YYYYMMDDHHmm') };

  const filledRequestConfig = {
    ...requestConfig,
    params,
    'axios-retry': {
      retryCondition: () => true,
    },
  };

  return httpGet<NewTvingResponse<TtvBufferModeResponse>>(`${GATEWAY_URL}${API_URLS.TTV_BUFFER_MODE.replace('{poc}', poc)}`, filledRequestConfig)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      Sentry.withScope((scope) => {
        scope.setLevel('warning');
        scope.setTag('generate-entry', 'fetch-buffer');
        Sentry.captureException(e);
      });
      return null;
    });
};

export default fetchTtvBuffer;
