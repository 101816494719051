import { useEffect, useRef } from 'react';

export interface OverlayAsyncFallbackResolverProps {
  children: React.ReactNode; // Overlay 내부에 렌더링할 자식 요소
  onResolve: () => void; // 비동기 작업을 해결하는 콜백 함수
}

/**
 * OverlayKit의 openAsync로 생성된 Overlay 컴포넌트가 `Route Change`, `Caller Unmount`으로 파괴되었을 때
 * 올바르게 Promise를 Resolve하기 위한 Wrapper 컴포넌트입니다.
 *
 * @example
 *  const handleOpenModal = async () => {
 *      const result = await overlayKit.openAsync<boolean>(
 *          ({ isOpen, close }) => {
 *               return (
 *                   <OverlayAsyncFallbackResolver onResolve={() => close(false)}>
 *                       <Modal isOpen={isOpen} onClose={() => close(false)} onConfirm={() => close(true)} />
 *                   </OverlayAsyncFallbackResolver>
 *               );
 *           },
 *           {
 *               destroyOnRouteChange: 'pathname',
 *               destroyOnCallerUnmount: true,
 *           },
 *       );
 *
 *       console.log('Overlay Result...', result);
 *   };
 */
const OverlayAsyncFallbackResolver = ({ children, onResolve }: OverlayAsyncFallbackResolverProps) => {
  const isMounted = useRef(false);

  useEffect(
    () => {
      if (!isMounted.current) {
        isMounted.current = true;
        return;
      }

      return () => {
        onResolve?.();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- 항상 Unmount 시점에만 호출되어야 합니다. (Strict Mode 동작 무시)
    [],
  );

  return children as JSX.Element;
};

export default OverlayAsyncFallbackResolver;
