import { KBO_API_URLS } from '@constants/kbo/url';
import { KBO_URL } from '@tving/utils/src/utils/common/apiConfig';
import { KboTeamDetailBandsResponse } from '@typings/kbo';
import httpGet from '@utils/common/httpGet';
import getScreenOsCodeDeviceParams from '@utils/kbo/getScreenOsCodeDeviceParams/getScreenOsCodeDeviceParams';

type DeviceType = {
    isMobileOrWebview: boolean;
    isTablet: boolean;
};
const fetchKboTeam = async ({ teamCode, deviceType }: { teamCode: string; deviceType: DeviceType }) => {
    const result = await httpGet<KboTeamDetailBandsResponse>(KBO_URL + KBO_API_URLS.TEAM({ teamCode }), {
        params: getScreenOsCodeDeviceParams({
            ...deviceType,
        }),
    });
    return result;
};

export default fetchKboTeam;
