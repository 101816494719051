import React from 'react';
import useSendbirdSession from '@hooks/chat/useSendbirdSession';
import { useUserInfo } from '@store/state';
import { useCookies } from 'react-cookie';
import { OverlayKitIntegrationProvider } from '@tving/overlay-kit-nextjs';

type AppInitializationProvider = {
    children: React.ReactNode;
};

const AppInitializationProvider = ({ children }: AppInitializationProvider) => {
    const [{ profileNo: profileNumber }] = useUserInfo();
    const [{ accessToken, _tving_token: tvingToken }] = useCookies<
        'accessToken' | '_tving_token',
        {
            accessToken: string;
            _tving_token: string;
        }
    >();
    /**
     * MEMO:
     * - 초기 한번 호출 되는 서버 쪽 getInitialProps에서 세션을 만들지 않고 클라이언트에서 만드는 이유.
     *   -> 클라이언트에서 user profile 정보를 확인 가능.
     */
    useSendbirdSession({ profileNumber, accessToken, tvingToken });

    return <OverlayKitIntegrationProvider>{children}</OverlayKitIntegrationProvider>;
};

export default AppInitializationProvider;
