import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import ListSlider from '@components/list/ListSlider';
import ListPage from '@components/list/ListPage';
import { useExposureItemList } from '@store/apis';
import { useBandsSize, useUserInfo } from '@store/state';
import CatalogMagic from '@components/list/Skeleton';
import { useViewportSpy } from '@hooks/useViewportSpy';
import { isEmpty } from '@utils/common/Object';
import { useIntersection } from 'react-use';
import { logFbaSearchDetailShown } from '@utils/firebaseAnalytics';

const Listing = ({
    displayTitle,
    bandType,
    listData = {},
    type,
    ratio,
    ratioType,
    isDetailLink,
    initialIdx,
    activeIdx,
    isEditMode,
    isLazy,
    isReturnLink,
    detailLinkRoute,
    scrollbar,
    ratioMap,
    isPan,
    setListSwiper,
    searchKeyword,
    searchUniqueKey,
    funnel,
    onClickListItem,
    listIndex,
    isPayUser,
}) => {
    if (type === 'slider') {
        return (
            <ListSlider
                displayTitle={displayTitle}
                isLazy={isLazy}
                bandType={bandType}
                listData={listData}
                ratio={ratio}
                ratioType={ratioType}
                isDetailLink={isDetailLink}
                initialIdx={initialIdx}
                activeIdx={activeIdx}
                detailLinkRoute={detailLinkRoute}
                scrollbar={scrollbar}
                ratioMap={ratioMap}
                isPan={isPan}
                setListSwiper={setListSwiper}
                searchKeyword={searchKeyword}
                searchUniqueKey={searchUniqueKey}
                funnel={funnel}
                onClickListItem={onClickListItem}
                listIndex={listIndex}
                isPayUser={isPayUser}
            />
        );
    }
    return (
        <ListPage
            displayTitle={displayTitle}
            isLazy={false}
            bandType={bandType}
            listData={listData}
            ratio={ratio}
            ratioType={ratioType}
            isEditMode={isEditMode}
            isReturnLink={isReturnLink}
            searchKeyword={searchKeyword}
            searchUniqueKey={searchUniqueKey}
            funnel={funnel}
            onClickListItem={onClickListItem}
            listIndex={listIndex}
            isPayUser={isPayUser}
        />
    );
};

const ListTitle = ({ children, displayTitle, isTitleHidden, isReturnLink }) => {
    const route = useRouter();
    const {
        query: { keyword, funnel },
        pathname,
    } = useRouter();

    if (isTitleHidden) {
        return null;
    }

    const linkSearchPage = () => {
        route.push({
            pathname: '/search',
            query: { keyword, funnel },
        });
    };

    const isSearchPage = pathname === '/search/[slug]';

    if (displayTitle) {
        return (
            <div className="layout__flex-left">
                <h2
                    className="lists__title"
                    css={
                        isSearchPage && {
                            display: 'flex',
                            whiteSpace: 'nowrap',
                            alignItems: 'center',
                        }
                    }
                >
                    {displayTitle}
                </h2>
                {isReturnLink && (
                    <a
                        className="search__result btn__line btn__line-small border0"
                        onClick={linkSearchPage}
                        css={
                            isSearchPage && {
                                textAlign: 'right',
                                padding: 0,
                            }
                        }
                    >
                        전체검색결과 돌아가기
                    </a>
                )}
            </div>
        );
    }
    return null;
};

export const ratioCheck = (bandType) => {
    let ratioValue = '25x36'; // 기본 세로형
    let ratioType = 'vertical'; // 기본 세로형
    switch (bandType) {
        case 'vodWatch':
        case 'programRank': // 세로 썸네일
            break;
        case 'liveBasic':
        case 'liveRank': // 가로 썸네일
        case 'liveReserve': // My 예약내역
        case 'tvingtvBasic':
        case 'tvingtvRank': // 가로 썸네일
        case 'movieChannel': // 가로 썸네일
        case 'episodeBasic': // 프로그램 회차보기
        case 'episodeWatch': // 프로그램 시청내역
        case 'movieWatch': // 영화 시청내역
            ratioValue = '16x9';
            ratioType = 'horizontal';
            break;
        case 'episodePurchase': // 프로그램 구매내역
            ratioValue = '16x9';
            ratioType = 'horizontal lists__itemtype-row';
            break;
        case 'moviePurchase': // 영화 구매내역
            ratioValue = '16x9';
            ratioType = 'horizontal lists__itemtype-row';
            break;
        case 'quickVod': // 가로 썸네일
            ratioValue = '16x9';
            ratioType = 'horizontal';
            break;
        case 'genre': // 장르
            break;
        case 'event': // 이벤트
            ratioValue = '200x10';
            ratioType = 'horizontal';
            break;
        case 'programTheme': // 방송 테마
            break;
        case 'tvingOrigin': // 티빙 오리지널
            ratioValue = '1x2';
            ratioType = 'original';
            break;
        case 'clip':
            ratioValue = '16x9';
            ratioType = 'horizontal';
            break;
        case 'singleBanner':
            ratioValue = '10x1';
            ratioType = 'single';
            break;
        case 'tvingMall':
            ratioValue = '1x1';
            ratioType = 'vertical';
            break;
        default:
            break;
    }

    return { ratioValue, ratioType };
};

const List = ({
    idx = -1,
    bandType = 'none',
    paramApi = '',
    title = '',
    exposeData = null,
    listData = null,
    type = 'slider',
    ratio = 'thumb__ratio-5*7',
    isDetailLink = false,
    initialIdx = 0,
    activeIdx = -1,
    isEditMode = false,
    isReturnLink = false,
    detailLinkRoute = null,
    scrollbar = false,
    isTitleHidden = false,
    ratioMap = null,
    isPan = false,
    isSearchPage = false,
    isSearchMore = false, // 검색 상세(더보기) 여부
    searchKeyword = null, // 검색결과인 경우 입력된 검색어
    searchUniqueKey = null, // 검색결과인 경우 유니크 키 (동일 검색결과인 경우 같은 키를 가짐)
    bandOrder = null, //  검색결과인 경우 띠 순서
    funnel = '',
    onClickListItem,
    isPayUser,
}) => {
    const isLazyLoad = () => {
        return idx > 3 || idx < -1;
    };
    const ref = useRef();
    const isVisible = useViewportSpy(ref);
    const [userInfo] = useUserInfo();
    const [isView, setIsView] = useState(!isLazyLoad());
    const [displayTitle, setTitle] = useState(title);
    const bandRef = useRef();
    const router = useRouter();
    const { query } = router;
    const [listSwiper, setListSwiper] = useState();

    const intersection = useIntersection(bandRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    useEffect(() => {
        if (!listSwiper) {
            return;
        }

        listSwiper.on('slideChange', (e) => {
            const { activeIndex, params } = e;
            const lastShwonIdx = Math.min(Number(activeIndex + (params?.slidesPerGroup || 0)), Number(listData?.data?.items?.length || 0));

            const mediaCodes = listData?.data?.items?.slice(activeIndex, lastShwonIdx)?.map((item) => item.code) ?? '';
            const impressionSchSr =
                listData?.data?.items
                    ?.slice(activeIndex, lastShwonIdx)
                    ?.map((item) => item.clickSchSr)
                    ?.join('') ?? '';
            const { schModel, schRecModel, schCollection, abtestGroup } = listData?.data?.items[0];

            logFbaSearchDetailShown({
                profileNo: userInfo?.profileNo,
                searchId: searchUniqueKey,
                keyword: searchKeyword,
                funnel,
                isMore: isSearchMore,
                resultCount: listData?.data?.items?.length || 0,
                bandOrder,
                bandName: displayTitle,
                rank: lastShwonIdx,
                mediaCodes,
                schModel,
                schRecModel,
                schCollection,
                abtestGroup,
                impressionSchSr,
            });
        });
    }, [listSwiper]);

    useEffect(() => {
        if (isSearchPage && !query?.slug && intersection?.intersectionRatio > 0) {
            const activeIndex = listSwiper?.activeIndex || 0;
            const params = listSwiper?.params || {};
            // 보여진 밴드의 마지막 아이템 인덱스 계산
            const lastShwonIdx = Math.min(Number(activeIndex + (params?.slidesPerGroup || 0)), Number(listData?.data?.items?.length || 0));

            const mediaCodes = listData?.data?.items?.slice(activeIndex, lastShwonIdx)?.map((item) => item.code) ?? '';
            const impressionSchSr =
                listData?.data?.items
                    ?.slice(activeIndex, lastShwonIdx)
                    ?.map((item) => item.clickSchSr)
                    ?.join('') ?? '';

            const { schModel, schRecModel, schCollection, abtestGroup } = listData?.data?.items[0];

            logFbaSearchDetailShown({
                profileNo: userInfo?.profileNo,
                searchId: searchUniqueKey,
                keyword: searchKeyword,
                funnel,
                isMore: isSearchMore,
                resultCount: listData?.data?.items?.length || 0,
                bandOrder,
                bandName: displayTitle,
                rank: lastShwonIdx,
                mediaCodes,
                schModel,
                schRecModel,
                schCollection,
                abtestGroup,
                impressionSchSr,
            });
        }
    }, [isSearchPage, intersection, displayTitle, query]);

    if (listData) {
    } else {
        if (paramApi == null) {
            return null;
        }
        listData = useExposureItemList({
            pageNo: 1,
            apiUrl: isView ? paramApi : null,
        });
    }

    useEffect(() => {
        if (!isView) {
            return;
        }

        const { data, mutate } = listData || {};
        if (!isEmpty(data) && (bandType.includes('vodWatch') || paramApi.startsWith('/v2/community/fan/contents'))) {
            mutate();
        }
    }, [isView]);

    useEffect(() => {
        if (isVisible && !isView) {
            setIsView(true);
        }
    }, [isVisible]);

    const [bandsSize, setBandsSize] = useBandsSize();

    useEffect(() => {
        const { data, isLoading } = listData;

        if (isLoading) {
            return;
        }

        // Title 입력이 전달되지않고, json.info에 title이 존재하면
        if (title === '' && data?.body?.info?.title) {
            setTitle(data?.body?.info?.title);
        }

        const size = data.bands?.length || 1;
        setBandsSize((prev) => ({
            ...prev,
            ...{
                [paramApi]: size,
            },
        }));
    }, [listData.data]);

    // bandType으로 ratioType 가져오기
    const { ratioValue, ratioType } = ratioCheck(bandType);
    if (bandType === 'singleBanner') {
        isTitleHidden = true;
    }
    // TODO: 기획 협의 후 하드코딩 제거 필요
    if (bandType === 'genre') {
        isDetailLink = false;
    }
    // TODO: 기획 협의 후 하드코딩 제거 필요
    // 추천 띠 (~님이 좋아할만한 영화/프로그램인 경우 전체보기 미표시)
    if (paramApi.includes('/media/recommend?uri=/recommend')) {
        isDetailLink = false;
    }

    // lazy
    if (!isView || listData.isLoading) {
        return (
            <section ref={ref}>
                {[...Array(bandsSize[paramApi] || 1)].map((_, _idx) => {
                    return (
                        <section key={_idx} className="lists">
                            <CatalogMagic
                                type={type}
                                ratioValue={ratioValue}
                                ratioType={ratioType}
                                heading={{ width: 140, height: type === 'slider' ? 24 : 0 }}
                            />
                        </section>
                    );
                })}
            </section>
        );
    }

    let listArr = [];
    if (Array.isArray(listData?.data?.bands)) {
        listArr = [...listData?.data?.bands];
    } else if (Array.isArray(listData?.data)) {
        listArr = [listData?.data];
    } else {
        listArr = [listData?.data];
    }

    const lazyClassName = () => {
        let name = 'lists';
        if (!isLazyLoad()) {
            name += ' lists__noneLazy';
        }
        return name;
    };

    return (
        <>
            {listArr.map((band, _idx) => {
                if (Array.isArray(band) || (band.items && band.items?.length > 0)) {
                    const objectListData = {
                        data: band,
                        exposeData,
                    };
                    const listTitle = (band.title || displayTitle).replaceAll('{{name}}', userInfo?.profileName || '');
                    return (
                        <section className={lazyClassName()} key={`list-section-${_idx}`} ref={bandRef}>
                            <Suspense>
                                <ListTitle displayTitle={listTitle} isTitleHidden={isTitleHidden} isReturnLink={isReturnLink} />
                                <Listing
                                    displayTitle={listTitle}
                                    bandType={bandType}
                                    listData={objectListData}
                                    ratio={ratioValue}
                                    ratioType={ratioType}
                                    isDetailLink={isDetailLink}
                                    initialIdx={initialIdx}
                                    activeIdx={activeIdx}
                                    isEditMode={isEditMode}
                                    type={type}
                                    isLazy={isLazyLoad()}
                                    detailLinkRoute={detailLinkRoute}
                                    scrollbar={scrollbar}
                                    ratioMap={ratioMap}
                                    isPan={isPan}
                                    searchKeyword={searchKeyword}
                                    searchUniqueKey={searchUniqueKey}
                                    setListSwiper={setListSwiper}
                                    funnel={funnel}
                                    onClickListItem={onClickListItem}
                                    listIndex={_idx}
                                    isPayUser={isPayUser}
                                />
                            </Suspense>
                        </section>
                    );
                }
                return null;
            })}
        </>
    );
};

export default List;
