import { useQuery } from '@tanstack/react-query';
import { KBO_QUERY_KEYS } from '@hooks/kbo/queries/index';
import fetchKboAthlete from '@apis/kbo/bff/fetchKboAthlete';
import { useRouter } from 'next/router';

const useKboAthleteQuery = () => {
    const {
        query: { athleteCode },
    } = useRouter() as {
        query: { athleteCode?: string };
    };

    return useQuery(KBO_QUERY_KEYS.ATHLETE(athleteCode ?? ''), () => fetchKboAthlete(athleteCode ?? ''), {
        enabled: !!athleteCode,
    });
};

export default useKboAthleteQuery;
