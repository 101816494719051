import Poster, { PosterProps } from '../../atom/poster/Poster';
import ProgressBar from '../../atom/progressBar/ProgressBar';
import Text, { TextProps } from '../../atom/text/Text';
import Labels, { LabelsProps } from '../labels/Labels';

type onAirItemProps = {
  className?: string;
  isCountDown: boolean;
  isLive: boolean;
  poster: PosterProps;
  infoClassName?: string;
  posterText: TextProps<React.ElementType>;
  progressBar: {
    className?: string;
    percentClassName?: string;
    percent: number | null;
  };
  labels: LabelsProps;
  texts: {
    liveIntro: {
      message: string;
      className: string;
    };
    currentTime: {
      message?: string;
      className: string;
      time: string;
    };
    broadcastStartTime: {
      message?: string;
      className: string;
      time: string;
    };
    info: {
      className?: string;
      items: { className: string; title: string }[];
    };
  };
  tabIndex?: number;
};

const OnAirItem = ({ className, isCountDown, isLive, infoClassName, posterText, poster, labels, progressBar, tabIndex, texts }: onAirItemProps) => {
  const { liveIntro, currentTime, broadcastStartTime, info } = texts;

  return (
    <>
      <div className={`onAir-item-wrapper ${className}`} tabIndex={tabIndex}>
        {posterText && <Text {...posterText} />}
        <Poster {...poster} />
        <Labels {...labels} />
        <ProgressBar {...progressBar} />
        <div className={infoClassName}>
          {isCountDown && (
            <div>
              <Text title={liveIntro.message} className={liveIntro.className} />
              <Text title={currentTime.time} className={`text-white ${currentTime.className}`} />
            </div>
          )}
          {!isCountDown && !isLive && <Text title={broadcastStartTime.time} className={`text-white ${broadcastStartTime.className}`} />}
        </div>
      </div>
      <div className={info.className}>
        {info.items.map((text) => (
          <Text key={`text_${text.title}`} {...text} />
        ))}
      </div>
    </>
  );
};

export default OnAirItem;
