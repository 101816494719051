import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { isMobile } from '@utils/device/device-detect';
import TagManager from 'react-gtm-module';
// import { initBraze } from '@utils/braze';

function RouteGuard({ children }) {
    const router = useRouter();
    const [authorized, setAuthorized] = useState(true);

    useEffect(() => {
        TagManager?.initialize({
            gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
        });
    }, []);

    // 페이스북
    useEffect(() => {
        const script = document.createElement('script');
        script.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '145395637048985');fbq('track', 'PageView');
        fbq('init', '1721101734862788');fbq('track', 'PageView');
        `;

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // 카카오 모먼트
    useEffect(() => {
        const script = document.createElement('script');
        script.innerText = `typeof kakaoPixel === 'function' && kakaoPixel('8263807987167545614').pageView()`;

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // 구글
    useEffect(() => {
        const script = document.createElement('script');
        script.innerText = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-761912692');`;

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // 트위터 Twitter universal website tag code
    useEffect(() => {
        const script = document.createElement('script');
        script.innerText = `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){
        s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
        },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
        a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}
        (window,document,'script');
        twq('config','o7riq');
        `;

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // 네이버 광고 성과 트래킹 스크립트
    useEffect(() => {
        const script = document.createElement('script');
        script.innerText = `if (!wcs_add) var wcs_add={};wcs_add["wa"] = "s_1b6ae80a204f";
        if (!_nasa) var _nasa={};if(window.wcs){wcs.inflow("tving.com");wcs_do(_nasa);}`;

        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // 에이스 트레이더 매체 태그 설치
    useEffect(() => {
        const script = document.createElement('script');
        script.innerText = `window.ne_tgm_q = window.ne_tgm_q || [];
        window.ne_tgm_q.push(
        {
            tagType: 'visit',
            device:'${isMobile ? 'mobile' : 'web'}',
            uniqValue:'',
            pageEncoding:'utf-8'
        });`;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        // on initial load - run auth check
        routeChangeComplete({ url: router.asPath });

        // on route change start - hide page content by setting authorized to false
        const hideContent = () => setAuthorized(true);
        router.events.on('routeChangeStart', hideContent);

        // on route change complete - run auth check
        router.events.on('routeChangeComplete', routeChangeComplete);

        // unsubscribe from events in useEffect return function
        return () => {
            router.events.off('routeChangeStart', hideContent);
            router.events.off('routeChangeComplete', routeChangeComplete);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const routeChangeComplete = ({ url = '' }) => {
        authCheck(url);
    };

    function authCheck(url) {
        console.log('#auth', url);
        // if (url === '/test-motion/') {
        //     router.push({
        //         pathname: '/',
        //     });
        //     setAuthorized(false);
        // } else {
        //     setAuthorized(true);
        // }

        // redirect to login page if accessing a private page and not logged in
        // const publicPaths = ['/login'];
        // const path = url.split('?')[0];
        // if (!userService.userValue && !publicPaths.includes(path)) {
        //     setAuthorized(false);
        //     router.push({
        //         pathname: '/login',
        //         query: { returnUrl: router.asPath }
        //     });
        // } else {
        //     setAuthorized(true);
        // }
    }

    return authorized && children;
}

export { RouteGuard };
