import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';

interface KboMyTeamSettingFooterProps {
    handleClickSave: () => void;
    footerClass: string;
    footerButtonClass: string;
    isMobile: boolean;
}

const KboMyTeamSettingFooter = ({ handleClickSave, footerClass, footerButtonClass, isMobile }: KboMyTeamSettingFooterProps) => {
    const background = isMobile
        ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 78.95%)'
        : 'linear-gradient(180deg, rgba(25, 25, 25, 0.00) 0%, #191919 75%)';

    return (
        <div
            className={tempCnx(footerClass)}
            style={{
                background,
            }}
        >
            <button type="button" onClick={handleClickSave} className={tempCnx(footerButtonClass)}>
                저장
            </button>
        </div>
    );
};
export default KboMyTeamSettingFooter;
