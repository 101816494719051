import React from 'react';
import { AdminMessage } from '@sendbird/chat/message';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import TvingLogo from '@img/chat/logo_tving_white.svg';
import dayjs from 'dayjs';

type Props = {
    message: AdminMessage;
};

const AdminMessageCell = ({ message }: Props) => {
    console.log('#tving-talk - AdminMessageCell', { message, data: message.data });
    const data: { time?: string } = JSON.parse(message?.data?.includes('time') ? message?.data.slice(1, -1) : '{}');

    const displayTime = data?.time ?? dayjs(message.createdAt).format('HH:mm');

    return (
        <div className={tempCnx('p-[0.333rem_0.667rem]')}>
            <div className={tempCnx('p-[1.167rem_1.333rem_1.333rem] rounded-[0.333rem] bg-[#262626]')}>
                <div className={tempCnx('flex justify-between items-center')}>
                    <TvingLogo className={tempCnx('w-[4rem] min-w-[4rem] h-[0.98825rem] min-h-[0.98825rem]')} />
                    <span className={tempCnx('text-[#808080]')}>{displayTime}</span>
                </div>
                <p className={tempCnx('mt-[0.667rem] text-[1.167rem] whitespace-pre-wrap break-all')}>{message.message}</p>
            </div>
        </div>
    );
};

export default AdminMessageCell;
