import useKboMyTeamsQuery from '@hooks/kbo/queries/useKboMyTeamsQuery';
import { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import ModalComp from '@components/Modal';

import { shallow } from 'zustand/shallow';
import { KboMyTeamItem } from '@typings/kbo/myTeam';
import fetchKboMyTeamPut from '@apis/kbo/bff/fetchKboMyTeamPut';
import useNativeStore from '@stores/useNativeStore';
import { KBO_QUERY_KEYS } from '@hooks/kbo/queries';
import QUERY_KEY from '@tving/utils/src/constants/queryKeys/queryKeyConstants';
import { useQueryClient } from '@tanstack/react-query';
import { KboNavigationContext } from '@contexts/kbo/navigation/KboNavigationContext';
import amplitudeEvent from '@tving/utils/src/utils/amplitude/amplitudeEvent';
import { SPORTS_QUERY_KEYS } from '@hooks/sports/queries';

export type KboMyTeamSettingResponse = {
    kboMyTeams: KboMyTeamItem[];
    selectedKboMyTeam: KboMyTeamItem; // 현재 설정어 있는 팀
    pickedTeam: KboMyTeamItem; // 새롭게 설정 될 팀
    setPickedTeam: Dispatch<SetStateAction<KboMyTeamItem>>;
    handleClickSave: () => void;
};

interface UseKboMySteamSettingProps {
    onClose: () => void;
    onOpenSettingAlert?: () => void;
    isAgreeTerms?: boolean;
}

/**
 * 마이팀 설정 메인 화면 관리 hook
 * @param onClose
 * @param onOpenSettingAlert
 */
const useKboMyTeamSetting = ({ onClose, onOpenSettingAlert, isAgreeTerms }: UseKboMySteamSettingProps): KboMyTeamSettingResponse => {
    const { data } = useKboMyTeamsQuery();
    const queryClient = useQueryClient();

    const { isReceiveEvent, isGranted, isReceiveNight } = useNativeStore(
        (state) => ({
            isReceiveEvent: state.isReceiveEvent,
            isGranted: state.isGranted,
            setIsGranted: state.setIsGranted,
            setIsReceiveEvent: state.setIsReceiveEvent,
            isReceiveNight: state.isReceiveNight,
            setIsReceiveNight: state.setIsReceiveNight,
            pushToken: state.pushToken,
            setPushToken: state.setPushToken,
        }),
        shallow,
    );

    const { kboMyTeams, selectedKboMyTeam } = data || {};
    const [pickedTeam, setPickedTeam] = useState(selectedKboMyTeam);

    const { device } = useContext(KboNavigationContext);

    const { isMobileWebview: isMobile } = device;

    /**
     * 설정 된 마이팀 정보를 서버로 전달
     */
    const sendKboMyTeamToServer = async (code, gamePush, highlightPush) => {
        await fetchKboMyTeamPut({
            teamCode: code,
            gamePush: gamePush ? 'Y' : 'N',
            highlightPush: highlightPush ? 'Y' : 'N',
        })
            .then(async () => {
                await queryClient.invalidateQueries(KBO_QUERY_KEYS.MY_TEAMS());
                await queryClient.invalidateQueries(QUERY_KEY.TERMS_AGREEMENT.all);
                queryClient.invalidateQueries({
                    queryKey: ['kbo', 'bff'],
                    refetchType: 'active',
                });
                queryClient.invalidateQueries({
                    queryKey: SPORTS_QUERY_KEYS.ALL,
                    refetchType: 'active',
                });
            })
            .catch(async (e) => {
                await queryClient.invalidateQueries(KBO_QUERY_KEYS.MY_TEAMS());
                await queryClient.invalidateQueries(QUERY_KEY.TERMS_AGREEMENT.all);
                queryClient.invalidateQueries({
                    queryKey: ['kbo', 'bff'],
                    refetchType: 'active',
                });
                queryClient.invalidateQueries({
                    queryKey: SPORTS_QUERY_KEYS.ALL,
                    refetchType: 'active',
                });
                throw e;
            });
    };
    /**
     * 별도의 알림 설정 없이 팀 선택으로 마이팀 설정이 완료 되는 케이스
     * ex) pc에서 마이팀 설정 하는 경우, 모바일 기기에서 별도 알림 없이 저장 하는 경우
     */
    const doPcMyTeamSetting = async () => {
        // 이미 설정된 팀을 저장하려 하는 경우 별도 API 호출 없이 저장 처리한다.
        const { code, isMyTeam } = pickedTeam;
        if (!isMyTeam || code !== selectedKboMyTeam.code) {
            // PC에서 저장하는 경우 모든 알림 'N' 상태로 저장
            await sendKboMyTeamToServer(code, false, false);
        }

        ModalComp.dialog({
            title: '마이팀 설정이 완료되었어요.',
            text: '마이팀 경기 및 하이라이트 알림은\n모바일 앱을 통해 설정해주세요.',
            showCancelButton: false,
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                onClose();
            }
        });
    };

    /**
     * 모바일에서 알림 설정 없이 저장 하는 경우 별도 기기 알림 처리 프로세스 호출 x
     */
    const doSimpleMobileMyTeamSetting = async () => {
        const { code, doMatchPush, doHighlightPush } = pickedTeam;
        await sendKboMyTeamToServer(code, doMatchPush, doHighlightPush);

        ModalComp.dialog({
            title: '마이팀 설정이 완료되었어요.',
            showCancelButton: false,
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                onClose();
            }
        });
    };

    const doDismissedMyTeamSetting = () => {
        ModalComp.dialog({
            title: '마이팀이 선택되지 않았어요.',
            text: '마이팀을 선택하지 않으시겠습니까?',
            confirmButtonText: '확인',
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                // 마이팀 설정 해제
                await sendKboMyTeamToServer('', false, false);
                onClose();
            }
        });
    };

    // 저장 버튼 처리 로직
    const handleClickSave = useCallback(() => {
        amplitudeEvent({
            event: 'click_kbo_myteam_save_app',
            eventProperties: {
                screen_name: 'myteam_select',
                myteam: pickedTeam?.code || '',
                game_alarm: pickedTeam?.doMatchPush ? pickedTeam?.code : '',
                highlight_alarm: pickedTeam?.doHighlightPush ? pickedTeam?.code : '',
                before_myteam: selectedKboMyTeam?.code || '',
            },
        });

        if (pickedTeam?.code) {
            // 선택된 마이팀이 있는 경우,
            if (isMobile) {
                // 모바일 환경 로직 처리
                // 선택 한 알림 설정이 없는 경우 (경기알림 or 하이라이트 알림
                // 팝업 호출
                if (!pickedTeam.doMatchPush && !pickedTeam.doHighlightPush) {
                    void doSimpleMobileMyTeamSetting();
                } else if (pickedTeam.doMatchPush || pickedTeam.doHighlightPush) {
                    /**
                     * Y/N, YYY NNN 에 따라서 로직이 달라진다.
                     */
                    if (isAgreeTerms) {
                        // 약관 동의 'Y'
                        if ((isGranted && isReceiveEvent && isReceiveNight) || (!pickedTeam.doHighlightPush && isGranted && isReceiveEvent)) {
                            /**
                             *  모든 알림이 ON 상태 or 하이라이트 알림 OFF, 기기알림, 이벤트 알림이 ON 이라면 라면 마이팀 설정 완료 후 종료
                             */
                            void doSimpleMobileMyTeamSetting();
                        } else {
                            // 그 외 모든 경우는 동의 팝업 진입 하여 이후 프로세스 진행
                            void sendKboMyTeamToServer(pickedTeam.code, pickedTeam.doMatchPush, pickedTeam.doHighlightPush);
                            if (onOpenSettingAlert) {
                                onOpenSettingAlert();
                            }
                        }
                    } else if (onOpenSettingAlert) {
                        void sendKboMyTeamToServer(pickedTeam.code, pickedTeam.doMatchPush, pickedTeam.doHighlightPush);
                        onOpenSettingAlert();
                    }
                } else {
                    // 설정 된 알림이 없는 경우 바로 설정 완료 팝업 노출
                    void doSimpleMobileMyTeamSetting();
                }
            } else {
                // PC 환경에서는 별도 기기 알림 설정 없이 저장
                void doPcMyTeamSetting();
            }
        } else {
            // 선택 된 마이팀이 없는 경우
            // 마이팀 해제 처리
            doDismissedMyTeamSetting();
        }
    }, [
        pickedTeam?.code,
        pickedTeam?.doMatchPush,
        pickedTeam?.doHighlightPush,
        isMobile,
        doSimpleMobileMyTeamSetting,
        isAgreeTerms,
        onOpenSettingAlert,
        isGranted,
        isReceiveEvent,
        isReceiveNight,
        sendKboMyTeamToServer,
        doPcMyTeamSetting,
        doDismissedMyTeamSetting,
    ]);

    return {
        kboMyTeams,
        selectedKboMyTeam,
        pickedTeam,
        setPickedTeam,
        handleClickSave,
    } as KboMyTeamSettingResponse;
};
export default useKboMyTeamSetting;
