import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const getCookie = (name, options = {}) => {
    return cookies.get(name, options);
};

export const getAllCookies = (options = {}) => {
    return cookies.getAll(options);
};

export const setCookie = (name, value, options = {}) => {
    return cookies.set(name, value, { ...options });
};

export const removeCookie = (name, options = {}) => {
    cookies.remove(name, { ...options });
};
