import { useContext } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { KBO_HOME_TAB_DATA } from '@constants/kbo/tab';
import { KboNavigationContext } from '@contexts/kbo/navigation/KboNavigationContext';
import cnx from '@utils/tailwind/cnx';

const KboHomeTab = () => {
    const router = useRouter();
    const { route } = router;
    const { device } = useContext(KboNavigationContext);

    const { isMobileWeb, isMobileWebview } = device || {};
    return (
        <nav
            className={cnx(
                'kbo-home-tab',
                isMobileWeb
                    ? 'flex justify-center items-center z-[200] top-0 w-full h-16 bg-transparent'
                    : 'flex-center absolute top-[3.667rem] left-[13.275rem] w-auto h-20 ',
                isMobileWeb ? (isMobileWebview ? 'mt-0' : '') : '',
            )}
        >
            <ul className="flex-center pw-[1.333rem] overflow-x-hidden text-white text-2xl">
                {Object.values(KBO_HOME_TAB_DATA).map(({ key, path, text, isHighlighted }) => (
                    <li key={key} className="h-16">
                        <Link scroll href={path}>
                            <span
                                className={cnx(
                                    'flex justify-center items-center h-full ',
                                    'text-[1.333rem] leading-normal',
                                    isHighlighted(route)
                                        ? 'text-white font-bold'
                                        : 'text-white/60 hover-supported:hover:text-white active:text-white',
                                    isMobileWeb ? 'px-4 pb-[0.333rem]' : 'px-5 pb-0',
                                    'cursor-pointer',
                                )}
                            >
                                {text}
                            </span>
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default KboHomeTab;
