export const PLAYWRIGHT_ATTRIBUTE_NAMES = {
  TEST_ID: 'data-testid',
  APP_READY: 'data-app-ready',
} as const;

export const PLAYWRIGHT_ATTRIBUTE_VALUES = {
  TEST_ID: {
    ROOT: 'tving-playwright-root',
  },
  APP_READY: {
    COMPLETE: 'complete',
    PENDING: 'pending',
  },
} as const;

export const PLAYWRIGHT_SELECTORS = {
  ROOT: `[${PLAYWRIGHT_ATTRIBUTE_NAMES.TEST_ID}="${PLAYWRIGHT_ATTRIBUTE_VALUES.TEST_ID.ROOT}"]`,
  APP_READY:
    `[${PLAYWRIGHT_ATTRIBUTE_NAMES.TEST_ID}="${PLAYWRIGHT_ATTRIBUTE_VALUES.TEST_ID.ROOT}"]` +
    `[${PLAYWRIGHT_ATTRIBUTE_NAMES.APP_READY}="${PLAYWRIGHT_ATTRIBUTE_VALUES.APP_READY.COMPLETE}"]`,
} as const;
