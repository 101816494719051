import React, { memo, MouseEventHandler, SVGProps, TouchEventHandler, useCallback, useEffect, useState } from 'react';
import { UserMessage } from '@sendbird/chat/message';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import { useChatConfig } from '@tving/utils/src/contexts/ChatContext';
import { overlayKit } from '@tving/overlay-kit-nextjs';
import BottomSheet from '@components/common/popupLayer/bottomSheet';
import IconSiren from '@img/chat/icon_siren.svg';
import IconTrash from '@img/chat/icon_trash.svg';
import {
    CrossPlatformChangePlayerModeOptions,
    DeleteMyMessageHandler,
    ReportMessageHandler,
} from '@tving/utils/src/hooks/common/chat/useChannelConnectionWithSendbird';
import { useLocalStorage } from 'react-use';
import dayjs, { Dayjs } from 'dayjs';
import { isWebview } from '@utils/device/device-detect';
import ModalComp from '@components/Modal';
import useCrossPlatform from '@utils/crossPlatform/react/useCrossPlatform';
import { CrossPlatformConfirmBottomSheetEvent, CrossPlatformConfirmDialogEvent } from '@utils/crossPlatform';
import supportAppWidthSendbird from '../../../supports/supportAppWithSendbird';
// import { CHAT_CUSTOM_CLASSES } from '@components/chat/ChatItem';

type Props = {
    message: UserMessage;
    deleteMyMessage: DeleteMyMessageHandler;
    reportMessage: ReportMessageHandler;
};

const UserMessageCellContainer = ({ children, onClick }: { children: React.ReactNode; onClick: MouseEventHandler }) => (
    <div
        className={tempCnx('relative py-[0.583rem] px-[1.333rem] text-[1.167rem] group/item', isWebview ? '' : 'hover:bg-[#262626]')}
        // onClick={onClick}
        onClick={onClick}
    >
        {children}
    </div>
);

const UserMessageCellText = ({ message, isCurrentUser, className }: { message: UserMessage; isCurrentUser: boolean; className?: string }) => {
    return (
        <>
            <span className={tempCnx('font-bold text-[#808080]', 'leading-normal]', isCurrentUser ? 'text-[#077CAA]' : '', className)}>
                {message.sender?.nickname}
            </span>
            <span className={tempCnx('ml-[0.667rem] text-[#e6e6e6]', 'leading-normal break-all')}>{message.message}</span>
        </>
    );
};

const IconTextButton = ({ icon, children, onClick }: { icon: any; children: React.ReactNode; onClick: () => Promise<void> }) => {
    return (
        <button type="button" className={tempCnx('flex items-center py-4 pr-[0.42rem] pl-[1.667rem]')} onClick={onClick}>
            {icon ? icon() : null}
            <span className={tempCnx('pl-[0.667rem] text-[1.333rem] font-bold leading-normal')}>{children}</span>
        </button>
    );
};

const UserMessageCell = ({ message, deleteMyMessage, reportMessage }: Props) => {
    const { user, reportedMessages: reportedMessageInApp, isInputFocus, isInputFocusDelay, isTextareaFocus } = useChatConfig();
    const [reportedMessages, setReportedMessages] = useLocalStorage(`_TVING_TALK_REPORTED_MESSAGE_${user?.userId}`, {});
    const crossPlatformController = useCrossPlatform();
    const [touchStartTimestamp, setTouchStartTimestamp] = useState<Dayjs | null>(null);

    const isCurrentUser = message.sender?.userId === user?.userId;
    const flag = `${isCurrentUser ? 'delete' : 'report'}_usermessage_${message.messageId}`;

    const handleDeleteMessage = () => {
        ModalComp.dialog({
            title: '삭제하시겠습니까?',
            confirmButton: '확인',
            cancelButton: '취소',
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await deleteMyMessage({ message });
            }
        });
    };

    const handleReportMessage = () => {
        ModalComp.dialog({
            title: '해당 채팅 내용을\n신고하시겠습니까?',
            confirmButton: '확인',
            cancelButton: '취소',
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                await reportMessage(message);
                setReportedMessages({ ...reportedMessages, [message.messageId]: dayjs().toISOString() });

                ModalComp.dialog({
                    title: '신고 완료 및\n블라인드 처리되었습니다.',
                    confirmButton: '확인',
                    showCancelButton: false,
                });
            }
        });
    };

    const handleUserMessageTouchStart: TouchEventHandler = () => {
        console.log('#tivng-talk user message touch start', { isInputFocus, isInputFocusDelay });
        if (isInputFocus || isInputFocusDelay) {
            setTouchStartTimestamp(null);
            return;
        }

        setTouchStartTimestamp(dayjs());
    };

    const handleUserMessageClick = () => {
        if (isWebview) {
            if (supportAppWidthSendbird()) {
                return crossPlatformController.showBottomSheet({
                    id: flag,
                    type: `tvingtalkMessage`,
                    isMyMessage: isCurrentUser,
                    nickname: message.sender?.nickname,
                    message: message.message,
                });
            }

            if (!supportAppWidthSendbird()) {
                return overlayKit.open(({ isOpen, close }) => {
                    return (
                        <BottomSheet
                            isOpen={isOpen}
                            onClose={close}
                            headerContent={
                                <div className={tempCnx('flex flex-col items-center pt-4 text-[1.333rem]')}>
                                    <button
                                        type="button"
                                        className={tempCnx('w-[3.333rem] h-[0.333rem] rounded-[1.667rem] bg-[#666] ')}
                                        onClick={close}
                                    />
                                    <div className={tempCnx('w-full p-[1.333rem_1.667rem_1rem]')}>
                                        <UserMessageCellText message={message} isCurrentUser={isCurrentUser} />
                                    </div>
                                </div>
                            }
                            bodyContent={
                                <div className={tempCnx('flex flex-col w-full py-[0.333rem] border-[#404040] border-solid border-0 border-t')}>
                                    {isCurrentUser ? (
                                        <IconTextButton
                                            icon={IconTrash}
                                            onClick={async () => {
                                                crossPlatformController.showDialog(
                                                    {
                                                        id: flag,
                                                        message: '삭제하시겠습니까?',
                                                        confirmButton: '확인',
                                                        cancelButton: '취소',
                                                    },
                                                    async (isConfirmed) => {
                                                        if (isConfirmed) {
                                                            await deleteMyMessage({ message });
                                                        }
                                                    },
                                                );
                                                close();
                                            }}
                                        >
                                            삭제
                                        </IconTextButton>
                                    ) : (
                                        <IconTextButton
                                            icon={IconSiren}
                                            onClick={async () => {
                                                crossPlatformController.showDialog(
                                                    {
                                                        id: flag,
                                                        message: '해당 채팅 내용을\n신고하시겠습니까?',
                                                        confirmButton: '확인',
                                                        cancelButton: '취소',
                                                    },
                                                    async (isConfirmed) => {
                                                        if (isConfirmed) {
                                                            await reportMessage(message);
                                                            crossPlatformController.moderateTvingTalk({
                                                                action: 'reportMessage',
                                                                id: message.messageId,
                                                            });
                                                            setReportedMessages({ ...reportedMessages, [message.messageId]: dayjs().toISOString() });
                                                            crossPlatformController.showDialog({
                                                                id: `${flag}_result`,
                                                                message: `신고 완료 및\n블라인드 처리되었습니다.`,
                                                                confirmButton: '확인',
                                                                cancelButton: '',
                                                            });
                                                        }
                                                    },
                                                );
                                                close();
                                            }}
                                        >
                                            신고
                                        </IconTextButton>
                                    )}
                                </div>
                            }
                        />
                    );
                });
            }
        }
    };

    const handleIconClick = () => {
        isCurrentUser ? handleDeleteMessage() : handleReportMessage();
    };

    useEffect(() => {
        const onConfirmBottomSheetHandler = (event: CustomEvent<CrossPlatformConfirmBottomSheetEvent>) => {
            const { id, action } = event.detail;
            if (id !== flag) {
                return;
            }

            crossPlatformController.showDialog({
                id,
                message: `${isCurrentUser ? '삭제하시겠습니까?' : '해당 채팅 내용을\n신고하시겠습니까?'}`,
                confirmButton: '확인',
                cancelButton: '취소',
            });
        };

        window.addEventListener('onConfirmBottomSheet', onConfirmBottomSheetHandler);

        return () => {
            window.removeEventListener('onConfirmBottomSheet', onConfirmBottomSheetHandler);
        };
    }, []);

    useEffect(() => {
        const onConfirmDialogHandler = async (event: CustomEvent<CrossPlatformConfirmDialogEvent>) => {
            const { id } = event.detail;

            if (id !== flag) {
                return;
            }

            if (isCurrentUser) {
                await deleteMyMessage({ message });
            } else {
                await reportMessage(message);
                crossPlatformController.moderateTvingTalk({
                    action: 'reportMessage',
                    id: message.messageId,
                });
                setReportedMessages({ ...reportedMessages, [message.messageId]: dayjs().toISOString() });
                crossPlatformController.getTvingTalkModeration();
                crossPlatformController.showDialog({
                    id: `${id}_result`,
                    message: `신고 완료 및\n블라인드 처리되었습니다.`,
                    confirmButton: '확인',
                    cancelButton: '',
                });
            }
        };

        window.addEventListener('onConfirmDialog', onConfirmDialogHandler);

        return () => {
            window.removeEventListener('onConfirmDialog', onConfirmDialogHandler);
        };
    }, []);

    const isReportedMessage =
        isWebview && supportAppWidthSendbird()
            ? reportedMessageInApp?.[message.messageId]
            : reportedMessages?.[message.messageId] && dayjs().isBefore(dayjs(reportedMessages?.[message.messageId]).add(1, 'day'), 'day');

    if (isReportedMessage) {
        return null;
    }

    return (
        <UserMessageCellContainer onClick={handleUserMessageClick}>
            <UserMessageCellText message={message} isCurrentUser={isCurrentUser} className={tempCnx('text-[1.167rem]')} />
            <button
                type="button"
                className={tempCnx(
                    'flex-center absolute top-0 right-0 w-12 h-full invisible bg-[#262626]',
                    isWebview ? null : 'group-hover/item:visible',
                )}
            >
                {isCurrentUser ? (
                    <IconTrash onClick={handleIconClick} width="1.667rem" height="1.667rem" />
                ) : (
                    <IconSiren onClick={handleIconClick} width="1.667rem" height="1.667rem" />
                )}
            </button>
        </UserMessageCellContainer>
    );
};

export default memo(UserMessageCell);
