import React, { useEffect, useRef } from 'react';
import lottie, { AnimationConfig, AnimationConfigWithData } from 'lottie-web';

type LottieContainerProps = {
    data: any;
    className: string;
};

const LottieContainer = ({ data, className }: LottieContainerProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const lottieOptions: AnimationConfigWithData<'svg'> = {
            container: containerRef.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: data,
        };

        const animation = lottie.loadAnimation(lottieOptions);

        return () => animation.destroy();
    }, [data]);

    return <div className={className} ref={containerRef} />;
};

export default LottieContainer;
