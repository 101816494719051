import { Dispatch, SetStateAction, useContext, useRef } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import useCrossPlatform from '@utils/crossPlatform/react/useCrossPlatform';

import useViewportOrientation from '@tving/utils/src/hooks/common/useViewportOrientation';

import { MoveToLoginPage } from '@components/content/CommonComponent';
import ErrorBoundaryWrapper from '@components/kbo/common/ErrorBoundaryWrapper';
import { KboLogo } from '@components/kbo/common/svg';
import KboMyTeamIcon from '@components/kbo/myteam/KboMyTeamIcon';
import Modal from '@components/Modal';
import SnsShareButton from '@components/snsShareButton/SnsShareButton';
import SportsMainHeaderSearchMenu from '@components/sports/search/mainHeaderSearchMenu/SportsMainHeaderSearchMenu';
import { GET_DYNAMIC_LINK_URL } from '@constants/urls';
import { KboNavigationContext } from '@contexts/kbo/navigation/KboNavigationContext';
import { isMobile } from '@utils/device/device-detect';
import cnx from '@utils/tailwind/cnx';

interface KboHeaderProps {
    setIsOnMyTeamModal: Dispatch<SetStateAction<boolean>>;
    isLogin: boolean;
}

const KboHeader = ({ setIsOnMyTeamModal, isLogin }: KboHeaderProps) => {
    const router = useRouter();
    const crossPlatformController = useCrossPlatform();
    const { device } = useContext(KboNavigationContext);

    const { pathname, query } = router;

    const { isMobileWebview: isWebview, isMobileWeb } = device;

    const viewportOrientation = useViewportOrientation();

    const isLiveContentPage = pathname.startsWith('/kbo/contents');
    const isAthletePage = pathname.startsWith('/kbo/athlete');
    const isRoasterPage = pathname.startsWith('/kbo/roaster');
    const isTeamDetailPage = pathname.startsWith('/kbo/team');
    const isClipContentPage = typeof query?.gameCode === 'string' ? query.gameCode.startsWith('SB') : false;
    const isVisibleMyTeamIcon = !(isLiveContentPage && !isClipContentPage) && !isTeamDetailPage;
    let isSnsShareButtonVisible =
        (!isMobileWeb || isWebview) &&
        !(isLiveContentPage && !isClipContentPage) &&
        //  TODO: APP DYNAMIC LINK 처리 이후 해제 필요
        !isAthletePage &&
        !isRoasterPage &&
        !isTeamDetailPage;
    // 클립 상세는 orientation='landscape'인 경우 네비게이션에서 SNS 공유 버튼을 노출하며, 'portrait'은 타이틀 하단에 노출합니다.
    if (isLiveContentPage && isClipContentPage && viewportOrientation === 'landscape' && !isWebview) {
        isSnsShareButtonVisible = true;
    }

    /**
     * 앱의 경우 별도 웹뷰를 새로 띄워 마이팀을 노출하므로 이전 창으로 돌아왔을 때 쿼리 무효화 필요
     */
    const shouldUpdateMyTeamRelatedQueries = useRef(false);

    return (
        <div
            className={cnx(
                'kbo-header',
                'flex justify-between items-center relative w-full h-16',
                isWebview ? '' : isMobileWeb ? 'mt-[4.916rem]' : 'h-20 mt-0 px-16',
            )}
        >
            <div className={cnx('flex items-center h-full')} />
            <button
                type="button"
                className={cnx(
                    'absolute top-1/2 -translate-y-1/2',
                    'text-white',
                    isMobileWeb ? 'left-1/2 -translate-x-1/2' : 'left-16 -translate-x-0',
                )}
            >
                <Link
                    /**
                     * [!] 스포츠 통합 전환 이후에도 구 KBO GNB가 노출되는 영역이 있습니다.
                     * - 선수 상세, 구단 상세 등
                     * - 해당 페이지에서 GNB 내 링크 클릭 시 스포츠 통합으로 이동하도록 해야 합니다.
                     * @see {@link https://tving.atlassian.net/browse/TVING-13200}
                     */
                    href="/sports/kbo"
                    scroll
                >
                    <KboLogo className={cnx(isMobileWeb ? 'w-[6.475rem] h-[2.333rem]' : 'w-[7.4rem] h-[2.667rem]')} />
                </Link>
            </button>
            <div className={cnx(`h-full flex items-center justify-end  ml-[1.333rem] gap-[1.67rem] lg:gap-[2.5rem]`)}>
                {/* <SportsMainHeaderSearchMenu /> */}

                {isSnsShareButtonVisible ? (
                    <div>
                        <SnsShareButton options={{ isOnlyIcon: true }} />
                    </div>
                ) : null}

                {isVisibleMyTeamIcon ? (
                    <div
                        className={`[&_button>svg]:!w-[2rem] [&_button>svg]:!h-[2rem] w-[2rem] h-[2rem] lg:w-[2.667rem] lg:h-[2.667rem] ${
                            isWebview || isMobileWeb ? 'mr-[1.33rem]' : 'mr-0'
                        }`}
                    >
                        <button
                            type="button"
                            onClick={() => {
                                if (isWebview) {
                                    shouldUpdateMyTeamRelatedQueries.current = true;
                                    crossPlatformController.pushWebView(`${window.location.origin}/kbo/myteam/setting`);
                                } else if (isMobileWeb) {
                                    Modal.dialog({
                                        title: '티빙 앱에서 가능합니다.\n앱으로 이동하시겠습니까?',
                                    }).then((result: { isConfirmed: boolean }) => {
                                        if (result.isConfirmed) {
                                            window.location.href = GET_DYNAMIC_LINK_URL({ pathname: `/sports/kbo` });
                                        }
                                    });
                                } else if (isLogin) {
                                    setIsOnMyTeamModal(true);
                                } else {
                                    MoveToLoginPage({ isMobile });
                                }
                            }}
                        >
                            <ErrorBoundaryWrapper>
                                <KboMyTeamIcon shouldUpdateMyTeamRelatedQueries={shouldUpdateMyTeamRelatedQueries} />
                            </ErrorBoundaryWrapper>
                        </button>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default KboHeader;
