import Poster, { PosterProps } from '../../atom/poster/Poster';
import { TextProps } from '../../atom/text/Text';

type TeamShortcutItemProps = {
  className?: string;
  poster: PosterProps;
  posterText: TextProps<React.ElementType>;
  tabIndex?: number;
  style: React.CSSProperties;
};

const TeamShortcutItem = ({ className, poster, tabIndex, style }: TeamShortcutItemProps) => {
  return (
    <div className={`item team-shortcut-item-wrapper ${className}`} tabIndex={tabIndex} style={style}>
      <Poster {...poster} />
    </div>
  );
};

export default TeamShortcutItem;
