import httpGet from '@utils/common/httpGet';
import { KBO_API_URLS } from '@constants/kbo/url';
import { KBO_URL } from '@tving/utils/src/utils/common/apiConfig';
import getKboApiDeviceParams from '@utils/kbo/getKboApiDeviceParams/getKboApiDeviceParams';
import { getCookie } from '@utils/common/cookie';
import { KboMyTeamsResponse } from '@typings/kbo';

const fetchKboMyTeams = <B>(isMobileOrWebview?: boolean): Promise<KboMyTeamsResponse<B>> => {
    const isKboApiV2 = getCookie('kbo_api_v2');
    const kboUrl = isKboApiV2 === 'true' ? KBO_URL.replace('v1', 'v2') : KBO_URL;

    return httpGet<KboMyTeamsResponse<B>>(kboUrl + KBO_API_URLS.MY_TEAMS(), {
        params: getKboApiDeviceParams(isMobileOrWebview),
    });
};
export default fetchKboMyTeams;
