interface ProgressBarProps {
  percent: number | null;
  className?: string;
  percentClassName?: string;
}

const ProgressBar = ({ className, percent, percentClassName }: ProgressBarProps) => {
  // wrapper : absolute left-0 bottom-0 h-[0.3rem] bg-[#000]/10 w-full z-[10]
  // percent : h-full bg-[#FF153C]/90
  return percent ? (
    <div className={`atom-progressBar-wrapper ${className}`}>
      <div className={`atom-progressBar-percent ${percentClassName}`} style={{ width: `${percent}%` }} />
    </div>
  ) : null;
};

export default ProgressBar;
