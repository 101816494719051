import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import axiosInstance from './index';

const httpPost = <T>(url: string, requestConfig?: AxiosRequestConfig): Promise<T> => {
  return axiosInstance
    .post<T>(url, requestConfig?.data, requestConfig)
    .then((response: AxiosResponse<T>) => {
      const { data } = response;

      return data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export default httpPost;
