import { create } from 'zustand';

type ProvisionState = {
    provisionResponse: any;
    setProvisionResponse: (provisionResponse: any) => void;
};

const useProvisionStore = create<ProvisionState>()((set) => ({
    provisionResponse: [],
    setProvisionResponse: (provisionResponse) => {
        if (provisionResponse) {
            set(() => ({
                provisionResponse,
            }));
        }
    },
}));

export const initializeProvisionStore = (initialData) => {
    return useProvisionStore.setState({ provisionResponse: initialData });
};

export default useProvisionStore;
