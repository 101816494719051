declare global {
    interface CustomEventMap {
        getContentInfo: CustomEvent<CrossPlatformGetContentInfoEvent>;
        onMediaChange: CustomEvent<CrossPlatformMediaChangeEvent>;
        onMediaStatusPlayer: CustomEvent<CrossPlatformEvent>;
        onStatusUpdate: CustomEvent<unknown>;
        onChangePlayerSize: CustomEvent<CrossPlatformRect>;
        onPushNotificationPermission: CustomEvent<CrossPlatformPushNotificationPermissionEvent>;
        onConfirmDialog: CustomEvent<CrossPlatformConfirmDialogEvent>;
        onChangeOrientation: CustomEvent<Orientation>;
        onSafeArea: CustomEvent<CrossPlatformSafeAreaEvents>;
        onChangeContentInset: CustomEvent<CrossPlatformContentInsetEvents>;
        onDismissedContentDetail: CustomEvent<unknown>;
        onForeground: CustomEvent<unknown>;
        onBackground: CustomEvent<unknown>;
        onTimeMachineChange: CustomEvent<CrossPlatformModeChangeEvent>;
        onClipPlayConfiguration: CustomEvent<CrossPlatformClipPlayConfigurationChangeEvent>;
        onRequestContentChange: CustomEvent<CrossPlatformRequestContentChangeEvent>;
        onServiceNotification: CustomEvent<CrossPlatformServiceNotification>;
        onInitAppConfigurationInfo: CustomEvent<CrossPlatformAppConfigurationEvent>;
        onChangePlayerStatus: CustomEvent<CrossPlatformChangePlayerStatusEvent>;
        onRequestNextContent: CustomEvent<unknown>;
        onMultiviewChange: CustomEvent<CrossPlatformModeChangeEvent>;
        onChangePlayerFeature: CustomEvent<CrossPlatformChangePlayerFeatureOptions>;
        onChangePlayerMode: CustomEvent<CrossPlatformChangePlayerModeEvent>;
        showBottomSheet: CustomEvent<CrossPlatformShowBottomSheetEventOptions>;
        onConfirmBottomSheet: CustomEvent<CrossPlatformConfirmBottomSheetEvent>;
        onModerateTvingTalk: CustomEvent<CrossPlatformModerateTvintTalkEvent>;
        moderateTvingTalk: CustomEvent<CrossPlatformModerateTvintTalkEventOptions>;
    }

    interface Window {
        onMediaStatusPlayer: (event: CrossPlatformEvent) => void;
        onBackPressed: () => void;
        onChangePlayerSize: (playerSizeOptions: CrossPlatformRect) => void;
        onMediaChange: (event: CrossPlatformMediaChangeEvent) => void;
        getContentInfo: (contentInfo: CrossPlatformGetContentInfoEvent) => void;
        onPushNotificationPermission: (event: CrossPlatformPushNotificationPermissionEvent) => void;
        onChangeOrientation: (event: Orientation) => void;
        onConfirmDialog: (event: CrossPlatformConfirmDialogEvent) => void;
        onStatusUpdate: (event: unknown) => void;
        onStart: () => void;
        onStop: () => void;
        onSafeArea: (event: CrossPlatformSafeAreaEvents) => void;
        onChangeContentInset: (event: CrossPlatformContentInsetEvents) => void;
        onDismissedContentDetail: () => void;
        onForeground: () => void;
        onBackground: () => void;
        onTimeMachineChange: (event: CrossPlatformModeChangeEvent) => void;
        getServiceNotification: () => void;
        setServiceNotification: (serviceNotification: CrossPlatformServiceNotification) => void;
        onServiceNotification: (event: CrossPlatformServiceNotification) => void;
        onClipPlayConfiguration: (event: CrossPlatformClipPlayConfigurationChangeEvent) => void;
        onRequestContentChange: (event: CrossPlatformRequestContentChangeEvent) => void;
        getInitAppConfigurationInfo: () => void;
        onInitAppConfigurationInfo: (event: CrossPlatformAppConfigurationEvent) => void;
        onChangePlayerStatus: (event: CrossPlatformChangePlayerStatusEvent) => void;
        onRequestNextContent: () => void;
        onMultiviewChange: (event: CrossPlatformModeChangeEvent) => void;
        onChangePlayerFeature: (event: CrossPlatformChangePlayerFeatureOptions) => void;
        onChangePlayerMode: (event: CrossPlatformChangePlayerModeEvent) => void;
        showBottomSheet: (event: CrossPlatformShowBottomSheetEventOptions) => void;
        onConfirmBottomSheet: (event: CrossPlatformConfirmBottomSheetEvent) => void;
        getTvingTalkModeration: () => void;
        reportMessage: (messageId: number) => void;
        onModerateTvingTalk: (event: CrossPlatformModerateTvintTalkEvent) => void;
        moderateTvingTalk: (event: CustomEvent<CrossPlatformModerateTvintTalkEventOptions>) => void;

        // adds definition to Document, but you can do the same with HTMLElement
        addEventListener<K extends keyof CustomEventMap>(type: K, listener: (this: Document, ev: CustomEventMap[K]) => void): void;
        dispatchEvent<K extends keyof CustomEventMap>(ev: CustomEventMap[K]): void;
        removeEventListener<K extends keyof CustomEventMap>(type: K, listener: (this: Document, ev: CustomEventMap[K]) => void): void;
    }
}

export enum CrossPlatformType {
    ANDROID = 'ANDROID',
    IOS = 'IOS',
    WEB = 'WEB',
}

export const enum PlayerMediaStatus {
    LOADED = 'loaded',
    PLAY = 'play',
    PAUSE = 'pause',
    ERROR = 'error',
    ENDED = 'ended',
}

export type Orientation = 'landscape' | 'portrait' | '';

export type CrossPlatformPageInitializedOptions = {
    code: string;
    gameCode?: string;
    roomId?: string;
    title: string;
    subtitle: string;
    imageUrl?: string;
    switchChannelCode?: string;
    switchChannelLogoUrl?: string;
};

export type CrossPlatformChangeMediaOptions = {
    code: string;
    gameCode?: string;
    isAutoPlay: boolean;
    orientation: Orientation;
};

export type CrossPlatformShowNotificationOptions = {
    title: string;
    titleSize: number;
    titleColor: string;
    titleWeight: number;
    desc: string;
    descSize: number;
    descColor: string;
    descWeight: number;
    buttonTitle: string;
    buttonTitleSize: number;
    buttonTitleColor: string;
    buttonTitleWeight: number;
    buttonDesc: string;
    buttonDescSize: number;
    buttonDescColor: string;
    buttonDescWeight: number;
    backgroundImageUrl: string;
    backgroundColor: string;

    isShowGameListPlayerButton: boolean;
    isDisabledGameListPlayerButton: boolean;
};

export type CrossPlatformOpenTvingTalkOptions = {
    roomId: string;
};

export type CrossPlatformRect = {
    x: number;
    y: number;
    width: number;
    height: number;
};

export type CrossPlatformChangeOrientationOptions = {
    orientation: Orientation;
    isFixed: boolean;
};

export type CrossPlatformShowDialogOptions = {
    id: string;
    confirmButton: string;
    cancelButton: string;
    message: string;
};

export enum AndroidHapticFeedbackType {
    CLOCK_TICK = 4,
    CONFIRM = 16,
    CONTEXT_CLICK = 6,
    DRAG_START = 25,
    FLAG_IGNORE_GLOBAL_SETTING = 2,
    FLAG_IGNORE_VIEW_SETTING = 1,
    GESTURE_END = 13,
    GESTURE_START = 12,
    GESTURE_THRESHOLD_ACTIVATE = 23,
    GESTURE_THRESHOLD_DEACTIVATE = 24,
    KEYBOARD_PRESS = 3,
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    KEYBOARD_TAP = 3,
    KEYBOARD_RELEASE = 7,
    LONG_PRESS = 0,
    NO_HAPTICS = -1,
    REJECT = 17,
    SEGMENT_FREQUENT_TICK = 27,
    SEGMENT_TICK = 26,
    TEXT_HANDLE_MOVE = 9,
    TOGGLE_OFF = 22,
    TOGGLE_ON = 21,
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    VIRTUAL_KEY = 1,
    VIRTUAL_KEY_RELEASE = 8,
}

export enum IosHapticFeedbackType {
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
    LIGHT = 'light',
    MEDIUM = 'medium',
    HEAVY = 'heavy',
    SOFT = 'soft',
    RIGID = 'rigid',
    SELECTION = 'selection',
}

export type CrossPlatformHapticFeedbackOptions = {
    hapticType: AndroidHapticFeedbackType | IosHapticFeedbackType;
};

export type CrossPlatformShareLinkOptions = CrossPlatformRect & {
    url: string;
    title: string;
};

export type CrossPlatformEvent = {
    event: string;
    code: string;
    message: string;
};

export type CrossPlatformMediaChangeEvent = {
    code: string;
};
export type CrossPlatformGetContentInfoEvent = {
    code: string;
    roomId: string;
};
export type CrossPlatformPushNotificationPermissionEvent = {
    isGranted: boolean;
};

export type CrossPlatformConfirmDialogEvent = {
    id: string;
};

export type CrossPlatformConfirmBottomSheetEvent = {
    id: string;
    action: 'tvingtalkMessageDelete' | 'tvingtalkMessageReport';
};

export type CrossPlatformSafeAreaEvents = {
    top: number;
    bottom: number;
    right: number;
    left: number;
};

export type CrossPlatformContentInsetEvents = {
    top: number;
    bottom: number;
    right: number;
    left: number;
};

export type CrossPlatformModeChangeEvent = {
    isOn: boolean;
};

export type CrossPlatformServiceNotification = {
    pushToken?: string;
    isGranted?: boolean;
    isReceiveEvent: boolean;
    isReceiveNight: boolean;
};

export type CrossPlatformChangePlayerFeatureOptions = {
    isDisabledGameListPlayerButton?: boolean; // 타구장 경기 버튼
    isDisabledContinuousClipPlayerButton?: boolean; // 클립 다음영상 버튼
    isDisabledClipPlaylistPlayerButton?: boolean; // 클립 재생목록 버튼
    isDisabledSwitchChannelButton?: boolean; // 채널 변경 버튼
    isActiveTvingTalk?: boolean; // 티빙톡 활성화 여부
};

export type CrossPlatformClipPlayConfigurationChangeEvent = {
    isContinuous: boolean;
};

export type CrossPlatformRequestContentChangeEvent = {
    code: string;
};

export type CrossPlatformAppConfigurationEvent = {
    apiKey: string;
    deviceName: string;
    deviceOsVersion: string;
    deviceAppId: string;
    deviceAppVersion: string;
    screenCode: string;
    networkCode: string;
    osCode: string;
    teleCode: string;
};

export type CrossPlatformChangePlayerStatusEvent = {
    status: PlayerMediaStatus;
};

export type CrossPlatformOpenUrlOptions = {
    url: string;
};

export type CrossPlatformSendBrazeCustomEventOptions = {
    eventName: string;
    eventProperties?: {
        [key: string]: string;
    };
};

export type CrossPlatformChangePlayerModeEvent = {
    isFull: boolean;
};

export type CrossPlatformShowBottomSheetEventOptions = {
    id: string;
    type: 'tvingtalkDelete' | 'tvingtalkReport' | 'tvingtalkMessage';
    isMyMessage: boolean;
    nickname: string;
    message: string;
};

export type CrossPlatformModerateTvintTalkEventOptions = {
    action: 'reportMessage' | 'hidePinnedMessage';
    id: number;
};

export type CrossPlatformModerateTvintTalkEvent = {
    reportedIdList: number[];
    hiddenPinnedMessageIdList: number[];
};
