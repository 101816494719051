import React from 'react';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import IconArrowDownward from '@img/chat/icon_arrow_downward.svg';
import { isMobile, isWebview } from '@utils/device/device-detect';
import { useChatConfig } from '@tving/utils/src/contexts/ChatContext';

type Props = {
    container: HTMLDivElement | null;
    handler: () => void;
};

const ScrollToBottomButton = ({ container, handler }: Props) => {
    const { isInputMultiline, isInputFocus } = useChatConfig();
    const moveScrollToBottom = () => {
        handler();
    };

    return (
        <button
            type="button"
            className={tempCnx(
                'flex-center absolute overflow-hidden w-[2.667rem] h-[2.667rem] right-1/2 translate-x-1/2',
                'mx-auto rounded-[50%] bg-[white]',
                'bottom-[5.750rem]',
                isWebview
                    ? isInputMultiline
                        ? isInputFocus
                            ? 'bottom-[calc(1.333rem_+_5.75rem)] landscape:bottom-[1.333rem]'
                            : 'bottom-[calc(1.333rem_+_5.75rem_+_var(--safe-area-inset-bottom))] landscape:bottom-[1.333rem]'
                        : isInputFocus
                        ? 'bottom-[calc(1.333rem_+_4.417rem)] landscape:bottom-[1.333rem]'
                        : 'bottom-[calc(1.333rem_+_4.417rem_+_var(--safe-area-inset-bottom))] landscape:bottom-[1.333rem]'
                    : 'landscape:bottom-[1.333rem]',
            )}
            aria-label="scroll to bottom"
            onClick={moveScrollToBottom}
        >
            <IconArrowDownward className={tempCnx('w-[1.667rem] h-[1.667rem]')} />
        </button>
    );
};

export default ScrollToBottomButton;
