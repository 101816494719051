import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useViewportSpy } from '@hooks/useViewportSpy';
import { Picture } from '@components/common/Picture';
import { css } from '@emotion/react';
import { StyledItemTagsDirection, StyledTag, StyledBlindText, StyledItemPlayBtn } from '@styles/components/item';
import { replaceWeirdUnicodeWithBlank } from '@utils/common/String';
import NewFreeLabel from '@tving/ui/src/molecules/labels/free/FreeLabel';
import NewQuickVodLabel from '@tving/ui/src/molecules/labels/quickVod/QuickVodLabel';

import NewOriginalLabel from '@tving/ui/src/molecules/labels/original/OriginalLabel';
import NewOnlyLabel from '@tving/ui/src/molecules/labels/only/OnlyLabel';

import { LABEL_IMAGE, LABEL_TYPE } from '@tving/ui/src/constants/labelConstants';

const defaultLabels = {
    type: 'SVG',
    free: 'sm:w-[2rem] sm:h-[1.333rem] sm:top-[0.166rem] sm:left-[0.166rem] md:top-[0.25rem] md:left-[0.25rem] md:w-[2.75rem] md:h-[1.833rem] z-[30]',
    original:
        'absolute left-1/2 translate-x-[-50%] translate-y-[-50%] sm:h-[0.916rem] sm:w-[5.316rem] sm:bottom-[1rem] md:w-[7.233rem] md:h-[1.25rem] md:bottom-[1rem] z-[30]',
    only: 'absolute left-1/2 translate-x-[-50%] translate-y-[-50%] sm:h-[0.916rem] sm:w-[3.541rem] sm:bottom-[1rem] md:w-[4.758rem] md:h-[1.25rem] md:bottom-[1rem] z-[30]',
    quick: 'sm:top-[0.166rem] sm:left-[0.166rem] sm:w-[3.333rem] sm:h-[1.333rem] md:top-[0.25rem] md:left-[0.25rem] md:w-[4.583rem] md:h-[1.833rem] z-[30]',
};

/* 무료 라벨 */
export const FreeLabel = () => {
    return <NewFreeLabel css={{ margin: '0 0 0.2em' }} type={defaultLabels.type} className={defaultLabels.free} />;
    // return <div className="tag tag-lightgreen">무료</div>;
};

/* 개별구매 라벨 */
export const BuyEachLabel = () => {
    return <div className="tag tag-green">개별구매</div>;
};

/* !!! 수정 필요 - 이벤트 라벨 */
export const EventLabel = () => {
    return <div className="tag tag-yellow">이벤트</div>;
};

/* 최초공개 라벨 */
export const FirstReleaseLabel = () => {
    return <div className="tag tag-red">최초공개</div>;
};

/* 극장동시 라벨 */
export const CinemaLabel = () => {
    return <div className="tag tag-red">극장동시</div>;
};

/* QuickVOD 라벨 */
export const QuickVodLabel = () => {
    return <NewQuickVodLabel css={{ margin: '0 0 0.2em' }} type={defaultLabels.type} className={defaultLabels.quick} />;
    // return <div className="tag tag-quick">Quick VOD</div>;
};
// 좌측 상단 라벨 - 종료

// 썸네일 이미지
export const ThumbnailImage = ({ thumbnail, title, size = 400, isLazy = false }) => {
    const ref = useRef();
    const isVisible = useViewportSpy(ref);
    const [isView, setIsView] = useState(!isLazy);
    const [imgActive, setImgActive] = useState(false);

    useEffect(() => {
        setImgActive(false);
        const activeTimer = setTimeout(() => {
            setImgActive(true);
        }, 0);
        return () => {
            clearTimeout(activeTimer);
        };
    }, [thumbnail]);

    useEffect(() => {
        if (isVisible && !isView) {
            setIsView(true);
        }
    }, [isVisible]);

    const eventLoaded = e => {
        e.target?.parentNode?.classList?.remove('error');
        e.target?.parentNode?.classList?.add('loaded');
    };
    const eventError = e => {
        if (e) {
            e.target?.parentNode?.classList?.remove('loaded');
            e.target?.parentNode?.classList?.add('error');
        }
    };

    return (
        <div className="item__image lazy" ref={ref}>
            {/* <!--컨텐츠(포스터)S--> */}
            {isView && imgActive && <Picture src={thumbnail} size={size} alt={title} onError={eventError} onLoad={eventLoaded} />}
            {/* {<Picture src={thumbnail} size={size} alt={title} onError={eventError} onLoad={eventLoaded} />} */}
        </div>
    );
};

// 플레이 버튼
export const ThumbnailPlay = ({ dimm, isOnlyMobile, isVertical }) => {
    return <StyledItemPlayBtn dimm={dimm} isOnlyMobile={isOnlyMobile} isVertical={isVertical} />;
};

// 썸네일 하단 태그
/* 티빙 오리지널 태그 */
export const TvingOriginalTag = () => {
    return (
        <NewOriginalLabel type={defaultLabels.type} className={defaultLabels.original} />
        // <StyledItemTagsDirection direction="down">
        //     <StyledTag type="original" />
        //     <StyledBlindText>티빙 오리지널</StyledBlindText>
        // </StyledItemTagsDirection>
    );
};

/* 티빙 온리 태그 */
export const TvingOnlyTag = () => {
    return <NewOnlyLabel type={defaultLabels.type} className={defaultLabels.only} />;
    // return (
    //     <StyledItemTagsDirection direction="down">
    //         <StyledTag type="only" />
    //         <StyledBlindText>티빙 온리</StyledBlindText>
    //     </StyledItemTagsDirection>
    // );
};

/* 뉴티빙 티빙 오리지널 svg */
export const TvingOriginalSvg = () => {
    const IMAGE = LABEL_IMAGE && LABEL_TYPE?.ORIGINAL && LABEL_IMAGE[LABEL_TYPE?.ORIGINAL];
    return IMAGE ? <IMAGE.SVG /> : null;
};

/* 뉴티빙 티빙 온리 svg */
export const TvingOnlySvg = () => {
    const IMAGE = LABEL_IMAGE && LABEL_TYPE?.ONLY && LABEL_IMAGE[LABEL_TYPE?.ONLY];
    return IMAGE ? <IMAGE.SVG /> : null;
};

// 썸네일 하단 태그 - 종료

// 썸네일 하위 시청 퍼센트 태그
export const RunningTimeTag = styled.div`
    span.item__progress-bar {
        width: ${props => props.watchPercent}% !important;
    }
`;

export const MouseOverSummary = ({ title = '', genre = '', summary = '' }) => {
    return (
        <div className="item__summary">
            <dl>
                <dt>{title}</dt>
                <dd>
                    <span>{genre}</span>

                    {/** 윈도우 환경을 위한 특정 유니코드 문자열 치환 */}
                    <p>{replaceWeirdUnicodeWithBlank(summary)}</p>
                </dd>
            </dl>
        </div>
    );
};
