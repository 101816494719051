import { useEffect, useMemo, useRef } from 'react';

import { shallow } from 'zustand/shallow';

import generateMergedConfig from './helper/generateMergedConfig';
import generateRandomId from './helper/generateRandomId';
import overlayKit from './overlayKit';
import { type OverlayControllerComponent, type OverlayAsyncControllerComponent, type OverlayKitIntegrationOptions } from './types';
import useOverlayKitIntegration from './useOverlayKitIntegration';

/**
 * `overlayKit`과 거의 동일하나, 컴포넌트 LifeCycle에 따라 인스턴스를 파괴할 수 있는 기능을 제공합니다.
 */
const useOverlayKit = () => {
  const callerManagedInstances = useRef<string[]>([]);
  const { destroyInstance } = useOverlayKitIntegration((state) => state, shallow);

  const apis = useMemo(() => {
    const open = (controller: OverlayControllerComponent, config?: OverlayKitIntegrationOptions) => {
      const mergedConfig = generateMergedConfig(config);
      const overlayId = overlayKit.open(controller, mergedConfig);

      if (mergedConfig.destroyOnCallerUnmount) {
        callerManagedInstances.current.push(overlayId);
      }

      return overlayId;
    };

    const openAsync = async <TResult>(controller: OverlayAsyncControllerComponent<TResult>, config?: OverlayKitIntegrationOptions) => {
      const overlayId = config?.overlayId || generateRandomId();
      const mergeConfig = generateMergedConfig({
        ...config,
        overlayId,
      });

      if (mergeConfig.destroyOnCallerUnmount) {
        callerManagedInstances.current.push(overlayId);
      }

      return overlayKit.openAsync(controller, mergeConfig);
    };

    return {
      open,
      /**
       * 실행 결과를 반환하는 Overlay를 엽니다.
       *
       * `Route Change`, `Caller Unmount`으로 파괴 시 Promise가 resolve되지 않을 수 있어, `<OverlayAsyncFallbackResolver/>`를 Wrapper로 함께 사용해야 합니다.
       */
      openAsync,
    };
  }, []);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps -- React가 렌더링한 Node가 아닙니다.
      callerManagedInstances.current.forEach((overlayId) => {
        destroyInstance(overlayId);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Unmount 시에만 호출되어야 합니다.
  }, []);

  return apis;
};

export default useOverlayKit;
