import { KBO_CONTENT_DETAIL_APP_LINK_URL, KBO_HOME_APP_LINK_URL } from '@constants/urls';

type GetDynamicUrlType = (code?: string) => string;

type PatternHandlerType = {
    [key: string]: GetDynamicUrlType;
};

const getKboDynamicUrl = ({ currentUrl = '', code }) => {
    const urlPatternHandler: PatternHandlerType = {
        home: () => KBO_HOME_APP_LINK_URL(),
        contents: (code) => {
            return KBO_CONTENT_DETAIL_APP_LINK_URL({ code });
        },
    };

    let url = '';

    const foundPattern = Object.keys(urlPatternHandler)?.find((pattern) => currentUrl?.includes(pattern));

    if (foundPattern) {
        url = urlPatternHandler[foundPattern](code);
    }

    return url;
};

export default getKboDynamicUrl;
