import { shallow } from 'zustand/shallow';
import useClientStore from '@stores/useClientStore';
import fetchKboHome from '@apis/kbo/bff/fetchKboHome';
import { useSuspenseQuery } from '@suspensive/react-query';
import { KBO_QUERY_KEYS } from '@hooks/kbo/queries/index';
import { KboHomeResponse } from '@typings/kbo';
import { KBO_TAB_TYPE } from '@typings/kbo/band';

type useKboHomeQueryType = {
    tabName: KBO_TAB_TYPE;
    options: {
        [key: string]: string;
    };
};
const useKboHomeQuery = <B = unknown>({ tabName, options }: useKboHomeQueryType) => {
    const { isMobile, isWebview, isTablet } = useClientStore(
        (state) => ({
            isMobile: state.isMobile,
            isWebview: state.isWebview,
            isTablet: state.isTablet,
        }),
        shallow,
    );
    const isMobileOrWebview = !!(isMobile || isWebview);

    return useSuspenseQuery<KboHomeResponse<B>>(KBO_QUERY_KEYS.HOME(tabName), () =>
        fetchKboHome({
            tab: tabName,
            deviceType: {
                isMobileOrWebview,
                isTablet: !!isTablet,
            },
            options,
        }),
    );
};

export default useKboHomeQuery;
