import qs from 'qs';

export const urlString = {
    parse: str => {
        const url = str.split('?')[0];
        const params = str.split('?')[1];
        const parse = qs.parse(params);
        parse._url = url;
        return parse;
    },
    stringify: obj => {
        const url = obj._url ? `${obj._url}?` : '';
        delete obj._url;
        return `${url}${qs.stringify(obj)}`;
    },
};

export const mediaTimeToString = time => {
    const sec_num = parseInt(time, 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (minutes < 10) {
        if (hours > 0) {
            minutes = `0${minutes}`;
        }
    }
    if (seconds < 10) {
        seconds = `0${seconds}`;
    }

    let out = `${minutes}:${seconds}`;

    if (hours > 0) {
        if (hours < 10) {
            hours = `${hours}`;
        }
        out = `${hours}:${out}`;
    }

    return out;
};

export const replaceWeirdUnicodeWithBlank = str =>
    str
        ? JSON.parse(
              JSON.stringify(str)
                  .replaceAll(/\\b|\\f/g, '')
                  .replaceAll(/\\u000b/g, ' '),
          )
        : str;
