import { HTMLProps, RefObject } from 'react';

export type PosterProps = Omit<HTMLProps<HTMLImageElement>, 'crossOrigin'> & {
  onLoad?: () => void;
  onError?: (e) => void;
  ref?: RefObject<HTMLImageElement>;
  imgRef?: RefObject<HTMLImageElement>;
  dataSrc?: string;
};

const Poster = (posterProps: PosterProps) => {
  const { alt, className, imgRef, dataSrc, src } = posterProps;

  if (!dataSrc && !src) {
    return null;
  }

  return <img {...posterProps} className={`atom-poster-img ${className}`} alt={`${alt} 포스터`} ref={imgRef} />;
};

export default Poster;
