// store.js
import { create } from 'zustand';

type State = {
    isSupportsWebP: boolean;
    isWebPSupportDetectionDone: boolean;
    setIsSupportsWebP: (value: boolean) => void;
};

const useWebpStore = create<State>()((set) => ({
    isSupportsWebP: false,
    isWebPSupportDetectionDone: false,
    setIsSupportsWebP: (value: boolean) => set({ isSupportsWebP: value, isWebPSupportDetectionDone: true }),
}));

export default useWebpStore;
