import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@styles/colors';
import { device, mq } from '@styles/device';
import { size, space } from '@styles/space';

export const StyledContentActionWrap = styled.div`
    display: flex;
    align-items: center;
    /* padding-left: 1.333rem; */
    > div {
        position: relative;
    }
`;
export const StyledContentActionInfoButton = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5.333rem;
    height: 5.333rem;
    text-align: center;
    margin: 0;
    opacity: 0.87;
    transition: opacity 0.1s;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
    svg {
        width: 2.667rem;
        height: 2.667rem;
        margin-bottom: 0.75rem;
    }
    span {
        font-size: 1.167rem;
        font-weight: 500;
        line-height: 0.86;
        text-align: center;
        color: #fff;
    }
`;
export const StyledContentArticle = styled.article`
    position: relative;
`;
export const StyledContentArticleInner = styled.article`
    ${mq({
        marginLeft: space.containerSidePadding,
        marginRight: space.containerSidePadding,
    })}
`;
export const StyledContentWrap = styled.div`
    position: relative;
    margin: 0 4.166rem 2.5rem 1.5rem;
    padding: 3rem 0rem 4rem 0;
    /* display: flex;
    flex-direction: row-reverse;
    align-items: flex-start; */

    display: grid;
    grid-column-gap: 2.5rem;
    /* grid-template-columns: 40rem auto 22rem; */
    grid-template-columns: minmax(auto, 40rem) auto 22rem;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -1.5rem;
        right: -4.166rem;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.18);
    }

    .is-mobile & {
        margin: 0 0 2.5rem 0;
        @media ${device.mobile} {
            margin: 0;
        }
        @media ${device.tabletOnly} {
            grid-template-columns: minmax(auto, 40rem) auto 20rem;
        }
        @media ${`${device.mobileL} and ${device.landscape}`} {
            grid-template-columns: minmax(auto, 40rem) auto 20rem;
        }
        &:after {
            left: 0rem;
            right: 0rem;
        }
    }

    @media ${device.mobile} {
        margin: 0;
        padding: 1.667rem 0 2.5rem 0;
        grid-template-columns: 1fr;
        &:after {
            content: none;
        }
    }
`;
export const StyledContentInfoWrap = styled.div`
    grid-column-start: 1;
    position: relative;
`;
export const StyledContentHeader = styled.h2`
    position: relative;
    display: block;
    line-height: 0;
`;
export const StyledTvingIconWrap = styled.span`
    position: relative;
    display: inline-block;
    svg {
        width: auto;
        height: 1.5rem;
        @media ${device.mobile} {
            height: 0.92rem;
        }
        .is-mobile & {
            @media ${device.tabletOnly} {
                height: 0.92rem;
            }
        }
    }
`;
export const StyledParamountIconWrap = styled.span`
    position: relative;
    display: inline-block;
    svg {
        width: auto;
        height: 2.167rem;
        @media ${device.mobile} {
            height: 1.329rem;
        }
        .is-mobile & {
            @media ${device.tabletOnly} {
                height: 1.329rem;
            }
        }
    }
`;
export const StyledPosterBgWrap = styled.div`
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 56.333rem;

    /* .is-laptop & { */
    @media ${device.tablet} {
        ${mq({
            top: size.gnbHeightMinus,
        })}
    }
    /* } */
`;
export const StyledPosterBgInner = styled.div`
    position: absolute;
    left: -5rem;
    right: -5rem;

    /* backdrop-filter: blur(50px); */
    canvas {
        display: block;
        width: 100%;
        height: 56.333rem;
        @media ${device.mobile} {
            height: 54rem;
        }
        /* object-fit: cover; */
        /* filter: blur(70px); */
    }
    &:before {
        content: '';
        position: absolute;
        top: -10rem;
        bottom: -10rem;
        left: -10rem;
        right: -10rem;
        background-color: rgba(0, 0, 0, 0.5);
    }
    &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: -1rem;
        right: -1rem;
        height: 25rem;
        background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
    }
`;
export const StyledMobilePosterWrap = styled.div`
    display: none;
    position: relative;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.13);
    ${mq({
        marginLeft: space.containerSidePaddingMinus,
        marginRight: space.containerSidePaddingMinus,
    })}
    @media ${device.mobile} {
        display: block;
    }
    @media ${`${device.mobileL} and ${device.landscape}`} {
        display: none;
    }

    &:before {
        content: '';
        display: block;
        padding-top: 56.25%;
        width: 100%;
        float: left;
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit;
    }
    &:after {
        content: '';
        display: block;
        clear: both;
    }
    picture {
        &.error {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                width: 100%;
                height: 100%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='28' viewBox='0 0 88 28'%3E%3Cdefs%3E%3Cstyle%3E .cls-3%7Bfill:%23000%7D %3C/style%3E%3C/defs%3E%3Cg id='TVING_logo_W' opacity='0.25' transform='translate(0 10.306)'%3E%3Cpath id='area' fill='none' d='M0 0H88V28H0z' transform='translate(0 -10.306)'/%3E%3Cg id='logo' transform='translate(-1 -1.93)'%3E%3Cpath id='사각형_1958' d='M0 0H6.325V19.975H0z' class='cls-3' transform='translate(41.561 -4.377)'/%3E%3Cpath id='패스_1268' d='M65.982 271.969H50.875v6.331h5.881v13.689h6.509V278.3h5.955l-1.982-5.438a1.343 1.343 0 0 0-1.255-.895' class='cls-3' transform='translate(-49.875 -276.346)'/%3E%3Cpath id='패스_1269' d='M205.762 280.825l-6.279-8.214a1.617 1.617 0 0 0-1.284-.642h-3.92v19.8l.017.17h6.092l.166-.018v-8.843l6.271 8.217a1.62 1.62 0 0 0 1.287.644h3.925v-19.97h-6.274z' class='cls-3' transform='translate(-143.654 -276.346)'/%3E%3Cpath id='패스_1270' d='M117.444 272.879l-3.389 9.708-3.373-9.706a1.343 1.343 0 0 0-1.261-.913h-6l7.481 19.974h6.313l7.485-19.974h-6a1.342 1.342 0 0 0-1.26.911' class='cls-3' transform='translate(-84.201 -276.344)'/%3E%3Cpath id='패스_1271' d='M264.2 286.15h-3.754v-8.272h7.646a1.763 1.763 0 0 0 1.641-1.166l1.743-4.744h-17.359v19.975h17.148v-11.4H264.2z' class='cls-3' transform='translate(-182.798 -276.344)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                background-position: center;
                background-size: 32%;
                background-repeat: no-repeat;
                background-color: rgba(255, 255, 255, 0.13);
            }
            img,
            source {
                opacity: 0;
                transition: opacity 0s;
            }
        }
    }
    img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;
export const StyledLaptopPosterWrap = styled.div`
    grid-column-start: 3;
    grid-row-start: 1;
    align-self: start;
    position: relative;
    overflow: hidden;
    display: inline-block;
    right: 0;
    width: 22rem;
    border-radius: 4px;
    box-shadow: 0 18px 30px 0 rgba(0, 0, 0, 0.31);
    background-color: rgba(255, 255, 255, 0.13);

    &:before {
        content: '';
        display: block;
        padding-top: 144%;
        width: 100%;
        float: left;
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit;
    }
    picture {
        &.error {
            &:before {
                background-size: 10.333rem 2.333rem;
            }
            img,
            source {
                opacity: 0;
                transition: opacity 0s;
            }
        }
    }
    img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    @media ${device.mobile} {
        display: none;
    }
    @media ${`${device.mobileL} and ${device.landscape}`} {
        display: block;
    }
    .is-mobile & {
        @media ${device.tabletOnly} {
            width: 20rem;
        }
        @media ${`${device.mobileL} and ${device.landscape}`} {
            width: 20rem;
        }
    }
`;
export const StyledPosterTag = styled.div`
    /* background: url('/img/t_orig.svg') no-repeat 50% 0;
    background-size: 48% 100%; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 7.002rem;
    object-fit: contain;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    svg {
        width: auto;
        height: 1.854rem;
        margin-bottom: 2.225rem;
    }
`;
export const StyledContentTitle = styled.p`
    position: relative;
    margin: 1rem 0 1.2rem;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    word-break: keep-all;
    /* @media ${`${device.mobile}, ${device.tabletOnly}`} { */
    /* .is-mobile & { */
    @media ${device.mobile} {
        margin: 0.333rem 0 0.667rem;
        line-height: 1.42;
        font-size: 2.167rem;
    }
    .is-mobile & {
        @media ${device.tabletOnly} {
            /* margin: 0.333rem 0 0.667rem; */
            font-size: 2.75rem;
        }
        @media ${`${device.mobileL} and ${device.landscape}`} {
            /* font-size: 2.75rem; */
        }
    }
`;
export const StyledLogoWrap = styled.div`
    position: relative;
    margin: 1.5rem 0 1.5rem;
    img {
        max-width: 30rem;
        max-height: 7rem;
        object-fit: contain;
        object-position: left top;
    }
`;
export const StyledTagsWrap = styled.div`
    position: relative;
    .tag_wrap {
        position: relative;
        display: flex;
        margin-top: -0.5rem;
        flex-wrap: wrap;

        .tag {
            margin: 0.5rem 0.5rem 0 0;
            padding-bottom: 0.05em;
            white-space: nowrap;

            @media ${device.mobile} {
                height: 1.75rem;
                font-size: 1rem;
                margin: 0.667rem 0.5rem 0 0;
                padding-bottom: 0.05em;
            }
        }
    }
`;
export const StyledContentActionButton = styled.button`
    position: relative;
    margin-right: 1.333rem;
    padding: 1.5rem 5rem 1.5rem;
    background-color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.89;
    color: #000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    transition: opacity 0.3s, transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    svg {
        height: 1.333rem;
        margin-right: 1rem;
    }

    &:hover {
        transform: scale(1.03);
    }
    &:active {
        transform: scale(0.95);
    }

    @media ${device.mobile} {
        width: 100%;
        justify-content: center;
    }

    .is-mobile & {
        padding: 1.167rem 4rem 1.433rem;
        font-size: 1.167rem;
        line-height: normal;
        border-radius: 3px;
        background-color: #ff153c;
        color: #fff;
    }
    ${({ isPointButton }) =>
        isPointButton &&
        css`
            margin-top: 0.5rem;
            padding: 1.25rem 5rem 1.166rem;
            background-color: #ff153c;
            color: #fff;
        `}

    ${({ isDisplayTitle }) =>
        isDisplayTitle &&
        css`
            padding: 1.5rem 2.5rem 1.5rem;
            font-size: 1.333rem;
            svg {
                /* display: none; */
            }
        `}
`;

export const StyledDisabledButton = styled.div`
    position: relative;
    margin-right: 1.333rem;
    padding: 1.5rem 5rem 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.89;
    border-radius: 4px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    background-color: rgba(255, 255, 255, 0.31);

    @media ${device.mobile} {
        width: 100%;
        justify-content: center;
    }

    .is-mobile & {
        padding: 1.167rem 4rem 1.433rem;
        font-size: 1.167rem;
        line-height: normal;
        border-radius: 3px;
    }

    & > span {
        color: ${colors.white};
        opacity: 0.53;
    }
`;

export const StyledContentActionProgress = styled.div`
    position: relative;
    overflow: hidden;
    width: 5rem;
    height: 0.333rem;
    margin: 0 0 0 1rem;
    padding: 0 4.167rem 0 0;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.1);

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: ${({ watchPercent }) => `${watchPercent}%`};
        height: 100%;
        opacity: 0.9;
        border-radius: 2px;
        background-color: #ff153c;
    }
`;

export const StyledContentFrequencyMoreButton = styled.button`
    display: block;
    width: 100%;
    border-radius: 3px;
    border: solid 1px rgba(255, 255, 255, 0.64);
    margin: 1.667rem 0 2.5rem;
    padding: 1.167rem 0 1.25rem;
    line-height: 1.47;
    text-align: center;
    font-size: 1.25rem;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.9);
    span {
        font-weight: 700;
    }
`;
export const StyledContentArtistsWrap = styled.div`
    margin: 1rem 0 0;
`;
export const StyledContentArtistsInfo = styled.dl`
    display: flex;
    dt {
        margin: 0 1.25rem 0 0;
        opacity: 0.64;
        font-size: 1.25rem;
        line-height: 1.4;
        color: #fff;
        white-space: nowrap;

        @media ${device.mobile} {
            margin: 0 0.583rem 0 0;
            font-size: 1.083rem;
            line-height: 1.23;
        }
    }
    dd {
        opacity: 0.64;
        font-size: 1.25rem;
        line-height: 1.4;
        color: #fff;
        /* word-break: keep-all; */

        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-inline-box;

        @media ${device.mobile} {
            font-size: 1.083rem;
            line-height: 1.23em;
            ${({ isBlockLine }) =>
                isBlockLine &&
                css`
                    max-height: 1.23em;
                `}
        }
    }
`;
export const StyledOpenSoonWrap = styled.div`
    padding: 2rem 0 0;
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    @media ${device.mobile} {
        padding: 1.167rem 0 0;
        font-size: 1.25rem;
    }
`;
export const StyledPcDiv = styled.div`
    display: inline-flex;
    padding: 2rem 0 1rem;
    @media ${device.tablet} {
        display: flex;
    }
`;
// 스토리 P 태그
export const StyledStory = styled.p`
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${(props) => props.line};
    display: -webkit-inline-box;
    padding-bottom: 0 !important;
    margin: 1rem 0 0;
    opacity: 0.64;
    font-size: 1.25rem;
    line-height: 1.4;
    word-break: keep-all;
    color: #fff;
    white-space: pre-wrap;

    @media ${device.mobile} {
        margin: 1rem 0 0;
        font-size: 1.083rem;
        line-height: 1.23em;
        ${({ isBlockLine }) =>
            isBlockLine &&
            css`
                max-height: 3.6em;
            `}
    }
`;

// 스토리 더보기 버튼
export const StyledStoryButton = styled.div`
    display: ${(props) => (props.hasMoreStory ? 'block' : 'none')};
    font-size: 1.167rem;
    line-height: 1.36;
    text-align: left;
    color: rgba(255, 255, 255, 0.64);
    padding-top: 1rem;
    font-weight: 400;

    button {
        &:hover {
            color: #fff;
        }
    }

    @media ${device.mobile} {
        padding-top: 0.667rem;
        button {
            font-size: 1rem;
            font-weight: 400;
            &.btn__small.arrow.arrow-down:after {
                background-position: 50% 55.5%;
            }
        }
    }
`;

export const StyledMobileActionWrap = styled.div`
    padding: 1.333rem 0 0 0;
`;
