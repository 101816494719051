import { create } from 'zustand';

interface OnboardingState {
    eventLink: string;
    setEventLink: (link: string) => void;
}

export const useOnboardingStore = create<OnboardingState>((set) => ({
    eventLink: '',
    setEventLink: (link) => set({ eventLink: link }),
}));
