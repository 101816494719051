import Link from 'next/link';
import { RunningTimeTag, ThumbnailImage, ThumbnailPlay, TvingOnlyTag, TvingOriginalTag } from '@components/item/ItemComponent';
import { NewAgeGradeTag } from '@components/content/CommonComponent';
import React from 'react';

const WatchingItem = ({
    href,
    idx,
    type, // 타입 - movie / episode
    title, // 타이틀
    subtitle, // 서브타이틀
    thumbnail, // 썸네일 이미지
    isAdult, // 성인인증 필요 여부
    gradeCode, // 시청 가능 연령코드
    ageGrade, // 시청 가능 연령
    isExclusive,
    isOriginal,
    duration, // 시청 퍼센트 계산 - 영상 전체 시간
    lastPlayTime, // 시청 퍼센트 계산 - 시청 시간
    isLazy,
    isEvent,
    isFree,
    onClick,
}) => {
    const watchPercent = lastPlayTime ? (100 * lastPlayTime) / duration : 0;

    return (
        <Link scroll href={href} passHref>
            {/* 컨텐츠(포스터)전체S:.item 단위 */}
            <a onClick={onClick}>
                <div className="item__thumb">
                    <div className="item__tags">
                        <div className="item__tags-left">
                            {/* 이벤트 라벨 */}
                            {/* {isEvent && <EventLabel />}
                            {isFree && <FreeLabel />} */}
                        </div>
                        {/* 연령 태그 - 프로그램은 19세, 영화는 18세 콘텐츠에 한해 연령등급 표시 */}
                        {(gradeCode === 'CPTG0500' || gradeCode === 'CMMG0400') && (
                            <div className="item__tags-right">
                                <NewAgeGradeTag gradeCode={gradeCode} />
                            </div>
                        )}

                        {/* 티빙 오리지널 태그 */}
                        {isOriginal && <TvingOriginalTag />}

                        {/* 티빙 온리 태그 */}
                        {!isOriginal && isExclusive && <TvingOnlyTag />}
                    </div>
                    {/* 컨텐츠(포스터) */}
                    <ThumbnailImage thumbnail={thumbnail} title={title} isLazy={isLazy} />
                    {/* 썸네일 플레이 버튼 */}
                    <ThumbnailPlay isVertical />
                    {/* 시청 퍼센트 */}
                    <RunningTimeTag className="item__progress" watchPercent={watchPercent}>
                        <span className="item__progress-bar" />
                    </RunningTimeTag>
                </div>

                {/* <!--컨텐츠 정보S--> */}
                <div className="item__info continue">
                    <p className="item__title">{title}</p>
                    {type === 'episode' && <p className="item__subtitle">{subtitle}</p>}
                </div>
            </a>
        </Link>
    );
};

export default WatchingItem;
