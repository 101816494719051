import { URL_TVING } from '@constants/urls';

export enum KBO_HOME_TAB {
    HOME = 'home',
    VIDEO = 'video',
    SCHEDULE = 'schedule',
    HISTORY = 'history',
}

type KBO_HOME_TAB_KEY = keyof typeof KBO_HOME_TAB;

interface KboHomeTab {
    [key: string]: {
        // key: keyof typeof KBO_HOME_TAB;
        // key: KBO_HOME_TAB[keyof typeof KBO_HOME_TAB];
        key: string;
        path: string;
        text: string;
        isHighlighted: (nextRoute: string) => boolean;
    };
}

/**
 * [!] 스포츠 통합 전환 이후에도 구 KBO GNB가 노출되는 영역이 있습니다.
 * - 선수 상세, 구단 상세 등
 * - 해당 페이지에서 GNB 내 링크 클릭 시 스포츠 통합으로 이동하도록 해야 합니다.
 * @see {@link https://tving.atlassian.net/browse/TVING-13200}
 */
export const KBO_HOME_TAB_DATA: KboHomeTab = {
    HOME: {
        key: 'home',
        path: '/sports/kbo',
        text: '홈',
        isHighlighted: (nextRoute) => {
            return nextRoute === '/kbo/home';
        },
    },
    VIDEO: {
        key: 'video',
        path: '/sports/kbo/video',
        text: '영상',
        isHighlighted: (nextRoute) => {
            return nextRoute === '/kbo/home/video';
        },
    },
    SCHEDULE: {
        key: 'schedule',
        path: '/sports/kbo/schedule',
        text: '일정',
        isHighlighted: (nextRoute) => {
            return nextRoute === '/kbo/home/schedule';
        },
    },
    HISTORY: {
        key: 'history',
        path: '/sports/kbo/history',
        text: '순위/기록',
        isHighlighted: (nextRoute) => {
            return nextRoute === '/kbo/home/history';
        },
    },
};

export enum KBO_CONTENTS_TAB {
    POWER = 'POWER',
    LINEUP = 'LINEUP',
    BROADCAST = 'BROADCAST',
    VIDEO = 'VIDEO',
    SCORE = 'SCORE',
    CHAT = 'CHAT',
    TALK = 'TALK',
    RECORD = 'RECORD',
}

export const KBO_CONTENTS_TAB_DATA = {
    POWER: {
        path: 'power',
        name: '전력',
    },
    LINEUP: {
        path: 'lineup',
        name: '라인업',
    },
    BROADCAST: {
        path: 'broadcast',
        name: '중계',
    },
    VIDEO: {
        path: 'video',
        name: '영상',
    },
    SCORE: {
        path: 'score',
        name: '득점',
    },
    TALK: {
        path: 'talk',
        name: '티빙톡',
    },
    // CHAT: {
    //     path: 'chat',
    //     name: '티빙톡 origin',
    // },
    RECORD: {
        path: 'record',
        name: '기록',
    },
};

export enum KBO_HOME_HISTORY_TAB {
    TEAM = 'TEAM',
    PLAYER = 'PLAYER',
}

export const KBO_HOME_HISTORY_TABS = [
    {
        key: KBO_HOME_HISTORY_TAB.TEAM,
        value: 'team',
        name: '팀 순위',
    },
    {
        key: KBO_HOME_HISTORY_TAB.PLAYER,
        value: 'player',
        name: '개인 순위',
    },
] as const;

export const KBO_HOME_RAKING_INITIAL_TAB = KBO_HOME_HISTORY_TABS[0];

export enum KBO_HOME_HISTORY_PLAYER_TYPE_TAB {
    PITCHER = 'PITCHER',
    HITTER = 'HITTER',
}

export const KBO_HOME_HISTORY_PLAYER_TYPE_TABS = [
    {
        key: KBO_HOME_HISTORY_PLAYER_TYPE_TAB.PITCHER,
        name: '투수',
        value: 'pitcher',
    },
    {
        key: KBO_HOME_HISTORY_PLAYER_TYPE_TAB.HITTER,
        name: '타자',
        value: 'hitter',
    },
] as const;

export const KBO_HOME_HISTORY_PLAYER_TYPE_INITIAL_TABS = KBO_HOME_HISTORY_PLAYER_TYPE_TABS[0];

export const KBO_PAGE_SEO_META = {
    home: {
        link: `${URL_TVING}/kbo/home`,
        pathname: '/kbo/home',
        title: '2025 KBO 리그 홈 | TVING',
        description: '2025 KBO 리그 홈',
        image: '/img/kbo/meta/kbo_meta_share.png',
    },
    video: {
        link: `${URL_TVING}/kbo/home/video`,
        pathname: '/kbo/home/video',
        title: '2025 KBO 리그 영상 | TVING',
        description: '2025 KBO 리그 영상',
        image: '/img/kbo/meta/kbo_meta_share.png',
    },
    schedule: {
        link: `${URL_TVING}/kbo/home/schedule`,
        pathname: '/kbo/home/schedule',
        title: '2025 KBO 리그 일정 | TVING',
        description: '2025 KBO 리그 일정',
        image: '/img/kbo/meta/kbo_meta_share.png',
    },
    history: {
        link: `${URL_TVING}/kbo/home/history`,
        pathname: '/kbo/home/history',
        title: '2025 KBO 리그 순위/기록 | TVING',
        description: '2025 KBO 리그 순위/기록',
        image: '/img/kbo/meta/kbo_meta_share.png',
    },
    more: {
        video: {
            link: `${URL_TVING}/kbo/home/redirect/more/video`,
            pathname: '/kbo/home/redirect/more/video',
            title: '2025 KBO 영상 더보기 | TVING',
            description: '2025 KBO 리그 영상 더보기',
            image: '/img/kbo/meta/kbo_meta_share.png',
        },
        schedule: {
            link: `${URL_TVING}/kbo/home/redirect/more/schedule/playlist`,
            pathname: '/kbo/home/redirect/more/schedule/playlist',
            title: '2025 KBO 영상 더보기 | TVING',
            description: '2025 KBO 리그 영상 더보기',
            image: '/img/kbo/meta/kbo_meta_share.png',
        },
    },
};
