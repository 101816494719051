import React, { useEffect, useState } from 'react';

import IconArrowDownBold from '@img/chat/icon_arrow_down_bold.svg';
import TvingLogo from '@img/chat/logo_tving_white.svg';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import { BaseMessage, UserMessage } from '@sendbird/chat/message';
import useCrossPlatform from '@utils/crossPlatform/react/useCrossPlatform';
import { useChatConfig } from '@tving/utils/src/contexts/ChatContext';
import supportAppWidthSendbird from '../../../supports/supportAppWithSendbird';
import { isWebview } from '@utils/device/device-detect';

const styles = {
    limitedLines: 'max-h-[calc(2.833rem+0.667rem)] ',
    normalLines: 'max-h-auto',
};

type Props = {
    item: BaseMessage;
};

const PinnedMessageCell = ({ item }: Props) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isHide, setIsHide] = useState(false);
    const crossPlatformController = useCrossPlatform();
    const { hiddenPinnedMessages } = useChatConfig();

    const handleCollapseButtonClick = () => {
        setIsCollapsed((prev) => !prev);
    };

    const hidePinnedMessageHandler = () => {
        crossPlatformController.moderateTvingTalk({
            action: 'hidePinnedMessage',
            id: item.messageId,
        });
        localStorage.setItem(`isHidePinndedMessage_${item.messageId}`, 'true');
        setIsHide(true);
    };

    useEffect(() => {
        try {
            if (isWebview && supportAppWidthSendbird() && hiddenPinnedMessages && hiddenPinnedMessages[item.messageId]) {
                setIsHide(true);
                return;
            }
            setIsHide((prev) => typeof localStorage?.getItem(`isHidePinndedMessage_${item.messageId}`) === 'string');
        } catch (error) {
            return console.error(error);
        }
    }, [item, item.messageId, hiddenPinnedMessages]);

    if (isHide) {
        return null;
    }

    return (
        <div className={tempCnx('absolute z-50 w-full top-0 pt-[0.667rem] px-[0.667rem]', 'bg-gradient-to-t to-[#0F0F0F] from-[#0F0F0F]/0')}>
            <div className="h-auto pt-[1rem] px-[1.333rem] pb-[1.333rem]  border border-solid border-[#ffffff]/20 rounded-[0.333rem] bg-[#191919] transition-all">
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-[0.333rem]">
                        <TvingLogo className={tempCnx('w-[4rem] min-w-[4rem] h-[0.98825rem] min-h-[0.98825rem] shrink-0')} />
                    </div>
                    <div className="flex items-center">
                        <button type="button" className="flex items-center" onClick={handleCollapseButtonClick}>
                            <IconArrowDownBold className={tempCnx(' transition-all', isCollapsed ? 'rotate-0' : 'rotate-180')} />
                        </button>
                    </div>
                </div>
                <div
                    className={tempCnx(
                        'overflow-hidden mt-[0.5rem] ',
                        'transition-all duration-1000',
                        isCollapsed ? styles.limitedLines : styles.normalLines,
                    )}
                >
                    <p className={tempCnx('text-[1.167rem] leading-[1.5rem] whitespace-pre-wrap', isCollapsed ? 'line-clamp-2' : 'line-clamp-none')}>
                        {(item as UserMessage).message}
                    </p>
                    {isCollapsed ? null : (
                        <div className="flex justify-end">
                            <button type="button" className="mt-[1.333rem] text-[1.167rem] text-[#999]" onClick={hidePinnedMessageHandler}>
                                다시 보지 않기
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PinnedMessageCell;
