import Link from 'next/link';
import { BuyEachLabel, FirstReleaseLabel, ThumbnailImage } from '@components/item/ItemComponent';
import useTranslation from 'next-translate/useTranslation';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDeleteList } from '@store/state';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { NewAgeGradeTag } from '@components/content/CommonComponent';

const ItemATag = styled.a`
    cursor: pointer;
`;
const PurchaseItem = ({
    href,
    idx,
    ratio,
    type,
    bandType,
    title,
    subtitle,
    code,
    contentType,
    thumbnail,
    watchThumbnail, // 구매한 에피소드/영화 썸네일
    grade,
    isAdult,
    isExclusive,
    isOriginal,
    isSubtitle, // 자막여부
    isDubbing, // 더빙여부
    gradeCode, // 연령 등급 코드
    ageGrade, // 시청 가능 연령
    episodeGradeCode, // 에피소드 시청 연령코드
    duration, // 상영시간
    purchaseDate, // 구매일
    expireDate, // 유효기간
    price, // 결제 금액
    genre, // 장르
    isLazy,
    isEditMode,
    isSelected,
    chargeNo, // 구매번호 (고유번호)
    remainDayStr, // 스트리밍or다운로드 남은 일자
    billingTag, // 빌링 관련 태그 - single=개별구매
    isFirstRelease, // 최초개봉 여부
    onClick,
}) => {
    const route = useRouter();
    const [deleteList, setDeleteList] = useDeleteList(null);
    const { t } = useTranslation('common');
    const ratioClassName = ratio ? `item__thumb-${ratio}` : '';
    const isMovie = bandType === 'moviePurchase';
    const purchaseDateStr =
        (purchaseDate && moment(`${purchaseDate}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD a hh:mm'))
            .replace('am', t('date.time.am'))
            .replace('pm', t('date.time.pm')) || ''; // 구매일
    const expireDateStr =
        (expireDate && moment(`${expireDate}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD a hh:mm'))
            .replace('am', t('date.time.am'))
            .replace('pm', t('date.time.pm')) || ''; // 유효기간
    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
        setIsClicked(false); // 모드가 변경될 때 선택 지우기
    }, [isEditMode]);

    useEffect(() => {
        const idx = deleteList.indexOf(chargeNo);
        if (idx > -1) {
            setIsClicked(true); // 모드가 변경될 때 선택 지우기
        } else {
            setIsClicked(false);
        }
    }, [deleteList]);

    const itemClick = () => {
        isSelected = true;

        if (!isClicked) {
            setDeleteList([...deleteList, chargeNo]);
            setIsClicked(true);
        } else {
            const idx = deleteList.indexOf(chargeNo);
            const resultList = Object.assign(deleteList);
            if (idx > -1) {
                setDeleteList(null);
                resultList.splice(idx, 1);
                setDeleteList(resultList);
                setIsClicked(false);
            }
        }
    };

    const moveLink = e => {
        if (!isEditMode) {
            // 수정모드 아닌경우 재생 화면으로 이동
            // route.push(href);
            onClick?.(e);
        } else {
            // 수정일때 링크 동작 안하게.
            e.preventDefault();
            e.stopPropagation();
            // 수정모드인 경우 아이템 클릭
            itemClick();
        }
    };
    return (
        <>
            {/* 컨텐츠(포스터)전체S:.item 단위 */}
            <div className="item item__column">
                <Link scroll href={href} passHref>
                    <ItemATag className={`${isEditMode && 'active'}`} onClick={e => moveLink(e)}>
                        <div className={`item__thumb ${ratioClassName}`}>
                            <div className="item__tags">
                                <div className="item__tags-left">
                                    {/* 개별구매 라벨 */}
                                    {billingTag === 'single' && <BuyEachLabel />}
                                    {/* 최초개봉 라벨 */}
                                    {isFirstRelease && <FirstReleaseLabel />}
                                </div>
                                {/* 연령 등급 라벨 태그 - 18세, 19세 콘텐츠에 한해 연령등급 표시 */}
                                {isMovie && (gradeCode === 'CPTG0500' || gradeCode === 'CMMG0400') ? (
                                    <div className="item__tags-right">
                                        <NewAgeGradeTag gradeCode={gradeCode} />
                                    </div>
                                ) : null}
                                {!isMovie && (episodeGradeCode === 'CPTG0500' || episodeGradeCode === 'CMMG0400') ? (
                                    <div className="item__tags-right">
                                        <NewAgeGradeTag gradeCode={episodeGradeCode} />
                                    </div>
                                ) : null}
                            </div>

                            {/* 컨텐츠(포스터) */}
                            <ThumbnailImage thumbnail={watchThumbnail} title={title} isLazy={isLazy} />
                            {isEditMode && (
                                <div className="thumb_summary">
                                    <button className={`btn__check ${isClicked && 'click_on'}`} />
                                </div>
                            )}
                        </div>
                        {/* </Link> */}
                        <div className="item__info">
                            <p className="item__title" css={{ color: '#dedede !important', fontWeight: '700 !important' }}>
                                {`${isSubtitle ? `[자막] ${title}` : isDubbing ? `[더빙] ${title}` : title}`}
                            </p>
                            {isMovie && (
                                <span className="item__subtitle">
                                    {genre} ⎮ {parseInt(duration / 60) + t('content.time.minutes')}
                                </span>
                            )}

                            <p className="item__subtitle-strong" css={{ fontWeight: '700 !important' }}>
                                {remainDayStr}
                            </p>

                            {price && (
                                <dl className="item__subinfo layout__flex">
                                    <dt>{t('my.payment')}</dt>
                                    <dd>{price} 원</dd>
                                </dl>
                            )}
                            {purchaseDateStr && (
                                <dl className="item__subinfo layout__flex">
                                    <dt>{t('my.purchase_date')}</dt>
                                    <dd>{purchaseDateStr}</dd>
                                </dl>
                            )}
                            {expireDate && (
                                <dl className="item__subinfo layout__flex">
                                    <dt>{t('my.expire_date')}</dt>
                                    <dd>{expireDateStr}</dd>
                                </dl>
                            )}
                        </div>
                    </ItemATag>
                </Link>
            </div>
        </>
    );
};
export default PurchaseItem;
