import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { size, device } from '@styles/device';

// itemSize: 25x36, 16x9, 1x1, 200x87, 200x10, 10x1
// <StyledItemThumb direction = "16x9"/>
const handleItemSize = itemSize => {
    // eslint-disable-next-line default-case
    switch (itemSize) {
        case '25x36':
            return css`
                &:before {
                    padding-top: 144%;
                }
            `;
        case '16x9':
            return css`
                picture.error:after {
                    background-size: 32%;
                }

                &:before {
                    padding-top: 56.25%;
                }
            `;
        case '1x1':
            return css`
                &:before {
                    padding-top: 100%;
                }
            `;
        case '200x87':
            return css`
                &:before {
                    padding-top: 43.5%;
                }
            `;
        case '200x10':
            return css`
                &:before {
                    padding-top: 33.333%;
                }
            `;
        case '10x1':
            return css`
                &:before {
                    padding-top: 10%;
                }
            `;
    }
};

export const StyledItemThumb = styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.13);
    transition: box-shadow 300ms ease-in-out;
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0), 0 0 5px 0 rgba(0, 0, 0, 0);
    will-change: box-shadow;

    img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &:before {
        content: '';
        display: block;
        padding-top: 144%;
        width: 100%;
        float: left;
        box-sizing: inherit;
    }
    &:after {
        content: '';
        display: block;
        clear: both;
    }

    @media screen and(max-width: ${size.tablet}px) {
        border-radius: 3px;
    }

    ${({ itemSize }) => handleItemSize(itemSize)};
`;

// direction : left, right, down
// <StyledItemTagsDirection direction = "left"/>
const handleTagDirection = direction => {
    // eslint-disable-next-line default-case
    switch (direction) {
        case 'left':
            return css`
                left: 0.333rem;
            `;
        case 'right':
            return css`
                right: 0.333rem;
            `;
        case 'down':
            return css`
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 9.93%;
                display: flex;
                align-items: flex-end;
                font-size: 1.2em;
                padding-bottom: 10.16%;
                box-sizing: initial;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
                text-align: center;
                > span {
                    margin-bottom: 0;
                }

                /* @media (max-width: ${size.tablet}px) {
                    .is-mobile & {
                        padding-bottom: 6.48%;
                    }
                } */
            `;
    }
};

export const StyledItemTagsDirection = styled.div`
    ${({ direction }) => handleTagDirection(direction)};
    ${({ direction }) =>
        direction === 'left' || direction === 'right'
            ? css`
                  position: absolute;
                  top: 0.333rem;
                  flex-wrap: wrap;
                  gap: 0.2em;
                  white-space: nowrap;
              `
            : ``}
`;

// type : original, only
// <StyledTag type = "original"/>
const handleTagType = type => {
    // eslint-disable-next-line default-case
    switch (type) {
        case 'original':
            return css`
                padding: 0;
                border-radius: 0;
                display: inline-block;

                width: 100%;
                background-size: auto 100%;
                background-position: center;
                height: 57.64%;
                text-indent: -9999px;
                background-repeat: no-repeat;
                background-image: url('/img/tving-new-original.svg');

                @media (max-width: ${size.laptop}px) {
                    height: 66.89%;
                }
                @media (max-width: ${size.tablet}px) {
                    height: 68%;
                }
            `;

        case 'only':
            return css`
                padding: 0;
                border-radius: 0;
                display: inline-block;

                width: 100%;
                background-size: auto 100%;
                background-position: center;
                height: 57.64%;
                text-indent: -9999px;
                background-repeat: no-repeat;
                background-image: url('/img/tving-new-only.svg');

                @media (max-width: ${size.laptop}px) {
                    height: 66.89%;
                }
                @media (max-width: ${size.tablet}px) {
                    height: 68%;
                }
            `;
    }
};

// color: green, lightgreen, red, yellow, blue, quick, pink, blank
// <StyledTag color = "lightgreen"/>
const handleTagColor = color => {
    // eslint-disable-next-line default-case
    switch (color) {
        case 'green':
            return css`
                background-color: rgba(0, 155, 119, 0.9);
            `;
        case 'lightgreen':
            return css`
                background-color: rgba(12, 177, 38, 0.9);
            `;
        case 'red':
            return css`
                background-color: rgba(237, 47, 78, 0.9);
            `;
        case 'yellow':
            return css`
                background-color: rgba(255, 130, 38, 0.9);
            `;
        case 'blue':
            return css`
                background-color: rgba(23, 85, 255, 0.9);
            `;
        case 'quick':
            return css`
                background-color: rgba(237, 47, 78, 0.9);
            `;
        case 'pink':
            return css`
                background-color: rgba(242, 50, 227, 0.9);
            `;
        case 'blank':
            return css`
                box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.3);
                color: rgba(255, 255, 255, 0.7);
            `;
    }
};

// age: free, twelve, fifteen, eighteen, nineteen, all
// <StyledTag age = "nineteen"/>
const handleTagAge = age => {
    // eslint-disable-next-line default-case
    switch (age) {
        case 'free':
            return css`
                width: 2.3rem;
                height: 1.6rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16'%3E%3Cpath data-name='Path 4373' d='M1 0h21a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z' style='fill:%231755ff'/%3E%3Ctext transform='translate(4 11)' style='fill:%23fff;font-size:9px;font-family:AppleSDGothicNeo-Bold,Apple SD Gothic Neo;font-weight:700;letter-spacing:-.15em;opacity:.996'%3E%3Ctspan x='0' y='0'%3E무료%3C/tspan%3E%3C/text%3E%3C/svg%3E");
            `;
        case 'twelve':
            return css`
                background-image: url('/img/tag_12.svg');
            `;
        case 'fifteen':
            return css`
                background-image: url('/img/tag_15.svg');
            `;
        case 'eighteen':
            return css`
                background-image: url('/img/tag_18.svg');
            `;
        case 'nineteen':
            return css`
                background-size: 100%;
                background-image: url('/img/tag_19.svg');
            `;
        case 'all':
            return css`
                background-image: url('/img/tag_all.svg');
            `;
    }
};

export const StyledTagWrap = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
`;

export const StyledItemTag = styled.div`
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
`;

const Tag = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0em 0.5em;
    width: max-content;
    border-radius: 0.2em;
    height: 1.666rem;
    font-size: 0.917rem;
`;

export const StyledTag = styled(Tag)`
    ${({ type }) => handleTagType(type)}
    ${({ color }) => handleTagColor(color)}

    ${props =>
        props.age &&
        `background-repeat: no-repeat;
        background-position: 100%;
        background-size: contain;

        text-indent: -9999px;
        width: 1.666rem;
        height: 1.666rem;
        padding: 0;`}
    ${({ age }) => handleTagAge(age)}
`;

export const StyledBlindText = styled.span`
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
`;

export const StyledItemPlayBtn = styled.div`
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ dimm }) => (dimm ? 'rgba(0, 0, 0, 0.7);' : 'transparent')};

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({ isVertical }) => (isVertical ? '40.45%' : '26.56%')};
        height: 50%;
        background-image: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MiIgaGVpZ2h0PSI3MiIgdmlld0JveD0iMCAwIDcyIDcyIj4KICAgIDxkZWZzPgogICAgICAgIDxzdHlsZT4KICAgICAgICAgICAgLmNscy0xe2ZpbGw6bm9uZX0KICAgICAgICA8L3N0eWxlPgogICAgPC9kZWZzPgogICAgPGcgaWQ9IuyerOyDnSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy42MTMgNC40ODgpIj4KICAgICAgICA8cGF0aCBpZD0i7IKs6rCB7ZiVXzQzNzciIGRhdGEtbmFtZT0i7IKs6rCB7ZiVIDQzNzciIGNsYXNzPSJjbHMtMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMuNjEzIC00LjQ4OCkiIGQ9Ik0wIDBoNzJ2NzJIMHoiLz4KICAgICAgICA8ZyBpZD0i7YOA7JuQXzM3MyIgZGF0YS1uYW1lPSLtg4Dsm5AgMzczIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyLjM4NyAxLjUxMikiIHN0eWxlPSJmaWxsOnJnYmEoMCwwLDAsLjQzKTtzdHJva2U6I2ZmZjtzdHJva2Utd2lkdGg6MS41cHgiPgogICAgICAgICAgICA8Y2lyY2xlIGN4PSIzMCIgY3k9IjMwIiByPSIzMCIgc3R5bGU9InN0cm9rZTpub25lIi8+CiAgICAgICAgICAgIDxjaXJjbGUgY2xhc3M9ImNscy0xIiBjeD0iMzAiIGN5PSIzMCIgcj0iMjkuMjUiLz4KICAgICAgICA8L2c+CiAgICAgICAgPHBhdGggaWQ9IuuLpOqwge2YlV80IiBkYXRhLW5hbWU9IuuLpOqwge2YlSA0IiBkPSJNOS44My4yNzFhLjIuMiAwIDAgMSAuMzM5IDBsOS42MzkgMTUuNDIzYS4yLjIgMCAwIDEtLjE3LjMwNkguMzYxYS4yLjIgMCAwIDEtLjE3LS4zMDZ6IiB0cmFuc2Zvcm09InJvdGF0ZSg5MCAxMC42OTMgMzIuNjk0KSIgc3R5bGU9ImZpbGw6I2ZmZiIvPgogICAgPC9nPgo8L3N2Zz4K');
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;

        @media ${device.mobile} {
            width: ${({ isVertical }) => (isVertical ? '40.45%' : '15%')};
            height: 100%;
        }

        ${({ isOnlyMobile }) =>
            isOnlyMobile
                ? css`
                      .is-laptop & {
                          display: none;
                      }
                  `
                : css``}
    }
`;
