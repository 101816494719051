import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';

/**
 * 해당 함수는 TVING GNB, KBO GNB 의 높이값 및 반응형 동작이 가변적이기 때문에 작성한 임시 함수입니다.
 * 다양한 요소가 하나의 흐름으로 동작하기때문에 예외적으로 스타일을 관리하게 됐습니다.
 *
 * TODO: 추후 tailwind.config.js 또는 global.css 등에 지정된 상수를 바탕으로 각 사용처에서 다시 정의하게 하는게 올바른 방향이라고 생각합니다.
 * TODO: 공통으로 사용되는 로직의 오염을 막기 위해 재정의 하는 방식을 택했지만, 협의 해본 뒤 수정하도록 하겠습니다.
 */
const getKboContentsExceptionalStyles = ({
    isKboContents,
    isMobile,
    isWebview,
}: {
    isKboContents: boolean;
    isMobile: boolean;
    isWebview: boolean;
}) => {
    if (!isKboContents) {
        return '';
    }

    if (isWebview) {
        return tempCnx(
            '[&_.kbo-header-layout]:hidden', // GNB
            '[&_.kbo-main]:mt-0', // Kbo main
            '[&_.kbo-player]:top-0', // Kbo player
            '[&_.kbo-main-section]:mt-0', // Kbo main section
            '[&_.kbo-contents-tab]:top-[var(--kbo-player-height)_+_var(--kbo-player-companion-banner-height)]', // Contents tab
            '[&_.kbo-inning-tab]:top-[calc(var(--kbo-player-height)_+_var(--kbo-player-companion-banner-height)_+_4.25rem)]', // Contents innings

            // '[&_.kbo-contents-main]:landscape:h-max',
            '[&_.kbo-contents-main]:landscape:overflow-y-scroll',

            '[&_.kbo-contents-main]:landscape:h-screen', // Tab area
            '[&_.kbo-contents-tab-area]:landscape:h-[var(--kbo-contents-main-height)]', // Tab area
            '[&_.kbo-contents-tab]:top-[calc(var(--kbo-player-height)_+_var(--kbo-player-companion-banner-height)_-_0.1rem)] [&_.kbo-contents-tab]:landscape:top-0', // Contents tab
            '[&_.kbo-inning-tab]:top-[calc(var(--kbo-player-height)_+_var(--kbo-player-companion-banner-height)_+_4.1rem)] [&_.kbo-inning-tab]:landscape:top-[4.24rem]', // Contents innings
            '[&_.kbo-notify-update]:top-[calc(var(--kbo-player-height)_+_var(--kbo-player-companion-banner-height)_+_9.25rem)] [&_.kbo-notify-update]:landscape:top-[9.35rem] ', // Contents innings

            '[&_.simplebar-scrollbar]:!hidden',

            // ignore safe area
            '!pb-0',
        );
    }

    if (isMobile) {
        return tempCnx(
            'portrait:[&_.kbo-header]:flex ',
            '[&_.kbo-home-tab]:hidden', // GNB
            '[&_.kbo-main-section]:mt-0', // Kbo main section
            '[&_.kbo-player]:static',
            '[&_.kbo-player-dummy]:hidden',
            '[&_.kbo-contents-tab-area]:landscape:h-screen', // Tab area
            '[&_.kbo-contents-tab]:static', // Contents tab
            '[&_.kbo-contents-sub-tab]:static', // Contents tab
            '[&_.kbo-inning-tab]:top-[calc(var(--kbo-player-height)_+_var(--kbo-player-companion-banner-height)_+_12.8rem)] [&_.kbo-inning-tab]:landscape:top-[4.24rem]', // Contents innings
        );
    }

    // isDesktop
    return tempCnx(
        '[&_.kbo-home-tab]:flex [&_.kbo-header]:flex', // GNB
        'portrait:[&_.kbo-header]:bg-black',
        '[&_.kbo-main]:mt-[8.66rem]', // Kbo main
        '[&_.kbo-main-section]:mt-0', // Kbo main section

        '[&_.kbo-player]:top-[8.66rem]',

        '[&_.kbo-contents-tab-area]:landscape:h-[calc(var(--kbo-contents-main-height)_-_8.667rem)]', // Tab area
        '[&_.kbo-contents-tab-area]:landscape:sticky [&_.kbo-contents-tab-area]:landscape:top-[8.667rem]', // Tab area

        '[&_.kbo-contents-tab]:top-[calc(var(--kbo-player-height)_+_var(--kbo-player-companion-banner-height)_+_8.4rem)] [&_.kbo-contents-tab]:landscape:top-0 ', // Contents tab
        '[&_.kbo-inning-tab]:top-[calc(var(--kbo-player-height)_+_var(--kbo-player-companion-banner-height)_+_12.5rem)] [&_.kbo-inning-tab]:landscape:top-[4.2rem] ', // Contents innings
        '[&_.kbo-notify-update]:top-[calc(var(--kbo-player-height)_+_var(--kbo-player-companion-banner-height)_+_17.7rem)] [&_.kbo-notify-update]:landscape:top-[9.4rem] ', // Contents innings
    );
};

export default getKboContentsExceptionalStyles;

// 3.667 rem
// 5 rem
