import useSWR, { SWRConfiguration } from 'swr';
import useSWRInfinite from 'swr/infinite';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { urlString } from '@utils/common/String';
import ApiSend from '@utils/common/ApiSend';

/**
 * 기본 SWR에서 Axios Abort 추가된 Function
 * @constructor
 * @param swrData - SWR [Key String,return value data].
 * @param fetchData - Axios Params Data.
 * @param swrOptions - SWR Options Data.
 * @return  data, source, isLoading, isError Value.
 */
import { apiProxy } from './proxy';
import { NullableString } from '@tving/utils/src/apis/types';

export interface SWRCustomResponse<T> {
    data: T;
    source: CancelTokenSource;
    isLoading: boolean;
    isError: boolean;
    mutate: (data?: T, shouldRevalidate?: boolean) => Promise<T | undefined>;
    isValidating: boolean;
}

/**
 * T: SWR Return Data Type
 * D: SWR Key Data Type
 * F: Fetch Callback Data Type
 *
 * @param swrData
 * @param fetchData
 * @param swrOptions
 * @param fetchCallback
 */
export const useAbortSWR = <T = unknown, F = unknown, D = NullableString>(
    swrData: D[] = [],
    fetchData = {} as AxiosRequestConfig,
    swrOptions = {} as SWRConfiguration,
    fetchCallback: ((data: F) => T) | null = null,
    isFetch = true,
): SWRCustomResponse<T> => {
    // axios CancelToken에서 source 추가
    const source = axios.CancelToken.source();

    // 기본 useSWR 생성
    const { data, error, mutate, isValidating } = useSWR<T>(
        swrData[0],
        async (url: string) => {
            if (!isFetch) {
                return null;
            }

            // Api Control에서 API 요청
            const { data: apiData } = await ApiSend({
                // URL 넘어온 값 추가
                url,
                // Axios Source의 token을 cancelToken에 전달.
                cancelToken: source.token,
                ...fetchData,
            });
            let proxyData = apiProxy(apiData, url);
            if (fetchCallback) {
                proxyData = fetchCallback(proxyData as F);
            }
            return proxyData;
        },
        // SWR Options.
        swrOptions,
    );

    return {
        data: data || ({} as T),
        source,
        isLoading: !error && !data,
        isError: error,
        mutate,
        isValidating,
    };
};

export interface SWRCustomInfiniteResponse<T> {
    data: T[];
    size: number;
    setSize: (size: number) => void;
    isLoading: boolean;
    isError: boolean;
    mutate: (data?: T[], shouldRevalidate?: boolean) => Promise<T[] | undefined>;
    isValidating: boolean;
}

/**
 * 기본 SWR에서 Axios Abort 추가된 Function.
 * 무제한 스크롤을 위한 SWRInfinite.
 */
export const useAbortSWRInfinite = <T = unknown, F = unknown>(
    key: string,
    fetchData = {} as AxiosRequestConfig,
    swrOptions = {},
    fetchCallback: ((data: F) => T) | null = null,
    pageParamName = 'pageNo',
): SWRCustomInfiniteResponse<T> => {
    // axios CancelToken에서 source 추가
    const source = axios.CancelToken.source();

    let modifiedKey = key;
    // 기본 Key Url에 들어가있는 `pageNo` 제거
    if (modifiedKey) {
        const keyObj = urlString.parse(modifiedKey);
        delete keyObj.pageNo;
        modifiedKey = urlString.stringify(keyObj);
    }

    // 기본 useSWR 생성
    const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite<T>(
        (index) => {
            return modifiedKey ? `${modifiedKey}&${pageParamName}=${index + 1}` : null;
        },
        async (url: string) => {
            // Api Control에서 API 요청
            const { data: apiData } = await ApiSend({
                // URL 넘어온 값 추가
                url,
                // Axios Source의 token을 cancelToken에 전달.
                cancelToken: source.token,
                ...fetchData,
            });
            let proxyData = apiProxy(apiData, url, fetchData);
            if (fetchCallback) {
                proxyData = fetchCallback(proxyData as F);
            }
            return proxyData;
            // return apiProxy(data, url, fetchData);
        },
        // SWR Options.
        { ...{ revalidateFirstPage: false }, ...swrOptions },
    );

    return {
        data: data || ({} as T[]),
        size,
        setSize,
        isLoading: !error && !data,
        isError: error,
        mutate,
        isValidating,
    };
};

const IMG_HOST = 'imagedev.tving.com';

export const imgOrigin = `https://${IMG_HOST}`;
