/** =======================
 *     CrossPlatformController와 별개로,
 *     컨트롤러 외부에서 여러 디바이스와 호환성을 유지하면서
 *     이벤트 리스너를 추가하고 제거하는 유틸리티 함수들을 제공합니다. (외부에서 사용하는 것도 고려함)
 *========================* */

import * as CrossPlatformEventListener from './crossPlatformEvents';

export default CrossPlatformEventListener;

export { type ViewportOrientation, type OrientationCallback, type ResizeCallback, type VisibilityCallback } from './crossPlatformEvents';
export type { RemoveListener } from './types';
