import { createContext } from 'react';

interface KboNavigationType {
    device: {
        isMobileWebview: boolean;
        isMobileWeb: boolean;
        isAndroid: boolean;
        isTablet: boolean;
    };
    screen: {
        isTopOfScroll: boolean;
        kboHomeBgOpacity: number;
    };
    toggleIsMobileWebview: () => void;
}

export const KboNavigationContext = createContext<KboNavigationType>({
    device: {
        isMobileWebview: false,
        isMobileWeb: false,
        isAndroid: false,
        isTablet: false,
    },
    screen: {
        isTopOfScroll: true,
        kboHomeBgOpacity: 0,
    },
    toggleIsMobileWebview: () => {},
});
