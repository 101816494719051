import throttle from 'lodash/throttle';

import { EventOptions, RemoveListener } from './types';

interface VisibilityEvent {
  isVisible: boolean;
}

interface VisibilityChangeOptions extends EventOptions {
  shouldEmitOnContentDetailClose?: boolean;
}

export type VisibilityCallback = (event: VisibilityEvent) => void;

/**
 * Webview 환경을 고려한 Cross Platform Visibility 이벤트 리스너
 */
export const addVisibilityChangeEvent = (callback: VisibilityCallback, options?: VisibilityChangeOptions): RemoveListener => {
  const handleVisibilityChange = throttle(
    (params: VisibilityEvent) => {
      callback?.(params);
    },
    undefined,
    {
      leading: true,
      trailing: true,
    },
  );

  const dispatchVisibilityChange = () => {
    handleVisibilityChange({
      isVisible: document.visibilityState === 'visible',
    });
  };

  const dispatchForeground = () => {
    handleVisibilityChange({
      isVisible: true,
    });
  };

  document.addEventListener('visibilitychange', dispatchVisibilityChange, options);
  window.addEventListener('onForeground', dispatchForeground, options);

  if (options?.shouldEmitOnContentDetailClose) {
    window.addEventListener('onDismissedContentDetail', dispatchForeground, options);
  }

  dispatchVisibilityChange();

  return () => {
    document.removeEventListener('visibilitychange', dispatchVisibilityChange, options);
    window.removeEventListener('onForeground', dispatchForeground, options);
    if (options?.shouldEmitOnContentDetailClose) {
      window.removeEventListener('onDismissedContentDetail', dispatchForeground, options);
    }
  };
};
