import React, {
    forwardRef,
    MutableRefObject,
    TouchEventHandler,
    UIEventHandler,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import ChatSection from '@components/chat/ChatSection';
import { BaseMessage } from '@sendbird/chat/message';
import { isMobile, isWebview } from '@utils/device/device-detect';
import { useChatConfig } from '@tving/utils/src/contexts/ChatContext';
import throttle from 'lodash.throttle';

import {
    DeleteMyMessageHandler,
    FetchLatestMessagesHandler,
    ReportMessageHandler,
} from '@tving/utils/src/hooks/common/chat/useChannelConnectionWithSendbird';
import ScrollToBottomButton from '@components/chat/Button/ScrollToBottomButton';
import { useInView } from 'react-intersection-observer';
import Scrollbars from 'react-custom-scrollbars-2';
import GlobalResizeObserver from '@utils/resizeObserver';
import useCrossPlatform from '@utils/crossPlatform/react/useCrossPlatform';
import { CrossPlatformModerateTvintTalkEvent } from '@utils/crossPlatform';
import { isIOS } from '@utils/device';
import chatCollection from '@components/chat/ChatCollection';

type Props = {
    getLatestMessages: FetchLatestMessagesHandler;
    getPreviousMessages: () => Promise<BaseMessage[] | null>;
    deleteMyMessage: DeleteMyMessageHandler;
    reportMessage: ReportMessageHandler;
    isInputFocus: boolean;
    messageCollection: BaseMessage[][];
    messageContainerRef: React.RefObject<HTMLDivElement>;
    scrollbarRef: React.RefObject<Scrollbars>;
    messageListBottomRef: React.RefObject<HTMLDivElement>;
};

// const color = ['red', 'blue', 'green', 'black', 'purple', 'magenta', 'cyan', 'gray', 'darkgray', 'olive', 'navy', 'violet', 'brown'];

type ChatSectionsProps = {
    messageCollection: BaseMessage[][];
    getPreviousMessages: () => Promise<BaseMessage[] | null>;
    getLatestMessages: FetchLatestMessagesHandler;
    messageContainerRef: React.RefObject<HTMLDivElement>;
    deleteMyMessage: DeleteMyMessageHandler;
    reportMessage: ReportMessageHandler;
    isPrevLoading: boolean;
    setIsPrevLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChatSections = forwardRef<Scrollbars, ChatSectionsProps>(
    (
        {
            messageCollection,
            getPreviousMessages,
            deleteMyMessage,
            reportMessage,
            messageContainerRef,
            getLatestMessages,
            isPrevLoading,
            setIsPrevLoading,
        },
        scrollbarRef,
    ) => {
        const tailChatSectionHeightRef = useRef<number>(0);
        const { isBottom, setIsBottom, textareaRef } = useChatConfig();
        const messageSectionsRef = useRef(null);

        useEffect(() => {
            const handleResize: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
                entries.forEach((entry) => {
                    const current = (scrollbarRef as MutableRefObject<Scrollbars>)?.current;

                    if (isBottom) {
                        const viewElement = current?.view;
                        if (!viewElement) {
                            return;
                        }
                        viewElement.style.overflow = 'hidden';

                        setTimeout(() => {
                            viewElement.style.overflow = 'auto';
                            current?.scrollToBottom();
                            setIsPrevLoading(false);
                        }, 0);
                    }
                });
            };

            // ResizeObserver 인스턴스 생성
            const resizeObserver = new ResizeObserver(handleResize);
            const element = messageSectionsRef.current;

            // 현재 참조하고 있는 요소에 ResizeObserver를 관찰하도록 설정
            if (element) {
                resizeObserver.observe(element);
            }

            // 컴포넌트 언마운트 시 ResizeObserver의 관찰을 중지하고 메모리 누수 방지
            return () => {
                if (element) {
                    resizeObserver.unobserve(element);
                }
                resizeObserver.disconnect(); // ResizeObserver 해제
            };
        }, [isBottom, scrollbarRef]);

        const onScrollFrame = useCallback(
            async (values) => {
                if (values.scrollHeight - (values.scrollTop + values.clientHeight) > values.clientHeight / 2) {
                    setIsBottom(false);
                } else {
                    setIsBottom(true);
                }

                if (values.scrollTop <= 1) {
                    const current = (scrollbarRef as MutableRefObject<Scrollbars>)?.current;
                    const prevElement = current?.view?.children[0];

                    if (isPrevLoading) {
                        return;
                    }

                    setIsPrevLoading(true);
                    await getPreviousMessages();
                    let isHeightCheck = true;

                    const scrollUpdate = () => {
                        if (prevElement.offsetTop > 0 && isHeightCheck) {
                            current.scrollTop(prevElement.offsetTop);
                            setIsPrevLoading(false);
                            isHeightCheck = false;
                        }
                    };
                    scrollUpdate();

                    // MutationObserver 생성
                    const containerElement = current.container;
                    const observer = new MutationObserver((mutations) => {
                        mutations.forEach(() => {
                            // 스크롤 위치를 조정하여 깜빡임 방지
                            scrollUpdate();
                            observer.disconnect(); // 스크롤 조정 후 observer 비활성화
                        });
                    });

                    // DOM 변경 감지 시작
                    observer.observe(containerElement, { childList: true, subtree: true });
                }
            },
            [getPreviousMessages, isPrevLoading, scrollbarRef, setIsBottom],
        );

        const onTouchStart: TouchEventHandler = (event) => {
            textareaRef.current?.blur();
        };

        useEffect(() => {
            const current = (scrollbarRef as MutableRefObject<Scrollbars>)?.current;

            if (isBottom) {
                const viewElement = current?.view;
                if (!viewElement) {
                    return;
                }
                viewElement.style.overflow = 'hidden';

                setTimeout(() => {
                    viewElement.style.overflow = 'auto';
                    current?.scrollToBottom();
                    setIsPrevLoading(false);
                }, 0);
            }
        }, [messageCollection, isBottom, scrollbarRef]);

        useEffect(() => {
            if (isBottom) {
                void getLatestMessages();
            }
        }, [getLatestMessages, isBottom]);

        const handleChatListScroll: UIEventHandler = (e) => {
            try {
                const targetElement = e.target as HTMLUListElement;

                if (targetElement.scrollTop + targetElement.offsetHeight >= targetElement.scrollHeight) {
                    targetElement.style.overscrollBehavior = 'auto';
                    targetElement.style.overflow = 'scroll';
                } else {
                    targetElement.style.overscrollBehavior = 'none';
                    targetElement.style.overflow = 'scroll';
                    e.preventDefault();
                    e.stopPropagation();
                }

                if (window.matchMedia('(orientation: portrait)').matches) {
                    document.body.style.overscrollBehavior = 'none';
                    document.documentElement.style.overscrollBehavior = 'none';
                } else {
                    document.body.style.overscrollBehavior = 'auto';
                    document.documentElement.style.overscrollBehavior = 'auto';
                }
            } catch (error) {
                console.log('#sb', 'error', error);
            }
        };

        useEffect(() => {
            return () => {
                document.body.style.overscrollBehavior = 'auto';
                document.documentElement.style.overscrollBehavior = 'auto';
                document.getElementById('__next')!.style.overscrollBehavior = 'auto';
            };
        }, []);

        const renderView = ({ style, ...props }) => {
            const customStyle = {
                overscrollBehavior: 'none',
            };
            return <div {...props} className="scrollbar-hidden" style={{ ...style, ...customStyle }} />;
        };

        return (
            <div
                className={tempCnx(
                    'flex flex-col',
                    ' relative',
                    'w-full h-full',
                    isWebview ? '' : 'landscape:[&>div>div]:overscroll-y-none landscape:[&>div>div]:!m-0',
                )}
                // ref={messageContainerRef}
                ref={messageSectionsRef}
                role="list"
                // onTouchStart={onTouchStart}
                onScroll={handleChatListScroll}
                onTouchMove={handleChatListScroll}
            >
                <Scrollbars ref={scrollbarRef} renderView={renderView} onScrollFrame={onScrollFrame} universal>
                    {messageCollection?.map((messageSection, index) => (
                        <ChatSection
                            key={messageSection?.[0]?.messageId}
                            messageSection={messageSection}
                            deleteMyMessage={deleteMyMessage}
                            reportMessage={reportMessage}
                        />
                    ))}
                    <div className={tempCnx('shrink-0 h-[0.583rem]')} />
                </Scrollbars>
            </div>
        );
    },
);
const ChatCollection = ({
    getLatestMessages,
    getPreviousMessages,
    deleteMyMessage,
    reportMessage,
    isInputFocus,
    messageCollection,
    messageContainerRef,
    scrollbarRef,
    messageListBottomRef,
}: Props) => {
    const { isInputMultiline, isBottom } = useChatConfig();
    const bottomPositionRef = useRef<HTMLDivElement>(null);
    const [isPrevLoading, setIsPrevLoading] = useState(false);

    const handleScrollToBottom = async () => {
        const viewElement = scrollbarRef.current.view;
        if (!viewElement) {
            return;
        }
        // ios Safari 에서는 overflow: auto를 주면 스크롤이 안되는 문제가 있어서, overflow: hidden을 주고 스크롤 이동 후 다시 auto로 변경
        viewElement.style.overflow = 'hidden';

        await getLatestMessages();

        setTimeout(() => {
            if (!scrollbarRef.current) {
                return;
            }
            viewElement.style.overflow = 'auto';
            scrollbarRef.current?.scrollToBottom();
            setIsPrevLoading(false);
        }, 0);
    };

    useEffect(() => {
        // if (messageCollection.length <= 2) {
        setTimeout(() => {
            const viewElement = scrollbarRef.current?.view;
            if (!viewElement) {
                return;
            }
            viewElement.style.overflow = 'hidden';

            setTimeout(() => {
                viewElement.style.overflow = 'auto';
                scrollbarRef.current?.scrollToBottom();
                setIsPrevLoading(false);
            }, 0);
        }, 10);
        // }
    }, [scrollbarRef]);

    return (
        <div
            className={tempCnx(
                'relative overflow-hidden grow',
                isWebview && isIOS() ? 'overflow-auto' : '',
                !isInputMultiline && !isWebview ? 'pb-[4.417rem] landscape:pb-0' : 'pb-[5.75rem] landscape:pb-0',
                isWebview
                    ? isInputFocus
                        ? !isInputMultiline
                            ? 'pb-[4.417rem]'
                            : 'pb-[5.75rem]'
                        : !isInputMultiline
                        ? 'pb-[calc(4.417rem_+_var(--safe-area-inset-bottom))]'
                        : 'pb-[calc(5.75rem_+_var(--safe-area-inset-bottom))]'
                    : '',
            )}
        >
            <ChatSections
                getLatestMessages={getLatestMessages}
                getPreviousMessages={getPreviousMessages}
                messageCollection={messageCollection}
                isInputFocus={isInputFocus}
                ref={scrollbarRef}
                messageContainerRef={messageContainerRef}
                messageListBottomRef={messageListBottomRef}
                deleteMyMessage={deleteMyMessage}
                reportMessage={reportMessage}
                isPrevLoading={isPrevLoading}
                setIsPrevLoading={setIsPrevLoading}
            />

            {/* <div */}
            {/*    className={tempCnx( */}
            {/*        'flex flex-col', */}
            {/*        // 'overflow-y-scroll relative', */}
            {/*        ' relative', */}
            {/*        'w-full h-full', */}
            {/*        // 'pb-[calc(env(safe-area-inset-bottom))]', */}
            {/*        // isMobile || isWebview */}
            {/*        //     ? !isInputMultiline */}
            {/*        //         ? 'pb-[calc(env(safe-area-inset-bottom)+4.417rem)]' */}
            {/*        //         : 'pb-[calc(env(safe-area-inset-bottom)+5.75rem)]' */}
            {/*        //     : '', */}
            {/*        // 'pb-[calc(env(safe-area-inset-bottom)+4.417rem)]', */}
            {/*        // 'w-full h-[calc(100vh-calc(var(--kbo-player-height)+4.167rem))] ', */}
            {/*        // 'landscape:h-[calc(100%-4.75rem)]', */}
            {/*    )} */}
            {/*    ref={messageContainerRef} */}
            {/*    role="list" */}
            {/* > */}
            {/*    /!*<div className={tempCnx('relative h-1 shrink-0 ')} ref={messageListTopRef}></div>*!/ */}
            {/*    /!*<div className={tempCnx('relative h-1 shrink-0 ')} ref={target}></div>*!/ */}

            {/*    <Scrollbars ref={scrollbarRef} onScrollFrame={onScrollFrame} universal> */}
            {/*        {messageCollection?.map((messageSection, index) => ( */}
            {/*            <div key={messageSection?.[0]?.messageId}> */}
            {/*                <div className={tempCnx(`previous-chat-list-top-position ${messageSection?.[0]?.messageId}`, 'shrink-0 h-[8px]')} /> */}
            {/*                <ChatSection */}
            {/*                    key={messageSection?.[0]?.messageId} */}
            {/*                    messageSection={messageSection} */}
            {/*                    deleteMyMessage={deleteMyMessage} */}
            {/*                    reportMessage={reportMessage} */}
            {/*                /> */}
            {/*            </div> */}
            {/*        ))} */}
            {/*    </Scrollbars> */}
            {/*    /!*<div className={tempCnx('shrink-0 h-[0.583rem]')} ref={messageListBottomRef} />*!/ */}
            {/* </div> */}
            {!isBottom ? <ScrollToBottomButton container={bottomPositionRef.current} handler={handleScrollToBottom} /> : null}
        </div>
    );
};

export default ChatCollection;
