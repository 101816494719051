import { useState, useEffect } from 'react';

const useDomLoaded = () => {
    const [domLoaded, setDomLoaded] = useState(false);

    useEffect(() => {
        setDomLoaded(true);
    }, []);

    return domLoaded;
};

export default useDomLoaded;
