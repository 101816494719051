// import 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import localforage from 'localforage';
import UAParser from 'ua-parser-js';
import ApiSend from '@utils/common/ApiSend';

const parser = new UAParser();
const firebaseCloudMessaging = {
    // checking whether token is available in indexed DB
    tokenInlocalforage: async () => {
        return localforage.getItem('fcm_token');
    },
    // initializing firebase app
    async init() {
        const firebaseConfig = {
            apiKey: 'AIzaSyBCmgxldClzTdhLlaUs_WIk29J-seJdiCM',
            authDomain: 'tving787570031759.firebaseapp.com',
            databaseURL: 'https://tving787570031759.firebaseio.com',
            projectId: 'tving787570031759',
            storageBucket: 'tving787570031759.appspot.com',
            messagingSenderId: '787570031759',
            appId: '1:787570031759:web:3d5e68c9fdf91cf5bf44fa',
            measurementId: 'G-7ERF1X30YC',
        };
        const firebaseApp = initializeApp(firebaseConfig);
        try {
            const messaging = getMessaging(firebaseApp);
            const tokenInLocalForage = await this.tokenInlocalforage();
            // if FCM token is already there just return the token
            console.log('tokenInLocalForage', tokenInLocalForage);
            if (tokenInLocalForage !== null) {
                return tokenInLocalForage;
            }
            // requesting notification permission from browser
            const status = await Notification.requestPermission();
            console.log('status', status);
            if (status && status === 'granted') {
                // getting token from FCM
                const fcm_token = await getToken(messaging, {
                    // vapidKey: 'BKPrF9XRmes9hCMoqHDTtrvGm_muNQLSUdnjv6zYwdRdiz9J_nIJxgMaoK9dvklhTKWfGov6skQw9-SWfJp9aPs',
                });
                if (fcm_token) {
                    // setting FCM token in indexed db using localforage
                    localforage.setItem('fcm_token', fcm_token);

                    // Api Control에서 API 요청
                    const { data, status, statusText } = await ApiSend({
                        // URL 넘어온 값 추가
                        url: '/v1/user/push',
                        params: {
                            deviceId: fcm_token,
                            pnsToken: fcm_token,
                            deviceName: parser.getBrowser().name,
                            deviceOsVersion: parser.getOS().name,
                            deviceAppId: 'tving',
                            deviceAppVersion: parser.getBrowser().version,
                            receiveYn: 'Y',
                            receiveEventYn: 'Y',
                            receiveLiveYn: 'Y',
                            receiveVodYn: 'Y',
                            receiveReserveYn: 'Y',
                            receiveTvingLiveYn: 'Y',
                            receiveNightYn: 'Y',
                            // screenCode: 'CSSD0300',
                            // networkCode: 'CSND0200',
                            osCode: 'CSOD0200',
                            // teleCode: 'CSCD0900',
                            // apiKey: '56dc890767ec858dcb4abf184c0b2d2d',
                        },
                        withCredentials: true,
                    });

                    // return the FCM token after saving it
                    return fcm_token;
                }
                return null;
            }
            return null;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
};
export { firebaseCloudMessaging };
