import { KboNavigationContext } from '@contexts/kbo/navigation/KboNavigationContext';
import { useMemo, useState } from 'react';

type KboNavigationProviderType = {
    children: React.ReactNode;
    device: {
        isMobileWebview: boolean;
        isMobileWeb: boolean;
        isAndroid: boolean;
        isTablet: boolean;
    };
    screen: {
        isTopOfScroll: boolean;
        kboHomeBgOpacity: number;
    };
};

const KboNavigationProvider = ({ children, device: deviceConfig, screen }: KboNavigationProviderType) => {
    const [device, setDevice] = useState(() => deviceConfig);

    const value = useMemo(
        () => ({
            device,
            screen,
            toggleIsMobileWebview() {
                setDevice((prev) => ({
                    ...prev,
                    isMobileWebview: !prev.isMobileWebview,
                }));
            },
        }),
        [device, screen],
    );

    return <KboNavigationContext.Provider value={value}>{children}</KboNavigationContext.Provider>;
};

export default KboNavigationProvider;
