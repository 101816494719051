import AspectRatio from '../../atom/aspectRatio/AspectRatio';
import Poster, { PosterProps } from '../../atom/poster/Poster';
import Text, { TextProps } from '../../atom/text/Text';
import IconPlay from '../../images/last/icon_play_continue.svg';
import tempCnx from '../../utils/tailwind/tempCnx';
import Labels, { LabelsProps } from '../labels/Labels';

type ClipVerticalItemProps = {
  rootClassName?: string;
  className?: string;
  poster: PosterProps;
  posterText?: TextProps<React.ElementType>;
  labels: LabelsProps;
  text: {
    className: string;
    items: { tag?: React.ElementType; title: string; className?: string; tabindex?: number }[];
  };
  totalPlayTime: number; // [ms]
  tabIndex?: number;
  isViewing?: boolean;
};

const formatClipDuration = (duration: number) => {
  if (duration === undefined || duration === null || Number.isNaN(duration) || duration < 0) {
    return '00:00';
  }

  try {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;

    if (Number.isNaN(hours) || Number.isNaN(minutes) || Number.isNaN(seconds)) {
      return '00:00:00';
    }

    if (hours === 0) {
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } catch (error) {
    console.error('error ClipHorizontalItem formatClipDuration:', error);
    return '00:00:00';
  }
};

const ClipHorizontalItem = ({
  rootClassName,
  className,
  poster,
  text,
  posterText,
  tabIndex,
  labels,
  totalPlayTime,
  isViewing,
}: ClipVerticalItemProps) => {
  return (
    <div className={tempCnx('flex', isViewing ? 'viewing' : '', rootClassName)}>
      <div className={`kbo-clip-item-wrapper ${className}`} tabIndex={tabIndex}>
        {posterText && <Text {...posterText} />}
        <Poster {...poster} />
        <Labels {...labels} />

        <span
          className={tempCnx(
            'absolute right-[0.17rem] bottom-[0.17rem] lg:right-[0.25rem] lg:bottom-[0.25rem] z-[21]',
            'inline-flex justify-center items-center rounded-[0.167rem] p-[0.167rem] bg-black/80',
            'text-[1rem] font-normal leading-[1.167rem] text-gray-70',
          )}
          style={{
            fontFamily: 'Pretendard, Roboto, Arial',
          }}
        >
          {formatClipDuration(totalPlayTime)}
        </span>

        {isViewing && (
          <div className="absolute inset-x-0 inset-y-0 flex-center bg-black/70 z-30 pointer-events-none">
            <AspectRatio ratio={1} className="w-[25%]">
              <IconPlay className="w-full h-full" />
            </AspectRatio>
          </div>
        )}
      </div>
      <div className={text?.className}>
        {text?.items.map((text) => {
          return <Text key={text.title} tag={text.tag} title={text.title} className={text.className} tabIndex={tabIndex} />;
        })}
      </div>
    </div>
  );
};

export default ClipHorizontalItem;
