import { AxiosRequestConfig } from 'axios';

import { API_URLS } from '../../constants/apiConstants';
import { GATEWAY_URL } from '../../utils/common/apiConfig';
import httpPost from '../../utils/http/httpPost';
import { NewTvingResponse, AccessTokenResponse } from '../types';

export interface AccessTokenParameter {
  refreshToken: string;
}

/**
 * Refresh Token을 이용해 Access Token을 생성하는 API
 * @param requestParameter : 요청 파라미터
 * @param requestConfig : axios 요청 설정
 */
const fetchAccessToken = (
  requestParameter: AccessTokenParameter,
  requestConfig?: AxiosRequestConfig,
): Promise<NewTvingResponse<AccessTokenResponse>> => {
  const { refreshToken } = requestParameter;

  const filledRequestConfig = {
    ...requestConfig,
    data: JSON.stringify({ refreshToken }),
    'axios-retry': {
      retryCondition: () => true,
    },
  };

  return httpPost<NewTvingResponse<AccessTokenResponse>>(`${GATEWAY_URL}${API_URLS.CREATE_ACCESS_TOKEN}`, filledRequestConfig)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
};

export default fetchAccessToken;
