import * as Sentry from '@sentry/nextjs';

/**
 * Node.js에서 이미 요청에 대한 응답이 전송된 후 헤더를 설정하거나 응답을 보내려고 할 때 발생하는 에러
 * @see {@link https://tracking.tving.com/share/issue/636f7c8af7384cb488b209d2fbaa1c7c}
 */
const isDuplicateResponseError = (name: string, message: string) => {
    if (message.startsWith('Cannot set headers after they are sent to the client')) {
        return true;
    }

    return false;
};

/**
 * 서버 렌더링 중에 클라이언트 렌더링과 일치하지 않을 때 발생하는 에러
 * @see {@link https://tracking.tving.com/share/issue/9d6035e5c50d4cd4a629d01096cc0c10 Hydration failed because the server rendered HTML didn't match the client.}
 * @see {@link https://tracking.tving.com/share/issue/da8ab99c8bcb433dbd6113c796fabca0 There was an error while hydrating but React was able to recover by instead client rendering the entire root.}
 */
const isHydrationMismatchError = (name: string, message: string) => {
    // React 오류 메시지는 Production 빌드에서 Minified 되므로 Minified React error #XXX도 반드시 포함해야 합니다.
    if (name === 'Error') {
        return (
            // Hydration failed because the server rendered HTML didn't match the client...
            message.startsWith('Hydration failed') ||
            message.startsWith('Minified React error #418') ||
            // There was an error while hydrating but React was able to recover by instead client rendering the entire root.
            message.startsWith('There was an error while hydrating') ||
            message.startsWith('Minified React error #423') ||
            // Text content does not match server-rendered HTML.
            message.startsWith('Text content does not match server-rendered HTML') ||
            message.startsWith('Minified React error #425')
        );
    }

    return false;
};

/**
 * Swiper의 Free Mode 사용 중 예기치 않게 언마운트 될 때 발생하는 에러
 * @see {@link https://tracking.tving.com/share/issue/49bc58d0247a4df9a84a2730ede63df6}
 */
const isUnexpectedSwiperUnmountError = (event: Sentry.Event, name: string, message: string) => {
    if (
        name === 'TypeError' &&
        (message === "Cannot read properties of undefined (reading 'speed')" || // Chrome + Edge
            (message.startsWith('undefined is not an object (evaluating') && message.endsWith(".params.speed')"))) // Safari
    ) {
        const nativeException = event.exception?.values?.[event.exception.values.length - 1];

        // setTimeout 함수에서 발생한 에러인 경우 무시 -- 가능한 좁은 범위로 적용
        if (nativeException?.mechanism?.type === 'instrument' && nativeException?.mechanism?.data?.function === 'setTimeout') {
            return true;
        }
    }

    return false;
};

/**
 * Sentry의 `beforeSend` Hook에서 오류를 무시할지 여부를 결정합니다.
 * - 오류가 발생하더라도 문제가 없는 경우에만 무시할 수 있습니다.
 * - 무시할 수 있는 오류이더라도 수정이 가능한 경우, 수정을 권장합니다.
 */
export const shouldIgnoreClientError = (event: Sentry.Event, hint: Sentry.EventHint | undefined) => {
    if (!(hint && hint.originalException instanceof Error)) {
        return false;
    }

    const { name, message } = hint.originalException;

    if (isHydrationMismatchError(name, message)) {
        return true;
    }

    if (isUnexpectedSwiperUnmountError(event, name, message)) {
        return true;
    }

    return false;
};

/**
 * Sentry의 `beforeSend` Hook에서 오류를 무시할지 여부를 결정합니다.
 * - 오류가 발생하더라도 문제가 없는 경우에만 무시할 수 있습니다.
 * - 무시할 수 있는 오류이더라도 수정이 가능한 경우, 수정을 권장합니다.
 */
export const shouldIgnoreServerError = (event: Sentry.Event, hint: Sentry.EventHint | undefined) => {
    if (!(hint && hint.originalException instanceof Error)) {
        return false;
    }

    const { name, message } = hint.originalException;

    if (isDuplicateResponseError(name, message)) {
        return true;
    }

    return false;
};
