import { type Page } from '@playwright/test';

/**
 * 페이지 이동 후 페이지 로드 상태를 기다립니다. (단, React의 Hydration 완료 등은 고려하지 않습니다.)
 */
export async function waitForPageTransition(page: Page, urlMatcher: string | RegExp | ((url: URL) => boolean)) {
  try {
    await page.waitForURL(urlMatcher);
    await page.waitForLoadState('load');
    await page.waitForTimeout(1000);
  } catch (error) {
    throw new Error(`페이지 이동 후 페이지 로드 상태를 기다리는 데 실패했습니다: ${error instanceof Error ? error.message : '알 수 없는 오류'}`);
  }
}
