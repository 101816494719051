import { StoreApi, UseBoundStore } from 'zustand';
import { createContext } from 'react';
import { UpdateIndicatorStore } from '@components/kbo/kboContentsBand/updateIndicator/updateIndicatorStore';

export const UpdateIndicatorContext = createContext<UseBoundStore<StoreApi<UpdateIndicatorStore>> | null>(null);

const UpdateIndicatorProvider = ({ value, children }) => {
    return <UpdateIndicatorContext.Provider value={value}>{children}</UpdateIndicatorContext.Provider>;
};

export default UpdateIndicatorProvider;
