import { useEffect } from 'react';

import { deepEqual } from 'fast-equals';
import { useRouter } from 'next/router';
import { OverlayProvider } from 'overlay-kit';
import { shallow } from 'zustand/shallow';

import useFreshCallback from '@tving/utils/src/hooks/common/useFreshCallback';

import useOverlayKitIntegration from './useOverlayKitIntegration';

const OverlayKitLifecycleManager = () => {
  const { destroyInstance, getAllInstances } = useOverlayKitIntegration((state) => state, shallow);
  const router = useRouter();

  const handleRouteChangeComplete = useFreshCallback(() => {
    const instances = getAllInstances();

    instances.forEach((instance) => {
      const { config } = instance;
      const { routerSnapshot, destroyOnRouteChange } = config;

      if (destroyOnRouteChange === true) {
        destroyInstance(instance.overlayId);
        return;
      }

      if (destroyOnRouteChange === 'pathname') {
        if (routerSnapshot.pathname !== router.pathname) {
          destroyInstance(instance.overlayId);
          return;
        }
      }

      if (destroyOnRouteChange === 'query') {
        if (routerSnapshot.pathname !== router.pathname || !deepEqual(routerSnapshot.query, router.query)) {
          destroyInstance(instance.overlayId);
          return;
        }
      }

      if (typeof destroyOnRouteChange === 'function' && destroyOnRouteChange(routerSnapshot, router)) {
        destroyInstance(instance.overlayId);
      }
    });
  });

  useEffect(
    () => {
      router.events.on('routeChangeComplete', handleRouteChangeComplete);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChangeComplete);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- 이벤트 핸들러는 항상 최신의 값을 보장합니다.
    [],
  );

  return null;
};

export type OverlayKitIntegrationProviderProps = React.PropsWithChildren;

const OverlayKitIntegrationProvider = ({ children }: OverlayKitIntegrationProviderProps) => {
  return (
    <OverlayProvider>
      <OverlayKitLifecycleManager />
      {children}
    </OverlayProvider>
  );
};

export default OverlayKitIntegrationProvider;
