import { useEffect, useState } from 'react';
import ApiSend from '@utils/common/ApiSend';
import { useAbortSWR } from './plugins';

import { useUserInfo } from '../state';

export * from './live';
export * from './episode';
export * from './movie';
export * from './program';
export * from './user';
export * from './list';
export * from './stream';
export * from './my';
export * from './search';
export * from './schedule';
export * from './event';

/**
 * Home Exposures API 호출.
 * @return {Object|boolean} data, source, isLoading, isError Value.
 */
export const useHomeExposures = () => {
    const [expoData, setExpoData] = useState({
        data: {},
        isLoading: true,
        isError: false,
    });
    const [userInfo] = useUserInfo();

    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달
    const exposeSWR = useAbortSWR(
        [`/v2/operator/home/exposures4`],
        {
            params: {
                exposeGroup: 'HPC',
                cacheTime: 5,
                responseType: 'group',
            },
        },
        { focusThrottleInterval: 1000 * 60 * 60 * 2 },
    );

    // 로그인 회원 Type에 맞춰 Filter
    const checkUserLoginType = item => {
        if (userInfo.isLogin) {
            if (userInfo.isPayUser) {
                return item.login_yn === '1' || item.login_yn === '4';
            }
            return item.login_yn === '2' || item.login_yn === '4';
        }
        return false;
    };

    useEffect(() => {
        console.log('#expo update', exposeSWR.isLoading, exposeSWR.isValidating, userInfo?.profileName);

        if (exposeSWR.isLoading || exposeSWR.isValidating) {
            setExpoData({ ...expoData, isLoading: true });
            return;
        }

        if (!expoData.isLoading || !userInfo?.userNo) {
            return;
        }

        let exportData = {};
        if (typeof userInfo.isLogin === 'boolean' && exposeSWR.data) {
            exportData = exposeSWR.data || {};
            const { items } = exportData;
            if (items) {
                Object.keys(items).forEach(key => {
                    if (Array.isArray(items[key])) {
                        items[key] = items[key].filter(checkUserLoginType);
                        items[key] = items[key].map(item => {
                            return {
                                ...item,
                                expose_nm: item.expose_nm && item.expose_nm?.replaceAll('{{name}}', userInfo?.profileName || ''),
                                api_param_pc: item.api_param_pc && item.api_param_pc?.replaceAll('{{userno}}', userInfo?.userNo || ''),
                            };
                        });
                    }
                });
                console.log('#expo updated');
                setExpoData({
                    data: exportData,
                    isLoading: false,
                });
            }
        }
    }, [userInfo, exposeSWR.isLoading, exposeSWR.isValidating, userInfo?.userNo]);

    return expoData;
};

/**
 * Test API 호출. timeout 4초가 걸려있는 API.
 * @return {Object|boolean} data, source, isLoading, isError Value.
 */
export const useTestApi = ({ type = 1 }) => {
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달
    return useAbortSWR([`/api/test/${type}`], {
        baseURL: '/',
        url: 'api/test',
    });
};

/**
 * 최신 공지사항 API 호출
 * @returns data
 */
export const getRecentNoticeList = async () => {
    const { data } = await ApiSend({
        url: '/v2/operator/notices/recent?pageNo=1',
        params: {
            pocCode: 'POCD0300',
            pageSize: 1,
            topYn: 'Y',
            cacheTime: 5,
        },
    });
    return data;
};
/**
 * 메인 모달 배너 API 호출.
 * @return {Object|boolean} data, source, isLoading, isError Value.
 */
export const useMainPopup = () => {
    return useAbortSWR([`/v2/operator/highlights?positionKey=PC_MAIN_PAYOUT_POPUP`], {
        params: {
            cacheTime: 5,
        },
    });
};
