import ReactModal from 'react-modal';
import type { Props as ReactModalProps } from 'react-modal';
import type { Optional } from '@tving/utils/src/types/utility';
import cnx from '@utils/tailwind/cnx';
import styles from './BottomSheet.module.scss';
import BodyScrollLocker from '@components/common/bodyScrollLocker';
import type { BottomSheetProps } from './types';
import ScrollableSection from './ScrollableSection';

const overlayClassName: ReactModalProps['overlayClassName'] = {
    base: cnx(styles['bottom-sheet-overlay-animation'], 'fixed inset-x-0 inset-y-0 bg-[rgba(0,0,0,0.5)] z-[1000]'),
    afterOpen: styles['after-open'],
    beforeClose: styles['before-close'],
};

const bodyClassName: ReactModalProps['className'] = {
    base: cnx(
        styles['bottom-sheet-body-animation-provider'],
        'absolute top-0 bottom-0 left-0 right-0',
        'flex justify-center items-end',
        'landscape:pt-[2rem]',
        'pointer-events-none',
    ),
    afterOpen: styles['after-open'],
    beforeClose: styles['before-close'],
};

const BOTTOM_SHEET_CONFIG: Optional<ReactModalProps> = {
    shouldCloseOnOverlayClick: true,
    shouldCloseOnEsc: true,
    preventScroll: true,
    shouldFocusAfterRender: true,
    bodyOpenClassName: null,
    overlayClassName,
    className: bodyClassName,
    closeTimeoutMS: 150,
};

/**
 * 하단 팝업 레이어(또는 Bottom Sheet) 컴포넌트입니다.
 */
const BottomSheet = ({ isOpen = false, onClose, headerContent, bodyContent, enableBodyContentScroll = true }: BottomSheetProps) => {
    return (
        <ReactModal {...BOTTOM_SHEET_CONFIG} isOpen={isOpen} onRequestClose={onClose}>
            <BodyScrollLocker />

            <div
                className={cnx(
                    styles['bottom-sheet-body'],
                    /**
                     * Bottom Sheet의 디자인 명세는 피그마 링크를 참조해 주세요.
                     * - 기존 iOS, Android 앱과 비슷한 형상을 가져가도록 모바일 분기점을 '600px'로 적용하였습니다.
                     *
                     * @see {@link https://www.figma.com/file/ZGXJMa8ry5UnNnrSeWEF25/1.APP?node-id=13653%3A119118&mode=dev}
                     */
                    'portrait:w-full portrait:max-w-[49.167rem] landscape:w-[32.5rem] max-h-[min(40rem,_calc(100vh-4rem))]',
                    'portrait:min-[600px]:w-[49.167rem] landscape:[@media(min-height:600px)]:w-[49.167rem]',
                    'flex flex-col justify-items-stretch items-stretch',
                    'bg-[#262626] outline-none rounded-t-[1.333rem]',
                    'overflow-hidden',
                    'pointer-events-auto',
                )}
            >
                {headerContent ? <header>{headerContent}</header> : null}

                <ScrollableSection enableBodyContentScroll={enableBodyContentScroll}>{bodyContent}</ScrollableSection>
            </div>
        </ReactModal>
    );
};

export default BottomSheet;
