import braze from '@braze/web-sdk-core';
import { Player, QualityInfo, SubtitleTrack } from '@tvingcorp/web-player';
import useSWR, { KeyedMutator, SWRConfiguration } from 'swr';

import { NullableString } from '@tving/utils/src/apis/types';
import useLocalStorage from '@tving/utils/src/hooks/common/useLocalStorage';

import Socket from '@utils/socket';

export * from './apis';

/**
 * SWR을 object store로 쓰기 위한 Function.
 * @param key - SWR Key.
 * @param fallbackData
 * @return Target Data Object, Setter Function.
 */
const useObjectNewSWR = <T = unknown>(key = '', fallbackData: T | null = null): [T, KeyedMutator<T>] => {
    const swrOption: SWRConfiguration = {};
    if (fallbackData) {
        swrOption.fallbackData = fallbackData;
    }
    const { data, mutate } = useSWR<T>(key, null, swrOption);
    return [data as T, mutate];
};

const useListSWR = <T = unknown>(key = '', target = [] as T[]): [T[], KeyedMutator<T[]>] => {
    const { data, mutate } = useSWR<T[]>(key, null, {
        fallbackData: target,
    });
    return [
        data as T[],
        (obj) => {
            return mutate(obj ? [...(obj as T[])] : []);
        },
    ];
};

export interface PcPlayerInfo {
    player?: Player | null;
    pathname?: string;
    isPreparing?: boolean;
    isPlaying?: boolean;
    mediaCode?: NullableString;
    viewActionCount?: number;

    playbackRate?: number;
    prevVolume?: number;
    isViewActionInfo?: boolean;
    isVolumeChange?: boolean;
    isSeekPrev?: boolean;
    isSeekNext?: boolean;
    seekSeconds?: number;
    isPlayToggle?: boolean;
    isPlayingTimeshift?: boolean;
    isPlayPause?: boolean;
    isHideNextEpisode?: boolean;
    isQualityChanging?: boolean;

    seekPrev?: () => void;
    seekNext?: () => void;
    ndvrToggle?: (time?: number) => void;
    ndvrOff?: () => void;
    volumeChange?: (value: number) => void;

    qualityList?: QualityInfo[];

    startStreamCode?: string;
    startLiveStreamCode?: string;
    startVodStreamCode?: string;
    startClipStreamCode?: string;
}

// Object를 이용한 상태 관리. 해당 Hook을 통해 get, set으로 이용 가능
// Player Info State
export const usePlayerInfo = (): [PcPlayerInfo, KeyedMutator<PcPlayerInfo>] => {
    return useObjectNewSWR<PcPlayerInfo>('playerInfo', {
        player: null,
        pathname: '',
        isPreparing: true,
        isPlaying: false,
        mediaCode: '',
        viewActionCount: 0,
    });
};

export interface PcPlayerPageInfo {
    pathname?: string;
    isPlaying?: boolean;
    isPlayerPage?: boolean;
    isDimmToggle?: boolean;
    mediaCode?: string;

    isWheelBlock?: boolean;
    displayTitle?: string;
}

export const usePlayerPageInfo = (): [PcPlayerPageInfo, KeyedMutator<PcPlayerPageInfo>] => {
    return useObjectNewSWR<PcPlayerPageInfo>('playerPageInfo', {
        pathname: '',
        isPlaying: false,
        isPlayerPage: false,
        isDimmToggle: true,
        mediaCode: '',
        displayTitle: '',
    });
};

export interface PcPlayerAdInfo {
    isAdsPlaying?: boolean;
    isAdsSkippable?: boolean;
    offset?: number;
    adsIdx?: number;
    adsTotal?: number;
    adLink?: NullableString;
    adLinkUrl?: string;
    adPoints?: number[];
}

// Player Info State
export const usePlayerAdInfo = (): [PcPlayerAdInfo, KeyedMutator<PcPlayerAdInfo>] => {
    return useObjectNewSWR<PcPlayerAdInfo>('playerAdInfo');
};

// Object를 이용한 상태 관리. 해당 Hook을 통해 get, set으로 이용 가능
// Play 옵션들.
export const usePlayOptions = () => {
    const [isAutoNextEpisode] = useLocalStorage('player-isAutoNextEpisode');
    const [isAutoNextClip] = useLocalStorage('player-isAutoNextClip');

    return useObjectNewSWR('playOptions', {
        isAutoNextEpisode: isAutoNextEpisode === undefined ? true : isAutoNextEpisode === 1,
        isAutoNextClip: isAutoNextClip === undefined ? true : isAutoNextClip === 1,
    });
};

export interface PcSimplePlayerInfo {
    player?: Player | null;
    mediaCode?: string;
}

// SimplePlayer Info State
export const useSimplePlayer = (): [PcSimplePlayerInfo, KeyedMutator<PcSimplePlayerInfo>] => {
    return useObjectNewSWR<PcSimplePlayerInfo>('simplePlayerInfo', {
        player: null,
        mediaCode: '',
    });
};

export interface PcUserInfo {
    profileNo: string;
    profileName: string;
    profileType: string;
    profileImgPath: string;
    userNo: string;
    userName: string;
    userId: string;
    userType: string;
    cjOneMember: boolean;
    lastPasswordUpdateDate: string;
    age: string;
    legalBirthday: string;
    sex: string;

    isPayUser?: boolean;
    isLogin?: boolean;
    isLoading?: boolean;
}

// User Info State
export const useUserInfo = ({ initialData = {} } = {}): [PcUserInfo, KeyedMutator<PcUserInfo>] => {
    const [userInfo, setUserInfo] = useObjectNewSWR<PcUserInfo>('userInfo', {
        profileNo: '', // 프로필 사용자No
        profileName: '', // 프로필 사용자명
        profileType: '', // 프로필 타입
        profileImgPath: '', // 프로필 이미지
        userNo: '', // 사용자No
        userName: '', // 사용자명
        userId: '', // 로그인ID ex)test2
        userType: '', // 사용자 타입
        cjOneMember: false, // cjone 회원 여부 (boolean)
        lastPasswordUpdateDate: '',
        age: '', // 나이
        legalBirthday: '',
        sex: '', // 성별
        // isLogin: true,
        // cash: '', // 티빙캐시
        ...initialData,
    });

    // key로 캐싱되기 때문에 고유의 문자열로 상태 지정할 명칭 전달
    return [userInfo, setUserInfo];
};

export const useDeleteList = () => useListSWR('deleteList', []);

export interface PcSocketInfo {
    socket?: Socket | null;
    isInitialize?: boolean;
    isConnect?: boolean;
    isConnected?: boolean;
}

export const useSocketInfo = (): [PcSocketInfo, KeyedMutator<PcSocketInfo>] =>
    // key로 캐싱되기 때문에 고유의 문자열로 상태 지정할 명칭 전달
    useObjectNewSWR<PcSocketInfo>('socketInfo', {
        socket: null,
    });

export const useSearchState = () => {
    return useObjectNewSWR('searchInfo', {
        pageInputEl: null,
        isViewLayer: false,
    });
};

export const useMobileNav = ({ initialData = {} } = {}) => {
    return useObjectNewSWR('mobileNavInfo', {
        isBuyContents: false, // 개별구매 여부
        isEndOfSale: false, // 개별구매면서 판매 종료 여부
        mediaCode: '',
        resultCode: '',
        isNotProd: false,
        isPreRelease: false,
        ...initialData,
    });
};

export const useBandsSize = () => {
    return useObjectNewSWR('homeBandsSize', {});
};

export type PcLoggingType = {
    braze: typeof braze;
    brazeInitialize: (params: { userToken: string }) => void;
    brazeEvent: (params: { eventName: string; eventProperties?: { [key: string]: string } }) => void;
    brazeScreen: (params: { eventName: string }) => void;
    setBrazeWebLoginStatus: (params: { loginWebYn: string }) => void;
};

export const useLogging = (): [PcLoggingType, KeyedMutator<PcLoggingType>] => {
    return useObjectNewSWR('useLogging', {} as PcLoggingType);
};

export const useAdultYn = () => {
    return useObjectNewSWR('useAdultYn', {
        isAdult: false,
    });
};

export const useUserRegionCode = () => {
    return useObjectNewSWR<string | null>('useUserRegionCode', '');
};

type useAppPagePropsType = {
    categoryCode: string;
    categoryName: string;
    channelCode: string;
    channelName: string;
    mediaType: string;
    mediaCode: string;
    programCode: string;
    programName: string;
};

export const useAppPageProps = () => {
    return useObjectNewSWR('useAppPageProps', {} as useAppPagePropsType);
};

export const useSubtitleInfo = (): [
    (
        | {
              id: number;
          }
        | undefined
    ),
    KeyedMutator<{
        id: number;
    }>,
] => {
    const [selectedSubtitleLang, setSelectedSubtitleLang] = useObjectNewSWR<{
        id: number;
    }>('useSubtitleInfo', {
        id: -1,
    });

    return [selectedSubtitleLang, setSelectedSubtitleLang];
};

export const useSubtitleTracksInfo = (): [SubtitleTrack[] | undefined, KeyedMutator<SubtitleTrack[]>] => {
    const [subtitleTracks, setSubtitleTracks] = useObjectNewSWR<SubtitleTrack[]>('useSubtitleTracksInfo', []);

    return [subtitleTracks, setSubtitleTracks];
};

export const useSdkLoadedList = () => {
    const [sdkLoadedList, setSdkLoadedList] = useObjectNewSWR('useSdkLoadedList', {
        kakao: {
            onSuccess: false,
            onLoading: false,
        },
    });

    return [sdkLoadedList, setSdkLoadedList];
};

export const useRouteChanged = () => {
    return useObjectNewSWR('useRouteChanged');
};
