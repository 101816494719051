import { useIsomorphicLayoutEffect } from 'react-use';

const focusableSelector =
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, [tabindex]:not([tabindex="-1"]), [contenteditable]';

export default function useTabTrap(contentRef, wrapper, isActive) {
    useIsomorphicLayoutEffect(() => {
        const listener = event => {
            console.log('listener', isActive);
            if (!isActive) {
                return;
            }
            if (event.key !== 'Tab') {
                return;
            }

            // forcing focus to the first focusable element if any of these are true:
            //   - no current focus on page
            //   - focused element is outside of the container
            //   - focused element is last in the container
            let forceFocus = false;
            let firstFocusableElement = null;
            if (!document.activeElement) {
                forceFocus = true;
            }
            if (!forceFocus && !contentRef.current.contains(document.activeElement)) {
                forceFocus = true;
            }
            if (!forceFocus) {
                let focusableInModal = contentRef.current.querySelector(wrapper).querySelectorAll(focusableSelector);
                if (event.shiftKey) {
                    focusableInModal = Array.prototype.slice.call(focusableInModal);
                    focusableInModal.reverse();
                }
                if (document.activeElement === focusableInModal[focusableInModal.length - 1]) {
                    firstFocusableElement = focusableInModal[0];
                    forceFocus = true;
                }
            }

            if (forceFocus) {
                event.preventDefault();
                if (!firstFocusableElement) {
                    if (event.shiftKey) {
                        firstFocusableElement = contentRef.current.querySelector(wrapper).querySelectorAll(focusableSelector);
                        firstFocusableElement = Array.prototype.slice.call(firstFocusableElement);
                        firstFocusableElement.reverse();
                        firstFocusableElement = firstFocusableElement[0];
                    } else {
                        firstFocusableElement = contentRef.current.querySelector(wrapper).querySelector(focusableSelector);
                    }
                }
                firstFocusableElement.focus();
            }
        };

        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [isActive]);
}
