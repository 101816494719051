import LottieContainer from '../../../utils/lottie/LottieContainer';

import animationData from './label_tsm_motion.json';

interface TsmLabelProps {
  className?: string;
  tabIndex?: number;
}
const TsmLabel = ({ className, tabIndex }: TsmLabelProps) => {
  return (
    <div className={`label-wrapper tsm-badge-wrapper ${className}`} tabIndex={tabIndex}>
      <LottieContainer data={animationData} className="" />
    </div>
  );
};
export default TsmLabel;
