import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import utilsAxiosInstance from '@utils/common/utilsAxiosInstance';

/**
 * 생성한 axiosInstance의 Post 요청 함수
 *
 * @param url
 * @param requestConfig: header, timeout 등 다양한 설정을 함수 사용자에게 전가
 */
const httpPost = <T>(url: string, requestConfig?: AxiosRequestConfig): Promise<T> => {
    return utilsAxiosInstance
        .post<T>(url, requestConfig?.data, requestConfig)
        .then((response: AxiosResponse<T>) => {
            const { data } = response;

            return data;
        })
        .catch((error: AxiosError) => {
            return Promise.reject(error);
        });
};

export default httpPost;
