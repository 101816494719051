import Link from 'next/link';
import styled from '@emotion/styled';
import { isMobile } from '@utils/device/device-detect';
import { palette } from '@styles/colors';

const GenreItem = ({
    href = '',
    idx,
    type, // 타입 - movie / episode
    bandType,
    title, // 타이틀
    onClick,
}) => {
    const isParamount = href?.startsWith('/paramount') || false;
    const itemBackgroundColor = isParamount ? palette.blue900 : palette.inverseGrey50;
    const itemHoverBackgroundColor = isParamount ? palette.blue800 : palette.inverseGrey100;
    const itemFontColor = isParamount ? palette.blue50 : '#ffdc3e';
    const itemHoverFontColor = isParamount ? palette.blue100 : '#ffe676';

    return (
        <Link scroll href={href} passHref>
            <StyleGenreItem onClick={onClick} itemHoverBackgroundColor={itemHoverBackgroundColor} itemHoverFontColor={itemHoverFontColor}>
                <StyleGenreItemTitle itemBackgroundColor={itemBackgroundColor} itemFontColor={itemFontColor}>
                    {title}
                </StyleGenreItemTitle>
            </StyleGenreItem>
        </Link>
    );
};

export default GenreItem;

const StyleGenreItem = styled.a`
    transition: opacity 0.3s, transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    > span {
        transition: all 0.1s;
    }
    &:hover {
        transform: scale(1.05);
        > span {
            background-color: ${props => `${props.itemHoverBackgroundColor}`};
            color: ${props => `${props.itemHoverFontColor}`};
        }
    }
    &:active {
        transform: scale(0.97);
    }
`;

const StyleGenreItemTitle = styled.span`
    display: flex;
    min-height: ${isMobile ? `3.9rem` : `3.677rem`};
    justify-content: center;
    align-items: center;
    margin-left: 0.667rem;
    padding: 0.917rem 2.917rem 1rem;
    border-radius: 999px;
    background-color: ${props => `${props.itemBackgroundColor}`};
    color: ${props => `${props.itemFontColor}`};
    font-size: ${isMobile ? `1.74rem` : `1.25rem`};
    line-height: 1.4;
    white-space: nowrap;
    box-sizing: border-box;

    &:first-child {
        margin-left: 0;
    }
`;
