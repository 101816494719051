/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

import { apiOrigin, apiKey } from '@utils/apiConfig';
import { getCookie } from '@utils/common/cookie';
import { moveProfileSelect } from '@utils/common/UserApi';

/**
 * generateCookies
 * @param {string} [cookieString]
 */
export const generateCookies = (cookieString) => {
    try {
        /**
         * cookies
         * @type Record<string, string | undefined>
         */
        const cookies = cookieString?.split(';').reduce((acc, cookie) => {
            const [key, value] = cookie.split('=');
            acc[key.trim()] = value;
            return acc;
        }, {});
        return cookies;
    } catch (e) {
        Sentry.captureException(e);
        return {};
    }
};

/**
 * generateAuthCookie
 * [!] SSR 환경에서 쿠키 전체를 보낼경우 CF에서 431에러 발생할 수 있음
 * @see https://tvingcorp.slack.com/archives/C085H288S93/p1739945662914799
 * @param {string} [cookieString]
 * @returns {string}
 */
export const generateAuthCookie = (cookieString) => {
    const cookies = generateCookies(cookieString);
    return Object.entries(cookies)
        .filter(([key]) => {
            return ['accessToken', 'refreshToken', '_tving_token', 'authToken'].includes(key);
        })
        .map(([key, value]) => {
            return `${key}=${value}`;
        })
        .join('; ');
};

let authToken = '';
let tvingToken = '';
if (typeof window !== 'undefined') {
    tvingToken = getCookie('_tving_token');
    authToken = getCookie('authToken');
}
/**
 * axios instance 생성
 * 옵션 (https://github.com/axios/axios 의 Request Config) 참고하여 추가
 */

const instance = axios.create({
    baseURL: apiOrigin,
    method: 'get',
    timeout: 10000,
    // headers: {},
    // withCredentials: true,
    params: {
        screenCode: 'CSSD0100',
        networkCode: 'CSND0900',
        osCode: 'CSOD0900',
        teleCode: 'CSCD0900',
        apiKey,
    },
});

/**
 * axios request 인터셉터
 */
instance.interceptors.request.use(
    (config) => {
        // 요청 직전 axios 설정 추가 ( 로딩 인디케이터 삽입 등.. )
        config.headers['Content-Type'] = 'application/json; charset=utf-8';

        // 통합API(api.tving.com) 호출 시 헤더 추가
        if (typeof window !== 'undefined' && (config.url?.startsWith('/v') || config.url?.startsWith('v'))) {
            /**
             * CSR 처리
             */
            try {
                // 티빙토큰은 있으니 authToken이 없는 경우 신규 로깅 추가
                if (tvingToken && (!authToken || authToken === '')) {
                    Sentry.configureScope((scope) => {
                        scope.setExtra('requestUrl', config.url || '');
                    });
                    Sentry.captureMessage('[Custom Error] authToken does not exist', { level: 'log' });
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
            config.headers['Auth-Token'] = authToken || '';
        }
        try {
            if (typeof window === 'undefined') {
                /**
                 * SSR 처리
                 */
                const headerCookieString = config.headers.Cookie || config.headers.cookie;
                if (headerCookieString) {
                    /**
                     * 쿠키 기반 인증 헤더 생성 및 필수 쿠키 정보만 전송
                     * @see https://tving.atlassian.net/browse/TVING-12735
                     * @see https://tving.atlassian.net/browse/TVING-12693
                     */
                    const authCookies = generateAuthCookie(headerCookieString);
                    /**
                     * [!] 인증 관련 쿠키만 남기고 모두 제거(=HTTP Status 431 이슈)
                     */
                    config.headers.Cookie = authCookies;
                    const cookies = generateCookies(authCookies);
                    const { authToken, _tving_token: profileToken } = cookies;
                    if (authToken) {
                        config.headers['Auth-Token'] = authToken;
                    }
                    if (profileToken) {
                        /**
                         * 프로필 토큰으로 인증 토큰 헤더 생성
                         */
                        config.headers.Authorization = `Bearer ${profileToken}`;
                    }
                }
            }
        } catch (error) {
            Sentry.captureException(error);
        }
        return config;
    },
    (error) => {
        // 에러처리 공통
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    (response) => {
        // API 요청 응답 결과 code 401인 경우 유효하지 않은 프로필로 판단
        if (response?.data?.header?.status === 401) {
            Sentry.captureMessage('[Custom Error] API Send Response - 401', { level: 'log' });
            // SSR로 호출하는 경우 window undefined 오류로 예외처리. ex) authToken 없는 상태로 에로컨텐츠 상세화면 접근 시 user/info에서 401 발생
            if (typeof window !== 'undefined') {
                moveProfileSelect();
            }
        }

        return response;
    },
    (error) => {
        // 에러처리 공통
        if (error?.response?.status >= 400 && error?.response?.status <= 511) {
            Sentry.captureMessage(`[Custom Error] API Send Response - ${error}`, { level: 'error' });
        }
        if (axios.isAxiosError(error)) {
            /**
             * @type {AxiosError}
             */
            const axiosError = error;
            /**
             * 서버 API 응답 에러 레포팅
             */
            Sentry.withScope((scope) => {
                const { url: apiUrl, headers: requestHeaders } = axiosError.config;
                const { response = {} } = axiosError;
                const { headers: responseHeaders, data: responseData = {} } = response;
                scope.setTags({
                    apiUrl,
                });
                scope.setExtras({
                    axiosError,
                    requestHeaders,
                    responseHeaders,
                    responseData,
                });
                Sentry.captureException(error);
            });
        }
        return Promise.reject(error);
    },
);

export default instance;
