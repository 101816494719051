import { useEffect, useRef, useState } from 'react';

import { BaseMessage } from '@sendbird/chat/message';

import { useChatConfig } from '../../../contexts/ChatContext';
import scrollToBottomOfContainer from '../../../utils/dom/scrollToBottomOfContainer';

type UseChatUiWithSendbirdProps = {
  messageCollection: BaseMessage[][];
  getPreviousMessages: () => Promise<BaseMessage[] | null>;
};

const rootMargin = 0;

const useVisibility = (init = true, option: IntersectionObserverInit = { threshold: [1], rootMargin: '0px' }) => {
  const [isVisible, setIsVisible] = useState(init);
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!targetRef.current) {
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    }, option);

    observer.observe(targetRef.current);

    return () => {
      if (!targetRef.current) {
        return;
      }

      observer.unobserve(targetRef.current);
    };
  }, [option]);

  return [targetRef, isVisible] as const;
};

const UseChatUiWithSendbird = ({ messageCollection, getPreviousMessages }: UseChatUiWithSendbirdProps) => {
  const { setCanReceiveMessage } = useChatConfig();
  // console.log('#tving-talk - ui', { messageCollection });
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const prevScrollHeightRef = useRef<number | null>(null);
  const prevMessageIdRef = useRef<number | null>(null);
  const prevPositionRef = useRef<HTMLDivElement | null>(null);

  const messageListTopRef = useRef<HTMLDivElement>(null);

  const bodyScrollTop = useRef<number>(0);
  // const isTop = true;
  // const [isTop, messageListTopRef] = useVisibility(false, { threshold: [1] });
  const [messageListBottomRef, isBottom] = useVisibility(true, { threshold: [0.1], rootMargin: '50%', root: messageContainerRef.current });
  // const [messageListBottomRef, isBottom] = useInView();

  // setCanReceiveMessage(isBottom);

  /**
   * 스크롤이 가장 아래일 때, 새로운 메시지가 추가되면 스크롤을 아래로 이동시킨다.
   */
  useEffect(() => {
    console.log('#tving-talk - ui', {
      messageCollection,
      isBottom,
      messagesContainer: messageContainerRef.current,
      messageListBottomRef: messageListBottomRef.current,
    });
    if (!messageContainerRef.current || !messageCollection || !isBottom) {
      return;
    }

    scrollToBottomOfContainer(messageContainerRef.current);
    // messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
  }, [isBottom, messageCollection, messageListBottomRef]);

  // document.documentElement.style.overflowY = 'hidden';

  /**
   * 스크롤이 가장 위일 때, 이전 메시지를 불러오면 스크롤을 보고 있던 메시지로 이동시킨다.
   */

  const prevMessageSectionCount = useRef<number>(0);
  const tailMessageSectionHeight = useRef<number>(0);
  useEffect(() => {
    console.log('#tving-talk - ui - 11', { prevScrollHeightRef: prevScrollHeightRef.current });

    if (!messageContainerRef.current || !messageCollection) {
      return;
    }
    const { scrollHeight, scrollTop } = messageContainerRef.current;

    console.log('#tving-talk - ui - 111', { scrollHeight, prevScrollHeightRef: prevScrollHeightRef.current });

    if (isBottom) {
      return;
    }

    const { pageYOffset } = window;
    const { scrollTop: documentScrollTop } = document.documentElement;
    const { scrollTop: documentBodyScrollTop } = document.body;

    console.log('#tving-talk - ui - scroll 111111', {
      length: messageCollection.length,
      scrollTop,
      scrollHeight,
      prevScrollHeightRef: prevScrollHeightRef.current,
      pageYOffset,
      documentScrollTop,
      // bodyScrollTop,
    });

    bodyScrollTop.current = documentBodyScrollTop;
    // const targets = document?.querySelectorAll('.previous-chat-list-top-position');
    // console.log('#tving-talk - ui - scroll 111111', { targets });
    // targets?.[1]?.scrollIntoView(true);
    // [0, 1, 10].forEach((timer) => {
    //   setTimeout(() => {
    //     targets?.[2]?.scrollIntoView(true);
    //   }, timer);
    // });
    // if (isMobileWeb()) {
    //   return;
    // }

    console.log('#tving-talk - ui - scroll 44', { messageCollection, length: messageCollection.length });

    console.log('#tving-talk - ui - scroll 44', {
      messageCollection,
      length: messageCollection.length,
      children: messageContainerRef.current.children,
    });

    tailMessageSectionHeight.current = messageCollection.length === 8 ? messageContainerRef.current.children[8].clientHeight : 0;

    // 스크롤 위치 조정: 기존 위치에서 새로 추가된 높이만큼 스크롤 위치를 이동

    // 새로운 메시지가 추가되기 전의 높이 차이를 계산
    let canMove = true;

    const moveScrollPositionMove = () => {
      if (!canMove || !messageContainerRef.current) {
        return;
      }

      // const prevScrollHeight = prevScrollHeightRef.current || scrollHeight;
      // const newMessageHeightDifference = scrollHeight - prevScrollHeight;
      // messageContainerRef.current.scrollTop = scrollTop + newMessageHeightDifference;

      canMove = false;
    };

    // moveScrollPositionMove();
    //
    // [0, 5, 10, 100, 500].forEach((timer) => {
    //   setTimeout(() => {
    //     moveScrollPositionMove();
    //   }, timer);
    // });

    // 스크롤 위치를 저장
    prevScrollHeightRef.current = scrollHeight;
    // document.body.scrollTop = bodyScrollTop.current;
  }, [messageCollection]);

  /**
   * 타겟한 영역에 닿으면 이전 메시지를 불러온다.
   */
  // useEffect(() => {
  //   if ((!messageCollection && !messageContainerRef.current) || !messageListTopRef.current) {
  //     return;
  //   }
  //
  //   console.log('#tving-talk - ui', { messageCollection, messageListTopRef: messageListTopRef.current });
  //
  //   const observer = new IntersectionObserver(
  //     (entries, observer) => {
  //       entries.forEach(async (entry) => {
  //         if (entry.isIntersecting) {
  //           console.log('#tving-talk - ui - scroll 111', {
  //             length: messageCollection.length,
  //             messageContainerRefScrollHeight: messageContainerRef.current?.scrollHeight,
  //             prevScrollHeightRef: prevScrollHeightRef.current,
  //           });
  //           prevScrollHeightRef.current = messageContainerRef.current?.scrollHeight || 0;
  //           void (await getPreviousMessages());
  //         }
  //       });
  //     },
  //     {
  //       threshold: 1,
  //       rootMargin: `${rootMargin}px`,
  //       root: messageContainerRef.current,
  //     },
  //   );
  //
  //   observer.observe(messageListTopRef.current);
  //
  //   return () => {
  //     if (!messageListTopRef.current) {
  //       return;
  //     }
  //
  //     observer.unobserve(messageListTopRef.current);
  //     observer.disconnect();
  //   };
  // }, [isBottom, messageCollection, getPreviousMessages]);

  useEffect(() => {
    setCanReceiveMessage(isBottom);
  }, [isBottom]);

  return {
    isBottom,
    messageContainerRef,
    messageListTopRef,
    messageListBottomRef,
    prevPositionRef,
  };
};

export default UseChatUiWithSendbird;
