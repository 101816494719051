import { CSSProperties } from 'react';

import Poster, { PosterProps } from '../../atom/poster/Poster';
import Text, { TextProps } from '../../atom/text/Text';
import Labels, { LabelsProps } from '../labels/Labels';

type basicItemProps = {
  className?: string;
  poster: PosterProps;
  posterText: TextProps<React.ElementType>;
  labels: LabelsProps;
  tabIndex?: number;
  style?: CSSProperties;
};

const BasicItem = ({ className, poster, labels, posterText, tabIndex, style }: basicItemProps) => {
  return (
    <div className={`basic-item-wrapper ${className}`} tabIndex={tabIndex} style={{ ...style }}>
      {posterText && <Text {...posterText} />}
      <Poster {...poster} />
      <Labels {...labels} />
    </div>
  );
};

export default BasicItem;
