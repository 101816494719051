import { kboScheduleApiProps } from '@apis/kbo/bff/fetchKboSchedule';
import dayjs from 'dayjs';

export const KBO_API_URLS = {
    // BFF 에서 HOME MAIN 만 예외적으로 받기 때문에 예외 처리
    HOME: (tab: string) => (tab === 'home' ? '/home' : `/home/${tab}`),
    SCHEDULE: ({ type, key }: kboScheduleApiProps) => `/home/schedule?${type}=${dayjs(key).format('YYYYMMDD')}`,
    SCHEDULE_DATE_PICKER: ({ date }: { date: string }) => `/schedule/day?date=${date}`,
    CONTENTS_MAIN: (gameCode: string) => `/live/${gameCode}`,
    CONTENTS_POWER: (gameCode: string) => `/live/${gameCode}/power`,
    CONTENTS_BROADCAST: (gameCode: string, inning?: number) => `/live/${gameCode}/broadcast${inning ? `?inning=${inning}` : ''}`,
    CONTENTS_POINT: (gameCode: string) => `/stream/${gameCode}/point`,
    CONTENTS_CLIP: (gameCode: string) => `/live/${gameCode}/clip`,
    CONTENTS_RUNS: (gameCode: string) => `/live/${gameCode}/runs`,
    CONTENTS_LINEUP: (gameCode: string) => `/live/${gameCode}/lineup`,
    CONTENTS_RECORD: (gameCode: string) => `/live/${gameCode}/record`,
    CONTENTS_MULTIVIEW: (gameCode: string) => `/stream/${gameCode}/multi-view`,
    RANKING: ({
        tab,
        year,
        season,
        playerType,
        rankOrder,
    }: {
        tab: string;
        year?: number;
        season?: string;
        playerType?: string;
        rankOrder?: string;
    }) => {
        if (!year && !season && !playerType && !rankOrder) {
            return `/home/history/${tab}`;
        }

        const params = new URLSearchParams();
        season && params.append('season', season);
        year && params.append('year', String(year));
        playerType && params.append('playerType', playerType);
        rankOrder && params.append('rankOrder', rankOrder);

        return `/home/history/${tab}?${params.toString()}`;
    },
    RANKING_SORTED: ({ year, season, playerType, rankOrder }: { year?: number; season?: string; playerType?: string; rankOrder?: string }) => {
        const rankOrderKey = playerType === 'pitcher' ? 'pitcherRankOrder' : playerType === 'hitter' ? 'hitterRankOrder' : null;

        if (!year && !playerType && !rankOrder) {
            return `/history/player/ranking`;
        }

        const params = new URLSearchParams();
        year && params.append('year', String(year));
        season && params.append('season', season);
        playerType && params.append('playerType', playerType);
        rankOrderKey && rankOrder && params.append(rankOrderKey, rankOrder);

        return `/history/player/ranking?${params.toString()}`;
    },
    CLIP_MAIN: (code: string) => `/content/clip/${code}`,
    GAME_LIST: (gameCode: string) => `/stream/${gameCode}/schedule`,
    MY_TEAMS: () => {
        return '/my/teams';
    },
    MY_TEAM_PUT: () => {
        return '/my-team/put';
    },
    MORE_VIDEO: ({ pathname, params }) => {
        if (params) {
            return `/more/clip/${pathname}?${params}`;
        }
        return `/more/clip/${pathname}`;
    },
    MORE_CURATION: ({ key }: { key: string }) => `/more/curation/${key}`,
    MORE_SCHEDULE: ({ query }: { query: string }) => {
        if (query) {
            return `/more/clip/playlist?${query}`;
        }
        return `/more/clip/playlist`;
    },
    ATHLETE: (athleteCode: string) => `/athlete?code=${athleteCode}`,
    ROASTER: (teamCode: string, position: string) => `/roaster?code=${teamCode}&position=${position}`,
    TEAM: ({ teamCode }: { teamCode: string }) => `/team?code=${teamCode}`,
};
