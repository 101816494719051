import { useContext } from 'react';

import CrossPlatformContext from '@utils/crossPlatform/react/CrossPlatformContext';

const useCrossPlatform = () => {
    const crossPlatformManager = useContext(CrossPlatformContext);
    if (crossPlatformManager === null) {
        throw new Error('CrossPlatformManager is not initialized');
    }

    return crossPlatformManager.crossPlatformController;
};

export default useCrossPlatform;
