import httpPost from '@utils/common/httpPost';
import { SENDBIRD_URL } from '@tving/utils/src/utils/common/apiConfig';
import { CHAT_API_URLS } from '@constants/chat';

type SendbirdTokenParameters = {
    accessToken: string;
    tvingToken: string;
};

export interface SendbirdTokenData {
    token: string;
    expiresAt: number;
    refreshAt: number;
}

export interface SendbirdTokenResponse {
    code: string;
    message: string;
    detailMessage: string;
    data: SendbirdTokenData;
}

// let requestSignal: AbortController;

const generateChatToken = async ({ accessToken, tvingToken }: SendbirdTokenParameters) => {
    // if (requestSignal) {
    //     requestSignal.abort();
    // }
    // requestSignal = new AbortController();

    try {
        const result = await httpPost<SendbirdTokenResponse>(`${SENDBIRD_URL}${CHAT_API_URLS.USER_TOKEN}`, {
            headers: {
                Authorization: `Bearer ${tvingToken}`,
                'Access-Token': accessToken,
            },
            // signal: requestSignal.signal,
            timeout: 5000,
        });

        result.data = { ...result.data, token: `${result.data.token}` };

        return result.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export default generateChatToken;
