import { imgOrigin } from '@utils/apiConfig';
import { Picture } from '@components/common/Picture';
import styled from '@emotion/styled';

const EventItem = ({ href, list_image, ratio, use_yn, title = '', onClick }) => {
    const eventClick = e => {
        onClick?.(e);
        window.open(href);
    };
    const isUse = use_yn === 'Y' || false;
    const ratioClassName = ratio ? `item__thumb-${ratio}` : '';

    return (
        <>
            {isUse && (
                <a className="thumb_position" onClick={eventClick}>
                    <div className={`item__thumb ${ratioClassName}`}>
                        <StyledEventDiv className="event_item item__image">
                            <Picture src={imgOrigin + list_image} size={0} />
                            {/* <ThumbnailImage thumbnail={imgOrigin + list_image} /> */}
                        </StyledEventDiv>
                    </div>
                </a>
            )}
        </>
    );
};
const StyledEventDiv = styled.div`
    > picture {
        > img {
            object-fit: cover;
        }
    }
`;

export default EventItem;
