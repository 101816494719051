import RankImg01 from '@img/ranking/1.svg';
import RankImg02 from '@img/ranking/2.svg';
import RankImg03 from '@img/ranking/3.svg';
import RankImg04 from '@img/ranking/4.svg';
import RankImg05 from '@img/ranking/5.svg';
import RankImg06 from '@img/ranking/6.svg';
import RankImg07 from '@img/ranking/7.svg';
import RankImg08 from '@img/ranking/8.svg';
import RankImg09 from '@img/ranking/9.svg';
import RankImg10 from '@img/ranking/10.svg';
import RankImg11 from '@img/ranking/11.svg';
import RankImg12 from '@img/ranking/12.svg';
import RankImg13 from '@img/ranking/13.svg';
import RankImg14 from '@img/ranking/14.svg';
import RankImg15 from '@img/ranking/15.svg';
import RankImg16 from '@img/ranking/16.svg';
import RankImg17 from '@img/ranking/17.svg';
import RankImg18 from '@img/ranking/18.svg';
import RankImg19 from '@img/ranking/19.svg';
import RankImg20 from '@img/ranking/20.svg';

const Ranking = ({ number = '' }) => {
    const getRankingImg = ({ rankNumber }) => {
        switch (rankNumber) {
            case 1:
                return <RankImg01 css={{ width: '1.34rem', height: '100%' }} />;
            case 2:
                return <RankImg02 css={{ width: '2.45rem', height: '100%' }} />;
            case 3:
                return <RankImg03 css={{ width: '2.24rem', height: '100%' }} />;
            case 4:
                return <RankImg04 css={{ width: '2.5rem', height: '100%' }} />;
            case 5:
                return <RankImg05 css={{ width: '2.27rem', height: '100%' }} />;
            case 6:
                return <RankImg06 css={{ width: '2.2rem', height: '100%' }} />;
            case 7:
                return <RankImg07 css={{ width: '2.22rem', height: '100%' }} />;
            case 8:
                return <RankImg08 css={{ width: '2.29rem', height: '100%' }} />;
            case 9:
                return <RankImg09 css={{ width: '2.2rem', height: '100%' }} />;
            case 10:
                return <RankImg10 css={{ width: '3.41rem', height: '100%' }} />;
            case 11:
                return <RankImg11 css={{ width: '2.7rem', height: '100%' }} />;
            case 12:
                return <RankImg12 css={{ width: '3.45rem', height: '100%' }} />;
            case 13:
                return <RankImg13 css={{ width: '3.32rem', height: '100%' }} />;
            case 14:
                return <RankImg14 css={{ width: '3.59rem', height: '100%' }} />;
            case 15:
                return <RankImg15 css={{ width: '3.45rem', height: '100%' }} />;
            case 16:
                return <RankImg16 css={{ width: '3.37rem', height: '100%' }} />;
            case 17:
                return <RankImg17 css={{ width: '3.44rem', height: '100%' }} />;
            case 18:
                return <RankImg18 css={{ width: '3.36rem', height: '100%' }} />;
            case 19:
                return <RankImg19 css={{ width: '3.41rem', height: '100%' }} />;
            case 20:
                return <RankImg20 css={{ width: '4.44rem', height: '100%' }} />;
            default:
                return '';
        }
    };

    return <div css={{ display: 'flex' }}>{getRankingImg({ rankNumber: number })}</div>;
};

export default Ranking;
