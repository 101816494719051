import { useSeasonList } from '@store/apis';
import useTranslation from 'next-translate/useTranslation';
import { isAndroid } from '@utils/device/device-detect';
import React from 'react';
import { movePurchaseContents, movePurchaseProductList } from '@utils/common/BillApi';
import { moveAdultCheckPage, moveMobileUserLogin, moveMobileUserLoginBackDetail, moveUserLoginBackDetail } from '@utils/common/UserApi';
import { useRouter } from 'next/router';
import { StyledContentActionButton, StyledContentFrequencyMoreButton } from './styles';
import { getAmplitudeEventParams } from '@utils/amplitude/contentDetail';
import amplitudeEvent from '@tving/utils/src/utils/amplitude/amplitudeEvent';
import getEventNameByDevice from '@utils/amplitude/eventName/getEventNameByDevice';
import getBasicEventProperties from '@utils/amplitude/eventProperties/getBasicEventProperties';

// 모바일웹 - 회원 회원가입/로그인 화면으로 이동
export const MoveToLoginWeb = ({ isPreRelease = false }) => {
    moveMobileUserLoginBackDetail({ isPreRelease });
};

export const MoveToLoginPageWithReturnUrl = ({ isMobile, returnUrl = '' }) => {
    if (isMobile) {
        // if (amplitudeEventType) {
        //     amplitudeEvent({
        //         event: amplitudeEventType,
        //         eventProperties: getBasicEventProperties({ screenName: 'kbo/home' }),
        //     });
        // }

        moveMobileUserLogin(returnUrl);
    } else {
        moveUserLoginBackDetail();
    }
};

// 회원 로그인 화면으로 이동 및 완료 시 이전 페이지로 이동 - 모바일/PC 구분
export const MoveToLoginPage = ({
    isMobile = false,
    isPreRelease = false,
    amplitudeEventType = null,
}: {
    isMobile?: boolean;
    isPreRelease?: boolean;
    amplitudeEventType?: string | null;
}) => {
    if (isMobile) {
        if (amplitudeEventType) {
            amplitudeEvent({
                event: amplitudeEventType,
                eventProperties: getBasicEventProperties({ screenName: 'onboarding' }),
            });
        }

        moveMobileUserLoginBackDetail({ isPreRelease });
    } else {
        moveUserLoginBackDetail();
    }
};
// 모바일웹 - 빌링 이용권 구매 페이지로 이동
export const MoveToBuyMembershipWeb = ({ mediaCode, isPreRelease = false }) => {
    movePurchaseProductList({ mediaCode, isPreRelease });
};

// 모바일웹 - 빌링 개별구매 결제화면으로 이동
export const MoveToBuyContentsWeb = ({ mediaCode, isPreRelease = false }) => {
    if (mediaCode !== '' && mediaCode.charAt(0) !== 'P') {
        movePurchaseContents({ mediaCode, isPreRelease });
    } else {
        MoveToBuyMembershipWeb({ mediaCode, isPreRelease });
    }
};

// 모바일웹 상세 - 앱 상세화면으로 이동
export const MoveToAppContentsDetail = ({ mediaCode, type }) => {
    let appUrl = '';

    if (isAndroid) {
        if (type === 'episode' || type === 'program') {
            // eslint-disable-next-line max-len
            appUrl = `intent://play?ver=0&type=ep&cd=${mediaCode}#Intent;scheme=tvingapp;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=net.cj.cjhv.gs.tving;end`;
        } else if (type === 'movie') {
            // eslint-disable-next-line max-len
            appUrl = `intent://play?ver=0&type=mv&cd=${mediaCode}#Intent;scheme=tvingapp;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=net.cj.cjhv.gs.tving;end`;
        }
        window.location.href = appUrl;
    } else {
        if (type === 'episode' || type === 'program') {
            appUrl = `tvingapp://play?ver=0&type=ep&cd=${mediaCode}`;
        } else if (type === 'movie') {
            appUrl = `tvingapp://play?ver=0&type=mv&cd=${mediaCode}`;
        }
        window.location.href = appUrl;
        setTimeout(() => {
            window.location.replace('https://itunes.apple.com/us/app/tving-for-iphone/id400101401');
        }, 2000);
    }
};

// 앱 공개예정 탭으로 이동
export const MoveToAppPrereleaseTab = ({ mediaCode }) => {
    let appUrl;
    if (isAndroid) {
        appUrl = `intent://com.tving.home/prerelease?code=${mediaCode}#Intent;scheme=tvingapp;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=net.cj.cjhv.gs.tving;end`;
        window.location.href = appUrl;
    } else {
        appUrl = `tvingapp://com.tving.home/prerelease?code=${mediaCode}`;
        window.location.href = appUrl;
        setTimeout(() => {
            window.location.replace('https://itunes.apple.com/us/app/tving-for-iphone/id400101401');
        }, 2000);
    }
};

// 앱 Open
export const MoveToAppHome = () => {
    let appUrl;
    if (isAndroid) {
        appUrl =
            'tvingapp://main#Intent;scheme=tvingapp;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=net.cj.cjhv.gs.tving;end';
        window.location.href = appUrl;
        setTimeout(() => {
            window.location.replace('market://details?id=net.cj.cjhv.gs.tving');
        }, 500);
    } else {
        appUrl = 'tvingapp://main';
        window.location.href = appUrl;
        setTimeout(() => {
            window.location.replace('https://itunes.apple.com/us/app/tving-for-iphone/id400101401');
        }, 2000);
    }
};

// 모바일웹 - 앱 테마관으로 이동
export const MoveToAppTheme = ({ themeCode = '' }) => {
    let appUrl;
    if (isAndroid) {
        appUrl = `intent://vod_page?page=theme_detail&cd=${themeCode}#Intent;scheme=tvingapp;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=net.cj.cjhv.gs.tving;end`;
        window.location.href = appUrl;
    } else {
        appUrl = `tvingapp://openDetail?type=vodTheme&cd=${themeCode}`;
        window.location.href = appUrl;
        setTimeout(() => {
            window.location.replace('https://itunes.apple.com/us/app/tving-for-iphone/id400101401');
        }, 2000);
    }
};

// 모바일웹 - 앱 웹뷰로 이동 (ex. 이벤트 페이지)
export const MoveToAppWebview = ({ webviewUrl = '' }) => {
    let appUrl;

    if (isAndroid) {
        appUrl = `intent://web?type=in&url=${encodeURIComponent(
            webviewUrl,
        )}&web_page_type=webview#Intent;scheme=tvingapp;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=net.cj.cjhv.gs.tving;end`;
        window.location.href = appUrl;
    } else {
        appUrl = `tvingapp://web?type=in&url=${encodeURIComponent(webviewUrl)}&web_page_type=webview`;
        window.location.href = appUrl;

        setTimeout(() => {
            window.location.replace('https://itunes.apple.com/us/app/tving-for-iphone/id400101401');
        }, 2000);
    }
};

// 모바일웹 - 앱 Live 하이라이트 리스트로 이동
export const MoveToAppLiveHighlight = ({ highlightCode = '' }) => {
    let appUrl;
    if (isAndroid) {
        appUrl = `intent://live_page?page=curation&cd=${highlightCode}#Intent;scheme=tvingapp;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=net.cj.cjhv.gs.tving;end`;
        window.location.href = appUrl;
    } else {
        appUrl = `tvingapp://live_page?page=curation&cd=${highlightCode}`;
        window.location.href = appUrl;
        setTimeout(() => {
            window.location.replace('https://itunes.apple.com/us/app/tving-for-iphone/id400101401');
        }, 2000);
    }
};

// 앱 이용권 구매 페이지로 이동
export const MoveToAppBuyMembership = () => {
    let appUrl;
    if (isAndroid) {
        appUrl =
            'intent://inappPurchase#Intent;scheme=tvingapp;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=net.cj.cjhv.gs.tving;end';
        window.location.href = appUrl;
    } else {
        appUrl = 'tvingapp://inappPurchase';
        window.location.href = appUrl;
        /* setTimeout(() => {
            window.location.replace('https://itunes.apple.com/us/app/tving-for-iphone/id400101401');
        }, 2000); */
    }
};

// 모바일웹 상세 - 티빙 시작하기 버튼
export const MobileStartTvingBtn = ({ isPreRelease = false }) => {
    const { t } = useTranslation('common');
    const buttonClick = () => {
        MoveToLoginWeb({ isPreRelease });
    };
    return (
        <StyledContentActionButton type="button" onClick={() => buttonClick()}>
            <span>{t('button.start_tving')}</span>
        </StyledContentActionButton>
    );
};

const UIActionPlayBtn = ({ buttonClick }) => {
    const { t } = useTranslation('common');
    return (
        <StyledContentActionButton type="button" onClick={buttonClick}>
            <span>{t('button.play')}</span>
        </StyledContentActionButton>
    );
};

// 모바일웹 상세 - 재생
export const MobilePlayBtn = ({ mediaCode, channelCode, channelName, programCode, programName, imageCode, categoryName1, categoryCode, type }) => {
    const buttonClick = () => {
        amplitudeEvent({
            event: getEventNameByDevice('play_contents'),
            eventProperties: getAmplitudeEventParams.baseContentMeta({
                mediaCode,
                channelCode,
                channelName,
                programCode,
                programName,
                maybeEpisodeCode: mediaCode,
                imageCode,
                categoryName: categoryName1,
                categoryCode,
            }),
        });

        MoveToAppContentsDetail({ mediaCode, type });
    };
    return <UIActionPlayBtn buttonClick={buttonClick} />;
};

// 모바일웹 상세 - 재생 -> 성인인증
export const MobileAdultCertBtn = () => {
    const { asPath } = useRouter();
    const buttonClick = () => {
        amplitudeEvent({
            event: getEventNameByDevice('enter_adult_identification'),
            eventProperties: getBasicEventProperties({ screenName: 'adult_certification' }),
        });

        moveAdultCheckPage({ returnUrl: asPath, isMobile: true });
    };
    return <UIActionPlayBtn buttonClick={buttonClick} />;
};

// 모바일웹 상세 - 이용권 구독하기
export const MobileBuyMembershipBtn = ({ mediaCode = '', isPreRelease = false }) => {
    const buttonClick = () => {
        MoveToBuyContentsWeb({ mediaCode, isPreRelease });
    };
    return (
        <StyledContentActionButton type="button" onClick={() => buttonClick()}>
            <span>이용권 구독하기</span>
        </StyledContentActionButton>
    );
};

// 모바일웹 상세 - 이용권 업그레이드하기
export const MobileUpgradeMembershipBtn = ({ mediaCode = '', isPreRelease = false }) => {
    const buttonClick = () => {
        MoveToBuyMembershipWeb({ mediaCode, isPreRelease });
    };
    return (
        <StyledContentActionButton type="button" onClick={() => buttonClick()}>
            <span>이용권 업그레이드하기</span>
        </StyledContentActionButton>
    );
};

// 모바일웹 상세 - 지금 구매하기
export const MobileBuyContentsBtn = ({ mediaCode, isPreRelease = false }) => {
    const { t } = useTranslation('common');
    const buttonClick = () => {
        MoveToBuyContentsWeb({ mediaCode, isPreRelease });
    };
    return (
        <StyledContentActionButton type="button" onClick={() => buttonClick()}>
            <span>{t('button.buy_contents')}</span>
        </StyledContentActionButton>
    );
};

// 모바일웹 상세 - 공개예정 더보기
export const MobileMorePrereleaseBtn = ({ mediaCode }) => {
    const { t } = useTranslation('common');
    const buttonClick = () => {
        MoveToAppPrereleaseTab({ mediaCode });
    };
    return (
        <StyledContentActionButton type="button" onClick={() => buttonClick()}>
            <span>{t('button.more_prerelease')}</span>
        </StyledContentActionButton>
    );
};

// 모바일웹 상세 - 더 많은 회차가 보고싶다면, 티빙 시작하기
export const MobileMoreEpiStartTvingBtn = ({ isPreRelease = false }) => {
    const { t } = useTranslation('common');
    const buttonClick = () => {
        MoveToLoginWeb({ isPreRelease });
    };
    return (
        <StyledContentFrequencyMoreButton onClick={() => buttonClick()}>
            {t('button.if_want_more_episodes')}, <span>{t('button.start_tving')}</span>
        </StyledContentFrequencyMoreButton>
    );
};
// 모바일웹 상세 - 더 많은 회차가 보고싶다면, 앱으로 보기
export const MobileMoreEpiOpenAppBtn = ({ mediaCode }) => {
    const { t } = useTranslation('common');
    const buttonClick = () => {
        MoveToAppContentsDetail({ mediaCode, type: 'program' });
    };
    return (
        <StyledContentFrequencyMoreButton onClick={() => buttonClick()}>
            {t('button.if_want_more_episodes')}, <span>{t('button.watch_app')}</span>
        </StyledContentFrequencyMoreButton>
    );
};
// 모바일웹 상세 - 더 많은 회차가 보고싶다면, 지금 구독하기
export const MobileMoreEpiBuyMembershipBtn = ({ mediaCode, isPreRelease = false }) => {
    const { t } = useTranslation('common');
    const buttonClick = () => {
        MoveToBuyContentsWeb({ mediaCode, isPreRelease });
    };
    return (
        <StyledContentFrequencyMoreButton onClick={() => buttonClick()}>
            {t('button.if_want_more_episodes')}, <span>{t('button.buy_membership')}</span>
        </StyledContentFrequencyMoreButton>
    );
};

export const AgeGradeTag = ({ ageGrade, detailYn = false, type = '' }: { ageGrade: string; detailYn?: boolean; type?: string }) => {
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {detailYn === true ? (
                <>
                    {/* 관람등급 - 전체관람가 / 7세관람가 / 12세관람가 / 15세관람가 / 19세관람가 */}
                    {ageGrade === 'all' &&
                        (type === 'movie' ? <div className="tag tag-age tag-age-all" /> : <div className="tag tag-blank">전체관람가</div>)}
                    {ageGrade === '7' && (type === 'movie' ? '' : <div className="tag tag-blank">7+</div>)}
                    {ageGrade === '12' &&
                        (type === 'movie' ? <div className="tag tag-age tag-age-twelve" /> : <div className="tag tag-blank">12+</div>)}
                    {ageGrade === '15' &&
                        (type === 'movie' ? <div className="tag tag-age tag-age-fifteen" /> : <div className="tag tag-blank">15+</div>)}
                    {ageGrade === '18' &&
                        (type === 'movie' ? <div className="tag tag-age tag-age-eighteen" /> : <div className="tag tag-blank">18+</div>)}
                    {ageGrade === '19' &&
                        (type === 'movie' ? <div className="tag tag-age tag-age-nineteen" /> : <div className="tag tag-blank">19+</div>)}
                </>
            ) : (
                <>
                    {ageGrade === '18' && <div className="tag tag-age tag-age-eighteen" />}
                    {ageGrade === '19' && <div className="tag tag-age tag-age-nineteen" />}
                </>
            )}
        </>
    );
};

export const NewAgeGradeTag = ({ gradeCode }) => {
    return (
        <>
            {/* CPTG0100 - 방통위 All */}
            {gradeCode === 'CPTG0100' && <div className="tag tag-age tag-age-cptg-all" />}
            {/* CPTG0200 - 방통위 7세 */}
            {gradeCode === 'CPTG0200' && <div className="tag tag-age tag-age-cptg-seven" />}
            {/* CPTG0300 - 방통위 12세 */}
            {gradeCode === 'CPTG0300' && <div className="tag tag-age tag-age-cptg-twelve" />}
            {/* CPTG0400 - 방통위 15세 */}
            {gradeCode === 'CPTG0400' && <div className="tag tag-age tag-age-cptg-fifteen" />}
            {/* CPTG0500 - 방통위 19세 */}
            {gradeCode === 'CPTG0500' && <div className="tag tag-age tag-age-cptg-nineteen" />}

            {/* CMMG0100 - 영등위 All */}
            {gradeCode === 'CMMG0100' && <div className="tag tag-age tag-age-cmmg-all" />}
            {/* CMMG0200 - 영등위 12세 */}
            {gradeCode === 'CMMG0200' && <div className="tag tag-age tag-age-cmmg-twelve" />}
            {/* CMMG0300 - 영등위 15세 */}
            {gradeCode === 'CMMG0300' && <div className="tag tag-age tag-age-cmmg-fifteen" />}
            {/* CMMG0400 - 영등위 19세 */}
            {gradeCode === 'CMMG0400' && <div className="tag tag-age tag-age-cmmg-nineteen" />}
        </>
    );
};

export const SeasonCountTag = ({ type, seasonCode }) => {
    const { t } = useTranslation('common');
    // 시즌 개수 표시

    const seasonList = useSeasonList({ type, seasonCode }) as {
        data: {
            items: {
                season_etc_vod_yn: string;
            }[];
        };
    };
    // 시즌 라벨 카운팅 시'기타영상'이 체크된 시즌은 카운팅에서 제외
    const validSeasonList = seasonList?.data?.items?.filter((d) => d.season_etc_vod_yn === 'N') || [];
    let seasonCount = seasonCode && validSeasonList?.length; // seasoncode 값이 없는 경우는 개수 1개 기본값, 있는 경우는 seasonList N 개 노출

    // seasoncode 값이 있는 경우인데도 불구하고, validSeasonList length 값이 0인 경우가 있음. ex) P000932642 [상용]
    if (seasonCount < 1) {
        seasonCount = 1;
    }
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            <div className="tag tag-blank">
                {t('content.season')} {seasonCount}
                {t('content.count')}
            </div>
        </>
    );
};
