/* eslint-disable class-methods-use-this,no-console,no-underscore-dangle */
import CrossPlatformController from '@utils/crossPlatform/CrossPlatformController';
import {
    CrossPlatformAppConfigurationEvent,
    CrossPlatformChangeMediaOptions,
    CrossPlatformChangeOrientationOptions,
    CrossPlatformChangePlayerFeatureOptions,
    CrossPlatformChangePlayerStatusEvent,
    CrossPlatformClipPlayConfigurationChangeEvent,
    CrossPlatformConfirmDialogEvent,
    CrossPlatformEvent,
    CrossPlatformGetContentInfoEvent,
    CrossPlatformHapticFeedbackOptions,
    CrossPlatformMediaChangeEvent,
    CrossPlatformOpenTvingTalkOptions,
    CrossPlatformPageInitializedOptions,
    CrossPlatformPushNotificationPermissionEvent,
    CrossPlatformRect,
    CrossPlatformRequestContentChangeEvent,
    CrossPlatformSafeAreaEvents,
    CrossPlatformServiceNotification,
    CrossPlatformShareLinkOptions,
    CrossPlatformShowDialogOptions,
    CrossPlatformShowNotificationOptions,
    CrossPlatformModeChangeEvent,
    CrossPlatformType,
    IosHapticFeedbackType,
    Orientation,
    CrossPlatformOpenUrlOptions,
    CrossPlatformSendBrazeCustomEventOptions,
    CrossPlatformChangePlayerModeEvent,
    CrossPlatformShowBottomSheetEventOptions,
    CrossPlatformConfirmBottomSheetEvent,
    CrossPlatformModerateTvintTalkEvent,
    CrossPlatformModerateTvintTalkEventOptions,
} from '@utils/crossPlatform/types';
import { PcPlayerInfo } from '@store/state';
import { KeyedMutator } from 'swr';
import { isServer } from '@utils/device';
import { KboContentsResponse } from '@typings/kbo';
import { KboBand } from '@typings/kbo/band';
import { KboMultiviewItem } from '@typings/kbo/item';
import isBroadcasting from '@utils/kbo/isBroadcasting/isBroadcasting';
import { CrossPlatformContentInsetEvents } from '@utils/crossPlatform';

declare global {
    interface Window {
        webkit: {
            messageHandlers: Record<
                string,
                {
                    postMessage: (data?: string) => void;
                }
            >;
        };
    }
}

class IosCrossPlatformController implements CrossPlatformController {
    private _setPlayerInfo: KeyedMutator<PcPlayerInfo> | null = null;

    set setPlayerInfo(value: KeyedMutator<PcPlayerInfo> | null) {
        this._setPlayerInfo = value;
    }

    constructor() {
        if (isServer()) {
            return;
        }

        window.onMediaStatusPlayer = this.onMediaStatusPlayer.bind(this);
        window.onBackPressed = this.onBackPressed.bind(this);
        window.onChangePlayerSize = this.onChangePlayerSize.bind(this);
        window.onMediaChange = this.onMediaChange.bind(this);
        window.getContentInfo = this.getContentInfo.bind(this);
        window.onPushNotificationPermission = this.onPushNotificationPermission.bind(this);
        window.onChangeOrientation = this.onChangeOrientation.bind(this);
        window.onConfirmDialog = this.onConfirmDialog.bind(this);
        window.onStatusUpdate = this.onStatusUpdate.bind(this);
        window.onStart = this.onStart.bind(this);
        window.onStop = this.onStop.bind(this);
        window.onChangeContentInset = this.onChangeContentInset.bind(this);
        window.onDismissedContentDetail = this.onDismissedContentDetail.bind(this);
        window.onForeground = this.onForeground.bind(this);
        window.onBackground = this.onBackground.bind(this);
        window.onTimeMachineChange = this.onTimeMachineChange.bind(this);
        window.getServiceNotification = this.getServiceNotification.bind(this);
        window.setServiceNotification = this.setServiceNotification.bind(this);
        window.onServiceNotification = this.onServiceNotification.bind(this);
        window.onClipPlayConfiguration = this.onClipPlayConfiguration.bind(this);
        window.onRequestContentChange = this.onRequestContentChange.bind(this);
        window.getInitAppConfigurationInfo = this.getInitAppConfigurationInfo.bind(this);
        window.onInitAppConfigurationInfo = this.onInitAppConfigurationInfo.bind(this);
        window.onChangePlayerStatus = this.onChangePlayerStatus.bind(this);
        window.onRequestNextContent = this.onRequestNextContent.bind(this);
        window.onMultiviewChange = this.onMultiviewChange.bind(this);
        window.onChangePlayerFeature = this.onChangePlayerFeature.bind(this);
        window.onChangePlayerMode = this.onChangePlayerMode.bind(this);
        window.onConfirmBottomSheet = this.onConfirmBottomSheet.bind(this);
        window.onModerateTvingTalk = this.onModerateTvingTalk.bind(this);
    }

    getCrossPlatformType(): CrossPlatformType {
        return CrossPlatformType.IOS;
    }

    pageInitialized(crossPlatformPageInitializedOptions: CrossPlatformPageInitializedOptions): void {
        console.log('[IosCrossPlatformController] pageInitialized');
        this.appInterface('pageInitialized', crossPlatformPageInitializedOptions);
    }

    reloadWebview(): void {
        console.log('[IosCrossPlatformController] reloadWebview');
        this.appInterface('reloadWebview');
    }

    getContentInfo(contentInfo: CrossPlatformGetContentInfoEvent): void {
        console.log('[IosCrossPlatformController] getContentInfo');
        const customEvent = new CustomEvent('getContentInfo', { detail: contentInfo });
        window.dispatchEvent(customEvent);
    }

    onMediaChange(event: CrossPlatformMediaChangeEvent): void {
        console.log('[IosCrossPlatformController] onMediaChanged');
        const customEvent = new CustomEvent('onMediaChanged', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onMediaStatusPlayer(event: CrossPlatformEvent): void {
        console.log('[IosCrossPlatformController] onMediaStatusPlayer');
        const customEvent = new CustomEvent('onMediaStatusPlayer', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onStatusUpdate(event: unknown): void {
        console.log('[IosCrossPlatformController] onStatusUpdate');
        const customEvent = new CustomEvent('onStatusUpdate', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onStart(): void {
        console.log('[IosCrossPlatformController] onStart');
    }

    onStop(): void {
        console.log('[IosCrossPlatformController] onStop');
    }

    visiblePlayer(): void {
        console.log('[IosCrossPlatformController] visiblePlayer');
        this.appInterface('visiblePlayer');
    }

    hiddenPlayer(): void {
        console.log('[IosCrossPlatformController] hiddenPlayer');
        this.appInterface('hiddenPlayer');
    }

    changePlayerMedia(changeMediaOptions: CrossPlatformChangeMediaOptions): void {
        console.log('[IosCrossPlatformController] changePlayerMedia');
        this.appInterface('changePlayerMedia', changeMediaOptions);
    }

    stopPlayer(): void {
        console.log('[IosCrossPlatformController] stopPlayer');

        if (this._setPlayerInfo) {
            void this._setPlayerInfo((prev) => ({
                ...prev,
                pathname: '',
                mediaCode: '',
                isPreparing: true,
                isPlaying: false,
                playbackRate: 1,
                isPlayingTimeshift: false,
            }));
        }

        this.appInterface('stopPlayer');
    }

    playPlayer(): void {
        console.log('[IosCrossPlatformController] playPlayer');
        this.appInterface('playPlayer');
    }

    pausePlayer(): void {
        console.log('[IosCrossPlatformController] pausePlayer');
        this.appInterface('pausePlayer');
    }

    showPlayerNotification(showNotificationOptions: CrossPlatformShowNotificationOptions): void {
        console.log('[IosCrossPlatformController] showPlayerNotification');
        this.appInterface('showPlayerNotification', showNotificationOptions);
    }

    hidePlayerNotification(): void {
        console.log('[IosCrossPlatformController] hidePlayerNotification');
        this.appInterface('hidePlayerNotification');
    }

    showPlayerController(): void {
        console.log('[IosCrossPlatformController] showPlayerController');
        this.appInterface('showPlayerController');
    }

    hidePlayerController(): void {
        console.log('[IosCrossPlatformController] hidePlayerController');
        this.appInterface('hidePlayerController');
    }

    changePlayerSize(playerSizeOptions: CrossPlatformRect): void {
        console.log('[IosCrossPlatformController] changePlayerSize');
        this.appInterface('changePlayerSize', playerSizeOptions);
    }

    onChangePlayerSize(playerSizeOptions: CrossPlatformRect): void {
        console.log('[IosCrossPlatformController] onChangePlayerSize');
        const customEvent = new CustomEvent('onChangePlayerSize', { detail: playerSizeOptions });
        window.dispatchEvent(customEvent);
    }

    getPushNotificationPermission(): void {
        console.log('[IosCrossPlatformController] getPushNotificationPermission');
        this.appInterface('getPushNotificationPermission');
    }

    onPushNotificationPermission(event: CrossPlatformPushNotificationPermissionEvent): void {
        console.log('[IosCrossPlatformController] onPushNotificationPermission');
        const customEvent = new CustomEvent('onPushNotificationPermission', { detail: event });
        window.dispatchEvent(customEvent);
    }

    needsPushNotificationPermission(): void {
        console.log('[IosCrossPlatformController] needsPushNotificationPermission');
        this.appInterface('needsPushNotificationPermission');
    }

    pushWebView(url: string): void {
        console.log('[IosCrossPlatformController] pushWebView');
        this.appInterface('pushWebView', { url });
    }

    modalWebView(url: string): void {
        console.log('[IosCrossPlatformController] modalWebView');
        this.appInterface('modalWebView', { url });
    }

    closeWebView(): void {
        console.log('[IosCrossPlatformController] closeWebView');
        this.appInterface('closeWebView');
    }

    showContentDetail(code: string): void {
        console.log('[IosCrossPlatformController] showContentDetail');
        this.appInterface('showContentDetail', { code });
    }

    showShorts(code: string, params: string, screenName: string, id: number): void {
        console.log('[IosCrossPlatformController] showShorts');
        this.appInterface('showShorts', { code, params, screenName, id });
    }

    showContentDetailWebView(code?: string, url?: string): void {
        console.log('[IosCrossPlatformController] showContentDetailWebView');
        this.appInterface('showContentDetailWebView', { code, url });
    }

    onBackPressed(): void {
        console.log('[IosCrossPlatformController] onBackPressed');
        this.appInterface('onBackPressed');
    }

    hapticFeedback(
        hapticFeedbackOptions: CrossPlatformHapticFeedbackOptions = {
            hapticType: IosHapticFeedbackType.SUCCESS,
        },
    ): void {
        console.log('[IosCrossPlatformController] hapticFeedback');
        this.appInterface('hapticFeedback', hapticFeedbackOptions);
    }

    changeOrientation(changeOrientationOptions: CrossPlatformChangeOrientationOptions): void {
        console.log('[IosCrossPlatformController] changeOrientation');
        this.appInterface('changeOrientation', changeOrientationOptions);
    }

    openAppSystemSetting(): void {
        console.log('[IosCrossPlatformController] openAppSystemSetting');
        this.appInterface('openAppSystemSetting');
    }

    showDialog(showDialogOptions: CrossPlatformShowDialogOptions): void {
        console.log('[IosCrossPlatformController] showDialog');
        this.appInterface('showDialog', showDialogOptions);
    }

    onConfirmDialog(event: CrossPlatformConfirmDialogEvent): void {
        console.log('[IosCrossPlatformController] onConfirmDialog');
        const customEvent = new CustomEvent('onConfirmDialog', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onChangeOrientation(event: Orientation): void {
        console.log('[IosCrossPlatformController] onChangeOrientation');
        const customEvent = new CustomEvent('onChangeOrientation', { detail: event });
        window.dispatchEvent(customEvent);
    }

    openTvingTalk(openTvingTalkOptions: CrossPlatformOpenTvingTalkOptions): void {
        console.log('[IosCrossPlatformController] openTvingTalk');
        this.appInterface('openTvingTalk', openTvingTalkOptions);
    }

    shareLink(shareLinkOptions: CrossPlatformShareLinkOptions): void {
        console.log('[IosCrossPlatformController] shareLink');
        this.appInterface('shareLink', shareLinkOptions);
    }

    getSafeArea() {
        console.log('[IosCrossPlatformController] getSafeArea');
        // this.appInterface('getSafeArea');
        // Android Only!
    }

    onSafeArea(event: CrossPlatformSafeAreaEvents): void {
        console.log('[IosCrossPlatformController] onSafeArea', event);
        // Android Only!
    }

    onChangeContentInset(event: CrossPlatformContentInsetEvents) {
        console.log('[IosCrossPlatformController] onChangeContentInset');
        const customEvent = new CustomEvent('onChangeContentInset', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onDismissedContentDetail() {
        console.log('[IosCrossPlatformController] onDismissedContentDetail');
        const customEvent = new CustomEvent('onDismissedContentDetail');
        window.dispatchEvent(customEvent);
    }

    onTimeMachineChange(event: CrossPlatformModeChangeEvent) {
        console.log('[IosCrossPlatformController] onTimeMachineChange');
        const customEvent = new CustomEvent('onTimeMachineChange', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onForeground(): void {
        console.log('[IosCrossPlatformController] onForeground');
        const customEvent = new CustomEvent('onForeground');
        window.dispatchEvent(customEvent);
    }

    onBackground(): void {
        console.log('[IosCrossPlatformController] onBackground');
        const customEvent = new CustomEvent('onBackground');
        window.dispatchEvent(customEvent);
    }

    changePlayerFeature(changePlayerFeatureOptions: CrossPlatformChangePlayerFeatureOptions) {
        console.log('[IosCrossPlatformController] changePlayerFeature');
        this.appInterface('changePlayerFeature', changePlayerFeatureOptions);
    }

    getClipPlayConfiguration() {
        console.log('[IosCrossPlatformController] getClipPlayConfiguration');
        this.appInterface('getClipPlayConfiguration');
    }

    setClipPlayConfiguration(isContinuous: boolean) {
        console.log('[IosCrossPlatformController] setClipPlayConfiguration');
        this.appInterface('setClipPlayConfiguration', { isContinuous });
    }

    onClipPlayConfiguration(event: CrossPlatformClipPlayConfigurationChangeEvent) {
        console.log('[IosCrossPlatformController] onClipPlayConfiguration');
        const customEvent = new CustomEvent('onClipPlayConfiguration', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onRequestContentChange(event: CrossPlatformRequestContentChangeEvent): void {
        console.log('[IosCrossPlatformController] onRequestContentChange');
        const customEvent = new CustomEvent('onRequestContentChange', { detail: event });
        window.dispatchEvent(customEvent);
    }

    appInterface(method: string, params: unknown = ''): void {
        console.log('[IosCrossPlatformController] appInterface');
        if (!window.webkit || !window.webkit.messageHandlers) {
            // eslint-disable-next-line no-alert
            // alert('appInterface is not defined', method);
            console.error('appInterface is not defined', method);
            return;
        }
        try {
            window.webkit.messageHandlers[method].postMessage(JSON.stringify(params));
        } catch (e) {
            console.error('[IosCrossPlatformController] appInterface error', method, params, e);
        }
    }

    setServiceNotification(serviceNotification: CrossPlatformServiceNotification) {
        console.log('[IosCrossPlatformController] setServiceNotification');
        this.appInterface('setServiceNotification', serviceNotification);
    }

    getServiceNotification() {
        this.appInterface('getServiceNotification');
    }

    onServiceNotification(event: CrossPlatformServiceNotification): void {
        console.log('[IosCrossPlatformController] onServiceNotification');
        const customEvent = new CustomEvent('onServiceNotification', { detail: event });
        window.dispatchEvent(customEvent);
    }

    getInitAppConfigurationInfo() {
        console.log('[IosCrossPlatformController] getInitAppConfigurationInfo');
        this.appInterface('getInitAppConfigurationInfo');
    }

    onInitAppConfigurationInfo(event: CrossPlatformAppConfigurationEvent) {
        console.log('[IosCrossPlatformController] onInitAppConfigurationInfo');
        const customEvent = new CustomEvent('onInitAppConfigurationInfo', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onChangePlayerStatus(event: CrossPlatformChangePlayerStatusEvent): void {
        console.log('[IosCrossPlatformController] onChangePlayerStatus');
        const customEvent = new CustomEvent('onChangePlayerStatus', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onRequestNextContent(): void {
        console.log('[IosCrossPlatformController] onRequestNextContent');
        const customEvent = new CustomEvent('onRequestNextContent');
        window.dispatchEvent(customEvent);
    }

    didChangeInputFocus(isFocus: boolean): void {
        console.log('[IosCrossPlatformController] didChangeInputFocus');
        this.appInterface('didChangeInputFocus', { isFocus });
    }

    openUrl(openUrlOptions: CrossPlatformOpenUrlOptions): void {
        console.log('[IosCrossPlatformController] openUrl');
        this.appInterface('openUrl', openUrlOptions);
    }

    sendBrazeCustomEvent(sendBrazeCustomEventOptions: CrossPlatformSendBrazeCustomEventOptions) {
        console.log('[IosCrossPlatformController] sendBrazeCustomEvent');
        const options = {
            ...sendBrazeCustomEventOptions,
            eventProperties: sendBrazeCustomEventOptions.eventProperties || {},
        };
        this.appInterface('sendBrazeCustomEvent', options);
    }

    openAppStore() {
        this.openUrl({ url: 'itms-apps://itunes.apple.com/kr/app/tibing-tving/id400101401?mt=8' });
    }

    transferMultiviewApiResponse(multiviewApiResponse: KboContentsResponse<KboBand<KboMultiviewItem>>) {
        console.log('[IosCrossPlatformController] transferMultiviewApiResponse', multiviewApiResponse);
        const multiviewItems = multiviewApiResponse?.data?.bands?.[0]?.items;

        if (!multiviewItems || multiviewItems.length < 1) {
            this.appInterface('transferMultiviewApiResponse', multiviewApiResponse);
            return;
        }

        const filteredMultiviewItems = multiviewItems.filter((item) =>
            isBroadcasting({
                broadcastStartTime: item.broadcastStartTime,
                broadcastEndTime: item.broadcastEndTime,
            }),
        );
        const filteredResponse = {
            ...multiviewApiResponse,
            data: {
                ...multiviewApiResponse.data,
                bands: [
                    {
                        ...multiviewApiResponse.data.bands[0],
                        items: filteredMultiviewItems,
                    },
                ],
            },
        };

        this.appInterface('transferMultiviewApiResponse', filteredResponse);
    }

    onMultiviewChange(event: CrossPlatformModeChangeEvent) {
        console.log('[IosCrossPlatformController] onMultiviewChange');
        const customEvent = new CustomEvent('onMultiviewChange', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onChangePlayerFeature(event: CrossPlatformChangePlayerFeatureOptions) {
        console.log('[IosCrossPlatformController] onChangePlayerFeature', event);
        const customEvent = new CustomEvent('onChangePlayerFeature', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onChangePlayerMode(event: CrossPlatformChangePlayerModeEvent): void {
        console.log('[IosCrossPlatformController] onChangePlayerMode');
        const customEvent = new CustomEvent('onChangePlayerMode', { detail: event });
        window.dispatchEvent(customEvent);
    }

    showBottomSheet(showBottomSheetOptions: CrossPlatformShowBottomSheetEventOptions) {
        console.log('[IosCrossPlatformController] showBottomSheet');
        this.appInterface('showBottomSheet', showBottomSheetOptions);
    }

    onConfirmBottomSheet(event: CrossPlatformConfirmBottomSheetEvent): void {
        console.log('[IosCrossPlatformController] onConfirmDialog');
        const customEvent = new CustomEvent('onConfirmBottomSheet', { detail: event });
        window.dispatchEvent(customEvent);
    }

    getTvingTalkModeration() {
        console.log('[IosCrossPlatformController] getTvingTalkModeration');
        this.appInterface('getTvingTalkModeration');
    }

    reportMessage(messageId: number) {
        console.log('[IosCrossPlatformController] reportMessage');
        this.appInterface('reportMessage', { id: messageId });
    }

    moderateTvingTalk(moderateTvingTalkEventOptions: CrossPlatformModerateTvintTalkEventOptions) {
        console.log('[IosCrossPlatformController] moderateTvingTalk', { moderateTvingTalkEventOptions });
        this.appInterface('moderateTvingTalk', moderateTvingTalkEventOptions);
    }

    onModerateTvingTalk(event: CrossPlatformModerateTvintTalkEvent) {
        console.log('[IosCrossPlatformController] onModerateTvingTalk');
        const customEvent = new CustomEvent('onModerateTvingTalk', { detail: event });
        window.dispatchEvent(customEvent);
    }
}

export default IosCrossPlatformController;
