import React, { useCallback, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import useCrossPlatform from '@utils/crossPlatform/react/useCrossPlatform';
import Swal from 'sweetalert2/dist/sweetalert2';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import { CHAT_ERRORS, useChatConfig } from '@tving/utils/src/contexts/ChatContext';
import useChatConnectionWithSendbird from '@tving/utils/src/hooks/common/chat/useChatConnectionWithSendbird';
import useDeviceOrientation from '@tving/utils/src/hooks/common/useDeviceOrientation';
import useDelayedNetwork from '@tving/utils/src/utils/network/useDelayedNetwork';

import ChatWrap from '@components/chat/ChatWrap';
import { SendbirdSession } from '@hooks/chat/useSendbirdSession';
import IconKboLogo from '@img/chat/icon_kbo_logo.svg';
import IconKboRefresh from '@img/kbo/common/icon_refresh.svg';
import { useUserInfo } from '@store/state';
import { CrossPlatformChangePlayerFeatureOptions, CrossPlatformConfirmDialogEvent, CrossPlatformModerateTvintTalkEvent } from '@utils/crossPlatform';
import { isAndroid } from '@utils/device';

type Props = {
    sendbirdSession: SendbirdSession | undefined;
};

const ChatManager = ({ sendbirdSession }: Props) => {
    const router = useRouter();
    const [{ profileNo: profileNumber, profileName: nickname, profileImgPath: profileUrl }] = useUserInfo();
    const userInfo = { profileNumber, nickname, profileUrl };
    const { sendbirdChat, user } = useChatConnectionWithSendbird({ userInfo, sendbirdToken: sendbirdSession?.token });
    const { channelUrl, isFrozen, error, setReportedMessages, setHiddenPinnedMessages, refetchSession, setError, clearError, infoIcon } =
        useChatConfig();
    const [, forceUpdate] = useState(0);
    const crossPlatformController = useCrossPlatform();
    const errorTrigger = useDelayedNetwork({ config: { delay: 10000 } });
    useDeviceOrientation({
        onPortrait: () => {
            crossPlatformController.getTvingTalkModeration();
        },
        onLandscape: () => {
            crossPlatformController.getTvingTalkModeration();
        },
    });

    const {
        query: { tab },
    } = router;

    const triggerRerender = useCallback(async () => {
        await refetchSession();
        clearError();
        forceUpdate((prev) => prev + 1);
    }, []);

    useEffect(() => {
        if (errorTrigger) {
            setError(CHAT_ERRORS.NETWORK_ERROR);
        }
    }, [errorTrigger]);

    useEffect(() => {
        if (!error) {
            return;
        }

        if (error.displayType === 'modal') {
            crossPlatformController.showDialog(
                {
                    id: 'modal_manager',
                    message: error.errorMessage,
                    confirmButton: '확인',
                    cancelButton: '',
                },
                (isConfirmed) => {
                    if (isConfirmed) {
                        clearError();
                    }
                },
            );

            if (isAndroid()) {
                clearError();
            }
        }

        return () => {
            clearError();
            Swal.close();
        };
    }, [error, channelUrl]);

    useEffect(() => {
        const onConfirmDialogHandler = (event: CustomEvent<CrossPlatformConfirmDialogEvent>) => {
            const { id } = event.detail;

            if (id !== 'modal_manager') {
                return;
            }

            clearError();
        };

        window.addEventListener('onConfirmDialog', onConfirmDialogHandler);

        return () => {
            window.removeEventListener('onConfirmDialog', onConfirmDialogHandler);
        };
    }, [clearError]);

    useEffect(() => {
        const onModerateTvingTalkHandler = (event: CustomEvent<CrossPlatformModerateTvintTalkEvent>) => {
            const { reportedIdList: reportedMessagesId, hiddenPinnedMessageIdList: hiddenPinnedMessagesId } = event.detail;

            const reportedMessagesIdMap =
                reportedMessagesId?.reduce((acc, cur) => {
                    acc[cur] = true;
                    return acc;
                }, {}) ?? {};
            const hiddenPinnedMessagesIdMap =
                hiddenPinnedMessagesId?.reduce((acc, cur) => {
                    acc[cur] = true;
                    return acc;
                }, {}) ?? {};

            setReportedMessages(reportedMessagesIdMap);
            setHiddenPinnedMessages(hiddenPinnedMessagesIdMap);
        };

        window.addEventListener('onModerateTvingTalk', onModerateTvingTalkHandler);

        return () => {
            window.removeEventListener('onModerateTvingTalk', onModerateTvingTalkHandler);
        };
    }, []);

    useEffect(() => {
        crossPlatformController.getTvingTalkModeration();
    }, [channelUrl, crossPlatformController]);

    useEffect(() => {
        const onChangePlayerFeatureHandler = (event: CustomEvent<CrossPlatformChangePlayerFeatureOptions>) => {
            crossPlatformController.getTvingTalkModeration();
        };

        window.addEventListener('onChangePlayerFeature', onChangePlayerFeatureHandler);

        return () => {
            window.removeEventListener('onChangePlayerFeature', onChangePlayerFeatureHandler);
        };
    }, [crossPlatformController]);

    if (error?.displayType === 'layout') {
        return (
            <div className={tempCnx('flex-center flex-col relative w-full', error?.withRefresh ? 'h-full' : 'pt-[5rem]')}>
                {infoIcon ? (
                    <div className="flex-center w-[10rem] h-[10rem] rounded-full bg-[#191919] ">{infoIcon}</div>
                ) : (
                    <IconKboLogo width="10rem" height="10rem" />
                )}
                <p
                    className={tempCnx(
                        'mt-[1.333rem] text-[1.333rem] text-center leading-normal whitespace-pre-line',
                        error?.withRefresh ? '' : 'text-gray-400',
                    )}
                >
                    {error.errorMessage}
                </p>
                {error?.withRefresh ? (
                    <button type="button" className="flex-center mt-8 px-8 py-4 rounded-[0.333rem] bg-white" onClick={triggerRerender}>
                        <IconKboRefresh className="w-[1.667rem] h-[1.667rem]" />
                        <span className="ml-[0.333rem] text-[1.333rem] font-[700] text-black leading-normal">새로고침</span>
                    </button>
                ) : null}
                {error?.bodyComponent}
            </div>
        );
    }

    return <ChatWrap sendbirdChat={sendbirdChat} user={user} />;
};

export default ChatManager;
