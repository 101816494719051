import Poster, { PosterProps } from '../../atom/poster/Poster';
import Text, { TextProps } from '../../atom/text/Text';
import Labels, { LabelsProps } from '../labels/Labels';

type episodeItemProps = {
  className?: string;
  poster: PosterProps;
  posterText: TextProps<React.ElementType>;
  labels: LabelsProps;
  text: {
    className: string;
    items: { tag?: React.ElementType<any>; title: string; className?: string; tabindex?: number }[];
  };
  tabIndex?: number;
};

const EpisodeItem = ({ className, poster, text, posterText, tabIndex, labels }: episodeItemProps) => {
  return (
    <>
      <div className={`episode-item-wrapper ${className}`} tabIndex={tabIndex}>
        {posterText && <Text {...posterText} />}
        <Poster {...poster} />
        <Labels {...labels} />
      </div>
      <div className={text.className}>
        {text.items.map((text) => {
          return <Text key={text.title} tag={text.tag} title={text.title} className={text.className} tabIndex={tabIndex} />;
        })}
      </div>
    </>
  );
};

export default EpisodeItem;
