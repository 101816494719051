export const HORIZONTAL_IMAGE_CODE = {
    episode: 'CAIE0400',
    program: 'CAIP0400',
    movie: 'CAIM0400',
};

export const IMAGE_RESIZE = {
    small: 400,
    medium: 1024,
    large: 1920,
};
