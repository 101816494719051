export const API_URLS = {
  DEVICES: '/v1/user/device/list',
  EPISODE: '/v2/media/episode/{mediaCode}',
  EPISODES: '/v2/media/episodes',
  PARAMOUNT_EPISODES: '/v2/media/paramount/episodes',
  FREQUENCY_PROGRAM: '/v2/media/frequency/program/{mediaCode}',
  EXPOSURE: '/v2/operator/home/exposures4',
  FAN_ACTION: '/v2/community/fans/{action}',
  FAN_CONTENTS: '/v2/community/fan/contents',
  FAQ: '/v1/operator/faq/{articleId}',
  FAQS: '/v1/operator/faqs',
  HIGHLIGHT: '/v2/operator/highlights',
  KIDS_CHARACTER: '/v2/media/kids/character/{kidsId}',
  KIDS_CHARACTERS: '/v2/media/kids/characters',
  KIDS_CREATOR: '/v2/media/kids/creator/{kidsId}',
  KIDS_CREATORS: '/v2/media/kids/creators',
  KIDS_MOVIES: '/v2/media/kids/movies',
  KIDS_POPULAR: '/v2/media/kids/popular',
  LIVE: '/v2/media/live/{liveCode}',
  LIVES: '/v2/media/lives',
  MAINTENANCE_POPUP: '/smarttv/popup.json',
  MOBILE_PURCHASE: '/v1/user/mobile/purchase',
  MOVIE: '/v2/media/movie/{mediaCode}',
  MOVIES: '/v2/media/movies',
  PARAMOUNT_MOVIES: '/v2/media/paramount/movies',
  MY_LASTS: '/v2/media/my/lasts',
  MY_LASTS_TV: '/v2/media/my/laststv',
  MY_RESERVE: '/v2/community/schedule/reserves',
  NOTICE: '/v1/operator/notice/{articleId}',
  NOTICES: '/v1/operator/notices',
  OTP_URL_AND_OTP_NUM: '/v1/user/otp/getOtpUrlAndOtpNum',
  PLAYER_OFFER_PRODUCT: '/v1/bill/product/playerOfferProduct',
  POPUPS: '/v1/operator/popups',
  PPV_AVAILABLE: '/v1/bill/product/ppvavail',
  PRODUCT_LIST_FOR_ATV: '/v2/bill/product/productListForAtv',
  PRODUCT_LIST_FOR_MARKET: '/v1/bill/product/productListForMarket',
  PROFILE: '/v2/user/profile/info/{profileNo}',
  PROFILE_CREATE: '/v2/user/profile/crt',
  PROFILE_FIND_PWD: '/v1/user/profile/findPwd/{profileNo}',
  PROFILE_IMAGE_LIST: '/v1/user/profile/profileImageList',
  PROFILE_LIST: '/v2/user/profile/list',
  PROFILE_RESET_PWD: '/v1/user/profile/resetPwd/{profileNo}',
  PROFILE_UPDATE: '/v2/user/profile/update/{profileNo}',
  PROFILE_DELETE: '/v2/user/profile/delete/{profileNo}',
  PROGRAM: '/v2/media/program/{mediaCode}',
  PROGRAM_RECOMMEND_VOD: '/v2/media/programRecommendVod',
  PURCHASED_CHECK: '/v2/bill/aos/purchased/check',
  PURCHASED_EPISODE: '/v2/media/my/purchase/episode',
  PURCHASED_MOVIE: '/v2/media/my/purchase/movie',
  RECOMMEND: '/v2/media/recommend',
  RECOMMEND_CATEGORY: '/v2/media/recommend/vod/category/{categoryCode}',
  RECOMMEND_GENRE: '/v2/media/recommend/vod/genre/{genreCode}',
  RECOMMEND_MOVIE_LIKE: '/v2/media/recommend/movie/like',
  RECOMMEND_MOVIE_SIMILAR: '/v2/media/recommend/movie/similar',

  RECOMMEND_PROGRAM_LIKE: '/v2/media/recommend/program/like',

  RECOMMEND_MOVIE_ASSOCIATE: '/v2/media/recommend/movie/associate',
  RECOMMEND_PROGRAM_ASSOCIATE: '/v2/media/recommend/program/associate',

  RECURPROD_LIST: '/v1/user/purchase/products/recurprodList',
  REGISTER_DEVICE: '/v1/user/device/regist',
  REGISTER_DEVICE_REMOVE: '/v1/user/device/remove',
  REGISTER_DEVICE_REGIST_INFO: '/v1/user/device/registInfo',
  SEARCH: '/search/getSearch.jsp',
  SEARCH_FEEDBACK: '/feedback',
  SEARCH_RANK: '/search/common/module/getRpk.jsp',
  SEARCH_AUTO_RESULT: '/search/common/module/getAkc.jsp',
  SEND_SMS_ADULT_AUTH_URL: '/v1/user/otp/sendSmsAdultAuthUrl',
  SEND_SMS_USER_AUTH_URL: '/v1/user/otp/sendSmsUserAuthUrl',
  SEND_SMS_JOIN_TVING_CJONE_URL: '/v1/user/otp/sendSmsJoinTvingCjoneUrl',
  STREAM_INFO: '/v2/media/stream/info',
  CONTENT_INFO: '/v2/media/content/info',
  TVING_VALID_TICKET_LIST: '/v1/user/myValidTicketList',
  USER_INFO: '/v2/user/info',
  SEASON_PROGRAM: '/v2/media/season/program',
  SEASON_MOVIE: '/v2/media/season/movie',
  ONBOARD: '/v2/onboard',
  SUBTITLEPROFILE: '/v2/subtitle/profile',
  ORIGINALS: '/v2/media/originals',
  ORIGINALS_BAND: '/v2/media/band/originals',
  TTV_BUFFER_MODE: '/gw/api/{poc}/ttv',
  PROVISIONING: '/gw/api/{poc}/provisioning',
  HOME: '/bff/{poc}/v3/home',
  MORE_BAND: '/bff/{poc}/v3/more/band',
  MORE_CURATION: '/bff/{poc}/v3/more/curation',
  MORE_GENRE: '/bff/{poc}/v3/more/genre',
  MORE_SPECIAL: '/bff/{poc}/v3/more/special',
  LASTS_DELETE: '/v2/lasts/delete',
  APP_LOGIN: '/cust/login/v1/tv/',
  APP_LOGOUT: '/v1/auth/expire-token',
  AUTO_LOGIN: '/cust/login/v1/tv/token',
  OTP_RESULT_USER: '/cust/login/v1/tv/otp-result',
  CREATE_ACCESS_TOKEN: '/v1/auth/access-token',
  CHECK_ACCESS_TOKEN: '/v1/auth/check-auth',
  MEMBERSHIP_PLANS: `/bill/v1/product/list`,
  MEMBERSHIP_SUBSCRIPTION: `/bill/membership/subscription`,

  // 다크패턴 대상 가격인상 동의 관련 API
  PRICE_INCREASE_AGREEMENT: {
    POPUP: '/bill/price-increase/v1/agreement-popup',
    STATUS: '/bill/price-increase/v1/agreement-status',
    ACTION: '/bill/price-increase/v1/agree',
    POPUP_STOP: '/bill/price-increase/v1/agreement-popup-stop',
  },

  // 약관 동의
  TERMS_AGREEMENT: '/cust/terms/v1/agreement',
  POLICY_AGREEMENT: '/v2/user/policy/agreement/{agreeCode}',

  BOOT_POPUP: '/bff/{poc}/v3/boot/popup',

  // 이용권 해지 취소
  MY_EXPIRE_TICKET_CHECK: '/v1/user/myExpireTicketCheck',

  // 회원웹 API
  ACCOUNT: {
    FIND_PASSWORD_BY_ID: '/cust/n/help/v1/find-password-by-id',
    TVING_ID_LOGIN: '/cust/login/v1/web/tving',
  },

  // D2C 전환
  D2C_TRANSITION: {
    RESERVE_CANCEL: `/bill/reserve/v1/cancel`,
    RESERVE_SUBSCRIBE: `/bill/reserve/v1/subscribe`,
    RESERVE_DISPLAY: '/bill/reserve/v1/display/web',
  },

  CLIP: `/bff/web/v3/content/clip/{clipCode}` as const,
} as const;

export const PATH_URLS = {
  MORE_CURATION: '/more/curation/[key]',
  MORE_SPECIAL: '/more/special/[key]',
  MORE_GENRE: '/more/genre/[key]',
  MORE_BAND: '/more/band/[key]',
} as const;

export {};
