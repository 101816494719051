import { create } from 'zustand';

type ChannelStatus = {
  isFrozen: boolean;
};

type State = {
  isChatOpen: boolean;
  setIsChatOpen: (isChatOpen: boolean) => void;
  channelStatus: ChannelStatus;
  setChannelStatus: (channelStatus: ChannelStatus) => void;
};

const useChatStatusStore = create<State>((set) => ({
  isChatOpen: false,
  channelStatus: {
    isFrozen: false,
  },
  setIsChatOpen: (isChatOpen) => set({ isChatOpen }),
  setChannelStatus: (channelStatus) =>
    set((prevState) => ({
      channelStatus: {
        ...prevState.channelStatus,
        ...channelStatus,
      },
    })),
}));

export default useChatStatusStore;
