type ResizeImageProps = {
  imageUrl: string;
  size: number;
};

const resizeImage = ({ imageUrl, size }: ResizeImageProps) => {
  if (!imageUrl) {
    return null;
  }
  return `${imageUrl}/dims/resize/${size}`;
};

export default resizeImage;
