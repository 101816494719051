import React, { useEffect, useState } from 'react';

import './TvingSpinner.scss';

interface TvingSpinnerProps {
  delay?: number;
}
const TvingSpinner = ({ delay = 1000 }: TvingSpinnerProps) => {
  const [isShow, setShow] = useState(!delay);
  useEffect(() => {
    const timeoutKey = setTimeout(() => {
      setShow(true);
    }, delay);

    return () => {
      clearTimeout(timeoutKey);
    };
  }, [delay]);

  return isShow ? <div className="tving spinner" /> : null;
};

export default React.memo(TvingSpinner);
