import { useCallback } from 'react';

import isEqual from 'lodash.isequal';

import Poster, { PosterProps } from '../../../atom/poster/Poster';
import ProgressBar from '../../../atom/progressBar/ProgressBar';

import ContentTextList from './ContentTextList';
import EditOverlay from './EditOverlay';

type TMyContentItemProps<T> = {
  className?: string;
  poster: PosterProps;
  progressBar: {
    percent: number;
  };
  text: {
    className: string;
    items: {
      tag?: React.ElementType;
      title: string;
      className?: string;
      tabIndex?: number;
    }[];
  };
  tabIndex?: number;
  children?: JSX.Element | null;
  editMode?: {
    isEditMode: boolean;
    checkItemHandler: (item: T, isChecked: boolean) => void;
    checkItems: T[];
    item: T;
  };
};

const MyContentsItem = <T,>({ className, poster, progressBar, text, tabIndex, children, editMode }: TMyContentItemProps<T>) => {
  /**
   * 아이템 체크 여부 확인
   */
  const isItemChecked = useCallback((item: T, checkItems: T[]): boolean => {
    if (typeof item === 'object' && item !== null) {
      // 객체 타입인 경우
      return checkItems.some((checkItem) => isEqual(checkItem, item));
    }
    // 기본 타입인 경우
    return checkItems.includes(item);
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (editMode?.isEditMode) {
      e.preventDefault();
      const isChecked = isItemChecked(editMode.item, editMode.checkItems);
      const newCheckedState = !isChecked;
      editMode.checkItemHandler(editMode.item, newCheckedState);
    }
  };

  return (
    <div
      className="group transition transform hover-supported:hover:translate-y-[-0.75rem] duration-500 will-change-[transform]"
      onClick={handleClick}
    >
      <div className={`${className}`}>
        <Poster {...poster} />
        {children}
        <ProgressBar {...progressBar} />
        {editMode?.isEditMode && <EditOverlay {...editMode} isItemChecked={isItemChecked} />}
      </div>
      <ContentTextList className={text.className} items={text.items} tabIndex={tabIndex} />
    </div>
  );
};

export default MyContentsItem;
