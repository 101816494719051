import { Optional } from '@tving/utils/src/types/utility';
import { LABEL_TYPE } from '@tving/ui/src/types';

type Label = {
    [labelType: string]: boolean;
};

/**
 * 밴드에서 정책에 따라 조건부 라벨 표시 여부를 결정하는 경우 사용합니다.
 */
type GetLabelsOptions = {
    /** 무료 라벨을 항상 표시하지 않습니다. */
    ignoreFreeLabel: boolean;
};

const defaultLabelsOptions: GetLabelsOptions = {
    ignoreFreeLabel: false,
};

const getMergedLabelsOptions = (options?: Optional<GetLabelsOptions>) => {
    const mergedOptions: GetLabelsOptions = {
        ...defaultLabelsOptions,
    };

    if (options) {
        Object.entries(options).forEach(([key, value]) => {
            // undefined로 넘어오는 경우는 기본값을 사용합니다.
            if (value !== undefined && value !== null) {
                mergedOptions[key] = value;
            }
        });
    }

    return mergedOptions;
};

// original, only를 제외하고 다른 아이템들은 global.css에서 관리
// original, only는 사용하는 곳이 많아서 사용할 때 필요한 클래스를 입력하는 방법으로 사용 (메인배너, 스페셜관 상단 라벨)
export const getLabels = ({ label = {}, options }: { label: Label; options?: Optional<GetLabelsOptions> }) => {
    const { ignoreFreeLabel } = getMergedLabelsOptions(options);

    return {
        type: 'SVG' as LABEL_TYPE,
        rightTopLabelsWrapper: 'absolute z-[30] top-[0.25rem] flex right-[0.25rem] [&>*:nth-child(n+1)]:ml-[0.25rem]',
        leftTopLabelsWrapper:
            'absolute z-[30] sm:top-[0.14rem] sm:left-[0.14rem] md:top-[0.25rem] flex md:left-[0.25rem] [&>*:nth-child(n+1)]:mr-[0.25rem]',
        free:
            label?.isFree && !ignoreFreeLabel
                ? 'absolute sm:w-[2rem] sm:h-[1.333rem] sm:top-[0.166rem] sm:left-[0.166rem] md:top-[0.25rem] md:left-[0.25rem] md:w-[2.75rem] md:h-[1.833rem] z-[30]'
                : false,
        original: label?.isOriginal
            ? 'absolute left-1/2 translate-x-[-50%] translate-y-[-50%] sm:h-[0.916rem] sm:w-[5.316rem] sm:bottom-[1rem] md:w-[7.233rem] md:h-[1.25rem] md:bottom-[1rem] z-[30]'
            : false,
        only: label?.isOnly
            ? 'absolute left-1/2 translate-x-[-50%] translate-y-[-50%] sm:h-[0.916rem] sm:w-[3.541rem] sm:bottom-[1rem] md:w-[4.758rem] md:h-[1.25rem] md:bottom-[1rem] z-[30]'
            : false,
        quick: label?.isQuickVod
            ? 'absolute sm:top-[0.166rem] sm:left-[0.166rem] sm:w-[3.333rem] sm:h-[1.333rem] md:top-[0.25rem] md:left-[0.25rem] md:w-[4.583rem] md:h-[1.833rem] z-[30]'
            : false,
        newEpisode: label?.isNewEpisode
            ? 'absolute sm:w-[2rem] sm:h-[1.333rem] sm:top-[0.166rem] sm:left-[0.166rem] md:top-[0.25rem] md:left-[0.25rem] md:w-[2.75rem] md:h-[1.833rem] z-[30]'
            : false,
        live: label?.isLive ? 'w-[6.07rem] live-label-animation' : false,
        tsm: label?.isTSM ? 'sm:w-[4.666rem] sm:h-[1.333rem] md:w-[6.417rem] md:h-[1.83rem] ' : false,
        subtitle: label?.isSubtitle ? 'sm:w-[2rem] sm:h-[1.333rem] md:w-[2.75rem] md:h-[1.833rem]' : false,
        dubbing: label?.isDubbing ? 'sm:w-[2rem] sm:h-[1.333rem] md:w-[2.75rem] md:h-[1.833rem]' : false,
        age18: label?.isGrade18 ? 'sm:w-[1.333rem] sm:h-[1.333rem] md:w-[1.833rem] md:h-[1.833rem]' : false,
        age19: label?.isGrade19 ? 'sm:w-[1.333rem] sm:h-[1.333rem] md:w-[1.833rem] md:h-[1.833rem]' : false,
    };
};

export const isFrequencyFalsy = (value) => {
    return value === undefined || value === false || value === '' || value < 0;
};

export const isValidFrequencyNumber = (value) => {
    return value !== undefined && value !== false && value !== '' && value >= 0;
};

export const isValidNumber = (value) => {
    return value !== null && value !== undefined && value !== false && value !== '' && value > 0.1;
};

export const isValidSeasonNumber = (value) => {
    return value !== null && value !== undefined && value !== false && value !== '' && value > 0;
};

export const getScreenName = ({ pathName }) => {
    switch (pathName) {
        case '/':
            return 'home';
        case '/drama':
            return 'drama';
        case '/entertainment':
            return 'top_entertainment';
        case '/live':
            return 'live';
        case '/movie':
            return 'movie';
        case '/sports':
            return 'sports';
        case '/animation':
            return 'top_animation';
        case '/news':
            return 'news';
        case '/paramount':
            return 'paramount';
        case '/more/special/[key]':
            return 'special';
        case '/sports/[sportsType]/more/shorts/[...path]':
        case '/more/band/[key]':
        case '/more/curation/[key]':
        case '/more/genre/[key]':
            return 'more_view';
        case '/onboarding':
            return 'onboarding';
        case '/contents/[mediaCode]':
            return 'contents_detail';
        case '/kbo/home':
        case 'sports/kbl':
            return 'home';
        case '/kbo/home/video':
            return 'player';
        case '/kbo/home/schedule':
        case 'sports/kbl/schedule':
            return 'schedule';
        case '/kbo/home/history':
            return 'kbo_ranking/history';
        case 'sports/kbl/history':
            return 'sports_ranking/history';
        case '/kbo/contents/[gameCode]/[tab]':
            return 'contents_detail';
        case '/kbo/more/video/[...path]':
            return 'more_view_kbo';
        case '/kbo/team/[teamCode]':
            return 'team_detail';
        case '/kbo/athlete/[athleteCode]':
            return 'player_detail';
        case '/my/[...slug]':
            return 'my';
        case '/help/[...slug]':
            return 'help';
        case '/policy/[...slug]':
            return 'policy';
        case '/event/[...slug]':
            return 'event';
        case '/search':
            return 'search';
        case '/membership/tving':
            return 'membership';
        default:
            return 'home';
    }
};

export const getMoreViewValue = ({ pathname }: { pathname?: string }) => {
    switch (pathname) {
        case '/more/band/[key]':
            return 'band';
        case '/more/curation/[key]':
            return 'curation';
        case '/more/genre/[key]':
            return 'genre';
        default:
            return null;
    }
};
