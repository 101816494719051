import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useCookie } from 'react-use';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import styled from '@emotion/styled';
import useCrossPlatform from '@utils/crossPlatform/react/useCrossPlatform';
import { getMessaging, isSupported, onMessage } from 'firebase/messaging';
import { shallow } from 'zustand/shallow';

import seGroupAmplitude from '@tving/utils/src/utils/amplitude/seGroupAmplitude';
import setUserIdAmplitude from '@tving/utils/src/utils/amplitude/setUserIdAmplitude';
import isClipContentsByUrl from '@tving/utils/src/utils/isClipContentsByUrl/isClipContentsByUrl';

import ModalComp from '@components/Modal';
import ModalAgree from '@components/modal/ModalAgree';
import Nav from '@components/Nav';
import Search from '@components/search/Search';
import { PARTNERNAME } from '@constants/partner';
import { URL_ONBOARDING, URL_ONBOARDING_SMARTTV, URL_PLAYER, URL_SCHEDULE } from '@constants/urls';
import { useLogging, useSearchState, useUserBillProfile, useUserData, useUserInfo, useUserRegionCode } from '@store/state';
import useClientStore from '@stores/useClientStore';
import { mq } from '@styles/device';
import { size } from '@styles/space';
import { pageOrigin, userOrigin } from '@utils/apiConfig';
import { openCouponRegister } from '@utils/common/BillApi';
import { getCookie } from '@utils/common/cookie';
import { passwordChangedModal } from '@utils/common/UserApi';
import { CrossPlatformType } from '@utils/crossPlatform';
import { getSelectorsByUserAgent, getUA, isDesktop, isMobile, isSafari, isSmartTV } from '@utils/device/device-detect';
import { openPurchaseProductList } from '@utils/openWindow';
import { firebaseCloudMessaging } from '@utils/webpush';

const BrazeInitPage = dynamic(
    () => {
        return import('@components/common/BrazeInit');
    },
    { ssr: false },
);

const Debug = dynamic(
    () => {
        return import('./Debug');
    },
    { ssr: false },
);
// import { onBackgroundMessage } from 'firebase/messaging/sw';

const FooterComponent = dynamic(() => import('@components/Footer'), {
    // suspense: true,
    ssr: false,
});

const Layout = (props) => {
    const { children, ssrToken, userInfoData, userInfoProfileData, userInfoValidTicketData, logoImgUrl, regionCode } = props;
    const { push, pathname, query, asPath } = useRouter();
    const [searchInfo] = useSearchState();
    const [lastPwUpdateCookie] = useCookie('TLPUDB35Qhn7');
    const [token] = useCookie('_tving_token');
    const { isWebview } = useClientStore((state) => ({ isWebview: state.isWebview }), shallow);
    const [, setUserRegionCode] = useUserRegionCode('');
    const [{ setBrazeWebLoginStatus }] = useLogging();

    // ssr 이 있을때와 없을때 분기 필요.
    let { userAgent } = props;
    userAgent = userAgent || getSelectorsByUserAgent(getUA);

    // UserData Update.
    /// useScript('https://developers.kakao.com/sdk/js/kakao.js');

    useEffect(() => {
        setUserRegionCode(regionCode); // CSR에서 regionCode 사용을 위한 상태관리
    }, []);

    useEffect(() => {
        Modal.setAppElement('#modal-root');

        // 쿠폰 열기
        const { action } = query || {};
        if (action === 'coupon') {
            push(pathname, undefined, { shallow: true });

            ModalComp.dialog({
                title: '지금 쿠폰등록 하시겠습니까?',
                text: "나중에 등록 시 '프로필 > 쿠폰등록' 메뉴에서 등록 가능합니다.",
            }).then(({ isConfirmed }) => {
                if (isConfirmed) {
                    openCouponRegister(`?returnUrl=${encodeURIComponent(`${window.location.href}?action=coupon`)}`);
                }
            });
        }

        if (isMobile) {
            navigator.serviceWorker?.getRegistrations()?.then((registrations) => {
                // eslint-disable-next-line no-restricted-syntax
                for (const registration of registrations) {
                    if (registration.scope?.indexOf('tving.com') !== -1) {
                        registration.unregister();
                    }
                }
            });
        }

        // eslint-disable-next-line
        require('clientjs');
    }, []);

    const [userInfo, setUserInfo] = useUserInfo({
        initialData: {
            isLogin: !!ssrToken,
        },
    });

    useEffect(() => {
        setUserInfo((prev) => ({
            ...prev,
            isLogin: !!ssrToken,
        }));
    }, [ssrToken]);

    const userData = useUserData({
        initialData: userInfoData,
        initialProfileData: userInfoProfileData,
        initialValidTicketData: userInfoValidTicketData,
    });
    useEffect(() => {
        if (token && !userData?.token && !userData?.userNo) {
            logout();
        }
    }, [token, userData?.token, userData?.userNo]);

    const logout = () => {
        const returnUrl = `${window.location.origin}/onboarding`;
        const logoutUrl = `${userOrigin}/pc/user/doLogout.tving`;
        setBrazeWebLoginStatus({ loginWebYn: 'N' });
        window.location.href = `${logoutUrl}?returnUrl=${encodeURIComponent(returnUrl)}`;
    };

    const { isLogin } = userInfo;
    const { data } = useUserBillProfile();
    const { purchaseCode } = data || {};
    const isPartner = Object.keys(PARTNERNAME).includes(purchaseCode);
    const { subscribe: { subscribeTitle, subscribeText, checkUrl } = {} } = PARTNERNAME[purchaseCode] || {};

    useEffect(() => {
        // setUserInfo
        setUserInfo({
            ...userInfo,
            linkPay: () => {
                if (isPartner) {
                    if (purchaseCode === PARTNERNAME.NAVER.name) {
                        openPurchaseProductList();
                    } else {
                        ModalComp.dialog({
                            title: subscribeTitle,
                            text: subscribeText,
                        }).then(({ isConfirmed }) => {
                            if (isConfirmed) {
                                window.open(checkUrl);
                            }
                        });
                    }
                } else {
                    push('/membership/tving');
                }
            },
        });
    }, [purchaseCode]);

    useEffect(() => {
        setUserInfo((prev) => ({
            ...prev,
            ...userData,
        }));
    }, [userData.isLogin, userData.profileNo]);

    useEffect(() => {
        if (
            !isWebview &&
            lastPwUpdateCookie &&
            lastPwUpdateCookie !== '' &&
            userData.lastPasswordUpdateDate &&
            userData.lastPasswordUpdateDate !== '' &&
            lastPwUpdateCookie !== userData.lastPasswordUpdateDate
        ) {
            passwordChangedModal();
        }
    }, [userData.lastPasswordUpdateDate]);

    const isSearchPage = () => {
        return pathname.indexOf('/search') === 0;
    };

    // Firebase.
    useEffect(() => {
        console.log('firebase isLogin', isLogin);
        if (!isLogin || isSafari || isMobile) {
            return;
        }

        const initialFirebase = () => {
            console.log('firebase', 'initialFirebase');
            const getMessage = () => {
                const messaging = getMessaging();
                onMessage(messaging, (payload) => {
                    console.log('[firebase-messaging-sw.js] foreground message ', payload);
                    const { title, sub_title, contents, app_url_scheme } = JSON.parse(payload?.data?.body || '{}');
                    const notificationTitle = `${title} - ${sub_title}`;
                    const notificationOptions = {
                        body: contents,
                        // icon: image,
                        icon: `${pageOrigin}/img/tving-icon@3x.png`,
                    };

                    const url = new URL(app_url_scheme);
                    const params = new URLSearchParams(url.search);
                    const mediaCode = params.get('cd');
                    const linkUrl = `${pageOrigin}/player/${mediaCode}`;

                    console.log('firebase linkUrl', linkUrl);

                    const notification = new Notification(notificationTitle, notificationOptions);
                    notification.onclick = (event) => {
                        event.preventDefault();
                        window.open(linkUrl, '_blank');
                        notification.close();
                    };
                });
            };

            const setToken = async () => {
                try {
                    const token = await firebaseCloudMessaging.init();
                    if (token) {
                        getMessage();
                    }
                } catch (error) {
                    console.log(error);
                }
            };

            setToken();
        };

        // Firebase isSupported 체크 추가
        isSupported().then((isFirebaseSupported) => {
            if (isFirebaseSupported) {
                initialFirebase();
            }
        });
    }, [isLogin]);

    const [mainClassName, setMainclassName] = useState([]);
    useEffect(() => {
        if (isDesktop) {
            setMainclassName(['is-desktop']);
        }
    }, []);

    useEffect(() => {
        setIsMobileDevice(isMobile);
    }, []);

    const isMounted = useRef(false);

    useEffect(() => {
        if (token && isLogin) {
            // user쪽에서 set해주는 값을 사용하는게 아닌, tving.com 이동 시 직접 setUserId() 함수를 통해 선택된 프로필 토큰 값을 추가
            setUserIdAmplitude(token);
        }
    }, [token, isLogin]);

    useEffect(() => {
        if (isLogin && !isMounted.current) {
            // _tutB3583: master profile 토큰 값
            seGroupAmplitude(getCookie('_tutB3583'));

            isMounted.current = true;
        }
    }, [isLogin]);

    const crossPlatformController = useCrossPlatform();
    useEffect(() => {
        // TODO: 확인 후 레이아웃 렌더링에 문제 없으면 KboDefaultLayout, Layout의 안전 영역 코드를 _app.js로 이동
        if (crossPlatformController.getCrossPlatformType() !== CrossPlatformType.ANDROID) {
            return () => null;
        }

        const handleSafeAreaEvent = (event) => {
            document.body.style.setProperty('--safe-area-inset-top', `${event.detail.top}px`);
            document.body.style.setProperty('--safe-area-inset-bottom', `${event.detail.bottom}px`);
            document.body.style.setProperty('--safe-area-inset-right', `${event.detail.right}px`);
            document.body.style.setProperty('--safe-area-inset-left', `${event.detail.left}px`);
        };
        const handleChangeOrientation = () => {
            crossPlatformController.getSafeArea();
        };

        crossPlatformController.getSafeArea();

        window.addEventListener('onSafeArea', handleSafeAreaEvent);
        window.addEventListener('onChangeOrientation', handleChangeOrientation);
        return () => {
            window.removeEventListener('onSafeArea', handleSafeAreaEvent);
            window.removeEventListener('onChangeOrientation', handleChangeOrientation);
        };
    }, []);

    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const isOnboardingPage = pathname === URL_ONBOARDING || pathname === URL_ONBOARDING_SMARTTV;
    const isLiveChannelPlayerPage = pathname.startsWith(URL_PLAYER) && query?.slug?.startsWith('C');

    const isSchedulePage = pathname === URL_SCHEDULE;
    const isKbo = pathname.includes('/kbo');
    const isClip = isClipContentsByUrl(asPath);
    const isPolicy = pathname.includes('/policy');

    return (
        <>
            {/* layout */}
            {!isWebview && <Nav isLogin={isLogin} userAgent={userAgent} logoImgUrl={logoImgUrl} />}

            {searchInfo.isViewLayer && !isSearchPage() && <Search isViewLayer />}

            {isLogin && !isMobileDevice && <ModalAgree />}

            <StyledLayoutMain
                className={mainClassName.join(' ')}
                isWebview={isWebview}
                isOnboard={isOnboardingPage}
                isLiveChannelPlayerPage={isLiveChannelPlayerPage}
            >
                {/* <Notification /> */}
                {/* {!isMobileDevice ? <Player layoutRef={layoutRef} /> : null} */}
                <section className={`${!isSchedulePage && !isKbo && !isClip && !isPolicy && 'overflow-hidden'}`}>{children}</section>
            </StyledLayoutMain>
            <div id="modal-root" />

            {!isWebview && !isSmartTV ? <FooterComponent isLogin={isLogin} {...userAgent} /> : null}

            <Debug />

            <BrazeInitPage />
        </>
    );
};

export const StyledPlayerWrapper = styled.div`
    ${({ isLiveChannelPlayerPage }) => {
        return isLiveChannelPlayerPage && mq({ marginTop: size.gnbHeight });
    }}
`;

export const StyledLayoutMain = styled.main`
    ${({ isWebview, isOnboard, isKboContents, isLiveChannelPlayerPage }) => {
        return (
            !isWebview &&
            !isOnboard &&
            !isKboContents &&
            !isLiveChannelPlayerPage &&
            mq({
                marginTop: size.gnbHeight,
            })
        );
    }}
`;

export default Layout;
