const QUERY_KEY = {
  MEMBERSHIP_PLANS: {
    all: ['membership'] as const,
    list: () => [QUERY_KEY.MEMBERSHIP_PLANS.all, 'list'] as const,
  },

  BOOT_POPUP: {
    all: ['bootPopup'] as const,
  },

  TERMS_AGREEMENT: {
    all: ['termsAgreement'] as const,
  },

  POLICY_AGREEMENT: {
    all: ['policyAgreement'] as const,
    detail: (agreeCode: string) => [QUERY_KEY.POLICY_AGREEMENT.all, agreeCode] as const,
  },

  MY_EXPIRE_TICKET_CHECK: {
    all: ['myExpireTicketCheck'] as const,
    info: () => [QUERY_KEY.MY_EXPIRE_TICKET_CHECK.all, 'info'] as const,
  },

  DISPLAY_AD: {
    info: (apiUrl: string, parameters: Record<string, string>) => ['displayAd', apiUrl, parameters] as const,
  },

  D2C_TRANSITION: {
    all: ['d2cTransition'] as const,
    reserveDisplay: () => [QUERY_KEY.D2C_TRANSITION.all, 'reserveDisplay'] as const,
  },

  PRICE_INCREASE_AGREEMENT: {
    popup: () => ['priceIncreaseAgreementPopup'] as const,
    status: () => ['priceIncreaseAgreementStatus'] as const,
  },

  CLIP: {
    info: (clipCode: string) => ['clip', clipCode] as const,
    infoInfinite: (clipCode: string) => ['clip', clipCode, 'infinite'] as const,
  },
};
export default QUERY_KEY;
