import { getWindowPopCenter } from '@utils/common/Popup';
import { billOrigin } from '@utils/apiConfig';
import { moveMobileUserLogin, moveUserLogin } from '@utils/common/UserApi';

// 쿠폰 등록 팝업
export const openCouponRegisterWithLogin = (isLogin = false, isMobile = false) => {
    if (!isLogin) {
        if (isMobile) {
            moveMobileUserLogin(`${billOrigin}/bill/registCoupon.tving?isMoWeb=Y`);
        } else {
            moveUserLogin('?action=coupon');
        }
    } else if (isMobile) {
        window.location.href = `${billOrigin}/bill/registCoupon.tving?isMoWeb=Y`;
        // openCouponRegister('?isMoWeb=Y');
    } else {
        openCouponRegister('');
    }
};
export const openCouponRegister = (params = '') => {
    const width = 500;
    const height = 650;
    const { left, top } = getWindowPopCenter({ w: width, h: height });
    window.open(
        `${billOrigin}/bill/registCoupon.tving${params}`,
        'BILL_COUPON_POPUP',
        `scrollbars=no,menubar=no,control=no,resizable=no,width=${width},height=${height},top=${top},left=${left}`,
    );
};

// 이용권 구매하기 이동 (모웹)
export const movePurchaseProductList = (
    {
        mediaCode = '',
        isPreRelease = false,
    }: {
        mediaCode?: string;
        isPreRelease?: boolean;
    } = {
        mediaCode: '',
        isPreRelease: false,
    },
) => {
    window.location = `${billOrigin}/bill/productList.tving?cmsItemId=${mediaCode}&isMoWeb=Y&isPreRelease=${
        isPreRelease ? 'Y' : 'N'
    }` as unknown as Location;
};
// 이용권 구매하기 이동 (모웹) - 온보딩 페이지에서만 사용하는 메소드
export const movePurchaseProductListOnboarding = ({ isMobile = false, isWavve = false }) => {
    if (isMobile) {
        window.location = `${billOrigin}/bill/productList.tving?isMoWeb=Y${isWavve ? '&focusType=TVING_BUNDLE' : ''}` as unknown as Location;
    } else {
        window.location = `${billOrigin}/bill/pc/productList.tving` as unknown as Location;
    }
};

// 특정 이용권 구매하기 버튼
export const openPurchaseProduct = ({ productId }: { productId?: string }) => {
    const width = 550;
    const height = 700;
    const { left, top } = getWindowPopCenter({ w: width, h: height });
    window.open(
        `${billOrigin}/bill/chargeRecurProduct.tving?productId=${productId}&productType=2&returnUrl=${encodeURIComponent(window.location.href)}`,
        'BILL_CHARGEPROD_POPUP',
        `scrollbars=no,menubar=no,control=no,resizable=no,width=${width},height=${height},top=${top},left=${left}`,
    );
};

// 특정 컨텐츠 구매 페이지 팝업
export const openPurchaseContents = ({ mediaCode }: { mediaCode?: string }) => {
    const width = 550;
    const height = 700;
    const { left, top } = getWindowPopCenter({ w: width, h: height });
    window.open(
        `${billOrigin}/bill/chargeProductList.tving?cmsItemId=${mediaCode}&returnUrl=${encodeURIComponent(window.location.href)}`,
        'BILL_CONTENTS_POPUP',
        `scrollbars=no,menubar=no,control=no,resizable=no,width=${width},height=${height},top=${top},left=${left}`,
    );
};

// 특정 컨텐츠 구매 페이지 이동 (모웹)
export const movePurchaseContents = ({ mediaCode, isPreRelease = false }: { mediaCode?: string; isPreRelease?: boolean }) => {
    window.location = `${billOrigin}/bill/chargeProductList.tving?isMoWeb=Y&cmsItemId=${mediaCode}&returnUrl=${encodeURIComponent(
        window.location.href,
    )}&isPreRelease=${isPreRelease ? 'Y' : 'N'}` as unknown as Location;
};

// 이용권 구독 예약 페이지 팝업
export const openReserveProductList = () => {
    const width = 600;
    const height = 700;
    const { left, top } = getWindowPopCenter({ w: width, h: height });
    window.open(
        `${billOrigin}/bill/reserve/productList.tving`,
        'BILL_RESERVE_PRODUCT_LIST_POPUP',
        `scrollbars=no,menubar=no,control=no,resizable=no,width=${width},height=${height},top=${top},left=${left}`,
    );
};

/**
 * 이용권 변경 페이지로 이동 (상세 영역이 아닌 곳에서 사용)
 *
 * 콘텐츠 상세의 [이용권 업그레이드하기]와 동일한 URL로 이동하여도 되지만, 파라미터 구분을 위해 별도로 분리합니다.
 * (콘텐츠 상세는 특정 mediaCode를 기준으로 리다이렉트하기 위해 cmsItemId가 필요합니다.) -- @see {@link https://tvingcorp.slack.com/archives/C01U62N5SBS/p1725612847715479?thread_ts=1725606912.198439&cid=C01U62N5SBS}
 */
export const moveToMembershipChangePage = (params?: { isMobile?: boolean }) => {
    const { isMobile } = {
        isMobile: true,
        ...params,
    };

    const searchParams = new URLSearchParams({
        returnUrl: window.location.href,
    });
    isMobile && searchParams.append('isMoWeb', 'Y');

    window.location = `${billOrigin}/bill/chargeProductList.tving?${searchParams.toString()}` as unknown as Location;
};

// 이용권 변경/해지 팝업
export const openChangeProduct = ({ recurNo, expireDate }: { recurNo: number; expireDate: string }) => {
    const width = 550;
    const height = 700;
    const { left, top } = getWindowPopCenter({ w: width, h: height });
    window.open(
        `${billOrigin}/bill/changeProductList.tving?recurNo=${recurNo}&expireDate=${expireDate}`,
        'BILL_CHANGE_PRODUCT_POPUP',
        `scrollbars=no,menubar=no,control=no,resizable=no,width=${width},height=${height},top=${top},left=${left}`,
    );
};

export const openSubPayMethod = () => {
    const width = 550;
    const height = 700;
    const { left, top } = getWindowPopCenter({ w: width, h: height });
    window.open(
        `${billOrigin}/bill/subPaymethod.tving`,
        'BILL_CONTENTS_POPUP',
        `scrollbars=no,menubar=no,control=no,resizable=no,width=${width},height=${height},top=${top},left=${left}`,
    );
};

// 특정 컨텐츠 구매 페이지 이동 (모웹)
export const moveSubPayMethod = () => {
    window.location = `${billOrigin}/bill/subPaymethod.tving` as unknown as Location;
};
