type BasicType = {
    screenName: string;
};

/**
 * 기본 이벤트 프로퍼티를 반환합니다.
 */
const getBasicEventProperties = ({ screenName }: BasicType) => {
    return {
        screen_name: screenName,
    };
};

export default getBasicEventProperties;
