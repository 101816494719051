import { create } from 'zustand';
import { KBO_CHANNEL_CODES } from '@tving/utils/src/constants/kboCodeConstants';
import { KBO_GAME_STATUS } from '@typings/kbo';
import dayjs from 'dayjs';
import { devtools } from 'zustand/middleware';

type KboGameStore = {
    // 전달한 mediaCode 가 KBO 코드인지 판단합니다.
    isKboCode: boolean;
    code: string;
    setCode: (code: string) => void;

    // 마지막으로 접근한 코드가 KBO 코드일 경우 KBO 상태로 판단합니다.
    isKbo: boolean;
    gameCode: string;
    setGameCode: (gameCode: string) => void;

    gameStartTime: number;
    setGameStartTime: (gameStartTime: number) => void;
    setBroadcastTime: (broadcastStartTime: number, broadcastEndTime: number) => void;

    status: KBO_GAME_STATUS;
    setStatus: (status: KBO_GAME_STATUS) => void;

    selectedInning: number | null;
    setSelectedInning: (inning: number | null) => void;

    isGamePlaying: boolean;
    isGameEnd: boolean;
    isGameNotStarted: boolean;

    clearGame: () => void;
    isKboPlaying: boolean;
    setKboPlaying: (isKboPlaying: boolean) => void;

    lastPlayChannel: {
        [key: string]: string;
    };
    setLastPlayChannel: (gameCode: string, selectedChannel: string) => void;

    uniqueCode: string;
    setUniqueCode: (uniqueCode: string) => void;

    currentShowTab: string;
    setCurrentShowTab: (currentShowTab: string) => void;
};

const kboGameStore = create(
    devtools<KboGameStore>((set) => ({
        isKboCode: false,
        code: '',
        setCode: (code) => {
            const isKboCode = KBO_CHANNEL_CODES.includes(code) || window.location.pathname.includes('/kbo/contents');
            return set({ code, isKboCode, ...(!isKboCode ? { isKboPlaying: false } : {}) });
        },

        lastPlayChannel: {},
        setLastPlayChannel: (gameCode, selectedChannel) => {
            set((state) => ({
                lastPlayChannel: {
                    ...state.lastPlayChannel,
                    [gameCode]: selectedChannel,
                },
            }));
        },

        uniqueCode: '',
        setUniqueCode: (uniqueCode) => set({ uniqueCode }),

        currentShowTab: '',
        setCurrentShowTab: (currentShowTab) => set({ currentShowTab }),

        isKbo: false,
        gameCode: '',
        setGameCode: (gameCode) => set({ gameCode, isKbo: !!gameCode }),

        gameStartTime: 0,
        setGameStartTime: (gameStartTime) => set({ gameStartTime }),

        setBroadcastTime: (broadcastStartTime, broadcastEndTime) => {
            const now = dayjs();
            const isGameNotStarted = now.isBefore(`${broadcastStartTime}`);
            const isGamePlaying = now.isAfter(`${broadcastStartTime}`) && now.isBefore(`${broadcastEndTime}`);
            const isGameEnd = now.isAfter(`${broadcastEndTime}`);
            return set({ isGameNotStarted, isGamePlaying, isGameEnd });
        },

        status: KBO_GAME_STATUS.PREV,
        isGameEnd: false,
        isGamePlaying: false,
        isGameNotStarted: false,

        isKboPlaying: false,
        setKboPlaying: (isKboPlaying) => set({ isKboPlaying }),

        setStatus: (status) => {
            return set({ status });
        },

        selectedInning: null,
        setSelectedInning: (selectedInning) => set({ selectedInning }),

        clearGame: () =>
            set({
                isKboCode: false,
                code: '',
                isKbo: false,
                gameCode: '',
                gameStartTime: 0,
                status: KBO_GAME_STATUS.PREV,
                selectedInning: null,
                isGamePlaying: false,
                isGameEnd: false,
                isGameNotStarted: false,
                lastPlayChannel: {},
                uniqueCode: '',
                currentShowTab: '',
                // isKboPlaying: false, // 미니플레이어 대응
            }),
    })),
);

export default kboGameStore;
