import React, { useEffect } from 'react';
import { useCookie } from 'react-use';
import { useSportsConfig } from '@tving/utils/src/contexts/sports/SportsContext';
import { useUserBillProfile, useUserData, useUserInfo } from '@store/state';
import { userOrigin } from '@utils/apiConfig';
import { PARTNERNAME } from '@constants/partner';
import ModalComp from '@components/Modal';
import { passwordChangedModal } from '@utils/common/UserApi';

const UserSectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { device, ssrToken, userInfoData, userInfoProfileData, userInfoValidTicketData } = useSportsConfig();
    const { data } = useUserBillProfile();
    const [token] = useCookie('_tving_token');
    const [lastPwUpdateCookie] = useCookie('TLPUDB35Qhn7');

    const [userInfo, setUserInfo] = useUserInfo({
        initialData: {
            isLogin: !!ssrToken,
        },
    });

    const userData = useUserData({
        initialData: userInfoData,
        initialProfileData: userInfoProfileData,
        initialValidTicketData: userInfoValidTicketData,
    });

    const { isLogin } = userInfo;
    const { isMobileWeb, isMobileWebview: isWebview } = device || {};
    const { purchaseCode } = data || {};
    // @ts-expect-error: Known Issue, useUserBillProfile 의 반환 값 타입이 필요합니다.
    const isPartner = Object.keys(PARTNERNAME).includes(purchaseCode);
    // @ts-expect-error: Known Issue,
    const { namesubscribe: { subscribeTitle, subscribeText, checkUrl } = {} } = PARTNERNAME[purchaseCode] || {};

    const logout = () => {
        const returnUrl = `${window.location.origin}/onboarding`;
        const logoutUrl = `${userOrigin}/pc/user/doLogout.tving`;
        window.location.href = `${logoutUrl}?returnUrl=${encodeURIComponent(returnUrl)}`;
    };

    useEffect(() => {
        setUserInfo((prev) => ({
            ...prev,
            isLogin: !!ssrToken,
        }));
    }, [ssrToken]);

    useEffect(() => {
        if (token && !userData?.token && !userData?.userNo) {
            logout();
        }
    }, [token, userData?.token, userData?.userNo]);

    useEffect(() => {
        // setUserInfo
        setUserInfo({
            ...userInfo,
            linkPay: () => {
                if (isPartner) {
                    if (purchaseCode === PARTNERNAME.NAVER.name) {
                        push('/membership/naver');
                    } else {
                        ModalComp.dialog({
                            title: subscribeTitle,
                            text: subscribeText,
                        }).then(({ isConfirmed }) => {
                            if (isConfirmed) {
                                window.open(checkUrl);
                            }
                        });
                    }
                } else {
                    push('/membership/tving');
                }
            },
        });
    }, [purchaseCode]);

    useEffect(() => {
        setUserInfo((prev) => ({
            ...prev,
            ...userData,
        }));
    }, [userData.isLogin, userData.profileNo]);

    useEffect(() => {
        if (
            !isWebview &&
            lastPwUpdateCookie &&
            lastPwUpdateCookie !== '' &&
            userData.lastPasswordUpdateDate &&
            userData.lastPasswordUpdateDate !== '' &&
            lastPwUpdateCookie !== userData.lastPasswordUpdateDate
        ) {
            passwordChangedModal();
        }
    }, [userData.lastPasswordUpdateDate]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

export default UserSectionProvider;
