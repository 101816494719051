/**
 * 현재 페이지의 전체 URL을 반환합니다.
 *
 * @returns {string} 현재 페이지의 전체 URL
 * @throws {Error} window 또는 location 객체가 정의되지 않은 경우
 */
const getCurrentPageUrl = () => {
  if (typeof window === 'undefined' || typeof window.location === 'undefined') {
    throw new Error('Window or location is not defined');
  }
  return window.location.href;
};

export default getCurrentPageUrl;
