import type { RequiredPick } from '@tving/utils/src/types/utility';

import { createCustomParametersFromUrl, mergeCustomParameters, createFilterDefaultAfParameters, modifyCachedOneLinkURL } from './helper';
import { CustomParameter, AfParameters, OneLinkSmartScriptConfigs, GenerateOneLinkOptions } from './types';

declare global {
  interface Window {
    AF_SMART_SCRIPT: {
      generateOneLinkURL: (options: { oneLinkURL: string; afParameters: AfParameters }) => { clickURL: string } | undefined;
      fireImpressionsLink: () => void;
    };
  }
}

/**
 * AppsFlyer의 OneLink Smart Script는 Deferred Deep Linking을 위해 OneLink URL을 생성하는데 사용하는 SDK입니다.
 * 이 클래스는 해당 SDK로 OneLink URL을 생성할 때 OneLink URL을 조건에 맞게 생성해야 하기 때문에 Wrapping 합니다. (최종적으로 OneLink URL을 생성하는 것은 동일합니다.)
 * - 전달해야 할 파라미터, 전달하면 안될 파라미터를 구분하여 OneLink URL을 생성합니다.
 * - OneLink Smart Script는 호출한 현재 웹 페이지의 URL을 기준으로 OneLink URL을 생성하는데, 이동할 앱 화면이 파라미터로 없는 경우가 있습니다. 이 경우 폴백 파라미터를 전달하여 이동할 앱 화면을 파라미터로 추가합니다.
 *
 * 실제 웹앱 상에서 Deferred Deep Linking을 사용할 때에는 `@tving/utils`의 `deferredDeepLinking` 패키지 내에 정의된 함수를 사용해 주세요.
 *
 * OneLink Smart Script v2.8.1을 기준으로 작성되었습니다.
 * 해당 스크립트는 사용하는 APP에서 별도로 로드되어야 합니다.
 * {@link https://onelinksmartscript.appsflyer.com/onelink-smart-script-v2.8.1.js OneLink Smart Script v2.8.1}
 */
class OneLinkSmartScript {
  private oneLinkUrl: string;

  private afParameters: RequiredPick<AfParameters, 'afCustom'> & Omit<AfParameters, 'afCustom'>;

  private filterDefaultAfParameters: (paramKey: string) => boolean;

  constructor(configs: OneLinkSmartScriptConfigs) {
    this.oneLinkUrl = configs.oneLinkUrl;
    this.filterDefaultAfParameters = createFilterDefaultAfParameters(configs.afParameters);

    const safeAfParameters = {
      ...configs.afParameters,
      afCustom: (configs.afParameters.afCustom || []).filter((customParameter) => this.filterDefaultAfParameters(customParameter.paramKey)),
    };
    this.afParameters = safeAfParameters;

    this.generateOneLink = this.generateOneLink.bind(this);
    this.generateOneLinkFromUrl = this.generateOneLinkFromUrl.bind(this);
    this.fireImpressionsLink = this.fireImpressionsLink.bind(this);
  }

  /**
   * 현재 URL의 QueryString(마케팅 분석에 사용) 중 OneLink URL 생성 시 전달할 파라미터를 정의하여 OneLink URL을 생성합니다. -- OneLink Smart Script 내부 동작으로 인해 직접 지정하지 않으면 포함되지 않음 :/
   *
   * @param customParams - 현재 URL의 QueryString 중 OneLink URL 생성 시 전달할 파라미터를 명시합니다.
   * @param options - 파라미터 옵션을 정의합니다.
   * @returns OneLink URL
   */
  private generateOneLink(customParams?: CustomParameter[], options?: GenerateOneLinkOptions): string {
    if (!window.AF_SMART_SCRIPT) {
      throw new Error(
        'OneLink Smart Script가 로드되지 않았습니다. https://onelinksmartscript.appsflyer.com/onelink-smart-script-v2.8.1.js 를 로드 후 사용해 주세요.',
      );
    }

    const mergedCustomParameters = mergeCustomParameters(this.afParameters.afCustom, customParams || []);

    if (options?.fallbackDeepLinkValue && !this.afParameters.deepLinkValue) {
      throw new Error('fallbackDeepLinkValue를 적용하려면, deepLinkValue 파라미터가 필수로 설정되어야 합니다.');
    }

    const mergedDeepLinkValue =
      options?.fallbackDeepLinkValue && this.afParameters.deepLinkValue
        ? { ...this.afParameters.deepLinkValue, defaultValue: options.fallbackDeepLinkValue }
        : this.afParameters.deepLinkValue;

    const mergedAfCustoms = mergedCustomParameters.map((customParameter) => {
      if (customParameter.paramKey === 'af_dp' && options?.fallbackAfDp) {
        return { ...customParameter, defaultValue: options.fallbackAfDp };
      }

      return customParameter;
    });

    const mergedAfParameters = {
      ...this.afParameters,
      ...(mergedDeepLinkValue && { deepLinkValue: mergedDeepLinkValue }),
      afCustom: mergedAfCustoms.filter((customParameter) => this.filterDefaultAfParameters(customParameter.paramKey)),
    };

    const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
      oneLinkURL: this.oneLinkUrl,
      afParameters: mergedAfParameters,
    });

    if (!result?.clickURL) {
      throw new Error('OneLink URL을 생성하는 데 실패했습니다. 파라미터 중 필수 값이 누락 되었는지 로그를 확인해주세요.');
    }

    // TODO: 로컬 스토리지(key: ss_incoming_params)에 캐시하여 의도하지 않은 화면으로 보내고 있습니다.
    // 타겟 페이지에 정상 도달하도록 이동할 곳의 파라미터만 덮어씌웁니다. (임시 :/)
    return modifyCachedOneLinkURL(window.location.href, result.clickURL, mergedAfParameters);
  }

  /**
   * 현재 URL의 QueryString(마케팅 분석에 사용)를 OneLink URL에 포함되도록 합니다. -- OneLink Smart Script 내부 동작으로 인해 직접 지정하지 않으면 포함되지 않음 :/
   *
   * @param url - 현재 URL의 QueryString를 파라미터로 전달합니다. -- window.location.href
   * @param options - 파라미터 옵션을 정의합니다.
   * @returns OneLink URL
   */
  public generateOneLinkFromUrl(url: string, options?: GenerateOneLinkOptions): string {
    return this.generateOneLink(createCustomParametersFromUrl(url), options);
  }

  // eslint-disable-next-line class-methods-use-this
  public fireImpressionsLink() {
    /**
     * 공식 문서에서 1초 이상의 딜레이를 권장하고 있습니다.
     * @see {@link https://github.com/AppsFlyerSDK/appsflyer-sample-app-smartscript-demo-page/blob/8c0b6e7385b3b0cedd1208a530f002438a336e76/index.html#L241-L244}
     */
    setTimeout(() => {
      window.AF_SMART_SCRIPT.fireImpressionsLink();
    }, 1000);
  }
}

export default OneLinkSmartScript;
