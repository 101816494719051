import React, { createContext, useContext, useMemo } from 'react';

export type SportsContextType = {
  ssrToken: string | null;
  userInfoData: any;
  userInfoProfileData: any;
  userInfoValidTicketData: any;
  userAgent:
    | {
        [key in string]: string | boolean | null | any;
      }
    | null;
  logoImgUrl: string | null;
  userRegionCode: 'KR' | string | null;
  isWebview: any;
  device: {
    [key in 'isAndroid' | 'isMobileWeb' | 'isMobileWebview' | 'isTablet' | 'isMobile' | 'isWebview']: boolean;
  };
};

export type SportsContextProviderProps = {
  children: React.ReactNode;
} & SportsContextType;

const defaultContextValue: SportsContextType = {
  ssrToken: null,
  userInfoData: null,
  userInfoProfileData: null,
  userInfoValidTicketData: null,
  userAgent: null,
  logoImgUrl: null,
  userRegionCode: null,
  isWebview: null,
  device: null,
};

const SportsContext = createContext<SportsContextType>(defaultContextValue);

/**
 * a
 * @description
 * ⚠️ 주의: 이 Context는 초기 설정값 전용입니다
 *
 * 이 Context는 앱 시작 시 한 번 설정되는 값들을 위해 설계되었습니다.
 * 자주 변경되는 상태를 관리하기 위한 용도가 아닙니다.
 *
 * @example Good
 * - 테마 설정
 * - API 엔드포인트
 * - 기능 플래그
 *
 * @description
 * 동적으로 변경되는 상태는 다음을 고려해주세요:
 * - 서버 상태 관리 도구(React Query)
 * - 클라이언트 상태 관리 라이브러리(Zustand)
 * - 공통 유틸 상태 관리 함수(utils/features/useContextSelector/**)
 *
 * @example Bad
 * - 사용자 인증 상태(예외. 로그인/로그아웃 시에만 변경되는 경우)
 * - 폼 데이터
 * - API 요청 결과
 */
export const SportsContextProvider: React.FC<SportsContextProviderProps> = ({
  children,
  device,
  isWebview,
  logoImgUrl,
  ssrToken,
  userAgent,
  userInfoData,
  userInfoProfileData,
  userInfoValidTicketData,
  userRegionCode,
}) => {
  const contextValue: SportsContextType = useMemo(
    () => ({
      ssrToken,
      userInfoData,
      userInfoProfileData,
      userInfoValidTicketData,
      userAgent,
      logoImgUrl,
      userRegionCode,
      isWebview,
      device,
    }),
    [device, isWebview, logoImgUrl, ssrToken, userAgent, userInfoData, userInfoProfileData, userInfoValidTicketData, userRegionCode],
  );

  return <SportsContext.Provider value={contextValue}>{children}</SportsContext.Provider>;
};

export const useSportsConfig = () => {
  const context = useContext(SportsContext);

  if (!context) {
    throw new Error('useChatConfig must be used within a ChatContextProvider');
  }

  return context;
};
