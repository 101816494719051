import { useEffect } from 'react';

const useCalculateScrollbarWidth = () => {
    const calculateScrollbarWidth = () => {
        document.documentElement.style.setProperty('--scrollbar-width', `${window.innerWidth - document.documentElement.clientWidth}px`);
    };

    useEffect(() => {
        window.addEventListener('resize', calculateScrollbarWidth, false);
        document.addEventListener('DOMContentLoaded', calculateScrollbarWidth, false);
        window.addEventListener('load', calculateScrollbarWidth);
    }, []);
};

export default useCalculateScrollbarWidth;
