import { imgOrigin } from '@utils/apiConfig';
import { getAgeGrade } from '@utils/common/Code';
import { useAbortSWR } from './plugins';
import { ProxyMovie } from '@store/apis/types';

interface UseMoviePopularListParams {
    pageNo?: number;
    // TODO: Spread 연산자로 전달하는 파라미터 중 실제로 사용하는 것만 추가하기 (파악 안됨)
}

interface UseMoviePopularListSWRData {
    apiUrl?: string;
    items?: Pick<
        ProxyMovie,
        | 'ageGrade'
        | 'categoryName1'
        | 'code'
        | 'contentType'
        | 'duration'
        | 'genre'
        | 'gradeCode'
        | 'masterCode'
        | 'summary'
        | 'thumbnail'
        | 'title'
        | 'type'
        | 'watchThumbnail'
    >[];
    // TODO: body, header 필드는 확인하지 못했습니다.
    body?: unknown;
    header?: unknown;
}

/**
 * 인기 Movie List
 */
export const useMoviePopularList = (opts: UseMoviePopularListParams = {}) => {
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달
    return useAbortSWR<UseMoviePopularListSWRData>([`/v2/media/movies`], {
        params: {
            cacheType: 'main',
            pageSize: 20,
            order: 'viewDay',
            adult: 'all',
            free: 'all',
            personal: 'N',
            ...opts,
        },
    });
};

interface UseRecommendMovieListParams {
    mediaCode?: string;
}

interface UseRecommendMovieListSWRData {
    items?: (Pick<
        ProxyMovie,
        | 'ageGrade'
        | 'billingTag'
        | 'code'
        | 'genre'
        | 'gradeCode'
        | 'isAdult'
        | 'isCinemaSame'
        | 'isDubbing'
        | 'isEvent'
        | 'isExclusive'
        | 'isFirstRelease'
        | 'isFree'
        | 'isOriginal'
        | 'isParamount'
        | 'isSubtitle'
        | 'programCode'
        | 'summary'
        | 'thumbnail'
        | 'title'
    > & { isIndividualPurchase?: boolean })[];
}

/**
 * 상세 - 비슷한 영화 띠 (NEW 영화 추천 리스트 API)
 */
export const useRecommendMovieList = ({ mediaCode = '' }: UseRecommendMovieListParams) => {
    return useAbortSWR<UseRecommendMovieListSWRData>(
        [`/v2/media/recommend/movie/associate?contentId=${mediaCode}`],
        {
            params: {
                pageSize: 20,
            },
        },
        {},
        data => {
            const lists = (data?.items || []).map(
                ({
                    name,
                    code,
                    image,
                    grade_code,
                    free_yn,
                    adult_yn,
                    tving_original_yn,
                    tving_exclusive_yn,
                    event_yn,
                    first_open_yn,
                    story,
                    category_name,
                    subtitle_ver_yn,
                    dub_ver_yn,
                    billing_package_tag,
                    cine_same_yn,
                    paramount_yn,
                }) => {
                    return {
                        title: name,
                        code,
                        programCode: code,
                        gradeCode: grade_code,
                        ageGrade: getAgeGrade(grade_code),
                        thumbnail: imgOrigin + image,
                        isFree: free_yn === 'Y', // 무료 여부
                        isAdult: adult_yn === 'Y', // 성인인증 필요여부
                        isExclusive: tving_exclusive_yn === 'Y', // 티빙온리 여부
                        isEvent: event_yn === 'Y', // 이벤트 여부
                        isOriginal: tving_original_yn === 'Y', // 오리지널 여부
                        isFirstRelease: first_open_yn === 'Y', // 최초개봉 여부
                        isCinemaSame: cine_same_yn === 'Y', // 극장동시 여부
                        isSubtitle: subtitle_ver_yn === 'Y', // 자막 여부
                        isDubbing: dub_ver_yn === 'Y', // 더빙 여부
                        isParamount: paramount_yn === 'Y', // 파라마운트 여부
                        summary: story, // 줄거리
                        genre: category_name, // 장르
                        isIndividualPurchase: billing_package_tag === 'single',
                        billingTag: billing_package_tag, // 빌링 태그 > 개별구매 라벨 표시
                    };
                },
            );

            return { items: lists };
        },
    );
};

interface UseMovieCurationListParams {
    mediaCode?: string;
}

/**
 * 상세 - 영화 큐레이션 띠
 */
export const useMovieCurationList = ({ mediaCode }: UseMovieCurationListParams) => {
    return useAbortSWR([`/v2/media/movie/curations?movieCode=${mediaCode}`], {
        params: {
            pageSize: 20,
            contentsType: 'all',
        },
    });
};
