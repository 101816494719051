/* eslint-disable */
/* eslint-disable-next-line */

// 위 eslint 룰 적용 시 webpack 관련 오류 발생하여 주석 처리해놓음

import { LegacyRef, MutableRefObject, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import ActiveLink from '@components/ActiveLink';
import { useAdultYn, useAppPageProps, useLogging, useMobileNav, useSearchState, useUserBillProfile, useUserInfo } from '@store/state';
import { device, mq } from '@styles/device';
import Modal from '@components/Modal';
import { Picture } from '@components/common/Picture';
import { size, space } from '@styles/space';
import getBasicEventProperties from '@utils/amplitude/eventProperties/getBasicEventProperties';
import { imgOrigin, userOrigin } from '@utils/apiConfig';
import { MoveToBuyContentsWeb, MoveToBuyMembershipWeb, MoveToLoginPage } from '@components/content/CommonComponent';
import { useRouter } from 'next/router';

import { moveSubPayMethod, moveToMembershipChangePage, openCouponRegister } from '@utils/common/BillApi';
import { moveProfileSelect } from '@utils/common/UserApi';
import { URL_ONBOARDING, URL_ONBOARDING_SMARTTV } from '@constants/urls';
import { palette } from '@styles/colors';

import dynamic from 'next/dynamic';

import amplitudeEvent from '@tving/utils/src/utils/amplitude/amplitudeEvent';
import useMount from '@tving/utils/src/hooks/common/useMount';
import getAmplitudeEventMediaType from '@tving/utils/src/utils/amplitude/getAmplitudeEventMediaType';

import { isSmartTV } from '@utils/device/device-detect';
import getMobileWebGnbEventProperties from '@utils/amplitude/eventProperties/getMobileWebGnbEventProperties';
import { getMoreViewValue, getScreenName } from '@utils/items/utils';
import { openExternalPage } from '@utils/openWindow';
import { useOnboardingStore } from '@stores/useOnboardingEventStore';

const NavLogo = dynamic(
    () => {
        return import('./common/navLogo/NavLogo');
    },
    { ssr: true },
);

const StyledUserInfo = styled.aside`
    margin-left: auto;
    position: relative;
    display: none;
    justify-content: left;
    align-items: center;

    @media ${device.mobileL} {
        display: flex;
    }

    .menu_my_root {
        position: static;
    }

    .menu_my {
        position: relative;
        width: 2.333rem;
        height: 2.333rem;

        &:before {
            content: '';
            position: absolute;
            top: -1em;
            left: -1em;
            right: -1em;
            bottom: -1em;
            background-color: transparent;
            z-index: -1;
        }

        .profile_img {
            position: relative;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;

            > picture {
                &:before {
                    background-size: 100%;
                }
            }

            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .profile_info {
            display: none;
        }

        &:hover {
            + .menu_my_content {
                visibility: visible;
                opacity: 1;
                transform: translate(0, 0);
                transition: visibility 0s linear 0s, opacity 0.2s, transform 0.2s;
            }
        }

        &_content {
            position: fixed;
            ${mq({
                top: size.gnbHeight,
                right: space.containerSidePadding,
            })}
            min-width: 20rem;
            box-sizing: border-box;
            margin-top: -2px;
            padding: 1.5rem 0 1.167rem;
            opacity: 1;
            border-radius: 2px;
            box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.5);
            border: solid 1px #4d4d4d;
            background-color: #212121;

            visibility: hidden;
            opacity: 0;
            transition: visibility 0s linear 0.2s, opacity 0.2s, transform 0.2s;
            transform: translate(0, -10px);

            &:hover {
                visibility: visible;
                opacity: 1;
                transform: translate(0, 0);
                transition: visibility 0s linear 0s, opacity 0.2s, transform 0.2s;
            }

            &:before {
                content: '';
                position: absolute;
                top: -1em;
                left: -1em;
                right: -1em;
                bottom: -1em;
                background-color: transparent;
                z-index: -1;
            }

            .profile {
                display: flex;
                align-items: center;
                padding: 0 1.667rem;

                &_img {
                    width: 3.5rem;
                    height: 3.5rem;
                    border-radius: 4px;
                    overflow: hidden;
                    background-color: rgba(255, 255, 255, 0.1);

                    > picture {
                        position: relative;
                        display: block;
                        height: 100%;

                        &:before {
                            background-size: 100%;
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &_info {
                    min-width: 70%;
                    padding-left: 1.25rem;

                    &_name {
                        width: 100%;
                        word-wrap: break-word;
                        /* overflow: hidden; */
                        text-overflow: ellipsis;
                        font-size: 1.25rem;
                        font-weight: 500;
                        white-space: nowrap;
                        word-break: keep-all;
                    }

                    &_change {
                        font-size: 0.81em;
                        padding-top: 0.333rem;
                        font-size: 1rem;
                        color: #a3a3a3;
                        transition: color 0.1s;

                        &::after {
                            content: '';
                            display: inline-block;
                            margin-left: 0.3em;
                            width: 0.95em;
                            height: 0.95em;
                            transform: translate3d(0, 0.1em, 0);
                            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6.2 11'%3E%3Cpath fill='%237f7f7f' d='M6 5.7L.7 11l-.7-.7 4.8-4.8L0 .7.7 0 6 5.3l.2.2-.2.2z'/%3E%3C/svg%3E");
                            background-size: contain;
                            background-position: 50% 0;
                            background-repeat: no-repeat;
                        }

                        &:hover {
                            color: ${palette.inverseGrey800};

                            &::after {
                                content: '';
                                display: inline-block;
                                margin-left: 0.3em;
                                width: 0.95em;
                                height: 0.95em;
                                transform: translate3d(0, 0.1em, 0);
                                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6.2 11'%3E%3Cpath fill='%23dedede' d='M6 5.7L.7 11l-.7-.7 4.8-4.8L0 .7.7 0 6 5.3l.2.2-.2.2z'/%3E%3C/svg%3E");
                                background-size: contain;
                                background-position: 50% 0;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            }

            hr {
                width: auto;
                margin: 1.667rem 0 1.167rem;
                border-width: 1px 0 0 0;
                border-color: #2e2e2e;
            }
        }

        &_btn_wrap {
            font-size: inherit;

            a {
                display: inline-block;
                width: 100%;
                padding: 0.5rem 1.667rem;
                font-size: 1.25rem;
                line-height: 1.5;
                color: #a3a3a3;
                transition: color 0.1s;

                &:hover {
                    background-color: ${palette.inverseGrey100};
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }
`;

const StyledHeader = styled.header<{
    isScrollNavType: boolean;
    isViewLayer: boolean;
    isScroll: boolean;
    isMobile: boolean;
}>`
    position: fixed;
    top: 0;
    z-index: 200;
    left: 0;
    right: 0;
    width: calc(100vw - var(--scrollbar-width));
    padding: 0 1.333rem;

    @media ${device.tablet} {
        padding: 0 3.888rem;
    }
    @media ${device.laptop} {
        padding: 0 4rem 0 3rem;
    }

    ${mq({
        height: size.gnbHeight,
    })}

    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: -1px;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate3d(0, 0, 0);
        color: rgba(255, 255, 255, 1);
        transition: background-color 0.3s linear;
        background-color: rgba(0, 0, 0, 0.9);
        ${({ isViewLayer }) =>
            isViewLayer &&
            css`
                display: none;
            `}
    }

    @media ${device.mobile} {
        > a {
            /* display: none; */

            &:first-of-type {
                /* display: inline-block; */
            }
        }
    }

    ${({ isScroll }) =>
        isScroll &&
        css`
            top: 0;
        `}
    ${({ isMobile }) =>
        isMobile &&
        css`
            /* backdrop-filter: blur(15px);
                background-color: rgba(0, 0, 0, 0.7); */
            height: 5rem;
        `}
    > a {
        margin-right: 2.5rem;
        color: #d9d9d9;
        font-size: 1.333rem;
        font-weight: 400;

        &:first-of-type {
            display: block;
            margin: 0;
            height: 100%;
            margin-right: 2rem;
            opacity: 1;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &.other {
            color: #d9d9d9;
        }

        &:hover {
            color: #fff;
        }

        &.on {
            font-weight: 700;
            color: #fff;
        }
    }

    .live {
        display: flex;
        align-items: center;
    }
`;

const GNBMenu = () => {
    const { route } = useRouter();
    const isActiveMenu =
        ['/drama', '/entertainment', '/movie', '/sports', '/animation', '/news', '/live'].filter((path) => path === route).length > 0;
    return (
        <>
            <ActiveLink href="/drama" activeClassName="on">
                <a className={isActiveMenu ? 'other' : ''}>
                    <div>드라마</div>
                </a>
            </ActiveLink>
            <ActiveLink href="/entertainment" activeClassName="on">
                <a className={isActiveMenu ? 'other' : ''}>
                    <div className="program">예능</div>
                </a>
            </ActiveLink>
            <ActiveLink href="/movie" activeClassName="on">
                <a className={isActiveMenu ? 'other' : ''}>
                    <div className="movie">영화</div>
                </a>
            </ActiveLink>
            <ActiveLink href="/sports" activeClassName="on">
                <a className={isActiveMenu ? 'other' : ''}>
                    <div>스포츠</div>
                </a>
            </ActiveLink>
            <ActiveLink href="/animation" activeClassName="on">
                <a className={isActiveMenu ? 'other' : ''}>
                    <div>애니</div>
                </a>
            </ActiveLink>
            <ActiveLink href="/news" activeClassName="on">
                <a className={isActiveMenu ? 'other' : ''}>
                    <div>뉴스</div>
                </a>
            </ActiveLink>
            <ActiveLink href="/live" activeClassName="on">
                <a className={isActiveMenu ? 'other' : ''}>
                    <div className="live">
                        <span>라이브</span>
                    </div>
                </a>
            </ActiveLink>
        </>
    );
};

const LogoutButton = ({ isMobile }: { isMobile?: boolean }) => {
    const { t } = useTranslation('common');
    const { pathname } = useRouter();
    const [isAdult] = useAdultYn();
    const [{ setBrazeWebLoginStatus }] = useLogging();

    const linkLogout = () => {
        amplitudeEvent({
            event: 'click_logout_pc',
            eventProperties: getBasicEventProperties({ screenName: 'nav' }),
        });

        Modal.dialog({
            title: t('message.actionLogout'),
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                logoutAction();
            }
        });
    };
    const logoutAction = () => {
        // $.removeCookie("menu_S", 				        {path: '/', domain: '.tving.com'});
        // $.removeCookie("menu_M", 				        {path: '/', domain: '.tving.com'});
        // $.removeCookie(plainToHex(global.uid), 	        {path: '/', domain: '.tving.com'});
        // $.removeCookie(profileCookieKey, 		        {path: '/', domain: '.tving.com'});
        // $.removeCookie(profileLockCookieKey, 	        {path: '/', domain: '.tving.com'});
        // $.removeCookie(tvingUserToken, 			        {path: '/', domain: '.tving.com'});
        // $.removeCookie("9f5Ec1XTDj8d1mYCvfcoIw", 		{path: '/', domain: '.tving.com'});

        let returnUrl = `${window.location.origin}/onboarding`;
        debugger;
        if (pathname === '/contents/[mediaCode]') {
            if (!isAdult.isAdult) {
                returnUrl = window.location.href;
            }
        }

        const logoutUrl = `${userOrigin}/pc/user/doLogout.tving`;
        setBrazeWebLoginStatus({ loginWebYn: 'N' });
        window.location.href = `${logoutUrl}?returnUrl=${encodeURIComponent(returnUrl)}`;
    };
    if (isMobile) {
        return (
            <StyledLoginBtn isMobile={isMobile} type="faded" onClick={linkLogout}>
                로그아웃
            </StyledLoginBtn>
        );
    }
    return (
        <a data-testid="nav-profile-menu-logout" className="btn_logout" onClick={linkLogout}>
            로그아웃
        </a>
    );
};
const StyledProfileImg = styled.div<{
    defaultProfileImg?: string;
}>`
    img {
        width: 100%;
        height: 100%;
    }
`;

const MyMenu = ({ braze, brazeEvent }) => {
    const router = useRouter();
    const [userInfo] = useUserInfo();
    const [appPagePropsData] = useAppPageProps();

    const transitionTimeoutRef = useRef<null | NodeJS.Timeout>(null);

    const onMyMenuTransitionStart = () => {
        const duration = 200;
        const threshold = 0.9;

        transitionTimeoutRef.current && clearTimeout(transitionTimeoutRef.current);

        transitionTimeoutRef.current = setTimeout(() => {
            transitionTimeoutRef.current = null;
        }, duration * threshold);
    };

    const onMyMenuMouseEnter = () => {
        if (transitionTimeoutRef.current === null) {
            amplitudeEvent({
                event: 'click_profile_pc',
                eventProperties: getBasicEventProperties({ screenName: 'nav' }),
            });
        }

        onMyMenuTransitionStart();
    };

    const onMyMenuMouseLeave = () => {
        onMyMenuTransitionStart();
    };

    useMount(() => {
        return () => {
            transitionTimeoutRef.current && clearTimeout(transitionTimeoutRef.current);
        };
    });

    const defaultProfileImg = `${imgOrigin}/upload/profile/default.png`;
    const { asPath, pathname, query } = router;

    const { categoryCode, categoryName, channelCode, channelName, programCode, programName } = appPagePropsData;

    // 비로그인
    if (!userInfo.isLogin) {
        return null;
    }

    // 로그인 정보 아직 없음.
    if (!userInfo.userId) {
        return (
            <div className="menu_my">
                <StyledProfileImg className="profile_img" />
            </div>
        );
    }

    const onClickSelectProfile = () => {
        amplitudeEvent({
            event: 'click_profile_change_pc',
            eventProperties: getBasicEventProperties({ screenName: 'nav' }),
        });

        moveProfileSelect();
    };

    const onClickMy = () => {
        if (asPath !== '/my/watch') {
            amplitudeEvent({
                event: 'click_my_pc',
                eventProperties: getBasicEventProperties({ screenName: 'nav' }),
            });
        }
    };

    const onClickCustomerCenter = () => {
        amplitudeEvent({
            event: 'click_help_pc',
            eventProperties: getBasicEventProperties({ screenName: 'nav' }),
        });
    };

    const onClickCouponRegistrationButton = () => {
        if (asPath === '/my/watch') {
            amplitudeEvent({
                event: 'click_regist_coupoon_pc',
                eventProperties: getBasicEventProperties({ screenName: 'select_subscription' }),
            });
        }

        amplitudeEvent({
            event: 'click_regist_coupoon_pc',
            eventProperties: getBasicEventProperties({ screenName: 'nav' }),
        });

        if (!braze) {
            return;
        }
        brazeEvent({
            eventName: 'enter_ticket',
        });
        openCouponRegister(`?returnUrl=${encodeURIComponent(`${window.location.href}?action=coupon`)}`);
    };

    const onClickSubscriptionButton = () => {
        const { mediaCode } = query as {
            mediaCode: string;
        };

        if (asPath === '/my/watch') {
            amplitudeEvent({
                event: 'enter_subscription_pc',
                eventProperties: getBasicEventProperties({ screenName: 'home' }),
            });
        }

        if (pathname === '/contents/[mediaCode]') {
            const config = {
                event: 'enter_contents_detail_pc',
                eventProperties: {
                    screen_name: 'contents_detail',
                    mediatype: getAmplitudeEventMediaType(mediaCode),
                    channel_code: channelCode,
                    channel_name: channelName,
                    program_code: mediaCode,
                    code: programCode,
                    program_name: programName,
                    ctgr_name: categoryName,
                    ctgr_code: categoryCode,
                },
            };

            amplitudeEvent(config);
        }

        amplitudeEvent({
            event: 'click_purchasement_pc',
            eventProperties: getBasicEventProperties({ screenName: 'nav' }),
        });
    };

    // 로그인 경우
    return (
        <div className="menu_my_root" onMouseEnter={onMyMenuMouseEnter} onMouseLeave={onMyMenuMouseLeave}>
            <div data-testid="nav-profile-menu-trigger" className="menu_my">
                <StyledProfileImg defaultProfileImg={defaultProfileImg} className="profile_img">
                    <Picture src={userInfo?.profileImgPath || defaultProfileImg} size={100} />
                </StyledProfileImg>
            </div>
            <div data-testid="nav-profile-menu-content" className="menu_my_content wrap_margin">
                <div className="profile">
                    <StyledProfileImg className="profile_img">
                        <Picture src={userInfo?.profileImgPath || defaultProfileImg} size={100} />
                    </StyledProfileImg>
                    <div className="profile_info">
                        <p className="profile_info_name">{userInfo?.profileName}</p>
                        <button type="button" className="profile_info_change" onClick={onClickSelectProfile}>
                            <span>프로필 전환</span>
                        </button>
                    </div>
                </div>
                <hr />
                <ul className="menu_my_btn_wrap">
                    <li>
                        <Link scroll href="/my/watch">
                            <a data-testid="nav-profile-menu-my" className="btn_my" onClick={onClickMy}>
                                MY
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link data-testid="nav-profile-menu-subscription" scroll href="/membership/tving">
                            <a onClick={onClickSubscriptionButton}>이용권 구독</a>
                        </Link>
                    </li>
                    <li>
                        <a data-testid="nav-profile-menu-coupon" onClick={onClickCouponRegistrationButton}>
                            쿠폰등록
                        </a>
                    </li>
                    <li>
                        <Link data-testid="nav-profile-menu-customer-center" scroll href="/help/notice">
                            <a onClick={onClickCustomerCenter}>고객센터</a>
                        </Link>
                    </li>
                    <li>
                        <LogoutButton />
                    </li>
                </ul>
            </div>
        </div>
    );
};
const StyledNavRightBtnArea = styled.div`
    width: 100%;
    text-align: right;

    & > :first-child {
        margin-left: 0px;
    }
`;
const StyledLoginBtn = styled.a<{ isMobile?: boolean; type?: 'accent' | 'faded' }>`
    display: inline-flex;
    align-items: center;
    margin: 0 0 0 0.667rem;
    padding: 0rem calc(0.75rem - 1px);
    border-radius: 3px;

    background-color: #000;
    text-align: center;
    color: #d8d8d8;
    font-size: 1.083rem;
    height: 2.25rem;

    ${({ type }) =>
        type === 'accent' &&
        css`
            border-color: #fe153c;
            background-color: #fe153c;
            color: #fff;
        `}

    ${({ isMobile, type }) => {
        if (isMobile) {
            return css`
                height: 2.333rem;
                border: none;
                border-radius: 0.333rem;

                padding: ${type === 'accent' ? '0 1rem' : '0'};
                margin: 0 0 0 1.333rem;
                ${type !== 'accent' && 'background-color: transparent;'}

                font-size: 1.167rem;
                font-weight: ${type === 'accent' ? '700' : '400'};
                color: #ffffff;
                ${type === 'accent' && 'color: #ffffff;'}
                ${type === 'faded' && 'color: #808080;'}
            `;
        }

        return null;
    }}
`;

const StyledSearchButton = styled.button<{
    isSearchPage: boolean;
    isScrollNavType: boolean;
    isViewLayer: boolean;
}>`
    margin-right: 2.5rem;
    width: 2.333rem;
    height: 2.333rem;

    ${({ isScrollNavType, isSearchPage }) => {
        if (isSearchPage) {
            return isScrollNavType
                ? css`
                      display: block;
                  `
                : css`
                      display: none;
                  `;
        }
        return null;
    }}

    ${({ isViewLayer }) =>
        isViewLayer
            ? css`
                  background-image: url('/img/x.svg');
                  background-size: 120%;
                  display: block;
              `
            : css`
                  background-image: url('/img/icon_search.svg');
                  background-size: 100%;
              `}
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.7;
    font-size: 1rem;
    text-indent: -9999px;
    transition: opacity 0.1s;

    &.on {
        opacity: 0.9;
    }

    &:hover {
        opacity: 1;
    }
`;

const SearchButton = (props) => {
    const { searchInfo, setSearchInfo, isScrollNavType } = props;
    const { isViewLayer } = searchInfo || {};
    const ref = useRef<HTMLElement | null>(null);
    const { pathname } = useRouter();

    useEffect(() => {
        setSearchInfo((prev) => ({
            ...prev,
            ignoreEl: ref,
        }));
    }, []);

    const toggleSearchLayer = () => {
        setSearchInfo((prev) => ({
            ...prev,
            isViewLayer: !isViewLayer,
        }));
    };
    const isSearchPage = pathname.includes('/search');

    return (
        <StyledSearchButton
            ref={ref as LegacyRef<HTMLButtonElement>}
            isSearchPage={isSearchPage}
            isScrollNavType={isScrollNavType}
            isViewLayer={isViewLayer}
            type="button"
            onClick={(e) => {
                e.stopPropagation();
                toggleSearchLayer();
            }}
            data-testid="nav-search-button"
        >
            찾기
        </StyledSearchButton>
    );
};

const OnBoardingEventButton = ({
    isMobile,
    isLogin,
    isNotProd,
    eventLink,
}: {
    isMobile: boolean;
    isLogin: boolean;
    isNotProd?: boolean;
    eventLink: string;
}) => {
    const handleClickEventButton = () => {
        if (!eventLink) {
            Modal.alert({
                text: '진행 중인 이벤트가 없습니다.',
            });
            return;
        }

        amplitudeEvent({
            event: `click_event_${isMobile ? 'mo' : 'pc'}`,
            eventProperties: {
                screen_name: 'onboarding',
                status: !isLogin ? '비로그인' : isNotProd ? '무료 회원' : '',
            },
        });
        openExternalPage({ url: eventLink });
    };
    return (
        <StyledLoginBtn isMobile={isMobile} onClick={() => handleClickEventButton()}>
            이벤트
        </StyledLoginBtn>
    );
};
const Nav = ({ isLogin = false, userAgent, logoImgUrl }) => {
    const { isMobile } = userAgent || {};
    const ref = useRef<HTMLElement>();
    const [scrollNavType, setScrollNavType] = useState(false);
    const { data } = useUserBillProfile();
    const { items } = data || [];

    const isShowPaymentBtn =
        items?.some((currentValue) => currentValue.purchaseCode === 'TVING' || currentValue.purchaseCode === 'TVING_BUNDLE') ?? undefined;

    const [searchInfo, setSearchInfo] = useSearchState();
    const [mobileNavInfo] = useMobileNav();
    const { isViewLayer } = searchInfo || {};
    const { pathname } = useRouter();
    const [isScroll, setIsScroll] = useState(false);

    const isOnboarding = pathname === URL_ONBOARDING;

    const { isNotProd } = mobileNavInfo || {};
    const { eventLink } = useOnboardingStore();

    const [{ braze, brazeEvent }] = useLogging();

    // Hide or show the menu.
    const handleScroll = () => {
        const scrollNum = Math.max(0, window.pageYOffset);
        const targetNum = isMobile ? 0 : 100;
        setScrollNavType(scrollNum > targetNum);

        if (scrollNum > 0) {
            setIsScroll(true);
        } else {
            setIsScroll(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const mobileNavPurchaseClick = () => {
        // 상세화면인 경우에만 특정 콘텐츠의 구매 페이지로 이동
        if (pathname === '/contents/[mediaCode]') {
            MoveToBuyContentsWeb({ mediaCode: mobileNavInfo.mediaCode, isPreRelease: mobileNavInfo.isPreRelease });
        } else {
            // 상세화면 외 페이지인 경우 이용권 리스트로 이동
            MoveToBuyMembershipWeb({ mediaCode: mobileNavInfo.mediaCode, isPreRelease: mobileNavInfo.isPreRelease });
        }
    };

    return (
        <StyledHeader
            ref={ref as MutableRefObject<HTMLElement>}
            isMobile={isMobile}
            isViewLayer={isViewLayer}
            isScrollNavType={scrollNavType}
            isScroll={isScroll}
        >
            <Link href={isMobile ? URL_ONBOARDING : isSmartTV ? URL_ONBOARDING_SMARTTV : '/'} scroll>
                <a className="!flex !items-center">
                    <NavLogo logoImgUrl={logoImgUrl} />
                </a>
            </Link>
            {!isMobile && isLogin && (
                <>
                    <GNBMenu />
                    <StyledUserInfo>
                        <SearchButton searchInfo={searchInfo} setSearchInfo={setSearchInfo} isScrollNavType={scrollNavType} />
                        <MyMenu braze={braze} brazeEvent={brazeEvent} />
                    </StyledUserInfo>
                </>
            )}
            {(!isLogin || isMobile) && !isSmartTV ? (
                <StyledNavRightBtnArea>
                    {!isLogin ? (
                        <>
                            <StyledLoginBtn
                                type="accent"
                                isMobile={isMobile}
                                onClick={() =>
                                    MoveToLoginPage({
                                        isMobile,
                                        isPreRelease: mobileNavInfo.isPreRelease,
                                        amplitudeEventType: 'click_signup_mo',
                                    })
                                }
                                data-testid="nav-signup-button"
                            >
                                지금 가입
                            </StyledLoginBtn>
                            <StyledLoginBtn
                                isMobile={isMobile}
                                onClick={() =>
                                    MoveToLoginPage({
                                        isMobile,
                                        isPreRelease: mobileNavInfo.isPreRelease,
                                        amplitudeEventType: 'click_login_mo',
                                    })
                                }
                                data-testid="nav-login-button"
                            >
                                로그인
                            </StyledLoginBtn>
                            {isOnboarding && <OnBoardingEventButton isMobile={isMobile} isLogin={isLogin} eventLink={eventLink} />}
                        </>
                    ) : (
                        <>
                            {isMobile && (
                                <>
                                    {mobileNavInfo.resultCode !== '000' && mobileNavInfo.resultCode !== '060' && (
                                        <>
                                            {mobileNavInfo.isBuyContents && (
                                                <span>
                                                    <StyledLoginBtn type="accent" isMobile={isMobile} onClick={() => mobileNavPurchaseClick()}>
                                                        지금 구매
                                                    </StyledLoginBtn>
                                                </span>
                                            )}
                                            {!mobileNavInfo.isBuyContents && isNotProd !== undefined && isNotProd && (
                                                <span>
                                                    <StyledLoginBtn type="accent" isMobile={isMobile} onClick={() => mobileNavPurchaseClick()}>
                                                        지금 구독
                                                    </StyledLoginBtn>
                                                </span>
                                            )}
                                        </>
                                    )}
                                    {isOnboarding && isLogin && isShowPaymentBtn && isNotProd !== undefined && !isNotProd && (
                                        <StyledLoginBtn
                                            isMobile={isMobile}
                                            onClick={() => {
                                                amplitudeEvent({
                                                    event: 'click_mo_sub_change_mo',
                                                    eventProperties: getMobileWebGnbEventProperties({
                                                        screenName: getScreenName({ pathName: pathname }),
                                                        moreViewValue: getMoreViewValue({ pathname }),
                                                    }),
                                                });

                                                moveToMembershipChangePage();
                                            }}
                                        >
                                            이용권 변경
                                        </StyledLoginBtn>
                                    )}
                                    {isOnboarding && isLogin && isShowPaymentBtn && isNotProd !== undefined && !isNotProd && (
                                        <StyledLoginBtn
                                            isMobile={isMobile}
                                            onClick={() => {
                                                moveSubPayMethod();
                                                amplitudeEvent({
                                                    event: 'click_purchase_type_change_pc',
                                                    eventProperties: {
                                                        screen_name: 'my',
                                                    },
                                                });
                                            }}
                                        >
                                            결제 수단
                                        </StyledLoginBtn>
                                    )}
                                    {isShowPaymentBtn !== undefined && isNotProd !== undefined && <LogoutButton isMobile={isMobile} />}
                                </>
                            )}
                            {isOnboarding && isNotProd !== undefined && isNotProd && (
                                <OnBoardingEventButton isMobile={isMobile} isLogin={isLogin} isNotProd={isNotProd} eventLink={eventLink} />
                            )}
                        </>
                    )}
                </StyledNavRightBtnArea>
            ) : null}
        </StyledHeader>
    );
};
export default Nav;
