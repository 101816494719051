import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';

/**
 * 해당 함수는 TVING GNB, SPORTS GNB 의 높이값 및 반응형 동작이 가변적이기 때문에 작성한 임시 함수입니다.
 * 다양한 요소가 하나의 흐름으로 동작하기때문에 예외적으로 스타일을 관리하게 됐습니다.
 *
 * TODO: 추후 tailwind.config.js 또는 global.css 등에 지정된 상수를 바탕으로 각 사용처에서 다시 정의하게 하는게 올바른 방향이라고 생각합니다.
 * TODO: 공통으로 사용되는 로직의 오염을 막기 위해 재정의 하는 방식을 택했지만, 협의 해본 뒤 수정하도록 하겠습니다.
 */
const getSportsContentsExceptionalStyles = ({ isMobile, isWebview }: { isMobile: boolean; isWebview: boolean }) => {
    if (isWebview) {
        return tempCnx(
            '[&_.sports-header-layout]:hidden', // GNB
            '[&_.sports-main]:mt-0', // Sports main
            '[&_.sports-player]:top-0', // Sports player
            '[&_.sports-main-section]:mt-0', // Sports main section
            '[&_.sports-contents-tab]:top-[var(--sports-player-height)_+_var(--sports-player-companion-banner-height)]', // Contents tab
            '[&_.sports-inning-tab]:top-[calc(var(--sports-player-height)_+_var(--sports-player-companion-banner-height)_+_4.25rem)]', // Contents innings

            // '[&_.sports-contents-main]:landscape:h-max',
            '[&_.sports-contents-main]:landscape:overflow-y-scroll',

            '[&_.sports-contents-main]:landscape:h-screen', // Tab area
            '[&_.sports-contents-tab-area]:landscape:h-[var(--sports-contents-main-height)]', // Tab area
            '[&_.sports-contents-tab]:top-[calc(var(--sports-player-height)_+_var(--sports-player-companion-banner-height)_-_0.1rem)] [&_.sports-contents-tab]:landscape:top-0', // Contents tab
            '[&_.sports-inning-tab]:top-[calc(var(--sports-player-height)_+_var(--sports-player-companion-banner-height)_+_4.1rem)] [&_.sports-inning-tab]:landscape:top-[4.24rem]', // Contents innings
            '[&_.sports-notify-update]:top-[calc(var(--sports-player-height)_+_var(--sports-player-companion-banner-height)_+_9.25rem)] [&_.sports-notify-update]:landscape:top-[9.35rem] ', // Contents innings

            '[&_.simplebar-scrollbar]:!hidden',
        );
    }

    if (isMobile) {
        return tempCnx(
            'portrait:[&_.sports-header]:flex ',
            '[&_.sports-home-tab]:hidden', // GNB
            '[&_.sports-main-section]:mt-0', // Sports main section
            '[&_.sports-player]:static',
            '[&_.sports-player-dummy]:hidden',
            '[&_.sports-contents-tab-area]:landscape:h-screen', // Tab area
            '[&_.sports-contents-tab]:static', // Contents tab
            '[&_.sports-contents-sub-tab]:static', // Contents tab
            '[&_.sports-inning-tab]:top-[calc(var(--sports-player-height)_+_var(--sports-player-companion-banner-height)_+_12.8rem)] [&_.sports-inning-tab]:landscape:top-[4.24rem]', // Contents innings
        );
    }

    // isDesktop
    return tempCnx(
        '[&_.sports-home-tab]:flex [&_.sports-header]:flex', // GNB
        '[&_.sports-main]:mt-[8.66rem]', // Sports main
        '[&_.sports-main-section]:mt-0', // Sports main section

        '[&_.sports-player]:top-[8.66rem]',

        '[&_.sports-contents-tab-area]:landscape:h-[calc(var(--sports-contents-main-height)_-_8.667rem)]', // Tab area
        '[&_.sports-contents-tab-area]:landscape:sticky [&_.sports-contents-tab-area]:landscape:top-[8.667rem]', // Tab area

        '[&_.sports-contents-tab]:top-[calc(var(--sports-player-height)_+_var(--sports-player-companion-banner-height)_+_8.4rem)] [&_.sports-contents-tab]:landscape:top-0 ', // Contents tab
        '[&_.sports-inning-tab]:top-[calc(var(--sports-player-height)_+_var(--sports-player-companion-banner-height)_+_12.5rem)] [&_.sports-inning-tab]:landscape:top-[4.2rem] ', // Contents innings
        '[&_.sports-notify-update]:top-[calc(var(--sports-player-height)_+_var(--sports-player-companion-banner-height)_+_17.7rem)] [&_.sports-notify-update]:landscape:top-[9.4rem] ', // Contents innings
    );
};

export default getSportsContentsExceptionalStyles;
