import { useContext } from 'react';
import { UpdateIndicatorContext } from '@components/kbo/kboContentsBand/updateIndicator/UpdateIndicatorProvider';
import { UpdateIndicatorStore } from '@components/kbo/kboContentsBand/updateIndicator/updateIndicatorStore';
import { shallow } from 'zustand/shallow';

/**
 * zustand Store를 지정한 Scope(React Context)에서 사용할 수 있도록 해주는 Hook 입니다.
 * 기본적으로 store가 한 개만 있어도 동작하지만 라이브 상세 내 여러개의 탭에서 업데이트
 *
 * 까먹기 쉬운 shallow를 기본값으로 적용했습니다.
 *
 * 아래와 같이 특정 값을 뽑아 사용할 때 스토어 내 타입추론이 가능해서 편리합니다. (value 말고 뭐가 있었더라 ?!)
 * const { value } = useStore(store => ({ value: store.value }));
 *
 * @param selector
 * @param equals
 */
const useUpdateIndicatorStore = (
    selector: (state: UpdateIndicatorStore) => Partial<UpdateIndicatorStore>,
    equals: (a: UpdateIndicatorStore, b: UpdateIndicatorStore) => boolean = shallow,
) => {
    const originalStore = useContext(UpdateIndicatorContext);
    if (!originalStore) {
        throw new Error('UpdateIndicatorStore is not initialized');
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore : Partial 사용했으나 zustand 의 복잡한 타입 추론과 연결하지 못했습니다.
    // zustand selector를 object 형태로 스토어에서 추출할 때 위 타입 추론이 도움이 많이 됩니다.
    return originalStore(selector, equals);
};

export default useUpdateIndicatorStore;
