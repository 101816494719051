import React from 'react';

import styled from '@emotion/styled';

export const StyledTextWrapper = styled.p``;

export interface TextProps<T extends React.ElementType> {
  tag?: T;
  title: string;
  tabIndex?: number;
  className?: string;
  style?: React.CSSProperties;
}

const Text = <T extends React.ElementType = 'p'>({ tag, title, tabIndex, className, style }: TextProps<T>) => {
  return (
    <StyledTextWrapper style={style} as={tag} className={`atom-text-wrapper ${className}`} tabIndex={tabIndex}>
      {title}
    </StyledTextWrapper>
  );
};

export default Text;
