import { useEffect } from 'react';

import 'moment/locale/ko';
import { Partner, PARTNERNAME } from '@constants/partner';
import * as Sentry from '@sentry/nextjs';
import { imgOrigin, useAbortSWR } from '@store/apis/plugins';
import { useMobileNav } from '@store/state';
import moment from 'moment';
import { useCookies } from 'react-cookie';

import { RegisteredDevice } from '@tving/utils/src/apis/types';
import { KeyedMutator } from 'swr';
import { MyValidTicketListResult, RecurprodListBody, RECUR_PROD_RESERVE_STATUS_CODE } from '@tving/utils/src/apis/types';
import { BillDataList } from '@typings/membership';
import { Optional } from '@tving/utils/src/types/utility';
import { NOT_GIVEN_TOKEN_PAGE_LIST } from '@constants/urls';
import { D2C_TYPE, D2CType } from '@tving/utils/src/constants/partnerConstants';

moment.locale('ko');

/**
 * userInfo
 * @returns {*} {Object|boolean} data, source, isLoading, isError Value.
 */
export const useUserData = ({ initialData = null, initialProfileData = null, initialValidTicketData = null }) => {
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달
    const [cookies] = useCookies(['_tving_token', 'authToken', 'accessToken', 'refreshToken']);
    const { _tving_token, authToken, refreshToken, accessToken } = cookies || {};

    let isPossibleLogin = _tving_token;

    // 로그인 가능 여부에 대해서 기존 token 만 비교하지 않고 rt, at, authTo
    if (typeof window !== 'undefined') {
        if (NOT_GIVEN_TOKEN_PAGE_LIST.filter((path) => window?.location?.href?.includes(path)).length > 0) {
            isPossibleLogin = _tving_token && (refreshToken || accessToken);
        }
    }

    const data = useAbortSWR(
        [isPossibleLogin ? '/v2/user/info' : null],
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${_tving_token}`,
            },
        },
        {
            fallbackData: initialData,
        },
    );
    const { mutate: mutateUserData } = data;

    // 유저 Data
    const { profileImage, userId, userNo, userName, userType, age, cjOneMember, lastPasswordUpdateDate, legalBirthday, sex } = data.data?.body || {};
    const { profileNo, profileNm, profileType } = data.data?.body?.profile || {};
    // 프로필 이미지 가져오는 API 였지만 기본 userInfo 에서 profile 정보 참조하면 될 것으로 판단. 제거.
    // const {
    //     data: profileData,
    //     isLoading: isProfileLoading,
    //     isError: isProfileError,
    // } = useAbortSWR(
    //     [userNo ? `/v2/user/profile/list` : null],
    //     {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //             Authorization: `Bearer ${_tving_token}`,
    //         },
    //     },
    //     { fallbackData: initialProfileData },
    // );

    // 유효 이용권 리스트
    const ticketsData = useAbortSWR(
        [data.data && !data.isLoading && !data.isError ? `v1/user/myValidTicketList` : null],
        {
            withCredentials: true,
        },
        {
            fallbackData: initialValidTicketData,
        },
    );

    // Sentry Scope 설정.
    try {
        Sentry.configureScope((scope) => {
            scope.setExtra('tvingToken', _tving_token || '');
            scope.setExtra('authToken', authToken || '');
        });
        if (profileNo) {
            Sentry.configureScope((scope) => {
                scope.setExtra('userNo', userNo || '');
                scope.setExtra('profileNo', profileNo || '');
                scope.setUser({
                    id: userNo || '',
                });
            });
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}

    // 두개의 API response가 넘어오면
    if (!data.isLoading && !data.isError && !ticketsData.isLoading && !ticketsData.isError) {
        // ProfileList 참조
        const [{ profileImgPath: profileImageReal, adultCertiDt } = {}] =
            data.data?.body?.profileList?.filter((profile) => profile.profileNo === profileNo) || [];

        return {
            profileNo, // 프로필 사용자No
            profileName: profileNm, // 프로필 사용자명
            profileType, // 프로필 타입
            profileImgPath: profileImageReal || `${imgOrigin}/upload/profile/default.png`, // 프로필 이미지
            profileAdultCertiDt: adultCertiDt,
            userId, // 로그인ID
            userNo, // 사용자No
            userName, // 사용자명
            userType, // 사용자 타입
            age, // 나이
            isLogin: !!userId,
            isPayUser: ticketsData?.data?.body?.result?.ISFREEYN === 'N', // '유료' 사용자 여부
            lastPasswordUpdateDate,
            cjOneMember,
            legalBirthday,
            sex,
            mutateUserData,
            isLoginUser: true,
            isLoading: data.isLoading,
        };
    }
    return {
        token: _tving_token,
        isLoginUser: false,
        isLoading: data.isLoading,
    };
};
/**
 * bilprofile 에서 cash 값을 추출
 * @returns {Object}
 */
export const useUserCash = () => {
    const [cookies] = useCookies(['_tving_token']);
    const { _tving_token } = cookies || {};
    const bill = useAbortSWR([_tving_token ? `/v1/user/billProfile` : null], {
        withCredentials: true,
    });

    const { CASH: cash = 0 } = bill?.data?.body?.result || {};

    return {
        cash: Number(cash || 0).toLocaleString(),
    };
};

type UserBillProfileSWRData = {
    body?: {
        result?: MyValidTicketListResult;
    };
};

type UseUserBillProfileParams = {
    initialTicketData?: BillDataList;
};

/**
 * 사용자의 이용권 상태를 관리합니다.
 * (무료 사용자 여부, 이용권 종류, 이용권에 따른 권한 등)
 */
export const useUserBillProfile = ({ initialTicketData }: UseUserBillProfileParams = {}) => {
    const [cookies] = useCookies(['_tving_token']);
    const { _tving_token } = cookies || {};
    const [, setMobileNav] = useMobileNav();
    const userBillProfileSWRResponse = useAbortSWR<UserBillProfileSWRData>(
        [_tving_token ? `v1/user/myValidTicketList` : null],
        {
            withCredentials: true,
        },
        {
            fallbackData: initialTicketData ?? null,
        },
    );

    // TODO: 유틸 함수로 분리하기
    // 렌더링 라이프 사이클과 직접적인 연관성이 없는 순수 함수입니다.
    const proxyUserBillProfile = (data: UserBillProfileSWRData) => {
        if (!data?.body) {
            return {} as Optional<BillDataList>;
        }

        let isNaver = false;
        let isIos = false;
        let isAos = false;
        let isIosMulti = false;
        let purchaseCode: string | null = null;
        let isOnlyInApp;
        let streamAuthType: string | null = null;
        let productId = null;
        let productName = null;

        const ticketList = (data?.body?.result?.VALIDTICKETLIST || []).map(
            ({
                CONSUMEDATE,
                DISPPRODNAME,
                DURATIONTYPE,
                DURATIONUNIT,
                EXPDATE,
                ISIOSYN,
                ISAOSYN,
                ISNAVERYN,
                PACKAGEATTR,
                PACKAGEID,
                PRODID,
                PRODNAME,
                ROWNUM,
                PURCHASECODE,
                STREAMAUTHTYPE,
            }) => {
                // CONSUMEDATE: "20210413140121"
                // DISPPRODNAME: "프리미엄 (모니터링용) 365일권"
                // EXPDATE: "20220413140121"
                // ISIOSYN: "N"
                // ISNAVERYN: "N"
                // PACKAGEATTR: 2610062
                // PACKAGEID: 2610062
                // PRODID: 6291500
                // ROWNUM: 1
                // YYY.MM.DD 오전 00:00
                // 네이버 Check.
                if (ISNAVERYN === 'Y') {
                    isNaver = true;
                }
                if (ISIOSYN === 'Y') {
                    isIosMulti = isIos;
                    isIos = true;
                }
                if (ISAOSYN === 'Y') {
                    isIosMulti = isIos;
                    isAos = true;
                }

                if (purchaseCode === null) {
                    purchaseCode = PURCHASECODE;
                }

                if (!productId) {
                    productId = PRODID;
                }

                if (!productName) {
                    productName = PRODNAME;
                }

                if (ISIOSYN === 'Y' || ISAOSYN === 'Y') {
                    if (isOnlyInApp === undefined) {
                        isOnlyInApp = true;
                    }
                } else {
                    isOnlyInApp = false;
                }

                if (STREAMAUTHTYPE) {
                    streamAuthType = STREAMAUTHTYPE;
                }

                return {
                    consumeDate: moment(`${CONSUMEDATE}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD a hh:mm'),
                    displayProdName: DISPPRODNAME,
                    durationType: DURATIONTYPE,
                    durationUnit: DURATIONUNIT,
                    expDate: moment(`${EXPDATE}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD a hh:mm'),
                    isIos: ISIOSYN === 'Y',
                    isAos: ISAOSYN === 'Y',
                    isNaver: ISNAVERYN === 'Y',
                    purchaseCode: PURCHASECODE,
                    packageAttr: PACKAGEATTR,
                    packageId: PACKAGEID,
                    prodId: PRODID,
                    rowNum: ROWNUM,
                };
            },
        );

        /**
         * [!] 서버에서 BANNER 객체 필드내 빈 문자열을 내려주고 있어 일관되게 처리하기 위해 아래와 같이 처리함.
         */
        const {
            TEXT: bannerText = '',
            TYPE: bannerType = '',
            PCURL: bannerPCUrl = '',
            MOBILEURL: bannerMobileUrl = '',
            URL: bannerUrl = '',
            PRODID: bannerProdID = '',
            LINKNAME: bannerLinkLabel = '',
        } = data?.body?.result?.BANNER || {};

        /**
         * 제휴 코드 목록
         */
        const partnerCodeList = Object.entries(Partner).map(([, value]) => value);
        /**
         * 제휴처 이용권이 사용중인 경우
         */
        const isPartnerVoucher = purchaseCode !== null && partnerCodeList.includes(purchaseCode);

        return {
            items: ticketList,
            isNotProd: data?.body?.result?.ISFREEYN === 'Y', // '무료' 사용자 여부
            purchaseCode,
            isNaver,
            isIos,
            isAos,
            isIosMulti,
            isMulti: ticketList.length > 1,
            isOnlyInApp: Boolean(isOnlyInApp),
            isPartner: isPartnerVoucher,
            hasQuickVodLicense: data?.body?.result?.PASSGROUPINFO?.QUICKVODYN === 'Y',
            bannerText,
            bannerType,
            bannerPCUrl: bannerPCUrl || bannerUrl || '',
            bannerMobileUrl: bannerMobileUrl || bannerUrl || '',
            bannerProdID,
            bannerLinkLabel,
            streamAuthType,
            productId,
            productName,
            reserveStatus: data?.body?.result?.RESERVE_STATUS,
        } as unknown as BillDataList;
    };

    // TODO: Referential Stability를 위해 useMemo 사용하기
    // 다만 사이드 이펙트를 감당하기 어려우므로 바로 적용하지는 않습니다.
    const returnData = {
        data: proxyUserBillProfile(userBillProfileSWRResponse.data),
        mutateAll: () => {
            userBillProfileSWRResponse.mutate();
        },
        isLoading: userBillProfileSWRResponse.isLoading,
    };

    useEffect(() => {
        if (!userBillProfileSWRResponse.data?.body) {
            return;
        }

        setMobileNav((prev) => ({
            ...prev,
            isNotProd: returnData.data?.isNotProd,
        }));
    }, [returnData.data?.isNotProd]);

    return returnData;
};

type PurchaseProdListSWRData = {
    items: {
        cExpDate: string;
        chargeAmt: string;
        eventProdIds: string;
        idFlag: number;
        consumeDate: string;
        consumeLineDate: string;
        displayProdName: string;
        isIos: boolean;
        isAos: boolean;
        isAtv: boolean;
        isNaver: boolean;
        isNaverUp: boolean;
        isMigration: boolean;
        openType: string;
        openUrl: string;
        isCurr: boolean;
        isCancelSubs: boolean;
        payToolName: string;
        price: string;
        prodId: number;
        prodName: string;
        prodType: number;
        prodUseState: number;
        useState: number;
        regdate: string;
        rowNum: number;
        siteCode: number;
        statusCode: number;
        statusText: string;
        totalCnt: number;
        userId: string;
        userNo: number;
        ymd: string;
        recurNo: number;
        expireDate: string;
        purchaseCode: string;
        isPartner: boolean;
        isLite: boolean;
    }[];
    isNotData: boolean;
    pageNo: number;
    pageSize: number;
    totalPage: number;
    reserve: {
        statusCode: RECUR_PROD_RESERVE_STATUS_CODE;
        statusMessage?: string;
        productName?: string;
        reserveYmd?: string;
        reservePrice?: number;
        reservePayTool?: string;
        purchaseYmd?: string;
    };
};

/**
 * User 이용권 구매 내역
 */
export const usePurchaseProdList = ({ pageNo = 1 } = {}) => {
    const pageSize = 10;
    return useAbortSWR<PurchaseProdListSWRData, { body?: RecurprodListBody }>(
        [`/v1/user/purchase/products/recurprodList?pageNo=${pageNo}`],
        {
            params: {
                pageSize,
            },
            withCredentials: true,
        },
        {},
        (data) => {
            const ticketList: PurchaseProdListSWRData['items'] = (data?.body?.result || []).map(
                ({
                    PACKAGEID, // 2839994
                    CEXPDATE, // "20210805235959"
                    CHARGEAMT, // 8900
                    CONSUMEDATE, // "20210629182501"
                    DISPPRODNAME, // "베이직 (iOS)"
                    EVENTPRODIDS, // "-"
                    IDFLAG, // 2
                    ISCURR, // "TRUE"
                    ISIOS, // "Y"
                    ISAOS, // "N"
                    ISATV, // "N"
                    ISMIGRATION, // "N"
                    ISNAVERUPYN, // "N"
                    ISNAVERYN, // "N"
                    OPEN_TYPE,
                    OPEN_URL,
                    PAYTOOLNAME, // "APPLE"
                    PRICE, // 8900
                    PRODID, // 6290406
                    PRODNAME, // "베이직 (iOS)"
                    PRODTYPE, // 2
                    USESTATE,
                    PRODUSESTATE, // 1
                    REGDATE, // "20210629182501"
                    RNUM, // 1
                    SITECODE, // 1
                    STATUS, // 2
                    STATUSTEXT, // "만료"
                    TOTCNT, // 25
                    USERID, // "webpartner10@naver.com"
                    USERNO, // 128097163
                    YMD, // "20210629"
                    RECURNO,
                    RECURSTATUS,
                    PURCHASECODE,
                }) => {
                    return {
                        cExpDate: moment(`${CEXPDATE}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD a hh:mm'),
                        chargeAmt: (CHARGEAMT || 0).toLocaleString(),
                        eventProdIds: EVENTPRODIDS,
                        idFlag: IDFLAG,
                        consumeDate: moment(`${CONSUMEDATE}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD a hh:mm'),
                        consumeLineDate: moment(`${CONSUMEDATE}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD<br/>a hh:mm'),
                        displayProdName: DISPPRODNAME,
                        isIos: ISIOS === 'Y',
                        isAos: ISAOS === 'Y',
                        isAtv: ISATV === 'Y',
                        isNaver: ISNAVERYN === 'Y',
                        isNaverUp: ISNAVERUPYN === 'Y',
                        isMigration: ISMIGRATION === 'Y',
                        openType: OPEN_TYPE || '', // POPUP, MOVE, BLANK
                        openUrl: OPEN_URL || '', // 이용권 변경/해지 버튼 링크 주소
                        isCurr: ISCURR === 'TRUE',
                        isCancelSubs: RECURSTATUS === '5', // 이용권 해지 신청 여부
                        payToolName: PAYTOOLNAME,
                        price: (PRICE || 0).toLocaleString(), // 결제 금액
                        prodId: PRODID,
                        prodName: PRODNAME,
                        prodType: PRODTYPE,
                        prodUseState: PRODUSESTATE,
                        useState: USESTATE,
                        regdate: REGDATE ? moment(`${REGDATE}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD<br/>a hh:mm') : '',
                        rowNum: RNUM,
                        siteCode: SITECODE,
                        statusCode: STATUS,
                        statusText: STATUSTEXT || '', // RECURSTATUS/STATUSTEXT : 1/구독중, 2/만료, 3/취소, 4/예매, 5/해지신청 완료
                        totalCnt: TOTCNT,
                        userId: USERID,
                        userNo: USERNO,
                        ymd: YMD,
                        recurNo: RECURNO,
                        expireDate: CEXPDATE,
                        purchaseCode: PURCHASECODE,
                        isPartner: !Object.values(D2C_TYPE).includes(PURCHASECODE as D2CType),
                        isLite: PACKAGEID === 2915284, // 2915284 : 라이트 이용권 (모든 환경 동일)
                    };
                },
            );

            const result: PurchaseProdListSWRData = {
                items: ticketList,
                isNotData: ticketList.length === 0,
                pageNo,
                pageSize,
                totalPage: Math.ceil((data?.body?.total_count || 0) / pageSize),
                reserve: {
                    statusCode: data?.body?.reserve?.STATUS_CODE || RECUR_PROD_RESERVE_STATUS_CODE.NONE,
                    statusMessage: data?.body?.reserve?.STATUS_MESSAGE,
                    productName: data?.body?.reserve?.PRODUCT_NAME,
                    reserveYmd: data?.body?.reserve?.RESERVE_YMD,
                    reservePrice: data?.body?.reserve?.RESERVE_PRICE,
                    reservePayTool: data?.body?.reserve?.RESERVE_PAY_TOOL,
                    purchaseYmd: data?.body?.reserve?.PURCHASE_YME,
                },
            };

            return result;
        },
    );
};

/**
 * User 캐시 구매 내역
 * @returns {Object|boolean}
 */
export const usePurchaseCashList = ({ pageNo = 1 } = {}) => {
    const pageSize = 10;
    return useAbortSWR(
        [`/v1/user/purchase/inoutcash?pageNo=${pageNo}`],
        {
            params: {
                pageSize,
            },
            withCredentials: true,
        },
        {},
        (data) => {
            const statusString = (status) => {
                let statusName = '';
                if (status == '1') {
                    statusName = '충전';
                } else if (status == '2') {
                    statusName = '사용';
                } else if (status == '3') {
                    statusName = '취소';
                } else if (status == '4') {
                    statusName = '취소';
                } else if (status == '5') {
                    statusName = '만료';
                }
                return statusName;
            };
            const ticketList = (data?.body?.result || []).map(
                ({ CASHAMT, CASHTYPE, EYMD, ISSUENO, PRODNAME, PAYTOOLNAME, REGDATE, RNUM, SEARCHFLAG, TOTCNT }) => {
                    // CASHAMT: 298600
                    // CASHTYPE: 2
                    // EYMD: "20200405"
                    // ISSUENO: 202004067558075
                    // PRODNAME: "캐시만료"
                    // REGDATE: "20200406000844"
                    // RNUM: 1
                    // SEARCHFLAG: 5
                    // TOTCNT: 616

                    return {
                        cashAmt: (CASHAMT || 0).toLocaleString(),
                        useNo: ISSUENO,
                        prodName: PRODNAME || (PAYTOOLNAME && '캐시 충전'),
                        regdate: moment(`${REGDATE}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD a hh:mm'),
                        rowNum: RNUM,
                        totalCnt: TOTCNT,
                        searchFlag: SEARCHFLAG,
                        status: statusString(SEARCHFLAG),
                        statusCode: SEARCHFLAG,
                        eymd: EYMD,
                    };
                },
            );
            return {
                items: ticketList,
                isNotData: ticketList.length === 0,
                pageNo,
                pageSize,
                totalPage: Math.ceil((data?.body?.total_count || 0) / pageSize),
            };
        },
    );
};

/**
 * User BillProfile
 * @returns {Object|boolean}
 */

export const useUserDeviceList = (): {
    data: RegisteredDevice[];
    isLoading: boolean;
    mutate: KeyedMutator<RegisteredDevice[]>;
} => {
    let { data, isLoading, mutate } = useAbortSWR(
        [`/v1/user/device/list`],
        {
            withCredentials: true,
        },
        {},
        (d) => {
            return [
                ...d.body.map((item) => {
                    item.regdate = moment(`${item.regdate}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD a hh:mm');
                    return item;
                }),
            ];
        },
    );

    if (!Array.isArray(data)) {
        data = [];
    }

    return { data, isLoading, mutate };
};

/**
 * User 문의 내역
 * @returns {Object|boolean}
 */
export const useQnaList = ({ pageNo = 1 } = {}) => {
    const pageSize = 10;
    return useAbortSWR(
        [`/v2/operator/qnas?pageNo=${pageNo}`],
        {
            params: {
                pageSize,
            },
            withCredentials: true,
        },
        {},
        (data) => {
            const statusString = (status) => {
                let statusName = '';
                if (status == '1') {
                    statusName = '충전';
                } else if (status == '2') {
                    statusName = '사용';
                } else if (status == '3') {
                    statusName = '취소';
                } else if (status == '4') {
                    statusName = '취소';
                } else if (status == '5') {
                    statusName = '만료';
                }
                return statusName;
            };
            const lists = (data?.body?.result || []).map(
                ({
                    answer,
                    answer_date,
                    answer_type,
                    category_id,
                    category_name,
                    contents,
                    cur_treat,
                    email,
                    insert_date,
                    qna_seq,
                    tel1,
                    tel2,
                    tel3,
                    title,
                    user_id,
                    user_ip,
                    user_name,
                    user_no,
                }) => {
                    // answer: null
                    // answer_date: null
                    // answer_type: null
                    // category_id: "7"
                    // category_name: "모바일"
                    // contents: null
                    // cur_treat: "W"
                    // email: null
                    // insert_date: "20210415115634"
                    // qna_seq: 760457
                    // tel1: null
                    // tel2: null
                    // tel3: null
                    // title: "test"
                    // user_id: "test2"
                    // user_ip: null
                    // user_name: null
                    // user_no: "12809716

                    return {
                        answer,
                        answerDate: answer_date,
                        answerType: answer_type,
                        isAnswer: !!answer_date,
                        categoryId: category_id,
                        categoryName: category_name,
                        contents,
                        curTreat: cur_treat,
                        email,
                        insertDate: moment(`${insert_date}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD'),
                        qnaSeq: qna_seq,
                        tel1,
                        tel2,
                        tel3,
                        title,
                        userId: user_id,
                        userIp: user_ip,
                        userName: user_name,
                        userNo: user_no,
                    };
                },
            );
            return {
                items: lists,
                isNotData: lists.length === 0,
                pageNo,
                pageSize,
                totalPage: Math.ceil((data?.body?.total_count || 0) / pageSize),
            };
        },
    );
};

/**
 * User 문의 내역 내용 보기
 * @returns {Object|boolean}
 */
export const useQnaContentView = ({ id = 1 } = {}) => {
    const pageSize = 10;
    return useAbortSWR(
        [`/v2/operator/qnas/${id}`],
        {
            withCredentials: true,
        },
        {},
        (data) => {
            // answer: "안녕하세요. TVING 고객센터입니다.<BR>항상 TVING을 사랑해 주셔서 감사드립니다.<BR><BR>안내에 앞서, 문의하신 내용에 대해서 처리가 지연되었던 점 <BR>고개 숙여 진심으로 사과드립니다.<BR><BR>현재 문의 남겨주신 후 시일이 많이 소요되어 <BR>고객님께서 불편을 겪으신 부분에 대해<BR>고객센터에서 임의로 판단하여 처리하기가 어려운 상태입니다.<BR><BR>현재까지 지속해서 불편을 겪고계신 상태라면<BR>번거로우시겠지만, 한번 더 문의 남겨주시면 신속히 확인하여 조치해드리겠습니다.<BR><BR>빠른 답변 드리지 못한 점 사과드리며,<BR>이외에도 궁금하신 점은 언제든지 고객센터를 통해 접수해주시길 바랍니다.<BR><BR>앞으로 더욱더 노력하는 TVING이 되겠습니다.<BR>감사합니다."
            // answer_date: "20201229132528"
            // answer_type: "T"
            // category_id: "304"
            // category_name: "기타"
            // contents: "문의제목 길이 테스트가 필요해서 해봄 ※ 하기 내용을 입력해 주시면 보다 상세하고 정확한 응대를 받으실 수 있습니다. 길이 테스트 길이 테스트 길이 테스트 길이 테스트 method : post (POSTMAN 사용)"
            // cur_treat: "E"
            // email: null
            // insert_date: "20200113185916"
            // qna_seq: 597033
            // tel1: null
            // tel2: null
            // tel3: null
            // title: "문의테스트문의테스트문의테스트문의테스트문의테스트문의테스트문의테스트문의테스트문의테스트문의테스트문의테스트문의테스트문의테스트"
            // user_id: "test2"
            // user_ip: null
            // user_name: null
            // user_no: "128097163"
            const {
                answer,
                answer_date,
                answer_type,
                category_id,
                category_name,
                contents,
                cur_treat,
                email,
                insert_date,
                qna_seq,
                tel1,
                tel2,
                tel3,
                title,
                user_id,
                user_ip,
                user_name,
                user_no,
            } = data.body || {};

            return {
                isLoaded: true,
                answer,
                answerDate: answer_date,
                answerType: answer_type,
                isAnswer: !!answer_date,
                categoryId: category_id,
                categoryName: category_name,
                contents,
                curTreat: cur_treat,
                email,
                insertDate: moment(`${insert_date}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD'),
                qnaSeq: qna_seq,
                tel1,
                tel2,
                tel3,
                title,
                userId: user_id,
                userIp: user_ip,
                userName: user_name,
                userNo: user_no,
            };
        },
    );
};
