'use client';

import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { PLAYWRIGHT_ATTRIBUTE_NAMES, PLAYWRIGHT_ATTRIBUTE_VALUES } from '../../core';

import { type PlaywrightAppProviderProps } from './PlaywrightAppProvider.interface';

const ROOT_STYLE: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '0',
  height: '0',
  opacity: 0,
  pointerEvents: 'none',
  zIndex: -1,
  visibility: 'hidden',
};

const isBrowser = () => {
  return !!(typeof window !== 'undefined' && window.document && window.document.documentElement);
};

/**
 * Playwright 테스트 환경에 필요한 기본 요소를 제공합니다.
 */
const PlaywrightAppProvider = ({ children }: PlaywrightAppProviderProps) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const rootAttributes = {
    [PLAYWRIGHT_ATTRIBUTE_NAMES.TEST_ID]: PLAYWRIGHT_ATTRIBUTE_VALUES.TEST_ID.ROOT,
    [PLAYWRIGHT_ATTRIBUTE_NAMES.APP_READY]: isMounted
      ? PLAYWRIGHT_ATTRIBUTE_VALUES.APP_READY.COMPLETE
      : PLAYWRIGHT_ATTRIBUTE_VALUES.APP_READY.PENDING,
  };

  const rootNode = isBrowser() ? document.body : null;

  return (
    <>
      {children}
      {isMounted && rootNode ? ReactDOM.createPortal(<div {...rootAttributes} style={ROOT_STYLE} />, rootNode) : null}
    </>
  );
};

export default PlaywrightAppProvider;
