import { useEffect, useRef } from 'react';
import useClientStore from '@stores/useClientStore';

const useLazyImage = ({ src }): [React.RefObject<HTMLImageElement>, string | undefined] => {
    const imgRef = useRef<HTMLImageElement>(null);
    const observer = useClientStore(state => state.observer);

    useEffect(() => {
        if (!observer) {
            const newObserver = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const img = entry.target as HTMLImageElement;
                        img.src = entry.target.getAttribute('datasrc')!;
                        newObserver.unobserve(img);
                    }
                });
            });
            useClientStore.getState().setObserver(newObserver);
        }
    }, [observer]);

    useEffect(() => {
        const img = imgRef.current;
        if (img && observer) {
            observer.observe(img);
        }

        return () => {
            if (observer && img) {
                observer.unobserve(img);
            }
        };
    }, [src, observer]);

    return [imgRef, src];
};

export default useLazyImage;
