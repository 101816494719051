import ApiSend from '@utils/common/ApiSend';
import { useAbortSWR } from './plugins';
import { ProxyLive } from '@store/apis/types';

interface UseLivesListsLoadStateParams {
    pageNo?: number;
}

interface UseLivesListsLoadStateSWRData {
    apiUrl?: string;
    items?: Pick<
        ProxyLive,
        | 'ageGrade'
        | 'broadcastEndTime'
        | 'broadcastStartTime'
        | 'channelLogoImage'
        | 'channelName'
        | 'code'
        | 'contentType'
        | 'frequency'
        | 'gradeCode'
        | 'isFree'
        | 'mediaCode'
        | 'programName'
        | 'programThumbnail'
        | 'subtitle'
        | 'thumbnail'
        | 'title'
        | 'type'
    >[];
    // TODO: body, header 필드는 확인하지 못했습니다.
    body?: unknown;
    header?: unknown;
}

/**
 * 인기 라이브 List
 */
export const useLivesListsLoadState = ({ pageNo = 1 }: UseLivesListsLoadStateParams) => {
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달
    return useAbortSWR<UseLivesListsLoadStateSWRData>([`/v2/media/lives?channelType=CPCS0100,CPCS0400&pageNo=${pageNo}`], {
        params: {
            cacheType: 'main',
            pageSize: 20,
            order: 'rating',
            adult: 'all',
            free: 'all',
            guest: 'all',
            scope: 'all',
        },
    });
};

interface UseLivesAllListsParams {
    pageNo?: number;
}

/**
 * 인기 라이브 모두 List
 */
export const useLivesAllLists = ({ pageNo = 1 }: UseLivesAllListsParams) => {
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달
    return useAbortSWR([`/v2/media/lives?channelType=CPCS0100,CPCS0300,CPCS0400&pageNo=${pageNo}`], {
        params: {
            cacheType: 'main',
            pageSize: 20,
            order: 'rating',
            adult: 'all',
            free: 'all',
            guest: 'all',
            scope: 'all',
        },
    });
};

interface GetScheduleListContext {
    channelCode: string;
    broadcastDate: string;
    pageNo?: number;
}

/**
 * 특정 채널의 편성표 가져오기
 */
export const getScheduleList = async (context: GetScheduleListContext) => {
    const { channelCode, broadcastDate, pageNo = 1 } = context;

    const { data } = await ApiSend({
        url: `/v2/media/schedules/${channelCode}/${broadcastDate}`,
        params: {
            channel_code: channelCode,
            broadcast_date: broadcastDate,
            pageNo,
            pageSize: 1000,
            order: 'time',
        },
    });
    return data;
};

interface UseLiveRecommendedListSWRData {
    apiUrl?: string;
    body?: {
        info: {
            title: string;
        };
    };
    // TODO: result, header, items 필드는 확인하지 못했습니다.
    result?: unknown;
    header?: unknown;
    items?: unknown;
}

/**
 * 라이브 채널의 추천 리스트
 */
export const useLiveRecommendedList = () => {
    return useAbortSWR<UseLiveRecommendedListSWRData>(
        [`/v2/operator/highlights?&positionKey=AND_LIVEREAL_RCMD`],
        {
            params: {
                cacheTime: 5,
            },
        },
        {},
        data => data as UseLiveRecommendedListSWRData,
    );
};
