import { initializeApp } from 'firebase/app';
import { getAnalytics, setUserId, logEvent } from 'firebase/analytics';
import { getImpressionCodes, getImpressionKeywords } from '@utils/firebaseHelper';

export const firebaseInitialize = () => {
    const firebaseConfig = {
        apiKey: 'AIzaSyBCmgxldClzTdhLlaUs_WIk29J-seJdiCM',
        authDomain: 'tving787570031759.firebaseapp.com',
        databaseURL: 'https://tving787570031759.firebaseio.com',
        projectId: 'tving787570031759',
        storageBucket: 'tving787570031759.appspot.com',
        messagingSenderId: '787570031759',
        appId: '1:787570031759:web:3d5e68c9fdf91cf5bf44fa',
        measurementId: 'G-7ERF1X30YC',
    };
    return initializeApp(firebaseConfig);
};
type FbaLogEvent = {
    eventName: string;
    profileNo: number | string;
    params: object;
};
export const fbaLogEvent = ({ eventName = '', profileNo = 0, params = {} }: FbaLogEvent) => {
    const analytics = getAnalytics(firebaseInitialize());
    const fbaProfileNo = String(profileNo) !== '' && profileNo !== 0 ? profileNo : 0;
    setUserId(analytics, String(fbaProfileNo));
    if (!analytics) {
        return;
    }
    logEvent(analytics, eventName, params);
};

// 메인배너 노출 이벤트
export const logFbaBannerShown = ({
    profileNo,
    homeType,
    rank,
    mediaCode,
    bannerCode,
    impressionCodes,
    schModel,
    schRecModel,
    schCollection,
    abtestGroup,
    bandCode,
    bandModel,
    bannerIsManual,
}) => {
    const params = {
        home_type: homeType,
        band_type: 'BANNER_MAIN',
        rank,
        media_code: mediaCode,
        ...getImpressionCodes(impressionCodes),
        sch_model: schModel,
        sch_rec_model: schRecModel,
        sch_collection: schCollection,
        abtest_group: abtestGroup,
        band_code: bandCode,
        band_model: bandModel,
        banner_code: bannerCode,
        banner_is_manual: bannerIsManual,
    };
    fbaLogEvent({
        eventName: 'impression',
        profileNo,
        params,
    });
};

// 메인배너 클릭 이벤트
export const logFbaBannerClick = ({
    profileNo,
    homeType,
    rank,
    bannerCode,
    mediaCode,
    clickCode,
    schModel,
    schRecModel,
    schCollection,
    abtestGroup,
    bandCode,
    bandModel,
    bannerIsManual,
}) => {
    const params = {
        home_type: homeType,
        band_type: 'BANNER_MAIN',
        rank,
        banner_code: bannerCode,
        media_code: mediaCode,
        click_code: clickCode,
        sch_model: schModel,
        sch_rec_model: schRecModel,
        sch_collection: schCollection,
        abtest_group: abtestGroup,
        band_code: bandCode,
        band_model: bandModel,
        banner_is_manual: bannerIsManual,
    };
    fbaLogEvent({
        eventName: 'click',
        profileNo,
        params,
    });
};

// 검색 화면 진입 노출 이벤트 (돋보기 클릭 또는 검색창 클릭 시 최근검색어, 인기검색어)
export const logFbaSearchShown = ({ profileNo, subType, keywords, impressionSchSr, schModel, schRecModel, schCollection, abtestGroup }) => {
    const params = {
        type: 'search_landing',
        sub_type: subType,
        ...getImpressionKeywords(keywords),
        impression_sch_src: impressionSchSr,
        sch_model: schModel,
        sch_rec_model: schRecModel,
        sch_collection: schCollection,
        abtest_group: abtestGroup,
    };
    fbaLogEvent({
        eventName: 'impression',
        profileNo,
        params,
    });
};

// 검색 화면 최근검색어, 인기검색어 클릭 이벤트
export const logFbaSearchClick = ({ profileNo, subType, keyword, rank, clickSchSr, schModel, schRecModel, schCollection, abtestGroup }) => {
    const params = {
        type: 'search_landing',
        sub_type: subType,
        keyword,
        rank,
        click_sch_sr: clickSchSr,
        sch_model: schModel,
        sch_rec_model: schRecModel,
        sch_collection: schCollection,
        abtest_group: abtestGroup,
    };
    fbaLogEvent({
        eventName: 'click',
        profileNo,
        params,
    });
};

// 자동완성 검색 노출 이벤트
export const logFbaSearchAutoComplete = ({
    profileNo,
    keyword,
    resultCount,
    keywords,
    impressionSchSr,
    schModel,
    schRecModel,
    schCollection,
    abtestGroup,
}) => {
    const params = {
        type: 'search_auto_complete',
        keyword,
        result_count: resultCount,
        ...getImpressionKeywords(keywords),
        impression_sch_src: impressionSchSr,
        sch_model: schModel,
        sch_rec_model: schRecModel,
        sch_collection: schCollection,
        abtest_group: abtestGroup,
    };
    fbaLogEvent({
        eventName: 'impression',
        profileNo,
        params,
    });
};

// 자동완성 검색 결과 클릭 이벤트
export const logFbaSearchAutoCompleteClick = ({
    profileNo,
    keyword,
    subType,
    rank,
    mediaCode,
    clickSchSr,
    schModel,
    schRecModel,
    schCollection,
    abtestGroup,
}) => {
    const params = {
        type: 'search_auto_complete',
        keyword,
        sub_type: subType,
        rank,
        media_code: mediaCode,
        click_sch_sr: clickSchSr,
        sch_model: schModel,
        sch_rec_model: schRecModel,
        sch_collection: schCollection,
        abtest_group: abtestGroup,
    };
    fbaLogEvent({
        eventName: 'click',
        profileNo,
        params,
    });
};

// 검색 상세 노출 이벤트
export const logFbaSearchDetailShown = ({
    profileNo,
    searchId,
    keyword,
    funnel,
    isMore,
    resultCount,
    bandOrder,
    bandName,
    rank,
    mediaCodes,
    schModel,
    schRecModel,
    schCollection,
    abtestGroup,
    impressionSchSr,
}) => {
    const params = {
        type: 'search_detail',
        search_id: searchId,
        keyword,
        funnel,
        is_more: isMore,
        result_count: resultCount,
        band_order: bandOrder,
        band_name: bandName,
        rank,
        sch_model: schModel,
        sch_rec_model: schRecModel,
        sch_collection: schCollection,
        abtest_group: abtestGroup,
        impression_sch_src: impressionSchSr,
        ...getImpressionCodes(mediaCodes),
    };
    fbaLogEvent({
        eventName: 'impression',
        profileNo,
        params,
    });
};

// 검색 상세 클릭 이벤트
export const logFbaSearchDetailClick = ({
    profileNo,
    searchId,
    keyword,
    funnel,
    isMore,
    bandOrder,
    bandName,
    mediaCode,
    rank,
    schModel,
    schRecModel,
    schCollection,
    abtestGroup,
    clickSchSr,
}) => {
    const params = {
        type: 'search_detail',
        search_id: searchId,
        keyword,
        funnel,
        is_more: isMore,
        band_order: bandOrder,
        band_name: bandName,
        media_code: mediaCode,
        rank,
        sch_model: schModel,
        sch_rec_model: schRecModel,
        sch_collection: schCollection,
        abtest_group: abtestGroup,
        click_code: mediaCode,
        click_sch_sr: clickSchSr,
    };
    fbaLogEvent({
        eventName: 'click',
        profileNo,
        params,
    });
};

// 뉴티빙 밴드 노출 이벤트
export const logFbaBandShown = ({
    profileNo,
    homeType,
    bandType,
    bandOrder,
    bandName,
    items = [],
    schModel,
    schRecModel,
    schCollection,
    abtestGroup,
    bandCode,
    bandModel,
    bannerCode,
    bannerIsManual,
}: {
    profileNo: number | string;
    homeType: string;
    bandType: string;
    bandOrder: number;
    bandName: string;
    items: { code: string; rank: number }[];
    schModel?: string;
    schRecModel?: string;
    schCollection?: string;
    abtestGroup?: string;
    bandCode?: string;
    bandModel?: string;
    bannerCode?: string;
    bannerIsManual?: string;
}) => {
    const impressionRanks = items.map((item) => item.rank).join(',');

    const params = {
        event_type: 'band',
        home_type: homeType,
        band_type: bandType,
        band_order: bandOrder,
        band_name: bandName,
        impression_ranks: impressionRanks,
        sch_model: schModel,
        sch_rec_model: schRecModel,
        sch_collection: schCollection,
        abtest_group: abtestGroup,
        band_code: bandCode,
        band_model: bandModel,
        banner_code: bannerCode,
        banner_is_manual: bannerIsManual,
    };

    // impression_codes1 ~ impression_codes4 까지 노출된 아이템 코드를 담아서 전송합니다.
    const MAX_IMPRESSION_CODES_NUM = 4;
    const MAX_IMPRESSION_CODES_LENGTH = 95;
    const SEPARATOR_CHAR = ',';
    const IMPRESSION_CODES_PARAM_NAME_PREFIX = 'impression_codes';

    let i = 0;
    let impressionCodesNum = 1;
    let impressionCodes = '';

    for (let codesNum = impressionCodesNum; codesNum <= MAX_IMPRESSION_CODES_NUM; codesNum += 1) {
        params[`${IMPRESSION_CODES_PARAM_NAME_PREFIX}${codesNum}`] = ''; // 기본값 설정
    }

    while (i < items.length) {
        if (impressionCodesNum > MAX_IMPRESSION_CODES_NUM) {
            break;
        }

        const { code } = items[i];

        if (code) {
            if (impressionCodes.length + code.length + SEPARATOR_CHAR.length > MAX_IMPRESSION_CODES_LENGTH) {
                params[`${IMPRESSION_CODES_PARAM_NAME_PREFIX}${impressionCodesNum}`] = impressionCodes;
                impressionCodes = '';
                impressionCodesNum += 1;
            }

            impressionCodes.length === 0 ? (impressionCodes += code) : (impressionCodes += `${SEPARATOR_CHAR}${code}`);
        }

        i += 1;
    }

    if (impressionCodes.length > 0 && impressionCodesNum <= MAX_IMPRESSION_CODES_NUM) {
        params[`${IMPRESSION_CODES_PARAM_NAME_PREFIX}${impressionCodesNum}`] = impressionCodes;
    }

    fbaLogEvent({
        eventName: 'impression',
        profileNo,
        params,
    });
};

// 뉴티빙 밴드 클릭 이벤트
export const logFbaBandClick = ({
    profileNo,
    homeType,
    bandType,
    bandOrder,
    rank,
    bandName,
    mediaCode,
    schModel,
    schRecModel,
    schCollection,
    abtestGroup,
    bandCode,
    bandModel,
    bannerIsManual,
}: {
    profileNo: number | string;
    homeType: string;
    bandType: string;
    bandOrder: number;
    rank: number;
    bandName: string;
    mediaCode: string;
    clickCode?: string;
    schModel?: string;
    schRecModel?: string;
    schCollection?: string;
    abtestGroup?: string;
    bandCode?: string;
    bandModel?: string;
    bannerIsManual?: string;
}) => {
    const params = {
        event_type: 'band',
        home_type: homeType,
        band_type: bandType,
        band_order: bandOrder,
        click_rank: rank,
        band_name: bandName,
        click_code: mediaCode,

        sch_model: schModel,
        sch_rec_model: schRecModel,
        sch_collection: schCollection,
        abtest_group: abtestGroup,
        band_code: bandCode,
        band_model: bandModel,
        banner_is_manual: bannerIsManual,
    };
    fbaLogEvent({
        eventName: 'click',
        profileNo,
        params,
    });
};

export const sendFbaEvent = ({ eventName, profileNo, params }) => {
    fbaLogEvent({
        eventName,
        profileNo,
        params,
    });
};

// 대체광고 노출 이벤트
type LogFbaLiveAds = {
    eventStatus: 'start' | 'end';
    profileNo: number | string;
    mediaCode: string;
    relateMediaCode: string;
    episodeCode?: string;
};
export const logFbaLiveAds = ({ eventStatus, profileNo, mediaCode, episodeCode = '', relateMediaCode }: LogFbaLiveAds) => {
    const params = { event_status: eventStatus, epiCode: episodeCode, mediaCode, relateMediaCode };
    fbaLogEvent({
        eventName: 'ad_backup_tracking',
        profileNo,
        params,
    });
};
