import RankImg01 from '@img/ranking/1.svg';
import RankImg02 from '@img/ranking/2.svg';
import RankImg03 from '@img/ranking/3.svg';
import RankImg04 from '@img/ranking/4.svg';
import RankImg05 from '@img/ranking/5.svg';
import RankImg06 from '@img/ranking/6.svg';
import RankImg07 from '@img/ranking/7.svg';
import RankImg08 from '@img/ranking/8.svg';
import RankImg09 from '@img/ranking/9.svg';
import RankImg10 from '@img/ranking/10.svg';
import RankImg11 from '@img/ranking/11.svg';
import RankImg12 from '@img/ranking/12.svg';
import RankImg13 from '@img/ranking/13.svg';
import RankImg14 from '@img/ranking/14.svg';
import RankImg15 from '@img/ranking/15.svg';
import RankImg16 from '@img/ranking/16.svg';
import RankImg17 from '@img/ranking/17.svg';
import RankImg18 from '@img/ranking/18.svg';
import RankImg19 from '@img/ranking/19.svg';
import RankImg20 from '@img/ranking/20.svg';
import Link from 'next/link';

const VerticalItemRanking = ({ number = '', item, isLazy = false, onClick }) => {
    const contentsCode = item && item.code ? item.code : '';

    const getRankingImg = ({ rankNumber }) => {
        switch (rankNumber) {
            case 1:
                return <RankImg01 css={{ width: '2.4rem', height: 'auto', marginRight: '0.6rem' }} />;
            case 2:
                return <RankImg02 css={{ width: '4.44rem', height: 'auto', marginRight: '0.25rem', marginLeft: '0.2rem' }} />;
            case 3:
                return <RankImg03 css={{ width: '3.99rem', height: 'auto', marginRight: '0.3rem', marginLeft: '0.4rem' }} />;
            case 4:
                return <RankImg04 css={{ width: '4.49rem', height: 'auto', marginRight: '0.16rem', marginLeft: '0.2rem' }} />;
            case 5:
                return <RankImg05 css={{ width: '4.11rem', height: 'auto', marginRight: '0.19rem', marginLeft: '0.2rem' }} />;
            case 6:
                return <RankImg06 css={{ width: '3.99rem', height: 'auto', marginRight: '0.23rem', marginLeft: '0.15rem' }} />;
            case 7:
                return <RankImg07 css={{ width: '3.99rem', height: 'auto', marginRight: '0.43rem', marginLeft: '0.15rem' }} />;
            case 8:
                return <RankImg08 css={{ width: '4.15rem', height: 'auto', marginRight: '0.25rem', marginLeft: '0.15rem' }} />;
            case 9:
                return <RankImg09 css={{ width: '3.99rem', height: 'auto', marginRight: '0.25rem', marginLeft: '0.15rem' }} />;
            case 10:
                return <RankImg10 css={{ width: '6.18rem', height: 'auto', marginRight: '0.14rem', marginLeft: '0.15rem' }} />;
            case 11:
                return <RankImg11 css={{ width: '4.88rem', height: 'auto', marginRight: '0.7rem', marginLeft: '0.4rem' }} />;
            case 12:
                return <RankImg12 css={{ width: '6.25rem', height: 'auto', marginRight: '0.14rem', marginLeft: '0.2rem' }} />;
            case 13:
                return <RankImg13 css={{ width: '6.03rem', height: 'auto', marginRight: '0.3rem', marginLeft: '0.15rem' }} />;
            case 14:
                return <RankImg14 css={{ width: '6.46rem', height: 'auto', marginRight: '0.2rem', marginLeft: '0.15rem' }} />;
            case 15:
                return <RankImg15 css={{ width: '6.25rem', height: 'auto', marginRight: '0.14rem', marginLeft: '0.15rem' }} />;
            case 16:
                return <RankImg16 css={{ width: '6.11rem', height: 'auto', marginRight: '0.14rem', marginLeft: '0.15rem' }} />;
            case 17:
                return <RankImg17 css={{ width: '6.21rem', height: 'auto', marginRight: '0.2rem', marginLeft: '0.15rem' }} />;
            case 18:
                return <RankImg18 css={{ width: '6.11rem', height: 'auto', marginRight: '0.2rem', marginLeft: '0.15rem' }} />;
            case 19:
                return <RankImg19 css={{ width: '6.19rem', height: 'auto', marginRight: '0.3rem', marginLeft: '0.15rem' }} />;
            case 20:
                return <RankImg20 css={{ width: '8.03rem', height: 'auto', marginRight: '0.2rem', marginLeft: '0.15rem' }} />;
            default:
                return '';
        }
    };

    return (
        <Link href={`/contents/${contentsCode}`} passHref>
            <a css={{ display: 'flex' }} onClick={onClick}>
                <div css={{ display: 'flex' }}>
                    <div
                        css={{
                            display: isLazy ? 'inherit' : 'none',
                            alignItems: 'end',
                            bottom: '0',
                            alignSelf: 'flex-end',
                            zIndex: 3,
                        }}
                    >
                        {getRankingImg({ rankNumber: number })}
                    </div>
                </div>
            </a>
        </Link>
        // </div>
    );
};

export default VerticalItemRanking;
