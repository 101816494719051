import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchAkc, useSearchRpk } from '@store/apis';
import { useDebounce } from 'react-use';
import SimpleBar from 'simplebar-react';
import { storage } from '@utils/common/LocalStorage';
import { ratioCheck } from '@components/list/List';
import Item from '@components/item/Item';
import { useRouter } from 'next/router';
import { useLogging, useSearchState, useUserInfo } from '@store/state';
import useOutsideClick from '@hooks/useOutsideClick';
import { StyledSearchLayer } from '@styles/components/search';
import Modal from '@components/Modal';
import { saveSearchKeyword } from '@utils/search/saveSearchKeyword';
import { logFbaSearchAutoCompleteClick, logFbaSearchClick, logFbaSearchShown } from '@utils/firebaseAnalytics';
import amplitudeEvent from '@tving/utils/src/utils/amplitude/amplitudeEvent';
import { emitSearchResultEvent } from '@utils/amplitude/emitEvent/eventHandlers/searchHandler';
import {
    PopularSearchButton,
    PopularSearchRanking,
    PopularSearchRankingDate,
    PopularSearchTitle,
    SearchResultText,
    SearchTitle,
    StyledDeleteBtn,
    StyledRecentKeyword,
    StyledSearchInput,
} from './styles';
import { useUserData } from '@store/apis';

const useInput = (initialValue = '') => {
    const ref = useRef();
    const [searchInfo, setSearchInfo] = useSearchState();
    const [value, changeValue] = useState(initialValue);
    const [isAutoComplete, setIsAutoComplete] = useState(false);
    const [debouncedValue, setDebouncedValue] = useState(initialValue);
    const data = useSearchAkc({ keyword: debouncedValue, encodedKeyword: encodeURIComponent(debouncedValue), isAutoComplete });
    const [{ braze, brazeEvent }] = useLogging();

    const setValue = (value) => {
        if (value || value === '') {
            changeValue(value);
        }
    };

    const [, cancel] = useDebounce(
        () => {
            setDebouncedValue(value);
            setIsAutoComplete(true);
        },
        500,
        [value],
    );

    const handleChange = (event) => {
        const { value } = event.target;
        setValue(value);
    };

    const router = useRouter();
    // Router 변경시 keyword Update.
    useEffect(() => {
        //
        setValue(router.query.keyword);
    }, [router.query.keyword]);

    const searchCall = (_value, isPopular, ranking = 0, funnel = 'manual') => {
        setSearchInfo((prev) => ({
            ...prev,
            isFocus: false,
            isViewLayer: false,
        }));
        console.log('searchCall', {
            // ...router.query,
            keyword: _value,
            popular: isPopular || '',
            funnel,
        });
        router.push({
            // pathname: searchInfo.isViewLayer ? '/search/' : router.pathname,
            pathname: '/search/',
            query: {
                // ...router.query,
                keyword: _value,
                popular: isPopular || '',
                funnel,
            },
        });
        ref.current.blur();

        saveSearchKeyword(_value);

        if (isPopular === 'Y') {
            if (!braze) {
                return;
            }
            if (ranking !== 0) {
                brazeEvent({
                    eventName: 'enter_rtsearchkeyword',
                    properties: { realtimesearchkeyword: _value, rank: ranking },
                });
            }
        } else {
            if (!braze) {
                return;
            }
            brazeEvent({
                eventName: 'search',
                properties: { keyword: _value },
            });
        }
    };

    // Enter
    const handleEnter = (event) => {
        const { key } = event;
        if (key === 'Enter') {
            if (value) {
                emitSearchResultEvent({ type: 'enter', keyword: value });
                searchCall(value);
            } else {
                Modal.alert({
                    text: '검색어를 입력해주세요.',
                });
            }
        }
    };

    return {
        ref,
        value,
        setValue,
        searchCall,
        data,
        onChange: handleChange,
        onKeyPress: handleEnter,
    };
};

const SearchSaveKeywords = ({ keywordClick, isSearchLayerFocus }) => {
    const [keywords, setKeywords] = useState(JSON.parse(storage.get('search_keywords', '[]')));
    const [{ profileNo, isLoading: isLoadingUserInfo }] = useUserInfo();

    useEffect(() => {
        if (keywords && isSearchLayerFocus && !isLoadingUserInfo) {
            logFbaSearchShown({ profileNo, subType: 'recent', keywords });
        }
    }, [isSearchLayerFocus, isLoadingUserInfo]);

    const removeSearchAll = (e) => {
        e.stopPropagation();
        storage.set('search_keywords', JSON.stringify([]));
        setKeywords([]);
    };

    const removeSearchIdx = (e, idx) => {
        e.stopPropagation();

        const saveKeywords = JSON.parse(storage.get('search_keywords', '[]'));
        saveKeywords.splice(idx, 1);
        storage.set('search_keywords', JSON.stringify(saveKeywords));
        setKeywords(saveKeywords);
    };

    // 최근 검색어 클릭 핸들러
    const onClickRecentKeywords = ({ title, ranking }) => {
        keywordClick(title, 'N', null, 'recent');

        // Amplitude - 검색창에서 최근 검색어 클릭 시
        amplitudeEvent({
            event: 'click_latest_keyword_pc',
            eventProperties: {
                screen_name: 'search',
                keyword: title,
            },
        });

        logFbaSearchClick({ profileNo, subType: 'recent', keyword: title, rank: ranking });
    };

    return keywords && keywords.length ? (
        <ul css={{ paddingRight: '1rem' }} data-testid="search-recent-keywords">
            <SearchTitle>
                최근 검색어
                <button type="button" className="btn__del-all" onClick={removeSearchAll}>
                    모두 지우기
                    <span className="icon__close-circle">지우기</span>
                </button>
            </SearchTitle>
            {keywords.map((title, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`item-real-${idx}`} className="title" css={{ display: 'flex', alignItems: 'center' }}>
                    <StyledRecentKeyword
                        type="button"
                        onClick={() => onClickRecentKeywords({ title, ranking: idx })}
                    >{`${title} `}</StyledRecentKeyword>
                    <StyledDeleteBtn
                        type="button"
                        onClick={(e) => removeSearchIdx(e, idx)}
                        css={{
                            verticalAlign: 'middle',
                        }}
                        aria-label="삭제"
                    >
                        X
                    </StyledDeleteBtn>
                </li>
            ))}
        </ul>
    ) : (
        <ul data-testid="search-recent-keywords">
            <li className="search__title">최근 검색어</li>
            <li>검색 내역이 없습니다.</li>
        </ul>
    );
};

const SearchRealKeywords = ({ setValue, searchCall, keywordClick, isSearchLayerFocus }) => {
    const { data, isLoading } = useSearchRpk();
    const [{ profileNo, isLoading: isLoadingUserInfo }] = useUserInfo();

    useEffect(() => {
        if (!isLoading && !isLoadingUserInfo && isSearchLayerFocus) {
            const titleList = data.lists.map((item) => {
                return item.title;
            });
            const { impressionSchSr, schModel, schRecModel, schCollection, abtestGroup } = data.lists[0] || {};

            logFbaSearchShown({
                profileNo,
                subType: 'live',
                keywords: titleList,
                impressionSchSr,
                schModel,
                schRecModel,
                schCollection,
                abtestGroup,
            });
        }
    }, [isLoading, isSearchLayerFocus, isLoadingUserInfo]);

    if (isLoading) {
        return null;
    }

    // 실시간 인기 검색어 클릭 핸들러
    const onClickLiveKeywords = ({ title, ranking, clickSchSr }) => {
        keywordClick(title, 'Y', ranking, 'live');

        amplitudeEvent({
            event: 'click_realtime_keyword_pc',
            eventProperties: {
                screen_name: 'search',
                keyword: title,
            },
        });

        const { schModel, schRecModel, schCollection, abtestGroup } = data.lists[0] || {};
        logFbaSearchClick({
            profileNo,
            subType: 'live',
            keyword: title,
            rank: ranking,
            clickSchSr,
            schModel,
            schRecModel,
            schCollection,
            abtestGroup,
        });
    };

    return (
        <ul data-testid="search-real-keywords">
            <SearchTitle>실시간 인기 검색어</SearchTitle>
            {data.lists?.map(({ title, ranking, clickSchSr }) => (
                <li key={`item-${title}`}>
                    <PopularSearchButton
                        data-testid="search-real-keyword"
                        data-title={title}
                        type="button"
                        onClick={() => onClickLiveKeywords({ title, ranking, clickSchSr })}
                    >
                        <PopularSearchRanking>{ranking}</PopularSearchRanking>
                        <PopularSearchTitle>{title}</PopularSearchTitle>
                    </PopularSearchButton>
                </li>
            ))}
            <PopularSearchRankingDate>{`${data.insertDate} 기준`}</PopularSearchRankingDate>
        </ul>
    );
};

const Search = ({ keyword = '', data: listData, isPage } = {}) => {
    const { ref: inputRef, value, setValue, searchCall, onChange, onKeyPress, data: searchData } = useInput(keyword);
    const { data } = searchData || {};

    const { isPayUser } = useUserData({});

    const [isFocus, setIsFocus] = useState(false);
    const [debouncedValue, setDebouncedValue] = useState(false);
    const [{ profileNo }] = useUserInfo();

    const [, cancel] = useDebounce(
        () => {
            setIsFocus(debouncedValue);
        },
        10,
        [debouncedValue],
    );

    const [searchInfo, setSearchInfo] = useSearchState();
    const handleFocus = (event) => {
        setDebouncedValue(true);
        setSearchInfo((prev) => ({
            ...prev,
            isViewLayer: true,
            isFocus: true,
        }));
    };
    const handleFocusOut = (event) => {
        // setSearchInfo(prev => ({
        //     isFocus: false,
        // }));
        setIsFocus(false);
    };

    useEffect(() => {
        if (searchInfo.isViewLayer) {
            const targetEl = inputRef.current;
            if (targetEl) {
                targetEl.selectionStart = targetEl.value.length;
                targetEl.selectionEnd = targetEl.value.length;
                targetEl.focus();
            }
            document.body.style.overflowY = 'hidden';
        } else {
            // inputRef.current.blur();
        }

        return () => {
            document.body.style.overflowY = 'scroll';
        };
    }, [searchInfo]);

    useEffect(() => {
        if (!searchInfo.isFocus) {
            // setDebouncedValue(false);
            // setIsFocus(false);
        }
    }, [searchInfo.isFocus]);

    // 레이어 닫기
    const closeLayer = () => {
        setSearchInfo((prev) => ({
            ...prev,
            isViewLayer: false,
            isFocus: false,
        }));
        setDebouncedValue(false);
        setIsFocus(false);
        inputRef.current?.blur();
    };

    // 라우터 변경시 레이어 닫기
    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = (url, { shallow }) => {
            closeLayer();
        };
        router.events.on('routeChangeStart', handleRouteChange);
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, []);

    // 검색 레이어 외부 클릭 시 닫기
    const wrapRef = useRef();
    useOutsideClick(wrapRef, (e) => {
        const ignoreEl = searchInfo.ignoreEl?.current;
        if (ignoreEl && ignoreEl.contains(e.target) && !searchInfo.isViewLayer) {
            return;
        }

        if (document.querySelector('.swal2-container')?.contains(e.target)) {
            return;
        }

        closeLayer();
    });

    // 키워드 외부 클릭
    const keywordClick = (value, isPopular = '', ranking = 0, funnel = 'manual') => {
        setValue(value);
        searchCall(value, isPopular, ranking, funnel);
    };

    const onClickSearch = useCallback(() => {
        if (value) {
            emitSearchResultEvent({ keyword: value });
            searchCall(value);
        } else {
            Modal.alert({
                text: '검색어를 입력해주세요.',
            }).then(({ isConfirmed }) => {
                if (isConfirmed) {
                    setTimeout(() => {
                        inputRef?.current?.focus();
                    }, 300);
                }
            });
        }
    }, [value, searchCall]);

    // 검색 레이어 결과 중 텍스트 클릭 핸들러
    const onClickRelatedSearchText = ({ searchItem = {}, order }) => {
        let fbaParams;
        if (searchItem.abtestGroup) {
            fbaParams = {
                clickSchSr: searchItem.clickSchSr,
                schModel: searchItem.schModel,
                schRecModel: searchItem.schRecModel,
                schCollection: searchItem.schCollection,
                abtestGroup: searchItem.abtestGroup,
            };
        }

        keywordClick(searchItem.title, 'N', null, 'related_keyword');
        logFbaSearchAutoCompleteClick({
            profileNo,
            keyword: value,
            subType: 'related_keyword',
            rank: order,
            mediaCode: searchItem?.code,
            ...fbaParams,
        });
    };

    // Amplitude - 검색 화면 진입 시
    useEffect(() => {
        amplitudeEvent({
            event: 'enter_search_pc',
            eventProperties: {
                screen_name: 'search',
            },
        });
    }, []);

    return (
        <StyledSearchLayer isPage={!searchInfo.isViewLayer}>
            <SimpleBar style={{ maxHeight: '100vh' }} data-testid="search-layer">
                <div ref={wrapRef} className={isFocus || searchInfo.isViewLayer ? 'is-layer' : ''}>
                    <label className="search__input" htmlFor="search__input">
                        <StyledSearchInput
                            ref={inputRef}
                            id="search__input"
                            type="text"
                            placeholder="제목, 인물명을 입력해보세요."
                            value={value}
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                            onFocus={handleFocus}
                            onBlur={handleFocusOut}
                            data-testid="search-input"
                        />
                        <button type="button" className="icon__search" onClick={onClickSearch} data-testid="search-button">
                            검색
                        </button>
                    </label>
                    {(isFocus || searchInfo.isViewLayer) && (
                        <>
                            <div className="lists__columns lists__columns-vertical" data-testid="search-result-swiper-list">
                                {data.lists?.map((item, idx) => {
                                    let fbaParams;
                                    if (item.abtestGroup) {
                                        fbaParams = {
                                            clickSchSr: item.clickSchSr,
                                            schModel: item.schModel,
                                            schRecModel: item.schRecModel,
                                            schCollection: item.schCollection,
                                            abtestGroup: item.abtestGroup,
                                        };
                                    }
                                    return (
                                        <Item
                                            key={`item-${idx}`}
                                            bandType="programBasic"
                                            item={item}
                                            idx={idx}
                                            ratio={ratioCheck('programBasic').ratioValue}
                                            isSearchLayer
                                            searchKeyword={value}
                                            isPayUser={isPayUser}
                                            fbaParams={fbaParams}
                                        />
                                    );
                                })}
                            </div>

                            <div className="search__preview layout__flex-left">
                                <SearchResultText data-testid="search-result-related-keywords">
                                    {data.textLists?.map((title, idx) => (
                                        <li key={`item-text-${title.title}`}>
                                            <button
                                                type="button"
                                                onClick={() => onClickRelatedSearchText({ searchItem: title, order: idx })}
                                                className="title"
                                            >
                                                <span dangerouslySetInnerHTML={{ __html: title.kwauto }} />
                                            </button>
                                        </li>
                                    ))}
                                </SearchResultText>
                            </div>

                            {(!data.lists || data.lists?.length === 0) && (
                                <div className="search__preview layout__flex-left">
                                    <SearchSaveKeywords keywordClick={keywordClick} isSearchLayerFocus={isFocus} />
                                    <SearchRealKeywords keywordClick={keywordClick} isSearchLayerFocus={isFocus} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </SimpleBar>
        </StyledSearchLayer>
    );
};
export default Search;
