/**
 * broadcastStartTime 처럼 응답 받은 YYYYMMDDHHmmss 형식의 숫자가 14자리인지 확인합니다.
 *
 * @param {number} number - 검사할 숫자
 * @returns {boolean} 숫자가 14자리면 true, 그렇지 않으면 false를 반환합니다.
 */
const isFourteenDigits = (number: number): boolean => {
    return /^\d{14}$/.test(number?.toString());
};

export default isFourteenDigits;
