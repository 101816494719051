/* eslint-disable class-methods-use-this,no-console,no-underscore-dangle */
import CrossPlatformController from '@utils/crossPlatform/CrossPlatformController';
import {
    AndroidHapticFeedbackType,
    CrossPlatformAppConfigurationEvent,
    CrossPlatformChangeMediaOptions,
    CrossPlatformChangeOrientationOptions,
    CrossPlatformChangePlayerFeatureOptions,
    CrossPlatformClipPlayConfigurationChangeEvent,
    CrossPlatformConfirmDialogEvent,
    CrossPlatformEvent,
    CrossPlatformGetContentInfoEvent,
    CrossPlatformHapticFeedbackOptions,
    CrossPlatformMediaChangeEvent,
    CrossPlatformOpenTvingTalkOptions,
    CrossPlatformPageInitializedOptions,
    CrossPlatformPushNotificationPermissionEvent,
    CrossPlatformRect,
    CrossPlatformRequestContentChangeEvent,
    CrossPlatformSafeAreaEvents,
    CrossPlatformServiceNotification,
    CrossPlatformShareLinkOptions,
    CrossPlatformShowDialogOptions,
    CrossPlatformShowNotificationOptions,
    CrossPlatformModeChangeEvent,
    CrossPlatformType,
    Orientation,
    CrossPlatformShowBottomSheetEventOptions,
    CrossPlatformConfirmBottomSheetEvent,
    CrossPlatformModerateTvintTalkEvent,
    CrossPlatformModerateTvintTalkEventOptions,
    CrossPlatformContentInsetEvents,
} from '@utils/crossPlatform';
import {
    CrossPlatformChangePlayerModeEvent,
    CrossPlatformChangePlayerStatusEvent,
    CrossPlatformOpenUrlOptions,
    CrossPlatformSendBrazeCustomEventOptions,
} from '@utils/crossPlatform/types';
import { PcPlayerInfo } from '@store/state';
import { KeyedMutator } from 'swr';
import { isServer } from '@utils/device';
import { KboMultiviewItem } from '@typings/kbo/item';
import { KboBand } from '@typings/kbo/band';
import { KboContentsResponse } from '@typings/kbo';

declare global {
    interface Window {
        TvingAndroid: Record<string, (data?: string) => void>;
    }
}

class AndroidCrossPlatformController implements CrossPlatformController {
    private _setPlayerInfo: KeyedMutator<PcPlayerInfo> | null = null;

    set setPlayerInfo(value: KeyedMutator<PcPlayerInfo> | null) {
        this._setPlayerInfo = value;
    }

    constructor() {
        if (isServer()) {
            return;
        }

        window.onMediaStatusPlayer = this.onMediaStatusPlayer.bind(this);
        window.onBackPressed = this.onBackPressed.bind(this);
        window.onChangePlayerSize = this.onChangePlayerSize.bind(this);
        window.onMediaChange = this.onMediaChange.bind(this);
        window.getContentInfo = this.getContentInfo.bind(this);
        window.onPushNotificationPermission = this.onPushNotificationPermission.bind(this);
        window.onChangeOrientation = this.onChangeOrientation.bind(this);
        window.onConfirmDialog = this.onConfirmDialog.bind(this);
        window.onStatusUpdate = this.onStatusUpdate.bind(this);
        window.onStart = this.onStart.bind(this);
        window.onStop = this.onStop.bind(this);
        window.onSafeArea = this.onSafeArea.bind(this);
        window.onChangeContentInset = this.onChangeContentInset.bind(this);
        window.onForeground = this.onForeground.bind(this);
        window.onBackground = this.onBackground.bind(this);
        window.onTimeMachineChange = this.onTimeMachineChange.bind(this);
        window.getServiceNotification = this.getServiceNotification.bind(this);
        window.setServiceNotification = this.setServiceNotification.bind(this);
        window.onServiceNotification = this.onServiceNotification.bind(this);
        window.onClipPlayConfiguration = this.onClipPlayConfiguration.bind(this);
        window.onRequestContentChange = this.onRequestContentChange.bind(this);
        window.getInitAppConfigurationInfo = this.getInitAppConfigurationInfo.bind(this);
        window.onInitAppConfigurationInfo = this.onInitAppConfigurationInfo.bind(this);
        window.onChangePlayerStatus = this.onChangePlayerStatus.bind(this);
        window.onRequestNextContent = this.onRequestNextContent.bind(this);
        window.onMultiviewChange = this.onMultiviewChange.bind(this);
        window.onChangePlayerFeature = this.onChangePlayerFeature.bind(this);
        window.onChangePlayerMode = this.onChangePlayerMode.bind(this);
        window.onConfirmBottomSheet = this.onConfirmBottomSheet.bind(this);
        window.onModerateTvingTalk = this.onModerateTvingTalk.bind(this);
    }

    getCrossPlatformType(): CrossPlatformType {
        return CrossPlatformType.ANDROID;
    }

    pageInitialized(crossPlatformPageInitializedOptions: CrossPlatformPageInitializedOptions): void {
        console.log('[AndroidCrossPlatformController] pageInitialized');
        this.appInterface('pageInitialized', crossPlatformPageInitializedOptions);
    }

    reloadWebview(): void {
        console.log('[AndroidCrossPlatformController] reloadWebview');
        this.appInterface('reloadWebview');
    }

    getContentInfo(contentInfo: CrossPlatformGetContentInfoEvent): void {
        console.log('[AndroidCrossPlatformController] getContentInfo');
        const customEvent = new CustomEvent('getContentInfo', { detail: contentInfo });
        window.dispatchEvent(customEvent);
    }

    onMediaChange(event: CrossPlatformMediaChangeEvent): void {
        console.log('[AndroidCrossPlatformController] onMediaChanged');
        const customEvent = new CustomEvent('onMediaChanged', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onMediaStatusPlayer(event: CrossPlatformEvent): void {
        console.log('[AndroidCrossPlatformController] onMediaStatusPlayer');
        const customEvent = new CustomEvent('onMediaStatusPlayer', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onStatusUpdate(event: unknown): void {
        console.log('[AndroidCrossPlatformController] onStatusUpdate');
        const customEvent = new CustomEvent('onStatusUpdate', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onStart(): void {
        console.log('[AndroidCrossPlatformController] onStart');
        this.appInterface('onStart');
    }

    onStop(): void {
        console.log('[AndroidCrossPlatformController] onStop');
        this.appInterface('onStop');
    }

    visiblePlayer(visiblePlayerOptions?: CrossPlatformRect): void {
        console.log('[AndroidCrossPlatformController] visiblePlayer');
        this.appInterface('visiblePlayer', visiblePlayerOptions);
    }

    hiddenPlayer(): void {
        console.log('[AndroidCrossPlatformController] hiddenPlayer');
        this.appInterface('hiddenPlayer');
    }

    changePlayerMedia(changeMediaOptions: CrossPlatformChangeMediaOptions): void {
        console.log('[AndroidCrossPlatformController] changePlayerMedia');
        this.appInterface('changePlayerMedia', changeMediaOptions);
    }

    stopPlayer(): void {
        console.log('[AndroidCrossPlatformController] stopPlayer');

        if (this._setPlayerInfo) {
            void this._setPlayerInfo((prev) => ({
                ...prev,
                pathname: '',
                mediaCode: '',
                isPreparing: true,
                isPlaying: false,
                playbackRate: 1,
                isPlayingTimeshift: false,
            }));
        }

        this.appInterface('stopPlayer');
    }

    playPlayer(): void {
        console.log('[AndroidCrossPlatformController] playPlayer');
        this.appInterface('playPlayer');
    }

    pausePlayer(): void {
        console.log('[AndroidCrossPlatformController] pausePlayer');
        this.appInterface('pausePlayer');
    }

    showPlayerNotification(showNotificationOptions: CrossPlatformShowNotificationOptions): void {
        console.log('[AndroidCrossPlatformController] showPlayerNotification');
        this.appInterface('showPlayerNotification', showNotificationOptions);
    }

    hidePlayerNotification(): void {
        console.log('[AndroidCrossPlatformController] hidePlayerNotification');
        this.appInterface('hidePlayerNotification');
    }

    showPlayerController(): void {
        console.log('[AndroidCrossPlatformController] showPlayerController');
        this.appInterface('showPlayerController');
    }

    hidePlayerController(): void {
        console.log('[AndroidCrossPlatformController] hidePlayerController');
        this.appInterface('hidePlayerController');
    }

    changePlayerSize(playerSizeOptions: CrossPlatformRect): void {
        console.log('[AndroidCrossPlatformController] changePlayerSize');
        this.appInterface('changePlayerSize', playerSizeOptions);
    }

    onChangePlayerSize(playerSizeOptions: CrossPlatformRect): void {
        console.log('[AndroidCrossPlatformController] onChangePlayerSize');
        const customEvent = new CustomEvent('onChangePlayerSize', { detail: playerSizeOptions });
        window.dispatchEvent(customEvent);
    }

    getPushNotificationPermission(): void {
        console.log('[AndroidCrossPlatformController] getPushNotificationPermission');
        this.appInterface('getPushNotificationPermission');
    }

    onPushNotificationPermission(event: CrossPlatformPushNotificationPermissionEvent): void {
        console.log('[AndroidCrossPlatformController] onPushNotificationPermission');
        const customEvent = new CustomEvent('onPushNotificationPermission', { detail: event });
        window.dispatchEvent(customEvent);
    }

    needsPushNotificationPermission(): void {
        console.log('[AndroidCrossPlatformController] needsPushNotificationPermission');
        this.appInterface('needsPushNotificationPermission');
    }

    pushWebView(url: string): void {
        console.log('[AndroidCrossPlatformController] pushWebView');
        this.appInterface('pushWebView', { url });
    }

    modalWebView(url: string): void {
        console.log('[AndroidCrossPlatformController] modalWebView');
        this.appInterface('modalWebView', { url });
    }

    closeWebView(): void {
        console.log('[AndroidCrossPlatformController] closeWebView');
        this.appInterface('closeWebView');
    }

    showContentDetail(code: string): void {
        console.log('[AndroidCrossPlatformController] showContentDetail');
        this.appInterface('showContentDetail', { code });
    }

    showShorts(code: string, params: string, screenName: string, id: number): void {
        console.log('[AndroidCrossPlatformController] showShorts');
        this.appInterface('showShorts', { code, params, screenName, id });
    }

    showContentDetailWebView(code?: string, url?: string): void {
        console.log('[AndroidCrossPlatformController] showContentDetailWebView');
        this.appInterface('showContentDetailWebView', { code, url });
    }

    onBackPressed(): void {
        console.log('[AndroidCrossPlatformController] onBackPressed');
        this.appInterface('onBackPressed');
    }

    hapticFeedback(
        hapticFeedbackOptions: CrossPlatformHapticFeedbackOptions = {
            hapticType: AndroidHapticFeedbackType.LONG_PRESS,
        },
    ) {
        console.log('[AndroidCrossPlatformController] hapticFeedback');
        this.appInterface('hapticFeedback', hapticFeedbackOptions);
    }

    changeOrientation(changeOrientationOptions: CrossPlatformChangeOrientationOptions): void {
        console.log('[AndroidCrossPlatformController] changeOrientation');
        this.appInterface('changeOrientation', changeOrientationOptions);
    }

    openAppSystemSetting(): void {
        console.log('[AndroidCrossPlatformController] openAppSystemSetting');
        this.appInterface('openAppSystemSetting');
    }

    showDialog(showDialogOptions: CrossPlatformShowDialogOptions): void {
        console.log('[AndroidCrossPlatformController] showDialog');
        this.appInterface('showDialog', showDialogOptions);
    }

    onConfirmDialog(event: CrossPlatformConfirmDialogEvent): void {
        console.log('[AndroidCrossPlatformController] onConfirmDialog');
        const customEvent = new CustomEvent('onConfirmDialog', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onChangeOrientation(event: Orientation): void {
        console.log('[AndroidCrossPlatformController] onChangeOrientation');
        const customEvent = new CustomEvent('onChangeOrientation', { detail: event });
        window.dispatchEvent(customEvent);
    }

    openTvingTalk(openTvingTalkOptions: CrossPlatformOpenTvingTalkOptions): void {
        console.log('[AndroidCrossPlatformController] openTvingTalk');
        this.appInterface('openTvingTalk', openTvingTalkOptions);
    }

    shareLink(shareLinkOptions: CrossPlatformShareLinkOptions): void {
        console.log('[AndroidCrossPlatformController] shareLink');
        this.appInterface('shareLink', shareLinkOptions);
    }

    getSafeArea() {
        console.log('[AndroidCrossPlatformController] getSafeArea');
        this.appInterface('getSafeArea');
    }

    onSafeArea(event: CrossPlatformSafeAreaEvents) {
        console.log('[AndroidCrossPlatformController] onSafeArea');
        const customEvent = new CustomEvent('onSafeArea', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onChangeContentInset(event: CrossPlatformContentInsetEvents) {
        console.log('[AndroidCrossPlatformController] onChangeContentInset');
        const customEvent = new CustomEvent('onChangeContentInset', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onForeground(): void {
        console.log('[AndroidCrossPlatformController] onForeground');
        const customEvent = new CustomEvent('onForeground');
        window.dispatchEvent(customEvent);
    }

    onBackground(): void {
        console.log('[AndroidCrossPlatformController] onBackground');
        const customEvent = new CustomEvent('onBackground');
        window.dispatchEvent(customEvent);
    }

    onTimeMachineChange(event: CrossPlatformModeChangeEvent): void {
        console.log('[AndroidCrossPlatformController] onTimeMachineChange');
        const customEvent = new CustomEvent('onTimeMachineChange', { detail: event });
        window.dispatchEvent(customEvent);
    }

    changePlayerFeature(changePlayerFeatureOptions: CrossPlatformChangePlayerFeatureOptions) {
        console.log('[AndroidCrossPlatformController] changePlayerFeature');
        this.appInterface('changePlayerFeature', changePlayerFeatureOptions);
    }

    getClipPlayConfiguration() {
        console.log('[AndroidCrossPlatformController] getClipPlayConfiguration');
        this.appInterface('getClipPlayConfiguration');
    }

    setClipPlayConfiguration(isContinuous: boolean) {
        console.log('[AndroidCrossPlatformController] setClipPlayConfiguration');
        this.appInterface('setClipPlayConfiguration', { isContinuous });
    }

    onClipPlayConfiguration(event: CrossPlatformClipPlayConfigurationChangeEvent): void {
        console.log('[AndroidCrossPlatformController] onClipPlayConfiguration');
        const customEvent = new CustomEvent('onClipPlayConfiguration', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onRequestContentChange(event: CrossPlatformRequestContentChangeEvent): void {
        console.log('[AndroidCrossPlatformController] onRequestContentChange');
        const customEvent = new CustomEvent('onRequestContentChange', { detail: event });
        window.dispatchEvent(customEvent);
    }

    appInterface(method: string, params: unknown = ''): void {
        console.log('[AndroidCrossPlatformController] appInterface', method, params);
        if (!window.TvingAndroid) {
            // eslint-disable-next-line no-alert
            console.error('appInterface is not defined');
            return;
        }

        try {
            window.TvingAndroid[method](JSON.stringify(params));
        } catch (e) {
            console.error('[AndroidCrossPlatformController] appInterface', method, params, e);
        }
    }

    setServiceNotification(serviceNotification: CrossPlatformServiceNotification) {
        console.log('[AndroidCrossPlatformController] setServiceNotification');
        this.appInterface('setServiceNotification', serviceNotification);
    }

    getServiceNotification() {
        this.appInterface('getServiceNotification');
    }

    onServiceNotification(event: CrossPlatformServiceNotification): void {
        console.log('[AndroidCrossPlatformController] onServiceNotification');
        const customEvent = new CustomEvent('onServiceNotification', { detail: event });
        window.dispatchEvent(customEvent);
    }

    getInitAppConfigurationInfo() {
        console.log('[AndroidCrossPlatformController] getInitAppConfigurationInfo');
        this.appInterface('getInitAppConfigurationInfo');
    }

    onInitAppConfigurationInfo(event: CrossPlatformAppConfigurationEvent) {
        console.log('[AndroidCrossPlatformController] onInitAppConfigurationInfo');
        const customEvent = new CustomEvent('onInitAppConfigurationInfo', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onChangePlayerStatus(event: CrossPlatformChangePlayerStatusEvent): void {
        console.log('[AndroidCrossPlatformController] onChangePlayerStatus');
        const customEvent = new CustomEvent('onChangePlayerStatus', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onRequestNextContent(): void {
        console.log('[AndroidCrossPlatformController] onRequestNextContent');
        const customEvent = new CustomEvent('onRequestNextContent');
        window.dispatchEvent(customEvent);
    }

    didChangeInputFocus(isFocus: boolean): void {
        console.log('[AndroidCrossPlatformController] didChangeInputFocus');
        this.appInterface('didChangeInputFocus', { isFocus });
    }

    openUrl(openUrlOptions: CrossPlatformOpenUrlOptions) {
        console.log('[AndroidCrossPlatformController] openUrl');
        this.appInterface('openUrl', openUrlOptions);
    }

    sendBrazeCustomEvent(sendBrazeCustomEventOptions: CrossPlatformSendBrazeCustomEventOptions) {
        console.log('[AndroidCrossPlatformController] sendBrazeCustomEvent');
        const options = {
            ...sendBrazeCustomEventOptions,
            eventProperties: sendBrazeCustomEventOptions.eventProperties || {},
        };
        this.appInterface('sendBrazeCustomEvent', options);
    }

    openAppStore() {
        this.openUrl({ url: 'market://details?id=net.cj.cjhv.gs.tving' });
    }

    transferMultiviewApiResponse(multiviewApiResponse: KboContentsResponse<KboBand<KboMultiviewItem>>) {
        console.log('[AndroidCrossPlatformController] transferMultiviewApiResponse: ANDROID IS NOT SUPPORTED!', multiviewApiResponse);
    }

    onMultiviewChange(event: CrossPlatformModeChangeEvent) {
        console.log('[AndroidCrossPlatformController] onMultiviewChange', event);
        const customEvent = new CustomEvent('onMultiviewChange', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onChangePlayerFeature(event: CrossPlatformChangePlayerFeatureOptions) {
        console.log('[AndroidCrossPlatformController] onChangePlayerFeature', event);
        const customEvent = new CustomEvent('onChangePlayerFeature', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onChangePlayerMode(event: CrossPlatformChangePlayerModeEvent): void {
        console.log('[AndroidCrossPlatformController] onChangePlayerMode');
        const customEvent = new CustomEvent('onChangePlayerMode', { detail: event });
        window.dispatchEvent(customEvent);
    }

    showBottomSheet(showBottomSheetOptions: CrossPlatformShowBottomSheetEventOptions) {
        console.log('[AndroidCrossPlatformController] showBottomSheet');
        this.appInterface('showBottomSheet', showBottomSheetOptions);
    }

    onConfirmBottomSheet(event: CrossPlatformConfirmBottomSheetEvent): void {
        console.log('[AndroidCrossPlatformController] onConfirmDialog');
        const customEvent = new CustomEvent('onConfirmBottomSheet', { detail: event });
        window.dispatchEvent(customEvent);
    }

    getTvingTalkModeration() {
        console.log('[AndroidCrossPlatformController] getTvingTalkModeration');
        this.appInterface('getTvingTalkModeration');
    }

    reportMessage(messageId: number) {
        console.log('[AndroidCrossPlatformController] reportMessage');
        this.appInterface('reportMessage', { id: messageId });
    }

    moderateTvingTalk(moderateTvingTalkEventOptions: CrossPlatformModerateTvintTalkEventOptions) {
        console.log('[AndroidCrossPlatformController] moderateTvingTalk', { moderateTvingTalkEventOptions });
        this.appInterface('moderateTvingTalk', moderateTvingTalkEventOptions);
    }

    onModerateTvingTalk(event: CrossPlatformModerateTvintTalkEvent) {
        console.log('[AndroidCrossPlatformController] onModerateTvingTalk');
        const customEvent = new CustomEvent('onModerateTvingTalk', { detail: event });
        window.dispatchEvent(customEvent);
    }
}

export default AndroidCrossPlatformController;
