import Label from '../../../atom/label/Label';
import { LABEL_IMAGE, LABEL_TYPE } from '../../../constants/labelConstants';

interface FreeLabelProps {
  type: 'PNG' | 'SVG';
  className?: string;
  tabIndex?: number;
}

const FreeLabel = ({ type, className, tabIndex }: FreeLabelProps) => {
  const IMAGE = LABEL_IMAGE[LABEL_TYPE.FREE];
  return (
    <div className={`label-wrapper free-label-wrapper ${className}`} tabIndex={tabIndex}>
      <Label
        labelImage={(type === 'PNG' && IMAGE.PNG) || undefined}
        SvgLabelImage={(type === 'SVG' && IMAGE.SVG) || undefined}
        tabIndex={tabIndex}
        alt={IMAGE.ALT}
      />
    </div>
  );
};

export default FreeLabel;
