import { AxiosError } from 'axios';

export const isHttpCancelled = (error: AxiosError) => {
    // status 0 인 경우 cancel 로 체크 추가
    const resStatus = error?.response?.status ?? -1;
    if (!error?.response || error?.code === 'ECONNABORTED' || resStatus === 0) {
        return true;
    }
    return false;
};
