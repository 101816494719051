import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { size } from '@styles/space';
import { device, mq } from 'src/styles/device';

export const StyledSearchLayer = styled.div`
    position: fixed;
    top: 0;
    z-index: 150;
    height: 100%;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);

    .simplebar-content > div {
        position: relative;
        padding-left: 5rem;
        padding-right: 5rem;
        padding-bottom: 5rem;
        background-color: rgba(25, 25, 25, 0);
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0);
        padding-top: 11.666rem !important;

        @media ${device.tablet} {
            padding-left: 12.5rem;
            padding-right: 12.5rem;
        }

        /* transition: background-color 0.1s, box-shadow 0.1s; */
        &.is-layer {
            max-height: 100%;
            background-color: rgba(25, 25, 25, 0.99);
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
            overflow: hidden;
            padding-top: 5.583rem !important;
        }
    }

    ${({ isPage }) =>
        isPage
            ? css`
                  position: absolute;
                  width: 100%;
                  background-color: transparent;
                  pointer-events: none;

                  ${mq({
                      top: size.gnbHeightMinus,
                  })}

                  > div {
                      pointer-events: visible;
                  }
              `
            : css`
                  width: 100%;
              `}

    .lists__title {
        font-size: 1.7em;
        font-weight: 500;
    }
    .icon__close-line {
        &.close-thin {
            position: absolute;
            bottom: 1.5em;
            right: 1.5em;
        }
    }
    .lists__columns-vertical {
        /* grid-template-columns: repeat(auto-fill, 200px); */
        /* grid-template-rows: repeat(2, 1fr); */
        margin-top: 3rem;
        grid-auto-rows: 0;
        overflow-y: hidden;
        .item {
            padding-bottom: 1.675em;
            &__title {
                color: rgba(222, 222, 222, 0.7);
                font-size: 1.333rem;
                font-weight: 400;
            }
        }
    }

    @media ${device.mobileL} {
        .lists__columns-vertical {
            grid-template: auto/repeat(3, 1fr);
        }
    }

    @media ${device.tablet} {
        .lists__columns-vertical {
            grid-template: auto/repeat(5, 1fr);
        }
    }

    @media ${device.laptop} {
        .lists__columns-vertical {
            grid-template: auto/repeat(6, 1fr);
        }
    }

    @media ${device.desktop} {
        .lists__columns-vertical {
            grid-template: auto/repeat(7, 1fr);
        }
    }
`;
