import amplitude from 'amplitude-js';

export type AmplitudeEventProps = {
  event: string;
  eventProperties?: {
    [key: string]: string | null | number;
  };
};
const amplitudeEvent = ({ event, eventProperties }: AmplitudeEventProps) => {
  amplitude.logEvent(event, eventProperties);
};

export default amplitudeEvent;
