import { useQuery } from '@tanstack/react-query';
import generateChatToken from '@apis/token/generateChatToken';
import dayjs from 'dayjs';
import { getCookie, setCookie } from '@utils/common/cookie';
import useDraftState from '@tving/utils/src/hooks/common/useDraftState';
import { useEffect } from 'react';
import refreshChatToken from '@apis/token/refreshChatToken';

type Props = {
    profileNumber: string | undefined;
    accessToken: string;
    tvingToken: string;
};

type SendbirdSessionForCookie = {
    token: string;
    expires: string;
    refreshBy: string;
};

export type SendbirdSession = {
    token: string;
    expiresAt: number;
    refreshAt: number;
};

const log = (...msg: any) => console.log('[#sendbirdsession]', ...msg);

const isExpired = (session: SendbirdSessionForCookie | undefined) => (session ? dayjs().isAfter(dayjs(session.expires).subtract(10, 'day')) : false);
const isNilOrEmpty = (value: any) => value === undefined || value === null || value === '';

const useSendbirdSession = ({ profileNumber, accessToken, tvingToken }: Props) => {
    const personalSendbirdSession: SendbirdSessionForCookie | undefined = getCookie(`sendbirdSession_${profileNumber}`);
    const [session, setSession] = useDraftState(personalSendbirdSession);

    // log({ session, personalSendbirdSession, profileNumber, getCookie: getCookie(`sendbirdSession_${profileNumber}`) });

    const isEnabled = !isExpired(personalSendbirdSession) && !isNilOrEmpty(profileNumber);

    // log({
    //     isEnabled,
    //     isExpired: isExpired(personalSendbirdSession),
    //     isNilOrEmpty: isNilOrEmpty(profileNumber),
    // });

    // 실패 했을 경우 대비
    const { data, isLoading, isInitialLoading, error, refetch } = useQuery<SendbirdSession>(
        ['user', 'sendbird', 'session', profileNumber],
        generateChatToken.bind(null, { accessToken, tvingToken }),
        {
            enabled: isEnabled,
            retry: 3,
        },
    );

    const {
        data: refreshData,
        isLoading: isRefreshLoading,
        error: refreshError,
        refetch: refreshRefetch,
    } = useQuery(['user', 'sendbird', 'session', profileNumber, 'refresh'], refreshChatToken.bind(null, { accessToken, tvingToken }), {
        enabled: false,
        retry: 3,
    });

    const refetchSession = async () => {
        try {
            const refreshSessionResult = await refetch();
        } catch (error) {
            console.error(error);
        }
    };

    const refreshSession = async () => {
        try {
            const refreshSessionRefetchResult = await refreshRefetch();
            const refreshSessionResult = await refetch();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!data) {
            return;
        }

        const { token, expiresAt, refreshAt } = data;
        const expires = dayjs.unix(expiresAt / 1000).toDate();
        const refreshBy = dayjs.unix(refreshAt / 1000).toDate();

        setCookie(`sendbirdSession_${profileNumber}`, { token, expires, refreshBy }, { expires, path: '/' });
    }, [data, profileNumber]);

    // log({ data: data ?? session, isLoading, error });

    const calculatedSession: SendbirdSession | undefined = session && {
        token: session.token,
        expiresAt: dayjs(session.expires).valueOf(),
        refreshAt: dayjs(session.refreshBy).valueOf(),
    };

    // console.log('#tving-talk - session', { data, refreshData, calculatedSession });
    // console.log('#tving-talk - session', { data: data?.token, refreshData: refreshData?.token, calculatedSession: calculatedSession?.token });

    return {
        data: data ?? calculatedSession,
        // Issue: enabled가 false일 때 isLoading이 true로 나오는 문제, v5에서 해결 됨.
        // https://github.com/TanStack/query/issues/3584
        isLoading: session ? false : isLoading,
        error: error || refreshError,
        refetch,
        refetchSession,
        refreshSession,
        sessionError: error,
        refreshSessionError: refreshError,
    };
};

export default useSendbirdSession;
