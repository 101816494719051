import { z } from 'zod';

import { PURCHASED_CHECK_STATUS } from '../constants/billingConstants';
import { GRADE_CODE, LOGIN_RESULT_CODE } from '../constants/commonConstants';
import { SALE_STATUS } from '../constants/detailConstants';
import { DURATION_UNIT } from '../constants/userConstants';

export interface TvingHeader {
  status: number;
  message: string;
}

export interface TvingResponse<T> {
  data: T;
  body: T;
  header: TvingHeader;
}

export interface NewTvingResponse<T> {
  code: string;
  message: string;
  data: T;
  detailMessage?: string;
}

export interface LoginResponse<T> {
  code: LOGIN_RESULT_CODE;
  message: string;
  data: T;
  detailMessage?: string;
}
export type NotUsingNull = null;
export type NullableString = string | null;
export type NullableNumber = number | null;
export type NullableValue = [] | string | number | boolean | null;
export type ContentType = 'banner' | 'vod' | 'VOD' | 'MOVIE' | 'live';

export interface ArticleParameter {
  articleId: string;
  pocCode?: string;
}

export interface ArticlesParameter extends PageParameter {
  pocCode?: string;
}

export interface PageParameter {
  pageSize?: number;
  pageNo?: number;
  pageNum?: number; // 검색용 paging parameter
}

export interface I18nName {
  prefix_ko?: NullableString;
  ko: NullableString;
  en?: NullableString;
}

export interface CountByTime {
  hour: number; // 879;
  day: number; // 0;
  week: number; // 0;
  total: number; // 0;
  noParseWeek: number; // 2012033100000000000;
  noParseHour: number; // 2022042516000000879;
  noParseDay: number; // 2022042305000000000;
}

export interface ResourceInfo {
  url: string;
  url2?: NullableString;
  code: string;
}

export interface AspInfo {
  code: string;
  price: Price;
  mapping_code: string;
}

export interface DownloadPrice {
  price: Price;
  mcp_code: NullableString;
  scp_code: NullableString;
}

export interface DownloadInfo {
  code: string;
  url: string;
  file_size: number;
}

export interface Price {
  krw: number;
  usd: number;
  appstore: number;
  playstore: number;
  rental: number;
  collect: number;
}

export interface ProfileImageInfo {
  imgDate: string;
  imgOrder: number;
  imgUrl: string;
}

// exposure
export type ExposeType = 'PORT' | 'PRANK' | 'LAND' | 'BAN1' | 'BAN2' | 'BAN3' | 'KATE' | 'CHA';
export type HomeType = 'CLIP' | 'HISTORY' | 'KIDS' | 'LIVE' | 'MAIN' | 'MALL' | 'MOVIE' | 'MUSIC' | 'STYLE' | 'VOD' | 'PARAMOUNT';

export interface SimpleServer {
  time: string;
  host: string;
  port: number;
  context: string;
}

export interface SimpleUser {
  no: string;
  id: string;
  age: number;
  type: string;
  ip: string;
  profile_no: NullableString;
}

export interface ProfileInfo {
  adultCertiDt: NullableString;
  delYn: NullableString;
  lastAccessDt: NullableString;
  profileImgPath: string;
  profileNm: string;
  profileNo: string;
  profilePwd: NullableString;
  profilePwdYn: NullableString;
  profileToken: string;
  profileType: NullableString;
  userNo: NullableString;
}

export interface NewTvingProfileInfo {
  profileType: string;
  custNo: string;
  profileNo: string;
  profileName: string;
  profileImagePath: string;
  adultCertifyDate: NullableString;
  deleteYn: string;
  profileToken: string;
  profilePwdYn: NullableString;
}

export interface StreamInfoResult {
  code: string;
  message: NullableString;
  sub_message: NullableString;
  auth_type: string;
  content_type: ContentType;
  link_url: NullableString;
  link_target: NullableString;
}

export interface BillInfo {
  download: PaidInfo;
  stream: PaidInfo;
}

export interface PaidInfo {
  paid: string;
  remain_day: string;
  remain_time: string;
  exp_date: string;
  prod_id: string;
  product_type: string;
  package_id: string;
  provide_flag: NotUsingNull;
}

export interface StreamDetail {
  broadcast: BroadcastInfo;
  drm_license_server_list: string[];
  drm_license_assertion: string;
  payType: string;
  drm_multi_yn: string;
  vtt: VttInfo;
  drm_license_server: string;
  drm_yn: string;
  default_stream: string;
  timeshift: string;
  timeshift_paid_yn?: string;
  timeshift_time?: number;
}

export interface BroadcastInfo {
  playready: BroadcastUrl;
  fairplay: BroadcastUrl;
  widevine: BroadcastUrl;
}

export interface BroadcastUrl {
  broad_url: string;
}

export interface VttInfo {
  vtt_path: string;
  vtt_img: string;
}

export interface PassGroupInfo {
  CHROMECASTSERVYN: string; // 크롬캐스트 지원 여부
  PIPSERVYN: string; // PIP 지원여부
  QUICKVODYN: string; // QVOD 지원여부
  STREAMINGRESOLUTION: number; // 지원하는 최대 화질 720 or 1080 or 2160
}

export interface MyValidTicketInfo {
  CHARGEFLAG: number;
  CONSUMEDATE: string;
  DISPPRODNAME: string;
  DURATION: number;
  DURATIONTYPE: number;
  DURATIONUNIT: DURATION_UNIT; // 'YEAR' or 'MONTH'
  EXPDATE: string;
  ISAOSYN: string;
  ISIOSYN: string;
  ISNAVERYN: string;
  ISSPEED: number;
  PACKAGEATTR: number;
  PACKAGEID: number;
  PRODID: number;
  PURCHASECODE: string;
  ROWNUM: number;
  BANNERURL?: NullableString;
  STREAMAUTHTYPE: string;
}

export enum TicketReserveStatus {
  'NONE' = 'NONE',
  'RESERVE' = 'RESERVE',
}

export enum MyValidTicketBannerType {
  'RESERVE_D2C' = 'RESERVE_D2C',
  'COMMON' = 'COMMON',
  'WAVVE' = 'WAVVE',
}

export interface PlayerOfferDownAuth {
  CMSITEMID: string;
  ERRCODE: string;
  ERRMSG: string;
  PAID: string;
}

export interface PlayerOfferProduct {
  CJONERATE: number; //
  CMSITEMID: string; // 'M000368261';
  DISPPRODNAME: string; // '브로커';
  DOWNSERVYN: string; // 'Y';
  DRM_YN: string; // 'Y';
  DURATION: number;
  DURATIONM: string; // '365일권';
  DURATIONTYPE: number;
  DURATIONTYPEM: string; // '일';
  EYMD: string; // '20991231';
  ISITEMSALE: string; // 'Y';
  ISNOWUSEYN: string; // 'N';
  ITEMTYPE: number;
  MAXDOWNCNT: number;
  ORGCMSITEMID: string; // 'M000368261';
  PACKAGEATTR: number;
  PACKAGEID: number;
  PACKAGETYPE: number;
  PACKVODTYPE: number;
  PMTYPE: string; // 'N|PC|M000368261';
  PRICE: number;
  PRODID: number;
  PRODNAME: string; // '브로커';
  PRODTYPE: number;
  PROVIDETYPE: number;
  RECURAVAIL: number;
  RECURDCRATE: number;
  RECURPRICE: number;
  REFERENCEFLAG: number;
  RENTALDURATION: number;
  RENTALPRICE: number;
  SHOWRATE: number;
  SORTNO: number;
  VODDOWNMAXCNT: number;
  WATCHMAXCNT: number;
}

export interface PlayerOfferStreamAuth {
  CHARGENO: string;
  CHARGETYPE: string;
  CMSITEMID: string;
  ERRMSG: string;
  EXPDATE: string;
  PACKAGEID: string;
  PACKDOWNREMAINCNT: string;
  PAID: string;
  PRODID: string;
  PRODNAME: string;
  PRODUCTTYPE: string;
  REMAINDAY: string;
  REMAINTIME: string;
  RETVAL: string;
}

export interface AtvProduct {
  duration: number;
  durationType: number;
  packageId: number;
  price: number;
  prodDesc: string;
  prodId: number;
  prodName: string;
  productId: string;
  recurDcRate: number;
  recurPrice: number;
  watchMaxCnt: number;
}

export interface PurchasedCheckInfo {
  PRODUCT_ID: string;
  PURCHASE_TOKEN: string;
  STATUS: PURCHASED_CHECK_STATUS;
  STATUS_CODE: string;
}

export interface Expose {
  api_oper: NotUsingNull;
  api_param_app: NotUsingNull;
  api_param_mw: NotUsingNull;

  // api_param_pc: "/v2/media/kids/popular?pageNo=1&pageSize=20&order=viewWeek&free=all&guest=all&scope=all&personal=N&lastFrequency=Y&totalCountYn=y"
  api_param_pc: string;
  bg_rgb: NotUsingNull;
  // depth: "0"
  depth: string;
  // expose_cd: "EXCD0700"
  expose_cd: string;
  // expose_nm: "인기 키즈"
  expose_nm: string;
  // expose_ord: 2
  expose_ord: number;
  // expose_type: 'PRANK';
  expose_type: ExposeType;
  // home_type: 'KIDS';
  home_type: HomeType;
  login_yn: NullableString;
  // more_cnt: '0';
  more_cnt: string;
  // more_nm: '전체보기';
  more_nm: NullableString;
  more_type_app: NotUsingNull;
  more_type_mw: NotUsingNull;
  // more_type_pc: 'N';
  more_type_pc: string;
  more_url_app: NotUsingNull;
  more_url_mw: NotUsingNull;
  // more_url_pc: '/popular/kids';
  more_url_pc: NullableString;
  parent_expose_cd: NotUsingNull;
  // pay_yn: 'N';
  pay_yn: string;
  // subList: NotUsingNull;
  sub_nm: NotUsingNull;
  sub_ord: NotUsingNull;
  subList: NotUsingNull;
  host?: NotUsingNull;
}

export interface BannerContent {
  banner_title: string; // 'Bundesliga';
  banner_sub_title: NullableString; // null;
  banner_link_url: string; // '/event/bundesliga';
  banner_link_move_type: string; // 'N';
  banner_image_type: string; // 'U';
  banner_image_url: string; // '/upload/fe/highlight/2021/0810/20210810211401banner_image_url_u.png';
  background_color_left: NullableString; // '000000';
  background_color_right: NullableString; // 'f40000';
  banner_title2: string; // 'Bundesliga';
  banner_title3: NullableString; // null;
  banner_sub_title2: string; // '레전드는 분데스리가에서 시작된다!';
  banner_sub_title3: NullableString; // '21/22 분데스리가 주요 경기 독점 중계';
  banner_bc_notice: NullableString; // null;
  banner_bc_notice_color: NullableString; // null;
  banner_title_bold: string; // 'N';
  banner_sub_title_bold: string; // 'N';
  banner_title2_bold: string; // 'N';
  banner_title3_bold: string; // 'N';
  banner_sub_title2_bold: string; // 'N';mapping_contents_name
  banner_sub_title3_bold: string; // 'N';
}

export interface ChannelContent {
  id?: NullableString;
  code: string; // 'C00551';
  name: I18nName;
  category_code: string; // 'CG110';
  category_name: I18nName;
  description?: I18nName;
  quality?: string; // 'CVPR0200';
  hd_yn?: string; // ''Y';
  price?: Price;
  free_yn: string; // ''N';
  adult_yn: string; // ''N';
  item_sale_yn?: string; // ''Y';
  guest_watch_yn: string; // ''N';
  oversea_watch_yn?: string; // ''Y';
  image: ResourceInfo[];
  no: number; // 2;
  sort_no?: number; // 9;
  stick_channel_no?: number; // 13;
  mcp_code?: string; // ''1115';
  scp_code?: string; // ''18';
  time_shift_time: number; // 120;
  type: string; // ''CPCS0100';
  broadcast_type?: string; // ''CPSE0200';
  service_yn?: string; // ''Y';
  fan_yn: string; // ''N';
  parent_code?: string; // '';
  enm_code: string; // ''EA';
  ch_home_url: string; // ''http://tvn.tving.com/tvn';
  ch_home_m_url: string; // ''http://tvn.m.tving.com/tvn';
  adpre?: string; // '';
  admid?: string; // '';
  drm_multi_yn?: NotUsingNull; // null;
  contenttype: string; // '';
  dai_asset_id?: string; // '';
}

export interface ProgramContent {
  id?: NullableString;
  code: string;
  name: I18nName;
  category1_code?: string;
  category1_name?: I18nName;
  category2_code?: string;
  category2_name?: I18nName;
  product_year?: number;
  product_country?: string;
  production?: string;
  director: string[];
  actor: string[];
  synopsis: I18nName;
  story?: I18nName;
  image: ResourceInfo[];
  channel_code?: string;
  display_category1: string[];
  display_category2: string[];
  grade_code: string;
  adult_yn: string;
  series_code?: string;
  keywords1?: string[] | null;
  keywords2?: string[] | null;
  fan_yn: string;
  enm_code?: string;
  pgm_home_url?: string;
  pgm_home_m_url?: string;
  script?: string;
  broad_state: string;
  broad_dt?: string;
  broad_end_dt?: string;
  broad_week: string;
  broad_hour: string;
  broad_minu: string;
  sub_info_list?: string[];
  smr_program_code?: string; // ''
  quickup_yn?: string;
  uhd_4k_yn?: string;
  season_pgm_code: string;
  season_pgm_no: string;
  season_etc_vod_yn: string;
  tving_original_yn: string;
  tving_exclusive_yn: string;
  stream_meta_info?: string;
  episode_sort: number;
  paramount_yn?: string;
  event_yn?: string;
  last_frequency_yn?: string;
  free_yn?: string;
}

export interface EpisodesNearContent {
  current: VodContent;
  prev: EpisodeContent[];
  next: EpisodeContent[];
  prev_season_episodes: EpisodeContent[];
  next_season_episodes: EpisodeContent[];
}

export interface EpisodeContent {
  id?: NullableString;
  content_type?: string;
  fan_yn?: string;
  kids?: KidsContent | null;
  actor?: string[];
  broadcast_date: number;
  broadcast_day?: string;
  category1_code: string;
  category1_name: I18nName;
  category2_code: string;
  category2_name: I18nName;
  code: string;
  display_category1?: string[] | null;
  display_category2?: string[] | null;
  download?: DownloadPrice;
  download_yn?: string;
  duration: number;
  free_yn: string;
  frequency: number;
  grade_code: string;
  adult_yn: string;
  guest_watch_yn?: string;
  audio_type?: string | null;
  hdr_type?: string | null;

  image: ResourceInfo[];
  keywords1?: string[];
  keywords2?: string[];
  mcp_code: string;
  name: I18nName;
  price?: Price;
  program_code?: string;
  hd_yn?: string;
  quality?: string;
  scp_code: string;
  synopsis: I18nName;
  broadcast_datetime: number;
  same_code?: string;
  drm_yn?: string;
  drm_sale_type?: string;
  enm_code?: string;
  preview_display_yn: string;
  paramount_yn: string;
  pip_media_url?: string;
  mezzokey?: string;
  etc?: {
    teamcode: string;
    hometeam: string;
    awayteam: string;
    teamcode_name: string;
    hometeam_name: string;
    awayteam_name: string;
    pickle_yn: string;
  };
  mdrm_multi_yn?: string;
  mdrm_fair_yn?: string;
  mdrm_fair_filesize?: string;
  mdrm_play_yn?: string;
  mdrm_play_filesize?: string;
  mdrm_wide_yn?: string;
  mdrm_wide_filesize?: NotUsingNull;
  mdrm_package_key?: string;
  pip_cliptype: string;
  stream_meta_info: string;
  skip_sec?: string;
  abr_flag?: string;
  vtt_path?: string;
  vtt_img?: string;
  new_broadcast_yn?: string;
  uhd_4k_yn: string;
  ko_cc_yn?: string;
  service_open_date?: string;
  display_title?: string;
  apple_yn?: string;
}

export interface MovieContent {
  id?: NullableString;
  actor: string[];
  audio_type?: NullableString;
  type?: string;
  category1_code?: string;
  category1_name?: I18nName;
  category2_code?: string;
  category2_name?: I18nName;
  code: string;
  director: string[];
  display_category1?: string[];
  display_category2?: string[];
  download?: DownloadPrice;
  download_yn?: string;
  duration: number;
  free_yn?: string;
  grade_code: string;
  adult_yn: string;
  guest_watch_yn?: string;
  keywords1?: string[];
  keywords2?: string[];
  mcp_code?: string;
  name: I18nName;
  original_cp?: string;
  price?: Price;
  product_country?: string;
  product_year?: number;
  production?: string;
  hd_yn?: string;
  hdr_type?: NullableString;
  quality?: string;
  rating?: number;
  release_date: number;
  scp_code?: string;
  series_code?: string;
  story: I18nName;
  image: ResourceInfo[];
  preview_display_yn?: string;
  fan_yn: string;
  same_code?: string;
  uhd_4k_yn?: string;
  drm_yn: string;
  drm_multi_yn?: string;
  drm_sale_type: string;
  drm_fair_yn?: string;
  drm_fair_filesize?: NotUsingNull;
  drm_play_yn?: string;
  drm_play_filesize?: NotUsingNull;
  drm_wide_yn?: string;
  drm_wide_filesize?: NotUsingNull;
  enm_code: string;
  insert_date?: string;
  update_date?: string;
  cine_same_yn: string;
  first_open_yn: string;
  direct_ver_yn: string;
  unedit_ver_yn?: string;
  special_ver_yn?: string;
  dub_ver_yn: string;
  subtitle_ver_yn: string;
  extend_ver_yn?: string;
  event_yn: string;
  stcut_master_img?: string;
  stcut_seri_img?: string;
  amt_sale_yn?: string;
  bill_my_catchon_yn?: string;
  diversity_yn?: string;
  non_drm_yn?: string;
  trailer_url?: {
    trailer_yn: string;
    trailer_def_nm: string;
    trailer_def_cd: string;
  };
  movie_second: string;
  skip_sec: string;
  abr_flag?: string;
  vtt_path?: string;
  vtt_img?: string;
  watermark_flag?: string;
  season_movie_code?: string;
  season_movie_no?: number;
  season_etc_vod_yn?: string;
  stream_meta_info?: string;
  boxoffice_info: NotUsingNull;
  tving_original_yn: string;
  tving_exclusive_yn: string;
  billing_package_tag: string;
  drm_4k_yn: string;
  paramount_yn: string;

  ko_cc_yn?: string;
  apple_yn?: string;
}

export interface StreamInfoContent {
  last_view_time: number;
  program_code: string;
  program_name: string;
  episode_code: string;
  episode_name: string;
  program_enm_code: string;
  frequency: number;
  duration: number;
  dlna_support: string;
  info: VodContent;
  quickup_yn: string;

  broadcast_start_date?: string;
  broadcast_end_date?: string;

  vod_type?: string;
  channel_type?: string;
}

export interface ContentInfoContent {
  code: string;
  program_code?: string;
  vod_type: string;
  last_view_time: number;
  preview_display_yn: string;
  title: string;
  channel: string;
  channel_code?: string;
  frequency: number;
  duration: number;
  grade_code: string;
  director: string[];
  actor: string[];
  keywords: string[] | null;
  synopsis: string;
  image: ResourceInfo[];
  broad_state: NullableString;
  broad_end_dt: NullableString;
  broad_dt: NullableString;
  broad_hour: NullableString;
  broad_minu: NullableString;
  product_year: number;
  release_date: number;
  episode_sort: number;
  season_no: string;
  season_code: string;
  category_code: string;
  category_name: string;
  genre_code: string;
  genre_name: string;
  open_text: null;
  hdr_type: string;
  audio_type: string;
  billing_package_tag: string;
  drm_yn: string;
  fan_yn: string;
  tving_exclusive_yn: string;
  tving_original_yn: string;
  paramount_yn: string;
  quickup_yn: string;
  uhd_4k_yn: string;
  subtitle_ver_yn: string;
  dub_ver_yn: string;
  event_yn: string;
  cine_same_yn: string;
  first_open_yn: string;
  ko_cc_yn: string;
  sale_status: SALE_STATUS;
  free_yn: string;
  display_title: string;
  apple_yn: string;
}

export interface ScheduleBroadcastUrl {
  block_yn?: string;
}

export interface ClipContent {
  code: string;
  title: string;
  synopsis: string;
  image: string;
  content_img_url: string;
}

export interface VodSchedule {
  channel?: ChannelContent;
  episode?: EpisodeContent;
  program?: ProgramContent;
  movie?: MovieContent;

  broadcast_date?: string;
  broadcast_end_time?: string;
  broadcast_start_time?: string;
  grade_code?: string;
  schedule_code?: string;
  free_yn?: string;
  adult_yn?: string;
  broadcast_url?: ScheduleBroadcastUrl[];

  lastPlayTime?: string | number;
  viewDate?: string;
  purchaseDate?: string;
  expireDate?: string;
  price?: string;
  chargeNo?: string;
  remainDay?: string;
  masterSeq?: string;

  banner_type?: string;
  contents_code?: string;
  media_type?: string;

  clip_info?: ClipContent;
}

export interface VodContent {
  id?: string;
  vod_code: string; // 'E003721722';
  vod_name: I18nName;
  vod_type: string; // 'CSMD0100';
  grade_code: string; // 'CPTG0400';
  fan_yn?: string;
  adult_yn: string; // 'N';
  insert_date?: number; // 20220423224802;
  view_count?: CountByTime;
  like_count?: number; // 0;
  link_url?: NullableString;
  mobile_link_url?: NullableString;

  channel?: ChannelContent | null;
  program?: ProgramContent | null;
  episode?: EpisodeContent | null;
  kids?: KidsContent | null;
  movie?: MovieContent | null;
  store?: NotUsingNull;
  broadcast_url?: {
    network_code: string;
    os_code: string;
    screen_code: string;
    tele_code: string;
  }[];
  sale_count?: CountByTime;
  last_frequency_yn?: string;
  program_view_count?: CountByTime;
  asp_info?: AspInfo[] | null;
  support_info?: string[] | null;
  service_open_date: number;
  service_end_date: number;
  free_last_frequency_yn?: NullableString;
  stream?: ResourceInfo[] | null;
  billing_package_id?: string[];
  program_sale_count?: CountByTime;
  stream_support_info?: NullableString; // 'stream40,stream30,stream50';
  sale_package_yn?: string;
  sale_status: number;
  enm_code?: NullableString;
  billing_package_id_type?: {
    charge_fixed_ids: NullableString;
    charge_fixed_yn: NullableString;
  };

  isTvingtv?: boolean;
  channel_code?: string;
  reserve_time?: string;
  live_rating?: {
    realtime_chtp: string;
  };
  channel_name?: string;
  episode_name?: string;
  free_yn?: string;

  schedule?: VodSchedule;
  content_img_url?: string;
  apple_yn?: string;
}

export interface FanContentResult {
  content_code: NullableString;
  content_type: NullableString;
  fan_insert_date: string;
  fan_total_count: NullableNumber;
  content: VodContent;
}

export interface ProfileImageListContent {
  groupCode: string;
  groupDate: string;
  groupName: string;
  groupOrder: number;
  programCode?: string;
  imageList: ProfileImageInfo[];
  imageListCount: number;
}

export interface SearchProgramContent {
  actor: string;
  broad_dt: string;
  cate_nm: string;
  director: string;
  free_yn: string;
  grade_cd: string;
  is_4k: boolean;
  mast_cd: string;
  mast_nm: string;
  mast_nm_en: string;
  quickvod: boolean;
  score: number;
  targetage: string;
  tving_exclusive_yn: string;
  tving_original_yn: string;
  web_url: string;
  web_url4: string;
  sch_sr: string;
}

export interface SearchMovieContent {
  actor: string;
  amt: string;
  bill: string[];
  bill_my_catchon_yn: string;
  bill_tag: string;
  broad_dt: string;
  cate_nm: string;
  cine_same_yn: string;
  direct_ver_yn: string;
  director: string;
  dub_ver_yn: string;
  event_yn: string;
  extend_ver_yn: string;
  first_open_yn: string;
  gpa: number;
  grade_cd: string;
  grade_nm: string;
  gubun: string;
  is_4k: boolean;
  key_value: string;
  mast_cd: string;
  mast_kwd: string;
  mast_nm: string;
  mast_nm_en: string;
  net_cd: string;
  os_cd: string;
  pack_yn: string;
  payfree_yn: string;
  pgm_cd: string;
  runtime_mi: string;
  runtime_sec: string;
  score: number;
  scr_cd: string;
  special_ver_yn: string;
  subtitle_ver_yn: string;
  tot_view_cnt: string;
  traffic: number;
  tving_exclusive_yn: string;
  tving_original_yn: string;
  unedit_ver_yn: string;
  view_cnt_yest: string;
  web_url: string;
  web_url2: string;
  web_url5: string;
  week_view_cnt: string;
  free_yn: string;
  sch_sr: string;
}

export interface KidsContent {
  contents_count: number;
  fan_yn: string;
  kids_id: string;
  kids_title: string;
  kids_type_cd: string;
  logo_img: string;
  logo_img2: string;
  logo_img2_bg_rgb: string;
  logo_img3: string;
  logo_img_bg_rgb: string;
}

export interface RecommendMovieResult extends RecommendResult {
  fan_yn: string;
  grade_code: string;
  movie: MovieContent;
  stream: ResourceInfo[];
}

export interface RecommendProgramResult extends RecommendResult {
  episode: EpisodeContent;
  free_last_frequency_yn: string;
  last_frequency_yn: string;
  program: ProgramContent;
  service_end_date: number;
  service_open_date: number;
}

export interface RecommendResult {
  kids: null | KidsContent;
  billing_package_id: string[];
  billing_package_id_type: {
    charge_fixed_ids: string;
    charge_fixed_yn: string;
  };
}
export interface RecommendProgramAssociateResult extends RecommendAssociateResult {
  broadcast_datetime: number;
  synopsis: string;
  broad_week: string;
  broad_hour: string;
  broad_minu: string;
  quickup_yn: string;
}

export interface RecommendMovieAssociateResult extends RecommendAssociateResult {
  story: string;
  subtitle_ver_yn: string;
  audio_type: null;
  hdr_type: null;
  dub_ver_yn: string;
  duration: number;
  release_date: number;
  billing_package_tag: string;
  event_yn: string;
  cine_same_yn: string;
  first_open_yn: string;
}

export interface RecommendAssociateResult {
  name: string;
  code: string;
  tving_original_yn: string;
  tving_exclusive_yn: string;
  image: string;
  adult_yn: string;
  free_yn: string;
  grade_code: string;
  uhd_4k_yn: string;
  category_name: string;
  drm_yn: string;
  apple_yn?: string;
}

export interface KidsResult {
  channel?: ChannelContent;
  episode?: EpisodeContent;
  grade_code: string;
  kids: KidsContent;
  program?: ProgramContent;
  movie?: MovieContent;
  vod_code: string;
  vod_name: I18nName;
  fan_yn?: string;
}

export interface LiveContent {
  content_type: ContentType;
  live_code: string;
  live_rating: {
    realtime_chtp: number;
  };
  schedule: {
    schedule_code: string;
    broadcast_start_time: number;
    broadcast_end_time: number;
    channel: ChannelContent | null;
    program: ProgramContent | null;
    episode: EpisodeContent | null;
    movie: MovieContent | null;
    broadcast_url: NotUsingNull;
    free_yn: string;
    guest_watch_yn: string;
  };
}

export interface HighlightResult {
  content_code: NullableString; // null;
  banner_type: NullableString; // 'AMTP1100';
  content_type: ContentType; // 'banner';
  content: BannerContent | VodContent | LiveContent;
  display_position: number; // 1;
  mapping_contents_name: NullableString; // null;
  mapping_image_type: NullableString; // null;
  mapping_image_url: NullableString; // null;
  tag_mapping_image_type: NullableString; // null;
  tag_mapping_image_url: NullableString; // null;
  add_clip: NullableString; // null;
  shopping_info: NullableString; // null;
  position_title: string; // '바로보기';
  display_start_date: string; // '20200701000000';
  display_end_date: string; // '20221231000000';
}

export interface MyLastsResult {
  content: VodContent;
  contentName: NullableString;
  content_code: NullableString;
  content_type: ContentType;
  fan_yn: string;
  favorite_yn: string;
  lastPlayTime: string;
  master_seq: NullableString;
  viewDate: string;
}

export interface PurchaseResult {
  LASTPLAYTIME: string;
  ACCESSCNT: number;
  CHARGEFLAG: number;
  CHARGENO: number;
  CMSITEMID: string;
  CONSUMEDATE: string;
  CONTENT_RESULT: VodContent;
  DISPPRODNAME: string;
  DOWNLOAD_INFO: DownloadInfo[];
  DOWNLOAD_YN: string;
  DOWNSERVYN: string;
  DRM_YN: string;
  DURATION: number;
  DURATIONM: string;
  DURATIONTYPE: number;
  DURATIONTYPEM: string;
  EXPDATE: string;
  ISSPEED: number;
  ITEMATTR: number;
  ITEMID: number;
  ITEMTYPE: number;
  MAXACCESSCNT: number;
  MCHARGEAVAIL: number;
  M_CMSITEMID: string;
  ORGCMSITEMID: string;
  ORGEXPDATE: string;
  PACKAGEATTR: number;
  PACKAGEID: number;
  PACKAGETYPE: number;
  PGMNAME: string;
  PMTYPE: string;
  PRICE: number;
  PRODGUBUN: string;
  PRODID: number;
  PRODNAME: string;
  PRODTYPE: number;
  PROVIDEFLAG: number;
  P_CMSITEMID: string;
  RECURPAYCHANGEABLEYN: string;
  RECURUSESTATE: number;
  REGDATE: string;
  REMAINDAY: number;
  REMAINTIME: number;
  RNUM: number;
  SERVICETYPE: number;
  STATUS: number;
  TOTCNT: number;
  USERNO: number;
  VODDOWNCNT: number;
  VODDOWNMAXACNT: number;
  VODDOWNREMAINTIME: number;
  VODTYPE: number;
}

export interface MyValidTicketListResult {
  BANNER:
    | NotUsingNull
    | {
        TEXT: string;
        PCURL: string;
        MOBILEURL: string;
        TYPE: MyValidTicketBannerType;
        URL: string;
        PRODID: number;
        LINKNAME: string;
      };
  PASSGROUPINFO: PassGroupInfo;
  VALIDTICKETLIST: MyValidTicketInfo[];
  ISFREEYN: string;
  RESERVE_STATUS: TicketReserveStatus;
}

export interface RecurProdResult {
  PACKAGEID: number;
  CEXPDATE: string;
  CHARGEAMT: number;
  CONSUMEDATE: string;
  DISPPRODNAME: string;
  EVENTPRODIDS: string;
  IDFLAG: number;
  ISAOS: string;
  ISATV: string;
  ISCURR: string;
  ISIOS: string;
  ISMIGRATION: string;
  ISNAVERUPYN: string;
  ISNAVERYN: string;
  OPEN_TYPE: string;
  OPEN_URL: string;
  PAYTOOLNAME: string;
  PRICE: number;
  PRODID: number;
  PRODNAME: string;
  PRODTYPE: number;
  PRODUSESTATE: number;
  PURCHASECODE: string;
  REGDATE: string;
  SITECODE: number;
  STATUS: number;
  TOTCNT: number;
  USERID: string;
  USERNO: number;
  YMD: string;

  PAYCNT: number;
  CHANGEFLAG: number;
  CNLDATE: string;
  NYMD: string;
  USESTATE: number;
  CHANGEYMD: string;
  RECURNO: number;
  DURATIONTYPE: number;
  STATUSTEXT: string;
  PROFILENO: number;
  PROFILENM: string;
  REQPRODNAME: string;
  RECURSTATUS: string;
  REQYMD: string;
  RNUM: number;
  DURATION: number;
}

export enum RECUR_PROD_RESERVE_STATUS_CODE {
  NONE = 'NONE',
  RESERVE = 'RESERVE',
}
export interface RecurProdReserve {
  STATUS_CODE: RECUR_PROD_RESERVE_STATUS_CODE;
  STATUS_MESSAGE?: string;
  PRODUCT_NAME?: string;
  RESERVE_YMD?: string; // YYYY.MM.DD
  RESERVE_PRICE?: number;
  RESERVE_PAY_TOOL?: string;
  PURCHASE_YME?: string; // YYYY.MM.DD
}

export interface MaintenancePopupResult {
  popupSeq: number;
  popup_seq: number;
  title: string;
  display_category_code: string;
  popup_type: string;
  start_date: string;
  end_date: string;
  link_url: NullableString;
  link_move_type: string;
  content: NullableString;
  image_type: string;
  image: string;
  stop_view_period: number;
  size_width: number;
  size_height: number;
  position_top: number;
  position_left: number;
  poc_code: string;
}

export interface TtvResult {
  check_date: string;
  noti_msg: string;
  smarttv_check_date: string;
  smarttv_noti_msg: string;
  smarttv_title: string;
  title: string;
}

export interface ArticleResult {
  category_code: string;
  content: string;
  del_yn: string;
  mod_date: string;
  mod_user_id: string;
  poc_code: string;
  read_cnt: string;
  reg_date: string;
  reg_user_id: string;
  title: string;
  top_yn: string;
  use_yn: string;
}

export interface NoticeResult extends ArticleResult {
  notice_seq: number;
}

export interface FaqResult extends ArticleResult {
  faq_seq: number;
}

export interface SearchRankResult {
  growth_rate: string;
  hit_count: string;
  inst_dt: string;
  kwd: string;
  new_yn: string;
  ranking: string;
  score: number;
}

export interface RecommendMetaInfo {
  NO: string;
  O_PID: string;
  TOTAL_NO: string;
  S2?: string;
  S3?: string;
}

export interface RecommendMetaContentInfo extends RecommendMetaInfo {
  content_type: ContentType;
  meta: VodContent;
}

export interface MarketProduct {
  // 응답은 배열로 전달됨 ex) response = ProductListForMarketBody[];
  CJONERATE: number;
  DCRATE: number;
  DURATION: number;
  DURATIONM: string;
  DURATIONTYPE: number;
  DURATIONTYPEM: string;
  ISNOWUSEYN: string;
  ISPREMIUM: string;
  PACKAGEATTR: number;
  PACKAGETYPE: number;
  PASSGROUPCD: string;
  PRICE: number;
  PRODDESC: string;
  PRODID: number;
  PRODNAME: string;
  PRODPRICE: number;
  PRODTYPE: number;
  RECURAVAIL: number;
  RECURDCRATE: string;
  RECURFLAG: number;
  RECURPRICE: number;
  SHOWRATE: number;
  SORTNO: number;
  TITLE: string;
  WATCHMAXCNT: number;
}

export interface RegisteredDevice {
  count: number;
  delete_month: NullableString;
  limited_count: number;
  model: string;
  name: string;
  regdate: string;
  regist: boolean;
  self: boolean;
  seq: string;
  userno: string;
  uuid: string;
}

export interface PopupsResult {
  popupSeq: number;
  popup_seq: number;
  title: string;
  display_category_code: string;
  popup_type: string;
  start_date: string;
  end_date: string;
  link_url: NullableString;
  link_move_type: string;
  content: NullableString;
  image_type: string;
  image: string;
  stop_view_period: number;
  size_width: number;
  size_height: number;
  position_top: number;
  position_left: number;
  poc_code: string;
}

export interface ExposureBody {
  result: {
    CLIP?: Expose[];
    HISTORY: Expose[];
    KIDS: Expose[];
    LIVE?: Expose[];
    MAIN: Expose[];
    MALL: Expose[];
    MOVIE: Expose[];
    MUSIC?: Expose[];
    STYLE?: Expose[];
    VOD: Expose[];
    PARAMOUNT: Expose[];
    total_count: number;
  };
}

export interface HighlightBody {
  info: {
    title: string;
  };
  result: HighlightResult[];
}

export interface StreamInfoBody {
  server: SimpleServer;
  user: SimpleUser;
  result: StreamInfoResult;
  content: StreamInfoContent;
  bill: BillInfo;
  ad: NotUsingNull;
  stream: StreamDetail;
  adProxy: NotUsingNull;
}

export interface ContentInfoBody {
  server: SimpleServer;
  user: SimpleUser;
  result: StreamInfoResult;
  content: ContentInfoContent;
  bill: BillInfo;
}

export interface VodsBody {
  has_more: string;
  result: VodContent[];
  total_count: number;
}

export interface EpisodesBody {
  result: VodContent[] | EpisodesNearContent;
  prev_has_more?: string;
  next_has_more?: string;
  has_more?: string;
  total_count: number;
}

export interface KidsBody {
  has_more: string;
  result: KidsResult[];
  total_count: number;
}

export interface KidsCategoriesBody {
  count: number;
  has_more: string;
  result: KidsContent[];
}

export interface KidsCategoryBody {
  result: {
    contents_list: KidsResult[];
    has_more: string;
    kids_title: string;
  };
}

export interface KidsMoviesBody {
  count: number;
  has_more: string;
  result: KidsResult[];
}

export interface MyLastsBody {
  result: MyLastsResult[];
  total_count: number;
}

export interface PurchaseBody {
  has_more: string;
  result: PurchaseResult[];
  total_count: number;
}

export interface FanContentsBody {
  result: FanContentResult[];
  has_more: string;
  total_count: number;
}

export interface MaintenancePopupBody {
  result: MaintenancePopupResult[] | null;
  total_count: number;
}

export interface UserInfoBody {
  adultCertifiedDate: string;
  age: number;
  aouthId: string;
  aouthType: string;
  blackListYn: string;
  bonusCash: number;
  cash: number;
  certType: string;
  cjOneJoinYn: string;
  cjOneMember: boolean;
  cjOneMemberYn: string;
  cjOneUserNo: string;
  deviceId: string;
  emailAddress: string;
  emailVendor: string;
  englishUserName: string;
  facebookAccessToken: string;
  firstLoginYn: string;
  foreignerYn: string;
  foreiner: boolean;
  gmCode: string;
  gmCodeUpdateDate: string;
  helloVisionAuthYn: string;
  hellovisionWatch: boolean;
  inMeYn: string;
  integrationYn: string;
  ipin: string;
  ipinYn: string;
  lastLoginDate: string;
  lastPasswordUpdateDate: string;
  legalAgrDy: string;
  legalAgrYn: string;
  localPasswordEncrypt: string;
  loginErrorCount: number;
  loginType: NotUsingNull;
  marketingAgreeYn: string;
  nickname: string;
  overAgeYn: string;
  passwordErrorCheckYn: string;
  passwordErrorCount: string;
  personalAgreeYn: string;
  profile: ProfileInfo;
  profileImage: string;
  profileList: ProfileInfo[];
  profileMaxCnt: number;
  profileTicketMaxCnt: number;
  realCash: number;
  realName: boolean;
  realNameConfirmYn: string;
  realNameYn: string;
  receiveEmailYn: string;
  receiveSmsYn: string;
  registDate: string;
  sex: string;
  svcAcntEqpConsNum: string;
  termAgreeYn: string;
  transTermDate: string;
  tvingGmcode: string;
  tvingToken: string;
  twitterAccessToken: string;
  twitterSecret: string;
  userAccountStatus: string;
  userGrade: string;
  userId: string;
  userName: string;
  userNo: NullableString;
  userStatus: string;
  userTicketYn: boolean;
  userType: string;
}
export interface AppLoginBody {
  custNo: number;
  custId: string;
  custName: string;
  custType: string;
  birthday: string;
  cjOneMembershipYn: string;
  cjOneUserNo: NullableString;
  email: string;
  registerDate: string;
  sex: NullableString;
  lastPasswordUpdateDate: string;
  ga360Token: string;
  tvingToken: string;
  profiles: NewTvingProfileInfo[];
  accessToken: string;
  refreshToken: string;
  packageYn: string;
  profileTicketMaxCount: number;
  realNameConfirmYn: string;
  authToken: string;
  specialStatus: NullableString;
  regionCode: NullableString;
}

export interface AppLogoutBody {
  accessToken: string;
}

export interface ProfileListBody {
  packageYn: string;
  profileCount: number;
  profileList: ProfileInfo[];
  profileMaxCnt: number;
  profileTicketMaxCnt: number;
  userNo: NullableString;
}

export interface ProfileImageListBody {
  data: {
    profileDefaultImage: string;
    programCount: number;
    programList: ProfileImageListContent[];
  };
  message: string;
  success: string;
}

export interface ActionBody {
  action?: {
    charge_flag: NullableString;
    content_id: NullableString;
    link: NullableString;
    message: string;
    product_id: NullableString;
    product_type: NullableString;
    success: string;
    title: NullableString;
    type: NullableString;
  } | null;
}

export interface OtpUrlAndOtpNumBody {
  otpNum: string;
  result: string;
}

export interface OtpResultUserBody {
  custNo: number;
  custId: string;
  custType: string;
  cjOneMembershipYn: string;
  email: string;
  lastPasswordUpdateDate: string;
  tvingToken: string;
  refreshToken: string;
  accessToken: string;
  realNameConfirmYn: string;
  authToken: string;
  regionCode: NullableString;
  reqType: string;
}

export interface PlayerOfferProductBody {
  CMSITEMID: string;
  DOWNAUTH: PlayerOfferDownAuth;
  DOWNSERVYN: string;
  OFFERTYPE: number;
  PACKAGE?: NotUsingNull;
  PAID: number;
  PRODID: string;
  PRODUCT: PlayerOfferProduct;
  PURCHASABLEPRODUCT: string;
  REMAINDAY: string;
  REMAINDOWNCNT: string;
  STREAMAUTH: PlayerOfferStreamAuth;
}

export interface PpvAvailableBody {
  result: {
    ERR_MSG: string;
    RESULT_CODE: string;
  };
}

export interface MyValidTicketListBody extends ActionBody {
  result: MyValidTicketListResult | null;
}

export interface RecurprodListBody {
  result: RecurProdResult[];
  total_count: number;
  reserve: RecurProdReserve;
}

export interface ProductListForAtvBody {
  appleYn: string;
  googleYn: string;
  naverYn: string;
  nowUseYn: string;
  packageId: number;
  prodId: number;
  prodName: NullableString;
  productList: AtvProduct[];
}

export interface PurchasedCheckBody {
  result: {
    ERR_MSG: string;
    PURCHASE_INFO: PurchasedCheckInfo;
    RESULT_CODE: string;
  };
}

export interface NoticesBody {
  result: NoticeResult[];
  total_count: number;
}

export interface NoticeBody {
  result: NoticeResult;
}

export interface FaqsBody {
  result: FaqResult[];
  total_count: number;
}

export interface FaqBody {
  result: FaqResult;
}

export interface ProgramRecommendVodBody {
  programRecommendVod: {
    available: string;
    firstEpisode?: EpisodeContent | null;
    last_episode?: EpisodeContent | null;
    last_viewdate: string | null;
    latest_episode?: EpisodeContent | null;
  };
}

export interface RecommendBody {
  recommend0: RecommendMetaInfo[];
  recommend1: RecommendMetaInfo[];
  recommend2: RecommendMetaInfo[];
  recommend3: RecommendMetaContentInfo[];
  'recommendation-uuid': string;
}

export interface RecommendMovieSimilarBody {
  result: RecommendMovieResult[];
  'recommendation-uuid': string;
}

export interface RecommendMovieAssociateBody {
  result: RecommendMovieAssociateResult[];
  'recommendation-uuid': string;
}

export interface RecommendProgramAssociateBody {
  result: RecommendProgramAssociateResult[];
  'recommendation-uuid': string;
}

export interface RecommendMovieLikeBody {
  recommend3: RecommendMetaContentInfo[];
  'recommendation-uuid': string;
}

export interface RecommendProgramLikeBody {
  recommend0: RecommendMetaInfo[];
  recommend1: RecommendMetaInfo[];
  recommend2: RecommendMetaInfo[];
  recommend3: RecommendMetaContentInfo[];
  'recommendation-uuid': string;
}

export interface RecommendCategoryBody {
  result: {
    category_code: string;
    category_name: string;
    vod_list: RecommendProgramResult[];
  };
  'recommendation-uuid': string;
}

export interface RecommendGenreBody {
  result: {
    category_code: string;
    genre_code: string;
    genre_name: string;
    has_more: NullableString;
    rmrk: string;
    vod_list: RecommendProgramResult[];
  };
  'recommendation-uuid': string;
}

export interface FanActionBody {
  result: number;
}

export interface LivesBody {
  has_more: string;
  result: LiveContent[];
  total_count: number;
}

export interface PopupsBody {
  total_count: number;
  result: PopupsResult[];
}

export interface OnboardInfo {
  section_code: string;
  section_type: string;
  motion: NotUsingNull;
  images: OnboardImage[];
}

export interface OnboardImage {
  horizontal: string;
  vertical: string;
  logo_horizontal: NotUsingNull;
  logo_vertical: NotUsingNull;
}

export interface SeasonProgramContent {
  code: string;
  name: string;
  season_etc_vod_yn: string;
  season_no: string;
}

export interface SeasonProgramBody {
  result: SeasonProgramContent[];
}

export interface SeasonMovieContent {
  actor: string[];
  adult_yn: string;
  audio_type: string;
  billing_package_tag: string;
  category_name: string;
  cine_same_yn: string;
  code: string;
  director: string[];
  drm_yn: string;
  dub_ver_yn: string;
  duration: number;
  first_open_yn: string;
  grade_code: GRADE_CODE;
  hdr_type: string;
  image: string;
  ko_cc_yn: string;
  name: string;
  paramount_yn: string;
  release_date: number;
  scp_code: string;
  season_etc_vod_yn: string;
  season_no: string;
  story: NullableString;
  subtitle_ver_yn: string;
  tving_exclusive_yn: string;
  tving_original_yn: string;
  uhd_4k_yn: string;
  free_yn?: string;
}

export interface SeasonMovieBody {
  result: SeasonMovieContent[];
}

export interface TtvStatusJsonResponse {
  check_type: string;
  emergency: TtvResult;
  login_check: string;
  noti_msg: string;
  regular: TtvResult;
  ttv_mode: string;
  ttv_url: string;
  upd_date: string;
  upd_id: string;
}

export interface VttStatusJsonResponse {
  data: string;
}

export interface BufferStatusJsonResponse {
  app: {
    mode: string;
    url: string;
  };
  smarttv: {
    mode: string;
    url: string;
  };
  pc: {
    mode: string;
    url: string;
  };
}

// 뉴티빙 TTV, 버퍼모드 API 응답
export interface TtvBufferModeResponse {
  ttv: {
    url: string;
    modeOn: boolean;
    checkType?: string;
    emergency?: {
      notiMsg?: string;
      checkDate?: string;
      title?: string;
    };
    regular?: {
      notiMsg?: string;
      checkDate?: string;
      title?: string;
    };
  };
  buffer: {
    url: string;
    modeOn: boolean;
    live?: {
      cover?: string;
      channel?: string;
      title?: string;
      url?: string;
      urlExpireDate?: string;
      channelCode?: string;
      buttonName?: string;
      buttonRgb?: string;
    };
  };
}

export interface VersionJsonResponse {
  version: string;
  lastUpdateDate: string;
  lastForcedUpdateVersion: string;
}
export interface SearchResponse {
  total: number;
  alonePublicRsb: {
    count: number;
  };
  dicSpcRsb: {
    count: number;
  };
  kreRsb: {
    count: number;
  };
  pickClipRsb: {
    count: number;
  };
  pipTvRsb: {
    count: number;
  };
  programRsb: {
    count: number;
    dataList: SearchProgramContent[];
    abtest_group: string;
    sch_collection: string;
    sch_model: string;
    sch_rec_model: string;
  };
  schAfterRsb: {
    count: number;
  };
  schRsb: {
    count: number;
    // 무슨 배열이 들어가는지 알 수 없음
    dataList: never[];
  };
  shortclipRsb: {
    count: number;
  };
  smrclipRsb: {
    count: number;
  };
  smrprogramRsb: {
    count: number;
  };
  srchSpcRsb: {
    count: number;
  };
  themeRsb: {
    count: number;
  };
  tvingmallRsb: {
    count: number;
  };
  vodBCRsb: {
    count: number;
  };
  vodMVRsb: {
    count: number;
    dataList: SearchMovieContent[];
    abtest_group: string;
    sch_collection: string;
    sch_model: string;
    sch_rec_model: string;
  };
}

export interface SearchRankResponse {
  rpkRsb: {
    count: number;
    dataList: SearchRankResult[];
  };
}

export interface SearchAutoResultContent {
  amt: string;
  broad_dt: string;
  broad_end_dt: string;
  cate_cd: string;
  cate_nm: string;
  ch_cd: string;
  ch_nm: string;
  epi_cd: string;
  epi_nm: string;
  frequency: string;
  gpa: number;
  gubun: string;
  key_value: string;
  kwauto: string;
  mast_cd: string;
  mast_nm: string;
  mast_nm_en: string;
  pack_yn: string;
  payfree_yn: string;
  runtime_mi: string;
  score: number;
  web_url: string;
  web_url3: string;
  web_url4: string;
  free_yn: string;
  tving_original_yn: string;
  tving_exclusive_yn: string;
  targetage: string;
  grade_cd: string;
  sch_sr: string;
}

export interface SearchAutoResultResponse {
  akcRsb: {
    count: number;
    dataList: SearchAutoResultContent[];
    sch_model?: string; // API response 중 sch_model 값
    sch_rec_model?: string; // API response 중 sch_rec_model 값
    sch_collection?: string; // API response 중 sch_collection 값
    abtest_group?: string; // 검색 : API response 중 abtest_group 값, 추천 : API response 중 abtestGroup 값
  };
}

export interface OriginalsContent {
  vod_name: string;
  vod_code: string;
  tving_original_yn: string;
  tving_exclusive_yn: string;
  image: string;
  adult_yn: string;
  grade_code: string;
  broadcast_datetime?: number;
  actor: string[];
  category_name: string;
  director: string[];
  ko_cc_yn: string;
  hdr_type?: string;
  free_yn?: string;
  audio_type?: string;
  billing_package_tag?: string;
  cine_same_yn?: string;
  drm_yn?: string;
  dub_ver_yn?: string;
  event_yn?: string;
  first_open_yn?: string;
  paramount_yn: string;
  uhd_4k_yn: string;
  synopsis: string;
  image_title_url: string;
  poster_url: string;
  thumbnail_url: string;
  broad_hour?: string;
  broad_minu?: string;
  broad_week?: string;
  channel_name?: string;
  subtitle_ver_yn?: string;
  quickup_yn?: string;
  release_date?: number;
}

export interface OriginalsBody {
  fix_contents?: OriginalsContent[];
  contents: OriginalsContent[];
  api_uri: string;
  title: string;
  type: string;
  has_more: string;
}

export interface ProvisioningImgUrl {
  no: number;
  url: string;
}
export interface ProvisioningLogoImg {
  imgUrl: string;
  imgOnUrl: ProvisioningImgUrl[];
  imgOffUrl: ProvisioningImgUrl[];
}
export interface ProvisioningMenu {
  type: string;
  title: string;
  uri: string;
  imgUrl: string;
  imgOnUrl: string;
  imgOffUrl: string;
}

// 프로비저닝 API 응답 (국가코드 있는 경우의 프로비저닝 API 응답과 동일)
export interface ProvisioningResponse {
  region: string;
  lang: string;
  baseUrl: string;
  logo: {
    seasonYn: boolean;
    seasonStart: number;
    seasonEnd: number;
    basic: ProvisioningLogoImg;
    season: ProvisioningLogoImg;
  };
  provisioning: {
    rightTopMenu?: ProvisioningMenu[];
    topMenu: ProvisioningMenu[];
    middleMenu?: ProvisioningMenu[];
    bottomMenu?: ProvisioningMenu[];
  };
}

// Refresh Token으로 발급한 Access Token API
export interface AccessTokenResponse {
  accessToken: string;
}

// Access Token 유효성 체크를 위한 API 응답
export interface CheckAccessTokenResponse {
  custNo?: number;
  profileNo?: number;
  refreshToken?: string;
  accessToken?: string;
}

export interface BffGenreImageContent {
  imageUrl: string;
}

// # 가격인상 관련 타입들
type _Y = 'Y';
type _N = 'N';
type _YN = _Y | _N;

export interface MembershipPlansResponse {
  code: string;
  message: string;
  data: MembershipPlansData;
}

export interface WavvePeriodProduct {
  type: DURATION_UNIT;
  passGroupCode: string;
  packageAttribute: number;
  durationType: string;
  tvingProductId: number;
  tvingProductName: string;
  partnerProductId: string;
  partnerProductName: string;
  displayProductPrice: string;
  productPrice: number;
  recurDcRate: number;
  displayRecurPrice: string;
  recurPrice: number;
  monthlyRecurPrice: number;
  productDescList: string[];
}

export interface MembershipPlansData {
  list: MembershipPlanItem[];
  dataTitleList: DataTitleList[];
  /**
   * @see https://tving.atlassian.net/jira/software/c/projects/TVING/boards/182?assignee=712020%3Abf45239b-5bc0-4465-931e-37e2063d94d5&selectedIssue=TVING-11358
   * @see https://tving.atlassian.net/wiki/spaces/COW/pages/20790131/API
   */
  wavveBundleList?: TvingWavveMembershipPlanItem[];
}

export interface DataTitleList {
  key: string;
  title: string;
  displayType: 'text' | 'icon';
}

export interface MembershipPlanItem {
  passGroupCode: string;
  quality: string;
  packageAttribute: number;
  watchMaxCount: string;
  profileMaxCount: string;
  downloadCount: string;
  supportDevice: _YN;
  productName: string;
  monthlyPrice: string;
  mainProductId: number;
  extProductId: string;
  periodProductList: PeriodProductList[];
  firstDisplayYn: _YN;
  advertisement: _YN;
}

export interface TvingWavveMembershipPlanItem {
  packageAttribute: number; // 2971978;
  partnerId: string; // "WAVVE";
  passGroupCode: string; // "PG1007";
  productName: string; //
  periodProductList: [
    {
      displayProductPrice: string; // "5,500원";
      displayRecurPrice: string; // "5,500원";
      durationType: string; // "3";
      monthlyRecurPrice: number; // 5500;
      packageAttribute: number; // 2971978;
      partnerProductId: string; // "W-AVOD001";
      partnerProductName: string; // "웨이브 Basic";
      passGroupCode: string; // "PG1007";
      productPrice: number; // 5500;
      recurDcRate: number; // 0;
      recurPrice: number; // 5500;
      tvingProductId: number; // 6967174;
      tvingProductName: string; // "티빙 광고형 스탠다드";
      type: string; // "MONTH";
      productDescList: string[]; // ["동시 시청 : 티빙 2대 | 웨이브 1대", "화질 : 티빙 1080p | 웨이브 HD화질", "모바일+태블릿+PC : 모두 지원", "TV : 티빙 지원 | 웨이브 미 지원",…];
    },
  ];
}

export interface PeriodProductList {
  type: DURATION_UNIT;
  productId: number;
  tvingProductId?: number;
  price: number;
  productPrice?: number;
  recurPrice: number;
  recurDcRate: number;
  durationType: string;
  monthlyRecurPrice: number;
}

export type TemplateShowType = 'type1' | 'type2';

export interface TemplateMatchData {
  productId: number;
  productName: string;
  baseDateFull: string;
  baseDateYear: string;
  baseDateMonth: string;
  baseDateDay: string;
  agreementStartDateFull: string;
  agreementStartDateYear: string;
  agreementStartDateMonth: string;
  agreementStartDateDay: string;
  agreementEndDateFull: string;
  agreementEndDateYear: string;
  agreementEndDateMonth: string;
  agreementEndDateDay: string;
  priceKeepDateFull: string;
  priceKeepDateYear: string;
  priceKeepDateMonth: string;
  priceKeepDateDay: string;
  existingPaymentDateFull: string;
  existingPaymentDateYear: string;
  existingPaymentDateMonth: string;
  existingPaymentDateDay: string;
  priceUpPaymentDateFull: string;
  priceUpPaymentDateYear: string;
  priceUpPaymentDateMonth: string;
  priceUpPaymentDateDay: string;
  remainDays: number;
  nowPrice: number;
  newPrice: number;
  tvQrImagePath: string;
}

export interface TemplateType {
  type: string;
  list: {
    template: string;
    path: string;
    noticeSeqId?: string;
    appNoticeDetailUrl?: string;
    text: string[];
  }[];
}

export interface TemplateInfoData {
  agreeRound: number;
  showType: TemplateShowType;
  showDuration: number;
  matchData: TemplateMatchData;
  type1: TemplateType;
  type2: TemplateType;
}

export type TemplateInfoResponse = NewTvingResponse<TemplateInfoData>;

export interface RecurpayInfoData {
  productId: number;
  productName: string;
  baseDate: string;
  agreementStartDate: string;
  agreementEndDate: string;
  priceKeepDate: string;
  existingPaymentDate: string;
  priceUpPaymentDate: string;
  remainedDays: number;
  nowPrice: number;
  newPrice: number;
}

export interface AgreementsInfoData {
  custNo: number;
  targetType: DURATION_UNIT;
  agreeDate: string;
  agreeRound: number;
  profileName: string;
  show: boolean;
  appContentsImageList: string[];
  appLineupImage: string;
  pcContentsImage: string;
  pcLineupImage: string;
  tvContentsImage: string;
}

export interface IncreasePriceAgreementsCheckData {
  result: string;
  targetType: DURATION_UNIT;
  naverShowDuration: number;
  showDuration: number;
}

export interface DowngradeCheckData {
  productId: number;
  productName: string;
  price: number;
  priceUpPayDate: string;
}

export interface BootPopupDataImage {
  url: string;
  width: number;
  height: number;
}
export interface BootPopupData {
  code: string;
  linkUrl?: string;
  disabledDay: number;
  disabledText: string;
  isNewWindow?: boolean;
  images: BootPopupDataImage[];
}

export interface Terms {
  over14Yn: string;
  optCollectInfoYn: string;
  customAdSettingYn: string;
  receiveMarketingAgreeYn: string;
  receivePushYn: string;
  receiveEmailYn: string;
  receiveSmsYn: string;
  marketingAgreeYn: string;
  omniServiceAgreeYn: string;
  jedangAgreeYn: string;
  enmAgreeYn: string;
  oliveyoungAgreeYn: string;
  olivenetworksAgreeYn: string;
  cgvAgreeYn: string;
  foodvilleAgreeYn: string;
  freshwayAgreeYn: string;
  daehantongunAgreeYn: string;
}
export interface TermsAgreementData {
  custNo: number;
  terms: Terms;
}

export interface PolicyAgreementData {
  agreementCode: string;
  agreementSeq: number;
  contents: string;
  displayVersion: string; // YYYY-MM-DD
}

export type IncreasePriceAgreementsCheckResponse = NewTvingResponse<IncreasePriceAgreementsCheckData>;

export interface MyExpireTicketCheckData {
  result: {
    EXPIRETICKET: {
      STATUS: number; // 1
      NYMD: string; // 만료일자 - "20250311"
      RECURSTATUS: string; // "1"
      USESTATE: number; // 1
      RECURNO: number; // 해지 요청 시 전달하는 이용권 정보- 6094013
      RECURS_EXPIRE_TRY_YN: string; // 이용권 해지 취소 노출 여부 - "N"
      STATUSTEXT: string; // "구독중"
    } | null;
  };
}

export interface FindPasswordByIdData {
  email?: string;
}

export interface TvingIdLoginData {
  serverMessage?: string;
  url?: string;
  type?: string;
  title?: string;
  custNo?: string;
  custId?: string;
  custName?: string;
  custType?: string;
  birthday?: string;
  cjOneMembershipYn?: string;
  cjOneUserNo?: string;
  email?: string;
  registerDate?: string;
  sex?: string;
  lastPasswordUpdateDate?: string;
  tvingToken?: string;
  refreshToken?: string;
  accessToken?: string;
  profiles?: NewTvingProfileInfo[];
  packageYn?: string;
  profileTicketMaxCount?: number;
  realNameConfirmYn?: string;
  authToken?: string;
  regionCode?: string;
  specialStatus?: string;
  token?: string;
  overAgeYn?: string;
  autoExpireTime?: string;
}

export interface ReserveSubscribeData {
  ticketName: string; // "스탠다드"
  payAmount: number; // 6750
  nextRecurrentDate: string; // "2024.12.27"
}

export interface ReserveDisplayData {
  mainPopup: {
    imageUrl: string; // "https://image.tving.com/..." 또는 ""
    landingUrl: string; // "https://billdev.tving.com/..." 또는 ""
  };
  productBanner: {
    imageUrl: string; // "https://image.tving.com/..." 또는 ""
    landingUrl: string; // "https://billdev.tving.com/..." 또는 ""
  };
}

// 기본 응답 스키마 생성기
const createTvingResponseSchema = <T extends z.ZodTypeAny>(schema?: T) => {
  return z.object({
    code: z.string(),
    message: z.string(),
    // set data when schema is exists
    data: schema || z.undefined().optional(),
    detailMessage: z.string().optional(),
  });
};

export enum PriceIncreaseAgreementPopupType {
  NONE = 'none',
  CONFIRM = 'confirm',
  EXPIRY = 'expiry',
}

// 현재 가격 정보 스키마
const PriceIncreaseAgreementPopupCurrentPriceSchema = z.object({
  promotion: z.string(),
  currentPrice: z.number(),
});

// 다음 가격 정보 스키마 (confirm 케이스)
const PriceIncreaseAgreementPopupConfirmNextPriceSchema = z.object({
  nextPrice: z.number(),
  payDate: z.string().regex(/^\d{8}$/),
  payMethod: z.string(),
});

// 다음 가격 정보 스키마 (expiry 케이스)
const PriceIncreaseAgreementPopupExpiryNextSchema = z.object({
  payDate: z.string().regex(/^\d{8}$/),
});

const PriceIncreaseAgreementPopupNoneSchema = z.object({
  popupType: z.literal(PriceIncreaseAgreementPopupType.NONE),
});

export const PriceIncreaseAgreementPopupConfirmSchema = z.object({
  popupType: z.literal(PriceIncreaseAgreementPopupType.CONFIRM),
  agreementInfo: z.object({
    productName: z.string().optional(),
    productId: z.number().optional(),
    durationType: z.string().optional(),
    current: PriceIncreaseAgreementPopupCurrentPriceSchema,
    next: PriceIncreaseAgreementPopupConfirmNextPriceSchema,
  }),
  popupStopMessage: z.string(),
});

export const PriceIncreaseAgreementPopupExpirySchema = z.object({
  popupType: z.literal(PriceIncreaseAgreementPopupType.EXPIRY),
  agreementInfo: z.object({
    next: PriceIncreaseAgreementPopupExpiryNextSchema,
  }),
});

const PriceIncreaseAgreementPopupDataSchema = z.discriminatedUnion('popupType', [
  PriceIncreaseAgreementPopupNoneSchema,
  PriceIncreaseAgreementPopupConfirmSchema,
  PriceIncreaseAgreementPopupExpirySchema,
]);

// API 응답 스키마
export const PriceIncreaseAgreementPopupResponseSchema = createTvingResponseSchema(PriceIncreaseAgreementPopupDataSchema);

// 타입 추론
export type PriceIncreaseAgreementPopupResponse = NewTvingResponse<z.infer<typeof PriceIncreaseAgreementPopupDataSchema>>;

/// /

// 미동의 상태의 agreement 정보 스키마
export const PriceIncreaseAgreementPendingInfoSchema = z.object({
  agree: z.literal(false),
  productName: z.string(),
  productId: z.number(),
  currentPrice: z.number(),
  nextPrice: z.number(),
  payDate: z.string(),
  payMethod: z.string(),
});

// 동의 상태의 agreement 정보 스키마
export const PriceIncreaseAgreementAgreeInfoSchema = z.object({
  agree: z.literal(true), // YN 타입
  agreeDate: z.string(),
  profileName: z.string(),
  nextPrice: z.number(),
  payDate: z.string(),
});

// API 데이터 스키마
const PriceIncreaseAgreementStatusDataSchema = z.discriminatedUnion('agreeTarget', [
  // 대상이 아닌 경우
  z.object({
    agreeTarget: z.literal(false),
  }),
  // 대상인 경우 (미동의 또는 동의)
  z.object({
    agreeTarget: z.literal(true),
    agreementInfo: z.discriminatedUnion('agree', [PriceIncreaseAgreementPendingInfoSchema, PriceIncreaseAgreementAgreeInfoSchema]),
  }),
]);

// API 응답 스키마
export const PriceIncreaseAgreementStatusResponseSchema = createTvingResponseSchema(PriceIncreaseAgreementStatusDataSchema);

// 타입 추론
export type PriceIncreaseAgreementStatusResponse = NewTvingResponse<z.infer<typeof PriceIncreaseAgreementStatusDataSchema>>;

// 가격 인상 동의 API 데이터 스키마
const PriceIncreaseAgreementActionDataSchema = z
  .object({
    resultMessage: z.string().optional(),
  })
  .optional();

// 가격 인상 동의 API 응답 스키마
export const PriceIncreaseAgreementActionResponseSchema = createTvingResponseSchema(PriceIncreaseAgreementActionDataSchema);

// 타입 추론
export type PriceIncreaseAgreementActionResponse = NewTvingResponse<z.infer<typeof PriceIncreaseAgreementActionDataSchema>>;

export const PriceIncreaseAgreementPopupStopSchema = createTvingResponseSchema();

export type PriceIncreaseAgreementPopupStopResponse = NewTvingResponse<void>;

export enum POC_TYPE {
  PC_WEB = 'PC_WEB',
  MOBILE_WEB = 'MOBILE_WEB',
  BUMPER = 'BUMPER',
  OLD_ANDROID_TV = 'OLD_ANDROID_TV',
  SKYBOX = 'SKYBOX',
  CHROMECAST = 'CHROMECAST',
  DELIVE = 'DELIVE',
  HONEY_SCREEN = 'HONEY_SCREEN',
  CASH_SLIDE = 'CASH_SLIDE',
  MOBILE_PHONE = 'MOBILE_PHONE',
  TVING_STICK = 'TVING_STICK',
  TVING_STICK_OTT = 'TVING_STICK_OTT',
  TVING_STICK_RCU = 'TVING_STICK_RCU',
  SMART_TV = 'SMART_TV',
  TABLET = 'TABLET',
  VIEWING = 'VIEWING',
  LG_SMART_TV = 'LG_SMART_TV',
  ANDROID_TV = 'ANDROID_TV',
  SAMSUNG_SMART_TV = 'SAMSUNG_SMART_TV',
  APPLE_TV = 'APPLE_TV',
}

// my > 라이브 예약 알림
export interface LiveSchedule {
  ad?: NotUsingNull;
  broadcast_date: number;
  broadcast_end_time: number;
  broadcast_start_time: number;
  broadcast_url?: {
    block_yn: string;
    broad_url1: string;
    broad_url2: string;
    broad_url3: string;
    broad_url4: string;
    broad_url5: string;
    network_code: string;
    os_code: string;
    screen_code: string;
    tele_code: string;
  }[];
  channel?: ChannelContent;
  clip?: NotUsingNull;
  episode?: EpisodeContent;
  free_yn: string;
  guest_watch_yn: string;
  id: string;
  insert_date: number;
  isfullvod?: string;
  kbo_schedule_info?: NotUsingNull;
  mezzokey?: string;
  movie?: MovieContent;
  multiview_url?: string;
  pip_media_url?: string;
  preview_url?: string;
  program?: ProgramContent;
  rerun_yn?: string;
  schedule_code?: string;
  schedule_type?: string;
  stream_type?: string;
  support_info?: string[];
}
