/**
 * const vodSpecialButtonItemProps = {
 *     poster: {
 *       src: 'https://image.tving.com/upload/cms/caie/CAIE0400/E002852819.jpg/dims/resize/600',
 *       alt: posterAlt,
 *     },
 *   };
 *   <SpecialButtonItem {...vodSpecialButtonItemProps} />
 */
import Poster, { PosterProps } from '../../atom/poster/Poster';
import Text, { TextProps } from '../../atom/text/Text';

type specialButtonItemProps = {
  className?: string;
  poster: PosterProps;
  posterText: TextProps<React.ElementType>;
  tabIndex?: number;
};

const SpecialButtonItem = ({ className, poster, posterText, tabIndex }: specialButtonItemProps) => {
  return (
    <div className={`special-button-item-wrapper ${className}`} tabIndex={tabIndex}>
      {posterText && <Text {...posterText} />}
      <Poster {...poster} />
    </div>
  );
};

export default SpecialButtonItem;
