type Version = string | number;

export enum VersionCompareResult {
  /**
   * 미만(<에 해당합니다. <= 아님)
   */
  LessThan = -1,
  /**
   * 같음
   */
  EqualTo = 0,
  /**
   * 초과(>에 해당합니다. >= 아님)
   */
  GreaterThan = 1,
}

/**
 * 두 버전을 비교합니다. (current가 other보다 작으면 -1, 같으면 0, 크면 1을 반환합니다.)
 *
 * @see {@link https://github.com/bevry/version-compare/blob/master/source/index.ts Origin Sources :D}
 */
function compareVersion(current: Version, other: Version): VersionCompareResult {
  const currentVersionParts = String(current).split('.');
  const otherVersionParts = String(other).split('.');

  for (let depth = 0; depth < Math.min(currentVersionParts.length, otherVersionParts.length); depth += 1) {
    const currentVersionPart = Number(currentVersionParts[depth]);
    const otherVersionPart = Number(otherVersionParts[depth]);

    if (currentVersionPart > otherVersionPart) return VersionCompareResult.GreaterThan;
    if (otherVersionPart > currentVersionPart) return VersionCompareResult.LessThan;
    if (!Number.isNaN(currentVersionPart) && Number.isNaN(otherVersionPart)) return VersionCompareResult.GreaterThan;
    if (Number.isNaN(currentVersionPart) && !Number.isNaN(otherVersionPart)) return VersionCompareResult.LessThan;
  }

  return VersionCompareResult.EqualTo;
}

export default compareVersion;
