import { isMobile, isWebview } from '@utils/device/device-detect';

// 서버에서는 항상 false인 값입니다. 클라이언트에서는 브라우저의 User-Agent를 통해 값을 결정합니다.
const clientSideIsMobileOrWebview = isMobile || isWebview;

/**
 * @params isMobileOrWebview - 클라이언트에서는 전달하지 않아도 되며, 서버에서는 전달해야 합니다.
 */
const getKboApiDeviceParams = (isMobileOrWebview: boolean = clientSideIsMobileOrWebview) => {
    if (!isMobileOrWebview) {
        return undefined;
    }

    /**
     * 모바일/태블릿 형상에서 BFF의 응답 결과를 다르게 받아야 하는 경우가 있어 일괄로 전달합니다. (ex. `KBO_HOME_TEAM_RANKING`)
     *
     * @see {@link https://tvingdev.atlassian.net/browse/TVING-763}
     */
    return {
        screenCode: 'CSSD0200',
        osCode: 'CSOD0900',
    };
};

export default getKboApiDeviceParams;
