import { create } from 'zustand';
import { SportsMultiviewItem } from '@typings/sports/item';
import isBroadcasting from '@utils/sports/isBroadcasting';

export type MultiviewGame = SportsMultiviewItem & {
    initialIndex: number;
    sortIndex: number;
};

type MultiviewStore = {
    isMultiview: boolean;
    startMultiview: () => void;
    clearMultiview: () => void;

    lastCode: string | null;
    updateMultiviewGames: (code: string, sportsMultiviewItems: SportsMultiviewItem[]) => void;
    refreshMultiviewGames: () => void;

    selectedMultiviewPlayer: MultiviewGame | null;
    setSelectedMultiviewPlayer: (game: MultiviewGame | null) => void;
    selectNextMultiviewPlayer: () => void;

    multiviewPlayers: MultiviewGame[];
    multiviewMatches: MultiviewGame[];

    addItemToMultiviewPlayer: (game: MultiviewGame) => void;
    removeItemFromMultiviewPlayer: (game: MultiviewGame) => void;

    paused: boolean;
    play: () => void;
    pause: () => void;

    multiviewVolume: number;
    setMultiviewVolume: (volume: number) => void;

    multiViewTitle: string;
    setMultiViewTitle: (title: string) => void;
};

const multiviewStore = create<MultiviewStore>((set) => {
    const getBroadcastingGames = (games: MultiviewGame[]) => {
        return games.filter((game) =>
            isBroadcasting({
                broadcastStartTime: game.broadcastStartTime,
                broadcastEndTime: game.broadcastEndTime,
            }),
        );
    };

    const getClosestBroadcastingMultiviewPlayer = (selectedMultiviewPlayer: MultiviewGame, multiviewPlayers: MultiviewGame[]) => {
        const selectedMultiviewPlayerIndex = multiviewPlayers.findIndex((game) => game.code === selectedMultiviewPlayer.code);
        const multiviewPlayersLength = multiviewPlayers.length;

        const reorderedMultiviewPlayers: MultiviewGame[] = [];

        for (let i = selectedMultiviewPlayerIndex + 1; i < multiviewPlayersLength; i += 1) {
            reorderedMultiviewPlayers.push(multiviewPlayers[i]);
        }

        for (let i = selectedMultiviewPlayerIndex - 1; i >= 0; i -= 1) {
            reorderedMultiviewPlayers.push(multiviewPlayers[i]);
        }

        return (
            reorderedMultiviewPlayers.find((game) =>
                isBroadcasting({
                    broadcastStartTime: game.broadcastStartTime,
                    broadcastEndTime: game.broadcastEndTime,
                }),
            ) || null
        );
    };

    return {
        isMultiview: false,
        startMultiview: () => {
            set({ isMultiview: true });
        },
        clearMultiview: () => {
            set({
                isMultiview: false,
                lastCode: null,
                multiviewPlayers: [],
                multiviewMatches: [],
                selectedMultiviewPlayer: null,
            });
        },
        lastCode: null,
        updateMultiviewGames: (code: string, sportsMultiviewItems: SportsMultiviewItem[]) => {
            set((state) => {
                const sortedMultiviewGames: MultiviewGame[] = sportsMultiviewItems.map((item, index) => ({
                    ...item,
                    initialIndex: index,
                    sortIndex: index,
                }));

                // lastGameCode 와 다르면 신규 게임 진입했기 때문에 초기화 진행 필요
                if (state.lastCode !== code) {
                    const selectedGame = sortedMultiviewGames.find((game) => code === game.code) || sortedMultiviewGames[0];
                    return {
                        lastCode: code,
                        multiviewPlayers: sortedMultiviewGames.filter((game) => selectedGame.code === game.code),
                        multiviewMatches: getBroadcastingGames(sortedMultiviewGames.filter((game) => selectedGame.code !== game.code)),
                        selectedMultiviewPlayer: selectedGame,
                    };
                }

                const sortedMultiviewGamesObject = sortedMultiviewGames.reduce((acc, game) => {
                    acc[game.code] = game;
                    return acc;
                }, {} as Record<string, MultiviewGame>);
                const updatedMultiviewPlayers = state.multiviewPlayers.map((game) => {
                    return {
                        ...game,
                        ...sortedMultiviewGamesObject[game.code],
                    };
                });

                // add except multiviewPlayers
                const updatedMultiviewMatches = sortedMultiviewGames.filter((game) => {
                    return !updatedMultiviewPlayers.some((player) => player.code === game.code);
                });

                // lastGameCode 와 같으면 데이터 업데이트
                return {
                    multiviewPlayers: updatedMultiviewPlayers,
                    multiviewMatches: getBroadcastingGames(updatedMultiviewMatches),
                };
            });
        },
        refreshMultiviewGames: () => {
            set((state) => {
                const { multiviewMatches } = state;
                return {
                    multiviewMatches: getBroadcastingGames(multiviewMatches),
                };
            });
        },
        selectedMultiviewPlayer: null,
        setSelectedMultiviewPlayer: (game) => {
            set({ selectedMultiviewPlayer: game });
        },
        selectNextMultiviewPlayer: () => {
            set((state) => {
                const { selectedMultiviewPlayer, multiviewPlayers } = state;
                if (!selectedMultiviewPlayer) {
                    return {
                        selectedMultiviewPlayer:
                            multiviewPlayers.find((game) =>
                                isBroadcasting({
                                    broadcastStartTime: game.broadcastStartTime,
                                    broadcastEndTime: game.broadcastEndTime,
                                }),
                            ) || null,
                    };
                }

                const closestMultiviewPlayer = getClosestBroadcastingMultiviewPlayer(selectedMultiviewPlayer, multiviewPlayers);
                return {
                    selectedMultiviewPlayer: closestMultiviewPlayer,
                };
            });
        },
        multiviewPlayers: [],
        multiviewMatches: [],
        addItemToMultiviewPlayer: (game) => {
            set((state) => {
                return {
                    multiviewPlayers: [...state.multiviewPlayers, game],
                    multiviewMatches: getBroadcastingGames(state.multiviewMatches.filter((item) => item.code !== game.code)),
                };
            });
        },
        removeItemFromMultiviewPlayer: (game) => {
            set((state) => {
                const { selectedMultiviewPlayer } = state;
                const isSelected = selectedMultiviewPlayer && selectedMultiviewPlayer.code === game.code;
                let nextSelectedMultiviewPlayer = selectedMultiviewPlayer;
                if (isSelected) {
                    nextSelectedMultiviewPlayer = getClosestBroadcastingMultiviewPlayer(selectedMultiviewPlayer, state.multiviewPlayers);
                }

                const multiviewPlayers = state.multiviewPlayers.filter((multiviewPlayer) => multiviewPlayer.code !== game.code);
                const multiviewMatches = getBroadcastingGames(state.multiviewMatches.concat(game).sort((a, b) => a.initialIndex - b.initialIndex));

                return {
                    multiviewPlayers,
                    multiviewMatches,
                    selectedMultiviewPlayer: nextSelectedMultiviewPlayer,
                };
            });
        },
        paused: false,
        play: () => set({ paused: false }),
        pause: () => set({ paused: true }),

        multiviewVolume: (typeof window !== 'undefined' ? Number(localStorage.getItem('tving-player-volume-range')) : undefined) || 1,
        setMultiviewVolume: (volume) => set({ multiviewVolume: volume }),

        multiViewTitle: '멀티뷰 라이브',
        setMultiViewTitle: (title: string) => set({ multiViewTitle: title }),
    };
});

export default multiviewStore;
