import React, { useEffect, useState } from 'react';

type OrientationChangeHandler = () => void;

type UseDeviceOrientationProps = {
  onLandscape?: OrientationChangeHandler | undefined;
  onPortrait?: OrientationChangeHandler | undefined;
};

export const ORIENTATION = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
} as const;

const isMobile = () => /ipad|iphone|android|mobi/i.test(navigator.userAgent);

const getOrientation = () => {
  const angle = window.orientation;
  return angle === 90 || angle === -90 ? ORIENTATION.LANDSCAPE : ORIENTATION.PORTRAIT;
};

const useDeviceOrientation = ({ onLandscape, onPortrait }: UseDeviceOrientationProps = {}) => {
  const [orientation, setOrientation] = useState<typeof ORIENTATION[keyof typeof ORIENTATION]>('portrait');

  const handleOrientationChange = () => {
    const orientation = getOrientation();

    setOrientation(orientation);

    console.log('useDeviceOrientation in ChatManager', { orientation });

    if (orientation === ORIENTATION.PORTRAIT && onPortrait) {
      onPortrait();
    } else if (orientation === ORIENTATION.LANDSCAPE && onLandscape) {
      onLandscape();
    }
  };

  useEffect(() => {
    if (!isMobile()) {
      return;
    }

    window.addEventListener('orientationchange', handleOrientationChange);

    // 초기 값 설정
    handleOrientationChange();

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return {
    orientation,
    isPortrait: orientation === ORIENTATION.PORTRAIT,
    isLandscape: orientation === ORIENTATION.LANDSCAPE,
  };
};

export default useDeviceOrientation;
