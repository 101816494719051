import { ReactNode } from 'react';

import tempCnx from '../../utils/tailwind/tempCnx';

export interface KboCategoryItemProps {
  className?: string;
  isSelected?: boolean;
  children?: ReactNode;
}

const KboCategoryItem = ({ className, isSelected, children }: KboCategoryItemProps) => {
  return (
    <div
      className={tempCnx(
        'inline-flex justify-center items-center min-w-[4.667rem] h-[3rem] rounded-[83.333rem] cursor-pointer whitespace-nowrap',
        'transition transform ease-in-out hover-supported:hover:translate-y-[-0.75rem] duration-500',
        'border border-solid border-white/10 text-[1.166rem] lg:text-[1.25rem]',
        isSelected ? 'bg-white' : 'bg-gray-15',
        isSelected ? 'text-black font-bold' : 'text-gray-70 font-normal',
        className,
      )}
    >
      <span className="px-[1.333rem]">{children}</span>
    </div>
  );
};
export default KboCategoryItem;
