/* eslint-disable no-console,class-methods-use-this,no-underscore-dangle */
import CrossPlatformController from '@utils/crossPlatform/CrossPlatformController';
import {
    CrossPlatformAppConfigurationEvent,
    CrossPlatformChangeMediaOptions,
    CrossPlatformChangeOrientationOptions,
    CrossPlatformChangePlayerFeatureOptions,
    CrossPlatformChangePlayerStatusEvent,
    CrossPlatformClipPlayConfigurationChangeEvent,
    CrossPlatformConfirmDialogEvent,
    CrossPlatformEvent,
    CrossPlatformGetContentInfoEvent,
    CrossPlatformHapticFeedbackOptions,
    CrossPlatformMediaChangeEvent,
    CrossPlatformOpenTvingTalkOptions,
    CrossPlatformOpenUrlOptions,
    CrossPlatformSendBrazeCustomEventOptions,
    CrossPlatformPageInitializedOptions,
    CrossPlatformPushNotificationPermissionEvent,
    CrossPlatformRect,
    CrossPlatformRequestContentChangeEvent,
    CrossPlatformSafeAreaEvents,
    CrossPlatformServiceNotification,
    CrossPlatformShareLinkOptions,
    CrossPlatformShowDialogOptions,
    CrossPlatformShowNotificationOptions,
    CrossPlatformModeChangeEvent,
    CrossPlatformType,
    Orientation,
    CrossPlatformChangePlayerModeEvent,
    CrossPlatformShowBottomSheetEventOptions,
    CrossPlatformConfirmBottomSheetEvent,
    CrossPlatformModerateTvintTalkEvent,
    CrossPlatformModerateTvintTalkEventOptions,
} from '@utils/crossPlatform/types';
import { NextRouter } from 'next/router';
import Player from '@tvingcorp/web-player/types/player/Player';
import { PcLoggingType, PcPlayerInfo, PcPlayerPageInfo } from '@store/state';
import { KeyedMutator } from 'swr';
import Modal from '@components/Modal';
import { isServer } from '@utils/device';
import { KboMultiviewItem } from '@typings/kbo/item';
import { KboBand } from '@typings/kbo/band';
import { KboContentsResponse } from '@typings/kbo';
import { CrossPlatformContentInsetEvents } from '@utils/crossPlatform';

class WebCrossPlatformController implements CrossPlatformController {
    private _router: NextRouter | null = null;

    private _tvingPlayer: Player | null = null;

    private _setPlayerInfo: KeyedMutator<PcPlayerInfo> | null = null;

    private _setPlayerPageInfo: KeyedMutator<PcPlayerPageInfo> | null = null;

    private _setPlayerSubTitle: ((playerSubTitle: string) => void) | null = null;

    private _setPlayerSubDescription: ((playerSubDescription: string) => void) | null = null;

    private _setPlayerText: ((playerText: string) => void) | null = null;

    private _setPlayerCoverImageUrl: ((playerCoverImageUrl: string) => void) | null = null;

    private _setPlayerBackgroundColor: ((playerBackgroundColor: string) => void) | null = null;

    private _pcLogging: PcLoggingType | null = null;

    set router(value: NextRouter | null) {
        this._router = value;
    }

    set tvingPlayer(value: Player | null) {
        this._tvingPlayer = value;
    }

    set setPlayerInfo(value: KeyedMutator<PcPlayerInfo> | null) {
        this._setPlayerInfo = value;
    }

    set setPlayerPageInfo(value: KeyedMutator<PcPlayerPageInfo> | null) {
        this._setPlayerPageInfo = value;
    }

    set setPlayerSubTitle(value: (playerSubTitle: string) => void) {
        this._setPlayerSubTitle = value;
    }

    set setPlayerSubDescription(value: (playerSubDescription: string) => void) {
        this._setPlayerSubDescription = value;
    }

    set setPlayerText(value: (playerText: string) => void) {
        this._setPlayerText = value;
    }

    set setPlayerCoverImageUrl(value: (playerCoverImageUrl: string) => void) {
        this._setPlayerCoverImageUrl = value;
    }

    set setPlayerBackgroundColor(value: (playerBackgroundColor: string) => void) {
        this._setPlayerBackgroundColor = value;
    }

    set pcLogging(value: PcLoggingType | null) {
        this._pcLogging = value;
    }

    constructor() {
        if (isServer()) {
            return;
        }

        window.onRequestNextContent = this.onRequestNextContent.bind(this);
        window.onChangePlayerStatus = this.onChangePlayerStatus.bind(this);
        window.onRequestContentChange = this.onRequestContentChange.bind(this);
        window.onConfirmDialog = this.onConfirmDialog.bind(this);
        window.onChangePlayerFeature = this.onChangePlayerFeature.bind(this);
        window.onChangePlayerMode = this.onChangePlayerMode.bind(this);
        window.onConfirmBottomSheet = this.onConfirmBottomSheet.bind(this);
        window.onModerateTvingTalk = this.onModerateTvingTalk.bind(this);
    }

    getCrossPlatformType(): CrossPlatformType {
        return CrossPlatformType.WEB;
    }

    pageInitialized(crossPlatformPageInitializedOptions: CrossPlatformPageInitializedOptions): void {
        console.log('[WebCrossPlatformController] pageInitialized', crossPlatformPageInitializedOptions);

        this._setPlayerSubTitle?.(crossPlatformPageInitializedOptions.title);
        this._setPlayerSubDescription?.(crossPlatformPageInitializedOptions.subtitle);
    }

    reloadWebview(): void {
        console.log('[WebCrossPlatformController] reloadWebview');
    }

    getContentInfo(contentInfo: CrossPlatformGetContentInfoEvent): void {
        console.log('[WebCrossPlatformController] getContentInfo', contentInfo);
    }

    onMediaChange(event: CrossPlatformMediaChangeEvent): void {
        console.log('[WebCrossPlatformController] onMediaChanged', event);
    }

    onMediaStatusPlayer(event: CrossPlatformEvent): void {
        console.log('[WebCrossPlatformController] onMediaStatusPlayer', event);
    }

    onStatusUpdate(event: unknown): void {
        console.log('[WebCrossPlatformController] onStatusUpdate', event);
    }

    onStart(): void {
        console.log('[WebCrossPlatformController] onStart');
    }

    onStop(): void {
        console.log('[WebCrossPlatformController] onStop');
    }

    visiblePlayer(): void {
        console.log('[WebCrossPlatformController] visiblePlayer');
    }

    hiddenPlayer(): void {
        console.log('[WebCrossPlatformController] hiddenPlayer');
    }

    changePlayerMedia(changeMediaOptions: CrossPlatformChangeMediaOptions): void {
        console.log('[WebCrossPlatformController] changeMediaPlayer', changeMediaOptions);
        if (!this._setPlayerInfo) {
            console.error('[WebCrossPlatformController] changeMediaPlayer: this._setPlayerInfo is not defined');
            return;
        }
        const isSportsContents = window.location.pathname.includes('/contents/sports');
        const isKboContents = window.location.pathname.includes('/kbo/contents');

        void this._setPlayerInfo((prev) => ({
            ...prev,
            mediaCode: changeMediaOptions.code,
            pathname: isKboContents
                ? `/kbo/contents/${changeMediaOptions.gameCode ?? changeMediaOptions.code}`
                : isSportsContents
                ? `/contents/sports/${changeMediaOptions.gameCode ?? changeMediaOptions.code}`
                : `/contents/${changeMediaOptions.code}`,
        }));
    }

    stopPlayer(): void {
        console.log('[WebCrossPlatformController] stopPlayer');
        if (!this._setPlayerInfo || !this._setPlayerPageInfo) {
            console.error('[WebCrossPlatformController] changeMediaPlayer: this._setPlayerInfo is not defined');
            return;
        }

        this._tvingPlayer?.close();
        this._tvingPlayer?.stop();

        void this._setPlayerInfo((prev) => ({
            ...prev,
            pathname: '',
            mediaCode: '',
            isPreparing: true,
            isPlaying: false,
            playbackRate: 1,
            isPlayingTimeshift: false,
        }));

        void this._setPlayerPageInfo((prev) => ({
            ...prev,
            isPlayerPage: false,
            isPlaying: false,
            pathname: '',
        }));
    }

    playPlayer(): void {
        console.log('[WebCrossPlatformController] playPlayer');
        if (!this._tvingPlayer) {
            console.error('[WebCrossPlatformController] playPlayer: this._tvingPlayer is not defined');
            return;
        }
        void this._tvingPlayer?.play();
    }

    pausePlayer(): void {
        console.log('[WebCrossPlatformController] pausePlayer');
        if (!this._tvingPlayer) {
            console.error('[WebCrossPlatformController] pausePlayer: this._tvingPlayer is not defined');
            return;
        }
        this._tvingPlayer.pause();
    }

    showPlayerNotification(showNotificationOptions: CrossPlatformShowNotificationOptions): void {
        console.log('[WebCrossPlatformController] showPlayerNotification', showNotificationOptions);
        this._setPlayerText?.(showNotificationOptions.title);
        this._setPlayerSubTitle?.(showNotificationOptions.buttonTitle);
        this._setPlayerSubDescription?.(showNotificationOptions.buttonDesc);
        this._setPlayerCoverImageUrl?.(showNotificationOptions.backgroundImageUrl);
        this._setPlayerBackgroundColor?.(showNotificationOptions.backgroundColor);
    }

    hidePlayerNotification(): void {
        console.log('[WebCrossPlatformController] hidePlayerNotification');
        this._setPlayerText?.('');
    }

    showPlayerController(): void {
        console.log('[WebCrossPlatformController] showPlayerController');
    }

    hidePlayerController(): void {
        console.log('[WebCrossPlatformController] hidePlayerController');
    }

    changePlayerSize(playerSizeOptions: CrossPlatformRect): void {
        console.log('[WebCrossPlatformController] changePlayerSize', playerSizeOptions);
        if (!this._tvingPlayer) {
            console.error('[WebCrossPlatformController] changePlayerSize: this._tvingPlayer is not defined');
            return;
        }
        this._tvingPlayer.playerContainer.style.width = `${playerSizeOptions.width}px`;
        this._tvingPlayer.playerContainer.style.height = `${playerSizeOptions.height}px`;
    }

    onChangePlayerSize(playerSizeOptions: CrossPlatformRect): void {
        console.log('[WebCrossPlatformController] onChangePlayerSize', playerSizeOptions);
    }

    getPushNotificationPermission(): void {
        console.log('[WebCrossPlatformController] getPushNotificationPermission');
    }

    onPushNotificationPermission(event: CrossPlatformPushNotificationPermissionEvent): void {
        console.log('[WebCrossPlatformController] onPushNotificationPermission', event);
    }

    needsPushNotificationPermission(): void {
        console.log('[WebCrossPlatformController] needsPushNotificationPermission');
    }

    pushWebView(url: string) {
        console.log('[WebCrossPlatformController] pushWebView', url);
        if (!this._router) {
            console.error('[WebCrossPlatformController] pushWebView: this._router is not defined');
            return;
        }
        void this._router.push(url);
    }

    async modalWebView(url: string): Promise<void> {
        console.log('[WebCrossPlatformController] modalWebView', url);
        if (!this._router) {
            console.error('[WebCrossPlatformController] modalWebView: this._router is not defined');
            return;
        }
        await this._router.push(url);
    }

    closeWebView(): void {
        console.log('[WebCrossPlatformController] closeWebView');
        window.location.href = '/';
    }

    showContentDetail(code: string, url?: string): void {
        console.log('[WebCrossPlatformController] showContentDetail', code);
        if (!this._router) {
            console.error('[WebCrossPlatformController] showContentDetail: this._router is not defined');
            return;
        }
        void this._router.push(url ?? `/contents/${code}`);
    }

    showContentDetailWebView(code?: string, url?: string): void {
        console.log('[WebCrossPlatformController] showContentDetailWebView');

        if (!this._router) {
            console.error('[WebCrossPlatformController] showContentDetail: this._router is not defined');
            return;
        }
        void this._router.push(url ?? `/contents/sports/${code}`);
    }

    onBackPressed(): void {
        console.log('[WebCrossPlatformController] onBackPressed');
    }

    hapticTimeouts: NodeJS.Timeout[] = [];

    hapticFeedback(hapticFeedbackOptions?: CrossPlatformHapticFeedbackOptions): void {
        console.log('[WebCrossPlatformController] hapticFeedback', hapticFeedbackOptions);
        // document.body.style.transition = 'transform 50ms';
        //
        // this.hapticTimeouts.forEach((timeout) => clearTimeout(timeout));
        //
        // Array.from({ length: 6 }, (_, index) => {
        //     this.hapticTimeouts[index] = setTimeout(() => {
        //         document.body.style.transform = index === 5 ? 'translate(0)' : index % 2 === 0 ? 'translateX(15px)' : 'translateX(-15px)';
        //     }, index * 50);
        //
        //     return index;
        // });
    }

    changeOrientation(changeOrientationOptions: CrossPlatformChangeOrientationOptions): void {
        console.log('[WebCrossPlatformController] changeOrientation', changeOrientationOptions);
    }

    openAppSystemSetting(): void {
        console.log('[WebCrossPlatformController] openAppSystemSetting');
    }

    showDialog(showDialogOptions: CrossPlatformShowDialogOptions, callback?: (result: boolean) => void) {
        console.log('[WebCrossPlatformController] showDialog', showDialogOptions);

        Modal.dialog({
            title: showDialogOptions.message,
            confirmButtonText: showDialogOptions.confirmButton,
            showCancelButton: !!showDialogOptions.cancelButton,
        }).then((result: { isConfirmed: boolean; isDenied: boolean; isDismissed: boolean }) => {
            callback?.(result.isConfirmed);
        });
    }

    onConfirmDialog(event: CrossPlatformConfirmDialogEvent): void {
        console.log('[WebCrossPlatformController] onConfirmDialog', event);

        const customEvent = new CustomEvent('onConfirmDialog', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onChangeOrientation(event: Orientation): void {
        console.log('[WebCrossPlatformController] onChangeOrientation', event);
    }

    openTvingTalk(openTvingTalkOptions: CrossPlatformOpenTvingTalkOptions): void {
        console.log('[WebCrossPlatformController] openTvingTalk', openTvingTalkOptions);
    }

    shareLink(shareLinkOptions: CrossPlatformShareLinkOptions): void {
        console.log('[WebCrossPlatformController] shareLink', shareLinkOptions);
    }

    getSafeArea() {
        console.log('[WebCrossPlatformController] getSafeArea');
        // Android Only!
    }

    onSafeArea(event: CrossPlatformSafeAreaEvents) {
        console.log('[WebCrossPlatformController] onSafeArea', event);
        // Android Only!
    }

    onChangeContentInset(event: CrossPlatformContentInsetEvents) {
        console.log('[WebCrossPlatformController] onChangeContentInset', event);
    }

    onTimeMachineChange(event: CrossPlatformModeChangeEvent) {
        console.log('[WebCrossPlatformController] onTimeMachineChange', event);
    }

    changePlayerFeature(changePlayerFeatureOptions: CrossPlatformChangePlayerFeatureOptions) {
        console.log('[WebCrossPlatformController] changePlayerFeature', changePlayerFeatureOptions);
    }

    getClipPlayConfiguration() {
        console.log('[WebCrossPlatformController] getClipPlayConfiguration');
    }

    setClipPlayConfiguration(isContinuous: boolean) {
        console.log('[WebCrossPlatformController] setClipPlayConfiguration', isContinuous);
    }

    onClipPlayConfiguration(event: CrossPlatformClipPlayConfigurationChangeEvent): void {
        console.log('[WebCrossPlatformController] onClipPlayConfiguration', event);
    }

    onRequestContentChange(event: CrossPlatformRequestContentChangeEvent): void {
        console.log('[WebCrossPlatformController] onRequestContentChange', event);
        const customEvent = new CustomEvent('onRequestContentChange', { detail: event });
        window.dispatchEvent(customEvent);
    }

    appInterface(method: string, params?: string | undefined): void {
        console.log('[WebCrossPlatformController] appInterface', method, params);
        throw new Error('Not allowed');
    }

    setServiceNotification(serviceNotification: CrossPlatformServiceNotification) {
        console.log('[WebCrossPlatformController] serviceNotification', serviceNotification);
    }

    getServiceNotification(): void {
        console.log('[WebCrossPlatformController] getServiceNotification');
    }

    onServiceNotification(event: CrossPlatformServiceNotification): void {
        console.log('[WebCrossPlatformController] onServiceNotification', event);
    }

    getInitAppConfigurationInfo(): void {
        console.log('[WebCrossPlatformController] getInitAppConfigurationInfo');
    }

    onInitAppConfigurationInfo(event: CrossPlatformAppConfigurationEvent) {
        console.log('[WebCrossPlatformController] onInitAppConfigurationInfo', event);
    }

    onForeground(): void {
        console.log('[WebCrossPlatformController] onForeground');
    }

    onBackground(): void {
        console.log('[WebCrossPlatformController] onBackground');
    }

    onChangePlayerStatus(event: CrossPlatformChangePlayerStatusEvent): void {
        console.log('[WebCrossPlatformController] onChangePlayerStatus', event);
        const customEvent = new CustomEvent('onChangePlayerStatus', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onRequestNextContent(): void {
        console.log('[WebCrossPlatformController] onRequestNextContent');
        const customEvent = new CustomEvent('onRequestNextContent');
        window.dispatchEvent(customEvent);
    }

    didChangeInputFocus(isFocus: boolean): void {
        console.log('[WebCrossPlatformController] didChangeInputFocus', { isFocus });
    }

    openUrl(openUrlOptions: CrossPlatformOpenUrlOptions): void {
        console.log('[WebCrossPlatformController] openUrl', openUrlOptions);
    }

    sendBrazeCustomEvent(sendBrazeCustomEventOptions: CrossPlatformSendBrazeCustomEventOptions): void {
        console.log('[WebCrossPlatformController] sendBrazeCustomEvent', sendBrazeCustomEventOptions);
        this._pcLogging?.brazeEvent(sendBrazeCustomEventOptions);
    }

    openAppStore(): void {
        this.openUrl({ url: '' });
    }

    transferMultiviewApiResponse(multiviewApiResponse: KboContentsResponse<KboBand<KboMultiviewItem>>): void {
        console.log('[WebCrossPlatformController] transferMultiviewApiResponse', multiviewApiResponse);
    }

    onMultiviewChange(event: CrossPlatformModeChangeEvent): void {
        console.log('[WebCrossPlatformController] onMultiviewChange', event);
    }

    onChangePlayerFeature(event: CrossPlatformChangePlayerFeatureOptions) {
        console.log('[WebCrossPlatformController] onChangePlayerFeature', event);
        const customEvent = new CustomEvent('onChangePlayerFeature', { detail: event });
        window.dispatchEvent(customEvent);
    }

    onChangePlayerMode(event: CrossPlatformChangePlayerModeEvent): void {
        console.log('[WebCrossPlatformController] onChangePlayerMode', event);
        const customEvent = new CustomEvent('onChangePlayerMode', { detail: event });
        window.dispatchEvent(customEvent);
    }

    showBottomSheet(event: CrossPlatformShowBottomSheetEventOptions): void {
        console.log('[WebCrossPlatformController] showBottomSheet', event);
    }

    onConfirmBottomSheet(event: CrossPlatformConfirmBottomSheetEvent): void {
        console.log('[WebCrossPlatformController] onConfirmDialog');
        const customEvent = new CustomEvent('onConfirmBottomSheet', { detail: event });
        window.dispatchEvent(customEvent);
    }

    getTvingTalkModeration() {
        console.log('[WebCrossPlatformController] getTvingTalkModeration');
    }

    reportMessage(messageId: number) {
        console.log('[WebCrossPlatformController] reportMessage', { id: messageId });
    }

    moderateTvingTalk(event: CrossPlatformModerateTvintTalkEventOptions) {
        console.log('[WebCrossPlatformController] moderateTvingTalk', { event });
    }

    onModerateTvingTalk(event: CrossPlatformModerateTvintTalkEvent) {
        console.log('[WebCrossPlatformController] onModerateTvingTalk');
        const customEvent = new CustomEvent('onModerateTvingTalk', { detail: event });
        window.dispatchEvent(customEvent);
    }
}

export default WebCrossPlatformController;
