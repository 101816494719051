import React from 'react';
import ContentLoader from 'react-content-loader';
import { useState, useEffect } from 'react';
import useDomLoaded from '@hooks/useDomLoaded';

const CatalogMagic = ({
    width = 1366,
    isTitle = true,
    heading = { width: 140, height: 24 },
    row = 1,
    column = 5,
    padding = 10,
    borderRadius = 4,
    type = 'slider',
    ratioType = 'vertical',
    ratioValue = '25x36',
    ...props
}) => {
    const domLoaded = useDomLoaded();

    if (!domLoaded) {
        return null;
    }

    const list = [];
    const windowWidth = window.innerWidth;
    let height;

    const ratioMap = {
        0: {
            vertical: 3, // 기본 (세로형)
            horizontal: 2, // 기본 (가로형)
            original: 2, // 오리지널
        },
        600: {
            vertical: 5, // 기본 (세로형)
            horizontal: 3, // 기본 (가로형)
            original: 4, // 오리지널
            single: 1,
        },
        1000: {
            vertical: 6, // 기본 (세로형)
            horizontal: 4, // 기본 (가로형)
            original: 5, // 오리지널
            single: 1,
        },
        1400: {
            vertical: 7, // 기본 (세로형)
            horizontal: 5, // 기본 (가로형)
            original: 6, // 오리지널
            single: 1,
        },
        2000: {
            vertical: 8, // 기본 (세로형)
            horizontal: 6, // 기본 (가로형)
            original: 7, // 오리지널
            single: 1,
        },
    };

    column = ratioMap['0'][ratioType];

    let height2 = 20;
    let height3 = 20;

    if (windowWidth < 768) {
        padding = 24;
        heading = { width: 280, height: 48 };
        height2 = 40;
        height3 = 40;
    }

    if (windowWidth >= 600 && windowWidth < 1000) {
        column = ratioMap['600'][ratioType];
    }
    if (windowWidth >= 1000 && windowWidth < 1400) {
        column = ratioMap['1000'][ratioType];
    }
    if (windowWidth >= 1400 && windowWidth < 2000) {
        column = ratioMap['1400'][ratioType];
    }
    if (windowWidth >= 2000) {
        column = ratioMap['2000'][ratioType];
    }

    for (let i = 1; i <= row; i++) {
        for (let j = 0; j < column; j++) {
            const itemWidth = (width - padding * (column - 1)) / column;
            const x = j * (itemWidth + padding);
            let height1 = (itemWidth / 100) * 144;
            if (ratioValue === '16x9') {
                height1 = (itemWidth / 100) * 56.25;
            }
            if (ratioValue === '10x1') {
                height1 = (itemWidth / 100) * 10;
                height2 = 0;
                height3 = 0;
            }
            if (ratioValue === '1x2') {
                height1 = (itemWidth / 100) * 200;
                height2 = 0;
                height3 = 0;
            }

            const space = padding + height1 + (padding / 2 + height2) + height3 + padding * 4;
            let y1 = heading.height + padding * 2 + space * (i - 1);
            if (heading.height === 0) {
                y1 = space * (i - 1);
            }
            const y2 = y1 + padding + height1;
            const y3 = y2 + padding + height2;

            list.push(
                <React.Fragment key={`${i}-${j}`}>
                    <rect x={x} y={y1} rx={borderRadius} ry={borderRadius} width={itemWidth} height={height1} />
                    <rect x={x} y={y2} rx={0} ry={0} width={itemWidth} height={height2} />
                    <rect x={x} y={y3} rx={0} ry={0} width={itemWidth * 0.6} height={height3} />
                </React.Fragment>,
            );

            if (i === row) {
                height = y3 + height3 + padding * 5;
            }
        }
    }

    return (
        <ContentLoader
            animate={false}
            viewBox={`0 0 ${width} ${height}`}
            // width={width}
            // height={height}
            {...props}
            backgroundColor="#ffffff13"
            foregroundColor="#ffffff13"
        >
            {heading && isTitle && <rect rx={0} ry={0} width={heading.width} height={heading.height} />}
            {list}
        </ContentLoader>
    );
};

CatalogMagic.metadata = {
    name: 'I am Doong - I come from Việt Nam',
    github: 'toiladoong',
    description: 'CatalogMagic',
    filename: 'CatalogMagic',
};

export default CatalogMagic;
