import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';

interface KboMyTeamSettingHeaderProps {
    titleClass: string;
    subTitleClass: string;
    headerClass: string;
}
const KboMyTeamSettingHeader = ({ titleClass, subTitleClass, headerClass }: KboMyTeamSettingHeaderProps) => {
    const title = <p className={tempCnx(titleClass)}>마이팀 설정</p>;
    const subTitle = <p className={tempCnx(subTitleClass)}>마이팀을 설정하면 KBO 홈에서 마이팀 소식을 먼저 볼 수 있어요.</p>;

    return (
        <div className={tempCnx(headerClass)}>
            {title}
            {subTitle}
        </div>
    );
};
export default KboMyTeamSettingHeader;
