import React, { useEffect, useState } from 'react';

import SendbirdChat, { ConnectionHandler, ConnectionState, SendbirdError, User } from '@sendbird/chat';
import { OpenChannelModule, SendbirdOpenChat } from '@sendbird/chat/openChannel';
import dayjs from 'dayjs';

import { sendbirdAppId as appId } from '../../../constants/chat';
import SENDBIRD_ERROR from '../../../constants/errors/SENDBIRD_ERROR';
import { CHAT_ERRORS, ChatErrorState, useChatConfig } from '../../../contexts/ChatContext';

type UseChatConnectionWithSendbirdProps = {
  sendbirdToken?: string;
  userInfo:
    | {
        profileNumber: string;
        nickname: string;
        profileUrl: string;
      }
    | {
        profileNumber: undefined;
        nickname: undefined;
        profileUrl: undefined;
      };
};

const useChatConnectionWithSendbird = ({ sendbirdToken, userInfo }: UseChatConnectionWithSendbirdProps) => {
  const [isConnected, setIsConnected] = useState(false);
  const [sendbirdChat, setSendbirdChat] = useState<SendbirdOpenChat | null>(null);
  const [retryAttempts, setRetryAttempts] = useState(0);
  const { user, error, setUser, setError, clearError, refetchSession, refreshSession, setIsReconnected } = useChatConfig();
  const { profileNumber, nickname, profileUrl } = userInfo;

  // Sendbird Chat 초기화 로직
  useEffect(() => {
    if (!appId) {
      console.error('sendbird2', 'appId is required');
      return;
    }

    try {
      setSendbirdChat(
        (prev) =>
          prev ??
          (SendbirdChat.init({
            appId,
            modules: [new OpenChannelModule()],
          }) as SendbirdOpenChat),
      );
    } catch (error) {
      console.error('Sendbird initialization error:', error);
    }
  }, []);

  useEffect(() => {
    if (!sendbirdChat || !sendbirdToken || !profileNumber) return;

    const handleSendbirdConnection = async () => {
      try {
        // 이미 연결된 유저인지 확인
        if (sendbirdChat.currentUser?.userId === profileNumber) {
          setUser(sendbirdChat.currentUser);
          setIsConnected(true);
          return;
        }

        // Connection Handler 설정
        const connectionHandler = new ConnectionHandler({
          onConnected: () => console.log(`#tving-talk connected at ${dayjs().format('YY/MM/DD HH:mm:ss')}`),
          onDisconnected: () => console.log(`#tving-talk disconnected at ${dayjs().format('YY/MM/DD HH:mm:ss')}`),
          onReconnectStarted: () => console.log(`#tving-talk reconnect started at ${dayjs().format('YY/MM/DD HH:mm:ss')}`),
          onReconnectSucceeded: () => {
            console.log(`#tving-talk reconnect succeeded at ${dayjs().format('YY/MM/DD HH:mm:ss')}`);
            clearError();
            setIsReconnected(true);
          },
          onReconnectFailed: () => {
            console.log(`#tving-talk reconnect failed at ${dayjs().format('YY/MM/DD HH:mm:ss')}`);
            setError(CHAT_ERRORS.NETWORK_ERROR);
          },
        });

        sendbirdChat.addConnectionHandler(profileNumber, connectionHandler);

        // Sendbird 연결
        await sendbirdChat.connect(profileNumber, sendbirdToken);
        setIsConnected(true);

        // 유저 정보 업데이트
        const updatedUser = await sendbirdChat.updateCurrentUserInfo({ nickname, profileUrl });
        setUser(updatedUser);
      } catch (error) {
        if (error instanceof SendbirdError) {
          console.error('Sendbird connection error:', error);

          // Access Token 에러 처리
          if (error.code === SENDBIRD_ERROR.BAD_REQUEST.INVALID_ACCESS_TOKEN) {
            retryAttempts >= 2 ? refreshSession() : refetchSession();
            setRetryAttempts((prev) => prev + 1);
          }
        }
      }
    };

    // 비동기 함수 호출
    handleSendbirdConnection();

    return () => {
      console.log('[#tving-talk - chat]', 'Sendbird is destroyed');
      sendbirdChat.removeConnectionHandler(profileNumber);
    };
  }, [
    sendbirdChat,
    sendbirdToken,
    profileNumber,
    nickname,
    profileUrl,
    retryAttempts,
    setUser,
    setError,
    clearError,
    refetchSession,
    refreshSession,
    setIsReconnected,
  ]);

  return {
    sendbirdChat: isConnected ? sendbirdChat : null,
    user,
    isLoading: !sendbirdChat || !user || !isConnected,
    error,
  };
};

export default useChatConnectionWithSendbird;
