import useKboHomeQuery from '@hooks/kbo/queries/useKboHomeQuery';
import useKboContentsMainQuery from '@hooks/kbo/queries/useKboContentsMainQuery';
import useKboContentsBroadcastQuery from './useKboContentsBroadcastQuery';
import dayjs from 'dayjs';
import { BaseballPosition } from '@typings/kbo/baseball';

const BFF_DEFAULT = ['kbo', 'bff'];
export const KBO_QUERY_KEYS = {
    HOME: (tab: string) => [...BFF_DEFAULT, 'home', tab] as const,
    MORE_BAND_ITEMS: (apiUrl: string) => [...BFF_DEFAULT, 'more-band-items', apiUrl] as const,
    SCHEDULE: ({ type, key }: { type: 'date' | 'team'; key: dayjs.Dayjs }) =>
        [...BFF_DEFAULT, 'home', 'schedule', { type, key: dayjs(key).format('YYYYMMDD') }] as const,
    SCHEDULE_DATE_PICKER: ({ date }: { date: string }) => [...BFF_DEFAULT, 'home', 'schedule', 'date-picker', date] as const,
    CONTENTS_MAIN: (gameCode: string) => [...BFF_DEFAULT, 'live', gameCode] as const,
    CONTENTS_POWER: (gameCode: string) => [...BFF_DEFAULT, 'live', gameCode, 'power'] as const,
    CONTENTS_BROADCAST: (gameCode: string, inning?: number) => [...BFF_DEFAULT, 'live', gameCode, 'broadcast', inning || ''] as const,
    CONTENTS_POINT: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'point'] as const,
    CONTENTS_CLIP: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'clip'] as const,
    CONTENTS_RUNS: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'runs'] as const,
    CONTENTS_LINEUP: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'lineup'] as const,
    CONTENTS_RECORD: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'record'] as const,
    CONTENTS_MULTIVIEW: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'multi-view'] as const,
    GAME_LIST: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'schedule'] as const,
    CLIP_MAIN: (code: string) => [...BFF_DEFAULT, 'clip', code] as const,
    RANKINGS: () => [...BFF_DEFAULT, 'ranking'] as const,
    RANKING: (filters: { tab: string; year?: number; season?: string; playerType?: string }) =>
        [
            ...KBO_QUERY_KEYS.RANKINGS(),
            {
                tab: filters.tab ?? null,
                year: filters.year ?? null,
                season: filters.season ?? null,
                playerType: filters.playerType ?? null,
            },
        ] as const,
    RANKING_SORTED: (filters: { tab: string; year?: number; season?: string; playerType?: string; rankOrder?: string }) =>
        [
            ...KBO_QUERY_KEYS.RANKING(filters),
            {
                rankOrder: filters.rankOrder ?? null,
            },
        ] as const,
    PLAYER_RANKING: (apiUrl) => [...BFF_DEFAULT, 'ranking', 'player', apiUrl] as const,
    MY_TEAMS: () => ['myTeams'] as const,
    MORE_VIDEO: ({ pathname, params }) => [...BFF_DEFAULT, 'more', pathname, params] as const,
    MORE_SCHEDULE: ({ query }) => [...BFF_DEFAULT, 'more', 'clip', 'playlist', query] as const,
    ATHLETE: (athleteCode: string) => [...BFF_DEFAULT, 'athlete', athleteCode] as const,
    ROASTER: (teamCode: string, position: BaseballPosition) => [...BFF_DEFAULT, 'roaster', teamCode, position] as const,
    TEAM: (teamCode: string) => [...BFF_DEFAULT, 'team', teamCode] as const,
} as const;

export { useKboHomeQuery, useKboContentsMainQuery, useKboContentsBroadcastQuery };
