import { useEffect } from 'react';

const useFixedModalBackground = () => {
  // 모달창이 부유한다면 뒤쪽 배경 스크롤이 안되게 막는 로직
  useEffect(() => {
    const setBodyOverflow = (value: string) => {
      document.body.style.overflow = value;
    };

    setBodyOverflow('hidden');

    return () => {
      setBodyOverflow('auto');
    };
  });
};

export default useFixedModalBackground;
