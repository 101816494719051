import { create } from 'zustand';

type State = {
    toggledLastItemIdx: number | null; // 이어보기 아이템의 팝업 레이어가 노출유무 상태
    setToggledLastItemIdx: (value: number) => void;
    observer: IntersectionObserver | null; // useLazyImage 함수에서 IntersectionObserver 객체를 한개 만들어서 각 아이템에 재사용을 위한 상태
    setObserver: (observer: IntersectionObserver) => void;
    regionCode?: null | string;
    setRegionCode: (region: string) => void;
    isWebview: boolean | undefined; // 웹뷰인지 아직 구분되지 않았을 때는 undefined
    setIsWebview: (isWebview: boolean) => void;
    isMobile: boolean | undefined; // mobile + tablet인지 아직 구분되지 않았을 때는 undefined
    setIsMobile: (isMobile: boolean) => void;
    isMobileOnly: boolean | undefined; // mobile인지 아직 구분되지 않았을 때는 undefined
    setIsMobileOnly: (isMobileOnly: boolean) => void;
    isTablet: boolean | undefined; // tablet인지 아직 구분되지 않았을 때는 undefined
    setIsTablet: (isTablet: boolean) => void;
    isAndroid: boolean | undefined; // android인지 아직 구분되지 않았을 때는 undefined
    setIsAndroid: (isAndroid: boolean) => void;
    isIOS: boolean | undefined; // ios 아직 구분되지 않았을 때는 undefined
    setIsIOS: (isIOS: boolean) => void;
    isDesktop: boolean | undefined; // desktop인지 아직 구분되지 않았을 때는 undefined
    setIsDesktop: (isDesktop: boolean) => void;
};

const useClientStore = create<State>()((set) => ({
    toggledLastItemIdx: null,
    setToggledLastItemIdx: (value: number) => set({ toggledLastItemIdx: value }),
    observer: null,
    setObserver: (observer) => set({ observer }),
    regionCode: null,
    setRegionCode: (regionCode) => set({ regionCode }),
    isWebview: undefined,
    setIsWebview: (isWebview) => set({ isWebview }),
    isMobile: undefined,
    setIsMobile: (isMobile) => set({ isMobile }),
    isMobileOnly: undefined,
    setIsMobileOnly: (isMobileOnly) => set({ isMobileOnly }),
    isTablet: undefined,
    setIsTablet: (isTablet) => set({ isTablet }),
    isAndroid: undefined,
    setIsAndroid: (isAndroid) => set({ isAndroid }),
    isIOS: undefined,
    setIsIOS: (isIOS) => set({ isIOS }),
    isDesktop: undefined,
    setIsDesktop: (isDesktop) => set({ isDesktop }),
}));

export default useClientStore;
