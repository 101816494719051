import { SSR_PAGE_LIST } from '@constants/urls';

/**
 * ssr 여부 체크가 필요한 url을 받아서 SSR 페이지 여부에 따라 boolean 값을 반환
 * @param {String} toBeCheckedPage
 * @return {Boolean}
 */

export const checkSsrPage = toBeCheckedPage => {
    let isSsrPage = false;

    SSR_PAGE_LIST.every(ssrPage => {
        if (toBeCheckedPage.includes(ssrPage)) {
            isSsrPage = true;
            return false;
        }
        return true;
    });

    return isSsrPage;
};
