import { ServerResponse } from 'node:http';

import Modal from '@components/Modal';
import { pageOrigin, userOrigin } from '@utils/apiConfig';
import { getWindowPopCenter } from '@utils/common/Popup';
import { appleUser, facebookUser, instagramUser, kakaoUser, naverUser, twitterUser } from '@utils/common/UserType';

/**
 * Nodejs Server Response 객체가 쓰기 가능한지 확인 - 이미 Nextjs에서 응답을 보낸 경우 다시 응답을 보낼 수 없습니다.
 * - @see {@link https://nodejs.org/api/http.html#responsewritableended - response.writableEnded}
 */
export const hasServerResponseBeenSent = (serverResponse: ServerResponse) => {
    return serverResponse?.writableEnded === true;
};

// 비밀번호가 변경되어 로그아웃 처리 및 이동
export const passwordChangedModal = () => {
    Modal.dialog({ title: '패스워드가 변경되어 로그아웃됩니다. 다시 로그인하시겠습니까?' }).then((result) => {
        if (result.isConfirmed) {
            // 로그아웃 후 로그인 페이지로 이동 필요
            window.location.href = `${userOrigin}/pc/user/doLogout.tving?returnUrl=${pageOrigin}/account/login`;
        } else {
            const encodedHref = encodeURIComponent(window.location.href);
            window.location.href = `${userOrigin}/pc/user/doLogout.tving?returnUrl=${encodedHref}`;
        }
    });
};

// 지원하지 않는 리전에서 서비스 진입한 경우 로그아웃 처리 및 6001 오류페이지 이동
export const noSupportedRegionModal = () => {
    Modal.alert({
        text: '서비스를 지원하지 않는 국가입니다.\n 로그아웃 처리됩니다.',
        customClass: {
            container: 'no-supported-region-modal',
        },
    }).then(({ isConfirmed }) => {
        if (isConfirmed) {
            const returnUrl = `${window.location.origin}/6001`;
            const logoutUrl = `${userOrigin}/pc/user/doLogout.tving`;
            window.location.href = `${logoutUrl}?returnUrl=${encodeURIComponent(returnUrl)}`;
        }
    });
};

// 로그아웃 처리 및 온보딩으로 이동
export const moveLogout = ({ serverRes = null, origin = '' }: { serverRes?: ServerResponse | null; origin?: string }) => {
    if (serverRes) {
        // SSR인 경우
        const returnUrl = `${origin}/onboarding`;
        const logoutUrl = `${userOrigin}/pc/user/doLogout.tving`;
        serverRes.writeHead(302, { Location: `${logoutUrl}?returnUrl=${encodeURIComponent(returnUrl)}` });
        serverRes.end();
    } else {
        // CSR인 경우
        const returnUrl = `${window.location.origin}/onboarding`;
        const logoutUrl = `${userOrigin}/pc/user/doLogout.tving`;
        window.location.href = `${logoutUrl}?returnUrl=${encodeURIComponent(returnUrl)}`;
    }
};

// 500 에러페이지 이동
export const move500Page = ({ serverRes = null }: { serverRes?: ServerResponse | null }) => {
    if (serverRes) {
        // SSR인 경우
        serverRes.writeHead(302, { Location: '/500' });
        serverRes.end();
    } else {
        // CSR인 경우
        window.location.href = '/500';
    }
};

// 로그인 화면으로 이동 > 이후 메인으로 이동
export const moveUserLogin = (params = '') => {
    window.location = `${pageOrigin}/account/login?returnUrl=${encodeURIComponent(window.location.origin + params)}` as unknown as Location;
};
// 모바일 로그인 화면으로 이동 > 이후 메인으로 이동 (모바일)
export const moveMobileUserLogin = (url = window.location.origin, params = '') => {
    window.location = `${pageOrigin}/account/login?returnUrl=${encodeURIComponent(url + params)}` as unknown as Location;
};

// 로그인 화면으로 이동 > 이후 이전 페이지로 이동 (ex. 상세)
export const moveUserLoginBackDetail = () => {
    window.location = `${pageOrigin}/account/login?returnUrl=${encodeURIComponent(window.location.href)}` as unknown as Location;
};
// 모바일 로그인 화면으로 이동 > 이후 이전 페이지로 이동 (ex. 상세)
export const moveMobileUserLoginBackDetail = ({ isPreRelease = false }) => {
    const params = isPreRelease ? '?isPreRelease=Y' : '';
    window.location = `${pageOrigin}/account/login?returnUrl=${encodeURIComponent(window.location.href + params)}` as unknown as Location;
};

// 로그인 화면으로 이동 > 이후 메인으로 이동
export const moveUserLoginSSR = (url = '') => {
    return `${pageOrigin}/account/login?returnUrl=${encodeURIComponent(url)}`;
};
// 모바일 로그인 화면으로 이동 > 이후 메인으로 이동 (모바일)
export const moveMobileUserLoginSSR = (url = '') => {
    return `${pageOrigin}/account/login?returnUrl=${encodeURIComponent(url)}`;
};

// 프로필 선택 페이지로 이동
export const moveProfileSelect = () => {
    window.location = `${userOrigin}/pc/user/profiles.tving` as unknown as Location;
};

// 프로필 선택 페이지로 이동 - SSR 처리 가능
export const moveProfileSelectSsr = ({ serverRes = null }: { serverRes?: ServerResponse | null }) => {
    const profileSelectPage = `${userOrigin}/pc/user/profiles.tving`;
    if (serverRes) {
        // SSR인 경우
        serverRes.writeHead(302, { Location: profileSelectPage });
        serverRes.end();
    } else {
        // CSR인 경우
        window.location = profileSelectPage as unknown as Location;
    }
};

// 프로필 수정 페이지로 이동 (프로필 썸네일 클릭, 닉네임 변경 클릭)
export const moveProfileEdit = () => {
    // route.push(`${userOrigin}/pc/user/profilesForEdit.tving`);
    window.location = `${userOrigin}/pc/user/myProfileForEdit.tving` as unknown as Location;
};

// 회원정보 수정
export const moveUserEdit = ({ isCjOneUser = false, userType }) => {
    // cj one 계정인 경우
    if (isCjOneUser) {
        window.location = `${userOrigin}/pc/user/modify/cjoneinfo.tving` as unknown as Location;
    } else if (isSnsUser({ userType })) {
        // SNS 계정인 경우
        window.location = `${userOrigin}/pc/user/modify/info.tving?confirmPwd=Y` as unknown as Location;
    } else {
        // 그 외
        window.location = `${userOrigin}/pc/user/info/confirm/password.tving` as unknown as Location;
    }
};

// 로그인 사용자가 SNS 계정인지 체크
const isSnsUser = ({ userType = '' }) => {
    const snsUserType = [naverUser, kakaoUser, facebookUser, twitterUser, appleUser, instagramUser];
    return snsUserType.includes(userType);
};

// 14세 이상 동의 여부 체크
export const moveOverAgeCheck = ({ overAgeYn = 'N', isMobile = false }) => {
    const ageCheckUrl = isMobile ? '/user/auth/overAgeCheck.tving' : '/pc/user/auth/overAgeCheck.tving';
    // const formData = new FormData();
    // formData.append('overAgeYn', overAgeYn);

    window.location = `${userOrigin}${ageCheckUrl}?overAgeYn=${overAgeYn}&returnUrl=${encodeURIComponent(
        window.location.href,
    )}` as unknown as Location;
    /* await ApiSend({
        // URL 넘어온 값 추가
        url: `https://user.tving.com/pc/user/auth/overAgeCheck.tving`,
        method: 'post',
        data: formData,
        headers: {
            'content-type': 'multipart/form-data',
        },
        withCredentials: true,
    }); */
};

// 회원 - 19세 인증 페이지 이동
export const moveAdultCheckPage = ({ returnUrl = '', isMobile }) => {
    window.location = getMoveAdultCheckPage({ returnUrl, isMobile }) as unknown as Location;
    // 사용자 인증 안된 경우 https://userdev.tving.com/pc/user/profileAuth/adultCheck.tving?returnUrl= 로 보냄
};
// 19세 인증 URL 조합
export const getMoveAdultCheckPage = ({
    returnUrl = '',
    isMobile,
}: {
    isMobile?: boolean;
    returnUrl?: string;
} = {}) => {
    const adultCheckUrl = isMobile ? '/user/profileAuth/adultCheck.tving' : '/pc/user/profileAuth/adultCheck.tving';
    return `${userOrigin}${adultCheckUrl}?returnUrl=${encodeURIComponent(pageOrigin + returnUrl)}`;
};
// 회원 - 법정 대리인 동의 페이지 이동
export const moveOverAgeCheckPage = () => {
    // https://userdev.tving.com/pc/user/auth/overAgeCheck.tving?overAgeYn=N&returnUrl=/pc/user/tving/regist/complete.tving&isJoin=y
    window.location = `${userOrigin}/pc/user/auth/overAgeCheck.tving?returnUrl=${encodeURIComponent(window.location.origin)}` as unknown as Location;
};

// 안심 본인 (휴대폰) 인증 팝업
export const openNiceMobileCheck = () => {
    const width = 500;
    const height = 650;
    const { left, top } = getWindowPopCenter({ w: width, h: height });
    window.open(
        'https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb?m=authMobileMain',
        'NICE_MOBILE_CHECK_POPUP',
        `scrollbars=no,menubar=no,control=no,resizable=no,width=${width},height=${height},top=${top},left=${left}`,
    );
};

export const openIpinCheck = () => {
    const width = 500;
    const height = 650;
    const { left, top } = getWindowPopCenter({ w: width, h: height });
    window.open(
        'https://cert.vno.co.kr/ipin.cb',
        'NICE_IPIN_CHECK_POPUP',
        `scrollbars=no,menubar=no,control=no,resizable=no,width=${width},height=${height},top=${top},left=${left}`,
    );
};
