import React, { useRef, useState } from 'react';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';

import IconHeartRed from '@img/chat/icon_heart_red.svg';
import LottieContainer from '@components/chat/LottieContainer';

import heart01 from '@public/lotties/hearts/heart_01.json';
import heart02 from '@public/lotties/hearts/heart_02.json';
import heart03 from '@public/lotties/hearts/heart_03.json';
import heart04 from '@public/lotties/hearts/heart_04.json';
import heart05 from '@public/lotties/hearts/heart_05.json';
import heart06 from '@public/lotties/hearts/heart_06.json';
import heart07 from '@public/lotties/hearts/heart_07.json';
import heart08 from '@public/lotties/hearts/heart_08.json';
import heart09 from '@public/lotties/hearts/heart_09.json';
import heart10 from '@public/lotties/hearts/heart_10.json';
import useCrossPlatform from '@utils/crossPlatform/react/useCrossPlatform';
import { MetaCounter } from '@sendbird/chat';
import { useChatConfig } from '@tving/utils/src/contexts/ChatContext';

type HeartInteractionProps = {
    metaCounts: MetaCounter;
    increaseMetaCounterHandler: (key: string, value?: number) => Promise<void>;
    isDisabled: boolean;
    className?: string;
};

const heartDatas = [
    { number: 1, data: heart01 },
    { number: 2, data: heart02 },
    { number: 3, data: heart03 },
    { number: 4, data: heart04 },
    { number: 5, data: heart05 },
    { number: 6, data: heart06 },
    { number: 7, data: heart07 },
    { number: 8, data: heart08 },
    { number: 9, data: heart09 },
    { number: 10, data: heart10 },
];
const RANDOM_HASH = 10_000;

const formatNumberWithThousandsSeparator = (value: number) => (value ? value.toLocaleString() : 0);
const formatNumberWithConditionalUnitForHeart = (value: number) =>
    ({
        true: formatNumberWithThousandsSeparator(value),
        [value >= 10_000]: `${(value / 10_000).toFixed(1)}만`,
        [value >= 1_000_000]: `${(value / 10_000).toFixed()}만`,
        [value >= 10_000_000]: `999만+`,
    }.true);

const HeartInteraction = ({ metaCounts, increaseMetaCounterHandler, isDisabled, className = '' }: HeartInteractionProps) => {
    const crossPlatformController = useCrossPlatform();
    const { metaCounters, setMetaCountersHandler } = useChatConfig();
    const [hearts, setHearts] = useState<{ key: number; number: number; data: any }[]>([]);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const buttonClickHandler = () => {
        if (isDisabled) {
            return;
        }

        const randomKey = Math.floor(Math.random() * (heartDatas.length * RANDOM_HASH));
        const randomData = heartDatas[Math.floor(randomKey / RANDOM_HASH)];

        setMetaCountersHandler(metaCounters);
        setHearts((prev) => [...prev, { key: randomKey, ...randomData }]);
        increaseMetaCounterHandler('HEART_COUNT', 1);
        crossPlatformController.hapticFeedback();

        setTimeout(() => {
            setHearts((prev) => prev.filter((value) => value.key !== randomKey));
        }, 1_000);
    };

    const buttonFocusHandler = () => {
        buttonRef.current?.blur();
    };

    const heartCount = metaCounts?.HEART_COUNT ?? 0;

    return (
        <div className={tempCnx('absolute -top-[1.333rem] right-[1.333rem] -translate-y-full transition-transform', className)}>
            <button
                type="button"
                className={tempCnx(
                    'group/heart flex-center flex-col w-20 h-20',
                    'border-solid border border-[#fff]/20 rounded-full bg-[#fff]/10 backdrop-blur-[20px]',
                )}
                ref={buttonRef}
                onClick={buttonClickHandler}
                onFocus={buttonFocusHandler}
            >
                <IconHeartRed
                    className={tempCnx('w-[2rem] h-[1.833rem] group-active/heart:scale-[0.8] transition-transform duration-100 ease-in-out')}
                />
                <span className={tempCnx('h-4 mt-[0.333rem]')}>
                    {heartCount >= 0 ? formatNumberWithConditionalUnitForHeart(heartCount).toString() : ''}
                </span>
            </button>

            {hearts.map((data) => (
                <LottieContainer
                    className={tempCnx('absolute bottom-full -right-[4.33rem] w-80 h-100', 'select-none')}
                    key={data.key.toString()}
                    data={data.data}
                />
            ))}
        </div>
    );
};

export default HeartInteraction;
