/* eslint-disable */
import { useState, useEffect } from 'react';
import { urlString } from '@utils/common/String';
import moment from 'moment';
import ApiSend from '@utils/common/ApiSend';
import useTranslation from 'next-translate/useTranslation';
import { usePlayerPageInfo } from '@store/state';
import { useAbortSWR } from './plugins';
import { replaceWeirdUnicodeWithBlank } from '@utils/common/String';

/**
 * 인기 Episode List
 * @constructor
 * @param {number} pageNo - 페이지 번호.
 * @return {Object|boolean} data, source, isLoading, isError Value.
 */
export const useEpisodeViewDayList = (keys = {}, opts = {}) => {
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달
    return useAbortSWR(
        [`/v2/media/episodes?${urlString.stringify(keys)}`],
        {
            params: {
                cacheType: 'main',
                pageSize: 20,
                order: 'viewDay',
                adult: 'all',
                free: 'all',
                guest: 'all',
                scope: 'all',
                lastFrequency: 'Y',
                personal: 'N',
                ...opts,
            },
        },
        { focusThrottleInterval: 1000 * 60 * 30 },
    );
};

export const useProgramTvingList = ({ pageNo = 1 }) => {
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달
    return useAbortSWR([`/v2/operator/highlights?pageNo=${pageNo}`], {
        params: {
            mainYn: 'Y',
            positionKey: 'AND_RE_VODHOME_HOT_PM_LIST',
        },
    });
};

// 프로그램/영화 시즌 리스트
export const useSeasonList = ({ type = 'program', seasonCode }) => {
    return useAbortSWR([seasonCode && seasonCode !== '' ? `/v2/media/season/${type}?seasonCode=${seasonCode}` : null]);
};

export const targetEpisodeFromProgram = async ({ programCode }) => {
    const { data } = await ApiSend({
        url: `/v2/media/programRecommendVod?programCode=${programCode}&pageNo=1`,
        withCredentials: true,
    });
    const availableType = data?.body?.programRecommendVod?.available;
    const { frequency, code: mediaCode } = data?.body?.programRecommendVod[availableType] || {};
    return { frequency, mediaCode };
};

// 프로그램 코드 기준 시청할 에피소드 정보 API
export const useTargetEpisodeFromProgram = ({ programCode }) => {
    const {
        data: targetEpisodeData,
        isLoading,
        isValidating,
        mutate,
    } = useAbortSWR(
        [programCode ? `/v2/media/programRecommendVod?programCode=${programCode}` : null],
        {
            params: {
                pageNo: 1,
            },
            withCredentials: true,
        },
        { revalidateOnFocus: false },
    );

    const availableType = targetEpisodeData?.body?.programRecommendVod?.available;
    const { frequency, code: mediaCode } = targetEpisodeData?.body?.programRecommendVod?.[availableType] || {};

    // console.log('targetMediaCode', mediaCode);
    return {
        isLoading,
        mediaCode,
        frequency,
        mutate,
        isValidating,
    };
};

// 특정 시즌의 에피소드 리스트
export const useFrequencyList = ({ programCode, order = 'new', isLive = false }) => {
    const { t } = useTranslation('common');
    const [pageInfo] = usePlayerPageInfo();
    const { mediaCode: playMediaCode } = pageInfo || {};
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달

    const { isLoading, mediaCode: targetMediaCode, mutate: firstMutate } = useTargetEpisodeFromProgram({ programCode });
    const { data, isLoading: afterIsLoading } = useAbortSWR(
        [!isLoading && programCode ? `/v2/media/frequency/program/${programCode}?order=${order}${isLive ? '&pageSize=20' : ''}` : null],
        {
            params: {
                cacheType: 'main',
                pageSize: 20,
                order: !isLive ? 'new' : order, // WEB-230: 상세 회차목록 order 파라미터 값 고정으로 변경 (newUpdate 값인 경우 최대 20개만 가져옴)
                adult: 'all',
                free: 'all',
                guest: 'all',
                scope: 'all',
            },
            withCredentials: true,
        },
        {
            revalidateOnFocus: false, // 창이 포커싱되었을 때 자동 갱신
        },
        (data) => {
            data.items?.map((item) => {
                const broadDate = moment(`${item.broadcastDate}`, 'YYYYMMDD');
                const broadDay = broadDate.day();
                item.displayFrequency = `${item.frequency}화`;
                item.subInfo = `${moment(`${item.broadcastDate}`, 'YYYYMMDD').format('YYYY.MM.DD')} | ${
                    parseInt(item.duration / 60) <= 0 ? 1 : parseInt(item.duration / 60)
                }${t('content.time.minutes')}`;
                item.subInfoMobile = `${broadDate.format('YYYY.MM.DD')} ${t(`date.day.${broadDay}`)}`;
                item.subInfoDuration = `${parseInt(item.duration / 60) <= 0 ? 1 : parseInt(item.duration / 60)}${t('content.time.minutes')}`;
                // item.duration = parseInt(item.duration / 60);

                // 윈도우 환경을 위한 특정 유니코드 문자열 치환
                item.synopsis = replaceWeirdUnicodeWithBlank(item.synopsis);
            });
            if (order === 'newUpdate') {
                data.items.reverse();
            }
            return data;
        },
    );

    // 활성 회차를 위한.
    // 재생 가능한 mediaCode가 업데이트되면 리스트 API 갱신
    const [targetCode, setTargetCode] = useState(targetMediaCode);
    const [playerActive, isPlayerActive] = useState(false);
    useEffect(() => {
        if (playerActive && !playMediaCode) {
            firstMutate();
        } else {
            setTargetCode(playMediaCode || targetMediaCode);
        }
        isPlayerActive(!!playMediaCode);
    }, [playMediaCode, targetMediaCode]);

    if (!afterIsLoading) {
        if (targetCode?.substr(0, 2) !== 'RV' && targetCode?.substr(0, 2) !== 'RM') {
            data.targetMediaCode = targetCode;
        }
    }

    const mutate = async () => {
        // 재생 가능한 회차 업데이트.
        firstMutate();
    };
    return {
        data,
        isLoading: afterIsLoading,
        mutate,
    };
};

// 특정 시즌의 에피소드 리스트
export const useOnlyFrequencyList = (programCode, order) => {
    const { t } = useTranslation('common');
    return useAbortSWR(
        [programCode ? `/v2/media/frequency/program/${programCode}` : null],
        {
            params: {
                cacheType: 'main',
                pageSize: 20,
                order: 'new',
                adult: 'all',
                free: 'all',
                guest: 'all',
                scope: 'all',
            },
            withCredentials: true,
        },
        {
            revalidateOnFocus: false, // 창이 포커싱되었을 때 자동 갱신
        },
        (data) => {
            data.items?.map((item) => {
                const broadDate = moment(`${item.broadcastDate}`, 'YYYYMMDD');
                const broadDay = broadDate.day();
                item.displayFrequency = `${item.frequency}화`;
                item.subInfo = `${moment(`${item.broadcastDate}`, 'YYYYMMDD').format('YYYY.MM.DD')} | ${
                    parseInt(item.duration / 60) <= 0 ? 1 : parseInt(item.duration / 60)
                }${t('content.time.minutes')}`;
                item.subInfoMobile = `${broadDate.format('YYYY.MM.DD')} ${t(`date.day.${broadDay}`)}`;
                item.subInfoDuration = `${parseInt(item.duration / 60) <= 0 ? 1 : parseInt(item.duration / 60)}${t('content.time.minutes')}`;

                // 윈도우 환경을 위한 특정 유니코드 문자열 치환
                item.synopsis = replaceWeirdUnicodeWithBlank(item.synopsis);
            });
            if (order === 'newUpdate') {
                data.items.reverse();
            }
            return data;
        },
    );
};
