import { useState, useEffect, useRef } from 'react';

import useNetworkStatus from '@tving/utils/src/utils/network/useGetNetworkStatus';

type DelayedNetworkConfig = {
  delay: number;
};

export type useDelayedNetworkProps = {
  config?: Partial<DelayedNetworkConfig>;
};

const defaultConfig: DelayedNetworkConfig = {
  delay: 30000, // default 30초
};

const useDelayedNetwork = ({ config = {} }: useDelayedNetworkProps = {}): boolean => {
  const isOnline = useNetworkStatus();
  const [isAlertTriggered, setIsAlertTriggered] = useState(false);
  const timeoutRef = useRef<number | null>(null);

  const finalConfig: DelayedNetworkConfig = { ...defaultConfig, ...config };

  useEffect(() => {
    if (!isOnline) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        setIsAlertTriggered(true);
      }, finalConfig.delay);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      setIsAlertTriggered(false);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isOnline, finalConfig.delay]);

  return isAlertTriggered;
};

export default useDelayedNetwork;
