import Swal, { SweetAlertOptions } from 'sweetalert2/dist/sweetalert2';
import Modal, { Styles, Props as ReactModalProps } from 'react-modal';
import { isWebview } from '@utils/device/device-detect';
import DOMPurify from 'isomorphic-dompurify';
import styles from './Modal.module.scss';

export type ModalAlertOptions<TResultValue> = Pick<SweetAlertOptions<TResultValue>, 'text' | 'customClass'> & {
    confirmButtonText?: string;
    cancelButtonText?: string;
    html?: string;
    customClass?: SweetAlertOptions['customClass'];
};

const alert = <TResultValue,>(data: ModalAlertOptions<TResultValue>) => {
    return Swal.fire<TResultValue>({
        title: data?.text || 'Please input the text',
        confirmButtonText: data?.confirmButtonText || '확인',
        cancelButtonText: data?.cancelButtonText || '취소',
        allowOutsideClick: false,
        html: data?.html,
        customClass: data?.customClass,
    });
};

export type ModalDialogOptions<TResultValue> = Pick<SweetAlertOptions<TResultValue>, 'title' | 'text' | 'customClass'> & {
    showCancelButton?: boolean;
    confirmButtonText?: string;
    cancelButtonText?: string;
    html?: string;
};

const dialog = <TResultValue,>(data: ModalDialogOptions<TResultValue>) => {
    return Swal.fire<TResultValue>({
        title: data?.title || 'Please input the text',
        text: data?.text || '',
        showCancelButton: Object.keys(data)?.includes('showCancelButton') ? data?.showCancelButton : true,
        confirmButtonText: data?.confirmButtonText || '확인',
        cancelButtonText: data?.cancelButtonText || '취소',
        allowOutsideClick: false,
        reverseButtons: isWebview,
        customClass: data.customClass,
        html: data?.html ? DOMPurify.sanitize(data.html) : undefined,
    });
};

export type ModalToastOptions<TResultValue> = Pick<
    SweetAlertOptions<TResultValue>,
    'timer' | 'showConfirmButton' | 'showCancelButton' | 'title' | 'position'
>;

const toast = <TResultValue,>({
    timer = 2000,
    showConfirmButton = false,
    showCancelButton = false,
    title,
    position = 'bottom',
}: ModalToastOptions<TResultValue>) => {
    return Swal.mixin({
        toast: true,
        position,
        showConfirmButton,
        showCancelButton,
        timer,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
    }).fire<TResultValue>({
        title,
    });
};

const WEBVIEW_DIALOG_CUSTOM_CLASS = {
    container: '!px-[2.79rem] !bg-black/50',
    popup: '!min-w-[unset] w-[25.833rem] lg:w-[33.333rem] !bg-gray-15',
    htmlContainer: `max-h-[min(36.75rem,_54.31vh)] !flex flex-col !justify-start text-[1.25rem] text-white font-normal text-start leading-[1.5] overflow-y-scroll my-[2.667rem] mx-[1rem] py-0 px-[1rem] scrollbar-hidden ${styles['override-popup-contents']}`,
    title: 'invisible opacity-0 !text-[0rem] !leading-[0rem] !p-0 !m-0',
    actions: 'flex-nowrap border-0 border-t border-solid border-gray-25',
    confirmButton: 'grow shrink basis-full w-full text-center !text-gray-70',
    cancelButton: 'grow shrink basis-full w-full text-center !text-gray-70',
};

export type ModalWebviewDialogOptions<TResultValue> = Pick<SweetAlertOptions<TResultValue>, 'title' | 'text' | 'customClass'> & {
    showCancelButton?: boolean;
    confirmButtonText?: string;
    cancelButtonText?: string;
    html?: string;
};

const webviewDialog = <TResultValue,>(data: ModalWebviewDialogOptions<TResultValue>) => {
    return dialog<TResultValue>({
        ...data,
        customClass: WEBVIEW_DIALOG_CUSTOM_CLASS,
    });
};

export type ModalViewProps = Pick<
    ReactModalProps,
    'isOpen' | 'onRequestClose' | 'onAfterClose' | 'style' | 'shouldCloseOnEsc' | 'shouldCloseOnOverlayClick' | 'children' | 'contentLabel'
>;

export const ModalView = (
    props: ModalViewProps = {
        isOpen: false,
        onRequestClose: () => {},
        style: {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'transparent',
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#000',
            },
        } as Styles,
        contentLabel: '기기등록',
        shouldCloseOnEsc: false,
    },
) => {
    const { isOpen, onRequestClose, onAfterClose, style, shouldCloseOnEsc, shouldCloseOnOverlayClick = true, children } = props;
    return (
        <Modal
            isOpen={isOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={onRequestClose}
            onAfterClose={onAfterClose}
            shouldCloseOnEsc={shouldCloseOnEsc}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            style={style}
            contentLabel="기기등록"
        >
            {children}
        </Modal>
    );
};

export default { alert, dialog, toast, webviewDialog };
