import type { ClassValue } from 'clsx';
import clsx from 'clsx';
// @ts-ignore
import { twMerge } from 'tailwind-merge/es5';

export const prefix2cnx = (prefix: string, ...inputs: string[]) => {
    return twMerge(
        clsx(
            inputs.map((values) =>
                values
                    ?.split(' ')
                    .map((value) => `${prefix}:${value}`)
                    .join(' '),
            ),
        ),
    );
};

export default (...inputs: ClassValue[]): string => {
    return twMerge(clsx(inputs));
};
