import { KboChannel } from '@typings/kbo/band';

type getActiveChannelIdxProps = {
    channels: KboChannel[];
    gameCode: string;
    lastPlayChannel: {
        [key: string]: string;
    };
    requestedChannelCode?: string;
};

/**
 * 채널이 중복일 경우 ex)TSM, 게임코드를 통해 재생 시켜야하는 채널 인덱스를 반환하는 함수입니다.
 */

const getActiveChannelIdx = ({ channels, gameCode, lastPlayChannel, requestedChannelCode }: getActiveChannelIdxProps) => {
    if (requestedChannelCode) {
        const targetChannel = channels?.find((channel) => channel.code === requestedChannelCode);
        const activeChannelIdx = targetChannel ? channels.indexOf(targetChannel) : 0;
        return activeChannelIdx;
    }

    const targetChannel =
        channels?.find((channel) => channel.broadcastFormat === 'TSM') || channels.find((channel) => channel.broadcastFormat === 'BASIC');
    let activeChannelIdx = targetChannel ? channels.indexOf(targetChannel) : 0;

    const currentChannel = lastPlayChannel?.[gameCode];

    if (channels && currentChannel) {
        activeChannelIdx = channels?.findIndex((channel) => channel.code === currentChannel);
        return activeChannelIdx;
    }

    return activeChannelIdx;
};

export default getActiveChannelIdx;
