import { EventLabel, FreeLabel, QuickVodLabel, ThumbnailImage, TvingOnlyTag, TvingOriginalTag } from '@components/item/ItemComponent';
import moment from 'moment';
import { useRouter } from 'next/router';
import { searchClickFeedback, targetEpisodeFromProgram, useDeleteList, useLogging, useUserInfo } from '@store/state';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { NewAgeGradeTag } from '@components/content/CommonComponent';
import { brazeEvent_contents } from '@utils/braze';
import Link from 'next/link';
import { logFbaSearchAutoCompleteClick, logFbaSearchDetailClick } from '@utils/firebaseAnalytics';
import { emitSearchResultBandItemClickEvent, emitSearchResultEvent } from '@utils/amplitude/emitEvent/eventHandlers/searchHandler';

const ItemATag = styled.a`
    cursor: pointer;
`;
const ProgramItem = ({
    href,
    idx,
    type,
    bandTitle, // 진입한 띠 명
    bandType, // 진입한 띠 타입
    title,
    subtitle,
    chName,
    chCd,
    cateName,
    cateCd,
    code,
    programCode,
    masterCode,
    contentType,
    thumbnail,
    verticalThumbnail,
    grade,
    gradeCode, // 연령 등급 코드
    ageGrade, // 시청 가능 연령
    episodeGradeCode, // 에피소드 시청 연령코드
    episodeAgeGrade, // 에피소드의 시청 가능 연령
    isAdult,
    isExclusive,
    isOriginal,
    isEvent,
    isQuickVod,
    isParamount, // 파라마운트 콘텐츠 여부
    broadcastStartTime,
    broadcastEndTime,
    insertDate,
    lastPlayTime,
    rating,
    ratio,
    isLazy,
    isEditMode, // 편집모드
    kwauto,
    isSearchLayer, // 실시간 검색 레이어 오픈 여부(모달이라면 타이틀이 빨간색 글씨(kwauto)로 노출해야함)
    isSearchPage, // 검색 페이지 여부
    isFree,
    categoryName1, // 첫번째 카테고리명
    categoryName2, // 두번째 카테고리명차
    frequency,
    itemWidth, // 아이템 width값
    searchKeyword, // 검색결과인 경우 검색어
    searchUniqueKey, // 검색에서 클릭된 경우 유니크 키
    funnel, // 검색진입 지점
    onClick,
    isPayUser,
    fbaParams,
}) => {
    const route = useRouter();
    const [userInfo] = useUserInfo();
    const [{ braze, brazeEvent }] = useLogging();
    const [deleteList, setDeleteList] = useDeleteList(null);
    const [isClicked, setIsClicked] = useState(false);
    const isRank = bandType === 'programRank';
    const isQuickVodBand = bandType === 'quickVod'; // ?

    const infoClassName = isRank ? 'item__info-rank' : '';
    const ratioClassName = ratio ? `item__thumb-${ratio}` : '';

    let isNew = false;
    if (moment().diff(moment(`${insertDate}`, 'YYYYMMDDhhmmss'), 'days') <= 1) {
        isNew = true;
    }

    useEffect(() => {
        setIsClicked(false); // 모드가 변경될 때 선택 지우기
    }, [isEditMode]);

    useEffect(() => {
        const idx = deleteList.indexOf(masterCode);
        if (idx > -1) {
            setIsClicked(true); // 모드가 변경될 때 선택 지우기
        } else {
            setIsClicked(false);
        }
    }, [deleteList]);

    const itemClick = () => {
        if (!isClicked) {
            setDeleteList([...deleteList, masterCode]);
            setIsClicked(true);
        } else {
            const idx = deleteList.indexOf(masterCode);
            const resultList = Object.assign(deleteList);
            if (idx > -1) {
                setDeleteList(null);
                resultList.splice(idx, 1);
                setDeleteList(resultList);
                setIsClicked(false);
            }
        }
    };

    const moveLink = async (e) => {
        if (!isQuickVodBand && (isSearchLayer || isSearchPage)) {
            // 검색결과에서 콘텐츠 클릭 시 검색어 순위 반영을 위한 feedback API 호출
            searchClickFeedback({ mediaCode: programCode, title, type: 'Program', userId: userInfo.userId || '' });

            if (isSearchLayer) {
                const eventData = {
                    keyword: searchKeyword,
                    mediatype: type,
                    channel_name: chName,
                    program_code: programCode,
                    program_name: title,
                    ctgr_name: cateName,
                };

                emitSearchResultEvent(eventData);

                logFbaSearchAutoCompleteClick({
                    profileNo: userInfo?.profileNo,
                    keyword: searchKeyword,
                    subType: 'auto_complete',
                    rank: idx,
                    mediaCode: code,
                    ...fbaParams,
                });
            } else if (isSearchPage) {
                const eventData = {
                    band_name: 'TV 프로그램',
                    band_sequence: 1,
                    contents_sequence: idx + 1,
                    channel_code: chCd || null,
                    channel_name: chName || null,
                    program_code: code,
                    code,
                    program_name: title,
                    ctgr_name: cateName || null,
                    ctgr_code: cateCd || null,
                    keyword: searchKeyword,
                };

                emitSearchResultBandItemClickEvent(eventData);
                logFbaSearchDetailClick({
                    profileNo: userInfo?.profileNo,
                    searchId: searchUniqueKey,
                    keyword: searchKeyword,
                    funnel,
                    bandName: bandTitle,
                    mediaCode: code,
                    rank: idx,
                    ...fbaParams,
                });
            }
        }
        if (bandType === 'originalBasic') {
            e.preventDefault();
            e.stopPropagation();
            onClick?.(e);
            route.push(href);
        } else if (!isEditMode) {
            if (route.pathname === '/player/[slug]' && route.query?.slug?.charAt(0) !== 'C') {
                // 수정일때 링크 동작 안하게.
                // 실시간 재생화면인 경우에는 하단 프로그램 띠에서 상세화면으로 이동되도록 조건 추가
                e.preventDefault();
                e.stopPropagation();

                const data = await targetEpisodeFromProgram({ programCode });
                const { mediaCode } = data || {};
                route.push(`/player/${mediaCode}`);
            } else {
                // 수정모드 아닌경우 재생 화면으로 이동
                // route.push(href);
            }
            brazeEvent_contents({
                braze,
                brazeEvent,
                type,
                genre1: categoryName1 || '',
                genre2: categoryName2 || '',
                contents: title,
                episode_no: frequency || '',
                gate: bandTitle,
            });
            onClick?.(e);
        } else {
            // 수정일때 링크 동작 안하게.
            e.preventDefault();
            e.stopPropagation();
            // 수정모드인 경우 아이템 클릭
            itemClick();
        }
    };
    return (
        <Link scroll href={href} passHref>
            <ItemATag
                className={`${isEditMode && 'active'}`}
                onClick={(e) => moveLink(e)}
                isSearchLayer={isSearchLayer}
                css={
                    isRank &&
                    itemWidth && {
                        width: itemWidth,
                    }
                }
            >
                <div className={`item__thumb ${ratioClassName}`}>
                    <div className="item__tags">
                        {/* 파라마운트 라벨은 포스터 이미지에 번인되어 있습니다. */}
                        {/* 따라서, 파라마운트 콘텐츠가 아닌 경우에만 좌측 상단 라벨을 노출해야 합니다. */}
                        {!isParamount && (
                            <div className="item__tags-left">
                                {/* 이벤트 라벨 */}
                                {isEvent && <EventLabel />}
                                {isFree && !isPayUser && <FreeLabel />}

                                {/* QuickVOD 태그 - Quick VOD 띠인 경우 */}
                                {isQuickVodBand && subtitle !== '' && <QuickVodLabel />}
                            </div>
                        )}

                        {/* 연령 태그 - 프로그램은 19세, 영화는 18세 콘텐츠에 한해 연령등급 표시 */}
                        {/* QuickVod 밴드의 경우 에피소드 등급코드 기준, 그 외 프로그램 등급코드 기준 */}
                        {isQuickVodBand && (episodeGradeCode === 'CPTG0500' || episodeGradeCode === 'CMMG0400') && (
                            <div className="item__tags-right">
                                <NewAgeGradeTag gradeCode={episodeGradeCode} />
                            </div>
                        )}
                        {!isQuickVodBand && (gradeCode === 'CPTG0500' || gradeCode === 'CMMG0400') && (
                            <div className="item__tags-right">
                                <NewAgeGradeTag gradeCode={gradeCode} />
                            </div>
                        )}

                        {/* 티빙 오리지널 태그 */}
                        {isOriginal && <TvingOriginalTag />}

                        {/* 티빙 온리 태그 */}
                        {!isOriginal && isExclusive && <TvingOnlyTag />}
                    </div>

                    {/* 컨텐츠(포스터) */}
                    <ThumbnailImage thumbnail={isQuickVodBand ? verticalThumbnail : thumbnail} title={title} isLazy={isLazy} />
                    {isEditMode && (
                        <div className="thumb_summary">
                            <button className={`btn__check ${isClicked && 'click_on'}`} />
                        </div>
                    )}
                </div>
                {!isRank && (
                    <div className={`item__info ${infoClassName}`}>
                        <p className={`item__title ${!isQuickVodBand && isNew && !lastPlayTime && 'item__title-new'}`}>
                            {isSearchLayer ? <span dangerouslySetInnerHTML={{ __html: kwauto }} /> : title}
                            {!isQuickVodBand && isNew && !lastPlayTime && <span className="item__point-new" />}
                        </p>
                        {isQuickVodBand && <p className="item__subtitle">{subtitle}</p>}
                    </div>
                )}
            </ItemATag>
        </Link>
    );
};

export default ProgramItem;
