type DeviceInfo = {
  isMobile?: boolean;
  isWebview?: boolean;
};

type PathInfoType = 'kbo_' | '';

type PathInfo = {
  pathname: string;
};

export const KBO_EVENT_NAMES = {
  CONTENTS: 'click_kbo_contents',
  MORE_VIEW: 'click_kbo_more_view',
  PLAYER_RANKING: 'click_kbo_player_ranking',
  CATEGORY: 'click_kbo_category',
  DETAIL: 'click_kbo_detail',
  CONTENTS_OTHER: 'click_kbo_contents_other',
} as const;

export const SPORTS_EVENT_NAMES = {
  BRAND: 'click_brand',
  CONTENTS: 'click_contents',
  MORE_VIEW: 'click_more_view',
  PLAYER_RANKING: 'click_player_ranking',
  CATEGORY: 'click_category',
  STATUS: 'click_status',
  CONTENTS_OTHER: 'click_contents_other',
  SEARCH_CONTENTS: 'click_search_contents',
} as const;

export type DeviceType = 'pc' | 'mo' | 'app';

export const getAmplitudePathInfo = ({ pathname }: PathInfo): PathInfoType => {
  // pathname이 '/kbo'로 시작하는지 확인
  if (pathname?.startsWith('/kbo')) {
    return 'kbo_';
  }

  // 그 외의 경우 빈 문자열 반환
  return '';
};

export const getAmplitudeDeviceName = ({ isMobile, isWebview }: DeviceInfo): DeviceType => {
  if (isWebview) return 'app';
  if (isMobile) return 'mo';
  return 'pc';
};
