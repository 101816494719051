let PAGE_HOST = 'qc.tving.com';
let USER_HOST = 'userqc.tving.com';
let BILL_HOST = 'billdev.tving.com';
let SEARCH_HOST = 'search-dev.aws.tving.com';
let API_HOST = 'apiqc.tving.com';
let API_KEY = '1e7952d0917d6aab1f0293a063697610';
let IMG_HOST = 'imagedev.tving.com';
let CHAT_HOST = 'dev-rt-next.tving.com';
let CHAT_ROOM_PREFIX = 'TVINGDEV-';
let CHAT_FROM = 'tving';
let MALL_HOST = 'qcmall.tving.com';
let MALL_API_HOST = 'qcmallapi.tving.com';
//
switch (process.env.REACT_APP_RELEASE) {
    case 'real':
        PAGE_HOST = 'www.tving.com';
        USER_HOST = 'user.tving.com';
        BILL_HOST = 'bill.tving.com';
        API_HOST = 'api.tving.com';
        SEARCH_HOST = 'search-api.tving.com';
        IMG_HOST = 'image.tving.com';
        CHAT_HOST = 'rt-next.tving.com';
        CHAT_ROOM_PREFIX = 'TVING-';
        CHAT_FROM = 'tving';
        MALL_HOST = 'mall.tving.com';
        MALL_API_HOST = 'mallapi.tving.com';
        break;
    case 'qc':
        PAGE_HOST = 'qc.tving.com';
        USER_HOST = 'userqc.tving.com';
        BILL_HOST = 'billqc.tving.com';
        API_HOST = 'apiqc.tving.com';
        SEARCH_HOST = 'search-qc.aws.tving.com';
        IMG_HOST = 'image.tving.com';
        CHAT_HOST = 'rt-next.tving.com';
        CHAT_ROOM_PREFIX = 'TVING-';
        CHAT_FROM = 'tving';
        MALL_HOST = 'qcmall.tving.com';
        MALL_API_HOST = 'qcmallapi.tving.com';
        break;
    case 'qa':
        PAGE_HOST = 'qa.tving.com';
        USER_HOST = 'userqa.tving.com';
        BILL_HOST = 'billqa.tving.com';
        API_HOST = 'apiqa.tving.com';
        SEARCH_HOST = 'search-qa.aws.tving.com';
        IMG_HOST = 'image-qa.tving.com';
        // TODO: 채팅 서버 주소 임시 변경
        // CHAT_HOST = 'rt-next.tving.com';
        CHAT_HOST = 'dev-rt-next.tving.com';
        CHAT_ROOM_PREFIX = 'TVINGDEV-';
        // CHAT_ROOM_PREFIX = 'TVINGDEV-';
        CHAT_FROM = 'tving_dev';
        MALL_HOST = 'devmall.tving.com';
        MALL_API_HOST = 'devmallapi.tving.com';
        break;
    case 'qa-reboot':
        PAGE_HOST = 'qa.tving.com';
        USER_HOST = 'userqa.tving.com';
        BILL_HOST = 'billqa.tving.com';
        API_HOST = 'qa-rebootapi.tving.com';
        API_KEY = '5d031d171294f9d609808c935ec1c42b';
        SEARCH_HOST = 'search-qa.aws.tving.com';
        IMG_HOST = 'image-qa.tving.com';
        CHAT_HOST = 'dev-rt-next.tving.com';
        CHAT_ROOM_PREFIX = 'TVINGDEV-';
        CHAT_FROM = 'tving_dev';
        MALL_HOST = 'devmall.tving.com';
        MALL_API_HOST = 'devmallapi.tving.com';
        break;
    case 'qc-reboot':
        PAGE_HOST = 'qc.tving.com';
        USER_HOST = 'userqc.tving.com';
        BILL_HOST = 'billqc.tving.com';
        API_HOST = 'qc-rebootapi.tving.com';
        // API_KEY = '5d031d171294f9d609808c935ec1c42b';
        SEARCH_HOST = 'search-qc.aws.tving.com';
        IMG_HOST = 'image.tving.com';
        CHAT_HOST = 'rt-next.tving.com';
        CHAT_ROOM_PREFIX = 'TVING-';
        CHAT_FROM = 'tving';
        MALL_HOST = 'qcmall.tving.com';
        MALL_API_HOST = 'qcmallapi.tving.com';
        break;
    case 'dev':
        PAGE_HOST = 'dev.tving.com';
        USER_HOST = 'userdev.tving.com';
        BILL_HOST = 'billdev.tving.com';
        API_HOST = 'apidev.tving.com';
        SEARCH_HOST = 'search-dev.aws.tving.com';
        IMG_HOST = 'imagedev.tving.com';
        CHAT_HOST = 'dev-rt-next.tving.com';
        CHAT_ROOM_PREFIX = 'TVINGDEV-';
        CHAT_FROM = 'tving_dev';
        MALL_HOST = 'devmall.tving.com';
        MALL_API_HOST = 'devmallapi.tving.com';
        break;
    default:
}

export const pageOrigin = `https://${PAGE_HOST}`;
export const userOrigin = `https://${USER_HOST}`;
export const billOrigin = `https://${BILL_HOST}`;
export const searchOrigin = `https://${SEARCH_HOST}`;
export const apiOrigin = `https://${API_HOST}`;
export const apiKey = API_KEY;
export const imgOrigin = `https://${IMG_HOST}`;
export const chatHost = CHAT_HOST;
export const chatRoomPrefix = CHAT_ROOM_PREFIX;
export const chatFrom = CHAT_FROM;
export const mallOrigin = `https://${MALL_HOST}`;
export const mallApiOrigin = `https://${MALL_API_HOST}`;
