import amplitudeEvent from '@tving/utils/src/utils/amplitude/amplitudeEvent';
import getAmplitudeEventMediaType from '@tving/utils/src/utils/amplitude/getAmplitudeEventMediaType';

interface EmitEventProperties {
    type?: string;
    screen_name?: string;
    keyword: string;
    // mediatype?: string;
    channel_code?: string;
    channel_name?: string;
    program_code?: string;
    code?: string;
    program_name?: string;
    ctgr_name?: string;
    ctgr_code?: string;
}

interface SearchResultBandEventProperties {
    band_name: string;
    contents_sequence: number;
    // mediatype: string;
    channel_code: string;
    channel_name: string;
    program_code: string;
    code: string;
    program_name: string;
    ctgr_name: string;
    ctgr_code: string;
}

interface EmitEventHandler<T> {
    (params: T): void;
}

export const emitSearchResultEvent: EmitEventHandler<EmitEventProperties> = (params) => {
    const mediaType = getAmplitudeEventMediaType(params.code);

    amplitudeEvent({
        event: `click_search_keyword${params.type ? `_${params.type}` : ''}_pc`,
        eventProperties: {
            screen_name: 'search',
            keyword: params.keyword,
            mediatype: mediaType,
            channel_code: params.channel_code ?? null,
            channel_name: params.channel_name ?? null,
            program_code: params.program_code ?? null,
            code: params.code ?? null,
            program_name: params.program_name ?? null,
            ctgr_name: params.ctgr_name ?? null,
            ctgr_code: params.ctgr_code ?? null,
        },
    });
};

export const emitSearchResultBandItemClickEvent: EmitEventHandler<SearchResultBandEventProperties> = (params) => {
    amplitudeEvent({
        event: `click_contents_pc`,
        eventProperties: {
            screen_name: 'search',
            ...params,
        },
    });
};
