import httpGet from '@utils/common/httpGet';
import { KboContentsResponse } from '@typings/kbo';
import { KBO_API_URLS } from '@constants/kbo/url';
import { KBO_URL } from '@tving/utils/src/utils/common/apiConfig';
import getKboApiDeviceParams from '@utils/kbo/getKboApiDeviceParams/getKboApiDeviceParams';

const fetchKboContentsBroadcast = async <B>(gameCode: string, inning?: number, isMobileOrWebview?: boolean) => {
    const response = await httpGet<KboContentsResponse<B>>(KBO_URL + KBO_API_URLS.CONTENTS_BROADCAST(gameCode, inning), {
        params: getKboApiDeviceParams(isMobileOrWebview),
    });

    return response;
};
export default fetchKboContentsBroadcast;
