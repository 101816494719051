import styled from '@emotion/styled';

export const StyledAgreeModal = styled.div`
    text-align: center;

    label {
        position: relative;
        display: block;
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-decoration: none;
    }

    .box-textscroll {
        padding-right: 2rem;
        &::-webkit-scrollbar {
            width: 0.25rem;
            border-radius: 3px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #4e4e4e;
        }
        &::-webkit-scrollbar-track {
            background-color: #232323;
        }
    }

    h4 {
        &.popup-title {
            color: #fff;
            font-size: 1.8rem;
            span {
                display: inline-flex;
                align-items: center;
                svg {
                    margin-right: 0.5rem;
                }
            }
            img {
                width: 7.25rem;
                font-size: 0;
                vertical-align: middle;
                padding-bottom: 0.25rem;
            }
            > p {
                margin-top: 1rem;
                color: #a3a3a3;
                font-size: 1.25rem;
                font-weight: normal;
            }
        }
    }
    .popup {
        &-title {
            color: #fff;
            font-size: 2.5rem;
        }
        &-close {
            position: absolute;
            top: 1rem;
            right: 0.5rem;
            padding: 0;
            width: 4.5rem;
            height: 4.5rem;
        }
        &-layer {
            &-wrap {
                position: fixed;
                top: 50%;
                left: 50%;
                z-index: 10;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                .btn-checks-wrap {
                    input {
                        + .popup-title {
                            font-size: 1.8rem;
                            color: #fff;
                        }
                        &:checked {
                            + .popup-title {
                                color: #dedede;
                            }
                        }
                    }
                }
                .btn-checks-wrap {
                    label {
                        &.icon-arrow {
                            background-position: 100% 50%;
                            background-size: 5%;
                        }
                    }
                }
            }
        }
        &-box {
            position: relative;
            width: 45rem;
            height: auto;
            padding: 2rem 2rem 3rem;
            box-sizing: border-box;
            border-radius: 3px;
            background-color: #191919;
            text-align: center;
            .head-title {
                p {
                    margin: 0.666rem auto 0;
                    width: 30rem;
                    font-size: 1.5rem;
                }
            }
            .popup__input-wrap {
                margin-top: 4rem;
                ul {
                    li {
                        margin-left: 0.667rem;
                        width: 100%;
                        &:first-of-type {
                            margin-left: 0;
                        }
                        label {
                            input {
                                padding: 1.4rem 1.667rem 1.7rem;
                                width: 100%;
                                height: auto;
                                box-sizing: border-box;
                                background-color: #000;
                                text-align: center;
                                font-size: 2rem;
                                border: solid 1px #000;
                                &:focus {
                                    border: solid 1px #808080;
                                }
                            }
                        }
                    }
                }
                &.popup__num-pw {
                    ul {
                        padding: 0 8.167rem;
                    }
                }
                &.popup__num-account {
                    margin-top: 3rem;
                    ul {
                        padding: 0 4.75rem;
                        li {
                            label {
                                input {
                                    padding: 1.083rem 0 1.083rem 1.333rem;
                                    font-size: 1.333rem;
                                    text-align: left;
                                }
                            }
                            &.btn-wrap {
                                margin-top: 0;
                                margin-left: 1.333rem;
                                width: 10.5rem;
                                .btn-long {
                                    height: 4.167rem;
                                    line-height: 0;
                                }
                            }
                        }
                    }
                }
            }
            .profile-photo-container .item .img_wrap {
                width: 100%;
                height: auto;
            }
            .box-black {
                margin-top: 2.5rem;
                width: 100%;
                .btn-checks-wrap {
                    margin: 0 auto;
                }
                .btn-checks {
                    .btn-checks-wrap {
                        margin-top: 1.5rem;
                        border-top: solid 0.083rem #191919;
                        label {
                            margin-top: 1.5rem;
                            font-weight: normal;
                        }
                    }
                    input {
                        + label {
                            text-align: left;
                            p {
                                color: #4e4e4e;
                                font-size: 1.083rem;
                                line-height: 1.583rem;
                                &.box-textscroll {
                                    height: 4.75rem;
                                }
                            }
                            div {
                                th,
                                td {
                                    color: #4e4e4e;
                                    font-size: 1.083rem;
                                    line-height: 1.583rem;
                                }
                                &.box-textscroll {
                                    height: 4.75rem;
                                }
                            }
                        }
                    }
                }
            }

            .input-info {
                margin: 2rem auto 0;
                text-align: left;
                font-size: 1.083rem;
            }
            .btn-wrap {
                margin-top: 3rem;
                .btn-middle {
                    width: 22.5rem;
                    padding: 1rem 0;
                    min-height: 4.167rem;
                    font-size: 1.333rem;
                }
            }
        }
        &-bottom {
            &-reset {
                margin-top: 3.333rem;
                .btn-noborder {
                    color: #808080;
                    text-decoration: underline;
                    font-size: 1.25rem;
                }
            }
        }
    }

    .img-select {
        .popup-box {
            padding: 4.167rem 0 8rem;
            width: 66.666rem;
            height: 72.5rem;
            max-height: 848px;
        }
        .swiper-img-select-wrap {
            position: relative;
            margin-top: 2.5rem;
        }
        .profile-photo {
            position: relative;
            margin: 0 auto;
            width: 55rem;
            overflow: hidden;
            + .profile-photo {
                margin-top: 2.5rem;
            }
            h4 {
                margin-bottom: 1rem;
                font-size: 1.5rem;
                font-weight: normal;
                text-align: left;
            }
            .profile-photo-container {
                justify-content: flex-start;
                .item {
                    margin-left: 1.25rem;
                    width: 10rem !important;
                    height: 10rem;
                    &:first-of-type {
                        margin-left: 0;
                    }
                }
            }
            &.swiper-img-select {
                + .swiper-button-wrap {
                    display: block;
                }
            }
        }
        .swiper-button-wrap {
            display: none;
            .swiper-button-next,
            .swiper-button-prev {
                margin-top: 0;
                top: calc(50% + 1.5rem);
                transform: translateY(-50%);
                width: 4.167rem;
                background-size: 100% auto;
            }
            .swiper-button-next {
                background-image: url('../images/icon-right.svg');
            }
            .swiper-button-prev {
                background-image: url('../images/icon-left.svg');
            }
        }
    }

    input {
        position: absolute;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
        line-height: 110%;
        appearance: none;
        + label {
            padding-left: 2.15rem;
            cursor: pointer;
            color: #6e6e6e;
            line-height: 2rem;
            letter-spacing: normal;
            font-size: 1.25rem;
            &.popup-title {
                color: #a3a3a3;
            }
            a {
                color: inherit;
                line-height: inherit;
                font-size: inherit;
            }
            &::before {
                content: ' ';
                display: block;
                position: absolute;
                top: 0.25rem;
                left: 0;
                width: 1.5rem;
                height: 1.5rem;
                box-sizing: border-box;
                border-radius: 100%;
                border-width: 0px;
                border-color: #535353;
                border-style: solid;
                background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath data-name='사각형 4301' style='fill:none' d='M0 0h18v18H0z'/%3E%3Cpath data-name='패스 2257' d='m-4526.787-12166.283 4.244 4.245 7.756-7.755' transform='translate(4529.787 12174.865)' style='stroke:%238d8d8d;stroke-width:2px;fill:none'/%3E%3C/svg%3E%0A");
                background-position: 75% 50%;
                background-repeat: no-repeat;
                background-size: 80% auto;
                background-color: #535353;
                text-align: center;
            }
            textarea {
                display: none;
            }
        }
        &:checked {
            & + label {
                color: #dedede;
                &::before {
                    border-color: #ff1744;
                    background-color: #ff1744;
                    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath data-name='사각형 4301' style='fill:none' d='M0 0h18v18H0z'/%3E%3Cpath data-name='패스 2257' d='m-4526.787-12166.283 4.244 4.245 7.756-7.755' transform='translate(4529.787 12174.865)' style='stroke:%23ffffff;stroke-width:2px;fill:none'/%3E%3C/svg%3E%0A");
                }
                textarea {
                    display: block;
                }
            }
        }
    }

    /* 체크박스-장보수신 동의 */
    .btn-checks,
    .withdrawal_checks {
        &-wrap {
            margin: 2rem auto 3rem;
            ul {
                margin-top: 1rem;
                li {
                    margin-top: 1rem;
                    ul {
                        margin-left: 3rem;
                    }
                }
            }
        }
        a {
            display: block;
        }
        input {
            position: absolute;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
            line-height: 110%;
            appearance: none;
            + label {
                padding-left: 2.15rem;
                cursor: pointer;
                color: #6e6e6e;
                line-height: 2rem;
                letter-spacing: normal;
                font-size: 1.25rem;
                &.popup-title {
                    color: #a3a3a3;
                }
                a {
                    color: inherit;
                    line-height: inherit;
                    font-size: inherit;
                }
                &::before {
                    content: ' ';
                    display: block;
                    position: absolute;
                    top: 0.25rem;
                    left: 0;
                    width: 1.5rem;
                    height: 1.5rem;
                    box-sizing: border-box;
                    border-radius: 100%;
                    border-width: 0px;
                    border-color: #535353;
                    border-style: solid;
                    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath data-name='사각형 4301' style='fill:none' d='M0 0h18v18H0z'/%3E%3Cpath data-name='패스 2257' d='m-4526.787-12166.283 4.244 4.245 7.756-7.755' transform='translate(4529.787 12174.865)' style='stroke:%238d8d8d;stroke-width:2px;fill:none'/%3E%3C/svg%3E%0A");
                    background-position: 75% 50%;
                    background-repeat: no-repeat;
                    background-size: 80% auto;
                    background-color: #535353;
                    text-align: center;
                }
                textarea {
                    display: none;
                }
            }
            &:checked {
                & + label {
                    color: #dedede;
                    &::before {
                        border-color: #ff1744;
                        background-color: #ff1744;
                        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath data-name='사각형 4301' style='fill:none' d='M0 0h18v18H0z'/%3E%3Cpath data-name='패스 2257' d='m-4526.787-12166.283 4.244 4.245 7.756-7.755' transform='translate(4529.787 12174.865)' style='stroke:%23ffffff;stroke-width:2px;fill:none'/%3E%3C/svg%3E%0A");
                    }
                    textarea {
                        display: block;
                    }
                }
            }
        }
        &-small {
            margin-top: 1rem;
            a {
                display: block;
                width: 100%;
                border: none;
            }
            input {
                + label {
                    color: #6e6e6e;
                    &::before {
                        background-image: url('../images/icon-check-unline.svg');
                        background-color: transparent;
                    }
                }
                &:checked {
                    + label {
                        color: #a3a3a3;
                        &::before {
                            background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath data-name='사각형 4301' style='fill:none' d='M0 0h18v18H0z'/%3E%3Cpath data-name='패스 2257' d='m-4526.787-12166.283 4.244 4.245 7.756-7.755' transform='translate(4529.787 12174.865)' style='stroke:%23ff153c;stroke-width:2px;fill:none'/%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }
        }
        &-line {
            input {
                + label {
                    color: #6e6e6e;
                    &::before {
                        background-image: none;
                        background-color: transparent;
                        border-width: 1px;
                        border-color: #6e6e6e;
                    }
                }
                &:checked {
                    + label {
                        color: #a3a3a3;
                        &::before {
                            background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18'%3E%3Cpath data-name='사각형 4301' style='fill:none' d='M0 0h18v18H0z'/%3E%3Cpath data-name='패스 2257' d='m-4526.787-12166.283 4.244 4.245 7.756-7.755' transform='translate(4529.787 12174.865)' style='stroke:%23000000;stroke-width:2px;fill:none'/%3E%3C/svg%3E%0A");
                            background-color: #fff;
                            border-color: #fff;
                        }
                    }
                }
            }
        }
        &-noline {
            input {
                + label {
                    &::before {
                        content: '';
                        display: inline-block;
                        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='none' d='M0 0H20V20H0z'/%3E%3Cpath fill='none' stroke='%23535353' stroke-width='2px' d='M-5223.029 3299.476l4.5 5.224 10.045-8.9' transform='translate(5226.176 -3291.302)'/%3E%3C/svg%3E%0A");
                        background-size: 100% auto;
                        background-color: transparent;
                        vertical-align: bottom;
                    }
                }
                &:checked {
                    + label {
                        &::before {
                            background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='none' d='M0 0H20V20H0z'/%3E%3Cpath fill='none' stroke='%23ff1744' stroke-width='2px' d='M-5223.029 3299.476l4.5 5.224 10.045-8.9' transform='translate(5226.176 -3291.302)'/%3E%3C/svg%3E%0A");
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
`;
export const StyledAgreeSubInfo = styled.p`
    margin: 2rem 0 3rem;
    font-size: 1.083rem;
    line-height: 1.46;
    text-align: left;
    color: #6e6e6e;
`;

export const StyledAgreeConfirmButton = styled.button`
    width: 22.5rem;
    margin: 0 8.75rem 0;
    padding: 1rem 10rem 1.167rem;
    border-radius: 4px;
    background-color: #dedede;
    font-size: 1.333rem;
    font-weight: 700;
    line-height: 1.13;
    text-align: center;
    color: #000;
    white-space: nowrap;
`;
