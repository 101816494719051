import React, { useEffect, useRef } from 'react';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import { SendbirdOpenChat } from '@sendbird/chat/openChannel';
import { User } from '@sendbird/chat';
import useChannelConnectionWithSendbird from '@tving/utils/src/hooks/common/chat/useChannelConnectionWithSendbird';
import useChatUIWithSendbird from '@tving/utils/src/hooks/common/chat/useChatUIWithSendbird';
import ChatCollection from '@components/chat/ChatCollection';
import { useChatConfig } from '@tving/utils/src/contexts/ChatContext';
import ChatFormBox from '@components/chat/ChatFormBox';
import PinnedMessageCell from '@components/chat/ChatMessageCell/PinnedMessageCell';
import Scrollbars from 'react-custom-scrollbars-2';
import { isWebview } from '@utils/device/device-detect';
import usePcPlayer from '@store/usePcPlayer';
import { shallow } from 'zustand/shallow';
import { useRouter } from 'next/router';
import IconKboLogo from '@img/chat/icon_kbo_logo.svg';
import TvingSpinner from '@tving/utils/src/utils/tving-spinner/TvingSpinner';

type Props = {
    sendbirdChat: SendbirdOpenChat | null;
    user: User | null;
};

const ChatWrap = ({ sendbirdChat, user }: Props) => {
    const router = useRouter();
    const { channelUrl, canReceiveMessage, pinnedMessage, isLoading, isFrozen, isInputFocus, setError, clearError, textareaRef, loadingComp } =
        useChatConfig();
    // const inputContainerRef = useRef<HTMLInputElement>(null);
    const { isFullScreen: isFullscreen } = usePcPlayer(
        (state) => ({
            isFullScreen: state.isFullScreen,
            setFullScreen: state.setFullScreen,
        }),
        shallow,
    );

    const {
        channel,
        messageCollection,
        getLatestMessages,
        getPreviousMessages,
        sendMessage,
        deleteMyMessage,
        reportMessage,
        increaseMetaCounterHandler,
    } = useChannelConnectionWithSendbird({
        sendbirdChat,
        channelUrl,
        canReceiveMessage,
    });
    const { isBottom, messageContainerRef, prevPositionRef, messageListTopRef, messageListBottomRef } = useChatUIWithSendbird({
        messageCollection,
        getPreviousMessages,
    });
    const scrollbarRef = useRef<Scrollbars>(null);

    const getPreviousMessagesHandler = () => {
        void getPreviousMessages();
    };

    const hasMessageCollection = !isLoading && messageCollection.length > 0;

    useEffect(() => {
        const isKbo = router.pathname.includes('kbo');
        if (!isFullscreen && isKbo) {
            channel?.enter();
            void getLatestMessages();
        }
    }, [isFullscreen]);

    const isKboContents = router.pathname.includes('/kbo/contents');
    const isSports = router.pathname.includes('/sports');

    return (
        // <div className={tempCnx('w-full h-full', 'bg-[green]')}>
        <div className={tempCnx('flex flex-col justify-end relative w-full h-full max-h-full')}>
            {(isLoading || !hasMessageCollection) &&
                (isKboContents || isSports ? (
                    loadingComp ? (
                        <div className="flex-center absolute top-[5rem] left-1/2 w-full aspect-[1/1] -translate-x-1/2">{loadingComp}</div>
                    ) : (
                        <div className={tempCnx('absolute top-[5rem] flex-center flex-col w-full pointer-events-none')}>
                            <IconKboLogo width="10rem" height="10rem" />
                        </div>
                    )
                ) : (
                    <div className={tempCnx('flex-center absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2')}>
                        <TvingSpinner delay={0} />
                    </div>
                ))}
            {pinnedMessage && (!isWebview || !isInputFocus) ? <PinnedMessageCell item={pinnedMessage} /> : null}
            {hasMessageCollection ? (
                <ChatCollection
                    getLatestMessages={getLatestMessages}
                    getPreviousMessages={getPreviousMessages}
                    deleteMyMessage={deleteMyMessage}
                    reportMessage={reportMessage}
                    isInputFocus={isInputFocus}
                    isBottom={isBottom}
                    messageCollection={messageCollection}
                    messageContainerRef={messageContainerRef}
                    scrollbarRef={scrollbarRef}
                    prevPositionRef={prevPositionRef}
                    messageListTopRef={messageListTopRef}
                    messageListBottomRef={messageListBottomRef}
                />
            ) : (
                <div>{/* 나중에 메시지 없을 때 작업 해야할 공간 */}</div>
            )}

            <ChatFormBox
                isInputFocus={isInputFocus}
                sendMessage={sendMessage}
                increaseMetaCounterHandler={increaseMetaCounterHandler}
                messageContainerRef={messageContainerRef}
                scrollbarRef={scrollbarRef}
            />
        </div>
    );
};

export default ChatWrap;
