import { overlay } from 'overlay-kit';

import generateMergedConfig from './helper/generateMergedConfig';
import generateRandomId from './helper/generateRandomId';
import { type OverlayKitIntegrationOptions } from './types';
import useOverlayKitIntegration from './useOverlayKitIntegration';

export type OverlayControllerProps = {
  overlayId: string;
  isOpen: boolean;
  close: () => void;
  unmount: () => void;
};

export type OverlayAsyncControllerProps<TResult> = Omit<OverlayControllerProps, 'close'> & {
  close: (param: TResult) => void;
};

export type OverlayControllerComponent = React.FC<OverlayControllerProps>;
export type OverlayAsyncControllerComponent<TResult> = React.FC<OverlayAsyncControllerProps<TResult>>;

const open = (controller: OverlayControllerComponent, config?: Omit<OverlayKitIntegrationOptions, 'destroyOnCallerUnmount'>) => {
  const { closeInstance, destroyInstance, addInstance } = useOverlayKitIntegration.getState();
  const mergedConfig = generateMergedConfig(config);

  const overlayId = overlay.open((props) => {
    const close = () => closeInstance(overlayId);
    const unmount = () => destroyInstance(overlayId);

    return controller({
      ...props,
      close,
      unmount,
    });
  }, config);

  addInstance(overlayId, mergedConfig);

  return overlayId;
};

/**
 * 실행 결과를 반환하는 Overlay를 엽니다.
 *
 * `Route Change`, `Caller Unmount`으로 파괴 시 Promise가 resolve되지 않을 수 있어, `<OverlayAsyncFallbackResolver/>`를 Wrapper로 함께 사용해야 합니다.
 */
const openAsync = async <TResult>(
  controller: OverlayAsyncControllerComponent<TResult>,
  config?: Omit<OverlayKitIntegrationOptions, 'destroyOnCallerUnmount'>,
) => {
  const { closeInstance, destroyInstance, addInstance } = useOverlayKitIntegration.getState();
  const overlayId = config?.overlayId || generateRandomId();

  const mergeConfig = generateMergedConfig({
    ...config,
    overlayId,
  });

  addInstance(overlayId, mergeConfig);

  return overlay.openAsync<TResult>((props) => {
    let resolved = false;
    const close = (param: TResult) => {
      if (resolved) {
        return;
      }

      resolved = true;
      closeInstance(overlayId);
      return props.close(param);
    };
    const unmount = () => destroyInstance(overlayId);

    return controller({
      ...props,
      close,
      unmount,
    });
  }, mergeConfig);
};

const close = (overlayId: string) => {
  const { closeInstance } = useOverlayKitIntegration.getState();
  closeInstance(overlayId);
};

const umount = (overlayId: string) => {
  const { destroyInstance } = useOverlayKitIntegration.getState();
  destroyInstance(overlayId);
};

const overlayKit = {
  open,
  openAsync,
  close,
  umount,
} as const;

export default overlayKit;
