import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isFourteenDigits from './isFourteenDigits';

dayjs.extend(duration);

type isBroadcastingType = {
    broadcastStartTime: number;
    broadcastEndTime: number;
};

/**
 * 제공된 방송 시작 시간 / 종료 시간을 기반으로 현재 방송이 진행 중인지 여부를 판단합니다.
 * @param {number} broadcastStartTime - 방송 시작 시간 (형식: YYYYMMDDHHmmss).
 * @param {number} broadcastEndTime - 방송 종료 시간 (형식: YYYYMMDDHHmmss).
 * @returns {boolean} - 방송이 진행 중인 경우 true를 반환하고, 그렇지 않은 경우 false를 반환합니다.
 */

const isBroadcasting = ({ broadcastStartTime, broadcastEndTime }: isBroadcastingType): boolean => {
    if (!isFourteenDigits(broadcastStartTime) || !isFourteenDigits(broadcastEndTime)) {
        return false;
    }
    const now = dayjs();
    const startTime = dayjs(`${broadcastStartTime}`, 'YYYYMMDDHHmmss');
    const endTime = dayjs(`${broadcastEndTime}`, 'YYYYMMDDHHmmss');

    return now.isAfter(startTime) && now.isBefore(endTime);
};

export default isBroadcasting;
