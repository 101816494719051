import { ReactNode } from 'react';

import tempCnx from '../../utils/tailwind/tempCnx';

export type TableHeader = {
  // field name of the data
  key: string;
  // column name
  value: ReactNode;
  // column style(tailwindCSS)
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
};

export type TableProps<T> = {
  headers: TableHeader[];
  values: T[];

  // table style(tailwindCSS)
  className?: string;
  // tableHeader style(tailwindCSS)
  tableHeaderClassName?: string;
  // tableRow style(tailwindCSS)
  tableRowClassName?: string;
};

const Table = <T,>({ headers, values, className, tableHeaderClassName, tableRowClassName }: TableProps<T>) => (
  <div className={tempCnx(`w-full flex flex-col`, className ?? '')}>
    {/* Table header */}
    <div className={tempCnx(`flex-center w-full`, tableHeaderClassName ?? '')}>
      {headers.map((header) => (
        <div key={header.key} className={tempCnx(header.className ?? '', header.headerClassName ?? '')}>
          {header.value}
        </div>
      ))}
    </div>
    {/* Table body */}
    <div className="w-full">
      {values.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key -- index is used for key
        <div key={index} className={tempCnx(`flex-center w-full`, tableRowClassName ?? '')}>
          {headers
            .filter((header) => value[header.key] !== undefined)
            .map((header) => (
              <div key={header.key} className={tempCnx(header.className ?? '', header.bodyClassName ?? '')}>
                {value[header.key]}
              </div>
            ))}
        </div>
      ))}
    </div>
  </div>
);

export default Table;
