const INIFINITY = 987_654_321;

const scrollToBottomOfContainer = (container: HTMLElement | undefined | null) => {
  if (!container) {
    return;
  }

  container.scrollTop = container.scrollHeight + INIFINITY;
};

export default scrollToBottomOfContainer;
