import { useEffect, useLayoutEffect, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

const defaultOptions = {
    root: undefined,
    rootMargin: '0px 500px 500px 0px',
    threshold: 0,
};

export const useViewportSpy = (elementRef, options = defaultOptions) => {
    const [isVisible, setIsVisible] = useState();

    useIsomorphicLayoutEffect(() => {
        const observer = new window.IntersectionObserver(
            entries =>
                entries.forEach(item => {
                    const nextValue = item.isIntersecting;
                    setIsVisible(nextValue);
                }),
            options,
        );
        if (elementRef.current) {
            observer.observe(elementRef.current);
        }
        return () => {
            if (elementRef.current) {
                observer.disconnect(elementRef.current);
            }
        };
    }, [elementRef]);

    return isVisible;
};
