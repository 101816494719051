type MobileWebGnbType = {
    screenName: string;
    moreViewValue?: string | null;
};

const getMobileWebGnbEventProperties = ({ screenName, moreViewValue }: MobileWebGnbType) => {
    return {
        screen_name: screenName,
        more_view_value: moreViewValue || null,
    };
};

export default getMobileWebGnbEventProperties;
