import Text from '../../../atom/text/Text';

type TextItem = {
  tag?: React.ElementType;
  title: string;
  className?: string;
  tabIndex?: number;
};

type ContentTextListProps = {
  className: string;
  items: TextItem[];
  tabIndex?: number;
};

const ContentTextList = ({ className, items, tabIndex }: ContentTextListProps) => (
  <div className={className}>
    {items.map((text, index) => (
      <Text key={index} tag={text.tag} title={text.title} className={text.className} tabIndex={tabIndex} />
    ))}
  </div>
);

export default ContentTextList;
