import React, { useEffect } from 'react';
import useCrossPlatform from '@utils/crossPlatform/react/useCrossPlatform';
import { CrossPlatformType } from '@utils/crossPlatform';

const useSafeAreaInsets = () => {
    const crossPlatformController = useCrossPlatform();
    const [insets, setInsets] = React.useState({ top: 0, bottom: 0, left: 0, right: 0 });

    useEffect(() => {
        const handleChangeContentInset = (event) => {
            document.body.style.setProperty('--content-inset-top', `${event.detail.top}px`);
            document.body.style.setProperty('--content-inset-bottom', `${event.detail.bottom}px`);
            document.body.style.setProperty('--content-inset-right', `${event.detail.right}px`);
            document.body.style.setProperty('--content-inset-left', `${event.detail.left}px`);

            setInsets(event.detail);
        };
        window.addEventListener('onChangeContentInset', handleChangeContentInset);
        return () => {
            window.removeEventListener('onChangeContentInset', handleChangeContentInset);
        };
    }, []);

    useEffect(() => {
        // ONLY Android
        if (crossPlatformController.getCrossPlatformType() !== CrossPlatformType.ANDROID) {
            return () => null;
        }

        const handleSafeAreaEvent = (event) => {
            document.body.style.setProperty('--safe-area-inset-top', `${event.detail.top}px`);
            document.body.style.setProperty('--safe-area-inset-bottom', `${event.detail.bottom}px`);
            document.body.style.setProperty('--safe-area-inset-right', `${event.detail.right}px`);
            document.body.style.setProperty('--safe-area-inset-left', `${event.detail.left}px`);

            setInsets(event.detail);
        };
        const handleChangeOrientation = () => {
            crossPlatformController.getSafeArea();
        };

        crossPlatformController.getSafeArea();

        window.addEventListener('onSafeArea', handleSafeAreaEvent);
        window.addEventListener('onChangeOrientation', handleChangeOrientation);
        return () => {
            window.removeEventListener('onSafeArea', handleSafeAreaEvent);
            window.removeEventListener('onChangeOrientation', handleChangeOrientation);
        };
    }, [crossPlatformController]);

    return [insets, setInsets];
};

export default useSafeAreaInsets;
