import { CSSProperties, forwardRef } from 'react';

import Poster, { PosterProps } from '../../atom/poster/Poster';
import Text from '../../atom/text/Text';
import tempCnx from '../../utils/tailwind/tempCnx';
import { LabelsProps } from '../labels/Labels';

type ShortsItemProps = {
  className?: string;
  poster: PosterProps;
  labels: LabelsProps;
  text: {
    className: string;
    items: { tag?: React.ElementType<any>; title: string; className?: string; tabindex?: number }[];
  };
  tabIndex?: number;
  style?: CSSProperties;
  ratio?: number;
};

const ShortsItem = forwardRef<HTMLDivElement, ShortsItemProps>(({ className, poster, tabIndex, style, text }, ref) => {
  return (
    <div className={tempCnx('shorts-item-wrapper relative', className)} tabIndex={tabIndex} style={{ ...style }} ref={ref}>
      <Poster {...poster} className={tempCnx('w-full h-full', poster.className)} />
      <div className={text.className}>
        {text.items.map((item) => {
          return <Text key={item.title} tag={item.tag} title={item.title} className={item.className} tabIndex={tabIndex} />;
        })}
      </div>
    </div>
  );
});

export default ShortsItem;
