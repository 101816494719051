import Modal, { ModalView } from '@components/Modal';
import { useEffect, useState } from 'react';
import { useCookie, useLocalStorage } from 'react-use';
import TvingLogo from '@img/tving-logo-w.svg';
import { useRouter } from 'next/router';
import { moveOverAgeCheck } from '@utils/common/UserApi';
import { isMobile } from '@utils/device/device-detect';
import { StyledAgreeConfirmButton, StyledAgreeModal, StyledAgreeSubInfo } from './styles.js';

export const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,.88)',
        zIndex: '200',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        maxHeight: '100vh',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent',
        border: '0',
        padding: '0',
        borderRadius: '0',
    },
};

const ModalAgree = () => {
    const route = useRouter();
    const [isModal, setIsModal] = useState(false);
    const [legalConfirmCookie] = useCookie('LEGAL_CONFIRM_YN'); // 14세 이상 법적 동의 필요 여부

    // LEGAL_CONFIRM_YN 쿠키값 체크 하여 모달 표시
    useEffect(() => {
        if (legalConfirmCookie === 'Y') {
            setIsModal(true);
        }
    }, [route]);

    const checkAgreeModal = () => {
        const agreeAgeCheck = document.querySelector('#agree-only-legal');
        if (agreeAgeCheck.checked) {
            moveOverAgeCheck({ overAgeYn: 'Y', isMobile });
        } else {
            moveOverAgeCheck({ overAgeYn: 'N', isMobile });
            /// moveOverAgeCheckPage();
            // route.push('/info/confirm-legal');
        }
        setIsModal(false);
    };

    if (isModal) {
        return (
            <ModalView
                isOpen
                // onAfterOpen={afterOpenModal}
                // onRequestClose={closeModal} // 모달 외 클릭 시에도 닫히지 않도록 처리
                style={customStyles}
                contentLabel="동의확인"
            >
                <StyledAgreeModal className="popup-layer-wrap layout__flex">
                    <div className="popup-box">
                        <div className="box-wrap">
                            <h4 className="popup-title">
                                <span>
                                    <TvingLogo /> 이용을 위하여
                                </span>
                                <br />
                                고객님의 연령 확인이 필요합니다.
                                <p>
                                    만 14세 미만 아동은 개인정보 보호를 위하여 <br />
                                    법정대리인 동의 후 서비스를 이용하실 수 있습니다.
                                </p>
                            </h4>

                            <div className="box-black">
                                <ul className="btn-checks-wrap">
                                    <li className="btn-checks">
                                        <input type="checkbox" id="agree-only-legal" />
                                        <label htmlFor="agree-only-legal">
                                            연령 (만 14세 이상) 확인
                                            <p>본인은 만 14세 이상입니다.</p>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <StyledAgreeSubInfo>
                                ※ 동의 후에는 해당 서비스의 이용약관 및 개인정보처리방침에 따라 정보가 관리됩니다. <br />※ 만14세 미만 아동은
                                법정대리인의 동의 후 서비스이용이 가능합니다.
                                <br />※ 회원가입 신청 후 5일 이내에 법정대리인(보호자)의 동의를 받지 않는 경우, 만 14세 미만의 회원은 지체 없이 탈퇴
                                처리됩니다.
                            </StyledAgreeSubInfo>
                            <StyledAgreeConfirmButton onClick={() => checkAgreeModal()}>확인</StyledAgreeConfirmButton>
                        </div>
                    </div>
                </StyledAgreeModal>
            </ModalView>
        );
    }
    return null;
};
export default ModalAgree;
