import { useState } from 'react';

import { shallow } from 'zustand/shallow';

import { AspectRatio, Poster } from '@tving/ui';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';

import useLazyImage from '@hooks/useLazyImage';
import useWebpStore from '@stores/useWebpStore';

const WALLPAPER_IMAGES = {
    MOBILE: {
        WEBP: '/img/sports/kbo/home/kbo-poster-mobile-250304.webp',
        FALLBACK: '/img/sports/kbo/home/kbo-poster-mobile-250304.jpg',
    },
    DESKTOP: {
        WEBP: '/img/sports/kbo/home/kbo-poster-desktop-250304.webp',
        FALLBACK: '/img/sports/kbo/home/kbo-poster-desktop-250304.jpg',
    },
};

const TopWallpaper = ({ device }) => {
    const { isMobileWeb, isTablet } = device;

    const { isSupportsWebP, isWebPSupportDetectionDone } = useWebpStore(
        (state) => ({ isSupportsWebP: state.isSupportsWebP, isWebPSupportDetectionDone: state.isWebPSupportDetectionDone }),
        shallow,
    );
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleLoadImage = () => {
        setIsImageLoaded(true);
    };

    const wallpaperSrc = isWebPSupportDetectionDone
        ? WALLPAPER_IMAGES[isMobileWeb && !isTablet ? 'MOBILE' : 'DESKTOP'][isSupportsWebP ? 'WEBP' : 'FALLBACK']
        : undefined;

    const [imgRef, dataSrc] = useLazyImage({
        src: wallpaperSrc,
    });

    return (
        <AspectRatio
            ratio={isMobileWeb && !isTablet ? 750 / 670 : 1920 / 440}
            className={tempCnx('absolute top-0 right-0 left-0  w-screen pointer-events-none')}
        >
            <Poster
                imgRef={imgRef}
                dataSrc={dataSrc}
                className={tempCnx('w-full h-full object-cover', isImageLoaded ? 'visible' : 'invisible')}
                onLoad={handleLoadImage}
                alt=""
            />
        </AspectRatio>
    );
};

export default TopWallpaper;
