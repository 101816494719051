import React, { useCallback, useEffect, useState } from 'react';

import { ConnectionState, MetaCounter, ReportCategory, SendbirdError } from '@sendbird/chat';
import { AdminMessage, BaseMessage, MessageListParams, UserMessage } from '@sendbird/chat/message';
import { OpenChannel, OpenChannelHandler, SendbirdOpenChat } from '@sendbird/chat/openChannel';
import dayjs from 'dayjs';

import { CHAT_ERRORS, ERROR_DISPLAY_TYPE, useChatConfig } from '../../../contexts/ChatContext';
import useChatStatusStore from '../../../stores/useChatStatusStore';
import { formatTime, msToTime } from '../../../utils/dateTime';

export type CrossPlatformChangePlayerFeatureOptions = {
  isDisabledGameListPlayerButton: boolean; // 타구장 경기 버튼
  isDisabledContinuousClipPlayerButton: boolean; // 클립 다음영상 버튼
  isDisabledClipPlaylistPlayerButton: boolean; // 클립 재생목록 버튼
  isDisabledSwitchChannelButton: boolean; // 채널 변경 버튼
  isActiveTvingTalk: boolean;
};

export type CrossPlatformChangePlayerModeOptions = {
  isFull: boolean;
};

declare global {
  interface CustomEventMap {
    onChangePlayerFeature: CustomEvent<CrossPlatformChangePlayerFeatureOptions>;
    onChangePlayerMode: CustomEvent<CrossPlatformChangePlayerModeOptions>;
  }

  interface Window {
    onChangePlayerFeature: (event: CrossPlatformChangePlayerFeatureOptions) => void;
    onChangePlayerMode: (event: CrossPlatformChangePlayerModeOptions) => void;

    // adds definition to Document, but you can do the same with HTMLElement
    addEventListener<K extends keyof CustomEventMap>(type: K, listener: (this: Document, ev: CustomEventMap[K]) => void): void;
    dispatchEvent<K extends keyof CustomEventMap>(ev: CustomEventMap[K]): void;
    removeEventListener<K extends keyof CustomEventMap>(type: K, listener: (this: Document, ev: CustomEventMap[K]) => void): void;
  }
}

type UseChannelConnectionWithSendbirdProps = {
  sendbirdChat: SendbirdOpenChat | null;
  channelUrl: string | null;
  canReceiveMessage: boolean;
};

export type FetchLatestMessagesHandler = () => Promise<BaseMessage[][]>;
export type DeleteMyMessageHandler = ({ message }: { message: BaseMessage }) => Promise<void>;
export type ReportMessageHandler = (message: UserMessage, reportType?: ReportCategory, description?: string) => Promise<void>;
export type SendMessageHandler = (message: string, { onFailed }: { onFailed?: (error?: Error) => void }) => void;

type OpenChannelHandlerProps = {
  setMessageCollection: React.Dispatch<React.SetStateAction<BaseMessage[][]>>;
  setMetaCounters: (metaCounter: MetaCounter) => void;
};

export const MESSAGE_CONFIG = {
  MAX_CHUNK_SIZE: 8, // 브라우저에서 가지고 있을 최대 메시지 청크 수
  INITIAL_CHUNK_SIZE: 2, // 초기 로딩 시 가지고 있는 최대 메시지 청크 수
  MESSAGES_PER_CHUNK: 30, // 한 청크에 들어가 있을 최대 메시지 수
};

type MessageConfig = typeof MESSAGE_CONFIG;

const appendMessageToChunks = (maxChunkSize: number, messagePerChuck: number) => (messageCollection: BaseMessage[][], message: BaseMessage) => {
  const lastSection = messageCollection[messageCollection.length - 1];

  if (!lastSection) {
    return [[message]];
  }

  return lastSection.length >= messagePerChuck
    ? [...messageCollection.slice(-maxChunkSize), [message]]
    : [...messageCollection.slice(0, messageCollection.length - 1).slice(-maxChunkSize), [...lastSection, message]];
};

const prependMessageToChunks = (maxMessageChuckSize: number) => (prev: BaseMessage[][], messages: BaseMessage[]) => {
  return [messages, ...prev].slice(0, maxMessageChuckSize);
};

// const appendMessageToChuck = (maxChunkSize: number, messagePerChuck: number) => (messageCollection: BaseMessage[][], message: BaseMessage) => {
//   const lastSection = messageCollection[messageCollection.length - 1];
//   console.log('#tving-talk - lastsection length', { messageCollection, lastSection });
//   return lastSection.length >= messagePerChuck
//     ? [...messageCollection.slice(-maxChunkSize), [message]]
//     : [...messageCollection.slice(0, messageCollection.length - 1).slice(-maxChunkSize), [...lastSection, message]];
// };

const prependMessageToCurrentChunk = prependMessageToChunks(MESSAGE_CONFIG.MAX_CHUNK_SIZE);
/**
 * 1차원 배열로 오는 메시지를 2차원 섹션과 배열로 구성된 값으로 변환해 줍니다.
 */
const appendMessageToCurrentChunk = appendMessageToChunks(MESSAGE_CONFIG.MAX_CHUNK_SIZE, MESSAGE_CONFIG.MESSAGES_PER_CHUNK);

const initializeOpenChannelHandlers = ({ setMessageCollection, setMetaCounters }: OpenChannelHandlerProps) => {
  return new OpenChannelHandler({
    onMessageReceived: (channel, message) => {
      console.log('[#tving-talk - channel - messages received]', { channel, message });
      // setMessages((prev) => [...prev, message as UserMessage]);

      console.log('[#tving-talk - channel - messages received]', { channel, message });

      if (message instanceof UserMessage || message instanceof AdminMessage) {
        setMessageCollection((prev) => appendMessageToCurrentChunk(prev, message));
      }
    },
    onMessageUpdated: (channel, message) => {
      console.log('[#tving-talk - channel - messages updated]', { channel, message });
    },
    onMessageDeleted: (channel, messageId) => {
      console.log('[#tving-talk - channel - messages deleted]', { channel, messageId });
    },
    onMetaCounterUpdated: (channel, metaCounter) => {
      console.log('[#tving-talk - channel - meta counter updated]', { channel, metaCounter });
      setMetaCounters(metaCounter);
    },
    onChannelFrozen: (channel) => {
      console.log('[#tving-talk - channel - frozen]', { channel });
    },
    onChannelUnfrozen: (channel) => {
      console.log('[#tving-talk - channel - unfrozen]', { channel });
    },
  });
};

const mergeObjectsWithMaxValues = <T extends Record<string, number>>(x: T, y: T) => {
  return Object.fromEntries(Object.entries(x).map(([key, value]) => [key, Math.max(value, y[key] ?? value)]));
};

const validateMessageWithinHours = (messages: BaseMessage[], hours: number) => {
  const now = dayjs();
  return messages.filter((message) => {
    return now.diff(dayjs(message.createdAt), 'hour') <= hours;
  });
};

type LatestMessagesConfig = Pick<MessageConfig, 'INITIAL_CHUNK_SIZE' | 'MESSAGES_PER_CHUNK'>;
const getLatestMessagesByTimestamp = async (channel: OpenChannel, messageConfig: LatestMessagesConfig = MESSAGE_CONFIG) => {
  return await channel.getMessagesByTimestamp(Date.now(), {
    prevResultSize: messageConfig.INITIAL_CHUNK_SIZE * messageConfig.MESSAGES_PER_CHUNK,
    nextResultSize: 0,
  });
};

const getMessageSectionByMessageId = (params: MessageListParams) => async (channel: OpenChannel, messageId: number) => {
  return await channel.getMessagesByMessageId(messageId, params);
};

const getPreviousMessagesSectionByMessageId = getMessageSectionByMessageId({ prevResultSize: MESSAGE_CONFIG.MESSAGES_PER_CHUNK, nextResultSize: 0 });
const getNextMessagesSectionByMessageId = getMessageSectionByMessageId({ prevResultSize: 0, nextResultSize: MESSAGE_CONFIG.MESSAGES_PER_CHUNK });

const useChannelConnectionWithSendbird = ({ sendbirdChat, channelUrl }: UseChannelConnectionWithSendbirdProps) => {
  const [channel, setChannel] = useState<OpenChannel | null>(null);
  const [messageCollection, setMessageCollection] = useState<BaseMessage[][]>([]);
  const {
    metaCounters,
    canReceiveMessage,
    setMetaCounters,
    setMetaCountersHandler,
    setPinnedMessage,
    setIsLoading,
    setError,
    setIsFrozen,
    isReconnected,
    setIsReconnected,
  } = useChatConfig();
  const { setChannelStatus } = useChatStatusStore();

  const getLatestMessages = useCallback(async () => {
    if (!channel) {
      return [] as BaseMessage[][];
    }

    const messages = await getLatestMessagesByTimestamp(channel);
    console.log('#tving-talk - channel - getLatestMessages', { channel, messages });
    const validMessage = validateMessageWithinHours(messages, 5);

    const messageChunks = validMessage.reduce((acc, cur) => appendMessageToCurrentChunk(acc, cur), [[]] as BaseMessage[][]);
    setMessageCollection(messageChunks);
    return messageChunks;
  }, [channel]);

  const getPreviousMessages = async () => {
    const headMessageId = messageCollection[0]?.[0]?.messageId;
    console.log('[#tving-talk - channel - getPreviousMessages]', { channel, headMessageId });

    if (!channel || !headMessageId) {
      return null;
    }
    const previousMessages = await getPreviousMessagesSectionByMessageId(channel, headMessageId);
    console.log('[#tving-talk - channel - getPreviousMessages]', { previousMessages });
    const validPreviousMessages = validateMessageWithinHours(previousMessages, 5);

    setMessageCollection((prev) => prependMessageToCurrentChunk(prev, validPreviousMessages || []));

    return previousMessages;
  };

  const sendMessage: SendMessageHandler = (message: string, { onFailed }) => {
    if (!channel) {
      return;
    }

    const result = channel
      .sendUserMessage({ message })
      .onPending((message) => {})
      .onSucceeded((message) => {
        if (message instanceof UserMessage) {
          setMessageCollection((prev) => appendMessageToCurrentChunk(prev, message));
        }
      })
      .onFailed(async (error) => {
        console.table({ error });
        try {
          const myMutedInfo = await channel?.getMyMutedInfo();
          const remainingDuration = myMutedInfo.remainingDuration ?? 0;

          if (error instanceof SendbirdError) {
            onFailed?.(error);

            if (remainingDuration > 0) {
              const adjustedDuration = remainingDuration + 60_000; // 60초(60000ms)
              const mutedTimeInfo = msToTime(adjustedDuration);

              const remainingDurationToString = formatTime(
                mutedTimeInfo,
                mutedTimeInfo.hours > 0 ? (mutedTimeInfo.minutes !== 0 ? 'h시간 mm분' : 'h시간') : 'm분',
              );

              setError({
                ...error,
                errorMessage: `회원님의 티빙톡 입력이 제한되었으며, 일정 시간 후 자동 해제됩니다. \n(신고 5회 누적 시 10분 / 부적절한 언어 사용 시 5시간)\n회원님의 입력 제한은 약 ${remainingDurationToString} 후 해제됩니다.`,
                displayType: ERROR_DISPLAY_TYPE.MODAL,
              });
              return;
            }

            if (CHAT_ERRORS[error.code]) {
              setError(CHAT_ERRORS[error.code]);
              return;
            }

            setError(CHAT_ERRORS.MESSAGE_SEND_FAILURE);
          }
        } catch (e) {
          console.error('메시지 전송 실패 처리 중 오류 발생:', e);
          setError(CHAT_ERRORS.MESSAGE_SEND_FAILURE);
        }
      });
  };

  const deleteMyMessage: DeleteMyMessageHandler = async ({ message }: { message: BaseMessage }) => {
    if (!channel) {
      return;
    }

    await channel.deleteMessage(message);
  };

  const reportMessage: ReportMessageHandler = async (message: UserMessage, reportType: ReportCategory = ReportCategory.SPAM, description = '') => {
    if (!channel) {
      return;
    }

    await channel.reportMessage(message, reportType, description);
  };

  const increaseMetaCounterHandler = async (key: string, value: number = 1) => {
    if (!channel) {
      return;
    }

    try {
      const result = await channel.increaseMetaCounters({ [key]: value });
      // MEMO: 사용성을 위해 heart interaction 컴포넌트 내부 변수로 내 상태 가지고 있기
      setMetaCountersHandler({ ...result, HEART_COUNT: Math.max(metaCounters.HEART_COUNT + 1, result.HEART_COUNT) });
    } catch (error) {
      setMetaCountersHandler({ ...metaCounters, HEART_COUNT: metaCounters.HEART_COUNT + 1 });
    }
  };

  useEffect(() => {
    if (!sendbirdChat || !channelUrl) return;

    (async () => {
      try {
        sendbirdChat.openChannel.addOpenChannelHandler(
          channelUrl,
          new OpenChannelHandler({
            onMessageReceived: (channel, message) => {
              if (message instanceof UserMessage || message instanceof AdminMessage) {
                setMessageCollection((prev) => appendMessageToCurrentChunk(prev, message));
              }
            },
            onMessageUpdated: (channel, message) => {
              console.log('[#tving-talk - channel - messages updated]', { channel, message });
            },
            onMessageDeleted: (channel, messageId) => {
              console.log('[#tving-talk - channel - messages deleted]', { channel, messageId });
              setMessageCollection((prev) => prev.map((messageSection) => messageSection.filter((message) => message.messageId !== messageId)));
            },
            onMetaCounterUpdated: (channel, metaCounter) => {
              console.log('[#tving-talk - channel - meta counter updated]', { channel, metaCounter });
              setMetaCountersHandler(metaCounter);
            },
            onChannelFrozen: (channel) => {
              console.log('[#tving-talk - channel - frozen]', { channel });
              setIsFrozen(true);
              setChannelStatus({ isFrozen: true });
            },
            onChannelUnfrozen: (channel) => {
              console.log('[#tving-talk - channel - unfrozen]', { channel });
              setIsFrozen(false);
              setChannelStatus({ isFrozen: false });
            },
            onPinnedMessageUpdated: (channel) => {
              console.log('#tving-talk - channel - pinned message updated', { channel, pinnedMessage: channel.lastPinnedMessage });
              setPinnedMessage(() => channel.lastPinnedMessage);
            },
          }),
        );

        const openChannel = await sendbirdChat.openChannel.getChannelWithoutCache(channelUrl);

        setChannel(openChannel);
      } catch (error) {
        if (error instanceof SendbirdError) {
          console.log('sendbird channel information load error');
          console.error(error);
          setError(CHAT_ERRORS[error.code] ?? { code: 400, errorMessage: '알 수 없는 오류가 발생했습니다.', shouldThrowOutside: false });
        }
      }
    })();

    return () => {
      setPinnedMessage(null);
      setMessageCollection([]);
      setIsLoading(true);
      sendbirdChat.openChannel.removeOpenChannelHandler(channelUrl);
    };
  }, [sendbirdChat, channelUrl, canReceiveMessage, setMetaCountersHandler, setIsFrozen, setPinnedMessage, setChannelStatus, setIsLoading]);
  // }, [sendbirdChat, channelUrl, channel, canReceiveMessage]);

  useEffect(() => {
    if (!channel) {
      return;
    }

    const setChannelData = async () => {
      try {
        await channel.enter();

        // const messages = await getLatestMessagesByTimestamp(channel);
        // const messageChunks = messages.reduce((acc, cur) => appendMessageToCurrentChunk(acc, cur), [[]] as BaseMessage[][]);
        const messageChunks = canReceiveMessage ? await getLatestMessages() : messageCollection;

        const metacounters = await channel.getAllMetaCounters();

        setPinnedMessage(channel.lastPinnedMessage);
        setMetaCounters(metacounters);
        setIsFrozen(channel.isFrozen);
        setChannelStatus({ isFrozen: channel.isFrozen });
        setIsLoading(false);
      } catch (error) {
        if (error instanceof SendbirdError) {
          console.table({ error_title: '#tving-talk', error });
          setError(CHAT_ERRORS[error.code] ?? { code: 400, errorMessage: '알 수 없는 오류가 발생했습니다.', shouldThrowOutside: false });
        }
      }
    };

    setChannelData();

    return () => {
      if (sendbirdChat && channel) {
        try {
          if (sendbirdChat.connectionState === ConnectionState.OPEN) {
            channel.exit();
          }
        } catch (error) {
          console.error(error);
          console.table(error);
        }
      }
    };
  }, [channel]);

  useEffect(() => {
    const onChangePlayerModeHandler = (event: CustomEvent<CrossPlatformChangePlayerModeOptions>): void => {
      console.log('#talk - useChannelConnectionWithSendbird - onChangePlayerModeHandler', { channel, event });
      if (!channel) {
        return;
      }

      const { isFull } = event.detail;

      if (isFull) {
        channel.exit();
        return;
      }

      const setChannelData = async (): Promise<void> => {
        await channel.enter();
        await getLatestMessages();
        const metacounters = await channel.getAllMetaCounters();
        setMetaCounters(metacounters);
        setPinnedMessage(channel.lastPinnedMessage);
        setIsFrozen(channel.isFrozen);
        setChannelStatus({ isFrozen: channel.isFrozen });
        setIsLoading(false);
      };

      setChannelData();
    };

    window.addEventListener('onChangePlayerMode', onChangePlayerModeHandler);

    return () => {
      window.removeEventListener('onChangePlayerMode', onChangePlayerModeHandler);
    };
  }, [channel, getLatestMessages, setIsFrozen, setIsLoading, setMetaCounters, setPinnedMessage]);

  useEffect(() => {
    if (!isReconnected) {
      return;
    }

    const updateChannelData = async () => {
      if (!channel) {
        return;
      }
      await getLatestMessages();
      const metacounters = await channel.getAllMetaCounters();
      setMetaCounters(metacounters);
      setPinnedMessage(channel.lastPinnedMessage);
      setIsFrozen(channel.isFrozen);
      setChannelStatus({ isFrozen: channel.isFrozen });
      setIsLoading(false);
      setIsReconnected(false);
    };

    updateChannelData();
  }, [channel, getLatestMessages, isReconnected, setChannelStatus, setIsFrozen, setIsLoading, setIsReconnected, setMetaCounters, setPinnedMessage]);

  return {
    channel,
    messageCollection,
    getLatestMessages,
    getPreviousMessages,
    sendMessage,
    deleteMyMessage,
    reportMessage,
    setMetaCountersHandler,
    increaseMetaCounterHandler,
  };
};

export default useChannelConnectionWithSendbird;
