import { useListThemeKeyList, useLiveRecommendedList, useUserInfo } from '@store/state';
import { imgOrigin } from '@utils/apiConfig';
import { urlString } from '@utils/common/String';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useExposureItemList, useHomeExposures } from '.';
import { useAbortSWR, useAbortSWRInfinite } from './plugins';

const apiContentType = apiUrl => {
    let mediaType = '';
    // Live
    if (apiUrl && apiUrl.includes('/v2/media/lives?')) {
        if (apiUrl.includes('channelType=CPCS0300')) {
            mediaType = 'tvingtv';
        } else if (apiUrl.includes('channelType=CPCS0400')) {
            mediaType = 'tvinglive';
        } else {
            mediaType = 'live';
        }
    }
    // Episodes.
    if (apiUrl && (apiUrl.includes('v2/media/episodes?') || apiUrl.includes('v2/media/paramount/episodes?'))) {
        mediaType = 'episode';
    }
    // Movies.
    if (apiUrl && (apiUrl.includes('v2/media/movies?') || apiUrl.includes('v2/media/paramount/movies?'))) {
        mediaType = 'movie';
    }
    // Original
    if (apiUrl && apiUrl.includes('v2/media/originals?')) {
        mediaType = 'original';
    }

    return mediaType;
};

/**
 * ListPage를 랜더링 하기 위한 Hook.
 * @return {object} targetData
 */
export const useListPageData = () => {
    const router = useRouter();
    const slug = router?.query?.slug?.[0];
    const slugCode = router?.query?.slug?.[1];
    const [userInfo] = useUserInfo();
    const isParamount = router?.asPath?.startsWith('/paramount') || false;

    const targetData = {
        title: '',
        thumbnail: null,
        list: [],
    };

    // router의 slug값을 가져와 분기.
    if (slug === 'live') {
        const { data, isLoading } = useLiveRecommendedList();
        targetData.title = '실시간 인기 LIVE';
        targetData.list.push({
            itemType: 'liveRank',
            prevListData: data,
            apiUrl: isLoading
                ? null
                : '/v2/media/lives?cacheType=main&pageNo=1&pageSize=20&order=rating&adult=all&free=all&guest=all&scope=all&channelType=CPCS0100,CPCS0400',
        });
    }
    if (slug === 'tvingtv') {
        targetData.title = '인기 정주행 채널';
        targetData.list.push({
            itemType: 'tvingtvRank',
            apiUrl: '/v2/media/lives?cacheType=main&pageNo=1&pageSize=20&order=rating&adult=all&free=all&guest=all&scope=all&channelType=CPCS0300',
        });
    }
    if (slugCode === 'yesterday') {
        const yesterday = moment().subtract(1, 'days').format('YYYYMMDD');
        targetData.title = '어제 놓친 프로그램';
        targetData.list.push({
            itemType: 'programBasic',
            apiUrl: `/v2/media/episodes?cacheType=main&pageNo=1&pageSize=24&order=viewDay&adult=all&free=all&guest=all&scope=all&lastFrequency=y&personal=N&broadStartDate=${yesterday}&broadEndDate=${yesterday}`,
        });
    } else if (slug === 'program' && !isParamount) {
        targetData.title = '시리즈 전체보기';
        targetData.list.push({
            itemType: 'programBasic',
            apiUrl: '/v2/media/episodes?cacheType=main&pageNo=1&pageSize=24&order=viewDay&adult=all&free=all&guest=all&scope=all&lastFrequency=y&personal=N',
        });
    } else if (slug === 'program' && isParamount) {
        targetData.title = '파라마운트+ 프로그램 전체보기';
        targetData.list.push({
            itemType: 'programBasic',
            /// v2/media/paramount/movies
            apiUrl: '/v2/media/paramount/episodes?cacheType=main&pageNo=1&pageSize=24&order=viewDay&adult=all&free=all&guest=all&scope=all&lastFrequency=y&personal=N',
        });
    } else if (slug === 'movie' && slugCode === 'tvod') {
        targetData.title = '영화 전체보기';
        targetData.list.push({
            itemType: 'movieBasic',
            apiUrl: '/v2/media/movies?pageNo=1&pageSize=24&order=viewDay&free=all&adult=all&guest=all&scope=all&personal=N&notProductPackageCode=2610061,2610161,261062',
        });
    } else if (slug === 'movie' && slugCode === 'svod') {
        targetData.title = '영화 전체보기';
        targetData.list.push({
            itemType: 'movieBasic',
            apiUrl: '/v2/media/movies?pageNo=1&pageSize=24&order=viewDay&free=all&adult=all&guest=all&scope=all&personal=N&productPackageCode=2610061,2610161,261062',
        });
    } else if (slug === 'movie' && !isParamount) {
        targetData.title = '영화 전체보기';
        targetData.list.push({
            itemType: 'movieBasic',
            apiUrl: '/v2/media/movies?pageNo=1&pageSize=24&order=viewDay&free=all&adult=all&guest=all&scope=all&personal=N',
        });
    } else if (slug === 'movie' && isParamount) {
        targetData.title = '파라마운트+ 영화 전체보기';
        targetData.list.push({
            itemType: 'movieBasic',
            apiUrl: '/v2/media/paramount/movies?pageNo=1&pageSize=24&order=viewDay&free=all&adult=all&guest=all&scope=all&personal=N&productPackageCode=2610061,2610161,2610062',
        });
    } else if (slug === 'highlight') {
        // 각 띠의 code를 가져와 구성
        const exposureData = useHomeExposures();
        if (!exposureData.isLoading) {
            const object = exposureData.data?.body?.result || {};
            for (const property in object) {
                const array = object[property];
                const result = Array.isArray(array) ? array.filter(d => d.api_param_pc?.includes(slugCode)) : [];
                const item = result.length > 0 && result[0];

                // 1번만 Expo 참조
                if (item) {
                    targetData.title = item.expose_nm;
                    if (targetData.list.length === 0) {
                        targetData.list.push({
                            itemType: item.expose_type,
                            apiUrl: item.api_param_pc,
                        });
                    }
                }
            }

            // 참조되는 값이 없을때는 기본셋팅
            if (targetData.list.length === 0) {
                targetData.list.push({
                    title: '',
                    isNotTargetExposure: true,
                    itemType: 'programBasic',
                    apiUrl: `/v2/operator/highlights?positionKey=${slugCode}&pageNo=1&pageSize=20`,
                });
            }
        }
        // targetData.title
        console.log('targetData', targetData);
    } else if (slug === 'theme') {
        // const data = useExposureItemList({
        //     apiUrl: `/v2/operator/theme/${slugCode}?&pocCode=POCD0400&pageNo=1&pageSize=10&themeType=T&status=Y&cacheTime=5`,
        // });

        const data = isNaN(Number(slugCode))
            ? useListThemeKeyList({ key: slugCode })
            : useExposureItemList({
                  apiUrl: `/v2/operator/theme/${slugCode}?&pocCode=POCD0400&pageNo=1&pageSize=10&themeType=T&status=Y&cacheTime=5`,
              });

        if (data.data?.body?.result?.sections) {
            const { title, theme_bottom_text, sections, body_image, list_image, body_image_link_url, body_image_link_move_type } =
                data.data?.body?.result;
            targetData.title = title;
            targetData.bodyThumbnail = body_image ? imgOrigin + body_image : '';
            targetData.bodyLinkUrl = body_image_link_url || '';
            targetData.bodyLinkMoveType = body_image_link_move_type || '';
            targetData.bodyMobileThumbnail = list_image ? imgOrigin + list_image : '';
            targetData.bottomText = theme_bottom_text;
            sections.map(section => {
                targetData.list.push({
                    title: section.title,
                    itemType: 'programBasic',
                    apiUrl: `/v2/operator/theme/${section.theme_seq}/${section.section_seq}?pocCode=POCD400&pageNo=1&pageSize=150&themeType=T&status=Y`,
                });
            });
        }
    } else if (slug === 'curation') {
        // 큐레이션 데이터 추출.
        const apiUrl = `/v2/media/movie/curation/${slugCode}?`;
        const { data } = useExposureTestItemList({ apiUrl });

        if (data.data && data.data[0]) {
            const { pcwebimg_url } = data.data[0]?.[0] || {};
            const { title, curation_description } = data.data[0]?.bands?.[0] || {};
            targetData.title = title;
            targetData.bottomText = curation_description;
            targetData.thumbnail = pcwebimg_url;
            targetData.list.push({
                itemType: 'movieBasic',
                apiUrl: `/v2/media/movie/curation/${slugCode}?`,
            });
        }
    } else if (slug === 'originalandonly' || slug === 'original' || slug === 'only') {
        let original = null;
        let only = null;

        if (slug.includes('original')) {
            original = true;
        }

        if (slug.includes('only')) {
            only = true;
        }

        // URL로 언제든지 리스트 접근 가능하므로 API 데이터로 셋팅 필요
        const data = useExposureItemList({
            apiUrl: `/v2/media/originals?&pocCode=POCD0400&pageNo=1&pageSize=20&status=Y&cacheTime=5&adult=${router?.query?.adult || ''}&order=${
                router?.query?.order
            }${original ? '&originalYn=Y' : ''}${only ? '&exclusiveYn=Y' : ''}`,
        });

        if (data.data?.body?.contents) {
            targetData.list.push({
                itemType: 'originalBasic',
                apiUrl: `/v2/media/originals?&pocCode=POCD0400&pageNo=1&pageSize=20&status=Y&cacheTime=5&adult=${router?.query?.adult || ''}&order=${
                    router?.query?.order
                }${original ? '&originalYn=Y' : ''}${only ? '&exclusiveYn=Y' : ''}`,
            });
        }

        // 리스트 타이틀 - CMS 입력 타이틀로 표기되도록 처리
        const exposureData = useHomeExposures();
        if (!exposureData.isLoading) {
            const object = exposureData.data?.body?.result || {};
            // eslint-disable-next-line guard-for-in
            for (const property in object) {
                const array = object[property];

                let result = [];
                if (Array.isArray(array)) {
                    const originalBandList = array.filter(d => d.api_param_pc?.includes('media/band/originals')) || [];
                    switch (slug) {
                        case 'originalandonly':
                            result = originalBandList.filter(
                                d => d.api_param_pc?.includes('originalYn=Y') && d.api_param_pc?.includes('exclusiveYn=Y'),
                            );
                            break;
                        case 'original':
                            result = originalBandList.filter(
                                d => d.api_param_pc?.includes('originalYn=Y') && !d.api_param_pc?.includes('exclusiveYn=Y'),
                            );
                            break;
                        case 'only':
                            result = originalBandList.filter(
                                d => !d.api_param_pc?.includes('originalYn=Y') && d.api_param_pc?.includes('exclusiveYn=Y'),
                            );
                            break;
                        default:
                            break;
                    }
                }

                const item = result.length > 0 && result[0];
                if (item) {
                    targetData.title = item.expose_nm;
                    break;
                }
            }
            // 홈 노출되지 않는 오리지널 띠의 경우 API 지정 타이틀 사용
            if (targetData.title === '' && data.data?.body?.contents) {
                targetData.title = data.data?.body?.title || '';
            }
        }
    } else if (slug?.includes('EXCD')) {
        // 각 띠의 code를 가져와 구성
        const exposureData = useHomeExposures();
        if (!exposureData.isLoading) {
            const object = exposureData.data?.body?.result || {};
            for (const property in object) {
                const array = object[property];
                const result = Array.isArray(array) ? array.filter(d => d.expose_cd === slug) : [];
                const item = result.length > 0 && result[0];
                if (item) {
                    targetData.title = item.expose_nm;
                    targetData.list.push({
                        itemType: item.expose_type,
                        apiUrl: item.api_param_pc,
                    });
                }
            }
        }
    }
    return targetData;
};

export const useExposureTestItemList = ({ apiUrl = null }) => {
    let apiCall = apiUrl || null;
    let prevActiveCode = null;
    let prevValue = null;
    let selectHookArr = [];
    let isOrderView = false;
    const selects = [];

    // selectBox 를 위한 Hook Array.
    // Live
    if (apiContentType(apiUrl) === 'tvingtv') {
        selectHookArr = [useVodGenreList, useVodChannelList];
        isOrderView = true;
    }
    // Episodes.
    if (apiContentType(apiUrl) === 'episode') {
        selectHookArr = [useVodGenreList, useVodGenreDetailList, useVodChannelList];
        isOrderView = true;
    }
    // Movies.
    if (apiContentType(apiUrl) === 'movie') {
        apiCall += '&personal=N';
        apiCall = apiCall.replace('&adult=N', '');
        apiCall = apiCall.replace('&personal=N', ''); // .....
        selectHookArr = [useMovieGenreList];
        isOrderView = true;
    }
    // Original
    if (apiContentType(apiUrl) === 'original') {
        isOrderView = true;
    }

    // 훅을 생성하면서 바인딩.
    selectHookArr.forEach(useSelect => {
        const {
            code,
            setSelect: setCode,
            apiUrl: _apiUrl,
            resultArr: _selectData,
            activeCode: _activeCode,
            activeName: _activeName,
        } = useSelect({
            apiUrl: apiCall,
            prevActiveCode,
            prevValue,
        });
        prevActiveCode = _activeCode;
        prevValue = _activeName;
        apiCall = _apiUrl;

        // Selects에 selectData push.
        selects.push([
            _selectData.map((item, _idx) => {
                return {
                    idx: _idx,
                    selected: code === item.code,
                    value: item.code,
                    label: item.name,
                };
            }),
            setCode,
            code,
        ]);
    });

    // 인기/최신 정렬
    let order;
    let setOrder;

    // 오더링 필요한 경우에만.
    if (isOrderView) {
        // Order Hook 으로 반영
        const { apiUrl: _apiUrl, order: _order, setOrder: _setOrder } = useOrderList({ apiUrl: apiCall });
        apiCall = _apiUrl;
        order = _order;
        setOrder = _setOrder;
    }

    const listData = useAbortSWRInfinite(apiCall, {
        params: {},
        withCredentials: true,
    });

    return { data: listData, selectData: selects, order, setOrder };
};

/**
 * Select 상태 변화에 따른 Router 설정을 위한 Hook.
 * @param {String} code - 상태 구분 값.
 * @param {String} query - router.query 구분 값.
 * @return {String|Function|Function} code, setCode, pushQuery
 */
const useSelectWithRoute = ({ code: _code = '', query = 'order' }) => {
    const router = useRouter();
    const [code, setCode] = useState(String(router.query[query] || _code));

    // 라우터 추가 및 상태 변경
    const pushQuery = _value => {
        const obj = {};
        obj[query] = _value;

        const queryObj = Object.assign(router.query, obj);
        if (query === 'genre') {
            delete queryObj.genreDetail;
        }
        if (!_value) {
            delete queryObj[query];
        }
        // 라우터에 Query Push.
        router.push({
            // pathname: router.route,
            pathname: router.asPath.split('?')[0],
            query: queryObj,
        });
        // 상태 변경
        setCode(_value);
    };

    // router의 Query가 변동되면 상태를 업데이트.
    useEffect(() => {
        // 상태 변경
        const _code = String(router.query[query] || '');
        setCode(_code);
    }, [router.query[query]]);

    return [code, setCode, pushQuery];
};

/**
 * 인기,최신 정렬을 위한 Hook
 * @param {String} apiUrl - API URL
 * @return {String|Function|String} order, setOrder, apiUrl
 */
export const useOrderList = ({ apiUrl }) => {
    const [code, _, setSelect] = useSelectWithRoute({
        code: '',
        query: 'order',
    });
    // orderType 선택 시 Proxy 구성.
    const setOrderType = _value => {
        if (apiUrl && _value === 'viewDay') {
            _value = apiUrl.includes('v2/media/lives?') ? 'rating' : 'viewDay';
        }
        if (apiUrl && _value === 'viewHour') {
            _value = apiUrl.includes('v2/media/originals?') ? 'new' : 'viewHour';
        }
        setSelect(_value);
    };
    let _code = code;

    // API URL Params 변경
    if (apiUrl) {
        const apiObj = urlString.parse(apiUrl);
        // 기본 order type 없을때 인기순 정렬
        if (_code === '') {
            _code = apiUrl.includes('v2/media/lives?') ? 'rating' : 'viewDay';
        }
        apiObj.order = _code;
        apiUrl = urlString.stringify(apiObj);
    }
    return { order: _code, setOrder: setOrderType, apiUrl };
};

/**
 * 채널 정렬을 위한 Hook
 * @param {String} apiUrl - API URL
 * @return {String|Function|String|Array} code, setSelect, apiUrl, resultArr
 */
export const useVodChannelList = ({ apiUrl }) => {
    const [code, _, setSelect] = useSelectWithRoute({
        code: '',
        query: 'channel',
    });
    const data = useAbortSWR([`/v2/operator/highlights?positionKey=AND_VOD_CHNLLIST`], {
        params: {
            cacheTime: 5,
        },
    });

    // Select 생성
    if (!data.isLoading && data.data) {
        const arr = data.data?.body?.result || [];
        const resultArr = [
            {
                code: '',
                name: '채널 전체',
            },
            ...arr.map(item => {
                return {
                    code: item.content_code,
                    name: item.mapping_contents_name,
                };
            }),
        ];

        const selectItemCode = resultArr.filter(d => d.code === code)[0]?.code;

        // API URL Params 변경
        if (selectItemCode) {
            apiUrl += `&channelCode=${selectItemCode}`;
            if (apiUrl.includes('channelType=CPCS0300')) {
                apiUrl += `&zapping=Y`;
            }
        }

        return { code, setSelect, apiUrl, resultArr };
    }
    return { code, setSelect, apiUrl: null, resultArr: [] };
};

/**
 * 장르 정렬을 위한 Hook
 * @param {String} apiUrl - API URL
 * @return {String|Function|String|Array} code, setSelect, apiUrl, resultArr
 */
export const useVodGenreList = ({ apiUrl }) => {
    const [code, _, setSelect] = useSelectWithRoute({
        code: '',
        query: 'genre',
    });
    const data = useAbortSWR([`/v2/media/programcats?&pageNo=1&pageSize=10&order=name`], {
        params: {},
    });

    // Select 생성
    if (!data.isLoading && data.data) {
        const arr = data.data?.body?.result || [];
        const resultArr = [
            {
                code: '',
                name: '장르 전체',
            },
            ...arr.map(item => {
                return {
                    code: item.cate_cd,
                    name: item.cate_nm,
                };
            }),
        ];

        const selectItem = resultArr.filter(d => d.code === code)[0];
        // API URL Params 변경
        if (selectItem?.code) {
            if (apiUrl.includes('/v2/media/lives')) {
                apiUrl += `&programMultiCategoryCode=${selectItem.code}`;
            } else {
                apiUrl += `&categoryCode=${selectItem.code}`;
            }
        }
        console.log('#mono', 'apiUrl', apiUrl);
        return { code, setSelect, apiUrl, resultArr, activeCode: selectItem?.code, activeName: selectItem?.name };
    }
    return { code, setSelect, apiUrl: null, resultArr: [] };
};

/**
 * 장르 상세 정렬을 위한 Hook
 * @param {String} apiUrl - API URL
 * @return {String|Function|String|Array} code, setSelect, apiUrl, resultArr
 */
export const useVodGenreDetailList = ({ apiUrl, prevActiveCode, prevValue }) => {
    const router = useRouter();
    const [code, setCode, setSelect] = useSelectWithRoute({
        code: '',
        query: 'genreDetail',
    });
    const data = useAbortSWR([`/v2/media/programcatsdtl?&pageNo=1&pageSize=10&order=name`], {
        params: {},
    });

    // 장르 router가 변경되면 장르 상세 상태 값 리셋.
    useEffect(() => {
        // setCode('');
    }, [router.query.genre]);

    // Select 생성
    if (!data.isLoading && data.data) {
        let arr = data.data?.body?.result || [];
        arr = arr.filter(item => prevActiveCode === item.parent_cd);

        const resultArr = [
            ...arr.map(item => {
                return {
                    code: item.cate_cd,
                    name: item.cate_nm,
                    genreCode: item.parent_cd,
                };
            }),
        ];
        if (resultArr.length > 0) {
            resultArr.unshift({
                code: '',
                name: `${prevValue} 전체`,
            });
        }
        const selectItemCode = resultArr.filter(d => d.code === code)[0]?.code;
        // API URL Params 변경
        if (selectItemCode) {
            apiUrl = `${apiUrl.replace('/episodes', `/episodes/category/${prevActiveCode}/${selectItemCode}`)}&genreCode=${selectItemCode}`;
        }
        return { code, setSelect, apiUrl, resultArr };
    }
    return { code, setSelect, apiUrl: null, resultArr: [] };
};

/**
 * 영화 장르 정렬을 위한 Hook
 * @param {String} apiUrl - API URL
 * @return {String|Function|String|Array} code, setSelect, apiUrl, resultArr
 */
export const useMovieGenreList = ({ apiUrl }) => {
    const [code, _, setSelect] = useSelectWithRoute({
        code: '',
        query: 'genre',
    });
    const data = useAbortSWR([`/v2/media/movie/categories?pageNo=1&pageSize=10&order=new&free=y&adult=n&guest=all&scope=all`], {
        params: {},
    });

    if (!data.isLoading && data.data) {
        const arr = data.data?.body?.result || [];
        const resultArr = [
            {
                code: '',
                name: '장르 전체',
            },
            ...arr.map(item => {
                return {
                    code: item.category_code || 'diversity',
                    name: item.category_name,
                };
            }),
        ];

        const selectItemCode = resultArr.filter(d => d.code === code)[0]?.code;
        if (selectItemCode) {
            if (selectItemCode === 'diversity') {
                const apiObj = urlString.parse(apiUrl);
                apiObj.personal = 'N';
                // 다양성 영화 장르를 위한 Params.
                apiObj.diversityYn = 'Y';
                apiUrl = urlString.stringify(apiObj);
            } else {
                apiUrl += `&categoryCode=${selectItemCode}`;
            }
        }

        return { code, setSelect, apiUrl, resultArr };
    }
    return { code, setSelect, apiUrl: null, resultArr: [] };
};
