const getScreenOsCodeDeviceParams = ({
    isMobileOrWebview = false,
    isTablet = false,
}: {
    isMobileOrWebview?: boolean;
    isTablet?: boolean;
} = {}) => {
    if (isTablet) {
        return {
            screenCode: 'CSSD0300',
            osCode: 'CSOD0900',
        };
    }
    if (isMobileOrWebview) {
        return {
            screenCode: 'CSSD0200',
            osCode: 'CSOD0900',
        };
    }
    return undefined;
};

export default getScreenOsCodeDeviceParams;
