/**
 * Swiper List
 * @constructor
 */
import Item from '@components/item/Item';
import VerticalItemRanking from '@components/item/VerticalItemRanking';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useThrottledFn from '@hooks/useThrottledFn';
import useWindowResize from '@hooks/useWindowResize';
import { useLogging } from '@store/state';
import { urlString } from '@utils/common/String';
import { isMobile } from '@utils/device/device-detect';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { device, mq } from 'src/styles/device';
import { space } from 'src/styles/space';
import SwiperCore, { A11y, FreeMode, Lazy, Mousewheel, Navigation, Pagination, Scrollbar, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([A11y, Navigation, Pagination, Scrollbar, Lazy, Mousewheel, Virtual, FreeMode]);

const SwiperList = ({
    displayTitle,
    bandType,
    title,
    ratio,
    ratioType,
    ld,
    isDetailLink,
    initialIdx,
    activeIdx,
    isLazy,
    detailLinkRoute,
    scrollbar,
    ratioMap,
    braze,
    brazeEvent,
    setListSwiper,
    searchKeyword,
    searchUniqueKey,
    funnel,
    onClickListItem,
    listIndex,
    isPayUser,
}) => {
    const router = useRouter();
    const { items } = ld.data;
    const isParamount = router?.asPath?.startsWith('/paramount') || false;
    const isSearchPage = router?.asPath?.startsWith('/search') || false;
    const prefixUrl = isParamount ? '/paramount' : '';
    const isVerticalRankBand = bandType === 'movieRank' || bandType === 'programRank';

    // const [initial, setInitial] = useState(initialIdx);
    // console.log(initial);

    const ratioList = ratioMap || {
        0: {
            vertical: 3.05, // 기본 (세로형)
            horizontal: 2.03, // 기본 (가로형)
            original: 2.1, // 오리지널
            single: 1,
        },
        600: {
            vertical: 5, // 기본 (세로형)
            horizontal: 3, // 기본 (가로형)
            original: 4, // 오리지널
            single: 1,
        },
        768: {
            vertical: 6, // 기본 (세로형)
            horizontal: 4, // 기본 (가로형)
            original: 5, // 오리지널
            single: 1,
        },
        1200: {
            vertical: 7, // 기본 (세로형)
            horizontal: 5, // 기본 (가로형)
            original: 6, // 오리지널
            single: 1,
        },
        1600: {
            vertical: 8, // 기본 (세로형)
            horizontal: 6, // 기본 (가로형)
            original: 7, // 오리지널
            single: 1,
        },
    };

    const spaceBetween = {
        0: {
            original: 7,
        },
        600: {
            original: 7,
        },
        1000: {
            original: 8,
        },
        1400: {
            original: 11,
        },
        2000: {
            original: 12,
        },
    };

    const breakpoints = {
        0: {
            spaceBetween: spaceBetween['0'][ratioType] || 8,
            slidesPerView: ratioList['0'][ratioType] || 4,
            slidesPerGroup: Math.floor(ratioList['0'][ratioType]) || 4,
        },
        600: {
            spaceBetween: spaceBetween['600'][ratioType] || 8,
            slidesPerView: ratioList['600'][ratioType] || 4,
            slidesPerGroup: ratioList['600'][ratioType] || 4,
        },
        1000: {
            spaceBetween: spaceBetween['1000'][ratioType] || 9,
            slidesPerView: ratioList['768'][ratioType] || 5,
            slidesPerGroup: ratioList['768'][ratioType] || 5,
        },
        1400: {
            spaceBetween: spaceBetween['1400'][ratioType] || 10,
            slidesPerView: ratioList['1200'][ratioType] || 6,
            slidesPerGroup: ratioList['1200'][ratioType] || 6,
        },
        2000: {
            spaceBetween: spaceBetween['2000'][ratioType] || 11,
            slidesPerView: ratioList['1600'][ratioType] || 7,
            slidesPerGroup: ratioList['1600'][ratioType] || 7,
        },
    };
    // 장르띠 breakPoint
    const genrebreakpoints = {
        0: {
            spaceBetween: 8,
            slidesPerView: 'auto',
            slidesPerGroup: Math.floor(ratioList['0'][ratioType]) || 4,
        },
        600: {
            spaceBetween: 8,
            slidesPerView: 'auto',
            slidesPerGroup: ratioList['600'][ratioType] || 4,
        },
        1000: {
            spaceBetween: 9,
            slidesPerView: 'auto',
            slidesPerGroup: ratioList['768'][ratioType] || 5,
        },
        1400: {
            spaceBetween: 10,
            slidesPerView: 'auto',
            slidesPerGroup: ratioList['1200'][ratioType] || 6,
        },
        2000: {
            spaceBetween: 11,
            slidesPerView: 'auto',
            slidesPerGroup: ratioList['1600'][ratioType] || 7,
        },
    };
    // 세로 썸네일 랭킹띠 breakPoint
    const rankBreakpoints = {
        0: {
            spaceBetween: 8,
            slidesPerView: 'auto',
            slidesPerGroup: 2,
        },
        600: {
            spaceBetween: 8,
            slidesPerView: 'auto',
            slidesPerGroup: 3,
        },
        1000: {
            spaceBetween: 8,
            slidesPerView: 'auto',
            slidesPerGroup: 4,
        },
        1400: {
            spaceBetween: 8,
            slidesPerView: 'auto',
            slidesPerGroup: 5,
        },
        2000: {
            spaceBetween: 8,
            slidesPerView: 'auto',
            slidesPerGroup: 6,
        },
    };

    // 띠 타입에 따른 breakPoint
    const swiperBreakpoint = (bandTypeBreak) => {
        switch (bandTypeBreak) {
            case 'genre':
                return genrebreakpoints;
            case 'movieRank':
            case 'programRank':
                return rankBreakpoints;
            default:
                return breakpoints;
        }
    };

    // 전체보기 링크 로직.
    const detailLink = () => {
        const link = {
            pathname: '/list/[...slug]',
            query: {
                slug: ld.exposeData?.expose_cd,
            },
        };
        const apiUrl = ld.exposeData?.api_param_pc || ld.data?.apiUrl;

        if (apiUrl && apiUrl.includes('v2/media/lives?')) {
            apiUrl.includes('CPCS0300') ? (link.query.slug = 'tvingtv') : (link.query.slug = 'live');
            // link.query.order = apiUrl.includes('order=broadDate') ? 'broadDate' : 'viewDay';
        }
        if (apiUrl && apiUrl.includes('v2/media/episodes?')) {
            if (apiUrl.includes('broadStartDate') && apiUrl.includes('broadEndDate')) {
                link.pathname = '/list/program/yesterday';
                link.query = null;
            } else {
                link.query.slug = 'program';
                link.query.order = apiUrl.includes('order=broadDate') || apiUrl.includes('order=new') ? 'new' : 'viewDay';
            }
        } else if (apiUrl && apiUrl.includes('v2/media/paramount/episodes?')) {
            if (apiUrl.includes('broadStartDate') && apiUrl.includes('broadEndDate')) {
                link.pathname = '/paramount/list/program/yesterday';
                link.query = null;
            } else {
                link.pathname = '/paramount/list/program';
                link.query.slug = 'program';
                link.query.order = apiUrl.includes('order=broadDate') || apiUrl.includes('order=new') ? 'new' : 'viewDay';
            }
        }
        if (apiUrl && apiUrl.includes('v2/media/movies?')) {
            if (apiUrl.includes('notProductPackageCode')) {
                // 개별구매
                link.query = {};
                link.pathname = '/list/movie/tvod';
            } else if (apiUrl.includes('productPackageCode')) {
                // 월정액 영화
                link.query = {};
                link.pathname = '/list/movie/svod';
            } else {
                link.query.slug = 'movie';
            }
            link.query.order = apiUrl.includes('order=new') ? 'new' : 'viewDay';
        } else if (apiUrl && apiUrl.includes('v2/media/paramount/movies?')) {
            link.pathname = '/paramount/list/movie';
            link.query.slug = 'movie';
            link.query.order = apiUrl.includes('order=new') ? 'new' : 'viewDay';
        }
        if (apiUrl && apiUrl.includes('v2/operator/highlights?')) {
            const apiObj = urlString.parse(apiUrl);
            link.pathname = `/list/highlight/${apiObj.positionKey}`;
            link.query = {};

            // 하이라이트 구좌 전체보기 Braze
            if (!braze) {
                return;
            }
            const prevScreen = location.pathname.replaceAll('/', '');
            brazeEvent({
                eventName: 'enter_curation',
                properties: { title: `${displayTitle}_전체보기`, gate: `${prevScreen === '' ? 'home' : prevScreen}` },
            });
        }
        if (ld.data.type) {
            link.pathname = `/list/${ld.data.type}/${ld.data.code}`;
            link.query = {};
        }
        // TODO: vod/recommend - 신규 방송상세 장르 추천으로 전환될 예정이며 운영에서 변경 후 제거 필요.
        // recommend/like/genre : 신규 장르 추천 띠 (~님이 좋아할만한 코미디 콘텐츠 등의 경우 전체보기 미표시)
        if (apiUrl && (apiUrl.includes('v2/media/recommend/vod/recommend?') || apiUrl.includes('v2/media/recommend/like/genre'))) {
            // 방송 상세 장르 추천
            link.pathname = '/list/program';
            link.query = { genre: ld.data.genre, genreDetail: ld.data.code };
        }

        if (detailLinkRoute) {
            link.pathname = detailLinkRoute.pathname || '';
            link.query = detailLinkRoute.query || {};
        }

        if (apiUrl && apiUrl.includes('/v2/media/frequency/program/')) {
            const programCode = apiUrl.split('?')[0]?.split('/v2/media/frequency/program/')[1] || '';
            link.pathname = `/contents/[mediaCode]`;
            link.query = {
                mediaCode: programCode,
            };
        }
        // 띠 타입이 vodWatch 인 경우 (~님이 시청중인 콘텐츠/프로그램/영화) My 페이지로 이동
        if (ld.exposeData?.expose_type === 'vodWatch') {
            link.pathname = '/my/watch';
            link.query = ld.exposeData.home_type === 'MOVIE' ? { tab: 'movie' } : {};
        }

        // 찜 띠
        if (apiUrl && apiUrl.includes('/v2/community/fan/contents')) {
            link.pathname = '/my/favorite';
            link.query = ld?.exposeData?.home_type === 'MOVIE' ? { tab: 'movie' } : {};
        }

        // 이벤트 띠의 경우 이벤트 리스트 페이지로 이동
        if (apiUrl && apiUrl.includes('v2/operator/events')) {
            link.pathname = '/event/list';
            link.query = {};
        }

        if (apiUrl && apiUrl.includes('/v2/media/band/originals')) {
            link.query.order = apiUrl.includes('order=new') ? 'new' : 'viewHour';
            if (apiUrl.includes('originalYn=Y') && apiUrl.includes('exclusiveYn=Y')) {
                link.query.slug = 'originalandonly';
            } else if (apiUrl.includes('originalYn=Y')) {
                link.query.slug = 'original';
            } else {
                link.query.slug = 'only';
            }
            if (apiUrl.includes('adult=all')) {
                link.query.adult = 'all';
            } else if (apiUrl.includes('adult=Y')) {
                link.query.adult = 'Y';
            } else if (apiUrl.includes('adult=N')) {
                link.query.adult = 'N';
            }
        }

        router.push({
            pathname: link.pathname,
            query: link.query,
        });
        // return link;
    };

    const activeFrequncyIdx = () => {
        let initialSlide = 0;

        items.map((item, idx) => {
            // console.log(item);
            if (item.frequency === initialIdx) {
                initialSlide = idx;
            }
        });
        // console.log(initialSlide);
        return initialSlide;
    };

    const createOnClickListItem = (itemIndex) => (event) => {
        onClickListItem?.(
            {
                itemIndex,
                listIndex,
            },
            event,
        );
    };

    const [swiper, setSwiper] = useState(null);
    const [updateCound, setUpdateCount] = useState(0);
    const [rankItemWidth, setRankItemWidth] = useState(0); // 세로 썸네일 랭킹 띠에서의 아이템 width 값

    useWindowResize(
        useThrottledFn(() => {
            setUpdateCount((prev) => {
                return prev + 1;
            });
        }, 300),
    );

    useEffect(() => {
        if (!swiper) {
            return;
        }
        swiper.update();
        try {
            setRankItemWidth(
                Math.floor(
                    (Number(
                        (swiper?.size || 0) -
                            Number(breakpoints[swiper?.currentBreakpoint]?.spaceBetween) *
                                (Number(breakpoints[swiper?.currentBreakpoint]?.slidesPerView) - 1),
                    ) /
                        Number(breakpoints[swiper?.currentBreakpoint]?.slidesPerView)) *
                        100,
                ) / 100,
            );
            /* 랭킹 띠 아이템 width 계산식 :
             * 일반 세로형 썸네일 사이즈와 동일하게 맞추기 위해 현재 breakpoint 기준 세로형 썸네일일 경우의 값으로 계산.
             * (띠 가로 사이즈 - 아이템 간 간격의 총 width) / 아이템 수
             */
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }, [updateCound]);

    const [isViewMobile, setIsViewMobile] = useState(false);
    useEffect(() => {
        setIsViewMobile(isMobile);
    }, [isMobile]);

    useEffect(() => {
        swiper?.slideTo(initialIdx, 0);
    }, [initialIdx, swiper]);

    useEffect(() => {
        if (!swiper) {
            return;
        }
        swiper.update();
        try {
            setRankItemWidth(
                Math.floor(
                    (Number(
                        (swiper?.size || 0) -
                            Number(breakpoints[swiper?.currentBreakpoint]?.spaceBetween) *
                                (Number(breakpoints[swiper?.currentBreakpoint]?.slidesPerView) - 1),
                    ) /
                        Number(breakpoints[swiper?.currentBreakpoint]?.slidesPerView)) *
                        100,
                ) / 100,
            );
            // eslint-disable-next-line no-empty
        } catch (e) {}
        if (swiper !== null && bandType === 'tvingOrigin') {
            swiper.navigation?.prevEl?.classList.add('origin_button');
            swiper.navigation?.nextEl?.classList.add('origin_button');
        }

        if (setListSwiper) {
            setListSwiper(swiper);
        }
    }, [swiper]);

    // 랭킹띠 아이템 width 값 변경된 경우 swiper 업데이트 - 페이징 업데이트를 위함
    useEffect(() => {
        if (!swiper) {
            return;
        }
        swiper.update();
    }, [rankItemWidth, swiper]);

    return (
        <Swiper
            lazy
            spaceBetween={8}
            slidesPerView={bandType === 'genre' || isVerticalRankBand ? 'auto' : 1} // 해당 값을 처음에 안넣으니 동작 안하는 케이스 발생.
            breakpoints={swiperBreakpoint(bandType)}
            pagination={isViewMobile ? false : { clickable: true }}
            // initialSlide={initialIdx}
            scrollbar={scrollbar}
            freeMode={
                scrollbar || isViewMobile
                    ? {
                          enabled: true,
                          sticky: !!scrollbar,
                      }
                    : false
            }
            navigation={!isViewMobile}
            mousewheel={{ forceToAxis: !isViewMobile, sensitivity: 1, thresholdDelta: 10 }}
            virtual={
                scrollbar && !isViewMobile
                    ? {
                          addSlidesAfter: 10,
                      }
                    : false
            }
            updateOnWindowResize={isViewMobile}
            onSwiper={setSwiper}
            a11y={{
                enabled: true,
                prevSlideMessage: '이전 슬라이드',
                nextSlideMessage: '다음 슬라이드',
                paginationBulletMessage: '{{index}}번 페이지로 이동',
            }}
            data-testid="swiper-list"
            data-band-type={bandType}
            data-band-name={displayTitle}
            data-legacy-band
        >
            {isDetailLink && !isMobile && (
                <a onClick={() => detailLink()}>
                    <button type="button" className="slide_item_all" title="전체보기 버튼">
                        전체보기
                    </button>
                </a>
            )}

            {items &&
                items.map((item, idx) => {
                    let fbaParams;
                    // fba 전달 파라미터 유무 검사
                    if (item.abtestGroup) {
                        fbaParams = {
                            schModel: item.schModel,
                            schRecModel: item.schRecModel,
                            schCollection: item.schCollection,
                            abtestGroup: item.abtestGroup,
                            impressionSchSr: item.impressionSchSr,
                            clickSchSr: item.clickSchSr,
                        };
                    }

                    return (
                        <SwiperSlide
                            key={`item-${idx}`}
                            className={classnames('item', {
                                genreItem: bandType === 'genre',
                                rankItem: isVerticalRankBand,
                                [`swiper-slide-item-${idx}`]: idx >= 0,
                            })}
                            virtualIndex={idx}
                            data-testid="swiper-list-item"
                            data-band-type={bandType}
                            data-band-name={displayTitle}
                            data-legacy-band
                        >
                            {/* Item 유형별로 테스트 진행 - 세로형 랭킹띠의 경우 */}
                            {isVerticalRankBand ? (
                                <div css={{ display: 'flex' }}>
                                    <VerticalItemRanking
                                        number={idx + 1}
                                        item={item}
                                        isLazy={swiper?.size > 0 && rankItemWidth > 0}
                                        onClick={createOnClickListItem(idx)}
                                    />
                                    <Item
                                        bandTitle={displayTitle}
                                        bandType={bandType}
                                        item={item}
                                        idx={`ranking-${idx + 1}`}
                                        ratio={ratio}
                                        isLazy={isLazy ? true : idx < 10 ? false : false}
                                        isView={idx === activeIdx}
                                        prefixUrl={prefixUrl}
                                        isSearchPage={isSearchPage}
                                        itemWidth={rankItemWidth}
                                        onClick={createOnClickListItem(idx)}
                                        isPayUser={isPayUser}
                                    />
                                </div>
                            ) : (
                                <Item
                                    bandTitle={displayTitle}
                                    bandType={bandType}
                                    item={item}
                                    idx={idx}
                                    ratio={ratio}
                                    isLazy={isLazy ? true : idx < 10 ? false : false}
                                    isView={idx === activeIdx}
                                    prefixUrl={prefixUrl}
                                    isSearchPage={isSearchPage}
                                    searchKeyword={searchKeyword}
                                    searchUniqueKey={searchUniqueKey}
                                    funnel={funnel}
                                    onClick={createOnClickListItem(idx)}
                                    isPayUser={isPayUser}
                                    fbaParams={fbaParams}
                                />
                            )}
                        </SwiperSlide>
                    );
                })}
        </Swiper>
    );
};

const swiperButton = css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* opacity: 0.5; */
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 50% 40%;
    ${mq({
        width: space.containerSidePadding,
    })}
    height: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    z-index: 10;
    cursor: pointer;
`;

const SlideWrap = styled.div`
    overflow: hidden;
    margin: -4rem 0 -1.5rem;
    padding: 4rem 0 2.5rem;

    ${mq({
        paddingLeft: space.containerSidePadding,
        paddingRight: space.containerSidePadding,
        marginLeft: space.containerSidePaddingMinus,
        marginRight: space.containerSidePaddingMinus,
    })}
    .swiper {
        overflow: visible;
    }
    .slide_item_all {
        top: -2em;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.64);
        &:hover {
            color: rgba(255, 255, 255, 0.87);
        }
    }
    .swiper-button-disabled {
        display: none;
    }
    .swiper-pagination {
        position: absolute;
        top: -2.4em;

        ${(props) =>
            props.isDetailLink
                ? css`
                      right: 4rem;
                  `
                : css`
                      right: 0;
                  `}
        margin-right: 1em;
        .swiper-pagination-bullet {
            position: relative;
            display: inline-block;
            width: 3px;
            height: 3px;
            margin: 0 2px;
            border-radius: 999px;
            background-color: rgba(255, 255, 255, 0.5);
            /* background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cellipse ry='5' rx='5' cy='5' cx='5' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
            background-size: cover;
            background-position: center; */
            @media ${device.laptop} {
                width: 4px;
                height: 4px;
                margin: 0 3px;
            }
            @media ${device.desktop} {
                width: 5px;
                height: 5px;
                margin: 0 4px;
            }
            cursor: pointer;

            &:first-of-type:nth-last-of-type(1) {
                display: none;
            }
        }
        .swiper-pagination-bullet-active {
            background-color: rgba(255, 255, 255, 1);
        }
    }
    .swiper-button-prev {
        ${mq({
            left: space.containerSidePaddingMinus,
        })}
        background-image: url('/img/icon_slide_left.svg'), radial-gradient(106.88% 50.3% at -6.88% 50%, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.6) 53.21%, rgba(0, 0, 0, 0) 100%);
    }
    .swiper-button-next {
        ${mq({
            right: space.containerSidePaddingMinus,
        })}
        background-image: url('/img/icon_slide_right.svg'), radial-gradient(106.25% 50% at 106.25% 50%, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.6) 52.6%, rgba(0, 0, 0, 0) 100%);
    }

    .swiper-button-prev,
    .swiper-button-next {
        ${swiperButton}

        &.origin_button {
            background-position: 50% 50%;
        }
    }

    ${({ scrollbar }) =>
        scrollbar
            ? css`
                  padding-bottom: 5.3rem;
              `
            : css``}

    .swiper-scrollbar:not(.swiper-scrollbar-lock) + div + .swiper-wrapper {
        margin-bottom: 2.8rem;
    }

    .genreItem {
        width: auto !important;
        &:hover {
            transform: none;
        }
    }
    .rankItem {
        width: auto !important;
    }
`;

const Slider = ({
    displayTitle = '',
    bandType = 'programBasic',
    title = '{title}',
    ratio = '25x36',
    ratioType = 'horizontal',
    listData = {},
    isDetailLink = false,
    initialIdx = 0,
    activeIdx = -1,
    isLazy = true,
    detailLinkRoute,
    scrollbar = false,
    ratioMap,
    isPan = false,
    setListSwiper,
    searchKeyword = '',
    searchUniqueKey = '',
    funnel = '',
    onClickListItem,
    listIndex,
    isPayUser,
}) => {
    if (bandType === 'quickVod') {
        isDetailLink = false;
    }
    const { items } = listData.data;
    if (isPan && items && items.length < 5) {
        // 판인 경우 콘텐츠 개수가 4개 이하인 경우 CMS 전체보기 제공유무 설정이 ON 이어도 전체보기 비노출
        isDetailLink = false;
    }
    const [{ braze, brazeEvent }] = useLogging();

    return (
        <SlideWrap className={`lists__slides-${ratioType}`} isDetailLink={isDetailLink} scrollbar={scrollbar}>
            <SwiperList
                displayTitle={displayTitle}
                isDetailLink={isDetailLink}
                bandType={bandType}
                title={title}
                ratio={ratio}
                ratioType={ratioType}
                ld={listData}
                initialIdx={initialIdx}
                activeIdx={activeIdx}
                isLazy={isLazy}
                detailLinkRoute={detailLinkRoute}
                scrollbar={scrollbar}
                ratioMap={ratioMap}
                braze={braze}
                brazeEvent={brazeEvent}
                setListSwiper={setListSwiper}
                searchKeyword={searchKeyword}
                searchUniqueKey={searchUniqueKey}
                funnel={funnel}
                onClickListItem={onClickListItem}
                listIndex={listIndex}
                isPayUser={isPayUser}
            />
        </SlideWrap>
    );
};

export default Slider;
