import { KboContentsResponse } from '@typings/kbo';
import { KBO_QUERY_KEYS, useKboContentsMainQuery } from '@hooks/kbo/queries/index';
import fetchKboContentsBroadcast from '@apis/kbo/bff/fetchKboContentsBroadcast';
import useUpdateIndicatorStore from '@components/kbo/kboContentsBand/updateIndicator/useUpdateIndicatorStore';
import { KBO_BAND_TYPE, KboBand, KboChannel, KboInningBand } from '@typings/kbo/band';
import { KboBaseRunningItem, KboMessageItem } from '@components/kbo/kboContentsBand/broadcast/KboContentsBroadcastBand';
import { useRef } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useKboGame from '@stores/kbo/game/useKboGame';
import { shallow } from 'zustand/shallow';
import dayjs from 'dayjs';
import { useUserInfo } from '@store/state';
import getActiveChannelIdx from '@utils/kbo/getActiveChannelIdx/getActiveChannelIdx';
import { useRouter } from 'next/router';

const useKboContentsBroadcastQuery = (gameCode: string, inning = 0) => {
    const { isGameEnd, lastPlayChannel } = useKboGame(
        (state) => ({
            isGameEnd: state.isGameEnd,
            lastPlayChannel: state.lastPlayChannel,
        }),
        shallow,
    );

    const router = useRouter();
    const { query } = router;
    const requestedChannelCode = (query?.streamCode || '') as string;

    // 문자 중계 API는 반드시 Main 정보를 요청해야합니다.
    const { data } = useKboContentsMainQuery<KboBand, KboChannel>();

    const channels = data?.data?.channels || [];
    const selectedChannelIdx = getActiveChannelIdx({
        channels,
        gameCode,
        lastPlayChannel,
        requestedChannelCode,
    });

    const channel = channels[selectedChannelIdx];
    const { broadcastStartTime } = channel || {};
    const isBroadcasting = !!(broadcastStartTime && dayjs().isAfter(dayjs(`${broadcastStartTime}`)));

    const { isAutoUpdate, setLastUpdatedAt } = useUpdateIndicatorStore((state) => ({
        setLastUpdatedAt: state.setLastUpdatedAt,
        isAutoUpdate: state.isAutoUpdate,
    }));

    const queryClient = useQueryClient();
    const lastData = useRef<KboContentsResponse<KboBand<KboBaseRunningItem> | KboInningBand<KboMessageItem>>>();

    const [userInfo] = useUserInfo();
    const { isLogin } = userInfo;

    const isRefetch = !isGameEnd && isAutoUpdate && isBroadcasting;

    return useQuery<KboContentsResponse<KboBand<KboBaseRunningItem> | KboInningBand<KboMessageItem>>>(
        KBO_QUERY_KEYS.CONTENTS_BROADCAST(gameCode, inning),
        () => fetchKboContentsBroadcast(gameCode, inning),
        {
            enabled: isLogin,
            refetchInterval: isRefetch && inning === 0 ? 10000 : false,
            refetchIntervalInBackground: isRefetch,
            refetchOnMount: isRefetch,
            refetchOnWindowFocus: isRefetch,
            refetchOnReconnect: isRefetch,
            keepPreviousData: true,
            useErrorBoundary: false,
            onSuccess: (result) => {
                if (!inning) {
                    setLastUpdatedAt(Date.now());
                }

                const messageBands = result?.data?.bands?.filter(
                    (band) => band.bandType === KBO_BAND_TYPE.KBO_MESSAGE,
                ) as KboInningBand<KboMessageItem>[];

                const isInvalidData = !result?.data?.bands || (messageBands?.length ?? 0) === 0 || messageBands?.[0]?.items?.length === 0;
                if (
                    // 응답 데이터 비정상
                    isInvalidData &&
                    // 문제 있는 응답값과 동일한 경기 코드일 때
                    gameCode === result?.data?.gameCode
                ) {
                    queryClient.setQueryData(KBO_QUERY_KEYS.CONTENTS_BROADCAST(gameCode, inning), lastData.current);
                } else {
                    lastData.current = result;
                }
            },
        },
    );
};

export default useKboContentsBroadcastQuery;
