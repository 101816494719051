import React, { useEffect, useState } from 'react';
import useSendbirdSession from '@hooks/chat/useSendbirdSession';
import { useUserInfo } from '@store/state';
import { useCookies } from 'react-cookie';
import ChatManager from '@components/chat/ChatManager';
import { ChatContextProvider, ErrorPayload } from '@tving/utils/src/contexts/ChatContext';

type ChatContainerProps = {
    channelUrl: string;
    error?: ErrorPayload;
    infoIcon?: React.ReactNode;
    loadingComp?: React.ReactNode;
};

const ChatContainer = ({ channelUrl, error: outerError, infoIcon, loadingComp }: ChatContainerProps) => {
    const [{ profileNo: profileNumber }] = useUserInfo();
    const [{ accessToken, _tving_token: tvingToken }] = useCookies<
        'accessToken' | '_tving_token',
        {
            accessToken: string;
            _tving_token: string;
        }
    >();

    const {
        data: sendbirdSession,
        isLoading: isSendbirdSessionLoading,
        error: sendbirdSessionError,
        refetchSession,
        refreshSession,
    } = useSendbirdSession({ profileNumber, accessToken, tvingToken });

    const error: ErrorPayload | undefined =
        outerError ||
        (sendbirdSessionError
            ? {
                  code: 900500,
                  errorMessage: '일시적인 오류로 티빙톡을 실행할 수 없습니다.\n잠시 후 다시 시도하세요.',
                  displayType: 'layout',
              }
            : undefined);

    return (
        <ChatContextProvider
            channelUrl={channelUrl}
            refetchSession={refetchSession}
            refreshSession={refreshSession}
            error={error}
            infoIcon={infoIcon}
            loadingComp={loadingComp}
        >
            <ChatManager sendbirdSession={sendbirdSession} />
        </ChatContextProvider>
    );
};

export default ChatContainer;
