import throttle from 'lodash/throttle';

import { EventOptions, RemoveListener } from './types';

export const VIEWPORT_ORIENTATION = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
} as const;

export type ViewportOrientation = typeof VIEWPORT_ORIENTATION[keyof typeof VIEWPORT_ORIENTATION];

interface OrientationEvent {
  orientation: ViewportOrientation;
}

export type OrientationCallback = (event: OrientationEvent) => void;

/**
 * Webview 환경을 고려한 Cross Platform Orientation 이벤트 리스너
 */
export const addOrientationChangeEvent = (callback: OrientationCallback, options?: EventOptions): RemoveListener => {
  const handleOrientationChange = throttle(
    (params: OrientationEvent) => {
      callback?.(params);
    },
    undefined,
    {
      leading: true,
      trailing: true,
    },
  );

  const dispatchOrientationChange = () => {
    handleOrientationChange({
      orientation: window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait',
    });
  };

  const supportScreenOrientation =
    typeof window.screen.orientation !== 'undefined' && typeof window.screen.orientation.addEventListener === 'function';

  window.addEventListener('resize', dispatchOrientationChange, options);
  window.addEventListener('orientationchange', dispatchOrientationChange, options);
  if (supportScreenOrientation) {
    window.screen.orientation.addEventListener('change', dispatchOrientationChange);
  }

  dispatchOrientationChange();
  requestAnimationFrame(() => {
    setTimeout(() => {
      dispatchOrientationChange();
    }, 100);
  });

  return () => {
    window.removeEventListener('resize', dispatchOrientationChange, options);
    window.removeEventListener('orientationchange', dispatchOrientationChange, options);
    if (supportScreenOrientation) {
      window.screen.orientation.removeEventListener('change', dispatchOrientationChange);
    }
  };
};
