import { KBO_QUERY_KEYS } from '@hooks/kbo/queries/index';
import type { UseQueryOptions } from '@tanstack/react-query';
import fetchKboMyTeams from '@apis/kbo/bff/fetchKboMyTeams';
import { KboBand } from '@typings/kbo/band';
import { KboMyTeamItem, KboMyTeams } from '@typings/kbo/myTeam';
import { storage } from '@utils/common/LocalStorage';
import { useSuspenseQuery } from '@suspensive/react-query';

const generateKboMyTeams = async (): Promise<KboMyTeams> => {
    const response = await fetchKboMyTeams<KboBand<KboMyTeamItem>>();

    const { data } = response;

    const kboMyTeams = data?.bands[0]?.items;

    const selectedKboMyTeam = kboMyTeams?.filter((kboMyTeam) => {
        return kboMyTeam.isMyTeam === true;
    });

    if (selectedKboMyTeam && selectedKboMyTeam[0]?.isMyTeam === true) {
        storage.set('kbo_my_team', JSON.stringify(selectedKboMyTeam[0]));
    }

    return {
        kboMyTeams,
        selectedKboMyTeam: selectedKboMyTeam[0],
    };
};
const useKboMyTeamsQuery = (options?: UseQueryOptions<KboMyTeams>) => {
    return useSuspenseQuery<KboMyTeams>(KBO_QUERY_KEYS.MY_TEAMS(), () => generateKboMyTeams(), options);
};

export default useKboMyTeamsQuery;
