import { GetServerSidePropsContext } from 'next';
import { API_VERSION, getKboDefaultApiUrl } from '@tving/utils/src/utils/common/apiConfig';
import httpGet from '@utils/common/httpGet';
import { KBO_GAME_STATUS, KboContentsResponse } from '@typings/kbo';
import { KBO_API_URLS } from '@constants/kbo/url';
import getKboApiDeviceParams from '@utils/kbo/getKboApiDeviceParams/getKboApiDeviceParams';

const lastResponseBands: Record<string, any> = {};
const fetchKboContentsMain = async <B, C>(gameCode: string, isMobileOrWebview?: boolean, context?: GetServerSidePropsContext) => {
    const { accessToken = '', _tving_token = '' } = context?.req?.cookies || {};

    const response = await httpGet<KboContentsResponse<B, C>>(
        getKboDefaultApiUrl({ version: API_VERSION.V3 }) + KBO_API_URLS.CONTENTS_MAIN(gameCode),
        {
            params: getKboApiDeviceParams(isMobileOrWebview),
            serverCookies: context?.req?.cookies,
            serverRes: context?.res,
            serverReq: context?.req,
            origin: `https://${context?.req?.headers?.host}` || '',
            headers: {
                Authorization: `Bearer ${_tving_token}`,
                'Access-Token': accessToken,
            },
        },
    );

    Object.keys(lastResponseBands).forEach((key) => {
        if (key !== gameCode) {
            delete lastResponseBands[key];
        }
    });

    if (!response?.data?.bands || response?.data?.bands?.length === 0) {
        if (lastResponseBands[gameCode]) {
            response.data.bands = lastResponseBands[gameCode];
        }
    } else {
        lastResponseBands[gameCode] = response.data.bands;
    }

    // For debugging
    // if (typeof window !== 'undefined') {
    //     const debugGameCode = sessionStorage.getItem('kbo-game-code');
    //     const debugBroadcastStartTime = sessionStorage.getItem('kbo-broadcast-start-time');
    //     const debugBroadcastEndTime = sessionStorage.getItem('kbo-broadcast-end-time');
    //     const debugStatus = sessionStorage.getItem('kbo-status');
    //     const debugChannelCode = sessionStorage.getItem('kbo-channel-code');
    //
    //     if (debugGameCode && gameCode === debugGameCode) {
    //         if (debugBroadcastStartTime) {
    //             response.data.broadcastStartTime = Number(debugBroadcastStartTime);
    //         }
    //
    //         if (debugBroadcastEndTime) {
    //             response.data.broadcastEndTime = Number(debugBroadcastEndTime);
    //         }
    //
    //         if (debugStatus) {
    //             response.data.status = debugStatus as KBO_GAME_STATUS;
    //         }
    //
    //         if (debugChannelCode) {
    //             response.data.code = debugChannelCode;
    //         }
    //     }
    // }

    return response;
};
export default fetchKboContentsMain;
