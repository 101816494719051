/**
 * 전역적으로 선언돼있는 고유 변수를 통해 장비의 타입을 구분합니다.
 */
import { DEVICE_TYPE } from '../../../constants/commonConstants';

const getDeviceType = (): DEVICE_TYPE => {
  // SSR에서 호출하는 경우 디바이스를 WEB이라고 판단
  if (typeof window === 'undefined') {
    return DEVICE_TYPE.WEB;
  }

  if (window.DEVICE_TYPE) {
    return window.DEVICE_TYPE;
  }

  const userAgentString = navigator.userAgent.toLowerCase();
  if (userAgentString.indexOf('tizen') !== -1) {
    window.DEVICE_TYPE = DEVICE_TYPE.TIZEN;
    return DEVICE_TYPE.TIZEN;
  }

  // webOS 체크 - userAgent로 확인
  if (userAgentString.indexOf('webos') !== -1 || userAgentString.indexOf('web0s') !== -1) {
    window.DEVICE_TYPE = DEVICE_TYPE.WEBOS;
    return DEVICE_TYPE.WEBOS;
  }

  if (Object.prototype.hasOwnProperty.call(window, 'NFCommon')) {
    window.DEVICE_TYPE = DEVICE_TYPE.ANDROIDTV;
    return DEVICE_TYPE.ANDROIDTV;
  }

  window.DEVICE_TYPE = DEVICE_TYPE.WEB;
  return DEVICE_TYPE.WEB;
};

export default getDeviceType;
