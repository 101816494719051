import { shallow } from 'zustand/shallow';
import useClientStore from '@stores/useClientStore';
import fetchKboTeam from '@apis/kbo/bff/fetchKboTeam';
import { KBO_QUERY_KEYS } from '@hooks/kbo/queries';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

const useKboTeamQuery = () => {
    const {
        query: { teamCode },
    } = useRouter() as {
        query: { teamCode?: string };
    };

    const { isMobile, isWebview, isTablet } = useClientStore(
        (state) => ({
            isMobile: state.isMobile,
            isWebview: state.isWebview,
            isTablet: state.isTablet,
        }),
        shallow,
    );
    const isMobileOrWebview = !!(isMobile || isWebview);

    return useQuery(
        KBO_QUERY_KEYS.TEAM(teamCode ?? ''),
        () =>
            fetchKboTeam({
                teamCode: teamCode ?? '',
                deviceType: {
                    isMobileOrWebview,
                    isTablet: !!isTablet,
                },
            }),
        {
            enabled: !!teamCode,
        },
    );
};

export default useKboTeamQuery;
