import { TtvBufferModeResponse } from '../../apis/types';
import { TvingTtvBuffer } from '../types';

const proxyTtvBuffer = (ttvBufferModeResponse: TtvBufferModeResponse | null): TvingTtvBuffer => {
  if (ttvBufferModeResponse) {
    const { ttv, buffer } = ttvBufferModeResponse;
    const isTtvRegular = ttv.checkType === 'regular';
    return {
      isTtvOn: ttv && ttv.modeOn,
      ttvUrl: ttv && ttv.url,
      isRegular: isTtvRegular,
      ttvTitle: isTtvRegular ? ttv.regular?.title : ttv.emergency?.title,
      ttvMessage: isTtvRegular ? ttv.regular?.notiMsg : ttv.emergency?.notiMsg,
      ttvCheckDate: isTtvRegular ? ttv.regular?.checkDate : ttv.emergency?.checkDate,

      isBufferOn: buffer && buffer.modeOn,
      bufferUrl: buffer && buffer.url,
    };
  }
  return {
    isTtvOn: false,
    ttvUrl: '',
    isRegular: false,
    ttvTitle: '',
    ttvMessage: '',
    ttvCheckDate: '',
    isBufferOn: false,
    bufferUrl: '',
  };
};

export default proxyTtvBuffer;
