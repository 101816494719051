import styled from '@emotion/styled';
import { colors, palette } from '@styles/colors';
import { device } from '@styles/device';

export const SearchTitle = styled.h2`
    color: ${colors.darkWhite};
    line-height: 2.1;
    font-size: 1.667rem;
    font-weight: bold;
    margin-bottom: 1.667rem;
`;

export const PopularSearchButton = styled.button`
    display: flex;
    align-items: center;
    color: #a3a3a3;
    transition: color 0.1s;

    &:hover {
        color: ${colors.darkWhite};
    }
`;

export const PopularSearchTitle = styled.h3`
    cursor: pointer;
    font-size: 1.333rem;
    font-weight: 400;
`;

export const PopularSearchRanking = styled.span`
    width: 2.5rem;
    font-size: 1.333rem;
    display: inline-block;
    color: ${colors.red};
`;

export const PopularSearchRankingDate = styled.span`
    display: inline-block;
    font-size: 1.167rem;
    font-weight: 400;
    line-height: 1.91;
    text-align: left;
    margin-top: 0.75rem;
    color: #646464;
`;

export const SearchResultText = styled.ul`
    padding-top: 2.045rem;
    li {
        button {
            transition: opacity 0.1s;
            &:hover {
                color: #fff;
            }
        }
        @media ${device.tablet} {
            &:nth-of-type(1),
            &:nth-of-type(2) {
                display: none;
            }
        }
        @media ${device.laptop} {
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3) {
                display: none;
            }
        }
        @media ${device.desktop} {
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4) {
                display: none;
            }
        }
    }
`;

export const StyledResultRecommendItem = styled.span`
    display: inline;
    cursor: pointer;
    color: ${palette.inverseGrey600};

    &:hover {
        color: ${palette.inverseGrey800};
    }
`;

export const StyledSearchedKeyword = styled.p`
    position: relative;
    color: ${palette.white};
    font-size: 2.5em;
    display: inline-block;
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    line-height: normal;
`;

export const StyledNoneResultTitle = styled.span`
    position: relative;
    font-size: 2.5em;
    color: ${palette.white};
    font-weight: bold;

    &::before {
        content: '’';
        margin-right: 0.5rem;
    }
`;

export const StyledRecentKeyword = styled.button`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &:hover {
        color: ${palette.white};
    }
`;

export const StyledDeleteBtn = styled.button`
    margin-left: 1rem;
    min-width: 1rem;
    min-height: 1rem;
    background-image: url('/img/icon_x.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    opacity: 0.7;
    line-height: normal;
    font-size: 1rem;
    text-indent: -9999px;
    cursor: pointer;
`;

export const StyledSearchInput = styled.input`
    width: calc(100% - 3rem);
    outline: none;
    border-width: 0;
    background: transparent;
    font-size: 2em;
    font-weight: 400;
    line-height: normal;
`;
