import React, { useEffect, useRef } from 'react';
import { useUserInfo } from '@store/state';
import { useSportsConfig } from '@tving/utils/src/contexts/sports/SportsContext';
import { isSafari, isMobile } from '@utils/device/device-detect';
import { firebaseCloudMessaging } from '@utils/webpush';
import { getMessaging, isSupported, onMessage } from 'firebase/messaging';

import { pageOrigin } from '@utils/apiConfig';
import setUserIdAmplitude from '@tving/utils/src/utils/amplitude/setUserIdAmplitude';
import seGroupAmplitude from '@tving/utils/src/utils/amplitude/seGroupAmplitude';
import { getCookie } from '@utils/common/cookie';
import { useCookie } from 'react-use';

const useAnalyticsConfig = () => {
    const [token] = useCookie('_tving_token');
    const { ssrToken } = useSportsConfig();
    const [userInfo] = useUserInfo({
        initialData: {
            isLogin: !!ssrToken,
        },
    });
    const isMounted = useRef(false);

    const { isLogin } = userInfo;

    useEffect(() => {
        if (token && isLogin) {
            // user쪽에서 set해주는 값을 사용하는게 아닌, tving.com 이동 시 직접 setUserId() 함수를 통해 선택된 프로필 토큰 값을 추가
            setUserIdAmplitude(token);
        }
    }, [token, isLogin]);

    useEffect(() => {
        if (isLogin && !isMounted.current) {
            // _tutB3583: master profile 토큰 값
            seGroupAmplitude(getCookie('_tutB3583'));

            isMounted.current = true;
        }
    }, [isLogin]);

    // Firebase.
    useEffect(() => {
        if (!isLogin || isSafari || isMobile) {
            return;
        }

        const initialFirebase = () => {
            const getMessage = () => {
                const messaging = getMessaging();
                onMessage(messaging, (payload) => {
                    const { title, sub_title, contents, app_url_scheme } = JSON.parse(payload?.data?.body || '{}');
                    const notificationTitle = `${title} - ${sub_title}`;
                    const notificationOptions = {
                        body: contents,
                        // icon: image,
                        icon: `${pageOrigin}/img/tving-icon@3x.png`,
                    };

                    const url = new URL(app_url_scheme);
                    const params = new URLSearchParams(url.search);
                    const mediaCode = params.get('cd');
                    const linkUrl = `${pageOrigin}/player/${mediaCode}`;

                    const notification = new Notification(notificationTitle, notificationOptions);
                    notification.onclick = (event) => {
                        event.preventDefault();
                        window.open(linkUrl, '_blank');
                        notification.close();
                    };
                });
            };

            const setToken = async () => {
                try {
                    const token = await firebaseCloudMessaging.init();
                    if (token) {
                        getMessage();
                    }
                } catch (error) {
                    console.log(error);
                }
            };

            setToken();
        };

        // Firebase isSupported 체크 추가
        isSupported().then((isFirebaseSupported) => {
            if (isFirebaseSupported) {
                initialFirebase();
            }
        });
    }, [isLogin]);

    return null;
};

export default useAnalyticsConfig;
