import moment from 'moment';
import { useAbortSWR } from '@store/apis/plugins';
import { useHomeExposures } from '@store/state';
import { getAgeGrade } from '@utils/common/Code';
import { imgOrigin } from '@utils/apiConfig';
import { useState } from 'react';
import { replaceWeirdUnicodeWithBlank } from '@utils/common/String';
import { API_URLS } from '@constants/urls';
import useSWR from 'swr';
import ApiSend from '@utils/common/ApiSend';

/**
 * 시청 중인 콘텐츠 List
 * @param {number} pageNo - 페이지 번호.
 * @return {Object|boolean} data, source, isLoading, isError Value.
 */
export const useMyWatchingLists = ({ pageNo = 1 }) => {
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달
    const { data } = useHomeExposures();
    if (data) {
        return useAbortSWR([`/v2/media/my/lasts?cache=Y&pageNo=${pageNo}`], {
            params: {
                cacheType: 'main',
                pageSize: 24,
                contentType: 'vod',
                viewTimeCutYn: 'Y',
                notCategoryCode: 'PCC,MG240',
            },
            withCredentials: true,
        });
    }
    return {};
};

export const useExposureItemList = ({ pageNo = 1, apiUrl = '', params = {} }, items) => {
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨ㅠ
    // 다른 params는 { params:{} }로 전달

    if (!apiUrl) {
        return {
            data: {
                bands: items,
            },
            isLoading: false,
        };
    }
    // 어제 놓친 방송 띠 API 호출을 위한 날짜 replace
    const yesterday = moment().subtract(1, 'days').format('YYYYMMDD');
    apiUrl = apiUrl && apiUrl.replaceAll('{{yesterday}}', yesterday);

    const listData = useAbortSWR(
        [apiUrl],
        {
            params,
            withCredentials: true,
        },
        {},
        (data) => {
            let isBandsLimitSize = true;
            if (apiUrl.includes('highlights') && apiUrl.includes('HOME_CATEGORY')) {
                isBandsLimitSize = false;
            }

            const items = isBandsLimitSize ? data?.items?.slice(0, 20) : data?.items;

            return {
                ...data,
                items,
            };
        },
    );
    return listData;
};

export const useListThemeKeyList = ({ pageNo = 1, key = '' }) => {
    const listData = useAbortSWR([`/v2/operator/theme/key/${key}`], {
        params: {
            contentType: 'episode',
            pageSize: 20,
            themeType: 'T',
            pocCode: 'POCD0100',
        },
        withCredentials: true,
    });
    console.log('listdata', listData);
    return listData;
};

// 프로그램 추천 리스트 API
export const useRecommendProgramList = ({ programCode = '' }) => {
    return useAbortSWR(
        [`/v2/media/recommend/program/associate?contentId=${programCode}`],
        {
            params: {
                pageSize: 20,
            },
        },
        {},
        (data) => {
            const lists = (data?.items || []).map(
                ({ name, code, image, grade_code, tving_original_yn, tving_exclusive_yn, free_yn, adult_yn, broadcast_datetime, paramount_yn }) => {
                    return {
                        title: name,
                        code,
                        programCode: code,
                        gradeCode: grade_code,
                        ageGrade: getAgeGrade(grade_code),
                        thumbnail: imgOrigin + image,
                        isOriginal: tving_original_yn === 'Y', // 오리지널 여부
                        isExclusive: tving_exclusive_yn === 'Y', // 티빙온리 여부
                        isFree: free_yn === 'Y', // 무료 여부
                        isAdult: adult_yn === 'Y', // 성인인증 필요여부
                        isParamount: paramount_yn === 'Y', // 파라마운트 여부
                        insertDate: broadcast_datetime, // 방송일자 > 최신 뱃지 표시
                    };
                },
            );
            return { items: lists };
        },
    );
};

export const useProgramThemeList = (programCode) => {
    return useAbortSWR([`/v2/operator/theme/contents?content_code=${programCode}`], {
        params: {
            content_type: 'program',
            // content_code: programCode,
            pocCode: 'POCD0400',
        },
    });
};

// 모바일웹
// 부가영상 목록 정보
export const usePreReleaseClipList = ({ programCode = '' }) => {
    return useAbortSWR([programCode ? `/v2/media/prerelease/clips?mediaCode=${programCode}` : null], {
        params: {
            pageNo: 1,
            pageSize: 20,
        },
        withCredentials: true,
    });
};

// 공개예정작 - TVING SOON 띠
export const usePreReleaseList = () => {
    return useAbortSWR(['/v2/media/prerelease/contents'], {
        params: {
            pageNo: 1,
            pageSize: 20,
        },
    });
};

const getVerticalImage = ({ contentType = 'program', imageList = [], randomNum }) => {
    const verticalImageCodeList =
        contentType === 'movie' ? ['CAIM0400', 'CAIM2400', 'CAIM2500'] : ['CAIP0200', 'CAIP0400', 'CAIP0500', 'CAIP1500', 'CAIP2100']; // 가로형 프로그램 이미지
    let imageUrlList = [];
    imageUrlList = imageList.filter((d) => verticalImageCodeList.includes(d.code));
    imageUrlList = imageUrlList.map((d) => d.url);
    return imageUrlList[Math.floor(randomNum * imageUrlList.length)];
};

const getBroadInfo = ({ week, hour, min }) => {
    let broadInfo = '';

    if (week) {
        let weekInfo = '';
        weekInfo = Array.isArray(week) ? week.join() : week;
        if (weekInfo === '월화수목금') {
            weekInfo = '월-금';
        }
        broadInfo += weekInfo !== '' ? `${weekInfo}` : '';
    }

    if (hour !== null && hour !== '') {
        if (parseInt(hour) > 12) {
            hour = parseInt(hour) - 12;
            hour = `오후${hour}`;
        } else if (parseInt(hour) === 12) {
            hour = `오후${parseInt(hour)}`;
        } else {
            hour = `오전${parseInt(hour)}`;
        }

        broadInfo += ` ${hour}`;
    }

    if (min !== null && min !== '') {
        broadInfo += `:${min}`;
    }

    return broadInfo;
};

// 특정 테마 띠
export const useThemeListBySeq = (themeSeq) => {
    return useAbortSWR([`/v2/operator/theme/${themeSeq}`], {
        params: {
            contentOutputLimit: 20,
            pocCode: 'POCD0400',
            cacheTime: 5,
        },
    });
};

// 공개예정작 - 상세
export const usePreReleaseFeed = ({ programCode = null }, swrOptions) => {
    return useAbortSWR(
        [programCode ? `/v2/media/prerelease/feed/${programCode}` : null],
        {
            params: {},
        },
        swrOptions,
        (data) => {
            // data.body
            // clip_code: "RV22436960"
            // image: "/upload/cms/cair/clip/P735743922/CLIP_RV22436960.jpg"
            // isMore: true
            // live_flag: "N"
            // open_day: "20211028"
            // open_text: "공개까지 D - 0"
            // open_type: "DDAY"
            // order_fixed_yn: "N"
            // synopsis: "진짜 가수 찾기가 시작된다! 더욱더 강력해져서 돌아온 <히든싱어3>"
            // view_type: "CLIP"
            // vod_code: "P000539939"
            // vod_name: "히든싱어 3"
            // vod_type: "CSMD0100"
            return data;
        },
    );
};

// 콘텐츠 데이터 수정중
export const useContentInfo = ({ contentType = '', mediaCode = '' }, swrOptions = {}) => {
    const [randomNum] = useState(Math.random());
    const { data, isLoading, isError, mutate } = useAbortSWR(
        [`${API_URLS.CONTENT_INFO}?mediaCode=${mediaCode}`],
        {
            withCredentials: true,
        },
        swrOptions,
        (data) => {
            return data.body.content;
        },
    );
    const target = data || {};
    const mediaType = target.vod_type || '';
    const returnData = {};
    let targetData = target;
    const isMovie = contentType === 'movie';

    let thumbnailStr;
    let mobileThumbnailStr;
    // mediaType 별 구분해서 Proxy
    targetData = target;

    switch (mediaType) {
        case 'CSMD0100': // Program or Episode
            returnData.type = 'program';
            returnData.frequency = target.frequency || ''; // 회차
            returnData.displayTitle = target.display_title || ''; // 회차 overrided title
            returnData.episodeSort = target.episode_sort?.toString() || targetData?.episode_sort?.toString() || ''; // 회차보기 정렬
            // integer 값이어서 0인 경우 false로 인식되어 빈값으로 정의되므로 string 처리 필요
            returnData.fanYn = targetData.fan_yn || ''; // 찜 여부
            returnData.story = targetData.synopsis || ''; // 스토리 정보
            returnData.duration = targetData.duration || '0'; // 컨텐츠 전체시간

            returnData.broadcastDate = targetData?.broad_dt.toString() || '';

            thumbnailStr = targetData?.image?.filter((d) => d.code === 'CAIP0900')[0]?.url || '';
            mobileThumbnailStr =
                getVerticalImage({
                    contentType: 'program',
                    imageList: targetData?.image,
                    randomNum,
                }) || '';

            returnData.mobileThumbnail = mobileThumbnailStr ? imgOrigin + mobileThumbnailStr : '';

            // 시즌 코드가 없을 경우 없는 시즌이 없는 프로그램 전체 가 나옴
            returnData.seasonPgmNo = targetData?.season_no || '';
            returnData.seasonPgmCode = targetData?.season_code || '';
            returnData.isDrm = target?.drm_yn === 'Y'; // DRM 여부
            returnData.isParamount = target?.paramount_yn === 'Y'; // 파라마운트 여부
            returnData.isApple = target?.apple_yn === 'Y'; // 애플TV 콘텐츠 여부
            returnData.ko_cc_yn = target?.ko_cc_yn === 'Y'; // 해설자막 여부

            break;
        case 'CSMD0200': // Movie
            returnData.type = 'movie';
            returnData.fanYn = target.fan_yn || ''; // 찜 여부
            returnData.story = target.synopsis || ''; // 스토리 정보
            // releaseDate 값이 없는 경우 number 0 으로 내려옴, 화면 노출 시 substring 함수 사용을 위해 타입 number -> string으로 변경.
            returnData.releaseDate = String(target?.release_date ?? 0);
            returnData.duration = target.duration || '0'; // 컨텐츠 전체시간

            thumbnailStr = targetData?.image?.filter((d) => d.code === 'CAIM2100')[0]?.url || '';
            mobileThumbnailStr =
                getVerticalImage({
                    contentType: 'movie',
                    imageList: targetData?.image,
                    randomNum,
                }) || '';
            returnData.seasonMovieCode = targetData?.season_code || ''; // 시즌 코드
            returnData.isDrm = targetData?.drm_yn === 'Y'; // DRM 여부
            returnData.isParamount = targetData?.paramount_yn === 'Y'; // 파라마운트 여부
            returnData.isApple = targetData?.apple_yn === 'Y'; // 애플TV 콘텐츠 여부
            returnData.ko_cc_yn = targetData?.ko_cc_yn === 'Y'; // 해설자막 여부
            break;
        default:
            break;
    }

    returnData.seasonCode = targetData?.season_code || '';
    returnData.thumbnail = thumbnailStr ? imgOrigin + thumbnailStr : '';
    returnData.mobileThumbnail = mobileThumbnailStr ? imgOrigin + mobileThumbnailStr : '';
    const logoImage = targetData?.image?.filter((d) => d.code === 'CAIP1800' || d.code === 'CAIM1800')[0]?.url;
    returnData.logoImage = logoImage && imgOrigin + logoImage;

    // 프로그램 코드 수정하기
    returnData.programCode = isMovie ? targetData?.code : targetData?.program_code; // 프로그램 코드

    returnData.contentName = targetData?.title || ''; // 콘텐츠명 (프로그램명 / 영화명)
    returnData.directorsName = targetData?.director?.join(', ') || ''; // 감독명
    returnData.actorsName = targetData?.actor?.join(', ') || ''; // 출연자명
    returnData.channelName = targetData?.channel || ''; // 채널명
    returnData.channelCode = targetData?.channel_code || ''; // 채널코드

    // 태그
    returnData.ageGrade = getAgeGrade(targetData?.grade_code); // 시청 연령
    returnData.gradeCode = targetData?.grade_code; // 시청 등급코드
    returnData.isIndividualPurchase = targetData?.billing_package_tag === 'single'; // 개별구매 여부
    returnData.isFirstRelease = targetData?.first_open_yn === 'Y'; // 최초공개 여부
    returnData.isCinemaSame = targetData?.cine_same_yn === 'Y'; // 극장동시 여부
    returnData.isEvent = targetData?.event_yn === 'Y'; // 이벤트 여부
    returnData.isQuickVod = targetData?.quickup_yn === 'Y'; // 퀵VOD 여부
    returnData.productYear = targetData?.product_year || '';
    returnData.genre = targetData?.category_name || ''; // genre 값은 상세페이지 내 장르 태그 & 로깅 & SEO에 사용 (API 변경으로 파라미터 키 변동)
    returnData.categoryName1 = targetData?.category_name || '';
    returnData.categoryName2 = targetData?.genre_name || '';

    returnData.categoryCode = targetData?.category_code || ''; // 비슷한 콘텐츠 가져오기 위한 파라미터
    returnData.genreCode = targetData?.genre_code || ''; // TV프로그램 비슷한 콘텐츠 리스트 호출을 위한 장르 코드
    returnData.isSubtitle = targetData?.subtitle_ver_yn === 'Y'; // 자막 여부
    returnData.isDubbing = targetData?.dub_ver_yn === 'Y'; // 더빙 여부
    returnData.isExclusive = targetData?.tving_exclusive_yn === 'Y'; // 티빙 온리 여부
    returnData.isOriginal = targetData?.tving_original_yn === 'Y'; // 티빙 오리지널 여부
    returnData.broadInfo = getBroadInfo({
        week: targetData?.broad_week,
        hour: targetData?.broad_hour,
        min: targetData?.broad_minu,
    });
    returnData.broadState = targetData?.broad_state || ''; // 방송 상태
    // 방영상태 - CPBS0100:방영전/CPBS0200:방영/CPBS0300:종영(201608개편 추가)
    returnData.broadEndDate = targetData?.broad_end_dt || ''; // 방송 종료 날짜
    returnData.saleStatus = targetData?.sale_status || ''; // 판매종료 여부를 위한 상태값

    // 윈도우 환경을 위한 특정 유니코드 문자열 치환
    returnData.story = replaceWeirdUnicodeWithBlank(returnData.story);

    return {
        data: returnData,
        isLoading,
        isError,
        mutate,
    };
};

// 컨텐츠 상세
export const useContentDetail = ({ contentType = '', mediaCode = '' }, swrOptions = {}, preSwrOptions = {}) => {
    const { data, isLoading, isError } = useAbortSWR(
        [`/v2/media/${contentType}/${mediaCode}`],
        {
            params: {
                cacheType: 'main',
                pageNo: 1,
                pageSize: 5,
                order: 'new',
                adult: 'all',
                free: 'all',
                guest: 'all',
                scope: 'all',
                lastFrequency: 'Y',
                personal: 'N',
            },
        },
        swrOptions,
        (data) => {
            // console.log('#test', data.body);
            // return proxyProgram({}, data.body);
            return data;
        },
    );

    const {
        data: preData,
        isLoading: isPreLoading,
        isError: isPreError,
    } = usePreReleaseFeed(data.body !== null ? { programCode: mediaCode } : {}, preSwrOptions);

    if (data.body && !isPreLoading) {
        // Proxy..
        const { name: { ko: title = '' } = {} } = data.body || {}; // 상세.
        const { vod_name } = preData.body || {}; // 예정작 상세.

        return {
            data: {
                title,
                isPreRelease: !!vod_name,
            },
            isLoading: false,
            isError: false,
        };
    }

    return {
        data: {},
        isLoading: isLoading || isPreLoading,
        isError: isError || isPreError,
    };
};

/**
 * 특정 하이라이트 구좌 API 호출.
 * @return {Object|boolean} data, source, isLoading, isError Value.
 */
export const useHighlightsData = ({ positionKey = '' }) => {
    const { data, isLoading, mutate } = useAbortSWR([positionKey ? `/v2/operator/highlights?positionKey=${positionKey}` : null], {
        params: {
            cacheTime: 5,
        },
    });
    return {
        data: data?.body || null,
        isLoading,
        mutate,
    };
};
