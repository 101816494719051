import compareVersion, { VersionCompareResult } from '@tving/utils/src/utils/common/compareVersion/compareVersion';
import { isServer } from '@utils/device';
import { isWebview as clientSideIsWebview, webviewAppVersion as clientSideWebviewAppVersion } from '@utils/device/device-detect';

const MIN_SUPPORT_VERSION = '24.35.01';

const supportAppWidthSendbird = (serverSideParams?: { isWebview: boolean; webviewAppVersion: string }) => {
    const isWebview = isServer() ? serverSideParams?.isWebview : clientSideIsWebview;
    const webviewAppVersion = isServer() ? serverSideParams?.webviewAppVersion : clientSideWebviewAppVersion;

    if (!isWebview) {
        return true;
    }

    const currentVersion = webviewAppVersion || '';
    return compareVersion(currentVersion, MIN_SUPPORT_VERSION) !== VersionCompareResult.LessThan;
};

export default supportAppWidthSendbird;
