import { AxiosRequestConfig } from 'axios';

import { API_URLS } from '../../constants/apiConstants';
import { GATEWAY_URL } from '../../utils/common/apiConfig';
import httpGet from '../../utils/http/httpGet';
import { NewTvingResponse, ProvisioningResponse } from '../types';

export interface ProvisioningParameter {
  poc: 'web' | 'tv'; // path parameter
  region: string;
}

const fetchProvisioning = (requestParameter: ProvisioningParameter, requestConfig: AxiosRequestConfig = {}): Promise<ProvisioningResponse> => {
  const { poc, region } = requestParameter;
  const headers = { menuVersion: 'v1.0', region }; // pc, tv는 메뉴 버전 없기 때문에 v1.0으로 고정
  // const newHeaders = { ...requestConfig.headers, menuVersion: 'v1.0', region };
  // console.log('newHeaders', newHeaders);

  const filledRequestConfig = {
    ...requestConfig,
    headers,
  };

  // 국가코드 유무에 따라 host 상이함
  return httpGet<NewTvingResponse<ProvisioningResponse>>(`${GATEWAY_URL}${API_URLS.PROVISIONING.replace('{poc}', poc)}`, filledRequestConfig)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
};

export default fetchProvisioning;
