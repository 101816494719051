import { useEffect } from 'react';

const MswWrapper = () => {
    useEffect(() => {
        if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
            import('../../mocks/browser');
        }
    }, []);

    return null;
};

export default MswWrapper;
