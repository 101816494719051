// code로 시청 연령 확인
export const getAgeGrade = code => {
    let ageGrade = '';
    switch (code) {
        case 'CPTG0100': // 프로그램 전체
        case 'CMMG0100': // 영화 전체
            ageGrade = 'all';
            break;
        case 'CPTG0200':
            ageGrade = '7';
            break;
        case 'CPTG0300': // 프로그램 12세
        case 'CMMG0200': // 영화 12세
            ageGrade = '12';
            break;
        case 'CPTG0400': // 프로그램 15세
        case 'CMMG0300': // 영화 15세
            ageGrade = '15';
            break;
        case 'CMMG0400': // 영화 18세
            ageGrade = '18';
            break;
        case 'CPTG0500': // 프로그램 19세
            ageGrade = '19';
            break;
        default:
            break;
    }
    return ageGrade;
};

export const changeBandCode = bandList => {
    // console.log('changeBandCode');
    if (bandList && Array.isArray(bandList)) {
        bandList = bandList.filter(function (item) {
            return (
                item.expose_type !== 'SL' && // 인기스타일
                item.expose_type !== 'MT' && // 뮤직테마
                item.expose_type !== 'CO' // 인기클립
            );
            // ;
        });
        bandList.map((item, idx) => {
            // 한번 변경된 item data 는 변경하지 않게.
            if (item.isChanged) {
                return;
            }
            item.isChanged = true;

            //
            // item.expose_type = item.expose_type.replace('tvingtv', 'live');
            // item.expose_type = item.expose_type.replace('movieChannel', 'liveBasic');

            //
            switch (item.expose_type) {
                case 'BM':
                    // 배너
                    item.expose_type = 'banner';
                    break;
                case 'L':
                    // 시청중인 콘텐츠 (vodWatch)
                    item.expose_type = 'vodWatch';
                    // item.expose_type = 'tvingOrigin'; // 오리지널띠 테스트
                    break;
                case 'LH':
                    // TV 기본 & 영화채널
                    item.expose_type = 'liveBasic';
                    break;
                case 'LO':
                    // 정주행 랭킹 & TV 랭킹
                    item.expose_type = 'liveRank';
                    break;
                case 'VV':
                    // 프로그램 기본
                    item.expose_type = 'programBasic';
                    break;
                case 'VO':
                    // 프로그램 랭킹
                    item.expose_type = 'programRank';
                    break;
                case 'M_VV': // 프로그램 테마
                    // 영화 기본
                    item.expose_type = 'programTheme';
                    break;
                case 'MV':
                case 'M_MV': // 영화 테마
                    // 영화 기본
                    item.expose_type = 'movieBasic';
                    break;
                case 'MO':
                    // 영화 랭킹
                    item.expose_type = 'movieRank';
                    break;
                case 'VH': /// !!!!!!!!!!!!
                    // Quick VOD
                    item.expose_type = 'quickVod';
                    break;
                case 'G':
                    // 장르
                    item.expose_type = 'genre';
                    break;
                case 'E':
                    // 이벤트
                    item.expose_type = 'event';
                    break;
                case 'ADB':
                    // 광고
                    item.expose_type = 'ad';
                    break;

                default:
                    // item.expose_type = 'programRank';
                    break;
            }
        });
    }
    return bandList;
};
