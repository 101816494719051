module.exports = {
    locales: ['ko'],
    defaultLocale: 'ko',
    pages: {
        '*': ['common'],
    },
    // loadLocaleFrom: (lang, ns) =>
    //     // You can use a dynamic import, fetch, whatever. You should
    //     // return a Promise with the JSON file.
    //     import(`./public/locales/${lang}/${ns}.json`).then(m => m.default),

    // loadLocaleFrom: (lang, ns) =>
    //     import(`./locales/${lang}/${ns}.json`)
    //         .catch(e => console.error(e))
    //         .then(m => m.default),
};
