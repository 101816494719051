export const colors = {
    black: '#000',
    lightBlack: '#191919',
    darkBlack: '#2e2e2e',
    white: '#fff',
    darkWhite: '#dedede',
    gray: '#888',
    whiteGray: '#a3a3a3',
    lightGray: '#6e6e6e',
    darkGray: '#4e4e4e',
    silver: '#d3d3d3',
    red: '#ff153c',
    point: '#ff1f45',
};

export const palette = {
    grey50: '#f9fafb',
    grey100: '#f2f4f6',
    grey200: '#e5e8eb',
    grey300: '#d1d6db',
    grey400: '#b0b8c1',
    grey500: '#8b95a1',
    grey600: '#6b7684',
    grey700: '#4e5968',
    grey800: '#333d4b',
    grey900: '#191f28',
    greyOpacity50: 'rgba(0,23,51,0.02)',
    greyOpacity100: 'rgba(2,32,71,0.05)',
    greyOpacity200: 'rgba(0,27,55,0.1)',
    greyOpacity300: 'rgba(0,29,58,0.18)',
    greyOpacity400: 'rgba(0,29,54,0.31)',
    greyOpacity500: 'rgba(3,24,50,0.46)',
    greyOpacity600: 'rgba(0,19,43,0.58)',
    greyOpacity700: 'rgba(3,18,40,0.7)',
    greyOpacity800: 'rgba(0,12,30,0.8)',
    greyOpacity900: 'rgba(2,9,19,0.91)',
    red50: '#ffebee',
    red100: '#ffcdd2',
    red200: '#ef9a9a',
    red300: '#e57373',
    red400: '#ef5350',
    red500: '#f44336',
    red600: '#ff153c',
    red700: '#d32f2f',
    red800: '#c62828',
    red900: '#b71c1c',
    white: '#fff',
    whiteOpacity50: 'rgba(209,209,253,0.05)',
    whiteOpacity100: 'rgba(217,217,255,0.11)',
    whiteOpacity200: 'rgba(222,222,255,0.19)',
    whiteOpacity300: 'rgba(224,224,255,0.27)',
    whiteOpacity400: 'rgba(232,232,253,0.36)',
    whiteOpacity500: 'rgba(255,255,255,0.64)',
    whiteOpacity600: 'rgba(248,248,255,0.6)',
    whiteOpacity700: 'rgba(253,253,255,0.75)',
    whiteOpacity800: 'rgba(253,253,254,0.89)',
    whiteOpacity900: '#fff',
    inverseGrey50: '#212121',
    inverseGrey100: '#2e2e2e',
    inverseGrey200: '#3c3c47',
    inverseGrey300: '#4e4e4e',
    inverseGrey400: '#6e6e6e',
    inverseGrey500: '#7e7e87',
    inverseGrey600: '#a3a3a3',
    inverseGrey700: '#c3c3c6',
    inverseGrey800: '#dedede',
    inverseGrey900: '#fff',
    black: '#000',
    black50: '#191919',
    blue50: '#d8dfef',
    blue100: '#dbdee5',
    blue800: '#184ab9',
    blue900: '#053296',
};
