import Link from 'next/link';
import { ThumbnailImage, ThumbnailPlay } from '@components/item/ItemComponent';
import { isMobile } from '@utils/device/device-detect';
import { useRouter } from 'next/router';
import { FreeLabel } from '@components/item/ItemComponent';

const ClipItem = ({
    href,
    idx,
    ratio,
    type, // 타입 - movie / episode
    title, // 타이틀
    subtitle, // 서브타이틀
    thumbnail, // 썸네일 이미지
    isLazy,
    code,
    onClick,
    isPayUser,
}) => {
    // console.log(type, billingTag, href);
    const router = useRouter();
    const ratioClassName = ratio ? `item__thumb-${ratio}` : '';

    const mediaLink = e => {
        if (isMobile) {
            // isMobile 링크 동작 안하게.
            e.preventDefault();
            e.stopPropagation();

            router.push({
                pathname: `/player/${code}`,
                // query: { pid: post.id },
            });
        } else {
            // router.push({
            //     pathname: `/player/${code}`,
            //     // query: { pid: post.id },
            // });
        }
        onClick?.(e);
    };
    return (
        <Link scroll href={`/player/${code}`} passHref>
            <a onClick={mediaLink}>
                <div className={`item__thumb ${ratioClassName}`}>
                    <div className="item__tags">
                        <div className="item__tags-left">{!isPayUser && <FreeLabel />}</div>
                    </div>

                    {/* 컨텐츠(포스터) */}
                    <ThumbnailImage thumbnail={thumbnail} title={title} isLazy={isLazy} />
                    {/* 썸네일 플레이 버튼 */}
                    <ThumbnailPlay isOnlyMobile />
                </div>

                {/* <!--컨텐츠 정보S--> */}
                <div className="item__info continue">
                    <p className="item__subtitle item__line-2">{title}</p>
                </div>
            </a>
        </Link>
    );
};

export default ClipItem;
