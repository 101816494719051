import { isMobile } from '@utils/device/device-detect';

/**
 * @param eventName 접미사를 제외한 이벤트명
 * @returns 디바이스 타입에 따른 이벤트명
 *
 * @example
 * const eventName = getEventNameByDevice('click_contents_detail'); // click_contents_detail_mo
 */
const getEventNameByDevice = (eventName: string) => {
    if (!eventName) {
        throw new Error('eventName은 필수값입니다.');
    }

    const suffix = isMobile ? '_mo' : '_pc';
    return eventName + suffix;
};

export default getEventNameByDevice;
