import throttle from 'lodash/throttle';

import { EventOptions, RemoveListener } from './types';

interface ResizeEvent {
  width: number;
  height: number;
}

export type ResizeCallback = (event: ResizeEvent) => void;

/**
 * Webview 환경을 고려한 Cross Platform Resize 이벤트 리스너
 */
export const addResizeEvent = (callback: ResizeCallback, options?: EventOptions): RemoveListener => {
  const handleResize = throttle(
    (params: ResizeEvent) => {
      callback?.(params);
    },
    undefined,
    {
      leading: true,
      trailing: true,
    },
  );

  const dispatchResizeEvent = () => {
    handleResize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const supportScreenOrientation =
    typeof window.screen.orientation !== 'undefined' && typeof window.screen.orientation.addEventListener === 'function';

  window.addEventListener('resize', dispatchResizeEvent, options);
  window.addEventListener('orientationchange', dispatchResizeEvent, options);
  if (supportScreenOrientation) {
    window.screen.orientation.addEventListener('change', dispatchResizeEvent);
  }

  dispatchResizeEvent();
  requestAnimationFrame(() => {
    setTimeout(() => {
      dispatchResizeEvent();
    }, 100);
  });

  return () => {
    window.removeEventListener('resize', dispatchResizeEvent, options);
    window.removeEventListener('orientationchange', dispatchResizeEvent, options);
    if (supportScreenOrientation) {
      window.screen.orientation.removeEventListener('change', dispatchResizeEvent);
    }
  };
};
