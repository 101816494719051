/**
 * const vodLiveItemProps = {
 *         className: `item__thumb ${ratioClassName}`,
 *         poster: {
 *             src: '//stillshot.tving.com/thumbnail/C01581_1_640x360.jpg/dims/resize/400',
 *             // src: thumbnail,
 *             alt: 'alt value',
 *         },
 *         labels: {
 *             type: 'SVG',
 *             free: {
 *                 className: 'top-[0.25rem] right-[0.25rem]',
 *                 isShow: true,
 *             },
 *         },
 *        text: {
 *             className: 'leading-[21.72px]',
 *             items: [
 *                 {
 *                     tag: 'h3',
 *                     title: 'tvn',
 *                     className: 'text-[15px] font-bold',
 *                 },
 *                 {
 *                     tag: 'h3',
 *                     title: '서진이네 1화',
 *                     className: 'text-[15px]',
 *                 },
 *             ],
 *         },
 *     };
 */

import Poster, { PosterProps } from '../../atom/poster/Poster';
import ProgressBar from '../../atom/progressBar/ProgressBar';
import Text, { TextProps } from '../../atom/text/Text';
import Labels, { LabelsProps } from '../labels/Labels';

type liveItemProps = {
  className?: string;
  poster: PosterProps;
  posterText: TextProps<React.ElementType>;
  labels: LabelsProps;
  text: {
    className: string;
    items: {
      tag?: React.ElementType;
      title: string;
      className?: string;
      tabIndex?: number;
    }[];
  };
  progressBar: {
    percent: number;
  };
  tabIndex?: number;
};

const LiveItem = ({ className, poster, posterText, text, tabIndex, labels, progressBar }: liveItemProps) => {
  return (
    <>
      <div className={`live-item-wrapper ${className}`} tabIndex={tabIndex}>
        {posterText && <Text {...posterText} />}
        <Poster {...poster} />
        <ProgressBar {...progressBar} />
        <Labels {...labels} />
      </div>
      <div className={text.className}>
        {text.items.map((text) => {
          return <Text tag={text.tag} title={text.title} className={text.className} tabIndex={tabIndex} />;
        })}
      </div>
    </>
  );
};

export default LiveItem;
