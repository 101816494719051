import IconArrowCirclePrice from '@tving/ui/src/images/icons/icon_arrow_circle_price.svg';
import IconArrowPrice from '@tving/ui/src/images/icons/icon_arrow_price.svg';
import IconCheck from '@tving/ui/src/images/icons/icon_check.svg';
import IconCheckFocus from '@tving/ui/src/images/icons/icon_check_focused.svg';
import IconCircleWarning from '@tving/ui/src/images/icons/icon_circle_warning.svg';
import IconClock from '@tving/ui/src/images/icons/icon_clock_five_hours.svg';
import IconClose from '@tving/ui/src/images/icons/icon_close.svg';
import IconCloseFocus from '@tving/ui/src/images/icons/icon_close_focused.svg';
import IconInfo from '@tving/ui/src/images/icons/icon_info.svg';
import IconPopupClose from '@tving/ui/src/images/icons/icon_popup_close.svg';

export {
    IconCheck,
    IconCheckFocus,
    IconClose,
    IconClock,
    IconCloseFocus,
    IconArrowPrice,
    IconArrowCirclePrice,
    IconInfo,
    IconPopupClose,
    IconCircleWarning,
};
