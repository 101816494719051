const checkWebPSupport = (callback: (supports: boolean) => void) => {
    const testImage = new Image();
    testImage.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';

    testImage.onload = event => {
        const result = testImage.width > 0 && event.type === 'load';
        callback(result);
    };

    testImage.onerror = () => {
        callback(false);
    };
};

export default checkWebPSupport;
