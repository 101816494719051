// KBO 경기 channel
export const KBO_CHANNEL_CODES = ['C51850', 'C51851', 'C51949', 'C51950', 'C51951', 'C52123'];
// export const KBO_CHANNEL_CODES: string[] = [];

// 구단별 채널
export const KBO_TEAM_CODES = ['C52296', 'C52294', 'C52295', 'C52293', 'C52292', 'C52291', 'C52290', 'C52289', 'C52288', 'C52287'];

// KBO 리그 6 채널코드
// 티빙슈퍼매치 등 라이브 채널 이동되기위함.
// QA & 상용 동일함.
export const KBO_LEAGE_SIX_CHANNEL_CODE = 'C52123';
