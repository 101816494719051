import initAmplitude from '@tving/utils/src/utils/amplitude/initAmplitude';
import { AMPLITUDE_KEY } from '@tving/utils/src/utils/common/apiConfig';
import useMount from '@tving/utils/src/hooks/common/useMount';

/**
 * 외부 플러그인의 초기화 등을 담당하는 최상위 컴포넌트입니다.
 * - Context Provider Wrapper와는 상이합니다. (children을 지원하지 않습니다.)
 * - <ToastContainer/> 등 최상위에서 사용되는 컴포넌트를 관리합니다.
 *
 * 기존에는 HOC 내에서 플러그인을 초기화하였고
 * 이 과정에서 상위/하위 컴포넌트의 Effect 실행 순서에 의해
 * 플러그인 초기화 전 플러그인을 사용하는 문제가 있었습니다.
 *
 * 이를 해결하기 위해 HOC가 아닌 별도의 컴포넌트로 분리하게 되었습니다.
 * 아래 예제에서 부모, 자식 컴포넌트의 렌더링/Effect 실행 순서를 확인할 수 있습니다.
 * @see {@link https://codesandbox.io/s/react-effect-mount-unmount-execution-order-and-reference-yuwysq?file=/src/App.js CodeSandbox Demo}
 */
const IntegrationManager = () => {
    useMount(() => {
        initAmplitude({ ApiKey: AMPLITUDE_KEY });
    });

    return null;
};

export default IntegrationManager;
