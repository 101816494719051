const setNavigatorOnline = ({ status }: { status: boolean }) => {
  Object.defineProperty(navigator, 'onLine', { value: status, writable: true });
};

const handleOnline = () => {
  setNavigatorOnline({ status: true });
};
const handleOffline = () => {
  setNavigatorOnline({ status: false });
};

const forceNavigatorOnline = () => {
  setNavigatorOnline({ status: true });

  window.addEventListener('online', handleOnline);
  window.addEventListener('offline', handleOffline);
};

export const cleanup = () => {
  window.removeEventListener('online', handleOnline);
  window.removeEventListener('offline', handleOffline);
};

export default forceNavigatorOnline;
