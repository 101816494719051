import facepaint from 'facepaint';

export const size = {
    mobileS: 320,
    mobileR: 360,
    mobileM: 375,
    mobileL: 411,
    tablet: 768,
    laptop: 1200,
    desktop: 1600,
};

const breakpoints = [768];
export const mq = facepaint(breakpoints.map(bp => `@media (min-width: ${bp}px)`));

export const device = {
    mobile: `(max-width: ${size.tablet - 1}px)`,
    mobileS: `(min-width: ${size.mobileS}px)`,
    mobileR: `(min-width: ${size.mobileR}px)`,
    mobileM: `(min-width: ${size.mobileM}px)`,
    mobileL: `(min-width: ${size.mobileL}px)`,
    tablet: `(min-width: ${size.tablet}px)`,
    tabletOnly: `(min-width: ${size.tablet}px) and (max-width: ${size.laptop - 1}px)`,
    laptopS: `(max-width: ${size.laptop - 1}px)`,
    laptop: `(min-width: ${size.laptop}px)`,
    desktop: `(min-width: ${size.desktop}px)`,
    landscape: `(orientation: landscape)`,
    portrait: `(orientation: portrait)`,
};
