import { v4 as uuidV4 } from 'uuid';

/**
 * 랜덤한 ID를 생성합니다.
 * @see {@link https://github.com/toss/overlay-kit/blob/main/packages/src/utils/random-id.ts 기존 생성 방식 참고}
 */
const generateRandomId = () => {
  return `tving-overlay-kit-${uuidV4()}`;
};

export default generateRandomId;
