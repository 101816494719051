import dayjs from 'dayjs';

import { SPORTS_TYPE } from '@typings/sports';

const BFF_DEFAULT = ['sports', 'bff'];

export const SPORTS_QUERY_KEYS = {
    /** 모든 쿼리 키 */
    ALL: BFF_DEFAULT,
    /**
     * 메인 페이지 (홈, 영상, 일정, 기록/순위 탭 등)
     */
    MAIN: (sportsType: string, tab: string, queryParams: { [key: string]: string | null }) =>
        [...BFF_DEFAULT, 'main', sportsType, tab, queryParams] as const,
    SCHEDULE: ({ type, key }: { type: 'date' | 'team'; key: dayjs.Dayjs }) =>
        [...BFF_DEFAULT, 'home', 'schedule', { type, key: dayjs(key).format('YYYYMMDD') }] as const,
    SCHEDULE_DATE_PICKER: ({ date }: { date: string }) => [...BFF_DEFAULT, 'home', 'schedule', 'date-picker', date] as const,
    MORE_BAND_ITEMS: (apiUrl: string) => [...BFF_DEFAULT, 'more-band-items', apiUrl] as const,
    /**
     * 게임 상세 관련 쿼리* @constructor
     */
    CONTENTS_MAIN: (gameCode: string) => [...BFF_DEFAULT, 'live', gameCode] as const,
    CONTENTS: {
        MAIN: (gameCode: string) => [...BFF_DEFAULT, 'live', gameCode] as const,
        LINEUP: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'lineup'] as const,
        POWER: (gameCode: string) => [...BFF_DEFAULT, 'live', gameCode, 'power'] as const,
        BROADCAST: (gameCode: string, inning?: number) => [...BFF_DEFAULT, 'live', gameCode, 'broadcast', inning || ''] as const,
        CLIP: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'clip'] as const,
        RUNS: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'runs'] as const,
        RECORD: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'record'] as const,
    },
    CONTENTS_MULTIVIEW: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'multi-view'] as const,
    CLIP_MAIN: (code: string) => [...BFF_DEFAULT, 'clip', code] as const,
    MORE_VIDEO: ({ pathname, params }) => [...BFF_DEFAULT, 'more', pathname, params] as const,
    MORE_SHORTS: ({ pathname, params }) => [...BFF_DEFAULT, 'more', pathname, params] as const,
    MORE_SCHEDULE: (params: { date?: string; sportsType: SPORTS_TYPE }) =>
        [
            ...BFF_DEFAULT,
            'more',
            'schedule',
            'playlist',
            params.sportsType,
            {
                date: params.date ?? null,
            },
        ] as const,
    GAME_LIST: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'schedule'] as const,
    CONTENTS_BROADCAST: (gameCode: string, period?: number) => [...BFF_DEFAULT, 'live', gameCode, 'broadcast', period || ''] as const,
    CONTENTS_POINT: (gameCode: string) => [...BFF_DEFAULT, 'stream', gameCode, 'point'] as const,
    SEARCH: {
        ALL: () => [...BFF_DEFAULT, 'search'] as const,
        STARTUP_ALL: () => [...SPORTS_QUERY_KEYS.SEARCH.ALL(), 'startup'] as const,
        STARTUP: ({ sportsType }: { sportsType: SPORTS_TYPE }) => [...SPORTS_QUERY_KEYS.SEARCH.STARTUP_ALL(), sportsType] as const,
        RECOMMEND_KEYWORDS_ALL: () => [...SPORTS_QUERY_KEYS.SEARCH.ALL(), 'recommend-keywords'] as const,
        RECOMMEND_KEYWORDS: ({ sportsType }: { sportsType: SPORTS_TYPE }) =>
            [...SPORTS_QUERY_KEYS.SEARCH.RECOMMEND_KEYWORDS_ALL(), sportsType] as const,
        RESULTS_ALL: () => [...SPORTS_QUERY_KEYS.SEARCH.ALL(), 'results'] as const,
        RESULTS: ({ query, sportsType }: { query: string; sportsType: SPORTS_TYPE }) =>
            [...SPORTS_QUERY_KEYS.SEARCH.RESULTS_ALL(), 'asQuery', sportsType, query] as const,
        RESULTS_PAGINATION: ({ nextApiUrl, sportsType }: { nextApiUrl: string; sportsType: SPORTS_TYPE }) =>
            [...SPORTS_QUERY_KEYS.SEARCH.RESULTS_ALL(), 'asPagination', sportsType, nextApiUrl] as const,
    },
    ATHLETE_LEADERBOARD: (apiUrl) => [...BFF_DEFAULT, 'athlete', 'leaderboard', apiUrl] as const,
    RANKINGS: () => [...BFF_DEFAULT, 'ranking'] as const,
    RANKING: (filters: { tab: string; yearSeason?: string; gameSeason?: string; athleteType?: string }) =>
        [
            ...SPORTS_QUERY_KEYS.RANKINGS(),
            {
                tab: filters.tab ?? null,
                yearSeason: filters.yearSeason ?? null,
                gameSeason: filters.gameSeason ?? null,
                athleteType: filters.athleteType ?? null,
            },
        ] as const,
    RANKING_SORTED: (filters: { tab: string; yearSeason: string; gameSeason: string; athleteType?: string; rankOrder?: string }) =>
        [
            ...SPORTS_QUERY_KEYS.RANKING(filters),
            {
                rankOrder: filters.rankOrder ?? null,
            },
        ] as const,
};
