import Link from 'next/link';
import { ThumbnailImage } from '@components/item/ItemComponent';
import React from 'react';

const OriginalItem = ({
    href,
    idx,
    type,
    bandTitle, // 진입한 띠 명
    bandType,
    title,
    ratio,
    subtitle,
    code,
    contentType,
    thumbnail,
    grade,
    gradeCode, // 연령 등급
    ageGrade, // 시청 가능 연령
    isAdult,
    isExclusive,
    isOriginal,
    isLazy,
    onClick,
}) => {
    isOriginal = true;
    const ratioClassName = ratio ? `item__thumb-${ratio}` : '';

    const moveLink = e => {
        onClick?.(e);
    };

    return (
        <Link scroll href={href} passHref>
            {/* 컨텐츠(포스터)전체S:.item 단위 */}
            <a className="thum_position" onClick={e => moveLink(e)}>
                {/* 컨텐츠(포스터) */}
                <div className={`item__thumb ${ratioClassName}`}>
                    {/* 컨텐츠(포스터) */}
                    <ThumbnailImage thumbnail={thumbnail} title={title} isLazy={isLazy} size={600} />
                </div>
            </a>
        </Link>
    );
};
export default OriginalItem;
