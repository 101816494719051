import React, { useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/router';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useCrossPlatform from '@utils/crossPlatform/react/useCrossPlatform';
import useTranslation from 'next-translate/useTranslation';
import { shallow } from 'zustand/shallow';

import { Button, Icon, Text } from '@tving/ui';
import useIsomorphicLayoutEffect from '@tving/utils/src/hooks/common/useIsomorphicLayoutEffect';
import amplitudeEvent from '@tving/utils/src/utils/amplitude/amplitudeEvent';
import { getAmplitudeDeviceName } from '@tving/utils/src/utils/amplitude/getAmplitudeEventInfo';
import copyToClipboard from '@tving/utils/src/utils/browser/copyToClipboard';
import getCurrentPageUrl from '@tving/utils/src/utils/browser/getCurrentPageUrl';
import isClipContentsByUrl from '@tving/utils/src/utils/isClipContentsByUrl/isClipContentsByUrl';

import { KboIconShare } from '@components/kbo/common/svg';
import Modal from '@components/Modal';
import { GET_APP_LINK_URL, SUB_BOARD_SHARE_POSSIBLE_URLS } from '@constants/urls';
import useDomLoaded from '@hooks/useDomLoaded';
import useOutsideClick from '@hooks/useOutsideClick';
import shareIcon from '@img/icon_share.svg';
import { useLogging, useSdkLoadedList } from '@store/state';
import useSportsTypeStore from '@stores/sports/useSportsTypeStore';
import useClientStore from '@stores/useClientStore';
import { getScreenName } from '@utils/items/utils';
import cnx from '@utils/tailwind/cnx';
import transformRedirectUrlPath from '@utils/transformRedirectUrlPath';

import styles from './styles.module.scss';

const HORIZONTAL_POSITION_THRESHOLD = 18;

type ShareButtonOptions =
    | {
          isOnlyIcon?: boolean;
      }
    | undefined;

const makeCornerRounded = (mapper: (vector: number) => string) =>
    Array.from({ length: 9 }, (_, i) => ((Math.PI / 2) * i) / 8)
        .map(mapper)
        .join(',');

const StyledResultPopup = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;

    z-index: 100;
    margin-top: -8em;
    padding: 1.25em 1.5em 2em;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));

    &:before {
        content: '';
        position: absolute;
        border-radius: 0.25rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        clip-path: polygon(
            0% 0%,
            100% 0%,
            ${makeCornerRounded(
                (vector: number) => `calc(100% - ${(1 - Math.cos(vector)) * 0.25}rem) calc(90% - ${(1 - Math.sin(vector)) * 0.25}rem)`,
            )},
            53.2% 90%,
            50% 100%,
            46.8% 90%,
            ${makeCornerRounded((vector: number) => `calc(0% + ${(1 - Math.sin(vector)) * 0.25}rem) calc(90% - ${(1 - Math.cos(vector)) * 0.25}rem)`)}
        );
        background-color: rgba(255, 255, 255, 0.9);

        ${({ isRight }) =>
            isRight &&
            css`
                clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 89.2% 90%, 86% 100%, 82.8% 90%, 0% 90%);
            `}
    }

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.2s, opacity 0.2s, transform 0.2s;
    transform: perspective(1px) translate3d(0, 10%, 0);

    ${({ isRight }) =>
        isRight
            ? css`
                  right: 4.1rem;

                  &.active {
                      visibility: visible;
                      opacity: 1;
                      transition: visibility 0s linear 0s, opacity 0.2s, transform 0.2s;
                      transform: perspective(1px) translate3d(0, 0, 0);
                  }
              `
            : css`
                  left: 50%;
                  transform: translate3d(-50%, 10%, 0);

                  &.active {
                      visibility: visible;
                      opacity: 1;
                      transition: visibility 0s linear 0s, opacity 0.2s, transform 0.2s;
                      transform: perspective(1px) translate3d(-50%, 0, 0);
                  }
              `}
    button {
        position: relative;
        opacity: 1;
        overflow: hidden;
        border-radius: 9900px;
        width: 3rem;
        height: 3rem;
        font-size: 1rem;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 100px;
            opacity: 0;
            transition: opacity 0.1s;
        }

        &:hover:after {
            opacity: 1;
        }
    }

    .btn_result {
        &:nth-of-type(n + 2) {
            margin-left: 1em;
        }
    }
`;

export const StyledContentActionInfoButton = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5.333rem;
    height: 5.333rem;
    text-align: center;
    margin: 0;
    opacity: 0.87;
    transition: opacity 0.1s;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    svg {
        width: 2.667rem;
        height: 2.667rem;
        margin-bottom: 0.75rem;
    }

    span {
        font-size: 1.167rem;
        font-weight: 500;
        line-height: 0.86;
        text-align: center;
        color: #fff;
    }
`;

const TextArea = styled.textarea`
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
`;

// 주의 new tving sub board 에서만 사용하는 컴포넌트

const SnsShareButton = (props) => {
    const router = useRouter();
    const crossPlatformController = useCrossPlatform();
    const { t } = useTranslation('common');
    const copyUrlRef = React.useRef();
    const [isPopActive, setIsPopActive] = useState(false);
    const { code, title, categoryName1, categoryName2, isLeft, style, options, supportLabel } = props;
    const [isKboShare, setIsKboShare] = useState(false);
    const [isSportsShare, setIsSportsShare] = useState(false);
    const [isClipShare, setIsClipShare] = useState(false);
    const [{ braze, brazeEvent }] = useLogging();
    const [loadedSdkList, setLoadedSdkList] = useSdkLoadedList();
    const { pathname } = useRouter();
    const { isWebview, isMobile } = useClientStore(
        (state) => ({
            isWebview: state.isWebview,
            isMobile: state.isMobile,
        }),
        shallow,
    );

    const isKboMorePage = pathname.startsWith('/kbo/more/');
    const isKbo = router.pathname.startsWith('/kbo');
    const isClip = isClipContentsByUrl(router.asPath);

    const sportsPatterns = [/^\/sports\/.*/, /^\/contents\/sports\/.*/];
    const isSports = sportsPatterns.some((pattern) => pattern.test(router.pathname));

    const isWeb = crossPlatformController.getCrossPlatformType() === 'WEB';
    const shareButtonOption = options as ShareButtonOptions;
    const { isOnlyIcon } = shareButtonOption ?? {};

    const { sportsType } = useSportsTypeStore((state) => ({ sportsType: state.sportsType }), shallow);

    // Function to get appropriate title based on sports type
    const getSportsTitle = (type: string | undefined) => {
        if (!type) {
            return 'Sports - TVING';
        }

        switch (type) {
            case 'KBO':
                return 'KBO - TVING';
            case 'KBL':
                return 'KBL - TVING';
            default:
                return `${type} - TVING`;
        }
    };

    // 공유하기 팝오버 외부 클릭 시 닫기
    const ref = useRef();
    useOutsideClick(ref, () => {
        if (isOnlyIcon) {
            if (isKbo) {
                toggleKboShare(false);
            } else {
                toggleSportsShare(false);
                toggleClipShare(false);
            }
        } else {
            setIsPopActive(false);
        }
    });

    // 카카오 버튼에 공유 기능 적용
    useEffect(() => {
        // 중복 initialization 방지
        const kakao = window.Kakao;
        if (kakao && !kakao.isInitialized()) {
            kakao.init(process.env.NEXT_PUBLIC_KAKAO_JAVASCRIPT_KEY);
        }
    }, []);

    /**
     * 공유하기 버튼 클릭 메소드
     */
    const clickShareButton = () => {
        onloadShareButton();
        setIsPopActive(!isPopActive);
    };

    const handleShareButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const currentUrl = new URL(window.location.href);
        let link = !isSports && (isWebview || isMobile) ? GET_APP_LINK_URL({ pathname: currentUrl.pathname }) : window.document.location.href;
        if (isKboMorePage) {
            const query = currentUrl.search;
            const pathname = `${transformRedirectUrlPath({ url: window.location.href })}${query || ''}`;
            link = GET_APP_LINK_URL({ pathname });
        }

        const shareButton = event.target as HTMLButtonElement;
        if (shareButton) {
            const { x, y, width, height } = shareButton.getBoundingClientRect();

            crossPlatformController.shareLink({
                x,
                y,
                width,
                height,
                url: link,
                title: isKbo ? 'KBO - TVING' : isSports ? getSportsTitle(sportsType) : '',
            });
        }

        if (isWeb && isMobile) {
            try {
                navigator.share({
                    url: link,
                    title: isKbo ? 'KBO - TVING' : isSports ? getSportsTitle(sportsType) : '',
                });
            } catch (error) {
                !isPopActive && clickShareButton();
                isPopActive && toggleKboShare(false);
                isPopActive && toggleSportsShare(false);
                isPopActive && toggleClipShare(false);
            }
            return;
        }

        if (isWeb) {
            !isPopActive && clickShareButton();
            isPopActive && toggleKboShare(false);
            isPopActive && toggleSportsShare(false);
            isPopActive && toggleClipShare(false);
        }
    };

    const toggleKboShare = (value: boolean) => {
        setIsKboShare(value);
    };

    const toggleSportsShare = (value: boolean) => {
        setIsSportsShare(value);
    };

    const toggleClipShare = (value: boolean) => {
        setIsClipShare(value);
    };

    const handleKboSportsShareTransitionEnd = () => {
        if (!isKboShare && !isSportsShare && !isClipShare) {
            setIsPopActive(false);
        }
    };

    /**
     * 공유하기 Amplitude Event
     */
    const sendAmplitudeEventWhenSharing = (sharedChannel) => {
        const device = getAmplitudeDeviceName({ isMobile, isWebview });

        amplitudeEvent({
            event: `click_share_${device}`,
            eventProperties: {
                screen_name: getScreenName({ pathName: pathname }),
                shared_channel: sharedChannel,
                title_name: title,
                sports_type: isSports ? (sportsType as string) : '',
                code,
            },
        });
    };
    /**
     * 공유하기 > 페이스북 메소드
     */
    const clickShareFacebook = () => {
        // const url = snsReturnUrl(); // props.code
        const currentUrl = new URL(window.location.href);
        let linkUrl = window.location.href;
        if (isKbo) {
            linkUrl = GET_APP_LINK_URL({ pathname: currentUrl.pathname });
        }
        if (isKboMorePage) {
            const query = currentUrl.search;
            const pathname = `${transformRedirectUrlPath({ url: window.location.href })}${query || ''}`;
            linkUrl = GET_APP_LINK_URL({ pathname });
        }

        window.open(`https://www.facebook.com/sharer.php?u=${encodeURI(`${linkUrl}`)}`, 'sharer', 'toolbar=0,status=0,width=800,height=600');

        if (!isKbo && !isSports && (code.charAt(0) === 'M' || code.charAt(0) === 'P' || code.charAt(0) === 'E')) {
            if (!braze) {
                return;
            }
            brazeEvent({
                eventName: 'share_facebook',
                properties: {
                    category: code.charAt(0) === 'M' ? '영화' : 'TV프로그램',
                    genre1: categoryName1,
                    genre2: categoryName2,
                    contents: title,
                },
            });
        }

        sendAmplitudeEventWhenSharing('facebook');
    };

    /**
     * 공유하기 > 트위터 메소드
     */
    const clickShareTwitter = () => {
        const currentUrl = new URL(window.location.href);
        let linkUrl = window.location.href;
        if (isKbo) {
            linkUrl = GET_APP_LINK_URL({ pathname: currentUrl.pathname });
        }

        if (isKboMorePage) {
            const query = currentUrl.search;
            const pathname = `${transformRedirectUrlPath({ url: window.location.href })}${query || ''}`;
            linkUrl = GET_APP_LINK_URL({ pathname });
        }

        // const url = snsReturnUrl();
        window.open(
            // 'https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.tving.com%2Fvod%2Fplayer%2FE003662053&text=%EC%98%A4%EC%9D%80%EC%98%81%EC%9D%98%20%EA%B8%88%EC%AA%BD%EC%83%81%EB%8B%B4%EC%86%8C%2013%ED%99%94%20%7C%20%ED%8B%B0%EB%B9%99',
            // `https://twitter.com/share?url=${encodeURI('https://ratel.tving.com/contents/E003660929')}&text=${encodeURI(title)}`,
            `https://www.twitter.com/share?url=${encodeURI(linkUrl)}&text=${encodeURI(`${title ?? ''}\n`)}`,
            'twitter_popup',
            'toolbar=0,status=0,width=626,height=436',
        );

        if (!isKbo && !isSports && (code.charAt(0) === 'M' || code.charAt(0) === 'P' || code.charAt(0) === 'E')) {
            if (!braze) {
                return;
            }
            brazeEvent({
                eventName: 'share_twitter',
                properties: {
                    category: code.charAt(0) === 'M' ? '영화' : 'TV프로그램',
                    genre1: categoryName1,
                    genre2: categoryName2,
                    contents: title,
                },
            });
        }

        sendAmplitudeEventWhenSharing('twitter');
    };

    /**
     * 공유하기 > 카카오톡 메소드
     */
    const clickShareKakao = () => {
        const currentUrl = new URL(window.location.href);

        const kakao = window.Kakao;
        if (!kakao) {
            return;
        }
        if (kakao && !kakao.isInitialized()) {
            kakao.init(process.env.NEXT_PUBLIC_KAKAO_JAVASCRIPT_KEY);
        }

        let linkUrl = window.location.href;
        if (isKbo) {
            linkUrl = GET_APP_LINK_URL({ pathname: currentUrl.pathname });

            if (isKboMorePage) {
                const query = currentUrl.search;
                const pathname = `${transformRedirectUrlPath({ url: window.location.href })}${query || ''}`;
                linkUrl = GET_APP_LINK_URL({ pathname });
            }
            kakao.Share.sendScrap({
                requestUrl: linkUrl,
            });
        } else if (isSports || isClip) {
            kakao.Share.sendScrap({
                requestUrl: linkUrl,
            });
        } else {
            kakao?.Link?.sendDefault({
                objectType: 'feed',
                content: {
                    title: props.title || '',
                    description: props.description || '', // '#리액트 #카카오 #공유버튼',
                    imageUrl: props.thumbnail || '', // 'IMAGE_URL', // i.e. process.env.FETCH_URL + '/logo.png'
                    link: {
                        mobileWebUrl: window.location.href,
                        webUrl: window.location.href,
                    },
                },
            });
        }

        if (!isKbo && !isSports && (code.charAt(0) === 'M' || code.charAt(0) === 'P' || code.charAt(0) === 'E')) {
            if (!braze) {
                return;
            }
            brazeEvent({
                eventName: 'share_kakaotalk',
                properties: {
                    category: code.charAt(0) === 'M' ? '영화' : 'TV프로그램',
                    genre1: categoryName1,
                    genre2: categoryName2,
                    contents: title,
                },
            });
        }

        sendAmplitudeEventWhenSharing('kakao');
    };

    const isIncludesSubBoardUrl = (currentPath: string) => {
        return SUB_BOARD_SHARE_POSSIBLE_URLS.some((subBoardPath: string) => {
            return currentPath.startsWith(subBoardPath);
            // 계속해서 다음 항목을 확인합니다.
        });
    };

    /**
     * 공유하기 > 주소 복사 메소드
     */
    const clickShareUrl = async () => {
        const currentUrl = new URL(window.location.href);
        let linkUrl = window.location.href;

        if (isKbo && !isKboMorePage) {
            linkUrl = `${GET_APP_LINK_URL({ pathname: currentUrl.pathname })}`;

            Modal.toast({
                title: t('community.copyUrl'), // "콘텐츠의 주소가 복사되었습니다."
                timer: 1000,
            });

            navigator.clipboard?.writeText(linkUrl);
            sendAmplitudeEventWhenSharing('link');

            return;
        }

        if (isKboMorePage) {
            const query = currentUrl.search;
            const pathname = `${transformRedirectUrlPath({ url: window.location.href })}${query || ''}`;
            linkUrl = GET_APP_LINK_URL({ pathname });
            navigator.clipboard?.writeText(linkUrl);
        }

        // KBO 영향도를 없애기 위한 로직입니다.
        const href = getCurrentPageUrl();
        const fullUrl = linkUrl === href ? href : linkUrl;

        const copyToClipboardResult = await copyToClipboard(fullUrl, { successMessage: t('community.copyUrl') });

        const { ok, data, error } = copyToClipboardResult;

        if (!ok) {
            return Modal.toast({
                title: error.message, // "클립보드 복사 기능이 지원되지 않는 브라우저입니다."
                timer: 1000,
            });
        }

        sendAmplitudeEventWhenSharing('link');
        return Modal.toast({
            title: data.message, // "콘텐츠의 주소가 복사되었습니다."
            timer: 1000,
        });
    };

    const onloadShareButton = () => {
        if (!loadedSdkList?.kakao?.onSuccess && !loadedSdkList?.kakao?.onLoading) {
            const script = document.createElement('script');
            script.src = 'https://developers.kakao.com/sdk/js/kakao.min.js';
            script.async = true;

            script.onload = () => {
                setLoadedSdkList({
                    kakao: {
                        onSuccess: true,
                        onLoading: false,
                    },
                });
            };

            document.body.appendChild(script);
            setLoadedSdkList({
                kakao: {
                    onSuccess: false,
                    onLoading: true,
                },
            });
        }
    };
    const domLoaded = useDomLoaded();

    useEffect(() => {
        if (isKbo) {
            setIsKboShare(isPopActive);
        }
        if (isSports) {
            setIsSportsShare(isPopActive);
        }
        if (isClip) {
            setIsClipShare(isPopActive);
        }
    }, [isKbo, isPopActive, isSports]);

    const menuRootRef = useRef<HTMLDivElement>(null);
    const [horizontalPosition, setHorizontalPosition] = useState<'start' | 'end'>('end');

    useIsomorphicLayoutEffect(() => {
        if (!isPopActive) {
            return;
        }

        const menuRootEl = menuRootRef.current;
        if (!menuRootEl) {
            return;
        }

        const menuRootRect = menuRootEl.getBoundingClientRect();

        // 우선순위는 end > start입니다.
        let nextHorizontalPosition: 'start' | 'end' = 'end';
        let outOfLeftScreenEdge = false;
        let outOfRightScreenEdge = false;

        if (menuRootRect.left <= HORIZONTAL_POSITION_THRESHOLD || menuRootRect.left - menuRootRect.width <= HORIZONTAL_POSITION_THRESHOLD) {
            nextHorizontalPosition = 'start';
            outOfLeftScreenEdge = true;
        }

        if (
            menuRootRect.right >= window.innerWidth - HORIZONTAL_POSITION_THRESHOLD ||
            menuRootRect.right - menuRootRect.width >= window.innerWidth - HORIZONTAL_POSITION_THRESHOLD
        ) {
            nextHorizontalPosition = 'end';
            outOfRightScreenEdge = true;
        }

        if (outOfLeftScreenEdge && outOfRightScreenEdge && menuRootEl.parentElement) {
            const triggerElementRect = menuRootEl.parentElement.getBoundingClientRect();
            nextHorizontalPosition = triggerElementRect.left - triggerElementRect.width / 2 < window.innerWidth / 2 ? 'start' : 'end';
        }

        setHorizontalPosition(nextHorizontalPosition);
    }, [isPopActive]);

    return (
        <div ref={ref}>
            {isOnlyIcon ? null : (
                <StyledResultPopup ref={menuRootRef} isLeft={isLeft} className={`${isPopActive ? 'active' : ''}`} style={style}>
                    {/* 활성화시 'active' */}
                    <button type="button" className="btn__action__lay btn_result icon-circle__facebook" onClick={() => clickShareFacebook()}>
                        facebook
                    </button>
                    <button type="button" className="btn__action__lay btn_result icon-circle__twitter" onClick={() => clickShareTwitter()} />
                    {loadedSdkList?.kakao?.onSuccess ? (
                        <button type="button" className="btn__action__lay btn_result icon-circle__kakao" onClick={() => clickShareKakao()} />
                    ) : null}
                    <button type="button" className="btn__action__lay btn_result icon-circle__url" onClick={() => clickShareUrl()} />
                </StyledResultPopup>
            )}
            {isOnlyIcon ? (
                <div className={cnx('flex-center relative')}>
                    <button type="button" className="inline-flex flex-col justify-center items-center" onClick={handleShareButtonClick}>
                        <KboIconShare className={cnx('w-[2rem] h-[2rem] lg:w-[2.667rem] lg:h-[2.667rem]')} />

                        {supportLabel || null}
                    </button>
                    {isPopActive ? (
                        <div
                            ref={menuRootRef}
                            className={[
                                styles.SocialMediaShareList,
                                isClip ? styles.clip : '',
                                isKboShare || isSportsShare || isClipShare ? styles['SocialMediaShareList--active'] : null,
                                isClipShare ? styles['ClipSocialMediaShareList--active'] : null,
                                'z-[1000]',
                                horizontalPosition === 'start' ? styles['position-start'] : styles['position-end'],
                            ].join(' ')}
                            onTransitionEnd={handleKboSportsShareTransitionEnd}
                        >
                            <button
                                type="button"
                                className={cnx(
                                    'btn__action__lay btn_result icon-circle__facebook',
                                    'relative opacity-100 p-[0]',
                                    "after:content-[''] after:absolute after:inset-0 after:bg-black/10 after:rounded-full after:opacity-0 after:transition-opacity after:duration-100 hover:after:opacity-100",
                                )}
                                onClick={() => clickShareFacebook()}
                            >
                                facebook share
                            </button>
                            <button
                                type="button"
                                className={cnx(
                                    'btn__action__lay btn_result icon-circle__twitter',
                                    'relative opacity-100 p-[0]',
                                    "after:content-[''] after:absolute after:inset-0 after:bg-black/10 after:rounded-full after:opacity-0 after:transition-opacity after:duration-100 hover:after:opacity-100",
                                )}
                                onClick={() => clickShareTwitter()}
                            >
                                x(twitter) share
                            </button>
                            {loadedSdkList?.kakao?.onSuccess ? (
                                <button
                                    type="button"
                                    className={cnx(
                                        'btn__action__lay btn_result icon-circle__kakao',
                                        'relative opacity-100 p-[0]',
                                        "after:content-[''] after:absolute after:inset-0 after:bg-black/10 after:rounded-full after:opacity-0 after:transition-opacity after:duration-100 hover:after:opacity-100",
                                    )}
                                    onClick={() => clickShareKakao()}
                                >
                                    kakao share
                                </button>
                            ) : null}
                            <button
                                type="button"
                                className={cnx(
                                    'btn__action__lay btn_result icon-circle__url',
                                    'relative opacity-100 p-[0]',
                                    "after:content-[''] after:absolute after:inset-0 after:bg-black/10 after:rounded-full after:opacity-0 after:transition-opacity after:duration-100 hover:after:opacity-100",
                                )}
                                onClick={() => clickShareUrl()}
                            >
                                url share
                            </button>
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="mt-[1.666rem] flex justify-center">
                    <Button
                        tag="button"
                        className="flex-center w-[10.916rem] h-[4rem] bg-black-700 border rounded-[4px]"
                        onClick={() => clickShareButton()}
                        onFocus={onloadShareButton}
                        onMouseEnter={onloadShareButton}
                    >
                        <Icon SvgIconImage={shareIcon} className="w-[2rem] h-[2rem]" />
                        <Text title="공유하기" className="text-[1.333rem] font-normal leading-normal ml-[0.666rem]" />
                    </Button>
                </div>
            )}

            {domLoaded && <TextArea ref={copyUrlRef} defaultValue={window.location.href} />}
        </div>
    );
};

export default SnsShareButton;
