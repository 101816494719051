/* eslint-disable react/jsx-no-useless-fragment */
import React, { SyntheticEvent } from 'react';

interface ButtonProps {
  tag?: 'button' | 'div';
  children?: React.ReactNode;
  onClick?: (e: SyntheticEvent) => void;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  style?: React.CSSProperties;
}

const Button = ({ tag = 'div', children, onClick, className, type, style }: ButtonProps) => {
  return tag === 'button' ? (
    // eslint-disable-next-line react/button-has-type
    <button onClick={onClick} className={`atom-button-wrapper ${className}`} type={type || 'button'} style={style}>
      {React.Children.map(children, (child) => {
        return child;
      })}
    </button>
  ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={onClick} className={`atom-button-wrapper ${className}`} style={style}>
      {React.Children.map(children, (child) => {
        return child;
      })}
    </div>
  );
};

export default Button;
