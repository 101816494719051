import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import utilsAxiosInstance from '@utils/common/utilsAxiosInstance';

/**
 * 생성한 axiosInstance의 GET 요청 함수
 *
 * @param url
 * @param requestConfig: header, timeout 등 다양한 설정을 함수 사용자에게 전가
 */
const httpGet = <T>(url: string, requestConfig?: AxiosRequestConfig, pathname?: string): Promise<T> => {
    const filledRequestConfig = {
        ...requestConfig,
        pathname,
    };

    try {
        return utilsAxiosInstance
            .get<T>(url, filledRequestConfig)
            .then((response: AxiosResponse<T>) => {
                const { data } = response;

                return data;
            })
            .catch((error: AxiosError) => {
                return Promise.reject(error);
            });
    } catch (e) {}
};

export default httpGet;
