import { API_URLS, PATH_URLS } from './apiConstants';

// 인증을 타지 않아야 하는 게이트웨이 API 집합. Access Token 헤더 추가하지 않아야 합니다.
export const NOT_REQUIRED_ACCESS_TOKEN_APIS = [
  API_URLS.TTV_BUFFER_MODE,
  '/gw/api/web/ttv',
  '/gw/api/tv/ttv',
  API_URLS.PROVISIONING,
  '/gw/api/web/provisioning',
  '/gw/api/tv/provisioning',
  API_URLS.CREATE_ACCESS_TOKEN,
  API_URLS.APP_LOGOUT,
  API_URLS.OTP_RESULT_USER,
  API_URLS.APP_LOGIN,
  API_URLS.CHECK_ACCESS_TOKEN,
];

// PC 뉴티빙에서 비로그인 접근이 가능한 pathname
export const PC_NOT_LOGIN_ACCESSABLE_PATHNAME = [PATH_URLS.MORE_CURATION, PATH_URLS.MORE_SPECIAL, PATH_URLS.MORE_GENRE, PATH_URLS.MORE_BAND];
