import { STREAM_INFO_RESULT_CODE_TYPE } from '@tving/utils/src/types/stream';
import { create } from 'zustand';
import dayjs from 'dayjs';

type PlayerStore = {
    playerSubTitle: string;
    setPlayerSubTitle: (playerSubTitle: string) => void;
    playerSubDescription: string;
    setPlayerSubDescription: (playerSubDescription: string) => void;
    playerText: string;
    setPlayerText: (playerText: string) => void;
    playerCoverImageUrl: string;
    setPlayerCoverImageUrl: (playerCoverImageUrl: string) => void;
    playerBackgroundColor: string;
    setPlayerBackgroundColor: (playerBackgroundColor: string) => void;

    ndvrOffsetSeconds: number;
    ndvrFirstPlayTimestamp: number;
    setNdvrFirstPlayTimestamp: (ndvrFirstPlayTimestamp: number) => void;

    ndvrTotalTime: number;
    setNdvrTotalTime: (ndvrTotalTime: number) => void;
    ndvrDuration: number;
    setNdvrDuration: (ndvrDuration: number) => void;

    ndvrSeekingSeconds: number;
    setNdvrSeekingSeconds: (ndvrSeekingSeconds: number) => void;

    isFullScreen: boolean;
    isFullScreenRef: React.MutableRefObject<boolean>;
    setFullScreen: (isFullScreen: boolean) => void;
    toggleFullScreen: () => void;

    streamResultCode: STREAM_INFO_RESULT_CODE_TYPE | string;
    setStreamResultCode: (streamResultCode: STREAM_INFO_RESULT_CODE_TYPE | string) => void;

    streamInfoMediaCode: string;
    setStreamInfoMediaCode: (streamInfoMediaCode: string) => void;
};

const usePcPlayer = create<PlayerStore>((set) => ({
    playerSubTitle: '',
    setPlayerSubTitle: (playerSubTitle) => set({ playerSubTitle }),
    playerSubDescription: '',
    setPlayerSubDescription: (playerSubDescription) => set({ playerSubDescription }),
    playerText: '',
    setPlayerText: (playerText) => set({ playerText }),
    playerCoverImageUrl: '',
    setPlayerCoverImageUrl: (playerCoverImageUrl) => set({ playerCoverImageUrl }),
    playerBackgroundColor: '',
    setPlayerBackgroundColor: (playerBackgroundColor) => set({ playerBackgroundColor }),

    ndvrFirstPlayTimestamp: 0,
    ndvrOffsetSeconds: 0,
    setNdvrFirstPlayTimestamp: (ndvrFirstPlayTimestamp) => {
        const baseTime = dayjs(ndvrFirstPlayTimestamp);
        const now = dayjs();
        const ndvrOffsetSeconds = now.diff(baseTime, 'second');

        return set({ ndvrFirstPlayTimestamp, ndvrOffsetSeconds });
    },

    ndvrTotalTime: 0,
    setNdvrTotalTime: (ndvrTotalTime) => set({ ndvrTotalTime }),
    ndvrDuration: 0,
    setNdvrDuration: (ndvrDuration) => set({ ndvrDuration }),

    ndvrSeekingSeconds: 0,
    setNdvrSeekingSeconds: (ndvrSeekingSeconds) => set({ ndvrSeekingSeconds }),

    streamResultCode: '',
    setStreamResultCode: (streamResultCode) => set({ streamResultCode }),

    streamInfoMediaCode: '',
    setStreamInfoMediaCode: (streamInfoMediaCode) => set({ streamInfoMediaCode }),

    isFullScreen: false,
    isFullScreenRef: { current: false },
    setFullScreen: (isFullScreen) => {
        return set(({ isFullScreenRef }) => {
            // eslint-disable-next-line no-param-reassign
            isFullScreenRef.current = isFullScreen;
            return { isFullScreen };
        });
    },
    toggleFullScreen: () =>
        set((state) => {
            const isFullScreen = !state.isFullScreen;
            // eslint-disable-next-line no-param-reassign
            state.isFullScreenRef.current = isFullScreen;
            return { isFullScreen };
        }),
}));

export default usePcPlayer;
