import Link from 'next/link';
import {
    FreeLabel,
    BuyEachLabel,
    CinemaLabel,
    EventLabel,
    FirstReleaseLabel,
    MouseOverSummary,
    RunningTimeTag,
    ThumbnailImage,
    TvingOnlyTag,
    TvingOriginalTag,
} from '@components/item/ItemComponent';
import moment from 'moment';
import { useRouter } from 'next/router';

import { searchClickFeedback, useDeleteList, useLogging, useUserInfo } from '@store/state';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { isMobile } from '@utils/device/device-detect';
import { NewAgeGradeTag } from '@components/content/CommonComponent';
import { brazeEvent_contents } from '@utils/braze';
import { logFbaSearchAutoCompleteClick, logFbaSearchDetailClick } from '@utils/firebaseAnalytics';
import { emitSearchResultBandItemClickEvent, emitSearchResultEvent } from '@utils/amplitude/emitEvent/eventHandlers/searchHandler';

const ItemATag = styled.a`
    cursor: pointer;
`;
const MovieItem = ({
    href,
    idx,
    type, // 타입 - movie / episode
    bandType,
    bandTitle, // 진입한 띠 명
    title, // 타이틀
    genre, // 영화 장르
    summary, // 영화 줄거리 요약
    subtitle, // 서브타이틀
    cateCd,
    cateName,
    chCd,
    chName,
    code,
    programCode,
    masterCode,
    contentType,
    ratio,
    thumbnail, // 썸네일 이미지
    watchThumbnail, // 시청중인 영화장 썸네일
    grade,
    gradeCode, // 연령 등급 코드
    ageGrade, // 시청 가능 연령
    isAdult, // 성인인증 필요 여부
    isExclusive,
    isOriginal,
    isEvent,
    isFree,
    isFirstRelease, // 최초개봉 여부
    isCinemaSame, // 극장동시 여부
    isSubtitle, // 자막 여부
    isDubbing, // 더빙 여부
    isParamount, // 파라마운트 콘텐츠 여부
    broadcastStartTime,
    broadcastEndTime,
    rating,
    duration, // 시청 퍼센트 계산 - 영상 전체 시간
    lastPlayTime, // 시청 퍼센트 계산 - 시청 시간
    billingTag, // 빌링 관련 태그 - single=개별구매
    isLazy,
    viewDate, // 시청일자
    isEditMode, // 편집모드
    masterSeq, // 시청 고유번호
    isSearchLayer, // 실시간 검색 레이어 오픈 여부(모달이라면 타이틀이 빨간색 글씨(kwauto)로 노출해야함)
    isSearchPage, // 검색 페이지 여부
    kwauto,
    categoryName1, // 첫번째 카테고리명
    categoryName2, // 두번째 카테고리명차
    itemWidth, // 아이템 width값
    searchKeyword, // 검색결과인 경우 검색어
    searchUniqueKey, // 검색에서 클릭된 경우 유니크 키
    funnel, // 검색진입 지점
    onClick,
    isPayUser,
    fbaParams,
}) => {
    const route = useRouter();
    const [userInfo] = useUserInfo();
    const [{ braze, brazeEvent }] = useLogging();
    const [deleteList, setDeleteList] = useDeleteList(null);
    const [isClicked, setIsClicked] = useState(false);
    const isWatch = bandType === 'movieWatch'; // 마이 > 시청내역인 경우
    const isMy = bandType === 'movieWatch' || bandType === 'movieFavorite';
    let subInfo = '';
    if (isWatch) {
        subInfo = `${moment(`${viewDate}`, 'YYYYMMDD').format('YYYY.MM.DD')} 시청`;
        thumbnail = watchThumbnail;
    }

    const ratioClassName = ratio ? `item__thumb-${ratio}` : '';
    const watchPercent = lastPlayTime ? (100 * lastPlayTime) / duration : 0;
    const isRank = bandType === 'movieRank';
    const infoClassName = isRank ? 'item__info-rank' : '';

    title = isSubtitle ? `[자막] ${title}` : isDubbing ? `[더빙] ${title}` : title;

    useEffect(() => {
        const idx = deleteList.indexOf(isWatch ? masterSeq : masterCode);
        if (idx > -1) {
            setIsClicked(true); // 모드가 변경될 때 선택 지우기
        } else {
            setIsClicked(false);
        }
    }, [deleteList]);

    const itemClick = () => {
        if (!isClicked) {
            setDeleteList([...deleteList, isWatch ? masterSeq : masterCode]);
            setIsClicked(true);
        } else {
            const idx = deleteList.indexOf(isWatch ? masterSeq : masterCode);
            const resultList = Object.assign(deleteList);
            if (idx > -1) {
                setDeleteList(null);
                resultList.splice(idx, 1);
                setDeleteList(resultList);
                setIsClicked(false);
            }
        }
    };

    const moveLink = (e) => {
        if (isSearchLayer || isSearchPage) {
            // 검색결과에서 콘텐츠 클릭 시 검색어 순위 반영을 위한 feedback API 호출
            searchClickFeedback({ mediaCode: code, title, type: 'Movie', userId: userInfo.userId || '' });

            if (isSearchLayer) {
                const eventData = {
                    keyword: searchKeyword,
                    code,
                    channel_code: chCd === '' ? null : chCd,
                    program_code: programCode,
                    program_name: title,
                    ctgr_code: cateCd === '' ? null : cateCd,
                };

                emitSearchResultEvent(eventData);

                logFbaSearchAutoCompleteClick({
                    profileNo: userInfo?.profileNo,
                    keyword: searchKeyword,
                    subType: 'auto_complete',
                    rank: idx,
                    mediaCode: code,
                    ...fbaParams,
                });
            } else if (isSearchPage) {
                const eventData = {
                    band_name: '영화',
                    band_sequence: 2,
                    contents_sequence: idx + 1,
                    channel_code: chCd || null,
                    channel_name: chName || null,
                    program_code: code,
                    code,
                    program_name: title,
                    ctgr_name: cateName || null,
                    ctgr_code: cateCd || null,
                    keyword: searchKeyword,
                };
                emitSearchResultBandItemClickEvent(eventData);

                logFbaSearchDetailClick({
                    profileNo: userInfo?.profileNo,
                    searchId: searchUniqueKey,
                    keyword: searchKeyword,
                    funnel,
                    bandName: bandTitle,
                    mediaCode: code,
                    rank: idx,
                    ...fbaParams,
                });
            }
        }
        if (bandType === 'originalBasic') {
            // 오리지널띠에서의 아이템 클릭 시 GA 이벤트 발생
            e.preventDefault();
            e.stopPropagation();
            onClick?.(e);
            route.push(href);
        } else if (!isEditMode) {
            if (route.pathname === '/player/[slug]') {
                e.preventDefault();
                e.stopPropagation();
                route.push(`/player/${code}`);
            } else {
                // 수정모드 아닌경우 재생 화면으로 이동
                // route.push(href);
            }
            brazeEvent_contents({
                braze,
                brazeEvent,
                type,
                genre1: categoryName1 || '',
                genre2: categoryName2 || '',
                contents: title,
                episode_no: '',
                gate: bandTitle,
            });
            onClick?.(e);
        } else {
            // 수정일때 링크 동작 안하게.
            e.preventDefault();
            e.stopPropagation();
            // 수정모드인 경우 아이템 클릭
            itemClick();
        }
    };
    return (
        <Link scroll href={href} passHref>
            <ItemATag
                className={`${isEditMode ? 'active' : ''}`}
                onClick={(e) => moveLink(e)}
                isSearchLayer={isSearchLayer}
                css={
                    isRank &&
                    itemWidth && {
                        width: itemWidth,
                    }
                }
            >
                <div className={`item__thumb ${ratioClassName}`}>
                    {/* my > 시청내역 영화 목록에는 태그 미표시 */}
                    {!isWatch && (
                        <div className="item__tags">
                            {/* 파라마운트 라벨은 포스터 이미지에 번인되어 있습니다. */}
                            {/* 따라서, 파라마운트 콘텐츠가 아닌 경우에만 좌측 상단 라벨을 노출해야 합니다. */}
                            {!isParamount && (
                                <div className="item__tags-left">
                                    {/* 개별구매 라벨 */}
                                    {billingTag === 'single' && <BuyEachLabel />}

                                    {/* 이벤트 라벨 */}
                                    {isEvent && <EventLabel />}

                                    {/* 무료 라벨 */}
                                    {isFree && !isPayUser && !isWatch && <FreeLabel />}

                                    {/* 최초개봉 라벨 */}
                                    {isFirstRelease && <FirstReleaseLabel />}

                                    {/* 극장동시 라벨 */}
                                    {isCinemaSame && <CinemaLabel />}
                                </div>
                            )}

                            {/* 연령 등급 라벨 태그 - 19세 콘텐츠에 한해 연령등급 표시 - CPTG: 방통위 / CMMG: 영등위 */}
                            {(gradeCode === 'CPTG0500' || gradeCode === 'CMMG0400') && (
                                <div className="item__tags-right">
                                    <NewAgeGradeTag gradeCode={gradeCode} />
                                </div>
                            )}

                            {/* 티빙 오리지널 태그 */}
                            {isOriginal && <TvingOriginalTag />}

                            {/* 티빙 온리 태그 */}
                            {!isOriginal && isExclusive && <TvingOnlyTag />}
                        </div>
                    )}
                    {isWatch && (
                        <div className="item__tags">
                            {/* 연령 태그 - 19세 콘텐츠에 한해 연령등급 표시 */}
                            {(gradeCode === 'CPTG0500' || gradeCode === 'CMMG0400') && (
                                <div className="item__tags-right">
                                    <NewAgeGradeTag gradeCode={gradeCode} />
                                </div>
                            )}
                        </div>
                    )}
                    {/* 컨텐츠(포스터) */}
                    <ThumbnailImage thumbnail={thumbnail} title={title} isLazy={isLazy} />
                    {/* 라이브 시청(진행) 퍼센트 */}
                    {(bandType === 'movieChannel' || isWatch) && (
                        <RunningTimeTag className="item__progress" watchPercent={watchPercent}>
                            <span className="item__progress-bar" />
                        </RunningTimeTag>
                    )}
                    {!isEditMode && !isMobile && !isMy && summary && <MouseOverSummary title={title} genre={genre} summary={summary} />}
                    {isEditMode && (
                        <div className="thumb_summary">
                            <button type="button" className={`btn__check ${isClicked && 'click_on'}`} />
                        </div>
                    )}
                </div>
                {!isRank && (
                    <div className={`item__info ${infoClassName}`}>
                        <p className="item__title">{isSearchLayer ? <span dangerouslySetInnerHTML={{ __html: kwauto }} /> : title}</p>
                        {isWatch && <p className="item__subinfo">{subInfo}</p>}
                    </div>
                )}
            </ItemATag>
        </Link>
    );
};

export default MovieItem;
