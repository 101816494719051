/**
 * examples (각 POC 별 컴포넌트 적용 시 선언 될 props 예시)
 *
 * for TV
 *        const categoryItemProps = {
 *           className: `absolute flex-center rounded-full bg-[#262626] h-[${height}px] `,
 *           tabIndex: -1,
 *           textProps: {
 *             className: `${
 *               tvingItem.itemId === focusedItem?.itemId ? whiteText : grayText
 *             } text-gray-15 text-[26px] font-normal whitespace-nowrap px-[63px]`,
 *             tabIndex: -1,
 *             title: `${tvingItem.textTitle}`,
 *           } as TextProps<React.ElementType>,
 *         };
 *
 *
 * for PC (TBD)
 *     const textColorClassName = `text-[${itemFontColor}]`;
 *     const categoryItemProps = {
 *         className: 'opacity 0.3s, transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) hover:scale-105 active:scale-105 bg-[#212121] ',
 *         tag: 'a',
 *         textProps: {
 *             tag: `span`,
 *             className: `flex-center min-h-[3.677rem] text-[1.25rem] pl-[2.917rem] pt-[1rem] pb-[1rem] pr-[2.917rem] rounded-sm ${textColorClassName} whitespace-nowrap leading-normal box-border`,
 *             title,
 *         },
 *     };
 */

import styled from '@emotion/styled';

import Text, { TextProps } from '../../atom/text/Text';

export const StyledCategoryItemWrapper = styled.div``;

export interface CategoryItemProps<T extends React.ElementType> {
  tag?: T;
  className?: string;
  tabIndex?: number;
  text: TextProps<React.ElementType>;
}

const CategoryItem = <T extends React.ElementType = 'div'>({ className, tabIndex, tag, text }: CategoryItemProps<T>) => {
  return (
    <StyledCategoryItemWrapper as={tag} className={`category-item-wrapper ${className}`} tabIndex={tabIndex}>
      <Text {...text} />
    </StyledCategoryItemWrapper>
  );
};
export default CategoryItem;
