import { useAbortSWR, useAbortSWRInfinite } from '@store/apis/plugins';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getScheduleReserveList } from '@utils/community';
import { isEmpty } from '@utils/common/Object';
import { proxyLive } from './proxy';

/**
 * 인기 라이브 List
 * @param {number} pageNo - 페이지 번호.
 * @return {Object|boolean} data, source, isLoading, isError Value.
 */
export const useLivesListsMax = ({ broadDay }) => {
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달
    const { data, isLoading, mutate, isValidating } = useAbortSWR(
        [`/v2/media/lives?channelType=CPCS0100&pageNo=1`],
        {
            params: {
                cacheType: 'main',
                pageSize: 40,
                order: 'rating',
                adult: 'all',
                free: 'all',
                guest: 'all',
                scope: 'all',
            },
        },
        {
            revalidateOnFocus: false,
        },
    );
    useEffect(() => {
        if (!isEmpty(data)) {
            mutate({ ...data }, true);
        }
    }, [broadDay]);
    return { data, isLoading, isValidating };
};

const CONFIG = {
    /**
     * 지연 로딩을 하기 위한 시간 단위의 범위입니다. (단위: 시간)
     *
     * @description 3시간 단위인 경우 다음과 같이 지연 로딩을 수행합니다.
     * - 0:00 ~ 3:00
     * - 3:00 ~ 6:00
     * - 6:00 ~ 9:00
     * - 9:00 ~ 12:00
     * - 12:00 ~ 15:00
     * - 15:00 ~ 18:00
     * - 18:00 ~ 21:00
     * - 21:00 ~ 24:00
     */
    TIME_INTERVAL_SEGMENT_FOR_LAZY_LOADING: 3,
};

const swrOptions = {
    revalidateOnFocus: false,
};

const getInitialViewingDateFetchUrlList = () => {
    const length = Math.ceil(24 / CONFIG.TIME_INTERVAL_SEGMENT_FOR_LAZY_LOADING);
    return Array.from({ length }).fill(null);
};

export const useScheduleData = ({ nowDay }) => {
    const [lazyIdx, setLazyIdx] = useState(-1);
    const broadDay = nowDay.format('YYYYMMDD');
    const { data, isLoading, isValidating } = useLivesListsMax({ broadDay });
    const [reserveList, setReserveList] = useState();

    useEffect(() => {
        const reserveLoadList = async () => {
            const list = await getScheduleReserveList({ pageNo: 1 });
            setReserveList(list?.body?.result);
        };
        reserveLoadList();
    }, []);

    const channelList = data.items?.map((item) => item.code) || [];
    const channelLogoImageList = data.items?.map((item) => item.channelLogoImage) || [];
    const params = {
        params: {
            channelCode: channelList?.join(','),
        },
    };

    const [activeSlide, setActiveSlide] = useState(-1);
    const [initialSlide, setInitialSlide] = useState(-1);
    const [dataList, setDataList] = useState([]);
    const [viewingDateFetchUrlList, setViewingDateFetchUrlList] = useState(getInitialViewingDateFetchUrlList());

    const getFetchScheduleUrl = (index) => {
        const broadStartTime = nowDay
            .clone()
            .set('hour', index * CONFIG.TIME_INTERVAL_SEGMENT_FOR_LAZY_LOADING)
            .startOf('hour')
            .subtract(0, 'h')
            .format('HHmmss');

        const broadEndTime = nowDay
            .clone()
            .set('hour', index * CONFIG.TIME_INTERVAL_SEGMENT_FOR_LAZY_LOADING)
            .startOf('hour')
            .add(CONFIG.TIME_INTERVAL_SEGMENT_FOR_LAZY_LOADING, 'h')
            .format('HHmmss');

        // 현재 DataList 업데이트를 useSWR의 proxy함수 내부에서 처리하고 있습니다.
        // 따라서, 이전에 조회한 데이터도 다시 조회하도록 하기 위해 강제로 현재 timestamp를 QueryParams로 추가합니다.
        const forcedDataRetrievalTimestamp = moment().unix();

        return `/v2/media/schedules?pageNo=1&pageSize=20&order=chno&scope=all&adult=n&free=all&broadDate=${broadDay}&broadcastDate=${broadDay}&startBroadTime=${broadStartTime}&endBroadTime=${broadEndTime}&_=${forcedDataRetrievalTimestamp}`;
    };

    /**
     * UI 상으로는 전날 23:30 ~ 24:00도 노출되어야 하므로, 전날 데이터를 가져오는 URL을 생성합니다. (조회 범위: 21:00 ~ 24:00)
     */
    const getFetchPreviousDateScheduleUrl = () => {
        const currentDate = moment(broadDay, 'YYYYMMDD');
        const prevDate = currentDate.clone().subtract(1, 'days');

        const broadcastDate = prevDate.format('YYYYMMDD');
        const broadStartTime = `${prevDate
            .clone()
            .set('hour', 24 - CONFIG.TIME_INTERVAL_SEGMENT_FOR_LAZY_LOADING)
            .format('HH')}0000`;
        const broadEndTime = `${currentDate.clone().set('hour', 0).format('HH')}0000`;

        // 현재 DataList 업데이트를 useSWR의 proxy함수 내부에서 처리하고 있습니다.
        // 따라서, 이전에 조회한 데이터도 다시 조회하도록 하기 위해 강제로 현재 timestamp를 QueryParams로 추가합니다.
        const forcedDataRetrievalTimestamp = moment().unix();

        return `/v2/media/schedules?pageNo=1&pageSize=20&order=chno&scope=all&adult=n&free=all&broadDate=${broadcastDate}&broadcastDate=${broadcastDate}&startBroadTime=${broadStartTime}&endBroadTime=${broadEndTime}&_=${forcedDataRetrievalTimestamp}`;
    };

    useEffect(() => {
        if (isLoading || isValidating) {
            return;
        }
        if (dataList.length > 0) {
            return;
        }

        const nextDataList = [];
        const nowTime = moment.duration(nowDay.diff(moment('0000', 'HHmm')));
        const nowSlide = nowTime.hours() * 2 + Math.floor(nowTime.minutes() / 30);
        if (nowTime.days() > 0) {
            // nowSlide = 0;
        }

        for (let i = 0; i < 48; i += 1) {
            nextDataList.push({
                isLive: nowSlide === i && nowTime.days() === 0,
                livePerTime: (nowTime.minutes() - Math.floor(nowTime.minutes() / 30) * 30) / 30,
                channels: Array.from({ length: channelList.length }, () => {
                    return [];
                }),
            });
        }

        setDataList(nextDataList);
        setInitialSlide(activeSlide > 0 ? activeSlide : nowSlide);
    }, [isLoading, isValidating]);

    /**
     * dataList 업데이트를 useSWR의 데이터 패칭 이후 Proxy 함수에서 처리합니다.
     * 반환한 값은 다음 데이터 패칭이 필요한지 여부를 판단하기 위해 사용됩니다.
     */
    const updateDataListAfterFetched = (data) => {
        const nowTime = moment().format('YYYYMMDDHHmmss');

        setDataList((prev) => {
            if (prev.length === 0) {
                return data;
            }

            const nextDataList = Array.isArray(prev) ? [...prev] : [];

            data?.body.result.forEach((channel) => {
                const channelCode = channel.channel_code;
                // 시간 별로 정보 추출
                channel.schedules?.forEach((schedule) => {
                    try {
                        const programStartTime = moment(String(schedule.broadcast_start_time), 'YYYYMMDDHHmmss');
                        const programEndTime = moment(String(schedule.broadcast_end_time), 'YYYYMMDDHHmmss');
                        const isProgramEnded = nowTime > schedule.broadcast_end_time;
                        const isProgramOngoing = nowTime > schedule.broadcast_start_time && nowTime < schedule.broadcast_end_time;
                        const isProgramNotStarted = nowTime < schedule.broadcast_start_time;
                        const isTodayBroadcasted = programStartTime.format('YYYYMMDD') === nowDay.format('YYYYMMDD');

                        const elapsedPercentage =
                            isProgramOngoing && isTodayBroadcasted
                                ? Math.min(
                                      Math.max(Math.round((nowDay.diff(programStartTime) / programEndTime.diff(programStartTime)) * 100), 0),
                                      100,
                                  )
                                : 0;

                        const programStartTimeMinutesOnly = programStartTime.format('mm');
                        const targetMinutes =
                            Number(programStartTimeMinutesOnly) < 30 ? Number(programStartTimeMinutesOnly) : Number(programStartTimeMinutesOnly) - 30;

                        let itemLeftOffsetAsRatio = targetMinutes / 30 + 0.5;
                        const startTime = moment.duration(programStartTime.diff(moment('0000', 'HHmm')));
                        let startSlide = startTime.hours() * 2 + Math.floor(startTime.minutes() / 30);
                        let itemWidthAsPercentage = (moment.duration(programEndTime.diff(programStartTime)).asMinutes() / 30) * 100;

                        if (startSlide < 0 || !isTodayBroadcasted) {
                            const lastTime = moment(nowDay.format('YYYYMMDD'), 'YYYYMMDD');
                            itemWidthAsPercentage =
                                (moment.duration(programEndTime.diff(lastTime.subtract(1, 'days').set({ hour: 23, minute: 45 }))).asMinutes() / 30) *
                                100;
                            startSlide = 0;
                            itemLeftOffsetAsRatio = 0;
                        }

                        // 예약여부 체크
                        const isReserved =
                            reserveList?.some((reserveItem) => {
                                if (channelCode === reserveItem.channel_code && schedule.broadcast_start_time == reserveItem.reserve_time) {
                                    return true;
                                }
                                return false;
                            }) || false;

                        nextDataList[startSlide]?.channels[channelList.indexOf(channelCode)]?.push({
                            ...schedule,
                            ...proxyLive({}, { schedule }),
                            channelCode,
                            programCode: schedule.program?.code,
                            movieCode: schedule.movie?.code,
                            episodeCode: schedule.episode?.stream_meta_info ? schedule.episode?.code : '',
                            programName: schedule.program?.name?.ko,
                            episodeName: schedule.episode?.name?.ko,
                            programStartTime: schedule.broadcast_start_time,
                            progressPer: itemLeftOffsetAsRatio,
                            rightPer: itemWidthAsPercentage,
                            startDate: programStartTime.format('HH:mm'),
                            endDate: programEndTime.format('HH:mm'),
                            isPassed: isProgramEnded,
                            isLive: isProgramOngoing,
                            isAdult: schedule.program?.adult_yn === 'Y',
                            livePercent: elapsedPercentage,
                            isNext: isProgramNotStarted,
                            isReserved,
                        });

                        nextDataList[startSlide] = { ...nextDataList[startSlide] };
                    } catch (e) {
                        console.error('#api', 'error', e);
                    }
                });
            });

            return nextDataList;
        });

        return data;
    };

    // TODO: 24 / CONFIG.TIME_INTERVAL_SEGMENT_FOR_LAZY_LOADING로 나누어서 사용할 수 있도록 수정
    // hook 호출을 함수 내부에서 할 수 없어 수정 불가
    const dataSet = [
        useAbortSWRInfinite(viewingDateFetchUrlList[0], params, swrOptions, updateDataListAfterFetched),
        useAbortSWRInfinite(viewingDateFetchUrlList[1], params, swrOptions, updateDataListAfterFetched),
        useAbortSWRInfinite(viewingDateFetchUrlList[2], params, swrOptions, updateDataListAfterFetched),
        useAbortSWRInfinite(viewingDateFetchUrlList[3], params, swrOptions, updateDataListAfterFetched),
        useAbortSWRInfinite(viewingDateFetchUrlList[4], params, swrOptions, updateDataListAfterFetched),
        useAbortSWRInfinite(viewingDateFetchUrlList[5], params, swrOptions, updateDataListAfterFetched),
        useAbortSWRInfinite(viewingDateFetchUrlList[6], params, swrOptions, updateDataListAfterFetched),
        useAbortSWRInfinite(viewingDateFetchUrlList[7], params, swrOptions, updateDataListAfterFetched),
    ];

    useEffect(() => {
        dataSet.forEach(({ data, size, setSize }) => {
            if (data[0]) {
                if (size === 1) {
                    setSize(2);
                }
            }
        });
    });

    const [previousDateFetchUrl, setPreviousDateFetchUrl] = useState(null);
    const previousDateData = useAbortSWRInfinite(previousDateFetchUrl, params, swrOptions, updateDataListAfterFetched);
    useEffect(() => {
        const { data, size, setSize } = previousDateData || {};
        if (data[0]) {
            if (size === 1) {
                setSize(2);
            }
        }
    }, [previousDateData]);

    useEffect(() => {
        if (dataList.length === 0 || lazyIdx === -1) {
            return;
        }

        setViewingDateFetchUrlList((prev) => {
            const nextFetchList = [...prev];

            if (nextFetchList[lazyIdx] === null) {
                nextFetchList[lazyIdx] = getFetchScheduleUrl(lazyIdx);
            }
            return nextFetchList;
        });

        lazyIdx === 0 && setPreviousDateFetchUrl(getFetchPreviousDateScheduleUrl());
    }, [lazyIdx, isValidating]);

    useEffect(() => {
        setLazyIdx(-1);
        setDataList([]);
        setPreviousDateFetchUrl(null);
        setViewingDateFetchUrlList(getInitialViewingDateFetchUrlList());
    }, [broadDay]);

    return { dataList, channelLogoImageList, initialSlide, setActiveSlide, lazyIdx, setLazyIdx };
};
