import { Logo, Switch, Text } from '@tving/ui';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { KboMyTeamItem } from '@typings/kbo/myTeam';
import { KboNavigationContext } from '@contexts/kbo/navigation/KboNavigationContext';

interface SwitchComponentProps {
    text: string;
    checked: boolean;
    setChecked: (checked: boolean) => void;
    handleClick: () => void;
}

const SwitchComponent = ({ text, checked, setChecked, handleClick }: SwitchComponentProps) => {
    return (
        <section className="flex items-center w-[100%] h-[4.333rem] justify-between text-[1.167rem]">
            {text}
            <div onClick={handleClick}>
                <Switch width="2.75rem" height="1.5rem" circleSize="1.2rem" sliderXPadding="0.1rem" checked={checked} setChecked={setChecked} />
            </div>
        </section>
    );
};

/**
 * 마이팀 선택 시 체크하는 처리하는 버튼
 * @constructor
 */
interface CheckComponentProps {
    isSelected: boolean;
    handleClick: () => void;
}

const CheckComponent = ({ isSelected, handleClick }: CheckComponentProps) => {
    const { device } = useContext(KboNavigationContext);
    const { isMobileWebview: isMobile } = device;
    const svgClassName = isMobile ? 'w-[2.333rem] h-[2.333rem] ' : 'w-[2rem] h-[2rem]';
    return (
        <button type="button" className="flex items-center  w-[2.333rem] h-[2.333rem] " onClick={handleClick}>
            {isSelected ? (
                <svg className={svgClassName} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="14" cy="13.9987" r="11.6667" fill="white" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.07 11.7512L12.6389 19.1823L7.92996 14.4734L9.18115 13.2222L12.6389 16.68L18.8189 10.5L20.07 11.7512Z"
                        fill="black"
                    />
                </svg>
            ) : (
                <svg className={svgClassName} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11.6667" cy="11.6667" r="11.1667" transform="matrix(1 0 0 -1 2.33331 25.668)" stroke="#737373" />
                </svg>
            )}
        </button>
    );
};

interface KboMyTeamSettingItemProps {
    imageUrl: string;
    name: string;
    isSelected: boolean;
    currentTeam: KboMyTeamItem; // 서버 응답으로 표기된 팀
    setPickedTeam: Dispatch<SetStateAction<KboMyTeamItem>>;
    doMatchPush?: boolean;
    doHighlightPush?: boolean;
}

const KboMyTeamSettingItem = ({
    imageUrl,
    name,
    isSelected,
    currentTeam,
    setPickedTeam,
    doMatchPush,
    doHighlightPush,
}: KboMyTeamSettingItemProps) => {
    const { device } = useContext(KboNavigationContext);
    const { isMobileWebview: isMobile } = device;

    const [isSetMatchPush, setMathPush] = useState(!currentTeam.isMyTeam && doMatchPush === undefined ? true : !!doMatchPush);
    const [isSetHighlightPush, setHighlightPush] = useState((!currentTeam.isMyTeam && doHighlightPush) === undefined ? true : !!doHighlightPush);
    const currentTeamColor = currentTeam.color;

    const handleClickCheckComponent = () => {
        if (!isSelected) {
            setPickedTeam({
                ...currentTeam,
                doMatchPush: isSetMatchPush,
                doHighlightPush: isSetHighlightPush,
            });
        } else {
            setPickedTeam({} as KboMyTeamItem);
        }
    };

    const handleClickMathPush = () => {
        setPickedTeam({
            ...currentTeam,
            doMatchPush: !isSetMatchPush,
            doHighlightPush: isSetHighlightPush,
        });
    };

    const handleClickHighlightPush = () => {
        setPickedTeam({
            ...currentTeam,
            doMatchPush: isSetMatchPush,
            doHighlightPush: !isSetHighlightPush,
        });
    };

    useEffect(() => {
        if (!isSelected && (!isSetMatchPush || !isSetHighlightPush)) {
            setMathPush(true);
            setHighlightPush(true);
        }
    }, [isSelected, isSetMatchPush, isSetHighlightPush]);

    return (
        <section className={`kbo-myteam-item-${currentTeam?.code} flex flex-col ${isMobile ? 'px-[1.33rem]' : 'px-[2.5rem]'}`}>
            <section className={`main flex justify-between w-[100%] ${isMobile ? 'h-[6.667rem]' : 'h-[6rem]'}  items-center`}>
                <div className="flex items-center w-[100%]">
                    <button type="button" className="flex items-center cursor-pointer " onClick={handleClickCheckComponent}>
                        <Logo
                            style={{
                                backgroundColor: currentTeamColor,
                            }}
                            src={imageUrl}
                            className={`logo cursor-pointer rounded-[0.33rem] ${
                                isMobile ? 'w-[4.66667rem] h-[4.66667rem]' : 'w-[4rem] h-[4rem] '
                            }  mr-[1rem]`}
                        />

                        <Text title={name} className={`${isMobile ? 'text-[1.167rem]' : 'text-[1.1667rem]'} text-white`} />
                    </button>
                </div>
                <CheckComponent isSelected={isSelected} handleClick={handleClickCheckComponent} />
            </section>
            {isMobile && (
                <section className={`sub ${isSelected ? 'flex flex-col' : 'hidden'}`}>
                    <SwitchComponent text="경기 알림" checked={isSetMatchPush} setChecked={setMathPush} handleClick={handleClickMathPush} />
                    <SwitchComponent
                        text="하이라이트 알림"
                        checked={isSetHighlightPush}
                        setChecked={setHighlightPush}
                        handleClick={handleClickHighlightPush}
                    />
                    <div className="flex items-center bg-[#262626] w-[100%] h-[0.083rem] mt-[1rem] mb-[1rem] " />
                </section>
            )}
        </section>
    );
};
export default KboMyTeamSettingItem;
