import Poster, { PosterProps } from '../../atom/poster/Poster';
import Text, { TextProps } from '../../atom/text/Text';
import Labels, { LabelsProps } from '../labels/Labels';

type specialItemProps = {
  className?: string;
  poster: PosterProps;
  labels: LabelsProps;
  posterText: TextProps<React.ElementType>;
  tabIndex?: number;
};

const SpecialItem = ({ className, poster, posterText, tabIndex, labels }: specialItemProps) => {
  return (
    <div className={`special-item-wrapper ${className}`} tabIndex={tabIndex}>
      {posterText && <Text {...posterText} />}
      <Poster {...poster} />
      <Labels {...labels} />
    </div>
  );
};

export default SpecialItem;
