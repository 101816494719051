import Age19Label from '../../molecules/labels/age19/Age19Label';
import CmmgAge19Label from '../../molecules/labels/age19/CmmgAge19Label';
import DubbingLabel from '../../molecules/labels/dubbing/DubbingLabel';
import FreeLabel from '../../molecules/labels/free/FreeLabel';
import LiveLabel from '../../molecules/labels/live/LiveLabel';
import NewEpisodeLabel from '../../molecules/labels/newEpisode/NewEpisodeLabel';
import OnlyLabel from '../../molecules/labels/only/OnlyLabel';
import OriginalLabel from '../../molecules/labels/original/OriginalLabel';
import QuickVodLabel from '../../molecules/labels/quickVod/QuickVodLabel';
import SubtitleLabel from '../../molecules/labels/subtitle/SubtitleLabel';
import TsmLabel from '../../molecules/labels/tsm/TsmLabel';
import { LABEL_TYPE } from '../../types';

export interface LabelsProps {
  type: LABEL_TYPE;
  rightTopLabelsWrapper: string;
  leftTopLabelsWrapper?: string;
  free?: string | boolean;
  only?: string | boolean;
  original?: string | boolean;
  quick?: string | boolean;
  newEpisode?: string | boolean;
  live?: string | boolean;
  subtitle?: string | boolean;
  dubbing?: string | boolean;
  age18?: string | boolean;
  age19?: string | boolean;
  tabIndex?: number;
  tsm?: string | boolean;
}

const Labels = (labelsProps: LabelsProps) => {
  const {
    type,
    free,
    rightTopLabelsWrapper,
    leftTopLabelsWrapper,
    original,
    live,
    only,
    quick,
    newEpisode,
    subtitle,
    dubbing,
    tabIndex,
    age18,
    age19,
    tsm,
  } = labelsProps;
  return (
    <>
      {original && <OriginalLabel className={`${original}`} type={type} tabIndex={tabIndex} />}
      {only && <OnlyLabel className={`${only}`} type={type} tabIndex={tabIndex} />}
      {newEpisode && <NewEpisodeLabel className={`${newEpisode}`} type={type} tabIndex={tabIndex} />}
      {quick && <QuickVodLabel className={`${quick}`} type={type} tabIndex={tabIndex} />}
      {free && <FreeLabel className={`${free}`} type={type} tabIndex={tabIndex} />}

      <div className={leftTopLabelsWrapper}>
        {live && <LiveLabel className={`${live}`} type={type} tabIndex={tabIndex} />}
        {tsm && <TsmLabel className={`${tsm}`} tabIndex={tabIndex} />}
      </div>

      <div className={rightTopLabelsWrapper}>
        {subtitle && <SubtitleLabel className={`${subtitle}`} type={type} tabIndex={tabIndex} />}
        {dubbing && <DubbingLabel className={`${dubbing}`} type={type} tabIndex={tabIndex} />}
        {age18 && <CmmgAge19Label className={`${age18}`} type={type} tabIndex={tabIndex} />}
        {age19 && <Age19Label className={`${age19}`} type={type} tabIndex={tabIndex} />}
      </div>
    </>
  );
};

export default Labels;
