import { KBO_GAME_STATUS } from '@typings/kbo/index';
import { KboCategoryItem, KboPointItem } from '@typings/kbo/item';

export enum KBO_BAND_TYPE {
    KBO_HOME_LIVE = 'KBO_HOME_LIVE',
    KBO_BANNER_LINE = 'BANNER_LINE',
    KBO_PLAYER_LEADERBOARD = 'KBO_PLAYER_RANKING_TOP5',
    KBO_HOME_PLAYER_LEADERBOARD = 'KBO_PLAYER_RANKING_TOP5',
    KBO_HOME_TEAM_RANKING = 'KBO_HOME_TEAM_RANKING',
    KBO_TEAM_RANKING = 'KBO_TEAM_RANKING',
    KBO_PLAYER_RANKING = 'KBO_PLAYER_RANKING',
    KBO_SHORTS = 'KBO_SHORTS',
    KBO_TEAMS = 'KBO_TEAMS',
    KBO_LIVE = 'LIVE_BASIC',
    KBO_LIVE_BASIC = 'KBO_LIVE_BASIC',
    KBO_TEAM_LINEUP = 'KBO_TEAM_LINEUP',
    KBO_MY_TEAM_LINEUP = 'KBO_MY_TEAM_LINEUP',
    KBO_SCHEDULE = 'KBO_SCHEDULE',
    KBO_LIVE_SCORE = 'KBO_LIVE_SCORE',
    KBO_LIVE_RUNS = 'KBO_LIVE_RUNS',
    KBO_CLIP_HORIZONTAL = 'KBO_CLIP_HORIZONTAL',
    KBO_CLIP_VERTICAL = 'KBO_CLIP_VERTICAL',
    KBO_CLIP_RANKING = 'KBO_CLIP_RANKING',
    KBO_LIVE_LINEUP = 'KBO_LIVE_LINEUP',
    KBO_LIVE_LINEUP_BACKUP = 'KBO_LIVE_LINEUP_BACKUP',
    KBO_LIVE_SCHEDULE = 'KBO_LIVE_SCHEDULE',
    VOD_BASIC = 'VOD_BASIC',
    KBO_EPISODE = 'KBO_EPISODE',
    KBO_BASE_RUNNING = 'KBO_BASE_RUNNING', // 라이브 상세 중계 - 주루화면
    KBO_MESSAGE = 'KBO_MESSAGE',
    KBO_RUNS_POINT = 'KBO_RUNS_POINT',
    KBO_TEAM_SCHEDULE = 'KBO_TEAM_SCHEDULE',
    KBO_PRIMARY_RECORD = 'KBO_PRIMARY_RECORD',
    KBO_HITTER_RECORD = 'KBO_HITTER_RECORD',
    KBO_PITCHER_RECORD = 'KBO_PITCHER_RECORD',
    KBO_MY_TEAM = 'KBO_MY_TEAM',
    KBO_STREAM_SCHEDULE = 'KBO_STREAM_SCHEDULE',
    KBO_POWER_TEAM = 'KBO_POWER_TEAM',
    KBO_POWER_PITCHER = 'KBO_POWER_PITCHER',
    KBO_POWER_HITTER = 'KBO_POWER_HITTER',
    KBO_STREAM_CLIP = 'KBO_STREAM_CLIP',
    KBO_CLIP_PLAYLIST = 'KBO_CLIP_PLAYLIST',
    KBO_STREAM_MULTI_VIEW = 'KBO_STREAM_MULTI_VIEW',
    KBO_LIVE_ONAIR = 'LIVE_ONAIR',
    // 선수 상세
    KBO_ATHLETE_PROFILE = 'KBO_PLAYER_PROFILE',
    KBO_ATHLETE_SEASON_RECORD = 'KBO_PLAYER_SEASON_RECORD',
    KBO_ATHLETE_TOTAL_RECORD = 'KBO_PLAYER_WHOLE_RECORD',
    // 구단 상세
    KBO_TEAM_SEASON_RECORD = 'KBO_TEAM_SEASON_RECORD',
    KBO_TEAM_SHORTCUT = 'KBO_TEAM_SHORTCUT',
    KBO_TEAM_ROASTER = 'KBO_TEAM_ROASTER',
    DA_LINE = 'DA_LINE',
    BANNER_IMAGE = 'BANNER_IMAGE',
}

export type KboBand<I = unknown> = {
    bandId: string;
    bandType: KBO_BAND_TYPE;
    bandName: string;
    moreUrl?: string;
    items: I[];
    isBlock?: boolean;
    categories?: KboCategoryItem[];
    nextApiUrl?: string;
    teamName?: string;
    teamShortName?: string;
};

// export type

export type KboChannel = {
    code: string;
    name: string;
    broadcastName: string;
    imageUrl: string;
    stillshotUrl: string;
    broadcastStartTime: number;
    broadcastEndTime: number;
    broadcastFormat: 'BASIC' | 'TSM';
    badgeImageUrl: string;
    channelLogoUrl: string;
};

export type KboRoasterBand = KboBand<{
    code: string;
    name: string;
    imageUrl: string;
    backNumber: string;
}> & {
    teamName: string;
};

export type KboPointsBand = KboBand<KboPointItem> & {
    gameDateTime: number; // YYYYMMDDHHmmss
};

export type Category = {
    name: string;
    linkUrl: string;
    isSelected: boolean;
    apiUrl: string;
    code: string;
};

export type KboNotOpenBand = Omit<KboBand, 'items'>;

export type KboCategorisedBand<I> = KboBand<I> & {
    categories: Category[];
};

export type Column = {
    name: string;
    variableName: string;
};

export type KboTableBand<I> = KboBand<I> & {
    column: Column[];
};

export type KboCalendarBand<I> = KboBand<I> & {
    calendar: number[];
    focusDate: number;
    prevGameDate: number | null;
    nextGameDate: number | null;
};

export type KboClipMoreBand<I> = {
    bands: KboBand<I>[];
    previewImage: string;
    title: string[];
};

export type KboRankingBand<I> = KboBand<I> & {
    column: Column[];
    calendar: number[]; // [2022, 2023, ...]
    focusDate: number;
    season: string;
    seasons: string[];
};

export type KboInningBand<I> = KboBand<I> & {
    inning: string;
    outCount: number;
    offensiveTeamName: string;
    status: KBO_GAME_STATUS;
};

export type KboClipPlaylistBand<I> = KboBand<I> & {
    gameDateTime: number; // YYYYMMDDHHmmss
};

export enum KBO_TAB_TYPE {
    home = 'home',
    video = 'video',
    schedule = 'schedule',
    history = 'history',
}
