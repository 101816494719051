/**
 * The name of the localStorage key.
 */
const prefix = 'tving';

/**
 * Store a key value pair locally.
 * @param key   The key
 * @param value Any serializable value
 */
const setLocalStorage = (key: string, value: string) => {
    try {
        window.localStorage[`${prefix}_${key}`] = value;
    } catch (error) {
        //
    }
};

/**
 * Get value by key locally
 * @param key The key
 * @param defaultValue
 */
const getLocalStorage = (key: string, defaultValue: string) => {
    try {
        const value = window.localStorage[`${prefix}_${key}`];
        return value === undefined ? defaultValue : value;
    } catch (error) {
        return defaultValue;
    }
};

/**
 * Get value by key locally
 * @param key The key
 */
const removeLocalStorage = (key: string) => {
    try {
        window.localStorage.removeItem(`${prefix}_${key}`);
    } catch (error) {
        //
    }
};

export const storage = {
    set: setLocalStorage,
    get: getLocalStorage,
    remove: removeLocalStorage,
};
