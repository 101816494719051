import { ErrorBoundary, Suspense } from '@suspensive/react';

interface ErrorBoundaryWrapperProps {
    children: React.ReactNode;
    loadingFallback?: React.ReactNode;
    errorFallback?: React.ReactNode;
}
const ErrorBoundaryWrapper = ({ children, loadingFallback = null, errorFallback = null }: ErrorBoundaryWrapperProps) => {
    return (
        <ErrorBoundary fallback={errorFallback}>
            <Suspense clientOnly fallback={loadingFallback}>
                {children}
            </Suspense>
        </ErrorBoundary>
    );
};
export default ErrorBoundaryWrapper;
