import { getWindowPopCenter } from '@utils/common/Popup';
import { billOrigin } from '@utils/apiConfig';
import { URL_NAVER_GOODBYE_MEMBERSHIP } from '@constants/urls';

interface openMembershipPlansOfBillingProps {
    packageId: number;
    extAppId?: string;
}

// 티빙 이용권페이지 상품 클릭 시 상품주기선택(빌링) 팝업
export const openMembershipPlansOfBilling = ({ packageId, extAppId = '' }: openMembershipPlansOfBillingProps) => {
    const width = 600;
    const height = 650;
    const { left, top } = getWindowPopCenter({ w: width, h: height });
    window.open(
        `${billOrigin}/bill/productList/period.tving?packageId=${packageId}&extAppId=${extAppId}&returnUrl=${encodeURIComponent(
            window.location.href,
        )}`,
        'BILL_MEMBERSHIP_POPUP',
        `scrollbars=no,menubar=no,control=no,resizable=no,width=${width},height=${height},top=${top},left=${left}`,
    );
};

// 이용권 구매하기, 다른 이용권 보기 버튼 > 이용권 구매(빌링) 팝업
export const openPurchaseProductList = () => {
    const width = 600;
    const height = 700;
    const { left, top } = getWindowPopCenter({ w: width, h: height });
    window.open(
        `${billOrigin}/bill/productList.tving`,
        'BILL_PRODUCT_LIST_POPUP',
        `scrollbars=no,menubar=no,control=no,resizable=no,width=${width},height=${height},top=${top},left=${left}`,
    );
};

// 네플멤 이용권 종료 마케팅 페이지 새창 오픈
export const openNaverGoodbyeMembership = () => {
    window.open(URL_NAVER_GOODBYE_MEMBERSHIP, '_blank', 'noreferrer');
};

// 특정 페이지 새창 오픈
export const openExternalPage = ({ url }: { url: string }) => {
    window.open(url, '_blank', 'noreferrer');
};

export const openExternalPopup = ({ url, width = 600, height = 700 }) => {
    const { left, top } = getWindowPopCenter({ w: width, h: height });
    window.open(url, 'POPUP', `scrollbars=no,menubar=no,control=no,resizable=no,width=${width},height=${height},top=${top},left=${left}`);
};
