import { useAbortSWR, useAbortSWRInfinite } from '@store/apis/plugins';
import moment from 'moment';
import { imgOrigin, searchOrigin } from '@utils/apiConfig';
import { getAgeGrade } from '@utils/common/Code';
import { useEffect, useState, useRef } from 'react';
import ApiSend from '@utils/common/ApiSend';
import { useUserInfo } from '../state';
import { logFbaSearchAutoComplete } from '@utils/firebaseAnalytics';
import { useCookies } from 'react-cookie';
import { getCookie } from '@utils/common/cookie';

/**
 * My > 시청 내역 List
 * @param {number} pageNo - 페이지 번호.
 * @return {Object|boolean} data, source, isLoading, isError Value.
 */
export const useSearchView = ({ keyword = '', category = 'TOTAL' } = {}) => {
    const [cookies] = useCookies(['_tving_token']);
    const { _tving_token } = cookies || {};
    const [{ isLoading: isLoadingUserInfo = true, userId }] = useUserInfo();

    keyword = encodeURIComponent(keyword);

    return useAbortSWRInfinite(
        !isLoadingUserInfo ? `${searchOrigin}/search/getSearch.jsp?kwd=${keyword}&category=${category}` : null,
        {
            params: {
                notFoundText: keyword,
                userId,
                siteName: 'TVING_WEB',
                pageSize: '20',
                indexType: 'both',
                methodType: 'allwordthruindex',
                payFree: 'ALL',
                runTime: 'ALL',
                grade: 'ALL',
                genre: 'ALL',
                screen: 'CSSD0100',
                os: 'CSOD0900',
                network: 'CSND0900',
                sort1: 'NO',
                sort2: 'NO',
                sort3: 'NO',
                type1: 'desc',
                type2: 'desc',
                type3: 'desc',
                fixedType: 'Y',
                spcMethod: 'someword',
                spcSize: '0',
                schReqCnt: '20',
                vodBCReqCnt: '20',
                programReqCnt: '20',
                vodMVReqCnt: '20',
                aloneReqCnt: '20',
                smrclipReqCnt: '0',
                pickClipReqCnt: 0,
                cSocialClipCnt: '0',
                boardReqCnt: '0',
                talkReqCnt: '0',
                nowTime: '',
                mode: 'normal',
                adult_yn: '',
                reKwd: '',
                xwd: '',
            },
            headers: {
                Authorization: `Bearer ${_tving_token}`,
            },
        },
        {},
        (data) => {
            const { programRsb, schRsb, pipTvRsb, vodMVRsb } = data || {};

            const {
                sch_model: programSchModel,
                sch_rec_model: programSchRecModel,
                sch_collection: programSchCollection,
                abtest_group: programAbtestGroup,
            } = programRsb || {};
            const programImpressionSchSr = programRsb.dataList?.map((rsbData) => rsbData.sch_sr)?.join('');
            const programLists = programRsb.dataList?.map(
                ({
                    actor,
                    broad_dt,
                    free_yn,
                    grade_cd,
                    mast_cd,
                    mast_nm,
                    quickvod,
                    score,
                    paramount_yn,
                    tving_exclusive_yn,
                    tving_original_yn,
                    web_url4,
                    sch_sr,
                }) => {
                    return {
                        actor,
                        broadDate: broad_dt,
                        isFree: free_yn === 'Y',
                        gradeCode: grade_cd,
                        ageGrade: getAgeGrade(grade_cd),
                        programCode: mast_cd,
                        code: mast_cd,
                        title: mast_nm,
                        isQuickVod: quickvod,
                        score,
                        targetage: '15',
                        isExclusive: tving_exclusive_yn === 'Y',
                        isOriginal: tving_original_yn === 'Y',
                        isParamount: paramount_yn === 'Y',
                        thumbnail: imgOrigin + web_url4,
                        schModel: programSchModel,
                        schRecModel: programSchRecModel,
                        schCollection: programSchCollection,
                        abtestGroup: programAbtestGroup,
                        impressionSchSr: programImpressionSchSr,
                        clickSchSr: sch_sr,
                    };
                },
            );

            const {
                sch_model: schSchModel,
                sch_rec_model: schSchRecModel,
                sch_collection: schSchCollection,
                abtest_group: schAbtestGroup,
            } = schRsb || {};
            const schImpressionSchSr = schRsb.dataList?.map((rsbData) => rsbData.sch_sr)?.join('');

            const liveLists = schRsb.dataList?.map(
                ({
                    amt,
                    broad_dt,
                    broad_end_dt,
                    broad_idx,
                    broad_min_idx,
                    broad_st_dt,
                    ch_cd,
                    ch_kwd,
                    ch_nm,
                    ch_sort_no,
                    epi_cd,
                    epi_nm,
                    free_yn,
                    frequency,
                    grade_cd,
                    grade_nm,
                    gubun,
                    hd_yn,
                    key_value,
                    mast_nm,
                    net_cd,
                    os_cd,
                    score,
                    scr_cd,
                    traffic,
                    sch_sr,
                }) => {
                    let subtitle = mast_nm;
                    subtitle += frequency && ` ${frequency}화`;
                    return {
                        amt,
                        broadcastDate: broad_dt,
                        broadcastEndTime: broad_end_dt,
                        broadcastStartTime: broad_st_dt,
                        broadIdx: broad_idx,
                        broadMinIdx: broad_min_idx,
                        code: ch_cd,
                        chKwd: ch_kwd,
                        title: ch_nm,
                        chSortNo: ch_sort_no,
                        epiCd: epi_cd,
                        epiNm: epi_nm,
                        frequency,
                        gradeCode: grade_cd,
                        ageGrade: getAgeGrade(grade_cd),
                        gradeNm: grade_nm,
                        gubun,
                        hdYn: hd_yn,
                        keyValue: key_value,
                        subtitle,
                        netCd: net_cd,
                        osCd: os_cd,
                        isFree: free_yn === 'Y',
                        score,
                        scrCd: scr_cd,
                        traffic,
                        // thumbnail: imgOrigin + web_url3,
                        thumbnail: `//stillshot.tving.com/thumbnail/${ch_cd}_1_640x360.jpg`,

                        schModel: schSchModel,
                        schRecModel: schSchRecModel,
                        schCollection: schSchCollection,
                        abtestGroup: schAbtestGroup,
                        impressionSchSr: schImpressionSchSr,
                        clickSchSr: sch_sr,
                    };
                },
            );

            const {
                sch_model: tvingtvSchModel,
                sch_rec_model: tvingtvSchRecModel,
                sch_collection: tvingtvSchCollection,
                abtest_group: tvingtvAbtestGroup,
            } = pipTvRsb || {};
            const tvingtvImpressionSchSr = pipTvRsb.dataList?.map((rsbData) => rsbData.sch_sr)?.join('');
            const tvingtvLists = pipTvRsb.dataList?.map(
                ({
                    amt,
                    broad_dt,
                    broad_end_dt,
                    broad_idx,
                    broad_min_idx,
                    broad_st_dt,
                    ch_cd,
                    ch_kwd,
                    ch_nm,
                    ch_sort_no,
                    epi_cd,
                    epi_nm,
                    free_yn,
                    frequency,
                    grade_cd,
                    grade_nm,
                    gubun,
                    hd_yn,
                    key_value,
                    mast_nm,
                    net_cd,
                    os_cd,
                    score,
                    scr_cd,
                    traffic,
                    web_url7,
                    sch_sr,
                }) => {
                    return {
                        amt,
                        broadcastDate: broad_dt,
                        broadcastEndTime: broad_end_dt,
                        broadcastStartTime: broad_st_dt,
                        broadIdx: broad_idx,
                        broadMinIdx: broad_min_idx,
                        code: ch_cd,
                        chKwd: ch_kwd,
                        title: ch_nm,
                        chSortNo: ch_sort_no,
                        epiCd: epi_cd,
                        epiNm: epi_nm,
                        frequency,
                        gradeCode: grade_cd,
                        ageGrade: getAgeGrade(grade_cd),
                        gradeNm: grade_nm,
                        gubun,
                        hdYn: hd_yn,
                        keyValue: key_value,
                        mastNm: mast_nm,
                        netCd: net_cd,
                        osCd: os_cd,
                        isFree: free_yn === 'Y',
                        score,
                        scrCd: scr_cd,
                        traffic,
                        thumbnail: imgOrigin + web_url7,
                        schModel: tvingtvSchModel,
                        schRecModel: tvingtvSchRecModel,
                        schCollection: tvingtvSchCollection,
                        abtestGroup: tvingtvAbtestGroup,
                        impressionSchSr: tvingtvImpressionSchSr,
                        clickSchSr: sch_sr,
                    };
                },
            );

            const {
                sch_model: movieSchModel,
                sch_rec_model: movieSchRecModel,
                sch_collection: movieSchCollection,
                abtest_group: movieAbtestGroup,
            } = vodMVRsb || {};
            const movieImpressionSchSr = vodMVRsb.dataList?.map((rsbData) => rsbData.sch_sr)?.join('');
            const movieLists = vodMVRsb.dataList?.map(
                ({
                    actor,
                    amt,
                    bill,
                    bill_my_catchon_yn,
                    bill_tag,
                    broad_dt,
                    cate_nm,
                    cine_same_yn,
                    direct_ver_yn,
                    director,
                    dub_ver_yn,
                    event_yn,
                    extend_ver_yn,
                    first_open_yn,
                    free_yn,
                    gpa,
                    grade_cd,
                    grade_nm,
                    gubun,
                    is_4k,
                    key_value,
                    mast_cd,
                    mast_kwd,
                    mast_nm,
                    net_cd,
                    os_cd,
                    pack_yn,
                    paramount_yn,
                    pgm_cd,
                    runtime_mi,
                    runtime_sec,
                    score,
                    scr_cd,
                    special_ver_yn,
                    subtitle_ver_yn,
                    tving_exclusive_yn,
                    tving_original_yn,
                    unedit_ver_yn,
                    view_cnt_yest,
                    web_url5,
                    sch_sr,
                }) => {
                    return {
                        actor,
                        amt,
                        bill,
                        billMyCatchon_yn: bill_my_catchon_yn,
                        billingTag: bill_tag,
                        broadDatte: broad_dt,
                        cate_nm,
                        // cine_same_yn,
                        direct_ver_yn,
                        director,
                        // dub_ver_yn,
                        gpa,
                        gradeCode: grade_cd,
                        ageGrade: getAgeGrade(grade_cd),
                        isAdult: grade_nm === '청소년관람불가',
                        gubun,
                        is_4k,
                        key_value,
                        code: mast_cd,
                        mast_kwd,
                        title: mast_nm,
                        netCd: net_cd,
                        osCd: os_cd,
                        isPack: pack_yn === 'Y',
                        isFree: free_yn === 'Y',
                        programCode: pgm_cd,
                        runtimeMi: runtime_mi,
                        runtimeSec: runtime_sec,
                        score,
                        scrCd: scr_cd,
                        isSpecialVer: special_ver_yn === 'Y',
                        isSubtitle: subtitle_ver_yn === 'Y',
                        // tot_view_cnt:tot_view_cnt,
                        // traffic:traffic,
                        isExclusive: tving_exclusive_yn === 'Y',
                        isOriginal: tving_original_yn === 'Y',
                        isParamount: paramount_yn === 'Y',

                        isEvent: event_yn === 'Y',
                        isExtendVer: extend_ver_yn === 'Y',
                        isFirstRelease: first_open_yn === 'Y',
                        isCinemaSame: cine_same_yn === 'Y', // 극장동시 여부
                        isDubbing: dub_ver_yn === 'Y', // 더빙 여부

                        unedit_ver_yn,
                        view_cnt_yest,
                        thumbnail: imgOrigin + web_url5,

                        schModel: movieSchModel,
                        schRecModel: movieSchRecModel,
                        schCollection: movieSchCollection,
                        abtestGroup: movieAbtestGroup,
                        impressionSchSr: movieImpressionSchSr,
                        clickSchSr: sch_sr,
                    };
                },
            );

            const totalObj = {
                lists: [],
            };

            if (Array.isArray(programLists) && programLists.length > 0) {
                totalObj.lists.push({
                    data: {
                        items: programLists,
                    },
                    isLoading: false,
                    isError: false,
                    totalCount: programRsb.count,
                    categoryCode: 'PROGRAM',
                    bandType: 'programBasic',
                });
            }

            if (Array.isArray(liveLists) && liveLists.length > 0) {
                totalObj.lists.push({
                    data: {
                        items: liveLists,
                    },
                    isLoading: false,
                    isError: false,
                    totalCount: schRsb.count,
                    categoryCode: 'SCH',
                    bandType: 'liveBasic',
                });
            }

            if (Array.isArray(tvingtvLists) && tvingtvLists.length > 0) {
                totalObj.lists.push({
                    data: {
                        items: tvingtvLists,
                    },
                    isLoading: false,
                    isError: false,
                    totalCount: pipTvRsb.count,
                    categoryCode: 'PIPTV',
                    bandType: 'tvingtvBasic',
                });
            }

            if (Array.isArray(movieLists) && movieLists.length > 0) {
                totalObj.lists.push({
                    data: {
                        items: movieLists,
                    },
                    isLoading: false,
                    isError: false,
                    totalCount: vodMVRsb.count,
                    categoryCode: 'VODMV',
                    bandType: 'movieBasic',
                });
            }

            const targetObj = { ...totalObj.lists[0] };
            targetObj.items = totalObj.lists[0]?.data?.items;
            targetObj.totalCount = data.total;
            targetObj.isMore = targetObj.items?.length >= 20;

            return category === 'TOTAL' ? totalObj : targetObj;
        },
        'pageNum',
    );
};

export const useSearchAkc = ({ keyword = '', encodedKeyword = '', isAutoComplete = false } = {}) => {
    const [data, setData] = useState({});
    const didMount = useRef(false);
    const [{ isLoading: isLoadingUserInfo = true, userId, profileNo }] = useUserInfo();
    const [cookies] = useCookies(['_tving_token']);
    const { _tving_token } = cookies || {};

    useEffect(() => {
        const loadApi = async () => {
            const { data } = await ApiSend({
                // URL 넘어온 값 추가
                url: `${searchOrigin}/search/common/module/getAkc.jsp?kwd=${encodedKeyword}`,
                params: {
                    screen: 'CSSD0100',
                    os: 'CSOD0900',
                    network: 'CSND0900',
                    userId,
                    category: '!SHORTCLIP',
                    type: 'both',
                    speed: 300,
                    sort1: 'no',
                    sort2: 'no',
                    sort3: 'no',
                    fixed: 'Y',
                },
                headers: {
                    Authorization: `Bearer ${_tving_token}`,
                },
            });

            const { abtest_group: abtestGroup, sch_collection: schCollection, sch_model: schModel, sch_rec_model: schRecModel } = data.akcRsb || {};
            const impressionSchSr = data.akcRsb?.dataList?.map((rsbData) => rsbData.sch_sr)?.join('');
            const lists =
                data.akcRsb?.dataList?.map(
                    ({
                        amt,
                        bill_tag,
                        broad_dt,
                        broad_end_dt,
                        cate_cd,
                        cate_nm,
                        ch_cd,
                        ch_nm,
                        epi_cd,
                        epi_nm,
                        frequency,
                        gpa,
                        gubun,
                        key_value,
                        kwauto,
                        mast_cd,
                        mast_nm,
                        pack_yn,
                        free_yn,
                        payfree_yn,
                        paramount_yn,
                        runtime_mi,
                        score,
                        tving_exclusive_yn,
                        tving_original_yn,
                        web_url,
                        web_url3,
                        web_url4,
                        targetage,
                        grade_cd,
                        sch_sr,
                    }) => {
                        return {
                            amt,
                            billingTag: bill_tag,
                            broadDt: broad_dt,
                            broadEndDt: broad_end_dt,
                            cateCd: cate_cd,
                            cateName: cate_nm,
                            chCd: ch_cd,
                            chName: ch_nm,
                            epiCd: epi_cd,
                            epiName: epi_nm,
                            frequency,
                            gpa,
                            gubun,
                            keyValue: key_value,
                            kwauto,
                            programCode: mast_cd,
                            code: mast_cd,
                            type:
                                mast_cd.slice(0, 1) === 'C'
                                    ? 'live'
                                    : mast_cd.slice(0, 1) === 'E' || mast_cd.slice(0, 1) === 'P'
                                    ? 'episode'
                                    : 'movie',
                            title: mast_nm,
                            isPack: pack_yn === 'Y',
                            isFree: free_yn === 'Y',
                            isPayfree: payfree_yn === 'Y',
                            isParamount: paramount_yn === 'Y',
                            runtime_mi,
                            score,
                            isExclusive: tving_exclusive_yn === 'Y',
                            isOriginal: tving_original_yn === 'Y',
                            gradeCode: grade_cd,
                            ageGrade: targetage || (grade_cd === 'CMMG0400' && '18'),
                            thumbnail: imgOrigin + web_url,
                            webUrl3: web_url3,
                            webUrl4: web_url4,
                            abtestGroup,
                            schCollection,
                            schModel,
                            schRecModel,
                            impressionSchSr,
                            clickSchSr: sch_sr,
                        };
                    },
                ) || [];

            setData({
                lists: lists.slice(0, 7),
                textLists: lists.slice(3, 10),
            });
            const titleList = lists.map((item) => {
                return item.title;
            });
            // 자동완성 노출 시 호출되는 경우에만 FBA 로깅
            if (isAutoComplete) {
                const {
                    abtest_group: abtestGroup,
                    sch_collection: schCollection,
                    sch_model: schModel,
                    sch_rec_model: schRecModel,
                } = data.akcRsb || {};
                const impressionSchSr = data.akcRsb?.dataList
                    ?.slice(0, 10)
                    ?.map((rsbData) => rsbData.sch_sr)
                    ?.join('');
                logFbaSearchAutoComplete({
                    profileNo,
                    keyword,
                    resultCount: data.akcRsb?.count || 0,
                    keywords: titleList.slice(0, 10),
                    abtestGroup,
                    schCollection,
                    schModel,
                    schRecModel,
                    impressionSchSr,
                });
            }
        };
        if (didMount.current && !isLoadingUserInfo) {
            void loadApi();
        } else {
            didMount.current = true;
        }
    }, [keyword]);

    return {
        data,
    };
};

export const useSearchRpk = ({ isNoResult = false } = {}) => {
    const [cookies] = useCookies(['_tving_token']);
    const { _tving_token } = cookies || {};

    const [{ isLoading: isLoadingUserInfo = true, userId }] = useUserInfo();

    // 같은 API지만 SWR Key 분리를 위해 주소에 queryString 추가.
    const baseUrl = `${searchOrigin}/search/common/module/getRpk.jsp`;
    const queryString = isNoResult ? `?isNoResult=true` : '';
    const url = `${baseUrl}${queryString}`;

    return useAbortSWR(
        [!isLoadingUserInfo ? url : null],
        {
            params: { userId },
            headers: {
                Authorization: `Bearer ${_tving_token}`,
            },
            // adapter: jsonpAdapter,
            // callbackParamName: 'jsoncallback',
            // withCredentials: true,
        },
        {},
        (data) => {
            const { abtest_group: abtestGroup, sch_collection: schCollection, sch_model: schModel, sch_rec_model: schRecModel } = data.rpkRsb || {};
            const impressionSchSr = data.rpkRsb?.dataList?.map((rsbData) => rsbData.sch_sr)?.join('');
            const lists = data.rpkRsb?.dataList?.map(({ kwd, growth_rate, inst_dt, new_yn, ranking, sch_sr }) => {
                return {
                    title: kwd,
                    insertDate: moment(`${inst_dt}`, 'YYYYMMDDHHmmss').format('YYYY.MM.DD a hh:mm'),
                    isNew: new_yn === 'Y',
                    ranking,
                    growthRate: growth_rate,
                    abtestGroup,
                    schCollection,
                    schModel,
                    schRecModel,
                    impressionSchSr,
                    clickSchSr: sch_sr,
                };
            });
            return {
                lists,
                insertDate: lists[0]?.insertDate,
            };
        },
    );
};

// 검색결과에서 콘텐츠 클릭 시 검색어 순위 반영을 위해 호출하는 feedback API
export const searchClickFeedback = async ({ mediaCode, title = '', type = 'Program', userId = '' }) => {
    if (mediaCode === '') {
        return false;
    }
    await ApiSend({
        url: `${searchOrigin}/feedback`,
        method: 'post',
        headers: {
            Authorization: `Bearer ${getCookie('_tving_token')}`,
        },
        data: {
            content_id: mediaCode,
            content_title: title,
            content_type: type,
            userId,
        },
    });
    return true;
};
