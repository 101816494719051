import { type Page } from '@playwright/test';

import { PLAYWRIGHT_SELECTORS } from '../constant/attributes';

/**
 * App의 동작이 완료되었는지 확인합니다. (예: React App에서 Hydration이 완료되었는지 확인)
 */
export const waitForAppReady = async (page: Page) => {
  try {
    await page.waitForSelector(PLAYWRIGHT_SELECTORS.APP_READY, {
      state: 'attached',
    });
  } catch (error) {
    throw new Error('App의 동작이 완료되지 않았습니다. 테스트가 필요한 웹앱에서 `@tving/playwright-utils` 구성이 적용되었는지 확인해 주세요.');
  }
};
