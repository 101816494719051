export interface SendbirdInfo {
    appId: string;
}

export const SENDBIRD_INFO: SendbirdInfo = {
    appId: process.env.NEXT_PUBLIC_SENDBIRD_APP_ID as string,
};

export const CHAT_API_URLS = {
    // BFF 에서 HOME MAIN 만 예외적으로 받기 때문에 예외 처리
    USER_TOKEN: `/user-token` as string,
    USER_TOKEN_REFRESH: `/user-token/renew` as string,
};
