import KboMyTeamSettingItem from '@components/kbo/myteam/setting/KboMyteamSettingItem';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import { KboMyTeamItem } from '@typings/kbo/myTeam';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

interface KboMySettingItemsProps {
    pickedTeam: KboMyTeamItem;
    setPickedTeam: Dispatch<SetStateAction<KboMyTeamItem>>;
    myTeamsClass: string;
    kboMyTeams: KboMyTeamItem[];
}
const KboMyteamSettingItems = ({ pickedTeam, setPickedTeam, myTeamsClass, kboMyTeams }: KboMySettingItemsProps) => {
    const isMountedRef = useRef(false);
    const scrollRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const scroll = scrollRef.current;
        if (!scroll) {
            return;
        }
        const pickedTeamItem: HTMLLIElement | null = scroll.querySelector(`.kbo-myteam-item-${pickedTeam?.code}`);
        if (!pickedTeamItem) {
            return;
        }
        const { clientHeight: scrollClientHeight } = scroll;
        const { clientHeight: itemClientHeight } = pickedTeamItem;

        if (!isMountedRef.current) {
            scroll.scrollTop = pickedTeamItem.offsetTop - itemClientHeight / 2;
            isMountedRef.current = true;
            return;
        }

        scroll.scrollTo({
            top: pickedTeamItem.offsetTop - (scrollClientHeight - itemClientHeight) / 2,
            behavior: 'smooth',
        });
    }, [pickedTeam]);

    return (
        <section className={tempCnx(myTeamsClass)} ref={scrollRef}>
            {kboMyTeams?.map((kboMyTeam) => {
                const { imageUrl, name, doMatchPush, doHighlightPush } = kboMyTeam;
                return (
                    <KboMyTeamSettingItem
                        key={kboMyTeam.code}
                        imageUrl={imageUrl}
                        name={name}
                        doMatchPush={doMatchPush}
                        doHighlightPush={doHighlightPush}
                        isSelected={kboMyTeam.code === pickedTeam?.code}
                        currentTeam={kboMyTeam}
                        setPickedTeam={setPickedTeam}
                    />
                );
            })}
        </section>
    );
};
export default KboMyteamSettingItems;
