import { CONTENT_TYPE } from '../../constants/commonConstants';

/**
 * mediaCode string 을 이용해 콘텐츠 타입을 획득합니다.
 * @param mediaCode
 */

export const getContentTypeFromMediaCode = (mediaCode: string): CONTENT_TYPE | null => {
  const firstCharacter = mediaCode?.charAt(0);

  switch (firstCharacter) {
    case 'A':
      return CONTENT_TYPE.CHARACTER;
    case 'B':
      return CONTENT_TYPE.CREATOR;
    case 'C':
      return CONTENT_TYPE.LIVE;
    case 'M':
      return CONTENT_TYPE.MOVIE;
    case 'P':
      return CONTENT_TYPE.PROGRAM;
    case 'E':
      return CONTENT_TYPE.EPISODE;
    case 'R':
      return CONTENT_TYPE.MORE;
    case 'S':
      return CONTENT_TYPE.CLIP;
    default:
      return null;
  }
};
