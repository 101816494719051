import { useCallback, useRef } from 'react';

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

function useFreshCallback<Arguments extends unknown[], R>(callback: (...args: Arguments) => R): (...args: Arguments) => R {
  const callbackRef = useRef<typeof callback>(callback);

  useIsomorphicLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback((...args: Arguments): R => {
    return callbackRef.current?.(...args);
  }, []);
}

export default useFreshCallback;
