import { type OverlayKitIntegrationConfig, type OverlayKitIntegrationOptions } from '../types';

const DEFAULT_CONFIG: Pick<OverlayKitIntegrationConfig, 'destroyOnCallerUnmount' | 'destroyOnRouteChange'> = {
  destroyOnCallerUnmount: false,
  destroyOnRouteChange: 'pathname',
};

const generateMergedConfig = (config?: OverlayKitIntegrationOptions): Omit<OverlayKitIntegrationConfig, 'routerSnapshot'> => {
  const destroyOnCallerUnmount = config?.destroyOnCallerUnmount ?? DEFAULT_CONFIG.destroyOnCallerUnmount;
  const destroyOnRouteChange = config?.destroyOnRouteChange ?? DEFAULT_CONFIG.destroyOnRouteChange;

  return {
    ...config,
    destroyOnCallerUnmount,
    destroyOnRouteChange,
  };
};

export default generateMergedConfig;
