import { RESERVED_PARAMETER_KEYS } from './constant';
import type { CustomParameter, AfParameters } from './types';

/**
 * 기본 커스텀 파라미터와 사용자 정의 커스텀 파라미터를 병합합니다.
 * - 커스텀 파라미터 중 기본값이 필요한 경우 초기 설정에 포함될 수 있습니다.
 * - 병합 과정에서 defaultCustomParameter 내에 있는 커스텀 파라미터는 제거하면 안됩니다.
 */
export const mergeCustomParameters = (defaultCustomParams: CustomParameter[], customParams: CustomParameter[]): CustomParameter[] => {
  const defaultCustomKeys = defaultCustomParams.reduce<Record<string, boolean>>(
    (acc, defaultCustomParam) => ({
      ...acc,
      [defaultCustomParam.paramKey]: true,
    }),
    {},
  );

  return [...defaultCustomParams, ...customParams.filter((customParam) => defaultCustomKeys[customParam.paramKey] !== true)];
};

/**
 * Custom Parameter에서 기본 AfParameter의 Param Key를 사용하지 않도록 필터링합니다.
 */
export const createFilterDefaultAfParameters = (afParameters: AfParameters) => {
  const paramKeys = Object.values(afParameters).reduce<string[]>((acc, paramInfo) => {
    if (paramInfo === undefined || paramInfo === null || Array.isArray(paramInfo)) {
      return acc;
    }

    if (typeof paramInfo === 'string') {
      return [...acc, paramInfo];
    }

    return [...acc, ...paramInfo.keys];
  }, []);

  const uniqueParamKeys = new Set([...paramKeys, ...RESERVED_PARAMETER_KEYS]);
  return (paramKey: string) => uniqueParamKeys.has(paramKey) === false;
};

const createCustomParameter = (paramKey: string): CustomParameter => {
  return {
    paramKey,
    keys: [paramKey],
  };
};

export const createCustomParameters = (paramKeys: string[]): CustomParameter[] => {
  return paramKeys.map((paramKey) => createCustomParameter(paramKey));
};

export const createCustomParametersFromUrl = (url: string): CustomParameter[] => {
  try {
    const urlSearchParams = new URLSearchParams(new URL(url).search);
    return createCustomParameters(Array.from(urlSearchParams.keys()));
  } catch (error) {
    throw new Error('URL에서 사용자 정의 파라미터를 생성하는 데 실패했습니다. URL을 확인해주세요.');
  }
};

const removeTrailingSlashes = (str: string) => {
  return str.replace(/\/+$/, '');
};

export const modifyCachedOneLinkURL = (originURL: string, targetURL: string, afParameters: AfParameters) => {
  const deepLinkValueKeys = afParameters.deepLinkValue?.keys || [];
  const afDpKeys = afParameters?.afCustom?.find((customParameter) => customParameter.paramKey === 'af_dp')?.keys || [];

  // 현재 브라우저 URL의 파라미터를 가져옵니다.
  const currentURLParams = new URLSearchParams(new URL(originURL).search);
  let currentDeepLinkValue: string | null = null;
  for (let i = 0; i < deepLinkValueKeys.length; i += 1) {
    const deepLinkValue = currentURLParams.get(deepLinkValueKeys[i]);
    if (deepLinkValue) {
      currentDeepLinkValue = deepLinkValue;
      break;
    }
  }

  let currentAfDp: string | null = null;
  for (let i = 0; i < afDpKeys.length; i += 1) {
    const afDp = currentURLParams.get(afDpKeys[i]);
    if (afDp) {
      currentAfDp = afDp;
      break;
    }
  }

  // OneLink URL을 가져옵니다.
  const oneLinkURL = new URL(targetURL);

  // 기본값을 가져옵니다.
  const defaultDeepLinkValue = afParameters.deepLinkValue?.defaultValue || '';
  const defaultAfDp = afParameters?.afCustom?.find((customParameter) => customParameter.paramKey === 'af_dp')?.defaultValue || '';

  // OneLink URL의 파라미터를 수정합니다. (캐싱된 값을 사용하지 않도록~)
  const modifiedOneLinkURLParams = new URLSearchParams(oneLinkURL.search);
  modifiedOneLinkURLParams.set('deep_link_value', currentDeepLinkValue || decodeURIComponent(defaultDeepLinkValue));
  modifiedOneLinkURLParams.set('af_dp', currentAfDp || decodeURIComponent(defaultAfDp));

  const modifiedOneLinkURL = `${oneLinkURL.origin}${removeTrailingSlashes(oneLinkURL.pathname)}?${modifiedOneLinkURLParams.toString()}`;
  return modifiedOneLinkURL;
};
