import { create } from 'zustand';

type State = {
    isOnMyTeamModal: boolean;
    setIsOnMyTeamModal: (boolean) => void;
};
const useKboMyTeamModalStore = create<State>()((set) => ({
    isOnMyTeamModal: false,
    setIsOnMyTeamModal: (isOnMyTeamModal) => set({ isOnMyTeamModal }),
}));
export default useKboMyTeamModalStore;
