import ranking1 from '../images/ranking/png/ranking_1.png';
import ranking10 from '../images/ranking/png/ranking_10.png';
import ranking11 from '../images/ranking/png/ranking_11.png';
import ranking12 from '../images/ranking/png/ranking_12.png';
import ranking13 from '../images/ranking/png/ranking_13.png';
import ranking14 from '../images/ranking/png/ranking_14.png';
import ranking15 from '../images/ranking/png/ranking_15.png';
import ranking16 from '../images/ranking/png/ranking_16.png';
import ranking17 from '../images/ranking/png/ranking_17.png';
import ranking18 from '../images/ranking/png/ranking_18.png';
import ranking19 from '../images/ranking/png/ranking_19.png';
import ranking2 from '../images/ranking/png/ranking_2.png';
import ranking20 from '../images/ranking/png/ranking_20.png';
import ranking3 from '../images/ranking/png/ranking_3.png';
import ranking4 from '../images/ranking/png/ranking_4.png';
import ranking5 from '../images/ranking/png/ranking_5.png';
import ranking6 from '../images/ranking/png/ranking_6.png';
import ranking7 from '../images/ranking/png/ranking_7.png';
import ranking8 from '../images/ranking/png/ranking_8.png';
import ranking9 from '../images/ranking/png/ranking_9.png';
import ranking1Svg from '../images/ranking/svg/ranking_1.svg';
import ranking10Svg from '../images/ranking/svg/ranking_10.svg';
import ranking11Svg from '../images/ranking/svg/ranking_11.svg';
import ranking12Svg from '../images/ranking/svg/ranking_12.svg';
import ranking13Svg from '../images/ranking/svg/ranking_13.svg';
import ranking14Svg from '../images/ranking/svg/ranking_14.svg';
import ranking15Svg from '../images/ranking/svg/ranking_15.svg';
import ranking16Svg from '../images/ranking/svg/ranking_16.svg';
import ranking17Svg from '../images/ranking/svg/ranking_17.svg';
import ranking18Svg from '../images/ranking/svg/ranking_18.svg';
import ranking19Svg from '../images/ranking/svg/ranking_19.svg';
import ranking2Svg from '../images/ranking/svg/ranking_2.svg';
import ranking20Svg from '../images/ranking/svg/ranking_20.svg';
import ranking3Svg from '../images/ranking/svg/ranking_3.svg';
import ranking4Svg from '../images/ranking/svg/ranking_4.svg';
import ranking5Svg from '../images/ranking/svg/ranking_5.svg';
import ranking6Svg from '../images/ranking/svg/ranking_6.svg';
import ranking7Svg from '../images/ranking/svg/ranking_7.svg';
import ranking8Svg from '../images/ranking/svg/ranking_8.svg';
import ranking9Svg from '../images/ranking/svg/ranking_9.svg';

/**
 * Ranking 이미지 Type
 */
export interface RANKING_IMAGE_TYPE {
  PNG: string;
  SVG: string;
}

/**
 * Ranking Type
 */
export const enum RANKING_TYPE {
  RANKING1 = 'RANKING1',
  RANKING2 = 'RANKING2',
  RANKING3 = 'RANKING3',
  RANKING4 = 'RANKING4',
  RANKING5 = 'RANKING5',
  RANKING6 = 'RANKING6',
  RANKING7 = 'RANKING7',
  RANKING8 = 'RANKING8',
  RANKING9 = 'RANKING9',
  RANKING10 = 'RANKING10',
  RANKING11 = 'RANKING11',
  RANKING12 = 'RANKING12',
  RANKING13 = 'RANKING13',
  RANKING14 = 'RANKING14',
  RANKING15 = 'RANKING15',
  RANKING16 = 'RANKING16',
  RANKING17 = 'RANKING17',
  RANKING18 = 'RANKING18',
  RANKING19 = 'RANKING19',
  RANKING20 = 'RANKING20',
}

export const RANKING_IMAGE: Record<RANKING_TYPE, RANKING_IMAGE_TYPE> = {
  RANKING1: { PNG: ranking1, SVG: ranking1Svg },
  RANKING2: { PNG: ranking2, SVG: ranking2Svg },
  RANKING3: { PNG: ranking3, SVG: ranking3Svg },
  RANKING4: { PNG: ranking4, SVG: ranking4Svg },
  RANKING5: { PNG: ranking5, SVG: ranking5Svg },
  RANKING6: { PNG: ranking6, SVG: ranking6Svg },
  RANKING7: { PNG: ranking7, SVG: ranking7Svg },
  RANKING8: { PNG: ranking8, SVG: ranking8Svg },
  RANKING9: { PNG: ranking9, SVG: ranking9Svg },
  RANKING10: { PNG: ranking10, SVG: ranking10Svg },
  RANKING11: { PNG: ranking11, SVG: ranking11Svg },
  RANKING12: { PNG: ranking12, SVG: ranking12Svg },
  RANKING13: { PNG: ranking13, SVG: ranking13Svg },
  RANKING14: { PNG: ranking14, SVG: ranking14Svg },
  RANKING15: { PNG: ranking15, SVG: ranking15Svg },
  RANKING16: { PNG: ranking16, SVG: ranking16Svg },
  RANKING17: { PNG: ranking17, SVG: ranking17Svg },
  RANKING18: { PNG: ranking18, SVG: ranking18Svg },
  RANKING19: { PNG: ranking19, SVG: ranking19Svg },
  RANKING20: { PNG: ranking20, SVG: ranking20Svg },
};
