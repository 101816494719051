// Braze 초기화
export const brazeEvent_contents = ({ braze = null, brazeEvent, type, genre1, genre2, contents, episode_no, gate }) => {
    if (!braze) {
        return;
    }
    brazeEvent({
        eventName: 'enter_contentsinfo',
        properties: {
            category: type === 'movie' ? '영화' : 'TV프로그램',
            genre1,
            genre2,
            contents,
            episode_no,
            gate,
        },
    });
};
