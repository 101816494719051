const transformRedirectUrlPath = ({ url }: { url: string }): string => {
    const currentUrl = new URL(url);
    const { pathname } = currentUrl;

    // 경로 변환 규칙 정의
    const pathRules: { [key: string]: string } = {
        '/kbo/more/schedule/playlist': '/kbo/home/redirect/more/schedule/playlist',
        '/kbo/more/video': '/kbo/home/redirect/more/video',
        '/kbo/more/curation': '/kbo/home/redirect/more/curation',
    };

    for (const key in pathRules) {
        if (pathname.startsWith(key)) {
            return pathname.replace(key, pathRules[key]);
        }
    }

    return pathname; // 변환되지 않은 원본 경로 반환
};

export default transformRedirectUrlPath;
