/**
 * const vodLiveItemProps = {
 *         className: `item__thumb ${ratioClassName} mb-[0.667rem]`,
 *         poster: {
 *             src: '//stillshot.tving.com/thumbnail/C01581_1_640x360.jpg/dims/resize/400',
 *             // src: thumbnail,
 *             alt: 'alt value',
 *         },
 *         labels: {
 *             type: 'SVG',
 *             free: {
 *                 className: 'top-[0.25rem] right-[0.25rem]',
 *                 isShow: true,
 *             },
 *         },
 *         text: {
 *             className: 'leading-[21.72px]',
 *             items: [
 *                 {
 *                     tag: 'h3',
 *                     title: 'tvn',
 *                     className: 'text-[15px] font-bold',
 *                 },
 *                 {
 *                     tag: 'h3',
 *                     title: '서진이네 1화',
 *                     className: 'text-[15px]',
 *                 },
 *                 {
 *                     tag: 'h3',
 *                     title: '10.0%',
 *                     className: 'text-[15px]',
 *                 },
 *             ],
 *         },
 *         ranking: {
 *             type: 'SVG',
 *             number: 1,
 *             className: 'w-[32px] h-[48px] mr-[0.667rem]',
 *         },
 *     };
 */

import Poster, { PosterProps } from '../../atom/poster/Poster';
import ProgressBar from '../../atom/progressBar/ProgressBar';
import Ranking, { RankingProps } from '../../atom/ranking/Ranking';
import Text, { TextProps } from '../../atom/text/Text';
import Labels, { LabelsProps } from '../labels/Labels';

type liveRankingItemProps = {
  className?: string;
  poster: PosterProps;
  posterText: TextProps<React.ElementType>;
  labels: LabelsProps;
  contentWrapperClassName?: string;
  textRankingWrapperClassName?: string;
  textWrapperClassName?: string;
  text: {
    className: string;
    items: [
      {
        tag?: React.ElementType;
        title: string;
        className?: string;
        subTag?: React.ElementType;
        subTitle?: string;
        subClassName?: string;
      },
    ];
  };
  progressBar: {
    percent: number;
  };
  ranking: RankingProps;
  tabIndex?: number;
};

const LiveRankingItem = ({
  className,
  poster,
  posterText,
  text,
  tabIndex,
  labels,
  ranking,
  progressBar,
  textRankingWrapperClassName,
  textWrapperClassName,
  contentWrapperClassName,
}: liveRankingItemProps) => {
  return (
    <>
      <div className={`live-ranking-item-wrapper  ${className}`} tabIndex={tabIndex}>
        {posterText && <Text {...posterText} />}
        <Poster {...poster} />
        <ProgressBar {...progressBar} />
        <Labels {...labels} />
      </div>
      <div className={`live-ranking-content-wrapper ${contentWrapperClassName} w-full`}>
        <div className={`${textRankingWrapperClassName}`}>
          <Ranking {...ranking} />
          <div className={`${textWrapperClassName}`}>
            {text.items.map((text) => {
              return <Text tag={text.tag} title={text.title} className={text.className} tabIndex={tabIndex} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveRankingItem;
