import React, { ChangeEventHandler, KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import IconPaperAirplane from '@public/chat/icon_paperplane.svg';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
import { CHAT_ERRORS, useChatConfig } from '@tving/utils/src/contexts/ChatContext';
import HeartInteraction from '@components/chat/Interactions/HeartInteraction';
import { isWebview, isMobile } from '@utils/device/device-detect';
import useCrossPlatform from '@utils/crossPlatform/react/useCrossPlatform';
import Scrollbars from 'react-custom-scrollbars-2';
import { useRouter } from 'next/router';
import useDeviceOrientation from '@tving/utils/src/hooks/common/useDeviceOrientation';
import usePcPlayer from '@store/usePcPlayer';
import { shallow } from 'zustand/shallow';
import { SendMessageHandler } from '@tving/utils/src/hooks/common/chat/useChannelConnectionWithSendbird';

type Props = {
    isInputFocus: boolean;
    sendMessage: SendMessageHandler;
    increaseMetaCounterHandler: (key: string, value?: number) => Promise<void>;
    messageContainerRef: React.RefObject<HTMLDivElement>;
    scrollbarRef: React.RefObject<Scrollbars>;
};

const CHAT_FORM_CONFIG = {
    MAX_MESSAGE_LINE: 2,
};

const ChatFormBox = ({ isInputFocus, sendMessage, increaseMetaCounterHandler, messageContainerRef, scrollbarRef }: Props) => {
    const router = useRouter();
    const [inputValue, setInputValue] = useState<string>('');
    const [canSending, setCanSending] = useState<boolean>(false);
    // const [isInputFocus, setIsInputFocus] = useState<boolean>(false);
    const {
        metaCounters,
        isLoading,
        error,
        isFrozen,
        isInputMultiline,

        setError,
        setIsInputFocus,
        setIsInputFocusDelay,
        setIsInputMultiline,
        isTextareaFocus,
        textareaRef,
    } = useChatConfig();
    // const textareaRef = useRef<HTMLTextAreaElement>(null);
    const trickTextInputRef = useRef<HTMLInputElement>(null);
    const crossPlatformController = useCrossPlatform();
    const { isFullScreen: isFullscreen } = usePcPlayer(
        (state) => ({
            isFullScreen: state.isFullScreen,
            setFullScreen: state.setFullScreen,
        }),
        shallow,
    );
    const { isLandscape } = useDeviceOrientation();

    const changeInputHandler: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        if (!textareaRef.current) {
            return;
        }

        const { value } = e.target;
        const escapeValue = value.replace(/\n/g, '');

        setInputValue(escapeValue);
        setCanSending(!!escapeValue.trim());
    };

    const sendMessageHandler = (message: string) => {
        if (!message.trim()) {
            setError(CHAT_ERRORS.EMPTY_MESSAGE);
            return;
        }

        sendMessage(message, {
            onFailed: () => {
                if (isWebview) {
                    textareaRef.current?.blur();
                }
            },
        });
        // scrollToBottomOfContainer(messageContainerRef.current);
        // inputContainerRef.current.value = '';
        scrollbarRef.current?.scrollToBottom();
        setTimeout(() => {
            scrollbarRef.current?.scrollToBottom();
        }, 50);
        setInputValue('');
        setCanSending(false);
        trickTextInputRef.current?.focus();
    };

    const sendMessageButtonClickHandler = (event) => {
        // if (!inputContainerRef?.current?.value) {
        if (!inputValue) {
            return;
        }

        // sendMessageHandler(inputContainerRef.current.value);
        event.preventDefault();
        sendMessageHandler(inputValue);
    };

    const sendMessageInputKeyDownHandler: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
        // event.stopPropagation();
        const { isComposing } = event.nativeEvent;
        const isEnterKey = event.key === 'Enter';

        if (isComposing) {
            return;
        }

        if (!isEnterKey) {
            return;
        }

        event.preventDefault();
        sendMessageHandler(inputValue);
    };

    const onTextareaBoxClickHandler = () => {
        textareaRef.current?.focus();
    };

    // 포커스 이동 시 스크롤을 최하단으로 이동
    const scrollToBottomOfContainer = () => {
        if (!isWebview) {
            return;
        }
        try {
            const nextId = document.getElementById('__next');
            if (nextId) {
                nextId.scrollTop = nextId.scrollHeight;
            }
            document.documentElement.scrollTop = document.documentElement.scrollHeight;
        } catch (e) {
            console.error('scrollToBottomOfContainer', e);
        }
    };

    const handleTextareaFocus = () => {
        setIsInputFocus(true);
        setIsInputFocusDelay(true);
        scrollToBottomOfContainer();
        crossPlatformController.didChangeInputFocus(true);
        isTextareaFocus.current = true;
    };

    const handleTextareaBlur = () => {
        setIsInputFocus(false);
        setTimeout(() => {
            setIsInputFocusDelay(false);
        }, 1000);
        crossPlatformController.didChangeInputFocus(false);
        isTextareaFocus.current = false;

        // setTimeout(() => {
        //     isTextareaFocus.current = false;
        // }, 100);
    };

    const handleTouchMoveBoxForBlur = useCallback(() => {
        if (textareaRef.current) {
            textareaRef.current.blur();
        }
    }, [textareaRef, textareaRef.current]);

    useEffect(() => {
        if (!textareaRef.current) {
            return;
        }

        textareaRef.current.style.height = ''; // Reset height to auto to calculate the correct scrollHeight

        const { scrollHeight, clientHeight, offsetHeight } = textareaRef.current;
        const lineHeight = parseInt(window.getComputedStyle(textareaRef.current).lineHeight, 10);
        const maxHeight = lineHeight * CHAT_FORM_CONFIG.MAX_MESSAGE_LINE;

        if (inputValue !== '') {
            textareaRef.current.style.height = 'auto'; // Reset height to auto to calculate the correct scrollHeight
            textareaRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
        }

        const isMultiline = scrollHeight >= maxHeight;
        setIsInputMultiline(isMultiline);
    }, [inputValue, setIsInputMultiline]);

    useEffect(() => {
        if (isWebview && isFullscreen && textareaRef.current) {
            textareaRef.current.blur();
        }
    }, [isFullscreen, textareaRef]);

    useEffect(() => {
        const targetElement = document.documentElement; // <html> 요소

        const updatePlayerWidth = () => {
            const targetElement = document.documentElement; // <html> 요소
            const width = getComputedStyle(targetElement).getPropertyValue('--kbo-player-width');
            const { innerWidth } = window;

            if (isLandscape && Math.abs(parseInt(width, 10) - innerWidth) <= 1) {
                textareaRef.current?.blur();
            }
        };

        // 초기값 설정
        updatePlayerWidth();

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'style') {
                    updatePlayerWidth();
                }
            });
        });

        // HTML 요소의 style 속성 변경 감지
        observer.observe(targetElement, {
            attributes: true,
            attributeFilter: ['style'],
        });

        return () => observer.disconnect();
    }, [isLandscape, textareaRef]);

    const isInActive = !isLoading && !isFrozen;
    const isSubmitDisabled = !isInActive || !canSending;

    const isKbo = router.pathname.includes('/kbo/contents');
    const isSports = router.pathname.includes('/contents/sports');

    return (
        <div
            className={tempCnx(
                'flex items-center shrink-0',
                'right-0 bottom-0 w-full h-auto p-[0.667rem_1.333rem_0.667rem]',
                'border-0 border-t border-[#404040] border-solid bg-black',
                'transition-height',
                isWebview ? 'fixed z-50 landscape:max-w-[calc(100vw_-_var(--kbo-player-width))]' : 'relative left-0',
                isKbo || isSports ? 'fixed landscape:relative' : '',
                isInputFocus ? 'pb-[0.667rem]' : 'pb-[calc(0.667rem_+_var(--safe-area-inset-bottom)_+_var(--content-inset-bottom))]',
                isFullscreen ? 'relative' : '',
                // isInputFocus ? 'pb-[0.667rem]' : 'pb-[calc(0.667rem_+_env(safe-area-inset-bottom))]',
            )}
        >
            <div
                className={tempCnx(
                    'flex items-center grow relative ',
                    'w-full min-h-[3rem] pl-[1.333rem] pr-[4.667rem] border border-solid border-[#262626] rounded-[0.333rem]',
                    'bg-[#262626] text-[#fff] leading-normal',
                    'transition-height duration-500 ease-in-out',
                    'placeholder:text-[#808080]',
                    isInputMultiline && inputValue !== '' ? 'h-[4.333rem]' : 'h-[3rem]',
                    isInActive ? 'hover:border-solid hover:border-[#4D4D4D] placeholder:text-[#808080] ' : 'border-none bg-[#0d0d0d] text-[#4d4d4d]',
                    isInputFocus ? 'border-solid border-[#808080] hover:border-solid hover:border-[#808080]' : '',
                )}
                onClick={onTextareaBoxClickHandler}
            >
                <textarea
                    className={tempCnx(
                        'peer border-none w-full text-[1.167rem] bg-transparent',
                        'leading-normal resize-none overflow-hidden',
                        'placeholder:text-[#808080]',
                    )}
                    value={inputValue}
                    placeholder={isInActive ? '티빙톡에 참여해 보세요' : '지금은 티빙톡을 이용하실 수 없습니다.'}
                    maxLength={100}
                    rows={1}
                    disabled={!isInActive}
                    aria-disabled={!isInActive}
                    ref={textareaRef}
                    onChange={changeInputHandler}
                    onKeyDown={sendMessageInputKeyDownHandler}
                    onFocus={handleTextareaFocus}
                    onBlur={handleTextareaBlur}
                />
                {/* 웹뷰에서 IME 노출 시 자식요소 클릭을 막기 위한 dimm 요소 */}
                {isWebview && (
                    <div
                        onTouchMove={handleTouchMoveBoxForBlur}
                        className={tempCnx('absolute bottom-[3rem] left-[-1.333rem] right-[-1.333rem] h-[100vh] hidden peer-focus:block ')}
                    />
                )}
                <input
                    className={tempCnx('absolute top-0 right-0 opacity-0 w-1 h-1 pointer-events-none')}
                    ref={trickTextInputRef}
                    onFocus={() => {
                        textareaRef.current?.focus();
                        // setTimeout(() => {
                        //     textareaRef.current?.focus();
                        // }, 500);
                    }}
                />
                <button
                    type="button"
                    aria-label="send_message"
                    className={tempCnx(
                        'flex-center absolute top-0 right-0 w-[4.667rem] h-full text-transparent',
                        'h-[calc(100%_+_var(--safe-area-inset-bottom))] pb-[var(--safe-area-inset-bottom)]',
                        isSubmitDisabled ? 'cursor-not-allowed' : '',
                    )}
                    onClick={sendMessageButtonClickHandler}
                >
                    <IconPaperAirplane
                        className={tempCnx('w-8 h-8 transition-all duration-500')}
                        fill={!isInActive ? '#4D4D4D' : canSending ? '#fff' : '#808080'}
                    />
                </button>
            </div>

            {!isFrozen && metaCounters?.HEART_COUNT !== undefined ? (
                <HeartInteraction metaCounts={metaCounters} increaseMetaCounterHandler={increaseMetaCounterHandler} isDisabled={isFrozen} />
            ) : null}
        </div>
    );
};

export default ChatFormBox;
