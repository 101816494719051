import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';
import type { Options } from '@sentry/types';
import { shouldIgnoreClientError } from './.sentry/helper/shouldIgnoreError';

// const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    enabled: true,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_STAGE || 'DEVELOPMENT',
    sampleRate: 0.1,
    tracesSampleRate: 0,
    integrations: [
        new BrowserTracing({
            tracingOrigins: ['localhost'],
        }),
    ],
    ignoreErrors: ['Request aborted'],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    initialScope: (scope) => {
        scope.setLevel(Sentry.Severity.Error);
        return scope;
    },
    beforeSend: (event, hint) => {
        const originalException = hint?.originalException;

        if (shouldIgnoreClientError(event, hint)) {
            return null;
        }

        if (originalException && typeof originalException !== 'string') {
            const { name, message } = originalException || {};

            if (
                (name && (name.indexOf('AbortError') !== -1 || name.indexOf('NotAllowedError') !== -1)) ||
                (message && (message.indexOf('AbortError') !== -1 || message.indexOf('NotAllowedError') !== -1))
            ) {
                return null;
            }
        }

        return event;
    },
} as Options);
