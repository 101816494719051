// eslint-disable-next-line max-classes-per-file
type ErrorType = 'UNSUPPORTED_BROWSER' | 'UNKNOWN';
class ClipboardError extends Error {
  constructor(public type: ErrorType, message: string) {
    super(message);
    this.type = type;
  }
}
type SuccessResponse = {
  ok: true;
  data: {
    text: string;
    message: string;
  };
  error: null;
};
type ErrorResponse = {
  ok: false;
  data: null;
  error: ClipboardError;
};
type ClipboardResponse = SuccessResponse | ErrorResponse;

class Result {
  private constructor(private readonly response: ClipboardResponse) {}

  static ok(text: string, message = '텍스트가 클립보드에 복사되었습니다.'): Result {
    return new Result({ ok: true, data: { text, message }, error: null });
  }

  static error(type: ErrorType = 'UNKNOWN', message = '알 수 없는 오류가 발생했습니다.'): Result {
    return new Result({ ok: false, data: null, error: new ClipboardError(type, message) });
  }

  get result(): ClipboardResponse {
    return this.response;
  }
}

type CopyOptions = {
  successMessage?: string;
};

const copyUsingClipboardAPI = async (text: string): Promise<void> => {
  await navigator.clipboard.writeText(text);
};

const copyUsingExecCommand = (text: string): void => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

/**
 * 텍스트를 클립보드에 복사하는 함수
 *
 * @remarks
 * 이 함수는 다양한 브라우저 환경에서의 호환성을 고려하여 구현되었습니다.
 * 두 가지 복사 방식을 순차적으로 시도하여 최대한 많은 브라우저에서 동작하도록 합니다.
 *
 * 1. navigator.clipboard API:
 *    - 최신 브라우저에서 지원되는 안전하고 권장되는 방식
 *    - HTTPS 환경에서만 동작
 *    - 사용자 권한이 필요할 수 있음
 *
 * 2. document.execCommand('copy'):
 *    - 레거시 브라우저 지원을 위한 대체 방식
 *    - navigator.clipboard가 지원되지 않는 환경에서 사용
 *    - 일부 오래된 브라우저에서도 동작 가능
 *    - 보안상의 이유로 권장되지 않지만, 호환성을 위해 유지
 *
 * @param text - 클립보드에 복사할 텍스트
 * @param options - 복사 작업에 대한 추가 옵션
 * @param options.successMessage - 복사 성공 시 반환할 결과 메시지
 *
 * @returns 복사 작업의 결과를 나타내는 Result 객체
 */
const copyToClipboard = async (text: string, options: CopyOptions = {}): Promise<ClipboardResponse> => {
  const { successMessage } = options;

  const isClipboardApiSupported = navigator.clipboard;
  const isCopyCommandSupported: boolean = document.queryCommandSupported('copy');

  if (!isClipboardApiSupported && !isCopyCommandSupported) {
    return Result.error('UNSUPPORTED_BROWSER', '클립보드 복사 기능이 지원되지 않는 브라우저입니다.').result;
  }

  try {
    if (isClipboardApiSupported) {
      await copyUsingClipboardAPI(text);
    }

    if (isCopyCommandSupported) {
      copyUsingExecCommand(text);
    }

    return Result.ok(text, successMessage).result;
  } catch (e) {
    console.error('클립보드 복사 중 오류 발생:', e);
    if (e instanceof Error) {
      return Result.error('UNKNOWN', e.message).result;
    }
    return Result.error('UNKNOWN', '알 수 없는 오류가 발생했습니다.').result;
  }
};

export default copyToClipboard;
