import amplitude from 'amplitude-js';

type InitAmplitudeProps = {
  ApiKey: string;
  Options?: { platform: string };
};

const initAmplitude = ({ ApiKey, Options }: InitAmplitudeProps) => {
  const defaultOptions = { saveParamsReferrerOncePerSession: false, includeUtm: true };
  amplitude.getInstance().init(ApiKey, null, { ...defaultOptions, ...Options });
};

export default initAmplitude;
