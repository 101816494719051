import getScreenOsCodeDeviceParams from '@utils/kbo/getScreenOsCodeDeviceParams/getScreenOsCodeDeviceParams';
import httpGet from '@utils/common/httpGet';
import { KboHomeResponse } from '@typings/kbo';
import { KBO_API_URLS } from '@constants/kbo/url';
import { KBO_URL } from '@tving/utils/src/utils/common/apiConfig';

type DeviceType = {
    isMobileOrWebview: boolean;
    isTablet: boolean;
};

type fetchKboHomeType = {
    tab: string;
    deviceType: DeviceType;
    options: {
        [key: string]: string;
    };
};
const fetchKboHome = <B>({ tab, deviceType, options }: fetchKboHomeType) => {
    return httpGet<KboHomeResponse<B>>(KBO_URL + KBO_API_URLS.HOME(tab), {
        params: getScreenOsCodeDeviceParams({
            ...deviceType,
        }),
        headers: {
            Authorization: `Bearer ${options?.tvingToken}`,
            'Access-Token': options?.accessToken,
        },
    });
};
export default fetchKboHome;
