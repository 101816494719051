import dynamic from 'next/dynamic';
import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import { isDesktop, isMobile } from '@utils/device/device-detect';
import { useUserInfo, useUserRegionCode } from '@store/state';
import styles from './styles.module.scss';
import { useRouter } from 'next/router';
import ModalComp from '@components/Modal';
import ModalAgree from '@components/modal/ModalAgree';
import { openCouponRegister } from '@utils/common/BillApi';
import { useSportsConfig } from '@tving/utils/src/contexts/sports/SportsContext';
import NextNprogress from 'nextjs-progressbar';
import getSportsContentsExceptionalStyles from '@utils/sports/getSportsContentsExceptionalStyles';
import tempCnx from '@tving/ui/src/utils/tailwind/tempCnx';
// import useRipple from '@hooks/interaction/useRipple';

const BrazeInitPage = dynamic(
    () => {
        return import('@components/common/BrazeInit');
    },
    { ssr: false },
);

const SportsLayout = ({ children }) => {
    const router = useRouter();

    const { userRegionCode, device } = useSportsConfig();
    // useRipple({ endabled: device?.isMobileWebview });
    const [userInfo] = useUserInfo();
    const { push, pathname, query } = router;
    const { isMobileWeb, isMobileWebview: isWebview } = device || {};
    const { isLogin } = userInfo || {};
    const [, setUserRegionCode] = useUserRegionCode();

    useEffect(() => {
        void setUserRegionCode(userRegionCode); // CSR에서 userRegionCode 사용을 위한 상태관리
    }, [setUserRegionCode, userRegionCode]);

    useEffect(() => {
        Modal.setAppElement('#modal-root');

        // 쿠폰 열기
        const { action } = query || {};
        if (action === 'coupon') {
            void push(pathname, undefined, { shallow: true });

            ModalComp.dialog({
                title: '지금 쿠폰등록 하시겠습니까?',
                text: "나중에 등록 시 '프로필 > 쿠폰등록' 메뉴에서 등록 가능합니다.",
            }).then(({ isConfirmed }) => {
                if (isConfirmed) {
                    openCouponRegister(`?returnUrl=${encodeURIComponent(`${window.location.href}?action=coupon`)}`);
                }
            });
        }

        if (isMobile) {
            navigator.serviceWorker?.getRegistrations()?.then((registrations) => {
                // eslint-disable-next-line no-restricted-syntax
                for (const registration of registrations) {
                    if (registration.scope?.indexOf('tving.com') !== -1) {
                        registration.unregister();
                    }
                }
            });
        }

        // eslint-disable-next-line
        require('clientjs');
    }, [pathname, push, query.getUA]);

    const [mainClassName, setMainclassName] = useState<string[]>([]);
    useEffect(() => {
        if (isDesktop) {
            setMainclassName(['is-desktop']);
        }
    }, []);

    useEffect(() => {
        setIsMobileDevice(isMobile);
    }, []);

    const [isMobileDevice, setIsMobileDevice] = useState(false);

    return (
        <>
            {!isWebview ? <NextNprogress color="#ff153c" startPosition={0.3} stopDelayMs={200} height={3} showOnShallow /> : null}
            <div
                // className={`${styles['Layout__safe-area']} grow flex flex-col justify-stretch ${getKboContentsExceptionalStyles({
                className={tempCnx(
                    `${styles['Layout__safe-area']} grow flex flex-col justify-stretch ${getSportsContentsExceptionalStyles({
                        isMobile: isMobileWeb,
                        isWebview,
                    })} `,
                    mainClassName.join(' '),
                )}
            >
                {children}
                {isLogin && !isMobileDevice && <ModalAgree />}
                <div id="modal-root" />
            </div>
            <BrazeInitPage />
        </>
    );
};

export default SportsLayout;
