import Logo, { LogoProps } from '../../atom/logo/Logo';
import Poster, { PosterProps } from '../../atom/poster/Poster';
import Text, { TextProps } from '../../atom/text/Text';
import Labels, { LabelsProps } from '../labels/Labels';

interface originalItemProps {
  className?: string;
  poster: PosterProps;
  posterText: TextProps<React.ElementType>;
  labels: LabelsProps;
  tabIndex?: number;
  logo: LogoProps;
}

const OriginalItem = ({ className, poster, labels, posterText, tabIndex, logo }: originalItemProps) => {
  return (
    <div className={`item-wrapper originalItem-wrapper ${className}`} tabIndex={tabIndex}>
      {posterText && <Text {...posterText} />}
      <Poster {...poster} />
      <Labels {...labels} />
      <Logo {...logo} />
    </div>
  );
};

export default OriginalItem;
