import { AxiosRequestConfig } from 'axios';
import httpPost from '@tving/utils/src/utils/http/httpPost';
import { KBO_API_URLS } from '@constants/kbo/url';
import { GATEWAY_URL } from '@tving/utils/src/utils/common/apiConfig';

export interface KboMyTeamPutParameter {
    gamePush: string;
    highlightPush: string;
    teamCode: string;
    isMyTeam?: 'Y' | 'N' | boolean;
}
const fetchKboMyTeamPut = (requestParameter: KboMyTeamPutParameter, requestConfig: AxiosRequestConfig = {}) => {
    const { gamePush, highlightPush, teamCode, isMyTeam } = requestParameter;

    const data = {
        gamePush,
        highlightPush,
        teamCode,
        isMyTeam,
    };

    const filledRequestConfig = {
        ...requestConfig,
        data,
    };

    return httpPost(`${GATEWAY_URL}/kbo${KBO_API_URLS.MY_TEAM_PUT()}`, filledRequestConfig)
        .then((response) => {
            return response;
        })
        .catch((e) => {
            throw e;
        });
};
export default fetchKboMyTeamPut;
