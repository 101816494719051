import moment from 'moment';

import {
    ClipContent,
    EpisodeContent,
    MovieContent,
    NullableString,
    ProgramContent,
    StreamInfoBody,
    StreamInfoContent,
    TvingResponse,
    VodContent,
    VodSchedule,
} from '@tving/utils/src/apis/types';

import { proxyLive, proxyProgram } from '@store/apis/proxy';
import { ProxyStreamInfo } from '@store/apis/types';
import { imgOrigin } from '@utils/apiConfig';

import { SWRCustomResponse, useAbortSWR } from './plugins';

/**
 * stream/info
 */
export const streamInfoProxy = (data: TvingResponse<StreamInfoBody>, mediaCode: string): ProxyStreamInfo => {
    const streamInfoContent: StreamInfoContent = data?.body?.content;
    const target = streamInfoContent?.info || data;
    const resultCode = data?.body?.result?.code;
    const mediaType = target.schedule?.channel?.type || target.vod_type || streamInfoContent?.vod_type || '';
    let targetData: ProgramContent | MovieContent | EpisodeContent | undefined | null;

    const proxyStreamInfoResult: ProxyStreamInfo = data;

    // mediaType 별 구분해서 Proxy
    switch (mediaType) {
        case 'CPCS0100': // Live
        case 'CPCS0300': // TVING TV
        case 'CPCS0400': // TVING LIVE
            if (mediaType === 'CPCS0100') {
                proxyStreamInfoResult.type = 'live';
            }
            if (mediaType === 'CPCS0300') {
                proxyStreamInfoResult.type = 'tvingtv';
            }
            if (mediaType === 'CPCS0400') {
                proxyStreamInfoResult.type = 'tvinglive';
            }
            proxyStreamInfoResult.mediaType = 'live';
            targetData = target.schedule?.program;
            proxyStreamInfoResult.title = targetData?.name?.ko || target.schedule?.movie?.name?.ko;
            proxyStreamInfoResult.live = proxyLive({}, target as VodContent & VodSchedule);
            proxyStreamInfoResult.episode = proxyProgram({}, target.schedule as VodContent & VodSchedule);
            proxyStreamInfoResult.isAdult =
                (target.schedule?.episode?.adult_yn || target.schedule?.program?.adult_yn || target.schedule?.movie?.adult_yn) === 'Y';

            if (target.schedule?.episode?.frequency) {
                proxyStreamInfoResult.subtitle = `${target.schedule.episode.frequency}화`;
            } else if (target.schedule?.episode?.frequency === 0) {
                proxyStreamInfoResult.subtitle = `${target.schedule.episode.frequency}화`;
            }
            break;
        case 'CSMD0100': // Program
            proxyStreamInfoResult.mediaType = 'program';
            proxyStreamInfoResult.type = 'program';
            proxyStreamInfoResult.episode = proxyProgram({}, target as VodContent & VodSchedule);
            targetData = target.program;
            proxyStreamInfoResult.title = targetData?.name?.ko;
            proxyStreamInfoResult.isAdult = targetData?.adult_yn === 'Y';

            if (target.episode?.frequency) {
                proxyStreamInfoResult.subtitle = `${target.episode.frequency}화`;
            } else if (target.episode?.frequency === 0) {
                // proxyStreamInfoResult.subtitle = target.episode?.name?.ko;
                proxyStreamInfoResult.subtitle = `${target.episode.frequency}화`;
            } else {
                // proxyStreamInfoResult.subtitle = target.episode?.name?.ko;
            }

            proxyStreamInfoResult.programCode = target.program?.code;
            proxyStreamInfoResult.seasonPgmCode = target.program?.season_pgm_code;
            proxyStreamInfoResult.frequency = target.episode?.frequency;
            proxyStreamInfoResult.skipTime = Number(target.episode?.skip_sec || 0);
            proxyStreamInfoResult.thumbnail = imgOrigin + (target.episode?.image?.filter((d) => d.code === 'CAIE0400')[0]?.url || '');
            break;
        case 'CSMD0200': // Movie
            proxyStreamInfoResult.mediaType = 'movie';
            proxyStreamInfoResult.type = 'movie';
            targetData = target.movie;
            proxyStreamInfoResult.title = targetData?.name?.ko;
            proxyStreamInfoResult.movieCode = target.movie?.code;
            proxyStreamInfoResult.skipTime = Number(targetData?.skip_sec || 0);
            proxyStreamInfoResult.isAdult = targetData?.adult_yn === 'Y';
            proxyStreamInfoResult.isNotiM1Mac =
                moment('20210121000000', 'YYYYMMDDHHmmss').unix() > moment(targetData?.insert_date, 'YYYYMMDDHHmmss').unix();
            break;
        case 'CSMD0400': // CLIP?
            proxyStreamInfoResult.mediaType = 'clip';
            proxyStreamInfoResult.type = 'clip';
            targetData = target.program;
            proxyStreamInfoResult.title = (target as unknown as ClipContent).title;
            proxyStreamInfoResult.programCode = target.program?.code;
            proxyStreamInfoResult.movieCode = target.movie?.code;
            proxyStreamInfoResult.thumbnail = imgOrigin + (target as unknown as ClipContent).content_img_url;
            break;
        default:
            break;
    }
    const { last_view_time } = data?.body?.content || {};

    proxyStreamInfoResult.startTime = last_view_time;
    proxyStreamInfoResult.mediaCode = mediaCode;

    // vttThumbnail
    let { vtt_img: vttImg, vtt_path: vttPath } = data?.body?.stream?.vtt || data?.data?.stream?.vtt || {};
    if (vttImg && vttPath) {
        vttImg = vttImg.replace('http:', 'https:');
        vttPath = vttPath.replace('http:', 'https:');
        proxyStreamInfoResult.vttThumbnail = {
            vttImg,
            vttPath,
        };
    }

    const {
        drm_yn: drmYn,
        timeshift,
        timeshift_paid_yn: timeshiftPaidYn,
        timeshift_time: timeshiftTime = 120,
    } = data?.body?.stream || {
        broadcast: {
            broad_url: '',
        },
        drm_license_server_list: {},
    };

    const isPreview = resultCode === '100' || resultCode === '150';
    // quickvod 여부
    const isQvod = target?.episode?.pip_cliptype === 'C012';

    return {
        ...proxyStreamInfoResult,
        resultCode,
        isQvod,
        isTimeshift: timeshift === 'Y',
        isTimeshiftPaid: timeshiftPaidYn === 'Y',
        timeshiftTime,
        isPreview,
        isDrm: drmYn === 'Y',
    };
};
export const useStreamInfoApi = (mediaCode?: NullableString, swrOptions = {}, isFetch = true): SWRCustomResponse<ProxyStreamInfo> => {
    // URL 경로를 변동되는 params 까지 묶어서 [ url ] 로 전달.
    // 해당 값이 key로 캐싱됨
    // 다른 params는 { params:{} }로 전달

    return useAbortSWR<ProxyStreamInfo, TvingResponse<StreamInfoBody>, NullableString>(
        [mediaCode ? `/v2/media/stream/info?mediaCode=${mediaCode}` : null],
        {
            params: {
                info: 'Y',
                callingFrom: 'HTML5',
                adReq: 'adproxy',
                uuid: '2236036850-f4d1a6f9',
                deviceInfo: 'PC',
                noCache: moment().unix(),
            },
            withCredentials: true,
        },
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            // revalidateOnMount: false,
            revalidateOnReconnect: false,
            refreshInterval: 0,
            ...swrOptions,
        },
        (data) => {
            return streamInfoProxy(data, mediaCode as string);
        },
        isFetch,
    );
};
