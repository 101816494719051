import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import axiosInstance from './index';

/**
 * 생성한 axiosInstance의 GET 요청 함수
 *
 * @param url: API 주소. baseUrl이 apis.tving.com로 설정되어있으나 웬만하면 항상 FULL URL을 사용하기로 하자!
 * @param requestConfig: header, timeout 등 다양한 설정을 함수 사용자에게 전가
 */
const httpGet = <T>(url: string, requestConfig?: AxiosRequestConfig): Promise<T> => {
  return axiosInstance
    .get<T>(url, requestConfig)
    .then((response: AxiosResponse<T>) => {
      const { data } = response;

      return data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};

export default httpGet;
