import cnx from '@utils/tailwind/cnx';
import GlobalResizeObserver from '@utils/resizeObserver';
import { useState, useRef, PropsWithChildren } from 'react';
import useIsomorphicLayoutEffect from '@tving/utils/src/hooks/common/useIsomorphicLayoutEffect';
import throttle from 'lodash.throttle';
import type { BottomSheetProps } from './types';

/**
 * 스크롤 스타일을 처리하는 컴포넌트입니다.
 */
const ScrollableSection = ({ enableBodyContentScroll, children }: PropsWithChildren<Pick<BottomSheetProps, 'enableBodyContentScroll'>>) => {
    const [hasVerticalScrollBar, setHasVerticalScrollBar] = useState(false);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    useIsomorphicLayoutEffect(() => {
        const scrollContainerElement = scrollContainerRef.current;
        if (!enableBodyContentScroll || !scrollContainerElement) {
            return;
        }

        const handleResize = throttle(
            () => {
                const hasVerticalScrollBar = scrollContainerElement.scrollHeight > scrollContainerElement.clientHeight;
                setHasVerticalScrollBar(hasVerticalScrollBar);
            },
            undefined,
            {
                leading: false,
                trailing: true,
            },
        );
        GlobalResizeObserver.observe(scrollContainerElement, handleResize);

        return () => {
            GlobalResizeObserver.unobserve(scrollContainerElement, handleResize);
        };
    }, []);

    return (
        <section
            ref={scrollContainerRef}
            className={cnx(
                'mt-[0.333rem] mb-[calc(var(--safe-area-inset-bottom,_0)_+_0.666rem)]',
                enableBodyContentScroll ? 'overflow-x-hidden overflow-y-scroll overscroll-contain' : 'overflow-hidden',
                enableBodyContentScroll && hasVerticalScrollBar && 'mr-[0.67rem]',
            )}
        >
            {children}
        </section>
    );
};

export default ScrollableSection;
