type WebviewStylesProps = {
    isWebview: boolean;
};

const WebviewStyles = ({ isWebview }: WebviewStylesProps) => {
    return isWebview ? (
        // eslint-disable-next-line react/no-unknown-property
        <style jsx global>
            {`
                html,
                body a,
                button,
                dd,
                div,
                dl,
                dt,
                html,
                input,
                label,
                li,
                p,
                span,
                textarea,
                ul,
                th,
                td {
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
                        'Segoe UI Symbol' !important;
                }
            `}
        </style>
    ) : null;
};

export default WebviewStyles;
