import httpGet from '@utils/common/httpGet';
import { KBO_URL } from '@tving/utils/src/utils/common/apiConfig';
import { KBO_API_URLS } from '@constants/kbo/url';
import { KboBandsResponse } from '@typings/kbo';
import getKboApiDeviceParams from '@utils/kbo/getKboApiDeviceParams/getKboApiDeviceParams';

const fetchKboAthlete = async (athleteCode: string, isMobileOrWebview?: boolean) => {
    return httpGet<
        KboBandsResponse & {
            data: {
                backgroundImage: string;
            };
        }
    >(KBO_URL + KBO_API_URLS.ATHLETE(athleteCode), {
        params: getKboApiDeviceParams(isMobileOrWebview),
    });
};

export default fetchKboAthlete;
