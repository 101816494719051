import React from 'react';
import useSafeAreaInsets from '@hooks/screen/useSafeAreaInsets';
import { SportsContextProvider, SportsContextProviderProps } from '@tving/utils/src/contexts/sports/SportsContext';
import UserSectionProvider from '@providers/user/UserSectionProvider';
import useAnalyticsConfig from '@hooks/tools/useAnalyticsConfig';

const SportsProvider = ({
    children,
    device,
    isWebview,
    logoImgUrl,
    ssrToken,
    userAgent,
    userInfoData,
    userInfoProfileData,
    userInfoValidTicketData,
    userRegionCode,
}: SportsContextProviderProps) => {
    useSafeAreaInsets();
    useAnalyticsConfig();

    return (
        <SportsContextProvider
            ssrToken={ssrToken}
            userInfoData={userInfoData}
            userInfoProfileData={userInfoProfileData}
            userInfoValidTicketData={userInfoValidTicketData}
            userAgent={userAgent}
            logoImgUrl={logoImgUrl}
            userRegionCode={userRegionCode}
            isWebview={isWebview}
            device={device}
        >
            <UserSectionProvider>{children}</UserSectionProvider>
        </SportsContextProvider>
    );
};

export default SportsProvider;
