import { createContext, useMemo, PropsWithChildren, useContext } from 'react';

type KboMyTeamSettingModalContextValue = {
    onClose: () => void;
};

type KboMyTeamSettingModalContextProviderProps = PropsWithChildren<{
    onClose: () => void;
}>;

const KboMyTeamSettingModalContext = createContext<KboMyTeamSettingModalContextValue | undefined>(undefined);

export const KboMyTeamSettingModalContextProvider = ({ onClose, children }: KboMyTeamSettingModalContextProviderProps) => {
    const value = useMemo(() => {
        return {
            onClose,
        };
    }, [onClose]);

    return <KboMyTeamSettingModalContext.Provider value={value}>{children}</KboMyTeamSettingModalContext.Provider>;
};

export const useKboMyTeamSettingModalContext = () => {
    const context = useContext(KboMyTeamSettingModalContext);

    if (!context) {
        throw new Error('useKboMyTeamSettingModalContextProvider을 사용해야 합니다.');
    }

    return context;
};
