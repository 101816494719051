import explainSubtitle from '../images/contentLabel/label_cc.png';
import cineSame from '../images/contentLabel/label_cine_same.png';
import cmmg0100 from '../images/contentLabel/label_cmmg0100.png';
import cmmg0200 from '../images/contentLabel/label_cmmg0200.png';
import cmmg0300 from '../images/contentLabel/label_cmmg0300.png';
import cmmg0400 from '../images/contentLabel/label_cmmg0400.png';
import cptg0100 from '../images/contentLabel/label_cptg0100.png';
import cptg0200 from '../images/contentLabel/label_cptg0200.png';
import cptg0300 from '../images/contentLabel/label_cptg0300.png';
import cptg0400 from '../images/contentLabel/label_cptg0400.png';
import cptg0500 from '../images/contentLabel/label_cptg0500.png';
import dolby51 from '../images/contentLabel/label_dolby51ch.png';
import dolbyAtmos from '../images/contentLabel/label_dolbyatmos.png';
import dolbyVision from '../images/contentLabel/label_dolbyvision.png';
import drm from '../images/contentLabel/label_drm.png';
import dtx from '../images/contentLabel/label_dtx.png';
import dubbing from '../images/contentLabel/label_dubbing.png';
import dubbingPoster from '../images/contentLabel/label_dubbing2.png';
import event from '../images/contentLabel/label_event.png';
import firstOpen from '../images/contentLabel/label_first_open.png';
import freePoster from '../images/contentLabel/label_free.png';
import free from '../images/contentLabel/label_free2.png';
import hdr from '../images/contentLabel/label_hdr.png';
import hdr10Plus from '../images/contentLabel/label_hdr10plus.png';
import lite from '../images/contentLabel/label_lite.png';
import premium from '../images/contentLabel/label_premium.png';
import purchase from '../images/contentLabel/label_purchase.png';
import quickVod from '../images/contentLabel/label_quick_vod.png';
import subtitle from '../images/contentLabel/label_subtitle.png';
import subtitlePoster from '../images/contentLabel/label_subtitle2.png';
import uhd4k from '../images/contentLabel/label_uhd4k.png';
import tvingOnly from '../images/label/label_only.png';
import tvingOriginal from '../images/label/label_original.png';
import paramount from '../images/label/label_paramount.png';

export enum TV_PAGE {
  // 판
  BOARD = 'BOARD',

  // SUB SUB_BOARD
  SUB_BOARD = 'SUB_BOARD', //

  // GNB
  GNB = 'GNB',

  // 리스트
  LIST = 'LIST',

  // 검색
  SEARCH = 'SEARCH',
  // 검색 결과 전체보기 리스트
  SEARCHLIST = 'SEARCHLIST',

  // 상세 진입
  DETAIL = 'DETAIL',

  // 플레이어
  PLAYER = 'PLAYER',

  // 온보딩
  ONBOARDING = 'ONBOARDING',

  // QR
  QR = 'QR',

  // Login
  LOGIN = 'LOGIN',

  // TV Login
  TVLOGIN = 'TVLOGIN',

  // Welcome
  WELCOME = 'WELCOME',

  // 이용권 구매
  PURCHASE = 'PURCHASE',

  // 프로필
  PROFILE = 'PROFILE',
  PROFILE_EDIT = 'PROFILE_EDIT',
  PROFILE_IMAGE_LIST = 'PROFILE_IMAGE_LIST',
  PASSWORD = 'PASSWORD',
  PASSWORD_RESET = 'PASSWORD_RESET',

  // 빌링
  BILLING = 'BILLING',
  BILLING_AFFILIATE = 'BILLING_AFFILIATE',
  BILLING_CAUTION = 'BILLING_CAUTION',
  BILLING_HISTORY = 'BILLING_HISTORY',
  // 가격 인상 동의 페이지
  BILLING_PRICE_INCREASE_CONSENT = 'BILLING_PRICE_INCREASE_CONSENT',
  BILLING_AVOD_QR = 'BILLING_AVOD_QR',

  // 문자 보내기
  SMS = 'SMS',

  // 확인
  CERTIFICATION = 'CERTIFICATION',

  // 설정
  SETTING = 'SETTING',

  // 팝업
  POPUP = 'POPUP',
  NOTICE_POPUP = 'NOTICE_POPUP',
  TOAST = 'TOAST',
  PLAYER_BLOCK = 'PLAYER_BLOCK',
  IMAGE_BUTTON_POPUP = 'IMAGE_BUTTON_POPUP',

  // TTV 및 긴급점검
  EMERGENCY = 'EMERGENCY',
}

export enum DEVICE_TYPE {
  TIZEN = 'TIZEN',
  ANDROIDTV = 'ANDROIDTV',
  WEBOS = 'WEBOS',
  WEB = 'WEB',
}

export const ONBOARD_SECTION_CODE = {
  TV_ONBOARDING_1: 'TV_ONBOARDING_1' as const,
  TV_ONBOARDING_2: 'TV_ONBOARDING_2' as const,
  TV_ONBOARDING_3: 'TV_ONBOARDING_3' as const,
};
export const COOKIE_MAX_AGE = 60 * 60 * 24 * 365; // 365일
export const COOKIE_MAX_AGE_1_YEAR = 60 * 60 * 24 * 365; // 365일 (31536000)
export const COOKIE_MAX_AGE_30_DAYS = 60 * 60 * 24 * 30; // 30일 (2592000)
export const COOKIE_DOMAIN_TVING = '.tving.com';

export const CACHE_KEY = {
  LEGACY_USER_INFO: 'ui' as const,

  USER_TOKEN: '_user_token' as const,
  TVING_TOKEN: '_tving_token' as const,
  ACCESS_TOKEN: 'accessToken' as const,
  REFRESH_TOKEN: 'refreshToken' as const,
  REGION_CODE: 'region_code' as const,
  AUTH_TOKEN: '_auth_token' as const,
  AUTH_TOKEN_NEW: 'authToken' as const,
  PROFILE_NO: '_profile_no' as const,
  LAST_PASSWORD_UPDATE_DATE: 'TLPUDB35Qhn7' as const,
  LAST_LOGIN_TYPE: 'LAST_LOGIN_TYPE' as const,
  LAST_TVING_ID: 'LAST_TVING_ID' as const,
  LAST_CJONE_ID: 'LAST_CJONE_ID' as const,
  TVINGID_INPUTBOX_TOOLTIP: 'TVINGID_INPUTBOX_TOOLTIP' as const,
  CJONEID_INPUTBOX_TOOLTIP: 'CJONEID_INPUTBOX_TOOLTIP' as const,
  LEGAL_CONFIRM_YN: 'LEGAL_CONFIRM_YN' as const,
  PACKAGE_YN: '9f5Ec1XTDj8d1mYCvfcoIw' as const, // 결제이력 유무
  TVING_AUTO_EXPIRE_TIME: 'TVING_AUTO_EXPIRE_TIME' as const,

  OPENING_SKIP_COUNT: 'OPENING_SKIP_COUNT' as const,
  OPENING_SKIP_POPUP: 'OPENING_SKIP_POPUP' as const,
  AUTO_OPENING_SKIP: 'AUTO_OPENING_SKIP' as const,
  UPGRADE_BILLING: 'UPGRADE_BILLING' as const, // 기존 스탠다드 4K 업셀링 팝업 노출 여부
  UPSELLING_POPUP: 'UPSELLING_POPUP' as const, // 신규 업셀링 팝업 노출 여부 관리
  BOOT_POPUP: 'BOOT_POPUP' as const, // 신규 업셀링 팝업 노출 여부 관리
  uuid: 'uuid' as const,
  RECENTLY_SEARCH_KEYWORDS: 'RECENTLY_SEARCH_KEYWORDS' as const,

  BUFFER: 'BUFFER' as const,
  OPEN_SEARCH_PROFILES: 'OPEN_SEARCH_PROFILES' as const,
  OPEN_PARAMOUNT_PROFILES: 'OPEN_PARAMOUNT_PROFILES' as const,
  OPEN_SPORTS_PROFILES: 'OPEN_SPORTS_PROFILES' as const,
  OPEN_LIVE_PROFILES: 'OPEN_LIVE_PROFILES' as const,

  PLAYER_TALK_TOOLTIP_OPEN_PROFILE: 'PLAYER_TALK_TOOLTIP_OPEN_PROFILE' as const, // 티빙톡 첫 진입시 툴팁 노출 여부 관련 localstorge id
  PLAYER_SUBTITLE_TOOLTIP_FIRST_DISPLAY: 'PLAYER_SUBTITLE_TOOLTIP_FIRST_DISPLAY' as const, // 자막 첫 진입시 툴팁 노출 여부 관련 localstorge id
  PLAYER_SUBTITLE_SIZE_INFO: 'PLAYER_SUBTITLE_SIZE_INFO' as const,

  DEVICE_OS_VERSION: 'DEVICE_OS_VERSION' as const,
  DEVICE_APP_VERSION_TIMESTAMP: 'DEVICE_APP_VERSION_TIMESTAMP' as const,
  DEVICE_UHD_SUPPORTED: 'DEVICE_UHD_SUPPORTED' as const,
  DEVICE_MODEL: 'DEVICE_MODEL' as const,
  DEVICE_RESOLUTION_WIDTH: 'DEVICE_RESOLUTION_WIDTH' as const,
  DEVICE_RESOLUTION_HEIGHT: 'DEVICE_RESOLUTION_HEIGHT' as const,

  TVING_PLAYER_DEBUG_CONSOLE: 'tving-player-debug-console' as const,

  CHUNK_RETRY_COUNT: 'CHUNK_RETRY_COUNT' as const,

  // amplitude 미디어코드별 1회 체크용도
  AMPLITUDE_CLICK_START_REPLAY_TV: 'AMPLITUDE_CLICK_START_REPLAY_TV' as const,
  AMPLITUDE_CLICK_EPISODE_LIST_TV: 'AMPLITUDE_CLICK_EPISODE_LIST_TV' as const,
  AMPLITUDE_CLICK_SUBTITLE_TV: 'AMPLITUDE_CLICK_SUBTITLE_TV' as const,
  AMPLITUDE_CLICK_SETTING_TV: 'AMPLITUDE_CLICK_SETTING_TV' as const,
  ADPROXY_POLICY_ID: 'ADPROXY_POLICY_ID' as const,
  ENVIRONMENT_TYPE: 'ENVIRONMENT_TYPE' as const,
  AGREE_POPUP: 'AGREE_POPUP' as const,
  TERMINATION_POPUP: 'TERMINATION_POPUP' as const,
  LAST_SHOW_POPUP_TYPE: 'LAST_SHOW_POPUP_TYPE' as const,

  ID_CHECKING_INFO: 'ID_CHECKING_INFO' as const,
  APP_ID: 'APP_ID' as const,
  WEB_OAUTH_CANCEL_URL: 'webOauthCancelUrl' as const,
  GOOGLE_SCORE_RECAPTCHA: 'GOOGLE_SCORE_RECAPTCHA' as const,
  GOOGLE_CHECKBOX_RECAPTCHA: 'GOOGLE_CHECKBOX_RECAPTCHA' as const,

  LOGIN_FAIL_LIMIT: 'LOGIN_FAIL_LIMIT' as const,
  LOGIN_EXCESS_LIMIT: 'LOGIN_EXCESS_LIMIT' as const,
  TVING_CAPTCHA_KEY: 'TVING_CAPTCHA_KEY' as const,
};

export enum RATING_TYPE {
  MOVIE_18 = 'MOVIE_18',
  PROGRAM_19 = 'PROGRAM_19',
}

export const RATING_ICON: Record<RATING_TYPE, string> = {
  MOVIE_18: cmmg0400,
  PROGRAM_19: cptg0500,
};

export enum LABEL_TYPE {
  TVING_ORIGINAL = 'TVING_ORIGINAL',
  TVING_ONLY = 'TVING_ONLY',
  PARAMOUNT = 'PARAMOUNT',
}

export const LABEL_IMAGE: Record<LABEL_TYPE, string> = {
  TVING_ORIGINAL: tvingOriginal,
  TVING_ONLY: tvingOnly,
  PARAMOUNT: paramount,
};

export enum CONTENT_TYPE {
  MOVIE = 'MOVIE',
  PROGRAM = 'PROGRAM',
  EPISODE = 'EPISODE',
  LIVE = 'LIVE',
  CREATOR = 'CREATOR',
  CHARACTER = 'CHARACTER',
  CLIP = 'CLIP',
  MORE = 'MORE',
}

export enum CONTENT_LABEL_TYPE {
  CINE_SAME = 'CINE_SAME',
  DOLBY_51 = 'DOLBY_51',
  DOLBY_ATMOS = 'DOLBY_ATMOS',
  DOLBY_VISION = 'DOLBY_VISION',
  DRM = 'DRM',
  DTX = 'DTX',
  DUBBING = 'DUBBING',
  DUBBING_POSTER = 'DUBBING_POSTER',
  FIRST_OPEN = 'FIRST_OPEN',
  HDR = 'HDR',
  HDR10_PLUS = 'HDR10_PLUS',
  LITE = 'LITE',
  PREMIUM = 'PREMIUM',
  PURCHASE = 'PURCHASE',
  QUICK_VOD = 'QUICK_VOD',
  SUBTITLE = 'SUBTITLE',
  SUBTITLE_POSTER = 'SUBTITLE_POSTER',
  UHD_4K = 'UHD_4K',
  EVENT = 'EVENT',
  EXPLAIN_SUBTITLE = 'EXPLAIN_SUBTITLE', // 해설자막
  FREE = 'FREE', // 무료
  FREE_META = 'FREE_META', // 무료(컨텐츠 메타영역용)
}

export const CONTENT_LABEL_IMAGE: Record<CONTENT_LABEL_TYPE, string> = {
  CINE_SAME: cineSame,
  DOLBY_51: dolby51,
  DOLBY_ATMOS: dolbyAtmos,
  DOLBY_VISION: dolbyVision,
  DRM: drm,
  DTX: dtx,
  DUBBING: dubbing,
  DUBBING_POSTER: dubbingPoster,
  FIRST_OPEN: firstOpen,
  HDR: hdr,
  HDR10_PLUS: hdr10Plus,
  LITE: lite,
  PREMIUM: premium,
  PURCHASE: purchase,
  QUICK_VOD: quickVod,
  SUBTITLE: subtitle,
  SUBTITLE_POSTER: subtitlePoster,
  UHD_4K: uhd4k,
  EVENT: event,
  EXPLAIN_SUBTITLE: explainSubtitle,
  FREE: free,
  FREE_META: freePoster,
};

export enum GRADE_CODE {
  // 전체관람가
  CPTG0100 = 'CPTG0100',
  CMMG0100 = 'CMMG0100',

  // 7세 관람가
  CPTG0200 = 'CPTG0200',

  // 12세 관람가
  CPTG0300 = 'CPTG0300',
  CMMG0200 = 'CMMG0200',

  // 15세 관람가
  CPTG0400 = 'CPTG0400',
  CMMG0300 = 'CMMG0300',

  // 청소년 관람불가 (18세)
  CMMG0400 = 'CMMG0400',

  // 청소년 관람불가 (19세)
  CPTG0500 = 'CPTG0500',
}

export const GRADE_CODE_IMAGE: Record<GRADE_CODE, string> = {
  // 전체관람가
  CPTG0100: cptg0100,
  CMMG0100: cmmg0100,

  // 7세 관람가
  CPTG0200: cptg0200,

  // 12세 관람가
  CPTG0300: cptg0300,
  CMMG0200: cmmg0200,

  // 15세 관람가
  CPTG0400: cptg0400,
  CMMG0300: cmmg0300,

  // 청소년 관람불가 (18세)
  CMMG0400: cmmg0400,

  // 청소년 관람불가 (19세)
  CPTG0500: cptg0500,
};

export enum DAY_CODE {
  PGWD0100 = 'PGWD0100',
  PGWD0200 = 'PGWD0200',
  PGWD0300 = 'PGWD0300',
  PGWD0400 = 'PGWD0400',
  PGWD0500 = 'PGWD0500',
  PGWD0600 = 'PGWD0600',
  PGWD0700 = 'PGWD0700',
}

export enum STREAM_INFO_RESULT_CODE {
  POSSIBLE_PLAY = '000', // 재생
  BAN_OVERSEAS = '001', // 해외접속 차단
  FAIL_META_INFO = '090', // 메타 정보 조회 실패
  NO_REGISTER_DRM = '060',
  LIMIT_MULTI_CONNECTION = '091',

  NO_LOGIN_INFO = '010', // 로그인 정보 없을시
  NOT_ADULT_CERTIFICATION = '011', // 성인인증 안되어있을때

  NOT_LOGIN = '100', // 로그인 필요
  NOT_BUY = '150', // 유료 콘텐츠 재생 시도시 상품 구매가 안되어 있는 경우
  DOWNLOAD = '050', // 영상 재생/다운로드 시도 시

  UPGRADE_BILLING = '151', // 이용권 업그레이드
  UPGRADE_BILLING_AVOD_QVOD = '152', // 이용권 업그레이드

  BLOCK_LIVE_CHANNEL = '080', // 라이브 블랙 채널 재생 시도시
  STATE_STREAMINFO_LOADERROR = '999', // Player SDK 에러코드 네트워크 에러일때 적용
}

export enum TOAST_CODE {
  // 001~099 :Player 관련 코드
  OPENING_SEEK_PLAY = '001', // 오프닝 건너뛰기 시점부터 재생됩니다.
  LAST_SEEK_PLAY = '002', // 마지막 시청 시점부터 이어보기됩니다.
  NOT_SUPPORT_HDR_DEVICE_PLAY_1080 = '003', // HDR 미지원 단말로 최대 1080p로 재생됩니다.
  NOT_SUPPORT_4K_DEVICE_PLAY_1080 = '004', // 4K 미지원 단말로 최대 1080p로 재생됩니다.
  NOT_SUPPORT_4K_PLAY_1080 = '005', // 4K 재생이 원활하지 않아 최대 1080p로 재생됩니다.
  NOT_SUPPORT_SEEKING = '006', // 이동구간이 아닙니다.

  // 100~199 :Device 관련 코드
  BAN_ROOTING = '100', // 루팅된 기기에서는 이용할 수 없습니다.
  AUTO_REGISTER_DEVICE = '101', // 재생을 위한 기기 정보가 자동 등록 되었습니다.
  // 200~299 : 기타 코드
}

/**
 * TOAST_CODE 별 텍스트 내용 정의
 */
export const TOAST_TEXTS = {
  // PLAYER
  [TOAST_CODE.OPENING_SEEK_PLAY]: '오프닝 건너뛰기 시점부터 재생됩니다.',
  [TOAST_CODE.LAST_SEEK_PLAY]: '마지막 시청 시점부터 이어보기됩니다.',
  [TOAST_CODE.NOT_SUPPORT_4K_PLAY_1080]: '현재 4K 재생이 원활하지 않아 최대 1080p로 재생됩니다.',
  [TOAST_CODE.NOT_SUPPORT_SEEKING]: '이동 가능한 구간이 아닙니다.',
  [TOAST_CODE.NOT_SUPPORT_HDR_DEVICE_PLAY_1080]: 'HDR 미지원 단말로 최대 1080p로 재생됩니다.',
  [TOAST_CODE.NOT_SUPPORT_4K_DEVICE_PLAY_1080]: '4K 미지원 단말로 최대 1080p로 재생됩니다.',
  // DEVICE
  [TOAST_CODE.BAN_ROOTING]: '루팅된 기기에서는 이용할 수 없습니다.',
  [TOAST_CODE.AUTO_REGISTER_DEVICE]: '재생을 위한 기기 정보가 자동 등록 되었습니다.',
};

export enum STREAM_INFO_LINK_TARGET {
  PRICEUP = 'PRICEUP', // 가격인상 미동의 유저 오리지널 시청 제한
}

/**
 * POPUP_TYPE 별 텍스트 내용 정의
 */
export const POPUP_TYPE_TEXTS = {
  SUBTITLE_NOT_SUPPORTED: [
    '자막 메뉴 이용을 위해서는',
    '최신 버전으로 업데이트가 필요합니다.',
    '기기에서 Google Play Store 접속 후',
    '티빙 앱을 진입하시고 업데이트를 해주세요.',
  ],
  LOAD_CONTENT_INFO_ERROR: ['일시적으로 콘텐츠 정보를 불러올 수 없습니다.', '[확인]을 누르시고 다시 시도해주세요.'],
  CHECK_NETWORK_STATE: ['네트워크 상태를 확인해주세요.'], // 확인
  CHECK_NETWORK_STATE_AND_RESTART: ['네트워크 상태를 확인 후', '티빙 앱을 다시 실행해주세요.'], // 확인
  END_SERVICE_CONTENT: ['서비스 종료된 콘텐츠로 재생 불가합니다.'], /// /확인
  WRONG_CONTENT: ['영상에 문제가 있어 재생이 불가합니다.', 'tving@cj.net 으로 문의해주세요'], // 확인
  MEDIA_PLAYER_ERROR: ['플레이어에 오류가 발생하여 재생이 불가합니다.', '동일한 문제가 지속 발생할 경우 고객센터로 문의해주세요.'], // 확인
  FAIL_META_INFO_ERROR: ['요청하신 콘텐츠 정보를 찾을 수 없습니다.', '문의사항은 고객센터로 문의해주세요.'], // 확인
  UNKNOWN_PLAY_ERROR: ['일시적인 오류가 발생하여 재생이 불가합니다.', '문의사항은 고객센터로 문의해주세요.'], // 확인
  NEED_UPGRADE_TICKET: ['TV에서 시청하시려면 이용권 업그레이드 후 이용 가능합니다.'], // 확인
  NEED_UPDATE_APP: ['클립 시청은 앱 업데이트 후 이용할 수 있어요.'], // 확인
  BAN_OVERSEAS: ['티빙 서비스는 국내에서만 이용하실 수 있습니다.', '국내에서 접속 중임에도 메시지가 노출되는 경우', '고객센터로 문의해주세요.'], // 확인
  CONFIRM_USER_AUTHENTICATION: ['구독을 위해서는 사용자 인증이 필요합니다.', '진행하시겠습니까?'], // 확인 취소
  CONFIRM_ADULT_AUTHENTICATION: ['성인 인증이 필요합니다.', '진행하시겠습니까?'], // 확인 취소
  CHECK_OTP_CONFIRM: (url: string, keyword: string) => [`${url} 으로 접속해서`, `${keyword} 완료 후 TV의 완료 버튼을 눌러주세요.`] as string[], // 확인
  CHECK_QR_CONFIRM: (keyword: string) => [`QR코드를 통해 모바일웹에 접속해서`, `${keyword} 완료 후 TV의 완료 버튼을 눌러주세요.`] as string[], // 확인

  CHECK_PASSWORD: ['계정 비밀번호를 입력해주세요.'], // 확인
  NOT_CORRECT_PASSWORD: ['비밀번호가 일치하지 않습니다.', '다시 입력해주세요.'], // 확인
  PASSWORD_CHANGED_LOGOUT: ['비밀번호가 변경되어 로그아웃됩니다.'] as string[], // 확인
  PASSWORD_RESET_COMPLETE: ['비밀번호가 초기화되었습니다.'] as string[],
  CONFIRM_SEND_EMAIL: (email: string) =>
    [
      `${email}로 비밀번호 초기화 URL이 발송됩니다.`,
      '이메일을 발송하시겠습니까?',
      '',
      '사용하는 이메일이 아니라면, App이나 PC의',
      'MY>회원정보 수정에서 이메일 주소를 변경해주세요.',
    ] as string[], // 확인 췩소
  INVALID_EMAIL_ADDRESS: [
    '비밀번호 초기화 링크를 보낼',
    '이메일 주소가 올바르지 않습니다.',
    'App이나 PC의 MY>회원정보 수정에서',
    '이메일 주소를 변경해주세요.',
  ] as string[], // 확인
  COMPLETE_SEND_EMAIL: ['이메일이 발송되었습니다.', '이메일 링크 비밀번호 초기화 후 프로필을 선택해주세요.'] as string[], // 확인
  FAIL_SEND_EMAIL: ['이메일이 정상적으로 발송되지 않았습니다.', '잠시 후 다시 시도해주세요.'] as string[], // 확인
  DO_NOT_DELETE_DEFAULT_PROFILE: ['대표 프로필(첫번째 프로필)은 삭제할 수 없습니다.'] as string[], // 확인
  CONFIRM_EDIT_CANCEL: ['취소 시, 변경된 내용은 저장되지 않습니다.', '그래도 취소하시겠습니까?'] as string[], // 확인 닫기
  CHECK_PROFILE_VALIDATION: ['프로필 이름은 2자 이상 10자 이내의', '한글, 영문, 숫자만 입력 가능합니다.', '다시 입력해주세요.'] as string[], // 확인
  CONFIRM_WRITE_CANCEL: ['취소 시, 입력된 내용은 저장되지 않습니다.', '그래도 취소하시겠습니까?'] as string[], // 확인 닫기
  CHECK_PROFILE_NAME: ['프로필 이름을 입력해주세요.'], // 확인
  CONFIRM_DELETE_PROFILE: ['정말 삭제하시겠습니까?', '프로필 삭제 시, 모든 데이터가 삭제되며', '복구 할 수 없습니다.'] as string[], // 확인 취소
  NOT_SUPPORTED_DRM: ['해당 기기는 DRM 재생 불가 단말로 DRM 콘텐츠 재생에 문제가 발생할 수 있습니다.'] as string[], // 확인
  CONFIRM_DELETE_DEVICE: ['등록된 기기를 삭제하시겠습니까?', '삭제는 월 1회 가능합니다.'] as string[], // 확인 취소
  COMPLETE_REGISTER_DEVICE: ['기기 등록이 완료되었습니다.'], // 확인
  FULL_REGISTERED_DEVICES: ['5대의 기기가 모두 등록되어 있어요.', '등록 기기 삭제 후 다시 시도하세요.'] as string[], // 확인
  IMPOSSIBLE_DELETE_DEVICE: ['이번달에 삭제한 이력이 있습니다.', '다음 달에 삭제/등록 가능합니다.'] as string[], // 확인
  FULL_REGISTERED_DEVICES_ERROR: [
    '고객님의 아이디로 5대의 기기가 등록되어 있어 추가 등록이 제한됩니다.',
    '기기등록 메뉴에서 등록된 기기를 관리할 수 있습니다.',
    '기기등록 메뉴로 이동하시겠습니까?',
  ] as string[], // 확인
  COMPLETE_DEVICE_DELETE: ['기기 삭제가 완료되었습니다.'], // 확인
  PROFILE_COUNT_LIMIT_UPGRADE: ['프로필 추가는 이용권 업그레이드 후 이용 가능합니다.', '이용권 변경 안내 페이지로 이동하시겠습니까?'] as string[], // 확인
  PROFILE_COUNT_LIMIT_PURCHASE: ['프로필 추가 권한이 없습니다.', '이용권을 구매 하시겠습니까?'] as string[], // 확인
  SALE_END_CONTENTS: ['판매가 종료된 콘텐츠입니다.'] as string[], // 확인
  UPGRADE_UPSALE_FOR_AFFILIATE: [
    '4K 화질 이용은 이용권 업그레이드 후',
    '이용 가능합니다.',
    '이용권 변경 안내 페이지로',
    '이동하시겠습니까?',
  ] as string[], // 확인, 기본 화질로 시청하기
  USE_AFTER_UPGRADE: ['TV에서 시청하시려면 이용권', '업그레이드 후 이용 가능합니다.'] as string[], // 확인
  USE_AFTER_PURCHASE: ['이용권 구독 후 시청할 수 있습니다.'] as string[], // 확인
  UPGRADE_UPSALE: ['해당 콘텐츠는 4K 화질로 제공됩니다.', '4K 콘텐츠는 프리미엄 이용권으로', '업그레이드 후 시청할 수 있어요.'], // 업그레이드하기, 기본 화질로 시청하기
  FREE_UPGRADE: ['무료 콘텐츠는 최대 720p 화질로 재생됩니다.', '고화질로 시청하시려면 이용권을 구독해 주세요.'], // 업그레이드하기, 기본 화질로 시청하기
  QVOD_UPGRADE: ['이용권 업그레이드 후', 'Quick VOD를 시청할 수 있어요.'], // 업그레이드하기, 기본 화질로 시청하기
  QVOD_PURCHASE: ['이용권 구독 후', 'Quick VOD를 시청할 수 있어요.'], // 업그레이드하기, 기본 화질로 시청하기
  AVOD_UPGRADE: ['광고형 이용권은 업그레이드 후', '시청할 수 있습니다.'] as string[], // 확인
  ALREADY_USE_TICKET: ['현재 구독중인 이용권과', '동일한 이용권입니다.'] as string[],
  NO_LOGIN_INFO: ['계정 정보를 불러올 수 없습니다.', '로그아웃 합니다.'],
  GUIDE_SKIP_OPENING: [
    '오프닝 건너뛰기 기능을 자주 사용하시네요!',
    "'자동 오프닝 건너뛰기' 기능을 ON 설정하시면",
    '자동으로 오프닝 구간이 점프됩니다.',
    '지금 바로 설정하실래요?',
  ] as string[], // 지금 설정하기 닫기

  PURCHASED_CHECK_TEXTS: {
    // 다른 구글 계정으로 로그인한 경우 + 구글 정기결제 기간이 만료되어서 purchase token은 없어졌으나, 티빙은 23:59:59 가 안되어 아직 이용권이 남아 있는 경우
    CHANGE_GOOGLE_ACCOUNT: [
      '이용권 변경이 불가능합니다.',
      '구매한 Google 계정이 맞는지 혹은 정기결제가 정상적으로 이뤄졌는지 확인해주세요. (Play 스토어>정기결제 복원)',
    ] as string[],

    // 다른 티빙 계정으로 로그인한 경우
    CHANGE_TVING_ACCOUNT: ['이미 다른 티빙 계정으로 이용권을 구독 중입니다.', '구독 중인 아이디를 확인해 주세요.'],

    CHANGE_ANOTHER_POC: [
      'PC, 모바일 웹 또는 iOS 기기에서 결제한',
      '이용권은 해당 기기에서 변경할 수 있습니다.',
      '(2022년 4월 이전 Android 기기에서 결제한 이용권 포함)',
    ] as string[],

    CHANGE_ANOTHER_POC_APP: ['Android App(Mobile, Tablet)에서 결제한 이용권은', '해당 기기에서 변경 가능하며 Android TV에서는 변경 불가능합니다.'],

    ALREADY_USE: ['Android 기기에서 결제한 이용권은', 'Play 스토어에서 변경할 수 있습니다.'],
    ALREADY_CHANGE: ['이용권 변경은 매월1회 가능합니다.'],
    NEED_RESTORE: ['이용권을 결제하셨으나 오류가 발생하여 이용권 지급에 실패했습니다.', '이용권 화면 하단의 구매내역 복원 버튼을 눌러주세요.'],
    ERROR: ['오류가 발생했습니다.', '다시 시도해주세요.'],

    RESTORE_SUCCESS: ['구매 내역이 복원되었습니다.'],
    RESTORE_FAIL_CHECK_ACCOUNT: ['구매 복원할 내역이 없습니다.', '로그인 된 계정을 확인해주세요.'],
    RESTORE_FAIL_TRY_AGAIN: ['구매 복원에 실패하였습니다.', '다시 시도해주세요.'],

    PURCHASE_SUCCESS: ['이용권 구독이 완료되었습니다.'],
  },
  LOGOUT: ['로그아웃 하시겠어요?'] as string[],
  EXIT: ['정말 TVING을 종료하시겠습니까?'] as string[],
  TICKET_OWNER_CANNOT_REGISTER_COUPON: ['이용권 구독 중으로 쿠폰을 등록할 수 없습니다.'] as string[],
  CORRECT_PHONE_NUMBER: ['휴대폰 번호를 정확히 입력해주세요.'] as string[],
  CAN_BUY_PC_MOBILE: ['해당 콘텐츠는 PC Web에서만 구매가 가능합니다.'] as string[],
  UPDATE_VERSION: [
    '안정적인 티빙 서비스 제공을 위하여',
    '최신 업데이트 사항을 적용하고자 합니다.',
    '[확인] 을 누르시면 자동으로 앱이 재시작되면서',
    '업데이트 적용이 완료됩니다.',
  ] as string[],
  END_CONTENT: ['서비스가 종료된 콘텐츠입니다.'] as string[],
  LOGIN_FAILED: ['일치하는 회원정보가 없습니다.', '이용하시는 계정 유형(TVING/CJ ONE)과', '아이디, 비밀번호를 다시 확인해주세요.'] as string[],
  LOGIN_DORMANCY_FAILED: ['회원님의 계정은 현재 휴면 상태입니다.', '스마트폰에서만 로그인이 가능합니다.'] as string[],
  PROVISIONING_6001_ERROR: ['서비스를 지원하지 않는 국가입니다.', '앱이 종료됩니다.'],
  PROVISIONING_6003_ERROR: ['일시적인 오류가 발생하였습니다.', '잠시 후 다시 시도해주세요.'],
  NOT_ONAIR_TIME: ['라이브 시작 후 시청 가능합니다.'],
  ENDED_LIVE_GO_HOME: ['라이브 방송이 종료되어 이전 화면으로 이동합니다.'],
  APPLE_CONTENTS_AVAILABLE_PREMIUM: ['Apple TV+ 콘텐츠는 프리미엄 이용권으로 시청할 수 있습니다.'] as string[],
  LOGIN_FAILED_LOCK_ID: [
    '로그인에 20회이상 실패하여,',
    ' 이용자 보호를 위해 계정이 잠김 상태로 전환됩니다.',
    '잠금 해제를 위해 모바일 앱 또는 PC에서',
    '[비밀번호 찾기]를 진행해주세요.',
  ] as string[],
};

export enum SMS_TYPE {
  ADULT = 'adult',
  USER = 'user',
  PPV = 'ppv',
  TICKET = 'ticket',
  COUPON = 'coupon',
}

export const SMS_TITLE = {
  [SMS_TYPE.ADULT]: '성인 인증',
  [SMS_TYPE.USER]: '사용자 인증',
  [SMS_TYPE.TICKET]: '이용권 구독하기',
  [SMS_TYPE.COUPON]: '쿠폰 등록하기',
};

export const CERTIFICATION_TITLE = {
  ...SMS_TITLE,
  [SMS_TYPE.TICKET]: '휴대폰 문자로 구매하기',
};

export const SMS_DESCRIPTION = {
  [SMS_TYPE.ADULT]: ['휴대폰 번호 입력 후 [문자 보내기] 버튼을 누르면', '성인 인증이 가능한 링크를 문자로 발송해드립니다.'],
  [SMS_TYPE.USER]: ['사용자 인증 가능한 링크를 문자로 발송해드립니다.'],
  [SMS_TYPE.PPV]: ['개별 구매 가능한 링크를 문자로 보내드립니다.'],
  [SMS_TYPE.TICKET]: ['이용권 구독 가능한 링크를 문자로 보내드립니다.'],
  [SMS_TYPE.COUPON]: ['쿠폰 등록 가능한 링크를 문자로 보내드립니다.'],
};

export const CERTIFICATION_DESCRIPTION = {
  [SMS_TYPE.ADULT]: [
    '문자로 전송된 URL 링크를 클릭하세요.',
    '휴대폰에서 사용자 인증을 진행하세요.',
    '인증이 완료되면 TV에서 즉시 시청이 가능합니다.',
  ] as string[],
  [SMS_TYPE.USER]: [
    '문자로 전송된 URL 링크를 클릭하세요.',
    '휴대폰에서 사용자 인증을 진행하세요.',
    '인증이 완료되면 TV에서 이용권 구독이 가능합니다.',
  ] as string[],
  [SMS_TYPE.TICKET]: [
    '문자로 전송된 URL 링크를 클릭하세요.',
    '휴대폰에서 이용권 구독을 진행하세요.',
    '등록이 완료되면 아래 [확인] 버튼을 눌러주세요.',
  ] as string[],
  [SMS_TYPE.COUPON]: [
    '문자로 전송된 URL 링크를 클릭하세요.',
    '휴대폰에서 쿠폰 등록을 진행하세요.',
    '등록이 완료되면 아래 [확인] 버튼을 눌러주세요.',
  ] as string[],
};

export enum DEVICE_LEVEL {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
}

export const LOGIN_DESCRIPTION = {
  MOBILE_LOGIN: ['스마트폰 카메라로 QR코드를 화면에 비추면', '빠르게 로그인할 수 있어요.'] as string[],
  TV_LOGIN: ['TVING ID 또는 CJ ONE ID 회원만', 'TV 리모컨으로 직접 입력하여 로그인할 수 있어요.'] as string[],
  ETC_LOGIN: ['TV에서 로그인은 TVING ID와 CJ ONE ID만 가능합니다.', '네이버, 카카오 등 그 외 계정은 스마트폰에서 로그인해주세요.'] as string[],
};

export enum TV_LOGIN_TYPE {
  CJONE_ID = '10', // cjone id 계정
  TVING_ID = '20', // tving id 계정
  OTHER = 'OTHER', // 그 외 계정
}

export const CUSTOMER_INFORMATION = {
  ID: '아이디를 잊으신 경우 tving@cj.net으로 문의 남겨주시면 빠르게 도움 드리겠습니다.',
  PASSWORD: '비밀번호를 잊으신 경우 모바일 앱 또는 PC에서 [비밀번호 찾기]를 통해 확인해주세요.',
};

export enum PROVISIONING_ERROR {
  PROVISIONING_6001_ERROR = '6001',
  PROVISIONING_6003_ERROR = '6003',
}

export enum BFF_API_ERROR {
  ERROR_500 = 'ERROR_500',
}

// 업셀링 팝업 캐시 유형 구분
export const UPSELLING_TYPE = {
  FREE: 'FREE',
  BASIC: 'BASIC',
  STANDARD: 'STANDARD',
  AVOD: 'AVOD',
};

// 가격인상 대상자 상태 코드
export const PRICE_INCREASE_TARGET_STATUS = {
  AGREED: '0000', // 가격인상 대상자가 동의한 경우
  NOT_AGREED: '2910', // 가격인상 대상자가 동의하지 않은 경우
  NOT_AGREED_MEMBERSHIP_CANCEL: '2913', // 가격인상 대상자가 동의하지 않은 상태로 이용권 해지 신청한 경우
  AGREED_MEMBERSHIP_CANCEL: '2914', // 가격인상 대상자가 동의한 상태로 이용권 해지 신청한 경우
  NOT_AGREED_TERMINATION: '2916', // 사후 동의 대상자가 동의하지 않은 상태로, 해지 예정인 경우
};

export const LAST_LOGIN_TYPE_VALUE = {
  TVING: '90',
  FACEBOOK: '91',
  TWITTER: '92',
  NAVER: '93',
  KAKAO: '94',
  APPLE: '96',
  CJONE: '10',
};

export enum LOGIN_RESULT_CODE {
  SUCCESS = '0000', // 성공
  DORMANT_ACCOUNT = '1025', // 휴먼 계정
  REQUIRED_AGREEMENT_TERMS = '1029', // 약관 동의 필요
  WRONG_ID_OR_PASSWORD = '1031', // 비밀번호 틀림 혹은 cust_id 가 DB에 없는 경우. 단, 실패 횟수가 5회 미만
  WRONG_PASSWORD_OVER_20 = '1060', // 비밀번호 틀림 혹은 cust_id 가 DB에 없는 경우. 단, 실패 횟수가 5회 미만
  TEMPORARY_ERROR = '1049', // 비밀번호 틀린 횟수가 5N + 1회이고 캡챠 토큰이 왔어야 했지만 오지 않음
  INVALID_RECAPTCHA_TOKEN = '1051', // 구글 리캡챠 토큰 검증 (실패)
  INVALID_RECAPTCHA_ACTION = '1052', // 구글 리캡챠 토큰 검증 (액션이 LOGIN 으로 가지 않음)
  SUSPECT_BOT = '1053', // 구글 리캡챠 점수 토큰 검증 후 봇으로 의심
  IDENTIFY_BOT = '1058', // 리캡챠 첼린지 검증 후 봇으로 확정
  RECAPTCHA_FAIL = '1054', // 구글 리캡챠 토큰 검증 (토큰 검증 후 실패 응답 받음)
  BLOCK_BOT = '1056', // 봇으로 판단 (custId, ip를 기반으로 블록된 상태)
}
