
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React, { useEffect, useRef, useState } from 'react';

import NextNprogress from 'nextjs-progressbar';

import Router, { useRouter } from 'next/router';

import { RouterScrollProvider } from '@moxy/next-router-scroll';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import CrossPlatformContext from '@utils/crossPlatform/react/CrossPlatformContext';
import dayjs from 'dayjs';
import { SWRConfig, useSWRConfig } from 'swr';

import 'swiper/css';
import 'simplebar-react/dist/simplebar.min.css';
import './_app.scss';
import fetchTtvBuffer from '@tving/utils/src/apis/ttvBuffer/fetchTtvBuffer';

import {
    MOBILE_PASS_URL_LIST,
    NOT_GIVEN_TOKEN_PAGE_LIST,
    PC_NO_LOGIN_USER_PASS_URL_LIST,
    URL_ONBOARDING,
    URL_ONBOARDING_SMARTTV,
    URL_PLAYER,
} from '@constants/urls';
import { pageOrigin, userOrigin } from '@utils/apiConfig';
import { getSelectorsByUserAgent, isAndroid, isDesktop, isIOS, isWebview } from '@utils/device/device-detect';

import '@components/Modal.css';

import useCalculateScrollbarWidth from '@utils/useCalculateScrollbarWidth';

import Layout, { StyledPlayerWrapper } from '../src/components/Layout';
import { RouteGuard } from '../src/components/RouteGuard';
import { useRouteChanged } from '../src/store/state';

import 'intl-pluralrules';
import 'intersection-observer';
import { checkSsrPage } from '@utils/checkSsrPage';

import Head from 'next/head';

import '../src/styles/global.css';
import fetchProvisioning from '@tving/utils/src/apis/provisioning/fetchProvisioning';
import proxyTtvBuffer from '@tving/utils/src/proxies/ttvBuffer/proxyTtvBuffer';
import proxyProvisioning from '@tving/utils/src/proxies/provisioning/proxyProvisioning';

import useProvisionStore, { initializeProvisionStore } from '../src/stores/useProvisionStore';

import { moveLogout, noSupportedRegionModal } from '@utils/common/UserApi';

import 'dayjs/locale/ko';
import useWebpStore from '@stores/useWebpStore';
import checkWebPSupport from '@utils/common/checkWebPSupport';
import httpGet from '@utils/common/httpGet';
import { getScreenName } from '@utils/items/utils';
import useClientStore from '@stores/useClientStore';

import { GATEWAY_URL } from '@tving/utils/src/utils/common/apiConfig';

import IntegrationManager from '@components/IntegrationManager';
import { isHttpCancelled } from '@utils/common/isHttpCancelled';
import KboDefaultLayout from '@layouts/kbo/defaultLayout/KboDefaultLayout';

import { shallow } from 'zustand/shallow';

import CrossPlatformManager from '@utils/crossPlatform/CrossPlatformManager';
import AndroidCrossPlatformController from '@utils/crossPlatform/platforms/android/AndroidCrossPlatformController';
import IosCrossPlatformController from '@utils/crossPlatform/platforms/ios/IosCrossPlatformController';
import WebCrossPlatformController from '@utils/crossPlatform/platforms/web/WebCrossPlatformController';
import { CrossPlatformType } from '@utils/crossPlatform';
import Player from '@components/Player';
import useNativeStore from '@stores/useNativeStore';

import useIsomorphicLayoutEffect from '@tving/utils/src/hooks/common/useIsomorphicLayoutEffect';

import AppInitializationProvider from '../src/providers/AppInitializationProvider';

import { DEFAULT_AF_PARAMETERS, OneLinkSmartScriptProvider } from '@tving/utils/src/utils/onelink-smart-script';
import forceNavigatorOnline from '@tving/utils/src/utils/network/forceNavigatorOnline';

import SportsLayout from '@layouts/sports/sportsLayout/SportsLayout';

import SportsProvider from '../src/providers/sports/SportsProvider';

import WebviewStyles from '@components/common/styles/WebviewStyles';
import MswWrapper from '@components/msw/MswWrapper';

import { PlaywrightAppProvider } from '@tving/playwright-utils/react';

const pcLginUserBlockPathArr = [URL_ONBOARDING];
export const DEFAULT_CACHE_TIME = 1000 * 60 * 3;
export const QUERY_CLIENT = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: DEFAULT_CACHE_TIME,
            cacheTime: DEFAULT_CACHE_TIME,
            retry: 2,
        },
    },
});

export const KBO_QUERY_CLIENT = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: DEFAULT_CACHE_TIME,
            cacheTime: DEFAULT_CACHE_TIME,
            retry: 2,
        },
    },
});

let crossPlatformController;
if (isWebview && isAndroid) {
    crossPlatformController = new AndroidCrossPlatformController();
} else if (isWebview && isIOS) {
    crossPlatformController = new IosCrossPlatformController();
} else {
    crossPlatformController = new WebCrossPlatformController();
}
const crossPlatformManager = new CrossPlatformManager(crossPlatformController);

if (typeof document !== 'undefined') {
    forceNavigatorOnline();
}

const MyApp = ({ Component, pageProps = {} } = {}) => {
    const { isWebviewDevice, device } = pageProps;
    if (typeof String.prototype.replaceAll === 'undefined') {
        // eslint-disable-next-line no-extend-native
        String.prototype.replaceAll = function (match, replace) {
            // eslint-disable-next-line react/no-this-in-sfc
            return this.replace(new RegExp(match, 'g'), () => replace);
        };
    }
    const router = useRouter();
    const provisioningErrorCode = pageProps.provisioningErrorCode || '';
    const { setIsWebview, setIsMobile, setIsMobileOnly, setIsTablet, setIsAndroid, setIsIOS, setIsDesktop } = useClientStore(
        (state) => ({
            setIsWebview: state.setIsWebview,
            setIsMobile: state.setIsMobile,
            setIsMobileOnly: state.setIsMobileOnly,
            setIsTablet: state.setIsTablet,
            setIsAndroid: state.setIsAndroid,
            setIsIOS: state.setIsIOS,
            setIsDesktop: state.setIsDesktop,
        }),
        shallow,
    );

    /**
     * TODO: Context API 기반으로 변경합니다.
     * - Next.js의 페이지 요청 단위로 UserAgent 기반 값을 초기화해야 합니다.
     * - 현재는 각 요청 기준 서버/클라이언트가 최초 1회 강제 리렌더링을 해야 합니다. (Context API로 변경 시 해결)
     *
     * TODO: 전역 Client Store가 Request 간 공유되지 않도록 합니다. (잠재적 이슈)
     * - Zustand 등 전역 스토어는 런타임 내에서 공유되므로 다른 요청에서도 이전 값이 사용될 수 있습니다. (따라서 매 요청마다 새로운 전역 스토어 인스턴스를 만들어야 합니다.)
     * - 참조: @see {@link https://docs.pmnd.rs/zustand/guides/nextjs#creating-a-store-per-request}
     */
    const isDeviceTypeInitialized = useRef(false);
    if (!isDeviceTypeInitialized.current) {
        const { isMobile, isMobileOnly, isTablet, isAndroid, isIOS, isDesktop } = getSelectorsByUserAgent(pageProps.userAgent) || {
            isMobile: false,
            isMobileOnly: false,
            isTablet: false,
            isAndroid: false,
            isIOS: false,
            isDesktop: false,
        };

        setIsWebview(isWebviewDevice);
        setIsMobile(isMobile);
        setIsMobileOnly(isMobileOnly);
        setIsTablet(isTablet);
        setIsAndroid(isAndroid);
        setIsIOS(isIOS);
        setIsDesktop(isDesktop);
        isDeviceTypeInitialized.current = true;
    }

    useEffect(() => {
        if (provisioningErrorCode === '6001' && !window.location.href.includes('/6001')) {
            noSupportedRegionModal();
        } else if (provisioningErrorCode === '6003') {
            // 500 에러 페이지 노출
            router.push('/500');
        }
    }, [provisioningErrorCode]);

    const setProvisionResponse = useProvisionStore((state) => state.setProvisionResponse);

    const setIsSupportsWebP = useWebpStore((state) => state.setIsSupportsWebP);

    useIsomorphicLayoutEffect(() => {
        checkWebPSupport(setIsSupportsWebP);
    }, []);

    useEffect(() => {
        setProvisionResponse(pageProps.provisionRes);
    }, [pageProps.provisionRes]);

    const { token: ssrToken, userInfoData, userInfoProfileData, userInfoValidTicketData, userRegionCode } = pageProps.userInfo || {};
    const [clientUA, setClientUA] = useState('');

    const targetUa = clientUA || pageProps.userAgent;
    const userAgent = targetUa ? getSelectorsByUserAgent(targetUa) : {};
    const [logoImg] = useState(pageProps.logoImgUrl);

    useCalculateScrollbarWidth();

    const { cache } = useSWRConfig();

    useEffect(() => {
        console.log('#swr', cache);
    }, [cache]);

    useEffect(() => {
        if (!ssrToken) {
            try {
                window.localStorage.removeItem('tving-player-subtitle-font-size');
            } catch (e) {
                console.error(e);
            }
        }
    }, [ssrToken]);

    const [, setRouteChanged] = useRouteChanged();

    // Mounted
    useIsomorphicLayoutEffect(() => {
        // UA.
        setClientUA(navigator?.userAgent);
        const ua = navigator?.userAgent ? getSelectorsByUserAgent(navigator?.userAgent) : {};
        const { isMobile, isIOS, isAndroid } = ua || {};

        const handleRouteChange = async () => {
            setRouteChanged(true);
            return true;
        };

        router.events.on('routeChangeStart', handleRouteChange);

        document.querySelector('html').classList.remove('is-laptop', 'is-mobile');
        document.querySelector('html').classList.add(isMobile ? 'is-mobile' : 'is-laptop');

        if (isIOS) {
            document.querySelector('html').classList.add('is-ios');
        }

        if (isAndroid) {
            document.querySelector('html').classList.add('is-android');
        }

        // Unmounted
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, []);
    dayjs.locale('ko');

    const head = (
        <Head>
            <title>TVING</title>
            <link rel="icon" href="/favicon.ico" />
            <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
            />
        </Head>
    );

    if (crossPlatformManager.crossPlatformController.getCrossPlatformType() === CrossPlatformType.WEB) {
        crossPlatformManager.crossPlatformController.router = router;
    }

    const [mainClassName, setMainclassName] = useState([]);
    const [layoutRef, setLayoutRef] = useState({ current: null });
    useEffect(() => {
        if (isDesktop) {
            setMainclassName(['is-desktop']);
        }
        setLayoutRef({ current: document.querySelector('body') });
    }, []);

    const { setAppConfiguration } = useNativeStore(
        (state) => ({
            setAppConfiguration: state.setAppConfiguration,
        }),
        shallow,
    );

    useEffect(() => {
        const handleAppConfigurationInfo = (event) => {
            const { detail } = event;
            setAppConfiguration(detail);
        };
        crossPlatformController.getInitAppConfigurationInfo();
        window.addEventListener('onInitAppConfigurationInfo', handleAppConfigurationInfo);

        return () => {
            window.removeEventListener('onInitAppConfigurationInfo', handleAppConfigurationInfo);
        };
    }, [setAppConfiguration]);

    const isKbo = router.pathname.startsWith('/kbo');
    const sportsPatterns = [/^\/sports\/.*/, /^\/contents\/sports\/.*/];
    const isSports = sportsPatterns.some((pattern) => pattern.test(router.pathname));
    const isLiveChannelPlayerPage = router.pathname.startsWith(URL_PLAYER) && router.query?.slug?.startsWith('C');

    return (
        <PlaywrightAppProvider>
            <QueryClientProvider client={!isKbo && !isSports ? QUERY_CLIENT : KBO_QUERY_CLIENT}>
                <SWRConfig
                    value={{
                        focusThrottleInterval: 1000 * 60 * 3,
                        errorRetryCount: 1,
                        dedupingInterval: 1000 * 60,
                        // fetcher: (resource, init) => fetch(resource, init).then(res => res.json()),
                    }}
                >
                    <CrossPlatformContext.Provider value={crossPlatformManager}>
                        <AppInitializationProvider>
                            <MswWrapper />
                            <OneLinkSmartScriptProvider
                                configs={{
                                    oneLinkUrl: process.env.NEXT_PUBLIC_APPSFLYER_ONELINK_URL,
                                    afParameters: DEFAULT_AF_PARAMETERS,
                                }}
                            >
                                <StyledPlayerWrapper
                                    isLiveChannelPlayerPage={isLiveChannelPlayerPage}
                                    className={`layout-player-main ${mainClassName.join(' ')}`}
                                >
                                    {!device?.isMobileWeb && !device?.isMobileWebview
                                        ? !router.pathname.includes('not-found') && <Player layoutRef={layoutRef} />
                                        : null}
                                </StyledPlayerWrapper>

                                {!isKbo && !isSports ? (
                                    <RouteGuard>
                                        <IntegrationManager />
                                        <Layout
                                            ssrToken={ssrToken}
                                            userInfoData={userInfoData}
                                            userInfoProfileData={userInfoProfileData}
                                            userInfoValidTicketData={userInfoValidTicketData}
                                            userAgent={userAgent}
                                            logoImgUrl={logoImg}
                                            regionCode={userRegionCode}
                                        >
                                            {!isWebviewDevice ? (
                                                <NextNprogress color="#ff153c" startPosition={0.3} stopDelayMs={200} height={3} showOnShallow />
                                            ) : null}
                                            {/* 각 페이지 컴포넌트에 props 추가 */}
                                            <RouterScrollProvider>
                                                {/* 공통 Head */}
                                                {head}
                                                <Hydrate state={pageProps.dehydratedState}>
                                                    <Component {...pageProps} userAgent={userAgent} />
                                                </Hydrate>
                                            </RouterScrollProvider>
                                        </Layout>
                                    </RouteGuard>
                                ) : null}

                                {isKbo ? (
                                    <>
                                        <IntegrationManager />
                                        <KboDefaultLayout
                                            ssrToken={ssrToken}
                                            userInfoData={userInfoData}
                                            userInfoProfileData={userInfoProfileData}
                                            userInfoValidTicketData={userInfoValidTicketData}
                                            userAgent={userAgent}
                                            logoImgUrl={logoImg}
                                            regionCode={userRegionCode}
                                            isWebview={isWebviewDevice}
                                            device={device || {}}
                                        >
                                            {!isWebviewDevice ? (
                                                <NextNprogress color="#ff153c" startPosition={0.3} stopDelayMs={200} height={3} showOnShallow />
                                            ) : null}
                                            {/* 각 페이지 컴포넌트에 props 추가 */}
                                            <RouterScrollProvider>
                                                {/* 공통 Head */}
                                                {head}
                                                <Hydrate state={pageProps.dehydratedState}>
                                                    <>
                                                        {isWebviewDevice ? (
                                                            // eslint-disable-next-line react/no-unknown-property
                                                            <style jsx global>
                                                                {`
                                                                    html,
                                                                    body a,
                                                                    button,
                                                                    dd,
                                                                    div,
                                                                    dl,
                                                                    dt,
                                                                    html,
                                                                    input,
                                                                    label,
                                                                    li,
                                                                    p,
                                                                    span,
                                                                    textarea,
                                                                    ul,
                                                                    th,
                                                                    td {
                                                                        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
                                                                            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
                                                                    }
                                                                `}
                                                            </style>
                                                        ) : null}
                                                        <Component {...pageProps} userAgent={userAgent} />
                                                    </>
                                                </Hydrate>
                                            </RouterScrollProvider>
                                        </KboDefaultLayout>
                                    </>
                                ) : null}

                                {isSports ? (
                                    <SportsProvider
                                        ssrToken={ssrToken}
                                        userInfoData={userInfoData}
                                        userInfoProfileData={userInfoProfileData}
                                        userInfoValidTicketData={userInfoValidTicketData}
                                        userAgent={userAgent}
                                        logoImgUrl={logoImg}
                                        userRegionCode={userRegionCode}
                                        isWebview={isWebviewDevice}
                                        device={device || {}}
                                    >
                                        <IntegrationManager />
                                        <SportsLayout>
                                            {/* 각 페이지 컴포넌트에 props 추가 */}
                                            <RouterScrollProvider>
                                                {/* 공통 Head */}
                                                {head}
                                                <WebviewStyles isWebview={isWebviewDevice} />
                                                <Hydrate state={pageProps.dehydratedState}>
                                                    <Component {...pageProps} userAgent={userAgent} />
                                                </Hydrate>
                                            </RouterScrollProvider>
                                        </SportsLayout>
                                    </SportsProvider>
                                ) : null}
                            </OneLinkSmartScriptProvider>
                        </AppInitializationProvider>
                    </CrossPlatformContext.Provider>
                </SWRConfig>
                <ReactQueryDevtools initialIsOpen={false} position="top-left" />
            </QueryClientProvider>
        </PlaywrightAppProvider>
    );
};

// https://maxschmitt.me/posts/next-js-redirects/
const redirectToUrl = async ({ ctx, url = '' }) => {
    if (ctx?.req && ctx?.res?.writeHead) {
        ctx.res.writeHead(302, { Location: url });
        ctx.res.end();
    } else if (url.includes('https:') || url.includes('http:')) {
        window.location = url;
        await new Promise(() => {});
    } else {
        Router.push(url);
    }
};

// App SSR을 위한 부분.
MyApp.getInitialProps = async ({ Component, ctx, router }) => {
    // let isPasswordChanged = false;
    let pageProps = {};
    let isFirstSSR = false;
    let userAgent;
    let cookies = {};

    const {
        pathname,
        asPath: _asPath,
        query: { movePage, viewType },
    } = ctx || {};
    const asPath = _asPath?.split('?')[0];

    // SSR/CSR 분기
    if (!ctx?.req) {
        userAgent = window?.navigator?.userAgent;
        try {
            cookies = document.cookie.split(';').reduce((res, c) => {
                const [key, val] = c.trim().split('=').map(decodeURIComponent);
                try {
                    return Object.assign(res, { [key]: JSON.parse(val) });
                } catch (e) {
                    return Object.assign(res, { [key]: val });
                }
            }, {});
        } catch (e) {
            console.log(e);
        }
    } else {
        isFirstSSR = !ctx.req?.url.includes('/_next/');
        cookies = ctx?.req?.cookies || {};
        userAgent = ctx?.req?.headers['user-agent'];

        try {
            if (viewType === 'webview' && ctx?.res?.setHeader) {
                ctx.res.setHeader('Set-Cookie', 'view_type= webview;');
            }
        } catch (e) {
            console.error('viewType cookie set error', e);
        }
    }

    // TODO: 수정 필요. 랜더링 프로세스 강제 종료로 인한 임시 주석,
    // - 예상 원인: 메모리 부족
    // if (router.pathname.startsWith('/sports/')) {
    //     const isInvalidPath = !Object.values(SUB_NAVIGATION_DATA).some((sportData) =>
    //         sportData.sectionRoutes.some((route) => {
    //             if (router.asPath === route.path) {
    //                 return true;
    //             }
    //             return false;
    //         }),
    //     );
    //
    //     if (isInvalidPath) {
    //         redirectToUrl({
    //             ctx,
    //             url: '/404',
    //         });
    //     }
    // }

    const {
        _tving_token: token,
        TP2wgas1K9Q8F7B359108383: profileToken,
        MASTER_PROFILE_LOCK_YN: masterLockYn,
        authToken,
        accessToken,
        refreshToken,
        view_type: cookieViewType,
    } = cookies || {};

    const isViewTypeWebview = viewType === 'webview' || cookieViewType === 'webview';
    const isWebviewDevice = /TVING/i.test(userAgent) || isViewTypeWebview || false;

    // 로그인 가능 여부에 대해서 기존 token 만 비교하지 않고 rt, at, authToken도 비교
    let isPossibleLogin = token;
    if (NOT_GIVEN_TOKEN_PAGE_LIST.includes(router.pathname)) {
        isPossibleLogin = token && (refreshToken || accessToken); // 변경
    }

    if (isPossibleLogin && isWebviewDevice && !accessToken && !refreshToken) {
        moveLogout({ serverRes: ctx?.res || null, origin: pageOrigin });
        return false;
    }

    try {
        const { isTtvOn, ttvUrl, isBufferOn, bufferUrl } = proxyTtvBuffer(await fetchTtvBuffer({ poc: 'web' }));
        // TTV, 범퍼 페이지 체크
        if (isTtvOn) {
            redirectToUrl({ ctx, url: ttvUrl });
            return false;
        }

        // 버퍼 모드 체크 후 범퍼페이지로 이동
        if (isBufferOn) {
            if (
                !(
                    cookies.BUFFER === 'BUFFER' ||
                    router.pathname.startsWith('/stream') ||
                    router.pathname.startsWith('/lg/ma') ||
                    router.pathname.startsWith('/sm/fh/')
                )
            ) {
                redirectToUrl({ ctx, url: bufferUrl });
                return false;
            }
        }
    } catch {
        //
    }

    // UserAgent Mobile Checking.
    const { isMobile, isSmartTV, isAndroid, isTablet } = userAgent ? getSelectorsByUserAgent(userAgent || '') || {} : {};
    if (isSmartTV && router.pathname !== URL_ONBOARDING_SMARTTV) {
        redirectToUrl({ ctx, url: URL_ONBOARDING_SMARTTV });
        return false;
    }

    // console.log('#SSR getInitialProps', { isSSR, isMobile }, pathname, asPath);
    if (isMobile) {
        if (!MOBILE_PASS_URL_LIST.includes(router.pathname) && MOBILE_PASS_URL_LIST.filter((path) => asPath.includes(path)).length === 0) {
            redirectToUrl({ ctx, url: URL_ONBOARDING });
            return false;
        }
    }

    // LoginToken 체크해 리다이렉트. 비로그인 리다이렉트.
    if (!isPossibleLogin) {
        const isError = ctx?.res?.statusCode >= 400;
        if (!isError) {
            if (movePage && movePage !== '') {
                // ex. movePage : %2Flist%2Fprogram%3Fslug%3Dprogram%26order%3DbroadDate
                await redirectToUrl({
                    ctx,
                    url: `${pageOrigin}/account/login?returnUrl=${encodeURIComponent(pageOrigin + decodeURIComponent(movePage))}`,
                });
                return false;
            }

            let isSsrPage = false;
            isSsrPage = checkSsrPage(asPath);

            if (
                !PC_NO_LOGIN_USER_PASS_URL_LIST.includes(pathname) &&
                PC_NO_LOGIN_USER_PASS_URL_LIST.filter((path) => asPath.includes(path)).length === 0 &&
                !isSsrPage
            ) {
                redirectToUrl({ ctx, url: URL_ONBOARDING });
                return false;
            }
        }
    } else if (!profileToken && masterLockYn === 'Y' && !asPath.includes('/billing/billCommand')) {
        // tving 토큰이 존재하지만 프로필토큰이 없고 마스터 프로필이 비밀번호 설정되어 있는 경우 프로필선택 페이지로 이동
        await redirectToUrl({
            ctx,
            url: `${userOrigin}/pc/user/profiles.tving?returnUrl=${encodeURIComponent(pageOrigin + _asPath)}`,
        });
        return false;
    } else if (movePage && movePage !== '') {
        redirectToUrl({ ctx, url: `${decodeURIComponent(movePage)}` });
        return false;
    }

    const userInfoData = null;
    const userInfoProfileData = null;
    const userInfoValidTicketData = null;
    let tvingLogoImgUrl = '';
    let provisionRes = null;
    let provisioningErrorCode = '';
    let boardApiResponse = null;
    let userRegionCode = '';
    let boardApiKey = '';

    // SSR 처음 진입인 경우 리전코드 및 프로비저닝 호출
    if (isFirstSSR && isPossibleLogin) {
        const { setRegionCode } = useClientStore.getState();
        // region code 조회
        // console.log('ctx?.req?.headers', ctx?.req?.headers);

        const regionCodeResult = await httpGet(
            `${GATEWAY_URL}/cust/v/v1/region-code`,
            {
                serverCookies: cookies,
                serverRes: ctx?.res,
                serverReq: ctx?.req,
                origin: `https://${ctx?.req?.headers?.host}` || '',
                headers: {
                    'Access-Token': accessToken || '',
                    Authorization: `Bearer ${token || ''}`,
                },
            },
            pathname,
        );

        setRegionCode(regionCodeResult?.data?.regionCode);
        userRegionCode = regionCodeResult?.data?.regionCode ?? '';

        // 프로비저닝 호출
        try {
            provisionRes = proxyProvisioning(
                await fetchProvisioning(
                    { poc: 'web', region: userRegionCode || '' },
                    {
                        // headers: {
                        //     'Tving-X-Forwarded-For': ctx?.req?.headers['x-forwarded-for'] || '',
                        // },
                        serverCookies: cookies,
                        serverRes: ctx?.res,
                        serverReq: ctx?.req,
                        origin: `https://${ctx?.req?.headers?.host}` || '',
                    },
                ),
            );
            initializeProvisionStore(provisionRes);

            tvingLogoImgUrl = provisionRes?.logoImgUrl;

            // await fetchCheckAccessToken({ tvingToken: token, accessToken: cookies?.accessToken || '' });

            const { baseUrl, topMenu } = provisionRes;
            const boardType = getScreenName({ pathName: pathname })?.toUpperCase();
            const boardApi = topMenu?.find((menu) => menu?.type === boardType);
            boardApiKey = `${baseUrl}${boardApi?.uri ?? 'HOME'}`;

            if (boardType === 'HOME') {
                await QUERY_CLIENT.prefetchQuery([`${baseUrl}${boardApi?.uri}`], async () => {
                    const apiResponse = await httpGet(
                        `${baseUrl}${boardApi?.uri}`,
                        {
                            serverCookies: cookies,
                            serverRes: ctx?.res,
                            serverReq: ctx?.req,
                            origin: `https://${ctx?.req?.headers?.host}` || '',
                            headers: {
                                // 'Tving-X-Forwarded-For': ctx?.req?.headers['x-forwarded-for'] || '',
                                'Auth-Token': authToken || '',
                                'Access-Token': cookies?.accessToken || '',
                                // eslint-disable-next-line no-underscore-dangle
                                Authorization: `Bearer ${cookies?._tving_token || ''}`,
                                region: userRegionCode || '',
                            },
                        },
                        {
                            withCredentials: true,
                        },
                    )
                        .then((res) => {
                            const bands = res?.data?.bands?.map((band, idx) => {
                                return {
                                    idx,
                                    ...band,
                                };
                            });
                            return bands;
                        })
                        .catch((error) => {
                            if (!isHttpCancelled(error)) {
                                router.push('/500');
                            }
                        });
                    boardApiResponse = apiResponse;
                });
            }
        } catch (error) {
            provisioningErrorCode = error?.response?.data?.code || '';
        }
    }

    // 로그인 유저 리다이렉트
    // 현재는 PC만
    if (isPossibleLogin && !isMobile && !isSmartTV) {
        if (pcLginUserBlockPathArr?.includes(pathname) || pcLginUserBlockPathArr.filter((path) => asPath?.includes(path)).length > 0) {
            const params = _asPath?.split('?')[1] || '';
            const isUtmParams = params.includes('utm_source');
            if (!isUtmParams) {
                redirectToUrl({ ctx, url: '/' });
            } else {
                redirectToUrl({ ctx, url: `/?${params}` });
            }
            return false;
        }
    }

    // 하위 컴포넌트에 getInitialProps가 있다면 추가 (각 개별 컴포넌트에서 사용할 값 추가)
    if (Component?.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
    }

    console.log('#SSR', '#end..', pathname, asPath);

    // _app에서 props 추가 (모든 컴포넌트에서 공통적으로 사용할 값 추가)
    const obj = {
        userInfo: {
            token,
            authToken,
            accessToken,
            refreshToken,
            userInfoData,
            userInfoProfileData,
            userInfoValidTicketData,
            userRegionCode,
        },
        userAgent,
        logoImgUrl: tvingLogoImgUrl,
        provisionRes,
        provisioningErrorCode,
        boardApiResponse,
        boardApiKey,
        isWebviewDevice,
        device: {
            isMobileWebview: isWebviewDevice,
            isMobileWeb: isMobile || isWebviewDevice,
            isWebview: isWebviewDevice,
            isMobile,
            isAndroid,
            isTablet,
        },
    };
    pageProps = { ...pageProps, ...obj };
    return { pageProps };
};

const __Page_Next_Translate__ = MyApp;

// Web Vitals are a set of useful metrics that aim to capture the user experience of a web page. The following web vitals are all included:
export function reportWebVitals(metric) {
    console.log(metric); // The metric object ({ id, name, startTime, value, label }) is logged to the console
}


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  