import Router from 'next/router';
import { overlay } from 'overlay-kit';
import { create } from 'zustand';

import generateMergedConfig from './helper/generateMergedConfig';
import { type OverlayKitInstance, OverlayKitIntegrationOptions } from './types';

const AUTO_DESTROY_DELAY = 1000 * 5;

type OverlayKitIntegrationStore = {
  instances: Map<string, OverlayKitInstance>;
  addInstance: (overlayId: string, config?: OverlayKitIntegrationOptions) => void;
  closeInstance: (overlayId: string) => void;
  destroyInstance: (overlayId: string) => void;
  getInstance: (overlayId: string) => OverlayKitInstance | undefined;
  getAllInstances: () => Map<string, OverlayKitInstance>;
};

const useOverlayKitIntegration = create<OverlayKitIntegrationStore>()((set, get) => ({
  instances: new Map<string, OverlayKitInstance>(),
  addInstance: (overlayId, config) => {
    const { instances } = get();

    set(() => ({
      instances: new Map(instances).set(overlayId, {
        overlayId,
        config: {
          ...generateMergedConfig(config),
          routerSnapshot: {
            asPath: Router.asPath,
            pathname: Router.pathname,
            query: Router.query,
          },
        },
      }),
    }));
  },
  closeInstance: (overlayId) => {
    const { instances, destroyInstance } = get();

    if (!instances.has(overlayId)) {
      return;
    }

    overlay.close(overlayId);

    setTimeout(
      () => {
        destroyInstance(overlayId);
      },
      // 임의의 충분한 시간을 주고 파괴합니다.
      AUTO_DESTROY_DELAY,
    );
  },
  destroyInstance: (overlayId) => {
    const { instances } = get();

    if (!instances.has(overlayId)) {
      return;
    }

    instances.delete(overlayId);
    overlay.close(overlayId);
    overlay.unmount(overlayId); // unmount하지 않으면 useOverlayData에 객체가 남아있어 메모리 누수가 발생할 수 있습니다.

    set(() => ({
      instances: new Map(instances),
    }));
  },
  getInstance: (overlayId) => {
    const { instances } = get();
    return instances.get(overlayId);
  },
  getAllInstances: () => {
    const { instances } = get();
    return new Map(instances);
  },
}));

export default useOverlayKitIntegration;
