import Link from 'next/link';
import { ThumbnailImage } from '@components/item/ItemComponent';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';

const TvingMallItem = ({
    href,
    idx,
    type,
    bandType,
    ratio,
    title,
    thumbnail,
    isLazy,
    originalPrice, // 정가
    saleRate, // 할인율
    salePrice, // 할인가
    couponRate, // 쿠폰 할인율
    couponPrice, // 쿠폰가
    onClick,

    /*
    {
        id: 14040,
        name: '[마이시티 MYCT][강식당2] 신서유기 핀브로치(파르페, 콜라, 피자)',
        brand: '',
        original_price: 15000,
        sale_price: 7500,
        sale_rate: 50,
        coupon_price: 0,
        coupon_rate: 100,
        tags: [],
        keyword: [
            '강식당굿즈',
            '신서유기굿즈',
            '마이시티',
            'MYCT',
            '강식당2',
            '신서유기',
            '핀브로치',
            '마이시티MYCT강식당2신서유기핀브로치',
            '피자',
            '규현',
            '착용',
            '출시.',
            '멤버들의',
            '개성이',
            '담겨있는',
            '뱃지입니다.',
            '피자규현착용출시.강식당2멤버들의개성이담겨있는뱃지입니다.',
            '14040',
            '신서유기뱃지',
            '신서유기캐릭터굿즈',
            '신묘한뱃지',
            '강식당신묘한',
        ],
        image_url: 'https://mall-image.tving.com/media/image/goods/2020/03/14040_tmp_15629161721878list1@335x335.jpg',
        tvingmall_url: 'https://mall.tving.com/goods/view/14040',
    },

     */
}) => {
    const { t } = useTranslation('common');
    ratio = '1x1';
    const ratioClassName = ratio ? `item__thumb-${ratio}` : '';

    // 테스트 데이터!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!1
    // title = '[마이시티 MYCT][강식당2] 신서유기 핀브로치(파르페, 콜라, 피자)';
    // thumbnail = 'https://mall-image.tving.com/media/image/goods/2020/03/16134_tmp_15698371192776list1@335x335.jpg';
    href = `https://mallapi.tving.com/member/login?redirect_url=${href}`;
    originalPrice += t('unit.price.won');
    // saleRate = 50;
    salePrice += t('unit.price.won');
    // couponRate = 70;
    couponPrice += t('unit.price.won');

    return (
        <Link scroll href={href} passHref>
            <a className="thum_position" target="_blank" rel="noreferrer" onClick={onClick}>
                <div className={`item__thumb ${ratioClassName}`}>
                    {/* 컨텐츠(포스터) */}
                    <ThumbnailImage thumbnail={thumbnail} title={title} isLazy={isLazy} />
                </div>
                <div className="item__info">
                    <p className="item__title item__line-2">{title}</p>

                    <div className="item__subtitle__row layout__flex-left">
                        {saleRate && saleRate !== 0 ? (
                            <>
                                <p className="item__subtitle info-cancle">{originalPrice}</p>
                                <p className="item__subtitle">{salePrice}</p>
                                <p className="item__subtitle red">{saleRate}%</p>
                            </>
                        ) : (
                            <p className="item__subtitle">{originalPrice}</p>
                        )}
                    </div>
                    {couponRate && couponRate !== 100 && (
                        <div className="item__subtitle__row layout__flex-left">
                            <p className="item__subtitle red">{t('my.coupon_price')}</p>
                            <p className="item__subtitle">{couponPrice}</p>
                            <p className="item__subtitle red">{couponRate}%</p>
                        </div>
                    )}
                </div>
            </a>
        </Link>
    );
};
export default TvingMallItem;
