import { CSSProperties } from 'react';

import { RANKING_IMAGE } from '../../constants/rankingConstants';

export interface RankingProps {
  type: 'PNG' | 'SVG';
  className?: string;
  number?: number;
  tabIndex?: number;
  style?: CSSProperties;
}
const Ranking = ({ type, number, tabIndex, className, style }: RankingProps) => {
  const RankingImage = type === 'SVG' && number ? RANKING_IMAGE[`RANKING${number}`].SVG : RANKING_IMAGE[`RANKING${number}`].PNG;
  return (
    <div className={`atom-ranking-wrapper ${className}`} style={{ ...style }}>
      {type === 'SVG' && <RankingImage />}
      {type === 'PNG' && <img src={RankingImage} tabIndex={tabIndex} alt={`랭킹_${number}`} />}
    </div>
  );
};

export default Ranking;
