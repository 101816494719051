'use client';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { PAGE_URL } from '@tving/utils/src/utils/common/apiConfig';

import FaceBookIcon from '../../images/icons/share-facebook.svg';
import InstagramIcon from '../../images/icons/share-instagram.svg';
import TwitterIcon from '../../images/icons/share-twitter.svg';
import YouTubeIcon from '../../images/icons/share-youtube.svg';
import { cn } from '../../lib/utils';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from '../ui/select';

export const BRAND_OPTIONS = [
  { value: 'https://tvn.cjenm.com/ko/', label: 'tvN' },
  { value: 'https://tvnsports.cjenm.com/ko/', label: 'tvN SPORTS' },
  { value: 'https://tvn.cjenm.com/ko/tvNstory-main/', label: 'tvN STORY' },
  { value: 'https://tvn.cjenm.com/ko/tvNDRAMA_main/', label: 'tvN DRAMA' },
  { value: 'https://tvn.cjenm.com/ko/tvNshow-main/', label: 'tvN SHOW' },
  { value: 'https://ocn.cjenm.com/ko/', label: 'OCN' },
  { value: 'https://zhtv.cjenm.com/ko/', label: '중화TV' },
  { value: 'https://uxn.cjenm.com/ko/', label: 'UXN' },
  { value: 'https://catchon.cjenm.com/ko/', label: 'CATCHON' },
  { value: 'https://tooniverse.cjenm.com/ko/', label: 'Tooniverse' },
];

export const CJ_GROUP_OPTIONS = [
  {
    value: 'http://www.cj.net',
    label: 'CJ주식회사',
  },
  {
    value: 'http://www.cjenm.com',
    label: 'CJ ENM 엔터테인먼트부문',
  },
  {
    value: 'http://www.cgv.co.kr',
    label: 'CJ CGV',
  },
  {
    value: 'http://www.cj.co.kr',
    label: 'CJ제일제당(식품)',
  },
  {
    value: 'http://www.cjfreshway.com',
    label: 'CJ프레시웨이',
  },
  {
    value: 'http://www.cjfoodville.co.kr',
    label: 'CJ푸드빌',
  },
  {
    value: 'http://www.md1.co.kr',
    label: 'CJ엠디원',
  },
  {
    value: 'https://www.cj.co.kr/kr/about/business/bio',
    label: 'CJ제일제당(바이오)',
  },
  {
    value: 'http://display.cjmall.com',
    label: 'CJ ENM 커머스부문',
  },
  {
    value: 'http://www.cjkoreaexpress.co.kr',
    label: 'CJ대한통운',
  },
  {
    value: 'http://www.cjtelenix.com',
    label: 'CJ텔레닉스',
  },
  {
    value: 'http://www.oliveyoung.co.kr/store/main/main.do',
    label: 'CJ올리브영',
  },
  {
    value: 'http://www.cjenc.co.kr',
    label: 'CJ대한통운(건설)',
  },
  {
    value: 'http://www.cjolivenetworks.co.kr/itbusiness',
    label: 'CJ올리브네트웍스',
  },
];

export const DAFAULT_STYLES = {
  commonTextColor: 'text-[#6e6e6e]',
  linkHoverColor: 'hover:text-[#dedede] focus:text-[#dedede]',
  footerUtilColor: 'text-[rgba(163,163,163,1)]',
  commonBeforeStyles:
    'relative pl-[0.75rem] pr-[0.5rem] before:content-[""] before:absolute before:top-1/2 before:left-0 before:w-px before:h-[11px] before:mt-[-5px] before:bg-[rgba(46,46,46,1)]',
  copyrightBoxHoverColor: 'hover:text-[#a3a3a3] focus:text-[#a3a3a3] focus:underline',
  mobilePadding: 'pt-[3.333rem] pb-[5rem]',
  desktopPadding: 'pt-[4rem] pb-[4rem]',
};

export const DEFAULT_ACTIONS = {
  openBusinessInfo: () => {
    window.open(
      'http://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=1888801893',
      'PC_SERVICEPROVIDER_POPUP',
      'scrollbars=no,menubar=no,control=no,resizable=no,width=550,height=700,top=100,left=0',
    );
  },
};

const openTvingYoutube = () => {
  window.open('https://www.youtube.com/c/TVING_official', '_blank', 'noreferrer');
};
const openTvingInstagram = () => {
  window.open('https://www.instagram.com/tving.official/', '_blank', 'noreferrer');
};
const openTvingTwitter = () => {
  window.open('https://twitter.com/tvingdotcom', '_blank', 'noreferrer');
};
const openTvingFacebook = () => {
  window.open('https://www.facebook.com/CJTVING/', '_blank', 'noreferrer');
};
const SnsIcons = ({ className }: { className?: string }) => {
  const svgStyle =
    'w-[3rem] group-hover:[&>g>*]:fill-[#ffffffa3] group-focus:[&>g>*]:fill-[#ffffffa3] group-hover:[&>g>g>*]:fill-[#ffffffa3] group-focus:[&>g>g>*]:fill-[#ffffffa3] group-hover:[&>g>rect]:fill-[#ffffff2e] group-focus:[&>g>rect]:fill-[#ffffff2e] [&>g>path]:transition-all [&>g>rect]:transition-all';

  return (
    <div className={cn(`flex w-[100%]`, className)}>
      <div className="flex gap-[1rem] w-[15rem]">
        <a
          className="cursor-pointer group"
          onClick={openTvingYoutube}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              openTvingYoutube();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <YouTubeIcon className={svgStyle} />
        </a>
        <a
          className="cursor-pointer group"
          onClick={openTvingInstagram}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              openTvingInstagram();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <InstagramIcon className={svgStyle} />
        </a>
        <a
          className="cursor-pointer group"
          onClick={openTvingTwitter}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              openTvingTwitter();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <TwitterIcon className={svgStyle} />
        </a>
        <a
          className="cursor-pointer group"
          onClick={openTvingFacebook}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              openTvingFacebook();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <FaceBookIcon className={svgStyle} />
        </a>
      </div>
    </div>
  );
};

const FooterLink = ({ href, className, text, onClick, external }: { href?: string; className: string; text; onClick?; external?: boolean }) => (
  <span className="mr-[1.667rem] cursor-pointer">
    <a
      className={className}
      href={href}
      onClick={onClick}
      target={external ? '_blank' : undefined}
      rel={external ? 'noreferrer' : undefined}
      tabIndex={0}
    >
      {text}
    </a>
  </span>
);

const FooterInfoLine = ({ infos, commonBeforeStyles }) => (
  <p className="mb-[0.55rem] flex flex-wrap items-center">
    {infos.map((info, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <span key={index} className={`${index !== 0 ? commonBeforeStyles : 'pr-[0.5rem]'}`}>
        {info.href || info.onClick ? (
          <a
            className={info.className}
            tabIndex={0}
            href={info.href}
            onClick={info.onClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                info.onClick();
              }
            }}
          >
            {info.text}
          </a>
        ) : (
          info.text
        )}
      </span>
    ))}
  </p>
);

const FooterSelect = ({
  options,
  placeholder = '',
  selectedValue,
  setSelectedValue,
}: {
  options: { value; label }[];
  placeholder: string;
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<string>>;
}) => (
  <Select
    value={selectedValue}
    onValueChange={(value) => {
      setSelectedValue(value);
    }}
  >
    <SelectTrigger className="flex items-center w-auto h-auto whitespace-nowrap border-0 py-0" asChild>
      <>
        <span>{placeholder}</span>
        <span className="ml-[1vw] mr-1">+</span>
      </>
    </SelectTrigger>
    <SelectContent position="popper" side="top" sideOffset={5}>
      <SelectGroup>
        {options.map(({ value, label }) => {
          return (
            <SelectItem
              value={value}
              key={label}
              className="rounded-none t focus-visible:ring-2 focus:text-current hover:focus:bg-accent hover:focus:text-accent-foreground focus:visible cursor-pointer"
              data-value={value}
            >
              {label}
            </SelectItem>
          );
        })}
      </SelectGroup>
    </SelectContent>
  </Select>
);

const urls = {
  help: `${PAGE_URL}/help`,
  qna: `${PAGE_URL}/help/qna?movePage=/help/qna`,
  policy: {
    terms: `${PAGE_URL}/policy/terms`,
    privacy: `${PAGE_URL}/policy/privacy`,
    youth: `${PAGE_URL}/policy/youth`,
    legal: `${PAGE_URL}/policy/legal`,
    careers: 'http://team.tving.com',
  },
  event: `${PAGE_URL}/event/list?movePage=/event/list`,
};

const SimpleFooter = ({ className = '' }: { className?: string }) => {
  const styles = DAFAULT_STYLES;
  return (
    <footer className={cn(`relative layout-container pt-[3.333rem] pb-[5rem] text-[1.25rem] ${styles.commonTextColor} `, className)}>
      <section>
        <div className="relative">
          <SnsIcons className="justify-center" />
          <nav className={`flex items-start pt-[1.5rem] ${styles.footerUtilColor}`}>
            <div className="flex flex-wrap items-center justify-center w-full text-[0.917rem]">
              <FooterLink href={urls.policy.terms} className={styles.linkHoverColor} text="이용약관" />
              <FooterLink
                href={urls.policy.privacy}
                className={`${styles.linkHoverColor} transition-colors duration-100`}
                text={<strong>개인정보처리방침</strong>}
              />
              <FooterLink className={`${styles.linkHoverColor} hover:underline`} onClick={DEFAULT_ACTIONS.openBusinessInfo} text="사업자정보" />
              <FooterLink href={urls?.policy.careers} className={styles.linkHoverColor} text="인재채용" external />
            </div>
          </nav>
          <div className="flex justify-center pt-[0.417rem] text-[0.917rem] opacity-100">
            <p className="whitespace-nowrap">© TVING Corp. ALL RIGHTS RESERVED</p>
          </div>
        </div>
      </section>
    </footer>
  );
};

const Footer = ({ className = '' }: { className?: string }) => {
  const styles = DAFAULT_STYLES;
  const actions = DEFAULT_ACTIONS;
  const onClicks = { event: () => {}, contact: () => {} };
  const isMobile = false;
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    if (selectedValue) {
      window.open(selectedValue, '_blank');
      setSelectedValue('');
    }
  }, [selectedValue]);

  return (
    <footer
      className={cn(
        `relative layout-container ${isMobile ? styles.mobilePadding : styles.desktopPadding} text-[1.25rem] ${
          styles.commonTextColor
        } wrap-padding-left wrap-padding-right`,
        className,
      )}
    >
      <section>
        <div className="relative">
          <nav
            className={`flex items-start py-[1.667rem] ${styles.footerUtilColor} border border-solid border-t border-l-0 border-r-0 border-b-0 border-t-[#212121]`}
          >
            <div className="flex flex-wrap items-center w-full">
              {!isMobile && <FooterLink href={urls.help} className={styles.linkHoverColor} text="고객센터" />}
              <FooterLink href={urls.policy.terms} className={styles.linkHoverColor} text="이용약관" />
              <FooterLink
                href={urls.policy.privacy}
                className={`${styles.linkHoverColor} transition-colors duration-100`}
                text={<strong>개인정보처리방침</strong>}
              />
              {!isMobile && (
                <>
                  <FooterLink href={urls.policy.youth} className={styles.linkHoverColor} text="청소년 보호정책" />
                  <FooterLink href={urls.policy.legal} className={styles.linkHoverColor} text="법적고지" />
                  <FooterLink href={urls.event} className={`${styles.linkHoverColor} cursor-pointer`} text="이벤트" onClick={onClicks.event} />
                </>
              )}
              {isMobile && <FooterLink className={`${styles.linkHoverColor} hover:underline`} text="사업자정보" onClick={actions.openBusinessInfo} />}
              <FooterLink href={urls?.policy.careers} className={styles.linkHoverColor} text="인재채용" external />
            </div>
            <div>
              <FooterSelect options={BRAND_OPTIONS} placeholder="브랜드 바로가기" selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
            </div>
            <div className="flex">
              <span className="text-lg text-border">|</span>
              <FooterSelect
                options={CJ_GROUP_OPTIONS}
                placeholder="그룹 계열사 바로가기"
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
            </div>
          </nav>
          <div className="text-[1.083rem] opacity-100 pb-[1.888rem]">
            <FooterInfoLine
              infos={[
                { text: '대표이사 : 최주희' },
                {
                  text: '사업자정보확인',
                  onClick: DEFAULT_ACTIONS.openBusinessInfo,
                  className: `underline cursor-pointer ${styles.copyrightBoxHoverColor} hover:underline transition-colors duration-100`,
                },
                { text: '사업자등록번호 : 188-88-01893' },
                { text: '통신판매신고번호 : 2020-서울마포-3641호' },
              ]}
              commonBeforeStyles={styles.commonBeforeStyles}
            />
            <FooterInfoLine
              infos={[
                { text: '사업장 : 서울특별시 마포구 상암산로 34, DMC디지털큐브 15층(상암동)' },
                { text: '호스팅사업자 : 씨제이올리브네트웍스(주)' },
              ]}
              commonBeforeStyles={styles.commonBeforeStyles}
            />
            <FooterInfoLine
              infos={[
                { text: '고객센터 (평일 09시~17시/점심시간 13시~14시)' },
                {
                  text: '1:1 게시판 문의',
                  href: urls.qna,
                  onClick: onClicks.contact,
                  className: `underline cursor-pointer ${styles.copyrightBoxHoverColor} hover:underline transition-colors duration-100`,
                },
                { text: '대표메일 : tving@cj.net' },
                { text: '전화번호(ARS) : 1670-1525 (챗봇/채팅 상담 연결)' },
              ]}
              commonBeforeStyles={styles.commonBeforeStyles}
            />

            <FooterInfoLine
              infos={[{ text: 'ENM 시청자 상담실 (편성 문의 및 시청자 의견) : 080-080-0780' }, { text: 'Mnet 고객센터(방송편성문의) : 1855-1631' }]}
              commonBeforeStyles={styles.commonBeforeStyles}
            />
          </div>
          <SnsIcons />
          <p className="mt-[1.5rem] text-[1.083rem] opacity-100 text-left">© TVING Corp. ALL RIGHTS RESERVED</p>
        </div>
      </section>
    </footer>
  );
};

export { Footer, SimpleFooter };
