import Link from 'next/link';
import { FreeLabel, QuickVodLabel, RunningTimeTag, ThumbnailImage, ThumbnailPlay } from '@components/item/ItemComponent';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDeleteList, useUserInfo } from '@store/state';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { pageOrigin } from '@utils/apiConfig';
import { NewAgeGradeTag } from '@components/content/CommonComponent';
import { moveUserLoginBackDetail } from '@utils/common/UserApi';

const ItemATag = styled.a`
    cursor: pointer;
`;
const EpisodeItem = ({
    href,
    idx,
    type,
    bandType,
    title,
    subtitle,
    code,
    contentType,
    thumbnail,
    watchThumbnail, // 시청중인 에피소드 썸네일
    grade,
    ageGrade, // 시청 가능 연령
    episodeGradeCode, // 에피소드 시청 연령코드
    isAdult,
    isExclusive,
    isOriginal,
    isFree, // 무료 여부
    isPayUser,
    synopsis,
    broadcastDate,
    isQuickVod,
    broadcastStartTime,
    broadcastEndTime,
    insertDate,
    rating,
    ratio,
    isLazy,
    order,
    initialSlide,
    initialIdx,
    isView,
    viewDate, // 시청일자
    lastPlayTime,
    duration,
    subInfo,
    isEditMode,
    masterSeq, // 시청 고유번호
    frequency,
    onClick,
    displayTitle,
}) => {
    const route = useRouter();
    const [deleteList, setDeleteList] = useDeleteList(null);
    const [isClicked, setIsClicked] = useState(false);
    const [{ isLogin }] = useUserInfo();

    const ratioClassName = ratio ? `item__thumb-${ratio}` : '';

    // subInfo = subInfo && subInfo !== '' ? subInfo : moment('' + broadcastDate, 'YYYYMMDD').format('YYYY.MM.DD') + ' 방영';
    const isWatch = bandType === 'episodeWatch'; // 마이 > 시청내역인 경우

    const watchPercent = lastPlayTime ? (100 * lastPlayTime) / duration : 0;
    // const isQuickVod = bandType === 'quickVod'; // ?
    // const infoClassName = isRank ? 'item__info-rank' : '';
    const episodeInsertDate = moment(`${insertDate}`, 'YYYYMMDDhhmmss');
    const nowTime = moment().format('YYYYMMDDHHmmss');
    let isNew = false;
    if (moment().diff(moment(`${insertDate}`, 'YYYYMMDDhhmmss'), 'days') <= 1) {
        isNew = true;
    }
    let isPlay = false;
    isPlay = initialSlide === idx;

    useEffect(() => {
        const idx = deleteList.indexOf(masterSeq);
        if (idx > -1) {
            setIsClicked(true); // 모드가 변경될 때 선택 지우기
        } else {
            setIsClicked(false);
        }
    }, [deleteList]);

    const itemClick = () => {
        if (!isClicked) {
            setDeleteList([...deleteList, masterSeq]);
            setIsClicked(true);
        } else {
            const idx = deleteList.indexOf(masterSeq);
            const resultList = Object.assign(deleteList);
            if (idx > -1) {
                setDeleteList(null);
                // setDeleteList([]);
                resultList.splice(idx, 1);
                setDeleteList(resultList);
                setIsClicked(false);
            }
        }
    };

    /**
     * 아이템 클릭 메소드
     */
    const moveLink = (e) => {
        if (!isEditMode) {
            // 수정모드 아닌경우 재생 화면으로 이동
            // route.push(href);

            onClick?.(e);
        } else {
            // 수정일때 링크 동작 안하게.
            e.preventDefault();
            e.stopPropagation();
            // 수정모드인 경우 아이템 클릭
            itemClick();
        }
    };

    const itemLink = () => {
        let linkUrl = '';
        if (isLogin) {
            linkUrl = href;
        } else {
            linkUrl = `${pageOrigin}/account/login?returnUrl=${encodeURIComponent(window.location.href)}`;
        }
        return linkUrl;
    };

    return (
        <Link scroll href={itemLink()} passHref>
            <ItemATag className={`${isEditMode && 'active'}`} onClick={(e) => moveLink(e)}>
                {/* <Link href={href} passHref> */}
                <div className={`item__thumb ${ratioClassName}`}>
                    <div className="item__tags">
                        <div className="item__tags-left">
                            {/* 마이 > 시청내역인 경우 무료라벨을 표시하지 않습니다. */}
                            {!isWatch && isFree && !isPayUser && <FreeLabel />}

                            {/* QuickVOD 라벨 - My-시청내역 에서는 안보이도록 처리 */}
                            {!isWatch && isQuickVod && subtitle !== '' && <QuickVodLabel />}
                        </div>
                        {/* 연령 태그 - 프로그램, 회차목록은 18, 19세 연령등급만 표시 */}
                        {(episodeGradeCode === 'CPTG0500' || episodeGradeCode === 'CMMG0400') && (
                            <div className="item__tags-right">
                                <NewAgeGradeTag gradeCode={episodeGradeCode} />
                            </div>
                        )}
                    </div>

                    {/* 컨텐츠(포스터) */}
                    <ThumbnailImage thumbnail={thumbnail} title={title} size={600} isLazy={isLazy} />
                    {isView && <ThumbnailPlay dimm />}

                    {isEditMode && (
                        <div className="thumb_summary">
                            <button type="button" className={`btn__check ${isClicked && 'click_on'}`} />
                        </div>
                    )}

                    {/* 시청 퍼센트 - My페이지 아닌 전체 회차보기에서는 사용자별 시청시간 내려오지않음. 트래픽 발생으로 무리. 21.08.19 스펙아웃 */}
                    {isWatch && (
                        <RunningTimeTag className="item__progress" watchPercent={watchPercent}>
                            <span className="item__progress-bar" />
                        </RunningTimeTag>
                    )}
                </div>
                <div className="item__info ">
                    <p className="item__title">
                        {displayTitle ? (
                            <>{displayTitle}</>
                        ) : bandType === 'episodeWatch' ? (
                            <>{title}</>
                        ) : (
                            <>
                                {frequency >= 0 && `${frequency}.`} {title}
                            </>
                        )}
                    </p>
                    <p className="item__subtitle item__line-3">{synopsis}</p>
                    <p className="item__subinfo">{subInfo}</p>
                </div>
            </ItemATag>
        </Link>
    );
};

export default EpisodeItem;
