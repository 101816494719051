import ApiSend from '@utils/common/ApiSend';

/**
 * 찜 등록하기
 * @param context
 * @returns {Promise<void>}
 */
export const insertContentsFan = async context => {
    const { type, code } = context;
    const { data } = await ApiSend({
        url: '/v2/community/fans',
        method: 'post',
        withCredentials: true,
        params: {
            type,
            code,
        },
    });
    return data;
};
/**
 * 찜 취소하기
 * @param context
 * @returns {Promise<*>}
 */
export const deleteContentsFan = async context => {
    const { type, code } = context;
    const { data } = await ApiSend({
        url: '/v2/community/fans',
        method: 'post',
        withCredentials: true,
        params: {
            type,
            code,
            _method: 'DELETE',
        },
    });
    return data;
};

/**
 * 예약 컨텐츠 목록
 * @returns {Promise<*>}
 */
export const getScheduleReserveList = async ({ pageNo = 1 }) => {
    const { data } = await ApiSend({
        url: '/v2/community/schedule/reserves',
        params: {
            pageNo,
            pageSize: 10,
        },
        withCredentials: true,
    });
    return data;
};

/**
 * 예약 컨텐츠 등록
 * @param context
 * @returns {Promise<*>}
 */
export const insertScheduleReserve = async context => {
    const { data } = await ApiSend({
        url: '/v2/community/schedule/reserve/insert',
        params: {
            channel_code: context.channelCode,
            reserve_time: context.reserveTime,
            program_name: context.programName,
            episode_name: context.episodeName,
        },
        withCredentials: true,
    });
    return data;
};
/**
 * 예약 컨텐츠 등록
 * @param context
 * @returns {Promise<*>}
 */
export const insertScheduleListReserve = async context => {
    for (const time of context.reserveTime) {
        /* eslint-disable no-await-in-loop */
        await ApiSend({
            url: '/v2/community/schedule/reserve/insert',
            params: {
                channel_code: context.channelCode,
                reserve_time: time,
                program_name: context.programName,
                episode_name: context.episodeName,
            },
            withCredentials: true,
        });
    }
    return true;
};

/**
 * 예약 컨텐츠 삭제
 * @param context
 * @returns {Promise<*>}
 */
export const deleteScheduleReserve = async context => {
    const { data } = await ApiSend({
        url: '/v2/community/schedule/reserve/delete',
        params: {
            channel_code: context.channelCode,
            reserve_time: context.reserveTime,
            program_name: context.programName,
            episode_name: context.episodeName,
        },
        withCredentials: true,
    });
    return data;
};

/**
 * 예약 컨텐츠리스트 삭제
 * @param context
 * @returns {Promise<*>}
 */
export const deleteScheduleListReserve = async context => {
    for (const time of context.reserveTime) {
        /* eslint-disable no-await-in-loop */
        await ApiSend({
            url: '/v2/community/schedule/reserve/delete',
            params: {
                channel_code: context.channelCode,
                reserve_time: time,
                program_name: context.programName,
                episode_name: context.episodeName,
            },
            withCredentials: true,
        });
    }
    return true;
};

/**
 * 컨텐츠 예약 여부
 * @param context
 * @returns {Promise<*>}
 */
export const getScheduleReserveCount = async context => {
    const { data } = await ApiSend({
        url: '/v2/community/schedule/reserve/count',
        params: {
            channel_code: context.channelCode,
            reserve_time: context.reserveTime,
            program_name: context.programName,
            episode_name: context.episodeName,
        },
        withCredentials: true,
    });
    return data.body;
};
