import { useState } from 'react';

import CrossPlatformEventListener, {
  type ViewportOrientation as BaseViewportOrientation,
  type OrientationCallback,
} from '../../utils/crossPlatformEventListener';

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

export const { VIEWPORT_ORIENTATION } = CrossPlatformEventListener;

export type ViewportOrientation = BaseViewportOrientation | null;

const useViewportOrientation = (defaultValue: ViewportOrientation = null) => {
  const [orientation, setOrientation] = useState<ViewportOrientation>(defaultValue);

  useIsomorphicLayoutEffect(() => {
    const handleChangeViewport: OrientationCallback = (event) => {
      const nextOrientation: ViewportOrientation = event.orientation;
      setOrientation(nextOrientation);
    };

    const removeListener = CrossPlatformEventListener.addOrientationChangeEvent(handleChangeViewport);
    return () => {
      removeListener();
    };
  }, []);

  return orientation;
};

export default useViewportOrientation;
