/**
 * 밀리초를 시간과 분으로 변환합니다
 * @param ms 밀리초
 * @returns {hours: number, minutes: number} 시간과 분
 */
export const msToTime = (ms: number) => {
  const hours = Math.floor(ms / (1000 * 60 * 60));
  const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));

  return {
    hours,
    minutes,
  };
};

/**
 * msToTime의 결과값을 원하는 포맷으로 변환합니다
 * @param timeObj msToTime의 반환값 ({hours: number, minutes: number})
 * @param format 시간 포맷 (예: '$h시 $m분')
 * @returns 포맷된 시간 문자열
 */
export const formatTime = (timeObj: { hours: number; minutes: number }, format: string = '$h시 $m분') => {
  return format
    .replace('h', timeObj.hours.toString())
    .replace('mm', timeObj.minutes.toString().padStart(2, '0'))
    .replace('m', timeObj.minutes.toString());
};
