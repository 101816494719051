import ProgramItem from '@components/item/ProgramItem';
import WatchingItem from '@components/item/WatchingItem';
import LiveItem from '@components/item/LiveItem';
import MovieItem from '@components/item/MovieItem';
import OriginalItem from '@components/item/OriginalItem';
import GenreItem from '@components/item/GenreItem';
import SingleBannerItem from '@components/item/SingleBannerItem';
import EventItem from '@components/item/EventItem';
import EpisodeItem from '@components/item/EpisodeItem';
import PurchaseItem from '@components/item/PurchaseItem';
import TvingMallItem from '@components/item/TvingMallItem';
import ClipItem from '@components/item/ClipItem';

const Item = ({
    bandTitle,
    bandType,
    item,
    idx,
    ratio,
    isLazy,
    isEditMode,
    initialSlide,
    isView,
    isSearchLayer = false,
    isSearchPage = false,
    isListPage = false,
    prefixUrl = '',
    itemWidth = null,
    searchKeyword = null,
    searchUniqueKey = null,
    funnel = '',
    onClick,
    isPayUser,
    fbaParams,
}) => {
    const props = {
        bandTitle, // 띠 타이틀
        bandType,
        idx,
        ratio,
        isLazy,
        isListPage,
        initialSlide,
        isEditMode,
        ...item,
        grade: item.gradeCode,
        isView,
        itemWidth,
        searchKeyword,
        searchUniqueKey,
        funnel,
        onClick,
    };

    // console.log(bandTitle, bandType, props.title, 'isFree', props.isFree, isPayUser);
    const { type } = item || {};
    switch (bandType) {
        case 'vodWatch':
            return <WatchingItem {...props} href={`/player/${item.code}`} />;
        case 'programBasic':
        case 'originalBasic':
        case 'programRank':
            return type === 'movie' ? (
                <MovieItem {...props} href={`/contents/${item.code}`} isSearchLayer={isSearchLayer} isPayUser={isPayUser} fbaParams={fbaParams} />
            ) : (
                <ProgramItem
                    {...props}
                    href={`/contents/${item.programCode}`}
                    isSearchLayer={isSearchLayer}
                    isSearchPage={isSearchPage}
                    isPayUser={isPayUser}
                    fbaParams={fbaParams}
                />
            );
        case 'quickVod':
            return (
                <ProgramItem {...props} href={`/contents/${item.code}`} isSearchLayer={isSearchLayer} isPayUser={isPayUser} fbaParams={fbaParams} />
            );
        case 'episodeBasic':
        case 'episodeWatch':
            return <EpisodeItem {...props} href={`/player/${item.code}`} isPayUser={isPayUser} />;
        case 'liveBasic':
        case 'liveRank':
        case 'liveReserve':
        case 'tvingtvBasic':
        case 'tvingtvRank':
        case 'movieChannel':
            return <LiveItem {...props} href={`/player/${item.code}`} isSearchPage={isSearchPage} isPayUser={isPayUser} fbaParams={fbaParams} />;
        case 'movieBasic':
        case 'movieRank':
        case 'movieFavorite': // 영화 찜 내역
            return (
                <MovieItem
                    {...props}
                    href={`/contents/${item.code}`}
                    isSearchLayer={isSearchLayer}
                    isSearchPage={isSearchPage}
                    isPayUser={isPayUser}
                    fbaParams={fbaParams}
                />
            );
        case 'movieWatch':
            return <MovieItem {...props} href={`/player/${item.code}`} fbaParams={fbaParams} />;
        case 'genre':
            const genreLinkUrl =
                item.type === 'program' ? `${prefixUrl}/list/program/?genre=${item.code}` : `${prefixUrl}/list/movie/?genre=${item.code}`;
            return <GenreItem {...props} href={genreLinkUrl} />;
        case 'programTheme':
            return <ProgramItem {...props} href={`/player/${item.code}`} isPayUser={isPayUser} fbaParams={fbaParams} />;
        case 'tvingOrigin':
            return <OriginalItem {...props} href={`/contents/${item.code}`} />;
        case 'singleBanner':
            return <SingleBannerItem {...props} href={item.href} />;
        case 'event':
            return <EventItem {...props} href={item.link_url} />;
        case 'episodePurchase':
        case 'moviePurchase':
            return <PurchaseItem {...props} href={`/contents/${item.code}`} />;
        case 'clip':
            return <ClipItem {...props} href={`/player/${item.code}`} isPayUser={isPayUser} />;
        case 'tvingMall':
            return <TvingMallItem {...props} />;
        default:
            return type === 'movie' ? (
                <MovieItem {...props} href={`/contents/${item.code}`} isPayUser={isPayUser} fbaParams={fbaParams} />
            ) : (
                <ProgramItem {...props} href={`/contents/${item.programCode}`} isPayUser={isPayUser} fbaParams={fbaParams} />
            );
    }
};

export default Item;
