import { create } from 'zustand';
import { CrossPlatformAppConfigurationEvent } from '@utils/crossPlatform';

type State = {
    isGranted: boolean;
    setIsGranted: (nextValue: boolean) => void;
    isReceiveEvent: boolean;
    setIsReceiveEvent: (nextValue: boolean) => void;
    isReceiveNight: boolean;
    setIsReceiveNight: (nextValue: boolean) => void;

    pushToken: string;
    setPushToken: (token: string) => void;

    appConfiguration: CrossPlatformAppConfigurationEvent;
    setAppConfiguration: (config: CrossPlatformAppConfigurationEvent) => void;
};

/**
 * 네이티브 알림 상태 저장하는 스토어
 */
const useNativeStore = create<State>()((set) => ({
    isGranted: false,
    setIsGranted: (isGranted) => set({ isGranted }),
    isReceiveEvent: false,
    setIsReceiveEvent: (isReceiveEvent) => set({ isReceiveEvent }),
    isReceiveNight: false,
    setIsReceiveNight: (isReceiveNight) => set({ isReceiveNight }),

    pushToken: '',
    setPushToken: (pushToken) => set({ pushToken }),

    appConfiguration: {} as CrossPlatformAppConfigurationEvent,
    setAppConfiguration: (appConfiguration) => set({ appConfiguration }),
}));
export default useNativeStore;
