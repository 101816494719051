import React, { memo } from 'react';
import { AdminMessage, BaseMessage, UserMessage } from '@sendbird/chat/message';
import UserMessageCell from '@components/chat/ChatMessageCell/UserMessageCell';
import AdminMessageCell from '@components/chat/ChatMessageCell/AdminMessageCell';
import { DeleteMyMessageHandler, ReportMessageHandler } from '@tving/utils/src/hooks/common/chat/useChannelConnectionWithSendbird';

type Props = {
    messageSection: BaseMessage[];
    deleteMyMessage: DeleteMyMessageHandler;
    reportMessage: ReportMessageHandler;
};

const ChatSection = ({ messageSection, deleteMyMessage, reportMessage }: Props) => {
    return (
        <section id={messageSection?.[0]?.messageId?.toString()}>
            {/*<section id={messageSection?.[0]?.messageId?.toString()} style={{ backgroundColor: colors[messageSection?.[0]?.messageId % colors.length] }}>*/}
            {messageSection.map((message) => {
                if (message instanceof AdminMessage || (message instanceof UserMessage && message.isOperatorMessage)) {
                    return <AdminMessageCell key={message.messageId} message={message} />;
                } else if (message instanceof UserMessage) {
                    return (
                        <UserMessageCell key={message.messageId} message={message} deleteMyMessage={deleteMyMessage} reportMessage={reportMessage} />
                    );
                }

                return null;
            })}
        </section>
    );
};

export default memo(ChatSection);
