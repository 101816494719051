import qs from 'qs';
import { isEmpty } from '@utils/common/Object';

const replaceHttps = url => {
    return url?.replace('http:', 'https:');
};

export const resizeImage = (url, size) => {
    if (!url) {
        return url;
    }
    const imageUrl = replaceHttps(url);
    return !size ? `${imageUrl}` : `${imageUrl}/dims/resize/${size}`;
};
export const changeImageFormat = (url, type, size = 400, queryParamsObj = {}) => {
    if (!url) {
        return url;
    }
    const imageUrl = replaceHttps(url);
    let returnUrl = `${imageUrl}/dims/resize/F_${type}`;
    if (size || size === 0) {
        returnUrl += `,${size}`;
    }
    return isEmpty(queryParamsObj) ? returnUrl : pasreImageUrl({ url: returnUrl, queryParamsObj });
};

const pasreImageUrl = ({ url = '', queryParamsObj = {} }) => {
    // ex) queryParamsObj = { paramKey1: paramVal1, paramKey2: paramVal2 }
    const [imageUrl, params] = url.split('?');
    return `${imageUrl}?${qs.stringify({ ...qs.parse(params), ...queryParamsObj })}`;
};
