import { KboContentsResponse } from '@typings/kbo';
import { KBO_QUERY_KEYS } from '@hooks/kbo/queries/index';
import fetchKboContentsMain from '@apis/kbo/bff/fetchKboContentsMain';
import useKboGame from '@stores/kbo/game/useKboGame';
import { shallow } from 'zustand/shallow';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

const useKboContentsMainQuery = <B = unknown, C = unknown>() => {
    const { gameCode, isGameEnd, isKbo } = useKboGame(
        (state) => ({
            gameCode: state.gameCode,
            isGameEnd: state.isGameEnd,
            isKbo: state.isKbo,
        }),
        shallow,
    );
    const router = useRouter();
    const isKboContents = router.pathname.includes('/kbo/contents');
    const isRefetch = !isGameEnd;

    return useQuery<KboContentsResponse<B, C>>(KBO_QUERY_KEYS.CONTENTS_MAIN(gameCode), () => fetchKboContentsMain(gameCode), {
        enabled: isKbo && isKboContents,
        refetchInterval: isRefetch ? 30000 : false,
        refetchIntervalInBackground: isRefetch,
        refetchOnMount: isRefetch,
        refetchOnWindowFocus: isRefetch,
        refetchOnReconnect: isRefetch,
        useErrorBoundary: false,
    });
};

export default useKboContentsMainQuery;
