type ConvertToWebpResizeProps = {
  imageUrl: string;
  size: number;
};

const convertToWebpResize = ({ imageUrl, size }: ConvertToWebpResizeProps): string | null => {
  if (!imageUrl) {
    return null;
  }
  return `${imageUrl}/dims/resize/F_webp,${size}`;
};

export default convertToWebpResize;
