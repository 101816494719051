import age19Label from '../images/label/label_age_19.png';
import age19LabelSvg from '../images/label/label_age_19.svg';
import explainSubtitleLabelImage from '../images/label/label_cc.png';
import explainSubtitleLabelImageSvg from '../images/label/label_cc.svg';
import cmmgAge19Label from '../images/label/label_cmmg_age_19.png';
import cmmgAge19LabelSvg from '../images/label/label_cmmg_age_19.svg';
import dolby51chLabelImage from '../images/label/label_dolby51ch.png';
import dolby51chLabelImageSvg from '../images/label/label_dolby51ch.svg';
import dolbyAtmosLabelImage from '../images/label/label_dolbyatmos.png';
import dolbyAtmosLabelImageSvg from '../images/label/label_dolbyatmos.svg';
import dolbyVisionLabelImage from '../images/label/label_dolbyvision.png';
import dolbyVisionLabelImageSvg from '../images/label/label_dolbyvision.svg';
import drmLabelImage from '../images/label/label_drm.png';
import drmLabelImageSvg from '../images/label/label_drm.svg';
import dtxLabelImage from '../images/label/label_dtx.png';
import dtxLabelImageSvg from '../images/label/label_dtx.svg';
import dubbingLabelImage from '../images/label/label_dubbing.png';
import dubbingLabelImageSvg from '../images/label/label_dubbing.svg';
import freeLabelImage from '../images/label/label_free.png';
import freeLabelImageSvg from '../images/label/label_free.svg';
import hdrLabelImage from '../images/label/label_hdr.png';
import hdrLabelImageSvg from '../images/label/label_hdr.svg';
import hdrPlusLabelImage from '../images/label/label_hdr10plus.png';
import hdrPlusLabelImageSvg from '../images/label/label_hdr10plus.svg';
import liveLabelImage from '../images/label/label_live.png';
import liveLabelImageSvg from '../images/label/label_live.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import liveLabelImageWebp from '../images/label/label_live.webp';
import newEpisodeLabelImage from '../images/label/label_new.png';
import newEpisodeLabelImageSvg from '../images/label/label_new.svg';
import onlyLabelImage from '../images/label/label_only.png';
import onlyLabelImageSvg from '../images/label/label_only.svg';
import originalLabelImage from '../images/label/label_original.png';
import originalLabelImageSvg from '../images/label/label_original.svg';
import paramountLabelImage from '../images/label/label_paramount.png';
import paramountLabelImageSvg from '../images/label/label_paramount.svg';
import quickVodLabelImage from '../images/label/label_quick.png';
import quickVodLabelImageSvg from '../images/label/label_quick.svg';
import subtitleLabelImage from '../images/label/label_subtitle.png';
import subtitleLabelImageSvg from '../images/label/label_subtitle.svg';
import tsmLabelImage from '../images/label/label_tsm.png';
import tsmLabelImageSvg from '../images/label/label_tsm.svg';
import uhd4kLabelImage from '../images/label/label_uhd4k.png';
import uhd4kLabelImageSvg from '../images/label/label_uhd4k.svg';

/**
 * label 이미지 Type
 */
export interface LABEL_IMAGE_TYPE {
  PNG: string;
  SVG: string;
  ALT: string;
}

/**
 * label Type
 */
export const enum LABEL_TYPE {
  AGE19 = 'AGE19',
  AGE18 = 'AGE18',
  DOLBY_51CH = 'DOLBY_51CH',
  DOLBY_ATMOS = 'DOLBY_ATMOS',
  DOLBY_VISION = 'DOLBY_VISION',
  ONLY = 'ONLY',
  ORIGINAL = 'ORIGINAL',
  QUICK_VOD = 'QUICK_VOD',
  FREE = 'FREE',
  NEW_EPISODE = 'NEW_EPISODE',
  PARAMOUNT = 'PARAMOUNT',
  DUBBING = 'DUBBING',
  DRM = 'DRM',
  HDR = 'HDR',
  HDR_PLUS = 'HDR_PLUS',
  UHD4K = 'UHD4K',
  LIVE = 'LIVE',
  EXPLAIN_SUBTITLE = 'EXPLAIN_SUBTITLE',
  DTX = 'DTX',
  TSM = 'TSM',

  // 아래 라벨 확인필요
  EVENT = 'EVENT',
  PPV = 'PPV',
  CINE_SAME = 'CINE_SAME',
  FIRST_OPEN = 'FIRST_OPEN',
  SUBTITLE = 'SUBTITLE',
}

export const LABEL_IMAGE: Record<LABEL_TYPE, LABEL_IMAGE_TYPE> = {
  AGE19: { PNG: age19Label, SVG: age19LabelSvg, ALT: '성인 19세 라벨' },
  AGE18: { PNG: cmmgAge19Label, SVG: cmmgAge19LabelSvg, ALT: '성인 19세 라벨' },
  DOLBY_51CH: { PNG: dolby51chLabelImage, SVG: dolby51chLabelImageSvg, ALT: '돌비5.1채널 라벨' },
  DOLBY_ATMOS: { PNG: dolbyAtmosLabelImage, SVG: dolbyAtmosLabelImageSvg, ALT: '돌비애트머스 라벨' },
  DOLBY_VISION: { PNG: dolbyVisionLabelImage, SVG: dolbyVisionLabelImageSvg, ALT: '돌비비전 라벨' },
  ONLY: { PNG: onlyLabelImage, SVG: onlyLabelImageSvg, ALT: '티빙온리 라벨' },
  ORIGINAL: { PNG: originalLabelImage, SVG: originalLabelImageSvg, ALT: '티빙오리지널 라벨' },
  QUICK_VOD: { PNG: quickVodLabelImage, SVG: quickVodLabelImageSvg, ALT: '퀵브이오디 라벨' },
  FREE: { PNG: freeLabelImage, SVG: freeLabelImageSvg, ALT: '무료 라벨' },
  NEW_EPISODE: { PNG: newEpisodeLabelImage, SVG: newEpisodeLabelImageSvg, ALT: '뉴에피소드 라벨' },
  PARAMOUNT: { PNG: paramountLabelImage, SVG: paramountLabelImageSvg, ALT: '파라마운트 라벨' },
  DUBBING: { PNG: dubbingLabelImage, SVG: dubbingLabelImageSvg, ALT: '더빙 라벨' },
  DRM: { PNG: drmLabelImage, SVG: drmLabelImageSvg, ALT: 'DRM 라벨' },
  HDR: { PNG: hdrLabelImage, SVG: hdrLabelImageSvg, ALT: 'HDR 라벨' },
  HDR_PLUS: { PNG: hdrPlusLabelImage, SVG: hdrPlusLabelImageSvg, ALT: 'HDRPlus 라벨' },
  UHD4K: { PNG: uhd4kLabelImage, SVG: uhd4kLabelImageSvg, ALT: '4k 라벨' },
  LIVE: { PNG: liveLabelImageWebp, SVG: liveLabelImageSvg, ALT: '라이브 라벨' },
  EXPLAIN_SUBTITLE: { PNG: explainSubtitleLabelImage, SVG: explainSubtitleLabelImageSvg, ALT: '해설자막 라벨' },
  DTX: { PNG: dtxLabelImage, SVG: dtxLabelImageSvg, ALT: 'DTX 라벨' },
  SUBTITLE: { PNG: subtitleLabelImage, SVG: subtitleLabelImageSvg, ALT: ' 라벨' },
  TSM: { PNG: tsmLabelImage, SVG: tsmLabelImageSvg, ALT: 'TSM 라벨' },

  // TODO 아래 이미지 재정의 필요
  EVENT: { PNG: freeLabelImage, SVG: freeLabelImageSvg, ALT: ' 라벨' },
  PPV: { PNG: freeLabelImage, SVG: freeLabelImageSvg, ALT: ' 라벨' },
  CINE_SAME: { PNG: freeLabelImage, SVG: freeLabelImageSvg, ALT: ' 라벨' },
  FIRST_OPEN: { PNG: freeLabelImage, SVG: freeLabelImageSvg, ALT: ' 라벨' },
};
