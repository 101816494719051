import DefaultEmblemIcon from '@img/kbo/common/ic_emblem_default.svg';
import useKboMyTeamsQuery from '@hooks/kbo/queries/useKboMyTeamsQuery';
import { KBO_QUERY_KEYS } from '@hooks/kbo/queries';
import QUERY_KEY from '@tving/utils/src/constants/queryKeys/queryKeyConstants';
import { useQueryClient } from '@tanstack/react-query';
import { MutableRefObject, useEffect, useRef } from 'react';
import useFreshCallback from '@tving/utils/src/hooks/common/useFreshCallback';
import { SPORTS_QUERY_KEYS } from '@hooks/sports/queries';

type KboMyTeamIconProps = {
    shouldUpdateMyTeamRelatedQueries: MutableRefObject<boolean>;
};

const KboMyTeamIcon = ({ shouldUpdateMyTeamRelatedQueries }: KboMyTeamIconProps) => {
    const queryClient = useQueryClient();
    const shouldValidateMyTeamDataOutdated = useRef(false);
    const { data } = useKboMyTeamsQuery();

    const { selectedKboMyTeam } = data;
    const myTeamCode = selectedKboMyTeam?.code;
    const previousMyTeamCodeRef = useRef(myTeamCode);

    const handleForeground = useFreshCallback(() => {
        queryClient.invalidateQueries(KBO_QUERY_KEYS.MY_TEAMS());
        queryClient.invalidateQueries(QUERY_KEY.TERMS_AGREEMENT.all);
    });
    const handleVisibilityChange = useFreshCallback(() => {
        if (document.visibilityState === 'visible') {
            queryClient.invalidateQueries(KBO_QUERY_KEYS.MY_TEAMS());
            queryClient.invalidateQueries(QUERY_KEY.TERMS_AGREEMENT.all);

            // 앱 내 다른 웹뷰에서 마이팀을 변경한 경우 무효화하여 최신 데이터 갱신
            if (shouldUpdateMyTeamRelatedQueries.current) {
                queryClient.invalidateQueries({
                    queryKey: ['kbo', 'bff'],
                    refetchType: 'active',
                });
                queryClient.invalidateQueries({
                    queryKey: SPORTS_QUERY_KEYS.ALL,
                    refetchType: 'active',
                });

                shouldValidateMyTeamDataOutdated.current = false;
            } else {
                // 다른 브라우저 탭에서 변경한 경우 다시 포커스 되었을 때 최신 데이터 갱신
                shouldValidateMyTeamDataOutdated.current = true;
            }

            // eslint-disable-next-line no-param-reassign -- Ref 업데이트를 위해 예외 처리..
            shouldUpdateMyTeamRelatedQueries.current = false;
        }
    });

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('onForeground', handleForeground);
        window.addEventListener('onDismissedContentDetail', handleForeground);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('onForeground', handleForeground);
            window.removeEventListener('onDismissedContentDetail', handleForeground);
        };
    }, [handleForeground, handleVisibilityChange]);

    useEffect(() => {
        // 브라우저 탭 간 마이팀 변경 시 데이터를 갱신함
        if (shouldValidateMyTeamDataOutdated.current && myTeamCode !== previousMyTeamCodeRef.current) {
            queryClient.invalidateQueries({
                queryKey: ['kbo', 'bff'],
                refetchType: 'active',
            });
            queryClient.invalidateQueries({
                queryKey: SPORTS_QUERY_KEYS.ALL,
                refetchType: 'active',
            });
            shouldValidateMyTeamDataOutdated.current = false;
        }

        previousMyTeamCodeRef.current = myTeamCode;
    }, [myTeamCode, queryClient]);

    const currentTeamColor = selectedKboMyTeam?.color;

    return (
        <div>
            {selectedKboMyTeam?.symbolImageUrl ? (
                <img
                    style={{
                        backgroundColor: currentTeamColor,
                    }}
                    className="w-[2rem] h-[2rem] lg:w-[2.667rem] lg:h-[2.667rem] rounded-[0.442rem]"
                    alt="my-team-emblem "
                    src={selectedKboMyTeam.symbolImageUrl}
                />
            ) : (
                <DefaultEmblemIcon className="w-[2rem] h-[2rem] lg:w-[2.667rem] lg:h-[2.667rem] rounded-[0.444rem]" />
            )}
        </div>
    );
};
export default KboMyTeamIcon;
