import axios, { AxiosError } from 'axios';

import { API_SERVER_URL } from '../common/apiConfig';

const axiosInstance = axios.create({
  baseURL: API_SERVER_URL,
  method: 'get',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

export type RequestError = AxiosError;
export default axiosInstance;
