import { HTMLProps, RefObject } from 'react';

export type LogoProps = Omit<HTMLProps<HTMLImageElement>, 'crossOrigin'> & {
  onLoad?: () => void;
  onError?: (e) => void;
  imgRef?: RefObject<HTMLImageElement>;
};

const Logo = (logoProps: LogoProps) => {
  const { alt, className, imgRef } = logoProps;
  return <img {...logoProps} className={`atom-logo-img ${className}`} alt={`${alt} 로고`} ref={imgRef} />;
};

export default Logo;
