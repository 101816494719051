import tempCnx from '../../utils/tailwind/tempCnx';

/**
 * 1. inlineCSS 사용하여 SwitchButton 커스텀 스타일 적용하는 방식
 * (tailwindCSS 통해 커스터마이징 하기 너무 어려움)
 */
type SwitchInlineStyle = {
  // Switch width
  width?: string;
  // Switch height
  height?: string;
  // Switch border radius
  borderRadius?: string;

  checkedBackgroundColor?: string;
  uncheckedBackgroundColor?: string;
  circleColor?: string;

  circleSize?: string;
  // 슬라이더 동그라미 이동 시 좌우 여백
  sliderXPadding?: string;
};

/**
 * 2. className 사용하여 SwitchButton 커스텀 스타일 적용하는 방식
 * (위 1번의 inline 방식 사용할 경우 className 사용하지 않는 것을 권장합니다.)
 */
type SwitchTailwindClassNames = {
  // SwitchButton label 영역
  labelClassName?: string;
  // SwitchButton 체크박스
  checkboxClassName?: string;
  // SwitchButton 슬라이더
  sliderClassName?: string;
  // SwitchButton 슬라이더 내 동그라미
  circleClassName?: string;
};

type SwitchProps = SwitchInlineStyle &
  SwitchTailwindClassNames & {
    checked: boolean;
    setChecked: (value: boolean) => void;
  };

const Switch = ({
  checked,
  setChecked,
  width,
  height,
  borderRadius,
  checkedBackgroundColor,
  uncheckedBackgroundColor,
  circleColor,
  circleSize,
  sliderXPadding,
  labelClassName,
  checkboxClassName,
  sliderClassName,
  circleClassName,
}: SwitchProps) => {
  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <label
      className={tempCnx('relative inline-block w-8 h-4', labelClassName)}
      style={{
        ...(width && { width }),
        ...(height && { height }),
      }}
    >
      <input
        checked={checked}
        onChange={handleChange}
        type="checkbox"
        className={tempCnx('opacity-0 w-0 h-0', '[&:checked+div]:bg-[#FF153C] [&:checked+div>div]:translate-x-[1.125rem]', checkboxClassName)}
      />
      <div
        className={tempCnx(
          'w-full h-full absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-600 transition-all rounded-[1rem]',
          sliderClassName,
        )}
        style={{
          ...(borderRadius && { borderRadius }),
          ...(uncheckedBackgroundColor && { backgroundColor: uncheckedBackgroundColor }),
          ...(checkedBackgroundColor && checked && { backgroundColor: checkedBackgroundColor }),
        }}
      >
        <div
          className={tempCnx(
            'absolute w-3 h-3 top-[0.125rem] bg-white rounded-[50%] transform translate-x-[0.125rem] transition-transform',
            circleClassName,
          )}
          style={{
            ...(circleSize && { width: circleSize, height: circleSize, top: `calc(50% - ${circleSize} / 2)` }),
            ...(circleColor && { backgroundColor: circleColor }),
            ...(sliderXPadding && {
              transform: checked ? `translateX(calc(${width} - ${sliderXPadding} - ${circleSize}))` : `translateX(${sliderXPadding})`,
            }),
          }}
        />
      </div>
    </label>
  );
};

export default Switch;
