import React, { useEffect, useState } from 'react';
import cnx from '@utils/tailwind/cnx';
import styles from '@layouts/kbo/defaultLayout/styles.module.scss';
import { useRouter } from 'next/router';
import usePcPlayer from '@store/usePcPlayer';
import { shallow } from 'zustand/shallow';

type KboHeaderProps = {
    children: React.ReactNode;
    device: {
        isMobileWebview: boolean;
        isMobileWeb: boolean;
    };
};

export const KBO_HEADER_ID = '__tving_kbo_gnb__';

const KboHeaderLayout = ({ children, device }: KboHeaderProps) => {
    const router = useRouter();
    const [isWebviewMounted, setIsWebviewMounted] = useState(false);
    const { isMobileWebview, isMobileWeb } = device || {};
    const { pathname } = router;
    const { isFullScreen: isFullscreen, setFullScreen } = usePcPlayer(
        (state) => ({
            isFullScreen: state.isFullScreen,
            setFullScreen: state.setFullScreen,
        }),
        shallow,
    );

    const isKboMorePage = pathname.startsWith('/kbo/more/');
    const isKboAthletePage = pathname.startsWith('/kbo/athlete/');
    const isKboRoasterPage = pathname.startsWith('/kbo/roaster/');
    const isKboTeamPage = pathname.startsWith('/kbo/team/');

    useEffect(() => {
        if (!isMobileWebview) {
            return;
        }

        !isWebviewMounted && setIsWebviewMounted(true);
    }, [isMobileWebview, isWebviewMounted]);

    return (
        <header
            id={KBO_HEADER_ID}
            className={cnx(
                'kbo-header-layout',
                styles['KBO-Header'],
                isMobileWebview && styles['KBO-Header--webview'],
                isMobileWebview && isWebviewMounted && styles.visible,
                isKboMorePage && isMobileWebview ? 'top-0 right-0 left-0' : 'z-[200] top-0 right-0 left-0',
                isMobileWebview ? 'fixed h-[calc(8rem+var(--safe-area-inset-top,_0))]' : '',
                isMobileWeb && !isMobileWebview ? (isKboMorePage ? 'relative h-[0]' : 'relative h-[8.917rem]') : 'fixed',
                isMobileWebview && isKboAthletePage ? 'h-[calc(3.67rem_+_var(--safe-area-inset-top))]' : '',
                isMobileWebview && isKboRoasterPage ? 'h-[calc(3.67rem_+_var(--safe-area-inset-top))]' : '',
                isMobileWebview && isKboTeamPage ? 'h-[calc(3.67rem_+_var(--safe-area-inset-top))]' : '',
                isMobileWebview ? 'fixed pt-[2.083rem]' : '',
                'transform-[translateY]',
                'w-full',
                isFullscreen ? 'hidden' : '',
            )}
        >
            {children}
        </header>
    );
};

export default KboHeaderLayout;
