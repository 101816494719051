declare const global: unknown;

export function isServer() {
    return typeof window === 'undefined' && typeof global !== 'undefined';
}

export function isClient() {
    return !isServer();
}

export function isAndroid() {
    if (isServer()) {
        return false;
    }

    return navigator.userAgent.match(/Android/i) != null;
}

export function isIOS() {
    if (isServer()) {
        return false;
    }

    return navigator.userAgent.match(/ipad|iphone/i) != null;
}

export function getOSByUserAgent() {
    if (isServer()) {
        return false;
    }

    if (isIOS()) {
        return 'ios';
    }

    if (isAndroid()) {
        return 'android';
    }

    return 'web';
}

export function isMobileWeb() {
    const userAgent = getOSByUserAgent();

    return userAgent === 'ios' || userAgent === 'android';
}
