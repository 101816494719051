// TODO: TV 병합 이후 삭제해야할 파일입니다. /apps/tv/src/util/firebaseAnalytics/helper.ts 파일과 동일한 내용입니다!

const VALUE_CHUNK_LENGTH = 95;
const MAX_CHUNK_ARR_LENGTH = 4;

const convertStrIntoChunks = (str: string, chunkLength: number, chunkArrLength: number) => {
    const result: string[] = [];
    let start = 0;
    let end = chunkLength;

    while (start < str.length && result.length < chunkArrLength) {
        result.push(str.slice(start, end));
        start = end;
        end = start + chunkLength;
    }

    return result;
};

// fba 이벤트의 속성의 값으로 문자열 100자 제한이 있어서 impression_code의 값을 95자로 나누어주는 함수 입니다.
export const getImpressionCodes = (codes?: string[]) => {
    if (!codes) {
        return {};
    }

    const codeChunks = convertStrIntoChunks(codes.join(','), VALUE_CHUNK_LENGTH, MAX_CHUNK_ARR_LENGTH);
    return codeChunks.reduce((acc, cur, idx) => {
        acc[`impression_codes${idx + 1}`] = cur;
        return acc;
    }, {} as { [key in 'impression_codes1' | 'impression_codes2' | 'impression_codes3' | 'impression_codes4']?: string });
};

export const getImpressionKeywords = (keywords?: string[]) => {
    if (!keywords) {
        return {};
    }

    const keywordChunks = convertStrIntoChunks(keywords.join('₩'), VALUE_CHUNK_LENGTH, MAX_CHUNK_ARR_LENGTH);
    return keywordChunks.reduce((acc, cur, idx) => {
        acc[`impression_keywords${idx + 1}`] = cur;
        return acc;
    }, {} as { [key in 'impression_keywords1' | 'impression_keywords2' | 'impression_keywords3' | 'impression_keywords4']?: string });
};
