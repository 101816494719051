import React, {
  createContext,
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { MetaCounter, User } from '@sendbird/chat';
import { BaseMessage } from '@sendbird/chat/message';

export const ERROR_DISPLAY_TYPE = {
  MODAL: 'modal',
  LAYOUT: 'layout',
} as const;

type ErrorDisplayType = typeof ERROR_DISPLAY_TYPE[keyof typeof ERROR_DISPLAY_TYPE];

export type ErrorPayload = {
  code: number;
  message?: string;
  errorMessage: string;
  displayType: ErrorDisplayType;
  withRefresh?: boolean;
  bodyComponent?: React.ReactNode;
};

type ErrorState = ErrorPayload | null;
export type ChatErrorState = ErrorState;

type SetErrorAction = {
  type: 'SET_ERROR';
  payload: ErrorPayload;
};

type ClearErrorAction = {
  type: 'CLEAR_ERROR';
};

type ErrorAction = SetErrorAction | ClearErrorAction;

export const CHAT_ERRORS: { [key in number | string]: ErrorPayload } = {
  400201: {
    code: 400201,
    errorMessage: '티빙 톡 채널이\n생성되지 않은 페이지입니다.',
    displayType: ERROR_DISPLAY_TYPE.LAYOUT,
  },
  // 900070: {
  //   code: 900070,
  //   errorMessage: '티빙 톡 채널이\n생성되지 않은 페이지입니다.',
  //   displayType: ERROR_DISPLAY_TYPE.LAYOUT,
  // },
  400700: {
    code: 400700,
    errorMessage: '회원님의 티빙톡 입력이 제한되었으며, 일정 시간 후 자동 해제됩니다. \n(신고 5회 누적 시 10분 / 부적절한 언어 사용 시 5시간)',
    displayType: ERROR_DISPLAY_TYPE.MODAL,
  },
  800101: {
    code: 800101,
    errorMessage: '채팅 전송이 완료되지 않았습니다.\n잠시 후 다시 시도하세요.',
    displayType: ERROR_DISPLAY_TYPE.MODAL,
  },
  800200: {
    code: 800200,
    errorMessage: 'Connection is requaired',
    displayType: ERROR_DISPLAY_TYPE.LAYOUT,
  },
  900041: {
    code: 900041,
    errorMessage: '회원님의 티빙톡 입력이 제한되었으며, 일정 시간 후 자동 해제됩니다. \n(신고 5회 누적 시 10분 / 부적절한 언어 사용 시 5시간)',
    displayType: ERROR_DISPLAY_TYPE.MODAL,
  },
  900060: {
    code: 900060,
    errorMessage: '금칙어가 포함되어 있습니다.\n다시 작성해 주세요.',
    displayType: ERROR_DISPLAY_TYPE.MODAL,
  },
  900200: {
    code: 900200,
    errorMessage: '같은 내용의 메시지를\n반복해서 보낼 수 없습니다.',
    displayType: ERROR_DISPLAY_TYPE.MODAL,
  },
  NETWORK_ERROR: {
    code: 500101,
    errorMessage: '일시적인 오류로 티빙톡을 실행할 수 없습니다.\n잠시 후 다시 시도하세요.',
    displayType: ERROR_DISPLAY_TYPE.LAYOUT,
    withRefresh: true,
  },
  EMPTY_MESSAGE: {
    code: 400000,
    errorMessage: '메시지를 입력해주세요.',
    displayType: ERROR_DISPLAY_TYPE.MODAL,
  },
  MESSAGE_SEND_FAILURE: {
    code: 400408,
    errorMessage: '채팅 전송이 완료되지 않았습니다.\n잠시 후 다시 시도하세요.',
    displayType: ERROR_DISPLAY_TYPE.MODAL,
  },
};

const errorReducer = (state: ErrorState, action: ErrorAction): ErrorState => {
  switch (action.type) {
    case 'SET_ERROR':
      const { message, errorMessage } = action.payload;

      return { ...action.payload, message: message || errorMessage };
    case 'CLEAR_ERROR':
      return null;
    default:
      return state;
  }
};

export const setError = (dispatch: React.Dispatch<ErrorAction>, error: ErrorPayload) => {
  dispatch({ type: 'SET_ERROR', payload: error });
};

export const clearError = (dispatch: React.Dispatch<ErrorAction>) => {
  dispatch({ type: 'CLEAR_ERROR' });
};

type ChatContextType = {
  channelUrl: string | null;
  user: User | null;
  pinnedMessage: BaseMessage | null;
  reportedMessages: { [key in number]: boolean } | null;
  hiddenPinnedMessages: { [key in number]: boolean } | null;
  metaCounters: MetaCounter;
  infoIcon?: React.ReactNode;
  loadingComp?: React.ReactNode;
  isLoading: boolean;
  isInputFocus: boolean;
  isInputFocusDelay: boolean;
  error: ErrorState;
  isFrozen: boolean;
  isInputMultiline: boolean;
  isBottom: boolean;
  canReceiveMessage: boolean;
  setUser: Dispatch<SetStateAction<User | null>>;
  setPinnedMessage: React.Dispatch<React.SetStateAction<BaseMessage | null>>;
  setReportedMessages: React.Dispatch<React.SetStateAction<{ [key in number]: boolean } | null>>;
  setHiddenPinnedMessages: React.Dispatch<React.SetStateAction<{ [key in number]: boolean } | null>>;
  setMetaCounters: Dispatch<React.SetStateAction<MetaCounter>>;
  setMetaCountersHandler: (metaCounter: MetaCounter) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setIsInputFocus: Dispatch<SetStateAction<boolean>>;
  setIsInputFocusDelay: Dispatch<SetStateAction<boolean>>;
  setError: (error: ErrorPayload | undefined) => void;
  clearError: () => void;
  setIsFrozen: Dispatch<SetStateAction<boolean>>;
  setIsInputMultiline: Dispatch<SetStateAction<boolean>>;
  setIsBottom: Dispatch<SetStateAction<boolean>>;
  setCanReceiveMessage: Dispatch<SetStateAction<boolean>>;
  isReconnected: boolean;
  setIsReconnected: Dispatch<SetStateAction<boolean>>;
  refetchSession: () => Promise<void>;
  refreshSession: () => Promise<void>;
  textareaRef: RefObject<HTMLTextAreaElement>;
  isTextareaFocus: React.MutableRefObject<boolean>;
};

type ChatContextProviderProps = {
  children: React.ReactNode;
  channelUrl: string | null;
  error: ErrorPayload | undefined;
  refetchSession: () => Promise<void>;
  refreshSession: () => Promise<void>;
  infoIcon?: React.ReactNode;
  loadingComp?: React.ReactNode;
};

const ChatContext = createContext<ChatContextType>({
  channelUrl: null,
  user: null,
  metaCounters: {},
  pinnedMessage: null,
  reportedMessages: null,
  hiddenPinnedMessages: null,
  infoIcon: null,
  loadingComp: null,
  isLoading: true,
  isInputFocus: false,
  isInputFocusDelay: false,
  error: null,
  isFrozen: true,
  isInputMultiline: false,
  isBottom: true,
  canReceiveMessage: true,
  setUser: () => {},
  setPinnedMessage: () => {},
  setReportedMessages: () => {},
  setHiddenPinnedMessages: () => {},
  setMetaCounters: () => {},
  setMetaCountersHandler: () => {},
  setIsLoading: () => {},
  setIsInputFocus: () => {},
  setIsInputFocusDelay: () => {},
  setError: () => {},
  clearError: () => {},
  setIsFrozen: () => {},
  setIsInputMultiline: () => {},
  setIsBottom: () => {},
  setCanReceiveMessage: () => {},
  isReconnected: false,
  setIsReconnected: () => {},
  refetchSession: async () => {
    console.log('not bind refetch session');
  },
  refreshSession: async () => {},
  textareaRef: { current: null } as RefObject<HTMLTextAreaElement>,
  isTextareaFocus: { current: false },
});

const mergeObjectsWithMaxValues = <T extends Record<string, number>>(x: T, y: T) => {
  return Object.keys({ ...(x || {}), ...(y || {}) }).reduce((acc, key) => {
    acc[key] = Math.max(x?.[key] || 0, y?.[key] || 0);

    return acc;
  }, {}) as T;
};

export const ChatContextProvider = ({
  children,
  channelUrl,
  error: initError,
  refetchSession,
  refreshSession,
  infoIcon,
  loadingComp,
}: ChatContextProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [pinnedMessage, setPinnedMessage] = useState<BaseMessage | null>(null);
  const [reportedMessages, setReportedMessages] = useState<{ [key in number]: boolean } | null>(null);
  const [hiddenPinnedMessages, setHiddenPinnedMessages] = useState<{ [key in number]: boolean } | null>(null);
  const [metaCounters, setMetaCounters] = useState<MetaCounter>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFrozen, setIsFrozen] = useState<boolean>(false);
  const [isInputFocus, setIsInputFocus] = useState<boolean>(false);
  const [isInputFocusDelay, setIsInputFocusDelay] = useState<boolean>(false);
  const [isInputMultiline, setIsInputMultiline] = useState<boolean>(false);
  const [isBottom, setIsBottom] = useState<boolean>(true);
  const [canReceiveMessage, setCanReceiveMessage] = useState<boolean>(true);
  // const [error, setError] = useState<ErrorState>(null);
  const [error, dispatchError] = useReducer(errorReducer, initError ?? null);
  const [isReconnected, setIsReconnected] = useState<boolean>(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const isTextareaFocus = useRef<boolean>(false);

  const setMetaCountersHandler = useCallback((metaCounter: MetaCounter) => {
    setMetaCounters((prev) => mergeObjectsWithMaxValues(prev, metaCounter));
  }, []);

  useEffect(() => {
    if (!initError) {
      dispatchError({ type: 'CLEAR_ERROR' });
    } else {
      dispatchError({ type: 'SET_ERROR', payload: initError });
    }
  }, [initError]);

  const context: ChatContextType = useMemo(
    () => ({
      channelUrl,
      user,
      pinnedMessage,
      reportedMessages,
      hiddenPinnedMessages,
      metaCounters,
      isLoading: isLoading || !user,
      error: error || (initError ?? null),
      infoIcon,
      loadingComp,
      isFrozen,
      isInputFocus,
      isInputFocusDelay,
      isInputMultiline,
      isBottom,
      canReceiveMessage,
      setUser,
      setPinnedMessage,
      setReportedMessages,
      setHiddenPinnedMessages,
      setMetaCounters,
      setMetaCountersHandler,
      setIsLoading,
      setError: (errorPayload: ErrorPayload | undefined) => {
        if (error?.code === errorPayload?.code) {
          return;
        }

        console.log('#tving-talk setError', { errorPayload });

        return errorPayload
          ? dispatchError({ type: 'SET_ERROR', payload: errorPayload })
          : dispatchError({
              type: 'SET_ERROR',
              payload: { code: 500, errorMessage: '알수 없는 에러가 발생했습니다.', displayType: ERROR_DISPLAY_TYPE.MODAL },
            });
      },
      clearError: () => dispatchError({ type: 'CLEAR_ERROR' }),
      setIsFrozen,
      setIsInputFocus,
      setIsInputFocusDelay,
      setIsInputMultiline,
      setIsBottom,
      setCanReceiveMessage,
      refetchSession,
      refreshSession,
      isTextareaFocus,
      textareaRef,
      isReconnected,
      setIsReconnected,
    }),
    [
      channelUrl,
      user,
      pinnedMessage,
      reportedMessages,
      hiddenPinnedMessages,
      metaCounters,
      isLoading,
      error,
      infoIcon,
      loadingComp,
      initError,
      isFrozen,
      isInputFocus,
      isInputFocusDelay,
      isInputMultiline,
      isBottom,
      canReceiveMessage,
      setMetaCountersHandler,
      refetchSession,
      refreshSession,
      isReconnected,
    ],
  );

  return <ChatContext.Provider value={context}>{children}</ChatContext.Provider>;
};

export const useChatConfig = () => {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChatConfig must be used within a ChatContextProvider');
  }

  return context;
};

export default ChatContext;
