import { storage } from '@utils/common/LocalStorage';

const getRegisterableKeywords = keyword => {
    const savedKeywords = JSON.parse(storage.get('search_keywords', '[]'));
    const deletedDuplicateKeywords = savedKeywords.filter(item => item !== keyword).slice(0, 10);

    return [keyword, ...deletedDuplicateKeywords];
};

export const saveSearchKeyword = keyword => {
    const keywords = getRegisterableKeywords(keyword);
    storage.set('search_keywords', JSON.stringify(keywords.slice(0, 10)));
};
