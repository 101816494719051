import Link from 'next/link';
import { FreeLabel, QuickVodLabel, RunningTimeTag, ThumbnailImage } from '@components/item/ItemComponent';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDeleteList, useLogging } from '@store/state';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Ranking from '@components/common/Ranking';
import { logFbaSearchDetailClick } from '@utils/firebaseAnalytics';
import { NewAgeGradeTag } from '@components/content/CommonComponent';

const ItemATag = styled.a`
    cursor: pointer;
`;

const ItemInfoDiv = styled.div`
    display: ${(props) => (props.isListPage ? 'block' : 'grid')} !important;
`;
const StyledThumb = styled.picture`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    ${({ isActive, isEnd }) =>
        isActive && !isEnd
            ? css`
                  opacity: 1;
              `
            : ``}
`;

const LiveThumb = ({ isHover, code, isLive, isBlock }) => {
    const [isActive, setIsActive] = useState(false);
    const [isEnd, setIsEnd] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    useEffect(() => {
        setImgSrc(`data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==`);
        setIsEnd(false);
        if (!isHover) {
            return undefined;
        }
        const activeTimer = setTimeout(() => {
            setImgSrc(`https://stillshot.tving.com/thumbnail/${code}_1_640x360.webp`);
            setIsActive(true);
        }, 100);
        const endTimer = setTimeout(() => {
            setIsEnd(true);
        }, 3000);
        return () => {
            setImgSrc(`data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==`);
            clearTimeout(activeTimer);
            clearTimeout(endTimer);
            setIsActive(false);
        };
    }, [isHover]);

    if (isBlock) {
        return null;
    }

    return isLive && isHover ? (
        <StyledThumb isActive={isActive} isEnd={isEnd}>
            <source srcSet={imgSrc} type="image/webp" />
            <img />
        </StyledThumb>
    ) : null;
};
const LiveItem = ({
    code, // 채널 코드
    mediaCode, // 컨텐츠 코드
    contentType,
    href,
    idx,
    type, // 타입 - movie / episode
    bandTitle,
    bandType,
    ratio,
    title, // 타이틀
    subtitle, // 서브타이틀
    subInfo,
    programName,
    thumbnail, // 썸네일 이미지
    programThumbnail, // My 예약내역의 경우 에피소드 또는 프로그램 썸네일 표시
    isAdult, // 성인인증 필요 여부
    gradeCode, // 연령 등급 코드
    ageGrade, // 시청 가능 연령
    broadcastStartTime,
    broadcastEndTime,
    rating,
    isQuickVod,
    isLazy,
    isEditMode, // 편집모드
    channelCode, // 채널코드 - 마이 > 삭제를 위한 파라미터
    channelName,
    frequency,
    reserveTime, // 예약시간(시작시간) - 마이 > 삭제를 위한 파라미터
    isListPage, // 리스트 페이지의 아이템인 경우
    isChannelBlock,
    categoryName1, // 카테고리(장르)1
    categoryName2, // 카테고리(장르)2
    searchKeyword, // 검색결과인 경우 검색어
    searchUniqueKey, // 검색에서 클릭된 경우 유니크 키
    funnel, // 검색진입 지점
    isSearchPage, // 검색 페이지 여부
    onClick,
    isPayUser,
    isFree, // 무료 여부
    fbaParams,
}) => {
    const route = useRouter();
    const [{ braze, brazeEvent }] = useLogging();
    const [deleteList, setDeleteList] = useDeleteList(null);
    const [isClicked, setIsClicked] = useState(false);
    const ratioClassName = ratio ? `item__thumb-${ratio}` : '';

    // 프로그래스바 계산
    const startDate = moment(`${broadcastStartTime}`, 'YYYYMMDDHHmmss');
    const endDate = moment(`${broadcastEndTime}`, 'YYYYMMDDHHmmss');
    const watchPercent = Math.min(Math.max(Math.round((moment().diff(startDate) / endDate.diff(startDate)) * 100), 0), 100);

    const isRank = bandType === 'liveRank' || bandType === 'tvingtvRank';
    const infoClassName = bandType === 'liveRank' || bandType === 'tvingtvRank' ? 'item__info-rank' : '';
    const isTvingtv = bandType === 'tvingtvBasic' || bandType === 'tvingtvRank';
    const isMyLiveReserve = bandType === 'liveReserve';
    const isLive = contentType === 'CPCS0100';

    useEffect(() => {
        setIsClicked(false); // 모드가 변경될 때 선택 지우기
    }, [isEditMode]);

    useEffect(() => {
        if (!deleteList) {
            return undefined;
        }
        const idx = deleteList?.findIndex((item) => item?.channelCode === channelCode && item?.reserveTime === reserveTime);
        if (idx > -1) {
            setIsClicked(true); // 모드가 변경될 때 선택 지우기
        } else {
            setIsClicked(false);
        }
    }, [deleteList]);

    const itemClick = () => {
        if (!isClicked) {
            setDeleteList([...deleteList, { channelCode, reserveTime }]);
            setIsClicked(true);
        } else {
            const idx = deleteList.findIndex((item) => item.channelCode === channelCode && item.reserveTime === reserveTime);
            const resultList = Object.assign(deleteList);
            if (idx > -1) {
                setDeleteList(null);
                resultList.splice(idx, 1);
                setDeleteList(resultList);
                setIsClicked(false);
            }
        }
    };

    /**
     * 아이템 클릭 메소드
     */
    const moveLink = (e) => {
        if (!isEditMode) {
            // 수정모드 아닌경우 재생 화면으로 이동
            // route.push(href);

            onClick?.(e);
            if (!braze) {
                return;
            }
            brazeEvent({
                eventName: 'real_play_ch',
                properties: {
                    type: '실시간',
                    genre1: categoryName1 || '',
                    genre2: categoryName2 || '',
                    ch: channelName || '',
                    contents: programName,
                    episode_no: frequency || '',
                    gate: bandTitle,
                    code: mediaCode,
                },
            });

            if (isSearchPage) {
                logFbaSearchDetailClick({
                    searchId: searchUniqueKey,
                    keyword: searchKeyword,
                    funnel,
                    bandName: bandTitle,
                    mediaCode: code,
                    rank: idx,
                    ...fbaParams,
                });
            }
        } else {
            // 수정일때 링크 동작 안하게.
            e.preventDefault();
            e.stopPropagation();
            // 수정모드인 경우 아이템 클릭
            itemClick();
        }
    };

    const isBlockLiveThumbnail = () => {
        const commonBlockConditions = isChannelBlock || isMyLiveReserve;

        return isTvingtv
            ? commonBlockConditions
            : commonBlockConditions || ageGrade === '19';
    };

    const [isHover, setIsHover] = useState(false);

    return (
        <Link scroll href={href} passHref>
            <ItemATag
                className={`${isEditMode && 'active'}`}
                onClick={(e) => moveLink(e)}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                <div className={`item__thumb ${ratioClassName}`}>
                    {!isBlockLiveThumbnail() && <LiveThumb isHover={isHover} code={code} type={type} isLive={isLive} isBlock={reserveTime} />}

                    <div className="item__tags">
                        <div className="item__tags-left">
                            {/* MY > 예약내역에서는 무료 라벨이 표시되지 않습니다. */}
                            {isFree && !isMyLiveReserve && !isPayUser && <FreeLabel />}

                            {/* QuickVOD 태그 */}
                            {isQuickVod && <QuickVodLabel />}
                        </div>
                        {/* 연령 태그 - 라이브는 19세 콘텐츠에 한해 연령등급 표시 */}
                        {gradeCode === 'CPTG0500' && (
                            <div className="item__tags-right">
                                <NewAgeGradeTag gradeCode={gradeCode} />
                            </div>
                        )}
                    </div>

                    {/* 컨텐츠(포스터) */}
                    <ThumbnailImage thumbnail={isBlockLiveThumbnail() ? programThumbnail : thumbnail} title={title} isLazy={isLazy} />
                    {/* 라이브 시청(진행) 퍼센트 */}
                    {!isMyLiveReserve && (
                        <RunningTimeTag className="item__progress" watchPercent={watchPercent}>
                            <span className="item__progress-bar" />
                        </RunningTimeTag>
                    )}
                    {isEditMode && (
                        <div className="thumb_summary">
                            <button className={`btn__check ${isClicked && 'click_on'}`} />
                        </div>
                    )}
                </div>
                <ItemInfoDiv className={`item__info ${infoClassName}`} isListPage={isListPage}>
                    {!isListPage && isRank && <Ranking number={idx + 1} />}
                    <div>
                        <p className="item__title">{title}</p>
                        <p className="item__subtitle">{subtitle}</p>
                        {isRank && rating && <p className="item__subinfo">{rating}%</p>}
                        {subInfo && <p className="item__subinfo">{subInfo}</p>}
                    </div>
                </ItemInfoDiv>
            </ItemATag>
        </Link>
    );
};

export default LiveItem;
