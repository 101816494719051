import { ElementType } from 'react';

import AspectRatio from '../../atom/aspectRatio/AspectRatio';
import Poster, { PosterProps } from '../../atom/poster/Poster';
import Text, { TextProps } from '../../atom/text/Text';
import IconPlay from '../../images/last/icon_play_continue.svg';
import Labels, { LabelsProps } from '../labels/Labels';

type ClipVerticalItemProps = {
  className?: string;
  poster: PosterProps;
  posterText?: TextProps<ElementType>;
  labels?: LabelsProps;
  text: {
    className: string;
    items: { tag?: ElementType; title: string; className?: string; tabindex?: number }[];
  };
  playTimeText?: {
    className?: string;
  };
  totalPlayTime: number; // [ms]
  tabIndex?: number;
  isViewing?: boolean;
};

const formatClipDuration = (duration: number) => {
  if (duration === undefined || duration === null || Number.isNaN(duration) || duration < 0) {
    return '00:00';
  }

  try {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;

    if (Number.isNaN(hours) || Number.isNaN(minutes) || Number.isNaN(seconds)) {
      return '00:00:00';
    }

    if (hours === 0) {
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } catch (error) {
    console.error('error ClipVerticalItem formatClipDuration:', error);
    return '00:00:00';
  }
};

const ClipVerticalItem = ({
  className,
  playTimeText,
  poster,
  text,
  posterText,
  tabIndex,
  labels,
  totalPlayTime,
  isViewing,
}: ClipVerticalItemProps) => {
  return (
    <>
      <div className={`sports-clip-item-wrapper clip-item-wrapper ${className}`} tabIndex={tabIndex}>
        {posterText && <Text {...posterText} />}
        <Poster {...poster} />
        {labels && <Labels {...labels} />}

        <span {...playTimeText}>{formatClipDuration(totalPlayTime)}</span>

        {isViewing && (
          <div className="absolute inset-x-0 inset-y-0 flex-center bg-black/70 z-30 pointer-events-none">
            <AspectRatio ratio={1} className="w-[25%]">
              <IconPlay className="w-full h-full" />
            </AspectRatio>
          </div>
        )}
      </div>
      <div className={text?.className}>
        {text?.items.map((text) => {
          return <Text key={text.title} tag={text.tag} title={text.title} className={text.className} tabIndex={tabIndex} />;
        })}
      </div>
    </>
  );
};

export default ClipVerticalItem;
