import { SENDBIRD_APPID } from '../utils/common/apiConfig';

console.log({ appId: SENDBIRD_APPID });

// export const sendbirdAppId = process.env.NEXT_PUBLIC_SENDBIRD_APP_ID as string;
export const sendbirdAppId = SENDBIRD_APPID;
export const CHAT_API_URL = {
  SENDBIRD_SESSION: '/user-token' as const,
  RENEWAL_SENDBIRD_SESSION: '/user-token/renew' as const,
};
